export default {
  app: {
    title: "Trampa",
  },
  refresh: {
    title: "Jel si aktivan?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    close: "Zatvori",
    send: "Pošalji",
    sendAgain: "Pošalji ponovo.",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavi",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Lozinka",
    labelFirm: "Ime Firme",
    labelPIB: "Matični broj",
    labelPhone: "Telefon",
    labelPhoneNumber: "Broj telefona",
    labelLocation: "Lokacija",
    labelWebsite: "Adresa Websajta",
    logout: "Odjavi se",
    next: "Sledeće",
    nextPage: "Sledeća strana",
    previousPage: "Prethodna strana",
    back: "Nazad",
    goBack: "Idi nazad",
    ok: "Ok",
    done: "Gotovo",
    confirm: "Potvrdi",
    printDownload: "Print/Download",
    cancel: "Obustavi",
    remove: "Izbriši",
    invite: "Pozovi",
    save: "Sačuvaj",
    complete: "Završi",
    download: "Download",
    yes: "Da",
    no: "Ne",
    to: "do",
    select: "Izaberi...",
    none: "Ni jedan",
    date: {
      range: "{{start}} do {{end}}",
    },
    fillIn: "Unesi",
    trampaIsFinished: "Trampa je završena",
  },
  login: {
    welcome: "React template",
    welcomeText: "Trampa sa kolegama na dohvat ruke",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Nevalidan format email adrese!",
    emailRequired: "Email adresa je obavezna!",
    noUsers: "Ne postoji korisnik sa zadatom email adresom.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Lozinka mora imati najmanje 8 karaktera!",
    signUpRecommendation: "Registrujte se.",
    email: "Unesite email adresu kako biste se prijavili",
    logInTitle: "Uloguj se",
    logIn: "Uloguj se",
    signUp: "Registrujte se.",
    usernameRequired: "Username je obavezan!",
    passwordRequired: "Lozinka je obavezna!",
    passwordConfirmRequired: "Potvrdite lozinku!",
    passwordConfirmIncorrect: "Lozinke se ne podudaraju!",
    forgotYourPassword: "Zaboravili ste lozinku?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Iskoristite drugačiju lozinku.",
    wrongCredentials: "Pogrešan mail ili lozinka!",
    headerTitle: "Ulogujte se",
    nameOfProductError: "Naslov je obavezan!",
    descriptionRequiredError: "Opis je obavezan!",
    descriptionNoOfCharsError: "Opis mora imati najmanje 8 karaktera!",
    locationError: "Unesite ispravnu lokaciju!",
    categoryError: "Kategorija je obavezna!",
    subcategoryError: "Podkategorija je obavezna!",
  },
  password: {
    weak: "slaba",
    average: "srednja",
    good: "dobra",
    strong: "jaka",
    strongPassword:
      "Lozinka mora imati barem jedan veliki karakter, jedan mali karakter, jedan znak i jedan broj!",
  },
  register: {
    title: "Registruj se",
    headerTitle: "Registrujte se",
    descriptionMain: "Trampa sa kolegama na dohvat ruke",
    descriptionFirst:
      "Email i Lozinka biće Vam primarni način da se ulogujete u aplikaciju",
    descriptionSecond:
      "Ovaj korak nije obavezan za razgledanje artikla ali za proces trampe je obavezan. Uvek možete popuniti ova polja u podešavanjima naloga kasnije",
    descriptionThird:
      "Ovaj korak nije obavezan za razgledanje artikla ali za proces trampe je obavezan. Uvek možete popuniti ova polja u podešavanjima naloga kasnije",
    loginText: "Već posedujete nalog?",
    emailFormat: "Nevalidan format email adrese!",
    emailTaken: "E-mail je zauzet!",
    login: "Ulogujte se.",
    acceptTerms: `Pri klikom na dugme "Registruj se", prihvatate naše`,
    terms: "Uslove Korišćenja",
    success: "Registracija Uspešna",
    PIBTaken: "Matični broj je zauzet!",
    PIBnoOfCharacters: "Matični broj mora imati 8 karaktera!",
    welcome: "Dobro došli na trampu, želimo vam uspešno trampovanje!",
    imageError: "Slika je obavezna!",
    serverError: "Greška sa serverom!",
    labelLocationValid: "Unesite ispravnu lokaciju!",
    labelLocationRequired: "Lokacija je obavezna!",
    phoneNumberNoOfCharacters:
      "Broj telefona mora imati izmedju 6 i 15 karaktera!",
    phoneNumberRequired: "Broj telefona je obavezan!",
    locationError: "Odaberite ispravnu lokaciju!",
    websiteError: "Unesite ispravnu adresu svog website!",
    websiteRequired: "Website je obavezan!",
    companyNameError: "Naziv kompanije je zauzet!",
    acceptTermsCheckbox: "Saglasan sam sa pravima privatnosti",
  },
  forgotPassword: {
    title: "Povrati lozinku",
    description:
      "Molimo vas unesite email sa koji cemo vam poslati link za povratak lozinke",
    label: "Pošalji email",
    emailRequired: "Email je obavezan!",
    emailFormat: "Nevalidan format email adrese!",
    mailSent: "E-Mail poslat!",
    mailSentDescription:
      "Poslat vam je email sa instrukcijama kako da resetujete lozinku",
    mailNotFound: "Mejl nije povezan ni sa jednim nalogom!",
    notRecievedMail: "Niste dobili email?",
    checkSpam:
      "U slučaju da Vam ne stigne email, pogledajte <strong>Spam</strong> folder email provajdera",
    forgotPassword: {
      title: "Zaboravili ste lozinku",
      subtitle: "Odgovorite na tajno pitanje kako biste povratili svoj nalog: ",
      label: "Obnovite lozinku",
    },
  },
  resetPassword: {
    title: "Unesite novu lozinku",
    description:
      "Poslali ste zahtev za promenu lozinke, molimo Vas da unesete novu željenu lozinku",
    passwordLabel: "Nova Lozinka",
    passwordConfirmLabel: "Potvrdite Lozinku",
    buttonText: "Postavi lozinku",
    resetSuccess: "Uspešno ste promenili lozinku!",
  },
  filters: {
    title: "Filteri",
    cancel: "Poništi filtere",
    usefilters: "Primeni filtere",
    categories: {
      title: "Kategorija",
      placeholder: "Pretraži kategorije...",
    },
    subcategories: {
      title: "Podkategorija",
      placeholder: "Pretraži podkategorije...",
    },
    location: {
      title: "Lokacija",
      placeholder: "Pretraži gradove...",
    },
    company: {
      title: "Kompanija",
      placeholder: "Pretraži kompanije...",
      firstSort: "A-H",
      secondSort: "I-Q",
      thirdSort: "R-Z",
    },
  },
  offer: {
    title: "NASLOV",
    productName: "Naziv objave...",
    productDescription: "OPIS OBJAVE",
    description: "Opis...",
    location: "LOKACIJA",
    choseLocation: "Izaberi lokaciju",
    category: "KATEGORIJA",
    choseCategory: "Izaberi kategoriju",
    subcategory: "PODKATEGORIJA",
    choseSubcategory: "Izaberi podkategoriju",
    condition: "STANJE",
    choseCondition: "Izaberi Stanje",
    supportedImagesFormats:
      "Podržani formati fotografija: <strong>.JPG</strong> | <strong>.JPEG</strong> | <strong>.PNG</strong>",
    continue: "NASTAVI",
    publish: "OBJAVI",
    review: "Pregled",
    changeOffer: "Izmena Objave",
    newOffer: "Nova Objava",
    product: "Objava",
    descriptionLabel: "Opis:",
    checkButtonLabel: "Pogledaj objavu",
    offers: "Objave",
    tooltip: "Izmeni objavu",
    imageAlt: "Offer image",
    type: "TIP",
    choseType: "Izaberi Tip",
  },
  apiErrors: {
    somethingWentWrong: "Greška sa serverom!",
    offerNotFound: "Ponuda nije pronađena!",
  },
  header: {
    addOffer: "Dodaj objavu",
    searchOffers: "Pretražite objave...",
    myProfile: "Moj profil",
    checkProfile: "POGLEDAJ PROFIL",
    myOffers: "Moje objave",
    checkEverything: "POGLEDAJ SVE",
    myMessages: "Moje poruke",
    newOffers: "Najnovije ponude",
    oldOffers: "Najstarije ponude",
    popularOffers: "Najpopularnije ponude",
    navMenu: "Navigacioni Meni",
    noItems: "Objave nisu pronađene.",
    logout: "Odjavite se",
    about: "O trampi",
    prices: "Cenovnik",
    privacy: "Politika privatnosti",
    addItem: "Dodajte",
    yourFirstOffer: " svoju prvu objavu.",
    adminPanel: "ADMIN PANEL",
  },
  reviews: {
    title: "Ova kompanija još uvek nema ocenu.",
    altTitle: "Budite prvi da je ocenite",
    modalTitle: "Ocenjivanje kompanije",
    isCorrectCommunication: "Korektna komunikacija",
    hasExchangeSucceed: "Uspešna trampa",
    comment: "Komentar",
    commentError: "Komentar mora imati minimum 5 karaktera!",
    selectFieldError: "Odaberite jedno polje!",
    leaveComment: "Ostavi komentar",
    rates: "Ocene kompanije",
    finishedReviewTitle: "Hvala vam",
    finishedReviewAltTitle: "na izdvojenom vremenu i datoj oceni!",
    sortBy: "Sortiraj po",
    offerTitle: "Objava:",
    givenHeaderTitle: "Ocenjena kompanija: ",
  },
  messages: {
    headerTitle: "Moje Ćaskanje",
    cardProduct: "Objava: ",
    chatCardBlocked: "(blokiran korisnik)",
    chatCardDeleted: "(izbrisan korisnik)",
    miniChatHeaderTitle: "Moje Poruke",
    send: "Pošalji",
    sendPlaceholder: "Poruka...",
    seeChats: "Pogledaj ćaskanje",
    noMessagesToast: "Nemate ni jednu poruku!",
    notAllowedChat: "Trampa za ovau objavu je završena.",
    notAllowedChatDeleted: "Profil sa kojim se dopisujete je izbrisan.",
    goBack: "Nazad na sve poruke",
    noMessages: "Poruke nisu pronađene.",
    noMessagesSecond: "Nažalost, nemate ni jednu poruku.",
    tooltip: "Pošalji poruku",
    requestSent: "Uspešno ste ponudili trampu kompaniji.",
    requestAccepted: "Kompanija je prihvatila trampu sa Vama.",
    requestReceived:
      "Da li želite da prihvatite trampu sa nama za  gore navedenu objavu?",
    acceptRequest: "Prihvati",
    acceptedRequest: "Prihvaćeno",
    declinedRequest: "Odbijeno",
    declineRequest: "Odbij",
    requestSuccessfulLong: "Uspešno ste ostvarili trampu sa ovom kompanijom.",
    requestSuccessfulShort: "Uspešno ste otvarili trampu.",
    requestSentLong: "Ponudili ste trampu kompaniji. Čeka se odgovor...",
    requestSentShort: "Ponudili ste trampu kompaniji.",
    exchangeAlreadyFinished: "Trampa za ovu objavu je već završena.",
  },
  editProfile: {
    website: "Web Sajt",
    applink: "App Link*",
    phoneNumber: "Broj Telefona",
    saveChanges: "Sačuvaj Promene",
    showDetails: "Prikaži Detalje",
    showBasic: "Prikaži osnovno",
    idNumber: "MATIČNI BROJ",
    labelNameRequired: "Ime firme je obavezno!",
    labelPIBRequired: "Matični broj firme je obavezan!",
    labelLocationRequired: "Lokacija je obavezna!",
    labelLocationValid: "Unesite ispravnu lokaciju!",
    labelPhoneValid: "Unesite validan broj telefona",
    labelPhoneRequired: "Broj telefona je obavezan!",
    tooltip: "Izmeni profil",
  },
  deleteOffer: {
    areYouSure: "Da li ste sigurni da želite da <br /> obrišete objavu?",
    cancel: "Otkaži",
    delete: "Obriši",
    tooltip: "Obriši objavu",
  },
  itemDetailsCard: {
    description: "Opis: ",
    startExchangeButton: "Trampi",
    PIB: "Matični broj - ",
    offers: " objava",
    totalViews: " ukupnih pregleda",
    successfulExchanges: " uspešnih trampi",
    correctCommunications: " korektna komunikacija",
    headerTitle: "Nazad na objave",
  },
  notFound: {
    error404: "Greška 404",
    errorMessage:
      "Stranica koju tražite ne postoji <br /> ili je u međuvremenu obirsana.",
    showAllOffers: "Pogledaj sve objave",
  },
  offersNotFound: {
    notFound: "Objave nisu pronađene",
    errorMessage:
      "Nažalost ne postoji ni jedna objava <br /> za unete kriterijume.",
    showAllOffers: "Pogledaj sve objave",
  },
  profile: {
    myProfile: "Moj profil",
    PIB: "Matični broj:",
    publishes: " objava",
    successExchange: " uspešnih trampi ",
    numberOfViews: " ukupnih pregleda",
    successComunication: "Procenat uspešne komunikacije ",
    back: "Nazad na objave",
    companyProfile: "Profil kompanije",
    noOffers: {
      mainText: "Objave nisu pronađene",
      altText: "Nažalost nemate ni jednu objavu",
    },
    backToHome: "Nazad na sve objave",
    myOffers: "Moje objave",
    profileOffers: "Objave kompanije",
    blockedProfile: "Trenutno blokiran profil",
    blockedProfileShort: "Blokiran profil",
    mineProfileBlocked: "Vaš profil je trenutno blokiran.",
    deletedProfile: "Obrisan profil",
    admin: "Administrator",
    adminPanel: "Admin Panel",
    verifiedTooltip: "Pouzdana saradnja",
  },
  about: {
    header: {
      title: "Priča o Trampi",
      navigation: "Šta je Trampa?",
      paragraph:
        "Znate ono kada smo bili mali pa je najveću radost u školi predstavljalo menjanje sličica na odmoru? Trampa je upravo to, samo što sada više nismo đaci u školi već poslovni, odrasli, ljudi.",
    },
    history: {
      title: "Istorija",
      text: "Ideja o Trampi je sinula sasvim spontano. Mladen i ja smo prijatelji iz detinjstva koji su nekada bili profesionalni latino plesači koje su kasnije drugačija životna interesovanja odvela na različite strane pa je tako Mladen otišao u preduzetnike, a ja u programere. Međutim, Trampa je učinila da nas dvoje opet budemo partneri, samo ovog puta ne plesni već poslovni. Zbog prirode biznisa kojim se Mladenova porodica bavi, neretko se desi da neke stvari ostanu da, žargonski rečeno, čuče u skladištu duži period. Neretko je to prehrambena roba i prave se veliki gubitci u samom poslovanju. Upravo se tu javila ideja o Trampi. Trampa je mesto gde rečenica ‘jedna strana u pregovorima uvek izlazi kao gubitnik’ jednostavno gubi smisao jer sa Trampom obe strane koje učestvuju pobeđuju!",
    },
    vision: {
      title: "Naša vizija",
      text: "Predmet Trampe nije kupovina već razmena, dakle nema novčane nadoknade, sve se bazira na robnoj/uslužnoj razmeni.Trampa je razvijena sa idejom da Vaša roba nađe novu upotrebnu vrednost kod svog novog vlasnika ujedno zadovoljavajući vaše potrebe.",
    },
    searchOffers: "Pretražite oglase",
  },
  privacyPolicy: {
    header: {
      title: "Politika Privatnosti",
      navigation: "Uslovi korišćenja",
      paragraph:
        "Aenean ut risus faucibus, tempor urna id, luctus urna. Nam placerat scelerisque hendrerit. Nam tortor augue, porta sed nulla vitae, rutrum rhoncus arcu. Ut in leo turpis. Pellentesque eu laoreet orci. Nam id nisi mauris.",
    },
    firstSection: {
      title: "I stavka",
      text: "Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    },
    secondSection: {
      title: "II stavka",
      text: "Aenean ut risus faucibus, tempor urna id, luctus urna. Nam placerat scelerisque hendrerit. Nam tortor augue, porta sed nulla vitae, rutrum rhoncus arcu. Ut in leo turpis. Pellentesque eu laoreet orci. Nam id nisi mauris. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nulla leo, consequat a lacus in, sollicitudin sollicitudin ex. Nunc blandit tincidunt turpis nec vestibulum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi tincidunt ipsum eget velit venenatis, quis sollicitudin nulla dapibus.",
    },
    thirdSection: {
      title: "III stavka",
      text: "Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    },
    forthSection: {
      title: "IV stavka",
      text: "Aenean ut risus faucibus, tempor urna id, luctus urna. Nam placerat scelerisque hendrerit. Nam tortor augue, porta sed nulla vitae, rutrum rhoncus arcu. Ut in leo turpis. Pellentesque eu laoreet orci. Nam id nisi mauris. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nulla leo, consequat a lacus in, sollicitudin sollicitudin ex. Nunc blandit tincidunt turpis nec vestibulum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi tincidunt ipsum eget velit venenatis, quis sollicitudin nulla dapibus.",
    },
  },
  prices: {
    header: {
      title: "Cenovnik",
      navigation: "Cenovnik",
      link: "Pročitajte opširnije o našim ponudama",
    },
    altText:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue tortor. Nulla facilisi. Cras vestibulum risus eget tincidunt egestas. Duis blandit enim sit amet dui vehicula luctus. Suspendisse id blandit arcu, vitae consequat nisi. Duis ut vestibulum tellus. Curabitur eu fringilla nisi.",
  },
  plan: {
    monthly: "/ mesečno",
    buy: "KUPI",
    beginner: {
      title: "POČETNIK",
      description: "Pellentesque venenatis leo at",
      firstDetail: "Suspendisse non semper ligula",
      secondDetail: "Nam nec neque non justo",
      thirdDetail: "Mauris elementum",
      forthDetail: "Ut gravida lacinia feugiat",
      fifthDetail: "Sed vel nibh tincidunt",
      firstQuantity: "25",
      secondQuantity: "10",
      thirdQuantity: "100",
      forthQuantity: "100",
      fifthQuantity: "",
    },
    businessman: {
      title: "BIZNISMEN",
      description: "Pellentesque venenatis leo at",
      firstDetail: "Suspendisse non semper ligula",
      secondDetail: "Nam nec neque non justo",
      thirdDetail: "Mauris elementum",
      forthDetail: "Ut gravida lacinia feugiat",
      fifthDetail: "Sed vel nibh tincidunt",
      firstQuantity: "500",
      secondQuantity: "NEOGRANIČENO",
      thirdQuantity: "NEOGRANIČENO",
      forthQuantity: "NEOGRANIČENO",
      fifthQuantity: "",
    },
    entrepreneur: {
      title: "PREDUZETNIK",
      description: "Pellentesque venenatis leo at",
      firstDetail: "Suspendisse non semper ligula",
      secondDetail: "Nam nec neque non justo",
      thirdDetail: "Mauris elementum",
      forthDetail: "Ut gravida lacinia feugiat",
      fifthDetail: "Sed vel nibh tincidunt",
      firstQuantity: "100",
      secondQuantity: "20",
      thirdQuantity: "NEOGRANIČENO",
      forthQuantity: "NEOGRANIČENO",
      fifthQuantity: "",
    },
  },
  aboutFooter: {
    goStart: "Početak stranice",
    middleText: "Diligent Software © 2022 | Sva prava zadržana",
  },
  notFoundData: {
    PIB: "Matični broj",
    companyName: "Naziv kompanije",
    categoryName: "Naziv kategorije",
    location: "Naziv grada",
    subcategoryName: "Naziv podkategorije",
    offerName: "Naslov ponude",
    condition: "Stanje",
    description: "Opis",
    email: "Mejl kompanije",
  },
  carousel: {
    imagesReview: "Pregled fotografija",
    offer: "Objava:",
  },
  admin: {
    login: {
      welcome: "React template",
      welcomeText: "Trampa sa kolegama na dohvat ruke",
      emailFormat: "Nevalidan format email adrese!",
      emailRequired: "Email adresa je obavezna!",
      noUsers: "Ne postoji korisnik sa zadatom email adresom.",
      passwordStrength: "Your password is {{strength}}.",
      passwordLength: "Lozinka mora imati najmanje 8 karaktera!",
      email: "Unesite email adresu kako biste se prijavili",
      logInTitle: "Uloguj se",
      logIn: "Uloguj se",
      usernameRequired: "Username je obavezan!",
      passwordRequired: "Lozinka je obavezna!",
      wrongCredentials: "Pogrešan mail ili lozinka!",
      headerTitle: "Ulogujte se",
    },
    users: {
      headerTitle: "Profili kompanija",
      searchPlaceholder: "Pretražite korisnike....",
      checkProfile: "Pogledaj profil",
      goBack: "Nazad na sve kompanije",
    },
    navigation: {
      role: "Administrator",
      menu: "Meni",
      users: "Korisnici",
      categories: "Kategorije",
      locations: "Lokacije",
      payment: "Uplate",
      marketplace: "Vidi sve objave",
      logout: "Odjavi se",
    },
    categories: {
      checkCategory: "Pogledaj podkategorije",
      noOfOffers: "Broj objava: ",
      noOfSubcategories: "Broj potkategorija: ",
      headerTitle: "Kategorije",
      placeholder: "Pretražite kategorije...",
      reassuranceDelete:
        "Da li ste sigurni da želite da obrišete odabranu kategoriju?",
      cancel: "Otkaži",
      delete: "Obriši",
      addCategory: "Dodaj kategoriju",
      edit: {
        title: "Izmena Kategorije",
        fieldTitle: "Naslov",
        placeholder: "Naziv kategorije...",
        save: "Izmeni",
        next: "Izmeni",
      },
      add: {
        title: "Nova Kategorija",
        fieldTitle: "Naslov",
        placeholder: "Naziv kategorije...",
        save: "Dodaj",
        next: "Sledeća",
      },
    },
    subcategories: {
      noOfOffers: "Broj objava: ",
      headerTitle: "Kategorija",
      subcategoriesHeaderTitle: "Podkategorije",
      placeholder: "Pretražite podkategorije...",
      addSubcategory: "Dodaj podkategoriju",
      cancel: "Otkaži",
      delete: "Obriši",
      goBack: "Nazad na sve kategorije",
      reassuranceDelete:
        "Da li ste sigurni da želite da obrišete odabranu podkategoriju?",
      edit: {
        title: "Izmena Podkategorije",
        fieldTitle: "Naslov",
        placeholder: "Naziv podkategorije...",
        save: "Izmeni",
        next: "Izmeni",
      },
      add: {
        title: "Nova Podkategorija",
        fieldTitle: "Naslov",
        placeholder: "Naziv podkategorije...",
        save: "Dodaj",
        next: "Sledeća",
      },
    },
    locations: {
      headerTitle: "Lokacije",
      noOfCompanies: "Broj firmi u gradu: ",
      placeholder: "Pretražite lokacije...",
      addLocation: "Dodaj lokaciju",
      cancel: "Otkaži",
      delete: "Obriši",
      reassuranceDelete:
        "Da li ste sigurni da želite da obrišete odabranu lokaciju?",
      edit: {
        title: "Izmena Lokacije",
        fieldTitle: "Naslov",
        placeholder: "Naziv lokacije...",
        save: "Izmeni",
        next: "Izmeni",
      },
      add: {
        title: "Nova Lokacija",
        fieldTitle: "Naslov",
        placeholder: "Naziv lokacije...",
        save: "Dodaj",
        next: "Sledeća",
      },
    },
    payment: {
      headerTitle: "Uplate",
      placeholder: "Pretražite uplate...",
      typeOfPayment: "Tip: ",
      date: "Datum: ",
      addPayment: "Dodaj uplatu",
      reassuranceDelete:
        "Da li ste sigurni da želite da obrišete odabranu uplatu?",
      cancel: "Otkaži",
      delete: "Obriši",
      modal: {
        newTitle: "Nova Uplata",
        editTitle: "Izmena Uplate",
        name: "UPLATIOC",
        company: "IME KOMPANIJE",
        type: "TIP",
        date: "DATUM",
        offer: "OBJAVA",
        add: "Dodaj",
        change: "Izmeni",
      },
      errors: {
        nameRequired: "Ime uplatioca je obavezno!",
        companyRequired: "Ime kompanije je obavezno!",
        offerRequired: "Objava je obavezana!",
        typeRequired: "Tip je obavezan!",
        dateRequired: "Datum je obavezan!",
      },
    },
    deleteUser: {
      reassuranceDelete:
        "Da li ste sigurni da želite da obrišete profil kompanje?",
      cancel: "Otkaži",
      delete: "Obriši",
      tooltip: "Obriši korisnika",
    },
    blockUser: {
      reassuranceDelete:
        "Da li ste sigurni da želite da blokirate profil kompanje?",
      cancel: "Otkaži",
      delete: "Blokiraj",
      tooltip: "Blokiraj korisnika",
    },
    approveUser: {
      reassuranceDelete:
        "Da li ste sigurni da želite da odobrite profil kompanje?",
      cancel: "Otkaži",
      delete: "Odobri",
      tooltip: "Odobri korisnika",
    },
    unblockUser: {
      reassuranceDelete:
        "Da li ste sigurni da želite da odblokirate profil kompanje?",
      cancel: "Otkaži",
      delete: "Odblokiraj",
      tooltip: "Odblokiraj korisnika",
    },
    pin: {
      reassurancePin: "Da li ste sigurni da želite da zakačite objavu na vrh?",
      confirm: "Zakači",
      tooltip: "Zakači objavu",
    },
    unpin: {
      reassurancePin: "Da li ste sigurni da želite da otkačite objavu sa vrha?",
      confirm: "Otkači",
      tooltip: "Otkači objavu",
    },
    review: {
      title: "Brisanje Komentara",
      confirm: "Obriši komentar",
    },
  },
  labels: {
    dropdownIcon: "Dropdown icon",
    dropdownOpen: "Open dropdown",
    dropdownClose: "Close dropdown",
    messageUser: "Message user",
    prevPage: "Previous page",
    nextPage: "Next page",
    link: "Link",
  },
  colorPicker: {
    label: "Boja: ",
    darkGray: "Tamno Siva",
    gray: "Siva",
    yellow: "Žuta",
    purple: "Ljubičasta",
    pink: "Roze",
  },
  examples: {
    link: "ex. https://google.com/",
  },
  blog: {
    headerTitle: "Blog",
    checkButtonLabel: "Pogledaj članak",
    tooltip: "Dodaj u omiljene",
    backButton: "Nazad na sve članke",
    sidebarTitle: "<strong>Blog:</strong> Najnoviji članci",
  },
};

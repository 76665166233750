import React from "react";
import { LabelContainer, LeftLabel, RightLabel } from "./Label.styled";
import PropTypes from "prop-types";

export const Label = (props) => {
  return (
    <LabelContainer
      onClick={props.onClick}
      maxWidth={props.maxWidth}
      style={props.containerStyle}
      className={props.className}
    >
      <LeftLabel style={props.leftTextStyle}>{props.leftText}</LeftLabel>
      {props.rightText !== null ? <RightLabel>{props.rightText}</RightLabel> : <></>}
    </LabelContainer>
  );
};

Label.propTypes = {
  onClick: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.string,
  leftTextStyle: PropTypes.any,
  rightTextStyle: PropTypes.any,
  containerStyle: PropTypes.any,
  className: PropTypes.any,
};

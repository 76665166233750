import React, { useState } from "react";
import PropTypes from "prop-types";
import { HomePageContainer } from "./HomePage.styled";
import FilterCard from "../../components/Cards/FilterCard/FilterCard";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import MarketPlace from "../../components/MarketPlace/MarketPlace";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { useSelector } from "react-redux";
import { OFFERS_SCOPE } from "../../store/actions/offers/offersActionConstants";
import useOffers from "../../hooks/useOffers/useOffers";
// import useIsLoggedIn from "../../hooks/useIsLoggedIn";
import AboutPageContent from "../../components/About/AboutPageContent";
import { selectUserId } from "../../store/selectors/loginSelectors";

const HomePage = (props) => {
  // const { isLoggedIn } = useIsLoggedIn();
  const userId = useSelector(selectUserId);
  const isLoadingOffers = useSelector(
    selectIsLoadingByActionType(OFFERS_SCOPE)
  );
  const [filtersOpened, setFiltersOpened] = useState(false);
  const offers = useOffers();
  const toggleFilters = () => {
    setFiltersOpened((prevFiltersOpened) => !prevFiltersOpened);
  };

  if (userId || props?.isMarketplacePage) {
    return (
      <HomePageContainer>
        <MainLayout
          leftCard={
            <FilterCard
              offers={offers}
              filtersOpened={filtersOpened}
              skeleton={isLoadingOffers}
              toggleFilters={toggleFilters}
            />
          }
          content={
            <MarketPlace
              offers={offers}
              skeleton={isLoadingOffers}
              toggleFilters={toggleFilters}
            />
          }
        />
      </HomePageContainer>
    );
  }
  return <AboutPageContent />;
};

HomePage.propTypes = {
  isMarketplacePage: PropTypes.bool,
};
export default HomePage;

import React from "react";
import PropTypes from "prop-types";
import { ButtonContainer, DeleteButtonContainer } from "./DeleteButton.styled";
import selectedTheme from "../../../../themes";
import { useTranslation } from "react-i18next";

const DeleteButton = (props) => {
    const {t} = useTranslation();
  return (
    <DeleteButtonContainer>
      <ButtonContainer
        variant="contained"
        buttoncolor={selectedTheme.colors.primaryPurple}
        onClick={props.onClick}
        textcolor={selectedTheme.colors.primaryTextDisabled}
      >
        {t("admin.review.confirm")}
      </ButtonContainer>
    </DeleteButtonContainer>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

export default DeleteButton;

export const variables = {
    font:{
      textFont: "Open Sans"
    },
    typography: {
      p: {
        large: {
          fontSize: '1.5rem',
          lineHeight: '1.5',
        },
        regular: {
          fontSize: '1.1rem',
          lineHeight: '1.7',
        },
        normal: {
          fontSize: '1rem',
          lineHeight: '1.6',
        },
        small: {
          fontSize: '0.9rem',
          lineHeight: '1.7',
        },
        extraSmall: {
          fontSize: '0.75rem',
          lineHeight: '1.4',
        },
      },
      h: {
        title: {
          fontSize: '2.2rem',
          lineHeight: '1.6',
        },
        subtitle: {
          fontSize: '1.5rem',
          lineHeight: '1.6',
        },
        paragraph: {
          fontSize: '1.5rem',
          lineHeight: '1.6',
        },
        paragraphSubtitle: {
          fontSize: '1rem',
          lineHeight: '1.5',
        },
      },
    },
    sizes: {
      baseFontSize: '14px',
      baseFontSizeMd: '16px'
    },
    shadow: {
      buttonShadowHover: '0 5px 6px 0 rgba(112, 120, 135, 0.24)',
      buttonShadowPressed: '0 2px 4px 0 rgba(112, 120, 135, 0.24)',
      boxShadow: '0 3px 8px 0 rgba(112, 120, 135, 0.24)',
      accountDropdownShadow: '0 6px 38px 0 rgba(112, 120, 135, 0.56)',
    },
    borderRadius: {
      borderRadius: '4px',
      borderRadiusMd: '8px',
    },
    breakpoints: {
      bpXxs: '325',
      bpXs: '400',
      bpSm: '576',
      bpMd: '768',
      bpLg: '992',
      bpXl: '1200',
      bpXxl: '1350',
    },
    zIndex: {
      indexXxs: 1,
      indexXs: 2,
      indexSm: 3,
      indexMd: 4,
      indexLg: 5,
      indexXl: 6,
      indexXxl: 7,
    },
    transitionSpeed: {
      transitionSpeed: '0.25s',
    },
    params: {
      welcomeContentWidth: '568px',
    },
  };
  
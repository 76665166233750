import { Box } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const DeleteButtonContainer = styled(Box)`
  background-color: white !important;
  border: 0 !important;
  padding: 0 !important;
  @media (max-width: 600px) {
    position: absolute;
    bottom: 36px;
    left: 18px;
  }
`;
export const ButtonContainer = styled(PrimaryButton)`
  height: 44px;
  width: 335px;
  margin: 18px auto;
  background-color: white !important;
  & > button {
    background-color: ${(props) => props.buttoncolor} !important;
  }
  @media (max-width: 600px) {
    width: calc(100vw - 36px);
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { BackIcon } from "./BackButton.styled";
import { ReactComponent as ArrowBack } from "../../../../assets/images/svg/arrow-back.svg";

const BackButton = (props) => {
  const backButtonHandler = () => {
    props.setCurrentStep((prevState) => prevState - 1);
  };

  return (
    <BackIcon onClick={backButtonHandler}>
      {props.currentStep !== 1 ? <ArrowBack /> : ""}
    </BackIcon>
  );
};

BackButton.propTypes = {
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
};

export default BackButton;

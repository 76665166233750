import React from "react";
import { ReactComponent as UserIcon } from "../assets/images/svg/user-gray.svg";
import { ReactComponent as CategoryIcon } from "../assets/images/svg/category.svg";
import { ReactComponent as LocationIcon } from "../assets/images/svg/location.svg";
import { ReactComponent as DollarIcon } from "../assets/images/svg/dollar-sign.svg";
import {
  ADMIN_CATEGORIES_PAGE,
  ADMIN_LOCATIONS_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_USERS_PAGE,
} from "./pages";
import i18n from "../i18n";

export const ADMIN_NAVIGATION = [
  {
    text: i18n.t("admin.navigation.users"),
    icon: <UserIcon />,
    route: `${ADMIN_USERS_PAGE}`,
  },
  {
    text: i18n.t("admin.navigation.categories"),
    icon: <CategoryIcon />,
    route: `${ADMIN_CATEGORIES_PAGE}`,
  },
  {
    text: i18n.t("admin.navigation.locations"),
    icon: <LocationIcon />,
    route: `${ADMIN_LOCATIONS_PAGE}`,
  },
  {
    text: i18n.t("admin.navigation.payment"),
    icon: <DollarIcon />,
    route: `${ADMIN_PAYMENT_PAGE}`,
  },
];

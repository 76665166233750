import React from "react";
import PropTypes from "prop-types";
import {
  ReviewDetailsContainer,
  ReviewDetailsText,
  ReviewDetailsValue,
} from "./ReviewDetails.styled";
import { useTranslation } from "react-i18next";

const ReviewDetails = (props) => {
  const { t } = useTranslation();
  return (
    <ReviewDetailsContainer>
      <ReviewDetailsText variant="body2" sx={{ display: "block" }}>
        {t("reviews.isCorrectCommunication") + ": "}
        <ReviewDetailsValue>
          {props?.isGoodCommunication?.toUpperCase()}
        </ReviewDetailsValue>
      </ReviewDetailsText>
      <ReviewDetailsText variant="body2" sx={{ display: "block" }}>
        {t("reviews.hasExchangeSucceed") + ": "}
        <ReviewDetailsValue>
          {props?.isSuccessfulSwap?.toUpperCase()}
        </ReviewDetailsValue>
      </ReviewDetailsText>
    </ReviewDetailsContainer>
  );
};

ReviewDetails.propTypes = {
  isGoodCommunication: PropTypes.string,
  isSuccessfulSwap: PropTypes.string,
};

export default ReviewDetails;

import React from "react";
import PropTypes from "prop-types";
import {
  HeaderTitleContent,
  MailIcon,
  MiniChatColumnHeaderContainer,
} from "./MiniChatColumnHeaderTitle.styled";
import { useTranslation } from "react-i18next";

const MiniChatColumnHeader = () => {
  const { t } = useTranslation();
  return (
    <MiniChatColumnHeaderContainer>
      <MailIcon />
      <HeaderTitleContent>
        {t("messages.miniChatHeaderTitle")}
      </HeaderTitleContent>
    </MiniChatColumnHeaderContainer>
  );
};

MiniChatColumnHeader.propTypes = {
  children: PropTypes.node,
};

export default MiniChatColumnHeader;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import FilterCheckboxDropdown from "../../FilterDropdown/Checkbox/FilterCheckboxDropdown";
import { LocationIcon } from "./LocationChoser.styled";
import { useTranslation } from "react-i18next";

const LocationChoser = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const filters = props.filters;

  const allLocations = useMemo(
    () => filters.locations.allLocations || [],
    [filters.locations]
  );

  useImperativeHandle(ref, () => ({
    closeSection: () => {
      setIsOpened(false);
    },
  }));

  useEffect(() => {
    if (filters.locations.selectedLocationsLocally.length > 0 && !isOpened) {
      setIsOpened(true);
    }
  }, [filters.locations.selectedLocationsLocally]);

  useEffect(() => {
    if (appliedFilters) {
      props?.offers?.apply();
      setAppliedFilters(false);
    }
  }, [appliedFilters]);

  const handleSetItemsSelected = (items) => {
    filters.locations.setSelectedLocations(items);
    setAppliedFilters(true);
  };
  return (
    <FilterCheckboxDropdown
      searchPlaceholder={t("filters.location.placeholder")}
      data={[...allLocations]}
      filters={[...filters.locations.selectedLocationsLocally]}
      open={isOpened}
      handleOpen={() => setIsOpened((prevIsOpened) => !prevIsOpened)}
      icon={<LocationIcon />}
      title={t("filters.location.title")}
      setItemsSelected={handleSetItemsSelected}
      offers={props.offers}
    />
  );
});

LocationChoser.displayName = "LocationChoser";

LocationChoser.propTypes = {
  filters: PropTypes.any,
  offers: PropTypes.any,
};

export default LocationChoser;

import React from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../../MUI/BackdropComponent";
import {
  CloseButton,
  ImagesCarouselContainer,
  ImagesCarouselHeader,
  Offer,
  OfferImage,
  OfferSpan,
  Scroller,
} from "./ImagesCarousel.styled";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import useIsMobile from "../../../../hooks/useIsMobile";
import { ReactComponent as CloseButtonIcon } from "../../../../assets/images/svg/close-modal.svg";
import { useTranslation } from "react-i18next";

const ImagesCarousel = (props) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();

  const closeCreateOfferModal = () => {
    props.onModalClose();
  };
  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeCreateOfferModal}
        position="fixed"
      />
      <ImagesCarouselContainer>
        <ImagesCarouselHeader>
          {t("carousel.imagesReview")}
        </ImagesCarouselHeader>
        <CloseButton onClick={closeCreateOfferModal}>
          <CloseButtonIcon />
        </CloseButton>
        <Scroller isCarousel>
          {props?.offer?.images.map((image) => {
            if (!image) return;
            return (
              <OfferImage
                src={getImageUrl(image, variants.carousel, isMobile)}
                key={image}
              />
            );
          })}
        </Scroller>
        <Offer>
          {t("carousel.offer")} <OfferSpan>{props.offer.name}</OfferSpan>
        </Offer>
      </ImagesCarouselContainer>
    </>
  );
};

ImagesCarousel.propTypes = {
  offer: PropTypes.any,
  onModalClose: PropTypes.any,
  createOffer: PropTypes.bool,
};

export default ImagesCarousel;

import React from "react";
import PropTypes from "prop-types";
import AutoSuggestTextField from "../../../../TextFields/AutoSuggestTextField/AutoSuggestTextField";
import { InputFieldLabelLocation } from "./LocationField.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../store/selectors/locationsSelectors";
import { useEffect } from "react";
import { fetchLocations } from "../../../../../store/actions/locations/locationsActions";
import { useTranslation } from "react-i18next";

const LocationField = (props) => {
  const { t } = useTranslation();
  const locations = useSelector(selectLocations);
  const dispatch = useDispatch();
  useEffect(() => {
    if (locations?.length === 0) {
      dispatch(fetchLocations());
    }
  }, [locations]);

  return (
    <>
      <InputFieldLabelLocation
        leftText={t("common.labelLocation").toUpperCase()}
      />
      <AutoSuggestTextField
        editLocation
        data={locations.map((item) => ({ name: item.city }))}
        value={props.formik.values.firmLocation}
        error={props.formik.errors.firmLocation}
        onChange={(event, { newValue }) =>
          props.formik.setFieldValue("firmLocation", newValue)
        }
      />
    </>
  );
};

LocationField.propTypes = {
  formik: PropTypes.any,
};

export default LocationField;

import React from "react";
import PropTypes from "prop-types";
import { DrawerButton, DrawerOption } from "../../Drawer.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { MailIcon } from "./MyMessagesButton.styled";
import history from "../../../../../store/utils/history";
import { MESSAGES_LIST_PAGE } from "../../../../../constants/pages";

const MyMessagesButton = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.toggleDrawer();
    history.push(MESSAGES_LIST_PAGE);
  };
  return (
    <DrawerButton onClick={handleClick}>
      <IconButton sx={{ borderRadius: "4px" }}>
        <MailIcon />
      </IconButton>
      <DrawerOption>{t("header.myMessages")}</DrawerOption>
    </DrawerButton>
  );
};

MyMessagesButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default MyMessagesButton;

import { useSelector } from "react-redux";
import { selectUserId } from "../store/selectors/loginSelectors";

const useIsLoggedIn = () => {
  const userId = useSelector(selectUserId);
  const isLoggedIn = userId ? true : false;
  return {
    isLoggedIn
  }
};

export default useIsLoggedIn;

import { sortCategoriesEnum } from "../../enums/sortEnum";
import { sortPaymentsEnum } from "../../enums/sortEnum";

export const adminSortMethod = (arrayOfItems, manualSearchString, sorting) => {
  let arrayOfItemsToReturn = [...arrayOfItems];
  if (manualSearchString)
    arrayOfItemsToReturn = arrayOfItems.filter(
      (item) =>
        item?.city?.toLowerCase()?.includes(manualSearchString.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(manualSearchString.toLowerCase()) ||
        item?.payerName
          ?.toLowerCase()
          ?.includes(manualSearchString.toLowerCase())
    );
  if (sorting?.selectedSortOptionLocally !== sortCategoriesEnum.INITIAL) {
    if (sorting?.selectedSortOptionLocally === sortCategoriesEnum.POPULAR) {
      arrayOfItemsToReturn.sort((a, b) => b.offerCount - a.offerCount);
    }
    if (sorting?.selectedSortOptionLocally === sortCategoriesEnum.OLD) {
      arrayOfItemsToReturn.sort((a, b) => {
        const firstCreated = new Date(
          a?._created || new Date(1970, 1).toISOString()
        );
        const secondCreated = new Date(
          b?._created || new Date(1970, 1).toISOString()
        );
        return firstCreated - secondCreated;
      });
    }
    if (sorting?.selectedSortOptionLocally === sortCategoriesEnum.NEW) {
      arrayOfItemsToReturn.sort((a, b) => {
        const firstCreated = new Date(
          a?._created || new Date(1970, 1).toISOString()
        );
        const secondCreated = new Date(
          b?._created || new Date(1970, 1).toISOString()
        );
        return secondCreated - firstCreated;
      });
    }
  }
  // if ("payerName" in arrayOfItems[0]) {
  if (sorting?.selectedSortOptionLocally === sortPaymentsEnum.OLD) {
    arrayOfItemsToReturn.sort((a, b) => {
      const firstCreated = new Date(
        a?._created || new Date(1970, 1).toISOString()
      );
      const secondCreated = new Date(
        b?._created || new Date(1970, 1).toISOString()
      );
      return firstCreated - secondCreated;
    });
  }
  if (sorting?.selectedSortOptionLocally === sortPaymentsEnum.NEW) {
    arrayOfItemsToReturn.sort((a, b) => {
      const firstCreated = new Date(
        a?._created || new Date(1970, 1).toISOString()
      );
      const secondCreated = new Date(
        b?._created || new Date(1970, 1).toISOString()
      );
      return secondCreated - firstCreated;
    });
  }
  // }
  return arrayOfItemsToReturn;
};

import React from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../MUI/BackdropComponent";
import {
  ButtonsContainer,
  CancelButton,
  CategoryName,
  DeleteCategoryContainer,
  DeleteIcon,
  ReassuranceText,
  SaveButton,
} from "./DeleteCategory.styled";
import LabeledCard from "../../Cards/LabeledCard/LabeledCard";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import selectedTheme from "../../../themes";
import { useDispatch } from "react-redux";
import { fetchAdminMethod } from "../../../store/actions/admin/adminActions";
import { DELETE_TYPE } from "../../../constants/adminMethodConstants";
import { dynamicRouteMatches } from "../../../util/helpers/routeHelpers";
import { ADMIN_SINGLE_USER_PAGE } from "../../../constants/pages";
import { fetchProfile } from "../../../store/actions/profile/profileActions";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  deletePayment,
  fetchPayments,
} from "../../../store/actions/payment/paymentActions";

const DeleteCategory = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const closeModalHandler = () => {
    dispatch(closeModal());
  };
  const reassuranceText = useMemo(() => {
    return t(`admin.${props.type}.reassuranceDelete`);
  }, [props, t]);

  const handleApiResponseSuccess = () => {
    if (dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE)) {
      dispatch(fetchProfile(props.customId));
    }
    closeModalHandler();
  };
  const handleApiDeletePaymentResponseSuccess = () => {
    closeModalHandler();
    dispatch(fetchPayments());
  };
  console.log(props);
  const handleSubmit = () => {
    if (props.type === "payment") {
      dispatch(
        deletePayment({
          id: props.id,
          handleApiDeletePaymentResponseSuccess,
        })
      );
    } else {
      dispatch(
        fetchAdminMethod({
          type: props.type,
          method: DELETE_TYPE,
          name: props.category?.name,
          id: props.customId || props.category?._id,
          categoryId: props.categoryId,
          handleApiResponseSuccess,
        })
      );
    }
  };
  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeModalHandler}
        position="fixed"
      />
      <DeleteCategoryContainer type={props.type}>
        <LabeledCard
          icon={props.customLabeledCardIcon || <DeleteIcon />}
          width={props.customLabeledCardWidth}
          height={props.customLabeledCardHeight}
        >
          {props.customLabeledCard || (
            <CategoryName>
              {props.category?.name ||
                props.category?.city ||
                props.category.payerName}
            </CategoryName>
          )}
        </LabeledCard>
        <ReassuranceText>{reassuranceText}</ReassuranceText>
        <ButtonsContainer>
          <CancelButton
            onClick={closeModalHandler}
            variant="contained"
            buttoncolor={selectedTheme.colors.primaryPurple}
          >
            {t(`admin.${props.type}.cancel`)}
          </CancelButton>
          <SaveButton
            variant="outlined"
            buttoncolor={selectedTheme.colors.primaryPurple}
            onClick={handleSubmit}
          >
            {t(`admin.${props.type}.delete`)}
          </SaveButton>
        </ButtonsContainer>
      </DeleteCategoryContainer>
    </>
  );
};

DeleteCategory.propTypes = {
  category: PropTypes.object,
  subcategory: PropTypes.bool,
  type: PropTypes.string,
  customLabeledCard: PropTypes.node,
  customLabeledCardWidth: PropTypes.string,
  customLabeledCardHeight: PropTypes.string,
  customLabeledCardIcon: PropTypes.node,
  categoryId: PropTypes.string,
  customId: PropTypes.string,
  id: PropTypes.string,
};

export default DeleteCategory;

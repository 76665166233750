import BlogImage1 from "../assets/images/blog/blog-image1.png";
import BlogImage2 from "../assets/images/blog/blog-image2.png";
import BlogImage3 from "../assets/images/blog/blog-image3.png";
import BlogImage4 from "../assets/images/blog/blog-image4.png";

export const BLOG_DATA = [
  {
    _id: 1,
    title: "Od nule do uspeha",
    creator: "Emilija Stojilković",
    creatorRole: "Marketing Manager",
    category: "Trampa",
    date: "04.09.22",
    image: BlogImage1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue tortor. Nulla facilisi. Cras vestibulum risus eget tincidunt egestas.Duis blandit enim sit amet dui vehicula luctus. Suspendisse id blandit arcu, vitae consequat nisi. Duis ut vestibulum tellus. Curabitur eu fringilla nisi. Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    description2:
      "Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia.",
  },
  {
    _id: 2,
    title: "Strategija kompanije",
    creator: "Emilija Stojilković",
    creatorRole: "Marketing Manager",
    category: "Trampa",
    date: "18.07.22",
    image: BlogImage2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue tortor. Nulla facilisi. Cras vestibulum risus eget tincidunt egestas.Duis blandit enim sit amet dui vehicula luctus. Suspendisse id blandit arcu, vitae consequat nisi. Duis ut vestibulum tellus. Curabitur eu fringilla nisi. Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    description2:
      "Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia.",
  },
  {
    _id: 3,
    title: "Skok cena na tržištu",
    creator: "Emilija Stojilković",
    creatorRole: "Marketing Manager",
    category: "Trampa",
    date: "02.07.22",
    image: BlogImage3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue tortor. Nulla facilisi. Cras vestibulum risus eget tincidunt egestas.Duis blandit enim sit amet dui vehicula luctus. Suspendisse id blandit arcu, vitae consequat nisi. Duis ut vestibulum tellus. Curabitur eu fringilla nisi. Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    description2:
      "Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia.",
  },
  {
    _id: 4,
    title: "Istorija Trampe iz mog ugla",
    creator: "Milan Tripković",
    creatorRole: "CTO @ Diligent Software",
    category: "Trampa",
    date: "28.06.22",
    image: BlogImage4,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac augue tortor. Nulla facilisi. Cras vestibulum risus eget tincidunt egestas.Duis blandit enim sit amet dui vehicula luctus. Suspendisse id blandit arcu, vitae consequat nisi. Duis ut vestibulum tellus. Curabitur eu fringilla nisi. Suspendisse non semper ligula. Nam nec neque non justo vehicula hendrerit vel ut ligula. Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia. Sed vel nibh tincidunt, tristique arcu fermentum, sollicitudin lorem.",
    description2:
      "Pellentesque venenatis leo at nisl facilisis euismod. Quisque at cursus quam. Praesent gravida metus erat, nec tempus nibh accumsan sed. Duis non ornare ipsum. Suspendisse justo metus, cursus eget efficitur vitae, imperdiet id neque. Ut vel commodo nunc. Morbi iaculis, arcu in commodo sollicitudin, est nisl feugiat mi, euismod accumsan odio ligula nec diam. Vestibulum eros est, ornare et varius sit amet, placerat sed nibh. Mauris elementum rutrum feugiat. Quisque consectetur, dui sed pharetra eleifend, augue nibh rhoncus felis, et feugiat tortor felis blandit dui. Ut gravida lacinia feugiat. In hac habitasse platea dictumst. Mauris cursus lectus ac libero ultrices lacinia.",
  },
];

import React from "react";
import PropTypes from "prop-types";
import AboutPageContent from "../../components/About/AboutPageContent";

const AboutPage = () => {
  return <AboutPageContent />;
};

AboutPage.propTypes = {
  children: PropTypes.node,
};

export default AboutPage;

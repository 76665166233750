import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as VisibilityOn } from "../../../../assets/images/svg/eye-striked.svg";
import { ReactComponent as VisibilityOff } from "../../../../assets/images/svg/eye.svg";
import { useSelector } from "react-redux";
import { selectLoginError } from "../../../../store/selectors/loginSelectors";
import { TextField } from "../../../TextFields/TextField/TextField";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../../Buttons/IconButton/IconButton";

const PasswordField = forwardRef((props, ref) => {
  const formik = props.formik;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const error = useSelector(selectLoginError);
  const { t } = useTranslation();
  console.log(error, props);
  useEffect(() => {
    console.dir(error);
  }, [error]);

  return (
    <TextField
      name="password"
      ref={ref}
      placeholder={t("common.labelPassword")}
      margin="normal"
      type={showPassword ? "text" : "password"}
      value={formik.values.password}
      onChange={formik.handleChange}
      error={
        (formik.touched.password && formik.errors.password?.length > 0) ||
        error.length > 0
      }
      helperText={formik.touched.password && formik.errors.password}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <VisibilityOn /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
    />
  );
});

PasswordField.displayName = "PasswordField";

PasswordField.propTypes = {
  formik: PropTypes.any,
};

export default PasswordField;

import React, { useState, useEffect, useRef } from "react";
import {
  AuthButtonsContainer,
  HeaderContainer,
  LogoContainer,
  LogoImage,
  MarketplaceLinkRoute,
  MarketplaceLinkRouteContainer,
  SmallScreenMessagesContainer,
  ToolsButtonsContainer,
  ToolsContainer,
} from "./Header.styled";
import PropTypes from "prop-types";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { ReactComponent as LogoHorizontal } from "../../assets/images/svg/logo-horizontal.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../store/selectors/loginSelectors";
import {
  selectMineProfile,
  selectProfileName,
} from "../../store/selectors/profileSelectors";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  ABOUT_PAGE,
  ADMIN_HOME_PAGE,
  BASE_PAGE,
  HOME_PAGE,
  MARKETPLACE_PAGE,
} from "../../constants/pages";
import { fetchMineProfile } from "../../store/actions/profile/profileActions";
import useSearch from "../../hooks/useOffers/useSearch";
import {
  isAdminRoute,
  isAuthRoute,
  routeMatches,
} from "../../util/helpers/routeHelpers";
import AboutHeader from "./AboutHeader/AboutHeader";
import SearchInput from "./SearchInput/SearchInput";
import DrawerContainer from "./DrawerContainer/DrawerContainer";
import OpenDrawerButton from "./OpenDrawerButton/OpenDrawerButton";
import AddOfferButton from "./AddOfferButton/AddOfferButton";
import MySwapsButton from "./MySwapsButton/MySwapsButton";
import MyMessagesButton from "./MyMessagesButton/MyMessagesButton";
import UserButton from "./UserButton/UserButton";
import LoginButton from "./LoginButton/LoginButton";
import RegisterButton from "./RegisterButton/RegisterButton";
import { toggleCreateOfferModal } from "../../store/actions/modal/modalActions";
import { ReactComponent as Marketplace } from "../../assets/images/svg/package.svg";
import { useTranslation } from "react-i18next";
import useIsTablet from "../../hooks/useIsTablet";
import { clearFilters } from "../../store/actions/filters/filtersActions";
import { selectLatestChats } from "../../store/selectors/chatSelectors";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";
import useIsMobile from "../../hooks/useIsMobile";

const Header = () => {
  const theme = useTheme();
  const searchRef = useRef(null);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector(selectUserId);
  const search = useSearch(() => {});
  const dispatch = useDispatch();
  const name = useSelector(selectProfileName);
  const profile = useSelector(selectMineProfile);
  const history = useHistory();
  const drawerRef = useRef(null);
  const [shouldShow, setShouldShow] = useState(true);
  const routeMatch = useRouteMatch();
  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();
  const [logoClicked, setLogoClicked] = useState(false);
  const [badge, setBadge] = useState(0);
  const { isLoggedIn } = useIsLoggedIn();
  const { t } = useTranslation();
  const allChats = useSelector(selectLatestChats);
  const seenLastChat = allChats[0]?.participants?.filter(
    (item) => item._id === user
  )[0]?.hasSeenLatestMessages;
  console.log(profile);

  useEffect(() => {
    if (seenLastChat === false) {
      setBadge((prevState) => prevState + 1);
    }
  }, [seenLastChat]);

  // Dont show header on auth routes(login, register, etc.) and admin routes
  useEffect(() => {
    if (isAuthRoute() || (isAdminRoute() && !isTablet)) setShouldShow(false);
    else setShouldShow(true);
  }, [routeMatch, isTablet]);

  // Fetch mine profile on loading home page
  useEffect(() => {
    if (user && user?.length > 0) {
      dispatch(fetchMineProfile());
    }
  }, []);
  useEffect(() => {
    if (logoClicked) {
      console.log("clicked");
      if (isAdminRoute()) {
        history.push({
          pathname: ADMIN_HOME_PAGE,
          state: {
            logo: true,
          },
        });
      } else {
        if (user) {
          history.push({
            pathname: HOME_PAGE,
            state: {
              logo: true,
            },
          });
        } else {
          console.log("odje");
          history.push({
            pathname: MARKETPLACE_PAGE,
            state: {
              logo: true,
            },
          });
        }
        if (searchRef?.current) searchRef.current.value = "";
      }
      setLogoClicked(false);
    }
  }, [logoClicked]);

  // Sets value into search input based on query string
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = search.searchString ?? "";
    }
  }, [search.searchString, searchRef.current]);

  // Handling search when user is on marketplace and when he is not
  const handleSearch = (value) => {
    // search.searchOffersImmediately(value);
    if (!isLoggedIn) {
      search.searchOffersImmediately(value);
      if (history.location.pathname.includes("/offer")) {
        const newQueryString = new URLSearchParams({ search: value });
        history.push({
          pathname: MARKETPLACE_PAGE,
          search: newQueryString.toString(),
        });
      }
    }
    if (isAdminRoute()) {
      search.setSearchStringManually(value);
    } else {
      if (history.location.pathname.includes("/offer")) {
        const newQueryString = new URLSearchParams({ search: value });
        history.push({
          pathname: HOME_PAGE,
          search: newQueryString.toString(),
        });
      }
      if (!routeMatches(HOME_PAGE) && !routeMatches(BASE_PAGE)) {
        const newQueryString = new URLSearchParams({ search: value });
        history.replace({
          search: newQueryString.toString(),
        });
      } else {
        search.searchOffersImmediately(value);
      }
    }
  };

  // When user clicks logo, he sends specific state so filters can be removed
  const handleLogoClick = () => {
    if (
      ((routeMatches(HOME_PAGE) || routeMatches(BASE_PAGE)) && user) ||
      routeMatches(MARKETPLACE_PAGE)
    ) {
      dispatch(clearFilters());
    }
    setLogoClicked(true);
  };

  const handleAddOfferClick = () => {
    dispatch(toggleCreateOfferModal());
  };

  if (!shouldShow) {
    return <></>;
  }

  console.log("asdasdasdasd", routeMatches(BASE_PAGE));

  return (
    <HeaderContainer>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: "white",
          zIndex: "80",
          height: "72px",
          borderBottom: "1px solid #e4e4e4",
        }}
      >
        <Toolbar sx={{ p: "15px" }}>
          <ToolsContainer>
            <LogoContainer onClick={() => handleLogoClick()}>
              {isMobile ? <LogoImage /> : <LogoHorizontal />}
            </LogoContainer>

            {!history.location.pathname.includes("messages") &&
            !history.location.pathname.includes("profiles") ? (
              <SearchInput
                ref={searchRef}
                handleSearch={handleSearch}
                user={user}
              />
            ) : (
              <></>
            )}
            {(routeMatches(ABOUT_PAGE) ||
              (!user &&
                (routeMatches(HOME_PAGE) || routeMatches(BASE_PAGE)))) && (
              <AboutHeader />
            )}

            {user ? (
              <ToolsButtonsContainer
                mobile={matches}
                shrink={routeMatches(ABOUT_PAGE)}
                isTablet={isTablet}
              >
                {matches ? (
                  <>
                    <SmallScreenMessagesContainer>
                      {isTablet && (
                        <MyMessagesButton badge={badge} setBadge={setBadge} />
                      )}
                    </SmallScreenMessagesContainer>
                    <OpenDrawerButton
                      onClick={drawerRef.current?.handleToggleDrawer}
                    />
                  </>
                ) : (
                  <React.Fragment>
                    {!routeMatches(ABOUT_PAGE) && (
                      <>
                        <AddOfferButton onClick={handleAddOfferClick} />
                        <MySwapsButton
                          handleAddOfferClick={handleAddOfferClick}
                        />
                        <MyMessagesButton badge={badge} setBadge={setBadge} />
                      </>
                    )}
                    <UserButton name={name} profile={profile} />
                  </React.Fragment>
                )}
              </ToolsButtonsContainer>
            ) : (
              <AuthButtonsContainer
                mobile={matches}
                basePage={routeMatches(BASE_PAGE) || routeMatches(HOME_PAGE)}
              >
                {matches ? (
                  <OpenDrawerButton
                    onClick={drawerRef.current?.handleToggleDrawer}
                  />
                ) : (
                  <React.Fragment>
                    {routeMatches(ABOUT_PAGE) ||
                    routeMatches(HOME_PAGE) ||
                    routeMatches(BASE_PAGE) ? (
                      <MarketplaceLinkRouteContainer>
                        <MarketplaceLinkRoute onClick={() => handleLogoClick()}>
                          {t("admin.navigation.marketplace")}
                        </MarketplaceLinkRoute>
                        <Marketplace />
                      </MarketplaceLinkRouteContainer>
                    ) : (
                      <>
                        <LoginButton />
                        <RegisterButton />
                      </>
                    )}
                  </React.Fragment>
                )}
              </AuthButtonsContainer>
            )}
          </ToolsContainer>
        </Toolbar>
      </AppBar>

      <DrawerContainer ref={drawerRef} />
    </HeaderContainer>
  );
};

Header.propTypes = {
  isGrid: PropTypes.bool,
  showModalHandler: PropTypes.func,
  manualSearch: PropTypes.func,
};

export default Header;

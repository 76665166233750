import React from "react";
import PropTypes from "prop-types";
import {
  ProfileCardWrapper,
  ProfileCardContainer,
  ProfileCardHeader,
  HeaderTitle,
  ProfileInfoContainer,
  ProfileInfoAndContactContainer,
  BlockedProfileContainer,
} from "./ProfileCard.styled";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useRouteMatch } from "react-router-dom";
import { fetchProfile } from "../../../store/actions/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectProfile } from "../../../store/selectors/profileSelectors";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { fetchProfileOffers } from "../../../store/actions/offers/offersActions";
import ProfileMainInfo from "./ProfileMainInfo/ProfileMainInfo";
import ProfileContact from "./ProfileContact/ProfileContact";
import ProfileStats from "./ProfileStats/ProfileStats";
import { useTranslation } from "react-i18next";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { PROFILE_SCOPE } from "../../../store/actions/profile/profileActionConstants";
import SkeletonProfileCard from "./SkeletonProfileCard/SkeletonProfileCard";
import { useMemo } from "react";
import companyData from "../../../notFoundData/companyData";
import history from "../../../store/utils/history";
import { HOME_PAGE } from "../../../constants/pages";
import useIsMobile from "../../../hooks/useIsMobile";
import BlockedProfile from "./BlockedProfile/BlockedProfile";
import ProfileControl from "./ProfileControl/ProfileControl";

const ProfileCard = (props) => {
  const isLoading = useSelector(selectIsLoadingByActionType(PROFILE_SCOPE));
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const profileFromRedux = useSelector(selectProfile);
  const userId = useSelector(selectUserId);
  const profileId = useMemo(() => {
    return routeMatch.params.profileId;
  }, [routeMatch.params.profileId]);
  const { t } = useTranslation();

  const profile = useMemo(() => {
    if (profileFromRedux) {
      if (profileFromRedux._id === routeMatch.params.profileId) {
        return profileFromRedux;
      }
    }
    return companyData;
  }, [profileFromRedux]);

  const isMyProfile = useMemo(
    () => userId === profileId && !props.isAdmin,
    [userId, profileId]
  );

  useEffect(() => {
    if (profileId?.length > 0) {
      reFetchProfile();
    }
  }, [profileId]);
  useEffect(() => {
    if (profile && isMyProfile !== undefined) {
      if (!isMyProfile && profile._blocked && !props.isAdmin)
        history.push(HOME_PAGE);
    }
  }, [profile, isMyProfile, props.isAdmin]);

  const reFetchProfile = () => {
    dispatch(fetchProfile(profileId));
    dispatch(fetchProfileOffers(profileId));
  };

  let numberOfExchanges;
  if (
    profile?.statistics?.exchanges?.succeededExchanges &&
    profile?.statistics?.exchanges?.failedExchanges
  ) {
    numberOfExchanges =
      profile?.statistics?.exchanges?.failedExchanges +
      profile?.statistics?.exchanges?.succeededExchanges;
  } else if (profile?.statistics?.exchanges?.succeededExchanges) {
    numberOfExchanges = profile?.statistics?.exchanges?.succeededExchanges;
  } else {
    numberOfExchanges = 0;
  }

  let percentOfSucceededExchanges;
  if (
    profile?.statistics?.exchanges?.succeededExchanges &&
    profile?.statistics?.exchanges?.failedExchanges
  ) {
    percentOfSucceededExchanges = Math.round(
      profile?.statistics?.exchanges?.succeededExchanges /
        ((profile?.statistics?.exchanges?.succeededExchanges +
          profile?.statistics?.exchanges?.failedExchanges) /
          100)
    );
  } else if (profile?.statistics?.exchanges?.succeededExchanges) {
    percentOfSucceededExchanges = 100;
  } else {
    percentOfSucceededExchanges = 0;
  }

  let percentOfSucceededCommunication;
  if (
    profile?.statistics?.exchanges?.succeededCommunication &&
    profile?.statistics?.exchanges?.failedCommunication
  ) {
    percentOfSucceededCommunication = Math.round(
      profile?.statistics?.exchanges?.succeededCommunication /
        ((profile?.statistics?.exchanges?.succeededCommunication +
          profile?.statistics?.exchanges?.failedCommunication) /
          100)
    );
  } else if (profile?.statistics?.exchanges?.succeededCommunication) {
    percentOfSucceededCommunication = 100;
  } else {
    percentOfSucceededCommunication = 0;
  }

  let verifiedUser =
    numberOfExchanges >= 20 && percentOfSucceededCommunication >= 90;

  return (
    <>
      {isLoading ? (
        <SkeletonProfileCard />
      ) : (
        <>
          <ProfileCardContainer isAdmin={props.isAdmin}>
            <ProfileCardHeader>
              <PersonOutlineIcon color="action" sx={{ mr: 0.9 }} />
              <HeaderTitle>
                {isMyProfile
                  ? t("profile.myProfile")
                  : t("profile.companyProfile")}
              </HeaderTitle>
            </ProfileCardHeader>
            <ProfileCardWrapper
              variant="outlined"
              isAdmin={props.isAdmin}
              isMyProfile={isMyProfile}
              blocked={!props.isAdmin && profile?._blocked}
            >
              {profile?._blocked && isMobile && props.isAdmin && (
                <BlockedProfileContainer>
                  <BlockedProfile hideIcon redText aboveTitle isAdmin />
                </BlockedProfileContainer>
              )}
              {/* Profile Control (edit, remove, block, unblock) */}
              <ProfileControl
                profile={profile}
                isMobile={isMobile}
                isAdmin={props.isAdmin}
                isMyProfile={isMyProfile}
                reFetchProfile={reFetchProfile}
              />
              <ProfileInfoContainer>
                <ProfileInfoAndContactContainer
                  isAdmin={props.isAdmin}
                  bigProfileCard={props.bigProfileCard}
                >
                  {profile?._blocked && !props.isAdmin && isMobile && (
                    <BlockedProfile aboveTitle isAdmin={props.isAdmin} />
                  )}

                  {/* Profile Main Info  */}
                  <ProfileMainInfo
                    isAdmin={props.isAdmin}
                    profile={profile}
                    isMyProfile={isMyProfile}
                    isBlocked={!props.isAdmin && profile?._blocked}
                    verifiedUser={verifiedUser}
                  />
                  {/* Profile Contact */}
                  <ProfileContact
                    profile={profile}
                    isAdmin={props.isAdmin}
                    isMyProfile={
                      (!props.isAdmin && profile?._blocked) || isMyProfile
                    }
                    isBlocked={!props.isAdmin && profile?._blocked}
                  />
                </ProfileInfoAndContactContainer>
                {/* Profile Stats */}
                <ProfileStats
                  profile={profile}
                  numberOfExchanges={numberOfExchanges}
                  percentOfSucceededExchanges={percentOfSucceededExchanges}
                  isBlocked={!props.isAdmin && profile?._blocked}
                  percentOfSucceededCommunication={
                    percentOfSucceededCommunication
                  }
                />
              </ProfileInfoContainer>
            </ProfileCardWrapper>
          </ProfileCardContainer>
        </>
      )}
    </>
  );
};

ProfileCard.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
  bigProfileCard: PropTypes.bool,
};

export default ProfileCard;

import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import loadingReducer from "./loading/loadingReducer";
import userReducer from "./user/userReducer";
import storage from "redux-persist/lib/storage";
import createFilter from "redux-persist-transform-filter";
import persistReducer from "redux-persist/es/persistReducer";
import filtersReducer from "./filters/filtersReducer";
import offersReducer from "./offers/offersReducer";
import categoriesReducer from "./categories/categoriesReducer";
import locationsReducer from "./locations/locationsReducer";
import profileReducer from "./profile/profileReducer";
import chatReducer from "./chat/chatReducer";
import queryStringReducer from "./queryString/queryStringReducer";
import exchangeReducer from "./exchange/exchangeReducer";
import reviewReducer from "./review/reviewReducer";
import appReducer from "./app/appReducer";
import modalReducer from "./modal/modalReducer";
import paymentReducer from "./payment/paymentReducer";

const loginPersistConfig = {
  key: "login",
  storage: storage,
  transform: [createFilter("login", ["email", "token"])],
};

const userPersistConfig = {
  key: "user",
  storage: storage,
  transform: [createFilter("user", ["user"])],
};

const categoriesPersistConfig = {
  key: "categories",
  storage: storage,
  transform: [createFilter("categories", ["_id", "name", "subcategories"])],
};

const locationsPersistConfig = {
  key: "locations",
  storage: storage,
  transform: [createFilter("locations", ["_id", "city"])],
};
const paymentsPersistConfig = {
  key: "payment",
  storage: storage,
  transform: [createFilter("payment", ["_id", "payerName"])],
};
const profilePersistConfig = {
  key: "profile",
  storage: storage,
  transform: [createFilter("profile", ["profile"])],
};
const chatPersistConfig = {
  key: "chat",
  storage: storage,
  transform: [createFilter("chat", ["latestChats"])],
};

export default combineReducers({
  login: persistReducer(loginPersistConfig, loginReducer),
  user: persistReducer(userPersistConfig, userReducer),
  loading: loadingReducer,
  filters: filtersReducer,
  offers: offersReducer,
  categories: persistReducer(categoriesPersistConfig, categoriesReducer),
  locations: persistReducer(locationsPersistConfig, locationsReducer),
  payment: persistReducer(paymentsPersistConfig, paymentReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  chat: persistReducer(chatPersistConfig, chatReducer),
  queryString: queryStringReducer,
  exchange: exchangeReducer,
  review: reviewReducer,
  app: appReducer,
  modal: modalReducer,
});

import React from "react";
import PropTypes from "prop-types";
import {
  LittleOfferCardContainer,
  OfferCategory,
  OfferCategoryIcon,
  OfferDetails,
  OfferImage,
  OfferName,
  OfferSwapsIcon,
  OfferSwapsIconContainer,
} from "./LittleOfferCard.styled";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";

const LittleOfferCard = (props) => {
  const { isMobile } = useIsMobile();
  return (
    <LittleOfferCardContainer>
      <OfferImage
        src={getImageUrl(props.image, variants.reviewCard, isMobile)}
      />
      <OfferDetails>
        <OfferName>{props.name}</OfferName>
        <OfferCategory>
          <OfferCategoryIcon />
          {props.categoryName}
        </OfferCategory>
      </OfferDetails>
      <OfferSwapsIconContainer>
        <OfferSwapsIcon />
      </OfferSwapsIconContainer>
    </LittleOfferCardContainer>
  );
};

LittleOfferCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  categoryName: PropTypes.string,
};

export default LittleOfferCard;

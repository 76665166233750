import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as X } from "../../../assets/images/svg/plus.svg";
import UserReviewsSingleCard from "../../Cards/UserReviewsCard/UserReviewsSingleCard/UserReviewsSingleCard";

export const DeleteReviewContainer = styled(Box)`
  position: fixed;
  top: 50%;
left: 50%;
transform: translate(-50%, -50%);
  width: 623px;
  min-height: 492px;
  background-color: white;
  z-index: 150;
  padding: 37px;
  & > div {
    margin: 0 auto;
    background-color: ${selectedTheme.colors.skeletonItemColor};
    padding: 18px;
    border: 1px solid ${selectedTheme.colors.borderNormal};
    border-radius: 2px;
  }
  & > div::after {
    border: 0;
  }
  @media (max-width: 600px) {
    padding: 36px 28px;
    width: 100vw;
    min-height: 0;
    height: 100vh;
  }
`;
export const DeleteReviewTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 36px;
  color: ${selectedTheme.colors.primaryPurple};
  @media (max-width: 600px) {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;
export const XIcon = styled(X)`
  transform: rotate(45deg);
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  & path {
    stroke: ${selectedTheme.colors.messageText};
    /* stroke-width: 2; */
  }
  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
    top: 40px;
    right: 18px;
  }
`;
export const ReviewCard = styled(UserReviewsSingleCard)`
  width: 335px;
`;

import { COUNTER_INCREASE, COUNTER_INCREASE_ERROR, COUNTER_INCREASE_SUCCESS } from "./counterActionConstants";

export const increaseCounter = (payload) => ({
    type: COUNTER_INCREASE,
    payload,
})
export const increaseCounterSuccess = () => ({
    type: COUNTER_INCREASE_SUCCESS
})
export const increaseCounterError = () => ({
    type: COUNTER_INCREASE_ERROR
})
import React from "react";
import PropTypes from "prop-types";
import {
  HeaderTitleContainer,
  HeaderTitleText,
  OffersIcon,
} from "./HeaderTitle.styled";
import { useTranslation } from "react-i18next";

const HeaderTitle = (props) => {
  const { t } = useTranslation();
  return (
    <HeaderTitleContainer container>
      <OffersIcon />
      <HeaderTitleText>
        {props.isMyProfile ? t("profile.myOffers") : t("profile.profileOffers")}
      </HeaderTitleText>
    </HeaderTitleContainer>
  );
};

HeaderTitle.propTypes = {
  isMyProfile: PropTypes.bool,
};

export default HeaderTitle;

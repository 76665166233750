import React from "react";
import {
  SidebarContainer,
  SidebarContent,
  ButtonsContainer,
} from "./Sidebar.styled";
import SidebarHeader from "./SidebarHeader/SidebarHeader";
import SidebarProfile from "./SidebarProfile/SidebarProfile";
import SidebarNavigation from "./SidebarNavigation/SidebarNavigation";
import MarketplaceButton from "./MarketplaceButton/MarketplaceButton";
import LogoutButton from "./LogoutButton/LogoutButton";

const Sidebar = () => {
  return (
    <SidebarContainer>
      <SidebarHeader />
      <SidebarContent>
        <SidebarProfile />
        <SidebarNavigation />
        <ButtonsContainer>
          <MarketplaceButton />
          <LogoutButton />
        </ButtonsContainer>
      </SidebarContent>
    </SidebarContainer>
  );
};

export default Sidebar;

import { PAYMENTS_SET } from "../../actions/payment/paymentActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  payments: [],
};

export default createReducer(
  {
    [PAYMENTS_SET]: setPayments,
  },
  initialState
);

function setPayments(state, action) {
  return {
    ...state,
    payments: action.payload,
  };
}

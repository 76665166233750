import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { Label } from "../../CheckBox/Label";
import ImagePicker from "../../ImagePicker/ImagePicker";
import { TextField } from "../../TextFields/TextField/TextField";
import { ReactComponent as X } from "../../../assets/images/svg/plus.svg";

export const EditCategoryContainer = styled(Box)`
  position: fixed;
  width: 623px;
  /* height: ${(props) => (props.hideImagePicker ? "296px" : "510px")}; */
  top: ${(props) =>
    props.hideImagePicker ? "calc(50vh - 148px)" : "calc(50vh - 146.5px)"};
  left: calc(50vw - 311px);

  background: white;
  z-index: 150;
  & > * {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 600px) {
    width: 350px;
    /* height: ${(props) => (props.hideImagePicker ? "207px" : "412px")}; */
    left: calc(50vw - 175px);
    top: ${(props) =>
      props.hideImagePicker ? "calc(50vh - 103px)" : "calc(50vh - 111px)"};
  }
`;
export const EditCategoryTitle = styled(Typography)`
  display: block;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin-top: 36px;
  width: 256px;
  color: ${selectedTheme.colors.primaryPurple};
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 18px;
  }
`;
export const EditCategoryImagePicker = styled(ImagePicker)`
  background: none;
  margin-top: 36px;
  background: ${selectedTheme.colors.primaryIconBackgroundColor};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%235A3984FF' stroke-width='2' stroke-dasharray='7%2c 12' stroke-dashoffset='44' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  left: calc(50% - 72px);
  margin-bottom: 18px;
  @media (max-width: 600px) {
    margin-top: 27px;
  }
`;
export const SupportedFormats = styled(Typography)`
  font-size: 13px;
  width: 100%;
  text-align: center;
  font-family: ${selectedTheme.fonts.textFont};
  @media (max-width: 600px) {
    font-size: 13px;
  }
`;
export const CategoryTitleField = styled(TextField)`
  width: 375px;
  margin-bottom: 36px;

  @media (max-width: 600px) {
    margin-bottom: 0;
    & div div input {
      font-size: 12px !important;
    }
    & div {
      height: 40px;
      width: 314px;
    }
  }
`;
export const FieldLabel = styled(Label)`
  position: relative;
  bottom: -14px;
  width: 376px;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
  }
  @media (max-width: 600px) {
    & label {
      font-size: 9px;
    }
  }
`;
export const InputContainer = styled(Box)`
  display: block;
  width: fit-content;
  margin-top: 22px;

  @media (max-width: 600px) {
    width: 314px;
    height: 56px;
    margin-top: 27px;
    position: relative;
    top: -18px;
    ${(props) =>
      !props.hideImagePicker &&
      `
      margin-top: 25px;
    `}
  }
`;
export const SaveButton = styled(PrimaryButton)`
  max-width: 376px;
  width: ${(props) => (props.showSecondButton ? "180px" : "376px")};
  height: 48px;
  & button {
    letter-spacing: 1.5px;
  }
  @media (max-width: 600px) {
    height: 45px;
    width: ${(props) => (props.showSecondButton ? "149px" : "314px")};
  }
`;
export const XIcon = styled(X)`
  transform: rotate(45deg);
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  & path {
    stroke: ${selectedTheme.colors.messageText};
    stroke-width: 2;
  }
  @media (max-width: 600px) {
    top: 18px;
    right: 18px;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin: 36px auto;
  @media (max-width: 600px) {
    margin-top: 18px;
  }
`;

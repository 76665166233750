import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { EndIcon, SearchIcon, SearchInput } from "./SearchField.styled";
import useSearch from "../../../hooks/useOffers/useSearch";

const SearchField = (props) => {
  const searchRef = useRef(null);
  const search = useSearch(() => {});
  let listener = useCallback(
    (event) => {
      // Event keycode 13 = ENTER keycode
      if (event.keyCode === 13) {
        event.preventDefault();
        handleSearch();
      }
    },
    [searchRef.current]
  );
  useEffect(() => {
    searchRef.current.value = search.searchString || "";
  }, []);
  const handleFocusSearch = () => {
    searchRef.current.addEventListener("keyup", listener);
  };
  const handleBlurSearch = () => {
    searchRef.current.removeEventListener("keyup", listener);
  };
  const handleSearch = () => {
    if (props.isAdmin) {
      if (props.handleSearch) props.handleSearch(searchRef.current.value);
      else search.searchOffersImmediately(searchRef.current.value);
    } else {
      props.searchMyOffers(searchRef.current.value);
      props.handleSearch();
    }
  };
  return (
    <SearchInput
      isAdmin={props.isAdmin}
      fullWidth={props.fullWidth}
      InputProps={{
        endAdornment: (
          <EndIcon size="36px">
            <SearchIcon onClick={() => handleSearch(searchRef.current.value)} />
          </EndIcon>
        ),
      }}
      placeholder={props.placeholder}
      onFocus={handleFocusSearch}
      onBlur={handleBlurSearch}
      ref={searchRef}
      className={props.className}
      onChange={handleSearch}
    />
  );
};

SearchField.propTypes = {
  children: PropTypes.node,
  searchMyOffers: PropTypes.func,
  handleSearch: PropTypes.func,
  isAdmin: PropTypes.bool,
  offers: PropTypes.any,
  isUsers: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
SearchField.defaultProps = {
  fullWidth: true,
};

export default SearchField;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { Label } from "../../CheckBox/Label";
import Option from "../../Select/Option/Option";
import Select from "../../Select/Select";

export const FirstStepCreateReviewContainer = styled(Box)`
  text-align: center;
  padding: 36px;
  @media (max-width: 600px) {
    padding: 0;
    padding-top: 36px;
    flex: 1;
  }
`;
export const CreateReviewTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 36px;
  @media (max-width: 600px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;
export const ProfileImageContainer = styled(Box)`
  width: 108px;
  height: 108px;
  overflow: hidden;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
`;
export const ProfileImage = styled.img`
  width: 108px;
  height: 108px;
  overflow: hidden;
  border-radius: 100%;
`;
export const ProfileName = styled(CreateReviewTitle)`
  padding-top: 0;
  padding-bottom: 14px;
`;
export const FieldLabel = styled(Label)`
  position: relative;
  bottom: -14px;
  width: 100%;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
    text-align: left;
  }
  @media (max-width: 600px) {
    & label {
      font-size: 10px;
    }
  }
`;
export const SelectField = styled(Select)`
  position: relative;
  top: 15px;
  margin-bottom: 18px;
  height: 48px;
  text-align: left;
  ${props => props.exchange && `background-color: ${selectedTheme.colors.backgroundSponsoredColor};`}
  @media (max-width: 600px) {
    height: 40px;
    font-size: 14px;
    margin-bottom: 18px;
  }
`;
export const SelectOption = styled(Option)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 12px;
    height: 35px !important;
    min-height: 35px;
  }
`;


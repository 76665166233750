import React from "react";
import PropTypes from "prop-types";
import {
  MessageCardContainer,
  MessageContent,
  MessageDate,
  MessageText,
  ProfileImage,
} from "./MessageCard.styled";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import { getMessageDate } from "../../../util/helpers/dateHelpers";

const MessageCard = (props) => {
  const message = props.message;
  const { isMobile } = useIsMobile();

  const dateString = getMessageDate(new Date(message._created));
  return (
    <MessageCardContainer ismymessage={props.isMyMessage}>
      <ProfileImage
        src={getImageUrl(props.image, variants.chatMessage, isMobile)}
      />
      <MessageContent ismymessage={props.isMyMessage}>
        <MessageText ismymessage={props.isMyMessage}>
          {props.message.text}
        </MessageText>
        <MessageDate ismymessage={props.isMyMessage}>{dateString}</MessageDate>
      </MessageContent>
    </MessageCardContainer>
  );
};

MessageCard.propTypes = {
  children: PropTypes.node,
  message: PropTypes.any,
  image: PropTypes.string,
  isMyMessage: PropTypes.bool,
};

export default MessageCard;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchOffers } from "../../store/actions/offers/offersActions";
import { setQueryString } from "../../store/actions/queryString/queryStringActions";
import { selectQueryString } from "../../store/selectors/queryStringSelectors";
import {
  convertQueryStringForBackend,
  convertQueryStringForFrontend,
  getQueryObjectHelper,
} from "../../util/helpers/queryHelpers";

const useQueryString = () => {
  const queryString = useSelector(selectQueryString);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isInitiallyLoaded, setIsInitallyLoaded] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [historyStateCleared, setHistoryStateCleared] = useState(true);

  // Initially read filters, sorting and paging from querystring
  useEffect(() => {
    if (
      (!isInitiallyLoaded || history.location?.state?.logo) &&
      !history.location?.state?.from
    ) {
      const queryStringFromUrl = history.location?.search;
      setQueryObject(getQueryObjectHelper(queryStringFromUrl));
      dispatch(setQueryString(queryStringFromUrl));
    }
    if (history.location?.state?.logo) {
      setHistoryStateCleared(false);
    } else {
      setHistoryStateCleared(true);
    }
  }, [history.location]);

  // Set initially loaded to true on initial load
  useEffect(() => {
    if (
      convertQueryStringForFrontend(queryString) ===
        convertQueryStringForFrontend(history.location.search) &&
      !isInitiallyLoaded
    ) {
      setIsInitallyLoaded(true);
    }
  }, [queryString]);

  // Updating offers on query string change
  useEffect(() => {
    if (isInitiallyLoaded) {
      dispatch(
        fetchOffers({ queryString: convertQueryStringForBackend(queryString) })
      );
      setQueryObject(getQueryObjectHelper(queryString));
      history.replace({
        search: convertQueryStringForFrontend(queryString),
      });
    }
  }, [queryString, isInitiallyLoaded]);

  return {
    queryString,
    queryObject,
    isInitiallyLoaded,
    historyStateCleared,
  };
};
export default useQueryString;

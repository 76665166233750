import React from "react";
import PropTypes from "prop-types";
import {
  ColorPickerContainer,
  ColorPickerLabel,
  ColorPickerName,
  ColorPickerNameContainer,
  ColorsContainer,
} from "./ColorPicker.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";
import ColorIcon from "../../RichTextComponent/ColorIcon/ColorIcon";

const ColorPicker = (props) => {
  const { t } = useTranslation();
  const handleClickColor = (singleColor) => {
    props?.handleClickColor({
      color: selectedTheme.colors.colorPicker[singleColor],
      colorName: singleColor,
    });
  };
  return (
    <ColorPickerContainer>
      <ColorPickerNameContainer>
        <ColorPickerLabel>{t("colorPicker.label")}</ColorPickerLabel>
        <ColorPickerName>
          {t(`colorPicker.${props.selectedColorName}`)}
        </ColorPickerName>
      </ColorPickerNameContainer>
      <ColorsContainer>
        {Object.keys(selectedTheme.colors.colorPicker).map((singleColor) => {
          if (singleColor === "border") return;
          return (
            <ColorIcon
              selected={singleColor === props?.selectedColorName}
              selectedInPopover={singleColor === props?.selectedColorName}
              color={selectedTheme.colors.colorPicker[singleColor]}
              key={singleColor}
              onClick={() => handleClickColor(singleColor)}
            />
          );
        })}
      </ColorsContainer>
    </ColorPickerContainer>
  );
};

ColorPicker.propTypes = {
  selectedColorName: PropTypes.string,
  selectedColor: PropTypes.string,
  handleClickColor: PropTypes.func,
};

export default ColorPicker;

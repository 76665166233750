import React, { useState } from "react";
import {
  CheckBoxStyled,
  CheckBoxContainer,
  FormControlLabelStyled,
} from "./CheckBox.styled";
import PropTypes from "prop-types";
import { Label } from "./Label";
import selectedTheme from "../../themes";

export const CheckBox = (props) => {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    if (props.onChange) props.onChange(!checked);
    setChecked((prevState) => !prevState);
  };

  return (
    <CheckBoxContainer
      style={props.containerStyle}
      fullWidth={props.fullWidth}
      className={props.className}
    >
      <FormControlLabelStyled
        fullwidth={props.fullWidth ? 1 : 0}
        control={
          <CheckBoxStyled
            sx={props.checkBoxStyle}
            boxcolor={props.color}
            checked={props.checked}
            onClick={handleClick}
            value={props.value}
          />
        }
        label={
          <Label
            sx={props.labelStyle}
            onClick={handleClick}
            leftText={props.leftText}
            rightText={props.rightText}
            maxWidth={props.maxWidth}
          />
        }
      />
    </CheckBoxContainer>
  );
};

CheckBox.propTypes = {
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  name: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  containerStyle: PropTypes.any,
  checkBoxStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  className: PropTypes.string,
};

CheckBox.defaultProps = {
  color: selectedTheme.colors.primaryPurple,
};

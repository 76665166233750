import i18next from "i18next";
import defaultImage from "../constants/defaultImage";

export default {
        category: {
            name: i18next.t("notFoundData.categoryName")
        },
        condition: i18next.t("notFoundData.condition"),
        description: i18next.t("notFoundData.description"),
        images: [defaultImage],
        location: {
            city: i18next.t("notFoundData.location")
        },
        name: i18next.t("notFoundData.offerName"),
        pinned: false,
        subcategory: i18next.t("notFoundData.subcategoryName"),
        user: {
            company: i18next.t("notFoundData.companyName")
        },
        views: {
            count: 0
        },
        _created: new Date().toString()
}
import { all, takeLatest, put } from "@redux-saga/core/effects";
import { convertQueryStringForBackend } from "../../util/helpers/queryHelpers";
// import { combineQueryStrings } from "../../util/helpers/queryHelpers";
import { QUERY_STRING_SET } from "../actions/queryString/queryStringActionConstants";
import { setQueryStringRedux } from "../actions/queryString/queryStringActions";
// import { selectQueryString } from "../selectors/queryStringSelectors";

function* setQueryString(payload) {
  try {
    // const currentQS = yield select(selectQueryString);
    // let newQueryString = payload.payload
    // if (currentQS?.length > 0) {
    //   newQueryString = yield call(
    //     combineQueryStrings,
    //     currentQS,
    //     payload.payload,
    //   );
    // }
    const newQueryString = convertQueryStringForBackend(payload.payload);
    yield put(setQueryStringRedux(newQueryString));
  } catch (e) {
    console.dir(e);
  }
}

export default function* queryStringSaga() {
  yield all([takeLatest(QUERY_STRING_SET, setQueryString)]);
}

import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";

export const ReviewContainer = styled(Box)`
  padding-top: 18px;
  padding-right: 12px;
  position: relative;
  @media (max-width: 600px) {
    height: ${props => props.hasGivenReview ? "323px" : "258px"};
  }
  ${(props) =>
    !props.lastReview &&
    css`
      &::after {
        content: "";
        display: block;
        border-bottom: 1px solid
          ${selectedTheme.colors.primaryIconBackgroundColor};
        width: calc(100% + 24.4px);
        height: 1px;
        position: relative;
        left: -18px;
        margin-top: 18px;
      }
    `}
`;

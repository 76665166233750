import { Box } from "@mui/material";
import styled from "styled-components";
import exchangeStatus from "../../../constants/exchangeStatus";
import selectedTheme from "../../../themes";

export const DirectChatContentContainer = styled(Box)`
  border: 1px solid ${selectedTheme.colors.borderNormal};
  margin-top: 18px;
  border-radius: 4px;
  min-height: 600px;
  background-color: white;
  position: relative;
`;
export const MessagesList = styled(Box)`
  padding: 27px 18px;
  position: relative;
  max-height: 425px;
  height: ${(props) =>
    props.exchangeState === exchangeStatus.ACCEPTED ||
    props.exchangeState === exchangeStatus.I_OFFERED ||
    props.exchangeState === exchangeStatus.REVIEWED
      ? "385px"
      : "425px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
  @media (max-width: 600px) {
    padding: 18px 18px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
`;
export const MessageContainer = styled(Box)``;

import React from "react";
import PropTypes from "prop-types";
import {
  DeleteReviewContainer,
  DeleteReviewTitle,
  ReviewCard,
  XIcon,
} from "./DeleteReview.styled";
import BackdropComponent from "../../MUI/BackdropComponent";
import { useTranslation } from "react-i18next";
import DeleteButton from "./DeleteButton/DeleteButton";
import { useDispatch } from "react-redux";
import { removeReview } from "../../../store/actions/review/reviewActions";
import { closeModal } from "../../../store/actions/modal/modalActions";

const DeleteReview = (props) => {
  const dispatch = useDispatch();
  const reviewId = props.review._id;
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const handleApiResponseSuccess = () => {
    closeModalHandler();
  };

  const deleteReviewHandler = () => {
    dispatch(removeReview({ reviewId, handleApiResponseSuccess }));
  };
  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeModalHandler}
        position="fixed"
      />
      <DeleteReviewContainer>
        <DeleteReviewTitle>{t("admin.review.title")}</DeleteReviewTitle>
        <ReviewCard
          deleteModal
          lastReview
          review={props.review}
        />
        <XIcon onClick={closeModalHandler} />
        <DeleteButton onClick={deleteReviewHandler} />
      </DeleteReviewContainer>
    </>
  );
};

DeleteReview.propTypes = {
  review: PropTypes.any,
  cardComponent: PropTypes.any,
};

export default DeleteReview;

import { Box } from "@mui/system";
import styled from "styled-components";

export const CategoryContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CategoryIcon = styled.img`
  margin-right: 13px;
`;

import React from "react";
import PropTypes from "prop-types";
import {
  FieldLabel,
  SelectField,
  SelectOption,
} from "../FirstStepCreateReview.styled";
import { reviewEnum } from "../../../../enums/reviewEnum";
import { useTranslation } from "react-i18next";

const SuccessfulSwapField = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FieldLabel leftText={t("reviews.hasExchangeSucceed").toUpperCase()} />
      <SelectField
        defaultValue={props.formik.values.exchangeSucceed}
        exchange
        onChange={(event) =>
          props.formik.setFieldValue("exchangeSucceed", event.target.value)
        }
      >
        {Object.keys(reviewEnum).map((property) => {
          if (property === "NOT_BAD") return;
          return (
            <SelectOption
              key={reviewEnum[property].value}
              value={reviewEnum[property].mainText}
            >
              {reviewEnum[property].mainText}
            </SelectOption>
          );
        })}
      </SelectField>
    </>
  );
};

SuccessfulSwapField.propTypes = {
  formik: PropTypes.any,
};

export default SuccessfulSwapField;

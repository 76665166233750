import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const SidebarProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const SidebarProfileImage = styled.img`
  width: 108px;
  height: 108px;
  border-radius: 100%;
`;

export const SidebarProfileName = styled(Typography)`
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  margin-top: 19px;
  color: ${selectedTheme.colors.primaryPurple};
`;

export const SidebarProfileRole = styled(Typography)`
  font-family: "DM Sans";
  font-size: 12px;
  color: ${selectedTheme.colors.primaryPurple};
`;
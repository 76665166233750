import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import FilterRadioDropdown from "../../FilterDropdown/Radio/FilterRadioDropdown";
import { CategoryChosenIcon, CategoryIcon } from "./CategoryChoser.styled";
import { useTranslation } from "react-i18next";

const firstCategoryOption = {
  label: "SVE KATEGORIJE",
  value: { _id: 0 },
};

const CategoryChoser = forwardRef((props, ref) => {
  const filters = props.filters;
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const handleSelectCategory = (category) => {
    filters.category.setSelectedCategory(category);
    filters.subcategory.setSelectedSubcategory({});
    props.offers.applyFilters();
  };
  useImperativeHandle(ref, () => ({
    closeSection: () => {
      setIsOpened(false);
    },
    isOpened: isOpened ? isOpened : false,
  }));

  useEffect(() => {
    if (
      (filters.category.selectedCategoryLocally &&
        !("_id" in filters.category.selectedCategoryLocally)) ||
      (filters.category.selectedCategoryLocally?._id === 0 && !isOpened)
    ) {
      setIsOpened(false);
    } else {
      setIsOpened(true);
    }
  }, [filters.category.selectedCategoryLocally]);
  return (
    <FilterRadioDropdown
      data={[...filters?.category.allCategories]}
      icon={
        filters.category.selectedCategoryLocally?.name ? (
          <CategoryChosenIcon />
        ) : (
          <CategoryIcon />
        )
      }
      title={
        filters.category.selectedCategoryLocally?.name
          ? filters.category.selectedCategoryLocally?.name
          : t("filters.categories.title")
      }
      searchPlaceholder={t("filters.categories.placeholder")}
      open={isOpened}
      handleOpen={() => setIsOpened((prevIsOpened) => !prevIsOpened)}
      setSelected={handleSelectCategory}
      selected={filters.category.selectedCategoryLocally}
      firstOption={firstCategoryOption}
      offers={props.offers}
    />
  );
});

CategoryChoser.displayName = "CategoryChoser";

CategoryChoser.propTypes = {
  filters: PropTypes.any,
  offers: PropTypes.any,
};

export default CategoryChoser;

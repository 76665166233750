import { Box } from "@mui/material";
import styled from "styled-components";
import Profile from "../../../../components/Profile/Profile";
import UserReviews from "../../../../components/UserReviews/UserReviews";

export const AdminSingleUserPageContainer = styled(Box)`
  margin: 0 20px;
  @media (max-width: 600px) {
    margin: 0;
    margin-top: 90px;
  }
`;
export const AdminSingleUserPageProfile = styled(Profile)`
  margin-bottom: 5px;

  h5 {
    pointer-events: none;
  }
`;
export const AdminSingleUserPageReviews = styled(UserReviews)`
  padding: 0;
  padding: 0 50px;
  padding-top: 30px;
  @media (max-width: 1200px) {
    padding-right: 33px;
    padding-left: 0;
  }
  @media (max-width: 600px) {
    /* padding: 0; */
    width: 100%;
    & > div {
      margin-top: 0;
      height: 500px;
      position: relative;
      top: -18px;
    }
  }
`;

import styled from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../../themes";
import HorizontalScroller from "../../../Scroller/HorizontalScroller";

export const ImagesCarouselContainer = styled(Box)`
  width: 756px;
  height: 774px;
  position: fixed;
  top: calc(50% - 387px);
  left: calc(50% - 378px);
  z-index: 150;
  background-color: #fff;
  padding: 0 18px 18px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 350px;
    height: 383px;
    overflow: hidden;
    top: calc(50% - 191.5px);
    left: calc(50% - 175px);
    padding-bottom: 36px;
  }
`;

export const ImagesCarouselHeader = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 24px;
  font-weight: 700;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const Scroller = styled(HorizontalScroller)`
  max-width: 100%;
  max-height: 576px;
  margin-top: 36px;
  margin-bottom: 38px;

  @media (max-width: 600px) {
    margin-bottom: 18px;
  }
`;

export const OfferImage = styled.img`
  min-width: 576px;
  min-height: 576px;
  margin-right: 23px;
  margin-left: 10px;
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: 600px) {
    min-width: 216px;
    min-height: 216px;
    margin-right: 18px;
    margin-left: 0;
  }
`;

export const Offer = styled.p`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`;

export const OfferSpan = styled.span`
  font-size: 16px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const CloseButton = styled(Box)`
  position: absolute;
  top: 42px;
  right: 42px;
  cursor: pointer;

  @media (max-width: 600px) {
    top: 38px;
    right: 21px;
    svg {
      width: 18px;
    }
  }
`;

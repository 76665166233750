import React from "react";
import PropTypes from "prop-types";
import { RemoveButtonContainer, RemoveIcon } from "./RemoveButton.styled";

const RemoveButton = (props) => {
  console.log(props)
  return (
    <RemoveButtonContainer
      isRemoved={props.isRemoved}
      onClick={props.onClick}
      hasGivenReview={props.hasGivenReview}
      isProfileReviews={props?.isProfileReviews}
    >
      <RemoveIcon isRemoved={props.isRemoved} />
    </RemoveButtonContainer>
  );
};

RemoveButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  hasGivenReview: PropTypes.bool,
  isRemoved: PropTypes.bool,
  isProfileReviews: PropTypes.any
};

export default RemoveButton;

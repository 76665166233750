import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const CHAT_SCOPE = "CHAT_SCOPE";
export const CHAT_HEADER_SCOPE = "CHAT_HEADER_SCOPE";
const CHAT_ONE_SCOPE = "CHAT_ONE_SCOPE";
const CHAT_SEND_SCOPE = "CHAT_SEND_SCOPE";
const CHAT_NEW_SCOPE = "CHAT_NEW_SCOPE";
const EXIST_CHAT_SCOPE = "EXIST_CHAT_SCOPE";

export const CHAT_FETCH = createFetchType(CHAT_SCOPE);
export const CHAT_FETCH_SUCCESS = createSuccessType(CHAT_SCOPE);
export const CHAT_FETCH_ERROR = createErrorType(CHAT_SCOPE);

export const CHAT_SEND_FETCH = createFetchType(CHAT_SEND_SCOPE);
export const CHAT_SEND_SUCCESS = createSuccessType(CHAT_SEND_SCOPE);
export const CHAT_SEND_ERROR = createErrorType(CHAT_SEND_SCOPE);

export const CHAT_HEADER_FETCH = createFetchType(CHAT_HEADER_SCOPE);
export const CHAT_HEADER_FETCH_SUCCESS = createSuccessType(CHAT_HEADER_SCOPE);
export const CHAT_HEADER_FETCH_ERROR = createErrorType(CHAT_HEADER_SCOPE);

export const CHAT_ONE_FETCH = createFetchType(CHAT_ONE_SCOPE);
export const CHAT_ONE_FETCH_SUCCESS = createSuccessType(CHAT_ONE_SCOPE);
export const CHAT_ONE_FETCH_ERROR = createErrorType(CHAT_ONE_SCOPE);

export const CHAT_NEW_FETCH = createFetchType(CHAT_NEW_SCOPE);
export const CHAT_NEW_FETCH_SUCCESS = createSuccessType(CHAT_NEW_SCOPE);
export const CHAT_NEW_FETCH_ERROR = createErrorType(CHAT_NEW_SCOPE);

export const CHAT_SET = createSetType("CHAT_SET");
export const CHAT_TOTAL_SET = createSetType("CHAT_TOTAL_SET");
export const CHAT_ONE_SET = createSetType("CHAT_ONE_SET");
export const CHAT_CLEAR = createSetType("CHAT_CLEAR");
export const CHAT_ADD_MESSAGE = createSetType("CHAT_ADD_MESSAGE");
export const CHAT_REMOVE_MESSAGE = createSetType("CHAT_REMOVE_MESSAGE");
// export const ADD_ONE_CHAT = "CHAT_ONE_ADD";

export const GET_EXIST_CHAT = createFetchType(EXIST_CHAT_SCOPE);
export const GET_EXIST_CHAT_SUCCESS = createSetType(EXIST_CHAT_SCOPE);
export const GET_EXIST_CHAT_ERROR = createSetType(EXIST_CHAT_SCOPE);

import React from "react";
import PropTypes from "prop-types";
import {
  StepProgressContainer,
  Progress,
  StepBar,
  StepLine,
} from "./StepProgress.styled";
import { ReactComponent as Checkmark } from "../../assets/images/svg/checkmark.svg";
import { useMemo } from "react";

const StepProgress = (props) => {
  const steps = useMemo(() => {
    let returnValue = [];
    for (let i = 1; i <= props.numberOfSteps; i++) {
      returnValue.push({
        done: i < props.current,
        current: i === props.current,
      });
    }
    return returnValue;
  }, [props.current]);

  return (
    <StepProgressContainer done current={props.current}>
      {steps.map((item, index) =>
        index === 0 ? (
          <StepBar
            current={item.current}
            done={item.done}
            key={index}
            onClick={
              item.done
                ? props.functions[index]
                : () => {}
            }
          >
            {item.done ? <Checkmark /> : index + 1}
          </StepBar>
        ) : (
          <React.Fragment key={index}>
            <StepLine done={item.done || item.current} lineColor={props.lineColor}>
              <Progress done={item.done || item.current} />
            </StepLine>
            <StepBar
              current={item.current}
              done={item.done}
              lineColor={props.lineColor}
              onClick={item.done ? props.functions[index] : () => {}}
            >
              {item.done ? <Checkmark /> : index + 1}
            </StepBar>
          </React.Fragment>
        )
      )}
    </StepProgressContainer>
  );
};

StepProgress.propTypes = {
  children: PropTypes.node,
  handleNext: PropTypes.node,
  current: PropTypes.number,
  numberOfSteps: PropTypes.number,
  functions: PropTypes.array,
  lineColor: PropTypes.string,
};
StepProgress.defaultProps = {
  functions: [],
  lineColor: "white"
};

export default StepProgress;

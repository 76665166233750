import React from "react";
import PropTypes from "prop-types";
import AdminButton from "./AdminButton/AdminButton";
import {
  CategoryIcon,
  DollarIcon,
  LocationIcon,
  MarketplaceIcon,
  UserIcon,
} from "./AdminButtons.styled";
import {
  ADMIN_CATEGORIES_PAGE,
  ADMIN_LOCATIONS_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_USERS_PAGE,
  HOME_PAGE,
} from "../../../../../constants/pages";
import { useTranslation } from "react-i18next";

const AdminButtons = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <AdminButton
        toggleDrawer={props.toggleDrawer}
        icon={<UserIcon />}
        title={t("admin.navigation.users")}
        route={ADMIN_USERS_PAGE}
      />
      <AdminButton
        toggleDrawer={props.toggleDrawer}
        icon={<CategoryIcon />}
        title={t("admin.navigation.categories")}
        route={ADMIN_CATEGORIES_PAGE}
      />
      <AdminButton
        toggleDrawer={props.toggleDrawer}
        icon={<LocationIcon />}
        title={t("admin.navigation.locations")}
        route={ADMIN_LOCATIONS_PAGE}
      />
      <AdminButton
        toggleDrawer={props.toggleDrawer}
        icon={<DollarIcon />}
        title={t("admin.navigation.payment")}
        route={ADMIN_PAYMENT_PAGE}
      />
      <AdminButton
        toggleDrawer={props.toggleDrawer}
        icon={<MarketplaceIcon />}
        title={t("admin.navigation.marketplace")}
        route={HOME_PAGE}
      />
    </>
  );
};

AdminButtons.propTypes = {
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
};

export default AdminButtons;

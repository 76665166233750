import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { TextField } from "../../../TextFields/TextField/TextField";

export const CommentFieldContainer = styled(TextField)`
  & * {
    font-family: ${selectedTheme.fonts.textFont};
    font-size: 16px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background: #777;
    }
    scrollbar-width: thin;
    scrollbar-color: #ddd;
    @media (max-width: 600px) {
      & * {
        font-size: 12px !important;
        /* line-height: 16px; */
      }
      & div {
        padding: 4px !important;
        padding-left: 8px !important;
      }
    }
  }
`;

import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroupContainer } from './RadioGroup.styled'

const RadioGroup = (props) => {
  return (
    <RadioGroupContainer onChange={props.onChange} value={props.value}>
        {props.children}
    </RadioGroupContainer>
  )
}

RadioGroup.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
}

export default RadioGroup
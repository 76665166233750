import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const HeaderContainer = styled(Box)`
  margin: 20px 0;

  @media (max-width: 600px) {
    margin-top: ${(props) => (!props.isPaymentRoute ? "28px" : "-28px")};
    margin-bottom: 18px;
    margin-left: ${(props) => (props.isAdmin ? "18px" : "0")};
  }
`;
export const ButtonContainer = styled(Link)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  text-decoration: none;
`;
export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
`;

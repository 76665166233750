import {
  CLOSE_MODAL,
  TOGGLE_CREATE_CATEGORY,
  TOGGLE_CREATE_OFFER,
  TOGGLE_CREATE_PAYMENT,
  TOGGLE_CREATE_REVIEW,
  TOGGLE_DELETE_CATEGORY,
  TOGGLE_DELETE_OFFER,
  TOGGLE_DELETE_PAYMENT,
  TOGGLE_DELETE_REVIEW,
  TOGGLE_EDIT_CATEOGRY,
  TOGGLE_EDIT_OFFER,
  TOGGLE_EDIT_PAYMENT,
  TOGGLE_EDIT_PROFILE,
} from "./modalActionConstants";

export const toggleCreateOfferModal = (payload) => ({
  type: TOGGLE_CREATE_OFFER,
  payload,
});
export const toggleEditOfferModal = (payload) => ({
  type: TOGGLE_EDIT_OFFER,
  payload,
});
export const toggleDeleteOfferModal = (payload) => ({
  type: TOGGLE_DELETE_OFFER,
  payload,
});
export const toggleCreateCategoryModal = (payload) => ({
  type: TOGGLE_CREATE_CATEGORY,
  payload,
});
export const toggleEditCategoryModal = (payload) => ({
  type: TOGGLE_EDIT_CATEOGRY,
  payload,
});
export const toggleDeleteCategoryModal = (payload) => ({
  type: TOGGLE_DELETE_CATEGORY,
  payload,
});
export const toggleCreateReviewModal = (payload) => ({
  type: TOGGLE_CREATE_REVIEW,
  payload,
});
export const toggleDeleteReviewModal = (payload) => ({
  type: TOGGLE_DELETE_REVIEW,
  payload,
});
export const toggleEditProfileModal = (payload) => ({
  type: TOGGLE_EDIT_PROFILE,
  payload,
});
export const toggleCreatePaymentModal = (payload) => ({
  type: TOGGLE_CREATE_PAYMENT,
  payload,
});
export const toggleEditPaymentModal = (payload) => ({
  type: TOGGLE_EDIT_PAYMENT,
  payload,
});
export const toggleDeletePaymentModal = (payload) => ({
  type: TOGGLE_DELETE_PAYMENT,
  payload,
});
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  CategoryCardContainer,
  CategoryCardDetailsContainer,
  CategoryCardLeftContainer,
  CategoryCardRightContainer,
} from "./CategoryCard.styled";
import CategoryCardName from "./CategoryCardName/CategoryCardName";
import CategoryDetail from "./CategoryDetail/CategoryDetail";
import CategoryCheckButton from "./CategoryCheckButton/CategoryCheckButton";
import CategoryEditButton from "./CategoryEditButton/CategoryEditButton";
import CategoryRemoveButton from "./CategoryRemoveButton/CategoryRemoveButton";
import { useTranslation } from "react-i18next";
import history from "../../../store/utils/history";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import { ADMIN_SUBCATEGORIES_PAGE } from "../../../constants/pages";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleDeleteCategoryModal,
  toggleEditCategoryModal,
  toggleEditPaymentModal,
} from "../../../store/actions/modal/modalActions";
import { selectAllProfiles } from "../../../store/selectors/profileSelectors";
import { fetchAllProfiles } from "../../../store/actions/profile/profileActions";
import { selectOffers } from "../../../store/selectors/offersSelectors";
import { fetchOffers } from "../../../store/actions/offers/offersActions";
import { formatDateLocale } from "../../../util/helpers/dateHelpers";

const CategoryCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profiles = useSelector(selectAllProfiles);
  const offers = useSelector(selectOffers);
  useEffect(() => {
    dispatch(fetchAllProfiles());
    dispatch(fetchOffers({ queryString: "" }));
  }, []);
  const company = useMemo(() => {
    if (profiles) {
      return profiles?.filter?.(
        (profile) => profile?._id === props?.category?.user?._id
      );
    } else {
      return [];
    }
  }, [profiles]);

  const offer = useMemo(() => {
    if (offers) {
      return offers?.filter?.(
        (offer) => offer?._id === props?.category?.offer?._id
      );
    } else {
      return [];
    }
  }, [offers]);

  const navigateToCategory = () => {
    if (!props.hideCheckButton) {
      history.push(
        replaceInRoute(ADMIN_SUBCATEGORIES_PAGE, {
          categoryId: props.category._id,
        })
      );
    }
  };
  const showEditCategoryModal = () => {
    if (!props.payments) {
      dispatch(
        toggleEditCategoryModal({
          hideImagePicker: props.type !== "categories",
          category: props.category,
          categoryId: props.categoryId,
          subcategory: props.subcategory,
          type: props.type,
          method: "edit",
          firstOutlined: false,
        })
      );
    } else {
      dispatch(
        toggleEditPaymentModal({
          paymentInfo: {
            id: props.category._id,
            payerName: props.category.payerName,
            companyName: company[0].companyName,
            type: props.category.type,
            date: props.category.date,
            offerName: offer[0].name,
          },
        })
      );
    }
  };

  const showDeleteCategoryModal = () => {
    if (!props.payments) {
      dispatch(
        toggleDeleteCategoryModal({
          categoryId: props.categoryId,
          subcategory: props.subcategory,
          category: props.category,
          type: props.type,
        })
      );
    } else {
      dispatch(
        toggleDeleteCategoryModal({
          id: props.category._id,
          category: props.category,
          type: props.type,
        })
      );
    }
  };
  const isDisabledDelete = useMemo(() => {
    return (
      props?.category?.name === "Ostalo" || props?.category?.city === "Ostalo"
    );
  }, [props.category]);

  const date = formatDateLocale(new Date(props?.category?.date));

  return (
    <>
      <CategoryCardContainer className={props.className}>
        <CategoryCardLeftContainer>
          <CategoryCardName
            image={props?.category?.image}
            categoryName={
              props?.category?.name ||
              props?.category?.city ||
              props?.category?.payerName
            }
            onClick={navigateToCategory}
          />
          <CategoryCardDetailsContainer>
            <CategoryDetail
              payments={props.payments}
              label={
                !props.payments
                  ? t("admin.categories.noOfOffers")
                  : t("admin.payment.typeOfPayment")
              }
              value={props?.category?.offerCount || props?.category?.type}
            />
            {!props.hideSecondLabel && (
              <CategoryDetail
                payments={props.payments}
                label={props?.secondLabel}
                value={
                  !props.payments
                    ? props?.category?.subcategories?.length ||
                      props?.category?.offerCount
                    : date
                }
              />
            )}
          </CategoryCardDetailsContainer>
        </CategoryCardLeftContainer>
        <CategoryCardRightContainer>
          <CategoryRemoveButton
            disabled={isDisabledDelete}
            onClick={showDeleteCategoryModal}
          />
          <CategoryEditButton
            disabled={isDisabledDelete}
            onClick={showEditCategoryModal}
          />
          {!props.hideCheckButton && (
            <CategoryCheckButton onClick={navigateToCategory} />
          )}
        </CategoryCardRightContainer>
      </CategoryCardContainer>
    </>
  );
};

CategoryCard.propTypes = {
  children: PropTypes.node,
  category: PropTypes.object,
  hideCheckButton: PropTypes.bool,
  secondLabel: PropTypes.string,
  hideSecondLabel: PropTypes.bool,
  className: PropTypes.string,
  subcategory: PropTypes.bool,
  type: PropTypes.string,
  categoryId: PropTypes.string,
  payments: PropTypes.bool,
};

export default CategoryCard;

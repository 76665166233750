import React from "react";
import PropTypes from "prop-types";
import { CheckOffersButtonContainer } from "./CheckOffersButton.styled";
import selectedTheme from "../../../themes";
import { useTranslation } from "react-i18next";
import { MARKETPLACE_PAGE } from "../../../constants/pages";
import history from "../../../store/utils/history";

const CheckOffersButton = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    history.push(MARKETPLACE_PAGE);
  };
  return (
    <CheckOffersButtonContainer
      buttoncolor={selectedTheme.colors.primaryPurple}
      variant="outlined"
      onClick={handleClick}
    >
      {t("about.searchOffers")}
    </CheckOffersButtonContainer>
  );
};

CheckOffersButton.propTypes = {
  children: PropTypes.node,
};

export default CheckOffersButton;

import { createSelector } from "reselect";

const loginSelector = (state) => state.login;
export const selectUserId = createSelector(
  loginSelector,
  (state) => state.userId
);
export const selectLoginError = createSelector(
  loginSelector,
  (state) => state.errorMessage
);
export const selectRoles = createSelector(
  loginSelector,
  (state) => state.jwtToken.roles
);
export const selectJwtToken = createSelector(
  loginSelector,
  (state) => state.jwtToken.token
);

import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Package } from "../../../../assets/images/svg/package-gray.svg";
import selectedTheme from "../../../../themes";

export const HeaderTitleText = styled(Typography)`
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkTextThird};
  position: relative;
  margin-left: 10px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const OffersIcon = styled(Package)`
  width: 18px;
  height: 18px;
  & path {
    stroke: ${selectedTheme.colors.primaryDarkTextThird};
  }
  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`;
export const HeaderTitleContainer = styled(Grid)`
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 8px;
`

import { Button } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const PrimaryButtonContainer = styled.div`
  min-width: fit-content;
`;

export const PrimaryButtonStyled = styled(Button)`
  background-color: ${(props) =>
    props.disabled
      ? selectedTheme.colors.primaryPurpleDisabled
      : props.variant === "contained"
      ? props.buttoncolor
      : "transparent"} !important;
  border-color: ${(props) =>
    props.variant === "outlined" ? props.buttoncolor : "transparent"};
  color: ${(props) =>
    props.disabled
      ? selectedTheme.colors.primaryTextDisabled
      : props.textcolor} !important;
  box-shadow: 0 0 0 0;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 100;
  width: ${(props) => props.width};
  font-family: ${(props) => props.font};

  height: ${(props) => props.height};
  &:hover {
    background-color: ${(props) =>
      props.variant === "contained" ? props.buttoncolor : "transparent"};
    box-shadow: 0 0 0 0;
  }
  &:disabled {
    background-color: ${selectedTheme.colors.primaryPurpleDisabled};
    color: ${(props) => props.textcolor};
    border: 1px solid ${selectedTheme.colors.primaryBorderDisabled};
  }
`;

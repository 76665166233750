import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../MUI/BackdropComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
// import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAdminMethod } from "../../../store/actions/admin/adminActions";
import { useRef } from "react";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  CreatePaymentContainer,
  CreatePaymentTitle,
  PaymentInputField,
  XIcon,
  FieldLabel,
  InputContainer,
  SaveButton,
  ButtonsContainer,
  StyledWrapper,
  ErrorMessage,
} from "./CreatePayment.styled";
import paymentInitialValues from "../../../initialValues/paymentInitialValues";
import { selectAllProfiles } from "../../../store/selectors/profileSelectors";
import { fetchAllProfiles } from "../../../store/actions/profile/profileActions";
import AutoSuggestTextField from "../../TextFields/AutoSuggestTextField/AutoSuggestTextField";
import { InputFieldLabelLocation } from "../../Cards/ProfileCard/EditProfile/LocationField/LocationField.styled";
import {
  addPayment,
  editPayment,
  fetchPayments,
} from "../../../store/actions/payment/paymentActions";
import { selectOffers } from "../../../store/selectors/offersSelectors";
// import { selectQueryString } from "../../../store/selectors/filtersSelectors";
import { fetchOffers } from "../../../store/actions/offers/offersActions";
import createPaymentValidation from "../../../validations/createPaymentValidation";

const CreatePayment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clickedOnNext, setClickedOnNext] = useState(false);
  const [offersToShow, setOffersToShow] = useState([]);
  const [userId, setUserId] = useState("");
  const [offerId, setOfferId] = useState("");
  const inputRef = useRef(null);
  // const queryString = useSelector(selectQueryString);
  const profiles = useSelector(selectAllProfiles);
  const offers = useSelector(selectOffers);
  useEffect(() => {
    dispatch(fetchAllProfiles());
    dispatch(fetchOffers({ queryString: "" }));
  }, []);

  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const userIdHandler = (value) => {
    if (value) {
      let userId = profiles?.filter?.(
        (profile) => profile.companyName === value
      )[0]?._id;
      setUserId(userId);
      let filterdOffers = offers?.filter?.(
        (offer) => offer.user._id === userId
      );
      setOffersToShow(filterdOffers);
    }
  };

  const offerIdHandler = (value) => {
    if (value) {
      let offerId = offers?.filter?.((offer) => offer.name === value)[0]?._id;
      setOfferId(offerId);
    }
  };

  const handleApiResponseSuccess = () => {
    closeModalHandler();
    dispatch(fetchPayments());
  };

  const handleSubmit = (values) => {
    if (!props.editPayment) {
      dispatch(
        addPayment({
          type: values.type,
          payerName: values.payerName,
          userId: userId,
          offerId: offerId,
          date: values.date,
          handleApiResponseSuccess,
        })
      );
    } else {
      const editUserId = profiles?.filter(
        (profile) => profile.companyName === values.companyName
      )[0]._id;
      const editOfferId = offers?.filter(
        (offer) => offer.name === values.offerName
      )[0]._id;
      dispatch(
        editPayment({
          id: props.paymentInfo.id,
          type: values.type,
          payerName: values.payerName,
          userId: editUserId,
          offerId: editOfferId,
          date: values.date,
          handleApiResponseSuccess,
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: paymentInitialValues(props.paymentInfo),
    validationSchema: createPaymentValidation(profiles, offers),
    onSubmit: handleSubmit,
  });
  const handleClick = (next) => {
    if (next !== clickedOnNext) setClickedOnNext(next);
    formik.handleSubmit();
  };

  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeModalHandler}
        position="fixed"
      />
      <CreatePaymentContainer hideImagePicker={props.hideImagePicker}>
        <XIcon onClick={closeModalHandler} />
        <CreatePaymentTitle>
          {!props.editPayment
            ? t("admin.payment.modal.newTitle")
            : t("admin.payment.modal.editTitle")}
        </CreatePaymentTitle>
        <InputContainer hideImagePicker={props.hideImagePicker}>
          <FieldLabel leftText={t("admin.payment.modal.name")} />
          <PaymentInputField
            name="payerName"
            ref={inputRef}
            // placeholder={placeholder}
            italicPlaceholder
            margin="normal"
            value={formik.values.payerName}
            onChange={formik.handleChange}
            error={formik.touched.payerName && formik.errors.payerName}
            helperText={formik.touched.payerName && formik.errors.payerName}
            autoFocus
            fullWidth
          />
          <InputFieldLabelLocation
            leftText={t("admin.payment.modal.company").toUpperCase()}
          />
          <AutoSuggestTextField
            data={profiles?.map((item) => ({
              name: item.companyName,
            }))}
            value={formik.values.companyName}
            error={formik.errors.companyName}
            onChange={(event, { newValue }) => {
              formik.setFieldValue("companyName", newValue);
              userIdHandler(newValue);
              if (newValue === "") {
                formik.setFieldValue("offerName", "");
                setOffersToShow([]);
              }
            }}
          />
          <InputFieldLabelLocation
            leftText={t("admin.payment.modal.offer").toUpperCase()}
          />
          <StyledWrapper>
            <AutoSuggestTextField
              data={
                userId
                  ? offersToShow?.map((item) => ({
                      name: item.name,
                    }))
                  : []
              }
              value={formik.values.offerName}
              error={formik.errors.offerName}
              onChange={(event, { newValue }) => {
                formik.setFieldValue("offerName", newValue);
                offerIdHandler(newValue);
              }}
            />
          </StyledWrapper>

          <FieldLabel leftText={t("admin.payment.modal.type")} />
          <PaymentInputField
            name="type"
            ref={inputRef}
            // placeholder={placeholder}
            italicPlaceholder
            margin="normal"
            value={formik.values.type}
            onChange={formik.handleChange}
            error={formik.touched.type && formik.errors.type}
            helperText={formik.touched.type && formik.errors.type}
            fullWidth
          />
          <FieldLabel leftText={t("admin.payment.modal.date")} />
          <PaymentInputField
            name="date"
            ref={inputRef}
            type="date"
            italicPlaceholder
            margin="normal"
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && formik.errors.date}
            helperText={formik.touched.date && formik.errors.date}
            fullWidth
          />
          {formik.errors.payerName && formik.touched.payerName ? (
            <ErrorMessage>{formik.errors.payerName}</ErrorMessage>
          ) : formik.errors.companyName && formik.touched.companyName ? (
            <ErrorMessage>{formik.errors.companyName}</ErrorMessage>
          ) : formik.errors.offerName && formik.touched.offerName ? (
            <ErrorMessage>{formik.errors.offerName}</ErrorMessage>
          ) : formik.errors.type && formik.touched.type ? (
            <ErrorMessage>{formik.errors.type}</ErrorMessage>
          ) : formik.errors.date && formik.touched.date ? (
            <ErrorMessage>{formik.errors.date}</ErrorMessage>
          ) : (
            <></>
          )}
        </InputContainer>

        <ButtonsContainer>
          <SaveButton
            showSecondButton={props.showSecondButton}
            variant="contained"
            height="48px"
            onClick={() => handleClick(true)}
          >
            {!props.editPayment
              ? t("admin.payment.modal.add")
              : t("admin.payment.modal.change")}
          </SaveButton>
        </ButtonsContainer>
      </CreatePaymentContainer>
    </>
  );
};

CreatePayment.propTypes = {
  category: PropTypes.any,
  setOpenedEditModal: PropTypes.func,
  hideImagePicker: PropTypes.bool,
  type: PropTypes.string,
  showSecondButton: PropTypes.bool,
  method: PropTypes.string,
  firstOutlined: PropTypes.bool,
  secondOutlined: PropTypes.bool,
  categoryId: PropTypes.string,
  editPayment: PropTypes.bool,
  paymentInfo: PropTypes.any,
  id: PropTypes.string,
};

CreatePayment.defaultProps = {
  firstOutlined: true,
};

export default CreatePayment;

import React from "react";
import PropTypes from "prop-types";
import {
  ProfileStatsContainer,
  ProfileStatsGrid,
  StatsItem,
} from "./ProfileStats.styled";
import { useTranslation } from "react-i18next";

const ProfileStats = (props) => {
  const { t } = useTranslation();
  return (
    <ProfileStatsContainer
      className={props.className}
      twoRows={props.twoRows}
      isBlocked={props.isBlocked}
    >
      <ProfileStatsGrid>
        {/* <StatsItem variant="subtitle2">
          <b>{props.profile?.statistics?.publishes?.count}</b>
          {t("profile.publishes")}
        </StatsItem> */}

        <StatsItem variant="subtitle2">
          <b>{props.numberOfExchanges}</b>
          {t("profile.successExchange")}
          <b>({props.percentOfSucceededExchanges}%)</b>
        </StatsItem>
      </ProfileStatsGrid>
      <ProfileStatsGrid>
        {/* <StatsItem variant="subtitle2">
          <b>{props.profile?.statistics?.views?.count}</b>
          {t("profile.numberOfViews")}
        </StatsItem> */}
        <StatsItem variant="subtitle2">
          {t("profile.successComunication")}
          <b>{props.percentOfSucceededCommunication}%</b>
        </StatsItem>
      </ProfileStatsGrid>
    </ProfileStatsContainer>
  );
};

ProfileStats.propTypes = {
  profile: PropTypes.object,
  percentOfSucceededExchanges: PropTypes.number,
  className: PropTypes.string,
  twoRows: PropTypes.bool,
  isBlocked: PropTypes.bool,
  numberOfExchanges: PropTypes.number,
  percentOfSucceededCommunication: PropTypes.number,
};

export default ProfileStats;

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { DirectChatHeaderContainer } from "./DirectChatHeader.styled";
import OfferCard from "../../Cards/OfferCard/OfferCard";
import { useSelector } from "react-redux";
import { selectExchange } from "../../../store/selectors/exchangeSelector";
import { useDispatch } from "react-redux";
import {
  fetchExchange,
  setExchange,
} from "../../../store/actions/exchange/exchangeActions";
import { selectSelectedChat } from "../../../store/selectors/chatSelectors";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { toggleCreateReviewModal } from "../../../store/actions/modal/modalActions";
import { setOneChat } from "../../../store/actions/chat/chatActions";

const DirectChatHeader = (props) => {
  const exchange = useSelector(selectExchange);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const chat = useSelector(selectSelectedChat);

  useEffect(() => {
    return () => {
      dispatch(setExchange({}));
      dispatch(setOneChat({}));
    };
  }, []);

  useEffect(() => {
    if (chat?.exchange?._id) refetchExchange();
  }, [chat?.exchange]);

  const isDisabledReviews = useMemo(() => {
    if (!exchange.valid) return true;
    if (exchange.seller?.user._id === userId && exchange.seller?.givenReview)
      return true;
    if (exchange.buyer?.user._id === userId && exchange.buyer?.givenReview)
      return true;
    if (chat?.offer?._deleted) return true;
    if (props.interlocutor?._blocked) return true;
    return false;
  }, [exchange, userId, props.interlocutor, chat]);

  const isDisabledCheckButton = useMemo(() => {
    if (props?.interlocutor?._blocked) return true;
    if (chat?.offer?._deleted) return true;
    return false;
  }, [props.interlocutor, chat]);

  const showReviewModal = () => {
    dispatch(
      toggleCreateReviewModal({
        offer: props.offer,
        interlocutor: props.interlocutor,
        exchange: exchange,
      })
    );
  };

  const refetchExchange = () => {
    dispatch(fetchExchange(chat.exchange?._id));
  };

  const acceptExchange = () => {
    props.acceptExchange();
  };
  return (
    <DirectChatHeaderContainer>
      <OfferCard
        offer={props.offer}
        aboveChat
        disabledReviews={isDisabledReviews}
        makeReview={showReviewModal}
        acceptExchange={acceptExchange}
        exchangeState={props?.exchangeState}
        dontShowViews
        disabledCheckButton={isDisabledCheckButton}
      />
    </DirectChatHeaderContainer>
  );
};

DirectChatHeader.propTypes = {
  children: PropTypes.node,
  offer: PropTypes.any,
  interlocutor: PropTypes.any,
  acceptExchange: PropTypes.func,
  exchangeState: PropTypes.any,
};

export default DirectChatHeader;

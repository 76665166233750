import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortEnum } from "../../enums/sortEnum";
import { fetchMineOffers } from "../../store/actions/offers/offersActions";
import { selectMineOffers } from "../../store/selectors/offersSelectors";
import useFilters from "./useFilters";
import usePaging from "./usePaging";
import useSearch from "./useSearch";
import useSorting from "./useSorting";

const useMyOffers = () => {
  const applyFilters = () => {
    paging.changePage(1);
    setAppliedFilters(false);
  };
  const filters = useFilters(true);
  const sorting = useSorting();
  const mineOffers = useSelector(selectMineOffers);
  const search = useSearch();
  const dispatch = useDispatch();
  const paging = usePaging();
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [totalOffers, setTotalOffers] = useState(0);

  useEffect(() => {
    dispatch(fetchMineOffers());
    return () => {
      filters.clear();
      
    }
  }, []);

  const clear = () => {
    filters.clear();
    setAppliedFilters(false);
  }

  // Filter, search and sort all mine offers
  const allOffersToShow = useMemo(() => {
    let mineOffersFiltered = [...mineOffers];
    // Filter mine offers by category
    if (filters.category.selectedCategoryLocally?.name)
      mineOffersFiltered = mineOffersFiltered.filter(
        (offer) =>
          offer?.category?.name ===
          filters.category.selectedCategoryLocally.name
      );
    // Filter mine offers by subcategory
    if (filters.subcategory.selectedSubcategoryLocally?.name) {
      mineOffersFiltered = mineOffersFiltered.filter(
        (offer) =>
          offer?.subcategory ===
          filters.subcategory.selectedSubcategoryLocally?.name
      );
    }
    // Filter mine offers by locations
    if (filters.locations.selectedLocationsLocally?.length > 0) {
      mineOffersFiltered = mineOffersFiltered.filter((offer) =>
        filters.locations.selectedLocationsLocally.find(
          (location) => location?.city === offer?.location?.city
        )
      );
    }
    // Sort mine offers
    if (sorting.selectedSortOptionLocally.value !== sortEnum.INITIAL.value) {
      if (sorting.selectedSortOptionLocally.value === sortEnum.OLD.value) {
        mineOffersFiltered.sort(
          (a, b) => new Date(a._created) - new Date(b._created)
        );
      }
      if (sorting.selectedSortOptionLocally.value === sortEnum.NEW.value) {
        mineOffersFiltered.sort(
          (a, b) => new Date(b._created) - new Date(a._created)
        );
      }
      if (sorting.selectedSortOptionLocally.value === sortEnum.POPULAR.value) {
        mineOffersFiltered.sort((a, b) => b.views.count - a.views.count);
      }
    }
    mineOffersFiltered = mineOffersFiltered.filter((offer) =>
      offer?.name?.toLowerCase()?.includes(search.searchStringLocally)
    );
    setTotalOffers(mineOffersFiltered?.length);
    mineOffersFiltered = mineOffersFiltered.slice(
      (paging.currentPage - 1) * 10,
      paging.currentPage * 10
    );
    if (!appliedFilters) {
      setAppliedFilters(true);
    }
    return [...mineOffersFiltered];
  }, [
    appliedFilters,
    sorting.selectedSortOptionLocally,
    mineOffers,
    paging.currentPage,
  ]);

  return {
    filters,
    paging,
    sorting,
    search,
    allOffersToShow,
    totalOffers,
    applyFilters,
    clear,
  };
};
export default useMyOffers;

import React from "react";
import PropTypes from "prop-types";
import { FilterFooterContainer } from "./FilterFooter.styled";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";

const FilterFooter = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const filters = props.filters;
  // const handleFilters = () => {
  //   filters.paging.changePage(1);
  //   // filters.search.clear();
  //   filters.applyFilters();
  //   props.toggleFilters();
  // };
  const clearFilters = () => {
    if (!props.payments) {
      if (props.isMyOffers) {
        filters.clear();
      } else {
        filters.clearOnlyFiltersAndApply();
      }
      props.toggleFilters();
      props.closeAllSections();
    } else {
      props.toggleDrawer();
      filters.clearOnlyFiltersAndApply();
    }
  };
  return (
    <FilterFooterContainer responsiveOpen={isMobile}>
      {isMobile && (
        <PrimaryButton
          variant="outlined"
          fullWidth
          onClick={clearFilters}
          textcolor={selectedTheme.colors.primaryPurple}
          font={selectedTheme.fonts.textFont}
          style={{
            fontWeight: "600",
            fontSize: "12px",
            border: "0",
            textAlign: "center",
          }}
        >
          {t("filters.cancel")}
        </PrimaryButton>
      )}
      {/* <PrimaryButton
        variant="outlined"
        fullWidth
        onClick={handleFilters}
        textcolor={selectedTheme.colors.primaryPurple}
        font={selectedTheme.fonts.textFont}
        style={{
          fontWeight: "600",
          fontSize: "12px",
          borderColor: selectedTheme.colors.primaryPurple,
        }}
      >
        {t("filters.usefilters")}
      </PrimaryButton> */}
    </FilterFooterContainer>
  );
};

(FilterFooter.propTypes = {
  responsiveOpen: PropTypes.bool,
  toggleFilters: PropTypes.func,
  filters: PropTypes.any,
  isMyOffers: PropTypes.bool,
  closeAllSections: PropTypes.func,
  toggleDrawer: PropTypes.func,
  payments: PropTypes.bool,
}),
  (FilterFooter.defaultProps = {
    responsiveOpen: false,
  });

export default FilterFooter;

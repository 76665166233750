import styled from "styled-components";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";

export const CheckOffersButtonContainer = styled(PrimaryButton)`
  position: absolute;
  bottom: 8px;
  right: 36px;
  width: 180px;
  height: 49px;
  & button {
    color: ${selectedTheme.colors.primaryPurple};
    font-family: ${selectedTheme.fonts.textFont};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  & button:hover {
    color: white;
    background-color: ${selectedTheme.colors.primaryPurple} !important;
  }

  @media (max-width: 1430px) {
    right: 22px;
  }

  @media (max-width: 1230px) {
    bottom: 0;
  }

  @media (max-width: 1200px) {
    bottom: 0;
    right: 18px;
  }

  @media (max-width: 1069px) {
    position: relative;
    right: 0;
    align-self: flex-end;
  }

  @media (max-width: 600px) {
    position: relative;
    bottom: 0;
    right: 18px;
    align-self: flex-end;
  }
`;

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Container,
  ErrorContainer,
  ErrorImageContainer,
  ErrorHeading,
  ErrorMessage,
  Button,
} from "./NotFoundPage.styles";
import { ReactComponent as Error404 } from "../../assets/images/svg/error-404.svg";
import selectedTheme from "../../themes";
import { useHistory } from "react-router-dom";
import { HOME_PAGE } from "../../constants/pages";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const showAllOffersHandler = () => {
    history.push({
      pathname: HOME_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  return (
    <Container>
      <ErrorContainer>
        <ErrorImageContainer>
          <Error404 />
        </ErrorImageContainer>
        <ErrorHeading>{t("notFound.error404")}</ErrorHeading>
        <ErrorMessage>
          <Trans i18nKey="notFound.errorMessage" />
        </ErrorMessage>
        <Button
          variant="contained"
          buttoncolor={selectedTheme.colors.primaryYellow}
          onClick={showAllOffersHandler}
        >
          {t("notFound.showAllOffers")}
        </Button>
      </ErrorContainer>
    </Container>
  );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;

import styled from "styled-components";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";

export const RegisterButtonContainer = styled(PrimaryButton)`
  height: 49px;
  width: 180px;
  font-weight: 600;
  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
`;
import React from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  BlockLabelIcon,
  UnblockLabelIcon,
  ApproveUserIcon,
} from "./ProfileControl.styled";
import UserLabeledCard from "../../LabeledCard/User/UserLabeledCard";
import BlockedProfile from "../BlockedProfile/BlockedProfile";
import {
  toggleDeleteCategoryModal,
  toggleEditProfileModal,
} from "../../../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";
import {
  USERS_APPROVE_TYPE,
  USERS_BLOCK_TYPE,
  USERS_DELETE_TYPE,
  USERS_UNBLOCK_TYPE,
} from "../../../../constants/adminTypeConstants";
import ProfileControlButton from "./ProfileControlButton/ProfileControlButton";

const ProfileControl = (props) => {
  const dispatch = useDispatch();
  const removeUser = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteCategoryModal({
        customId: props.profile?._id,
        type: USERS_DELETE_TYPE,
        customLabeledCard: <UserLabeledCard user={props.profile} />,
        customLabeledCardHeight: "90px",
      })
    );
  };
  const blockUser = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteCategoryModal({
        customId: props.profile?._id,
        type: USERS_BLOCK_TYPE,
        customLabeledCard: <UserLabeledCard user={props.profile} />,
        customLabeledCardHeight: "90px",
        customLabeledCardIcon: <BlockLabelIcon />,
      })
    );
  };
  const unblockUser = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteCategoryModal({
        customId: props.profile?._id,
        type: USERS_UNBLOCK_TYPE,
        customLabeledCard: <UserLabeledCard user={props.profile} />,
        customLabeledCardHeight: "90px",
        customLabeledCardIcon: <UnblockLabelIcon />,
      })
    );
  };
  const handleEditProfile = (event) => {
    event.stopPropagation();
    console.log("edit");
    console.log(props);
    if (!props.profile?._blocked || props?.isAdmin) {
      console.log("edit2");
      dispatch(
        toggleEditProfileModal({
          userId: props.profile._id,
          profile: props.profile,
          isAdmin: props.isAdmin,
          reFetchProfile: props.bigProfileCard
            ? () => {}
            : props.reFetchProfile,
        })
      );
    }
  };
  const approveUser = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteCategoryModal({
        customId: props.profile?._id,
        type: USERS_APPROVE_TYPE,
        customLabeledCard: <UserLabeledCard user={props.profile} />,
        customLabeledCardHeight: "90px",
        customLabeledCardIcon: <ApproveUserIcon />,
      })
    );
  };
  console.log(props);
  return (
    <ButtonsContainer>
      {props.profile?._blocked && !props.isMobile && !props?.isAdmin && (
        <BlockedProfile />
      )}
      {props.isAdmin && (
        <>
          <ProfileControlButton title="approveUser" onClick={approveUser} />
          {props.profile?._blocked ? (
            <ProfileControlButton title="unblockUser" onClick={unblockUser} />
          ) : (
            <ProfileControlButton title="blockUser" onClick={blockUser} />
          )}
          <ProfileControlButton title="deleteUser" onClick={removeUser} />
        </>
      )}
      {(props.isMyProfile || props.isAdmin) && (
        <ProfileControlButton
          title="editProfile"
          onClick={handleEditProfile}
          isAdmin={props.isAdmin}
          disabled={!props.isAdmin && props.profile?._blocked}
        />
      )}
    </ButtonsContainer>
  );
};

ProfileControl.propTypes = {
  profile: PropTypes.any,
  isAdmin: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  isMobile: PropTypes.bool,
  reFetchProfile: PropTypes.func,
  bigProfileCard: PropTypes.bool,
};

export default ProfileControl;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as LogoBroken } from "../../../assets/images/svg/logo-broken-purple.svg";

export const NoReviewsContainer = styled(Box)`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.fullHeight && `height: 70vh;`}
`;
export const NoReviewsText = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-size: 24px;
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  font-weight: 700;
  width: 229px;
  margin-top: 20px;
`;
export const NoReviewsAltText = styled(Typography)`
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
`;

export const Logo = styled(LogoBroken)``;

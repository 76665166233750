import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  DirectChatContentHeaderContainer,
  DirectChatContentHeaderFlexContainer,
  DirectChatHeaderStatusContainer,
  DirectChatHeaderStatusText,
  PhoneIcon,
  PhoneIconContainer,
  ProfileDetails,
  ProfileImage,
  ProfileLocation,
  ProfileLocationIcon,
  ProfileLocationText,
  ProfileName,
} from "./DirectChatContentHeader.styled";
import PopoverComponent from "../../../Popovers/PopoverComponent";
import PhonePopover from "../../../Popovers/PhonePopover/PhonePopover";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import useIsMobile from "../../../../hooks/useIsMobile";
import history from "../../../../store/utils/history";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import { PROFILE_PAGE } from "../../../../constants/pages";
import { selectAmIBlocked } from "../../../../store/selectors/profileSelectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import exchangeStatus from "../../../../constants/exchangeStatus";

const DirectChatContentHeader = (props) => {
  const { t } = useTranslation();
  const [showPhonePopover, setShowPhonePopover] = useState(false);
  const [phonePopoverAnchorEl, setPhonePopoverAnchorEl] = useState(null);
  const { isMobile } = useIsMobile();
  const mineProfileBlocked = useSelector(selectAmIBlocked);
  const togglePhonePopover = (event) => {
    if (props.interlocutor?.company?.contacts?.telephone) {
      setShowPhonePopover((prevState) => !prevState);
      setPhonePopoverAnchorEl((prevState) => {
        if (prevState) return null;
        return event.target;
      });
    }
  };
  const routeToUser = () => {
    if (!props?.interlocutor?._blocked)
      history.push(
        replaceInRoute(PROFILE_PAGE, {
          profileId: props?.interlocutor?._id,
        })
      );
  };
  return (
    <>
      <DirectChatContentHeaderContainer>
        <DirectChatContentHeaderFlexContainer>
          <ProfileImage
            onClick={routeToUser}
            src={getImageUrl(
              props?.interlocutor?.image,
              variants.chatHeader,
              isMobile
            )}
          />
          <ProfileDetails>
            <ProfileName onClick={routeToUser}>
              {props?.interlocutor?.company?.name}
            </ProfileName>
            <ProfileLocation>
              <ProfileLocationIcon />
              <ProfileLocationText>
                {props?.interlocutor?.company?.contacts?.location}
              </ProfileLocationText>
            </ProfileLocation>
          </ProfileDetails>
        </DirectChatContentHeaderFlexContainer>
        <DirectChatContentHeaderFlexContainer>
          <PhoneIconContainer
            disabled={
              mineProfileBlocked ||
              props?.interlocutor?._blocked ||
              !props.interlocutor?.company?.contacts?.telephone
            }
            onClick={togglePhonePopover}
          >
            <PhoneIcon />
          </PhoneIconContainer>
        </DirectChatContentHeaderFlexContainer>
        <PopoverComponent
          anchorEl={phonePopoverAnchorEl}
          open={showPhonePopover}
          anchorRight
          onClose={togglePhonePopover}
          content={
            <PhonePopover
              phoneNumber={props.interlocutor?.company?.contacts?.telephone}
            />
          }
        />
      </DirectChatContentHeaderContainer>
      {(props.exchangeState === exchangeStatus.I_OFFERED ||
        props.exchangeState === exchangeStatus.ACCEPTED ||
        props.exchangeState === exchangeStatus.REVIEWED) && (
        <DirectChatHeaderStatusContainer>
          <DirectChatHeaderStatusText>
            {props.exchangeState === exchangeStatus.I_OFFERED
              ? isMobile
                ? t("messages.requestSentShort")
                : t("messages.requestSentLong")
              : isMobile
              ? t("messages.requestSuccessfulShort")
              : t("messages.requestSuccessfulLong")}
          </DirectChatHeaderStatusText>
        </DirectChatHeaderStatusContainer>
      )}
    </>
  );
};

DirectChatContentHeader.propTypes = {
  children: PropTypes.node,
  interlocutor: PropTypes.any,
  exchangeState: PropTypes.bool,
};

export default DirectChatContentHeader;

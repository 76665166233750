import React from "react";
import DirectChat from "../../components/DirectChat/DirectChat";
import MiniChatColumn from "../../components/DirectChat/MiniChatColumn/MiniChatColumn";
import ChatGridLayout from "../../layouts/ChatGridLayout/ChatGridLayout";
import { useSwipeable } from "react-swipeable";
import {
  DirectChatPageContainer,
  SwiperContainer,
} from "./DirectChatPage.styled";
import { useHistory } from "react-router-dom";
export const DirectChatPage = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const handlersBox = useSwipeable({
    onSwipedRight: () => setTimeout(goBack, 0),
    trackMouse: false,
  });
  
  return (
    <SwiperContainer {...handlersBox}>
      <DirectChatPageContainer>
        <ChatGridLayout
          content={<DirectChat />}
          leftCard={<MiniChatColumn />}
        />
      </DirectChatPageContainer>
    </SwiperContainer>
  );
};

DirectChatPage.propTypes = {};

export default DirectChatPage;

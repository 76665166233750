import React, { useMemo } from "react";
import {
  ProfileHeader,
  ProfileHeaderIconContainer,
  ProfileHeaderText,
  ProfileMiniHeader,
  ProfileMiniStats,
} from "./ProfileMini.styled";
import { useSelector } from "react-redux";
import { selectOffer } from "../../store/selectors/offersSelectors";
import { selectUserId } from "../../store/selectors/loginSelectors";
import { ReactComponent as ProfileIcon } from "../../assets/images/svg/user-gray.svg";
import { useTranslation } from "react-i18next";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import SkeletonProfileMini from "./SkeletonProfileMini/SkeletonProfileMini";
import { ONE_OFFER_SCOPE } from "../../store/actions/offers/offersActionConstants";

const ProfileMini = () => {
  const offer = useSelector(selectOffer);
  const userId = useSelector(selectUserId);
  const { t } = useTranslation();
  const isLoadingOfferContent = useSelector(
    selectIsLoadingByActionType(ONE_OFFER_SCOPE)
  );
  let isMyProfile = useMemo(() => {
    if (offer?.offer?.userId?.toString() === userId?.toString()) return true;
    return false;
  }, [offer, userId]);

  let numberOfExchanges;
  if (
    offer?.user?.statistics?.exchanges?.succeededExchanges &&
    offer?.user?.statistics?.exchanges?.failedExchanges
  ) {
    numberOfExchanges =
      offer?.user?.statistics?.exchanges?.failedExchanges +
      offer?.user?.statistics?.exchanges?.succeededExchanges;
  } else if (offer?.user?.statistics?.exchanges?.succeededExchanges) {
    numberOfExchanges = offer?.user?.statistics?.exchanges?.succeededExchanges;
  } else {
    numberOfExchanges = 0;
  }

  let percentOfSucceededExchanges;
  if (
    offer?.user?.statistics?.exchanges?.succeededExchanges &&
    offer?.user?.statistics?.exchanges?.failedExchanges
  ) {
    percentOfSucceededExchanges = Math.round(
      offer?.user?.statistics?.exchanges?.succeededExchanges /
        ((offer?.user?.statistics?.exchanges?.succeededExchanges +
          offer?.user?.statistics?.exchanges?.failedExchanges) /
          100)
    );
  } else if (offer?.user?.statistics?.exchanges?.succeededExchanges) {
    percentOfSucceededExchanges = 100;
  } else {
    percentOfSucceededExchanges = 0;
  }

  let percentOfSucceededCommunication;
  if (
    offer?.user?.statistics?.exchanges?.succeededCommunication &&
    offer?.user?.statistics?.exchanges?.failedCommunication
  ) {
    percentOfSucceededCommunication = Math.round(
      offer?.user?.statistics?.exchanges?.succeededCommunication /
        ((offer?.user?.statistics?.exchanges?.succeededCommunication +
          offer?.user?.statistics?.exchanges?.failedCommunication) /
          100)
    );
  } else if (offer?.user?.statistics?.exchanges?.succeededCommunication) {
    percentOfSucceededCommunication = 100;
  } else {
    percentOfSucceededCommunication = 0;
  }

  let verifiedUser =
    numberOfExchanges >= 20 && percentOfSucceededCommunication >= 90;

  return (
    <>
      {isLoadingOfferContent || isLoadingOfferContent === undefined ? (
        <SkeletonProfileMini />
      ) : (
        <ProfileHeader>
          <ProfileHeaderIconContainer>
            <ProfileIcon />
            <ProfileHeaderText>
              {isMyProfile
                ? t("profile.myProfile")
                : t("profile.companyProfile")}
            </ProfileHeaderText>
          </ProfileHeaderIconContainer>
          <ProfileMiniHeader
            offer={offer}
            isMyProfile={isMyProfile}
            singleOffer
            verify
            verifiedUser={verifiedUser}
          />
          <ProfileMiniStats
            // profile={profile}
            numberOfExchanges={numberOfExchanges}
            percentOfSucceededExchanges={percentOfSucceededExchanges}
            percentOfSucceededCommunication={percentOfSucceededCommunication}
            isMyProfile={isMyProfile}
          />
        </ProfileHeader>
      )}
    </>
  );
};

export default ProfileMini;

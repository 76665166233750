import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const HeaderDetails = styled(Box)`
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
`;
export const BottomDetails = styled(Box)`
  max-width: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 18px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;
export const StatusText = styled(Grid)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const StatusValue = styled.b`
    font-weight: bold;
`
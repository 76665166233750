import React from 'react'
import PropTypes from 'prop-types'
import { Circle, EndLine, Line, OptionLeftContainer, SkeletonSectionOptionContainer } from './SkeletonSectionOption.styled'

const SkeletonSectionOption = () => {
  return (
    <SkeletonSectionOptionContainer>
        <OptionLeftContainer>
            <Circle/>
            <Line/>
        </OptionLeftContainer>
        <EndLine/>
    </SkeletonSectionOptionContainer>
  )
}

SkeletonSectionOption.propTypes = {
    children: PropTypes.any,
}

export default SkeletonSectionOption
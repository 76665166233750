import styled from "styled-components";
import { TextField } from "../../../../TextFields/TextField/TextField";

export const TitleField = styled(TextField)`
  & input {
    background-color: white;
  }
  @media (max-width: 600px) {
    margin-bottom: 0;
    & div div input {
      font-size: 12px !important;
    }
    & div {
      height: 40px;
    }
  }
`;

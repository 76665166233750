import { TextField, Avatar, Box, Typography } from "@mui/material";
import styled from "styled-components";
import PIB from "@mui/icons-material/AdminPanelSettingsOutlined";
import { ReactComponent as Dollar } from "../../../assets/images/svg/dollar.svg";
import selectedTheme from "../../../themes";

export const ProfileImgPIB = styled(PIB)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.36rem;
`;

export const ProfileAvatar = styled(Avatar)`
  width: 63px;
  height: 63px;
  margin-right: 1rem;
`;

export const SearchInput = styled(TextField)`
  margin-left: 3.8rem;
  background-color: #f4f4f4;
  width: 45%;
  max-width: 100%;
  @media (max-width: 1100px) {
    width: 36%;
  }
  @media (max-width: 900px) {
    width: 54%;
  }
  @media (max-width: 600px) {
    width: 36%;
  }
`;
export const GrayButton = styled(Box)`
  text-align: right;
  & button {
    margin-bottom: 5.5px;
    margin-top: 5.5px;
    margin-right: 0;
    color: ${selectedTheme.colors.primaryPurple};
  }
`;
export const GrayButtonsContainer = styled(Box)`
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(77, 77, 77, 0.12);
`;
export const DollarIcon = styled(Dollar)`
  position: relative;
  left: -7px;
  margin-left: 10px;
  & path {
    stroke: ${selectedTheme.colors.borderNormal};
    fill: ${selectedTheme.colors.borderNormal};
  }
`;
export const AdminText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
`;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputField, InputFieldLabel } from "../EditProfile.styled";

const PhoneField = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <InputFieldLabel leftText={t("editProfile.phoneNumber").toUpperCase()} />
      <InputField
        type="number"
        name="firmPhone"
        value={props.formik.values.firmPhone}
        onChange={(event) => {
          props.formik.setFieldValue("firmPhone", event.target.value);
        }}
        error={props.formik.touched.firmPhone && props.formik.errors.firmPhone}
        margin="normal"
        fullWidth
        onInput={(e) => {
          e.target.value =
            e.target.value[0] === "0" && e.target.value.length > 1
              ? "0" +
                String(
                  Math.max(0, parseInt(e.target.value)).toString().slice(0, 14)
                )
              : Math.max(0, parseInt(e.target.value)).toString().slice(0, 14);
        }}
      />
    </>
  );
};

PhoneField.propTypes = {
  formik: PropTypes.any,
};

export default PhoneField;

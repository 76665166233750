import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FieldLabel,
  SelectField,
  SelectOption,
} from "../FirstStepCreateReview.styled";
import { reviewEnum } from "../../../../enums/reviewEnum";

const CorrectCommunicationField = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FieldLabel
        leftText={t("reviews.isCorrectCommunication").toUpperCase()}
      />
      <SelectField
        defaultValue={props.formik.values.correctCommunication}
        onChange={(event) =>
          props.formik.setFieldValue("correctCommunication", event.target.value)
        }
      >
        {Object.keys(reviewEnum).map((property) => (
          <SelectOption
            key={reviewEnum[property].value}
            value={reviewEnum[property].mainText}
          >
            {reviewEnum[property].mainText}
          </SelectOption>
        ))}
      </SelectField>
    </>
  );
};

CorrectCommunicationField.propTypes = {
    formik: PropTypes.any,
};

export default CorrectCommunicationField;

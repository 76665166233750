import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectLoginError } from "../../../store/selectors/loginSelectors";
import { FORGOT_PASSWORD_PAGE } from "../../../constants/pages";
import Link from "../../Link/Link";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ForgotPasswordLink = () => {
  const error = useSelector(selectLoginError);
  const { t } = useTranslation();
  return (
    <Link
      to={FORGOT_PASSWORD_PAGE}
      textsize="12px"
      component={NavLink}
      underline="hover"
      align="right"
      style={{
        marginTop: error.length > 0 ? "0" : "18px",
        marginBottom: "18px",
      }}
    >
      {t("login.forgotYourPassword")}
    </Link>
  );
};

ForgotPasswordLink.propTypes = {
  children: PropTypes.node,
};

export default ForgotPasswordLink;

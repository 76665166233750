import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../../themes";
import { InfoGroup, InfoIcon, InfoText } from "./SingleInformation.styled";

const SingleInformation = (props) => {
  return (
    <InfoGroup hide={props.hide}>
      <InfoIcon
        color={selectedTheme.colors.iconStrokeColor}
        component="span"
      >
        {props.icon}
      </InfoIcon>
      <InfoText>{props.value}</InfoText>
    </InfoGroup>
  );
};

SingleInformation.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.string,
  hide: PropTypes.bool,
};

export default SingleInformation;

import React from "react";
import PropTypes from "prop-types";
import { LoginButtonContainer } from "./LoginButton.styled";
import selectedTheme from "../../../themes";
import { useTranslation } from "react-i18next";
import { LOGIN_PAGE } from "../../../constants/pages";
import history from "../../../store/utils/history";

const LoginButton = () => {
  const { t } = useTranslation();
  const handleNavigateLogin = () => {
    history.push(LOGIN_PAGE);
  };
  return (
    <LoginButtonContainer
      type="submit"
      variant="contained"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor="white"
      onClick={handleNavigateLogin}
    >
      {t("login.headerTitle")}
    </LoginButtonContainer>
  );
};

LoginButton.propTypes = {
  children: PropTypes.node,
};

export default LoginButton;

import React from "react";
import PropTypes from "prop-types";
import {
  CircleOne,
  CircleSecond,
  LeftContainer,
  Line,
  SkeletonChooserContainer,
} from "./SkeletonChooserHeader.styled";

const SkeletonChooserHeader = () => {
  return (
    <SkeletonChooserContainer>
      <LeftContainer>
        <CircleOne/>
        <Line/>
      </LeftContainer>
      <CircleSecond/>
    </SkeletonChooserContainer>
  );
};

SkeletonChooserHeader.propTypes = {
  children: PropTypes.node,
};

export default SkeletonChooserHeader;

import React from "react";
import PropTypes from "prop-types";
import {
  MessageIcon,
  OfferDetails,
  OfferImage,
  OfferTitle,
  HeaderTop,
  UserIcon,
  UserIconContainer,
  TooltipInnerContainer,
} from "./ItemDetailsHeaderCard.styled";
import { ItemDetailsHeaderContainer } from "./ItemDetailsHeaderCard.styled";
import { ReactComponent as MessageColor } from "../../../assets/images/svg/mailColor.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLatestChats } from "../../../store/selectors/chatSelectors";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import StatisticDetails from "./StatisticDetails/StatisticDetails";
import PIBDetail from "./OfferDetail/PIB/PIBDetail";
import CategoryDetail from "./OfferDetail/Category/CategoryDetail";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import { useMemo } from "react";
import itemDetailsData from "../../../notFoundData/itemDetailsData";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import {
  ADMIN_SINGLE_USER_PAGE,
  DIRECT_CHAT_PAGE,
  PROFILE_PAGE,
} from "../../../constants/pages";
import { NEW_CHAT } from "../../../constants/chatConstants";
import { VerifiedUserContainer } from "../../Cards/ProfileCard/ProfileMainInfo/ProfileMainInfo.styled";
import { ReactComponent as VerifiedIcon } from "../../../assets/images/svg/verified-user.svg";

const ItemDetailsHeaderCard = (props) => {
  const history = useHistory();
  const chats = useSelector(selectLatestChats);
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const offer = useMemo(() => {
    if (props.offer) {
      if (props.offer._id === routeMatch.params.offerId) {
        return props.offer;
      }
    }
    return itemDetailsData;
  }, [props.offer]);
  const userId = useSelector(selectUserId);
  const { isMobile } = useIsMobile();

  const handleGoProfile = () => {
    if (props?.isAdmin) {
      history.push(
        replaceInRoute(ADMIN_SINGLE_USER_PAGE, {
          profileId: props?.offer?.user?._id,
        })
      );
    } else {
      history.push(
        replaceInRoute(PROFILE_PAGE, {
          profileId: props?.offer?.user?._id,
        })
      );
    }
  };
  const messageUser = (offer) => {
    const chatItem = chats.find((item) => item.offer._id === offer?._id);
    if (chatItem !== undefined) {
      console.log(chatItem);
      history.push(
        replaceInRoute(DIRECT_CHAT_PAGE, {
          chatId: chatItem._id,
        })
      );
    } else {
      if (offer?.user?._id !== userId) {
        history.push({
          pathname: replaceInRoute(DIRECT_CHAT_PAGE, {
            chatId: NEW_CHAT,
          }),
          state: {
            offerId: offer?._id,
          },
        });
      }
    }
  };
  return (
    <ItemDetailsHeaderContainer
      isMyProfile={props.isMyProfile}
      halfwidth={props.halfwidth ? 1 : 0}
    >
      <HeaderTop>
        <OfferImage
          src={getImageUrl(
            offer?.user?.image ? offer.user.image : "",
            variants.profileImage,
            isMobile
          )}
        />
        <OfferDetails>
          <OfferTitle isMyProfile={props.isMyProfile} onClick={handleGoProfile}>
            {offer?.user?.company?.name}
            {props.verify && props.verifiedUser && (
              <Tooltip title={t("profile.verifiedTooltip")} placement="right">
                <VerifiedUserContainer>
                  <VerifiedIcon />
                </VerifiedUserContainer>
              </Tooltip>
            )}
          </OfferTitle>
          <PIBDetail offer={offer} isMyProfile={props.isMyProfile} />
          <CategoryDetail offer={offer} isMyProfile={props.isMyProfile} />
        </OfferDetails>
        {props.isMyProfile ? (
          <UserIconContainer onClick={handleGoProfile}>
            <UserIcon />
          </UserIconContainer>
        ) : (
          <Tooltip title={t("messages.tooltip")}>
            <TooltipInnerContainer>
              <MessageIcon onClick={() => messageUser(offer)}>
                <MessageColor />
              </MessageIcon>
            </TooltipInnerContainer>
          </Tooltip>
        )}
      </HeaderTop>
      {!props.singleOffer && <StatisticDetails offer={offer} />}
    </ItemDetailsHeaderContainer>
  );
};

ItemDetailsHeaderCard.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  author: PropTypes.string,
  location: PropTypes.string,
  image: PropTypes.node,
  quantity: PropTypes.number,
  package: PropTypes.string,
  numberOfViews: PropTypes.number,
  halfwidth: PropTypes.bool,
  sponsored: PropTypes.bool,
  offer: PropTypes.any,
  isMyProfile: PropTypes.bool,
  singleOffer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  verify: PropTypes.bool,
  verifiedUser: PropTypes.bool,
};
ItemDetailsHeaderCard.defaultProps = {
  halfwidth: false,
  sponsored: false,
};

export default ItemDetailsHeaderCard;

import {
  ADMIN_CATEGORIES_PAGE,
  ADMIN_HOME_PAGE,
  ADMIN_ITEM_DETAILS_PAGE,
  ADMIN_LOCATIONS_PAGE,
  ADMIN_LOGIN_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_SINGLE_USER_PAGE,
  ADMIN_SUBCATEGORIES_PAGE,
  ADMIN_USERS_PAGE,
  FORGOT_PASSWORD_MAIL_SENT,
  FORGOT_PASSWORD_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
  REGISTER_SUCCESSFUL_PAGE,
  RESET_PASSWORD_PAGE,
} from "../../constants/pages";
import history from "../../store/utils/history";

export const routeMatches = (route, secondRoute = null) => {
  let routeToCheck = secondRoute || history.location.pathname;
  if (
    routeToCheck === route ||
    routeToCheck + "/" === route ||
    routeToCheck.slice(0, -1) === route
  )
    return true;
  return false;
};

export const replaceInRoute = (route, pathVariables = {}) => {
  const keys = Object.keys(pathVariables);
  return keys.reduce(
    (acc, key) => acc.replace(`:${key}`, pathVariables[`${key}`]),
    route
  );
};
export const isAuthRoute = () => {
  if (
    routeMatches(LOGIN_PAGE) ||
    routeMatches(REGISTER_PAGE) ||
    routeMatches(REGISTER_SUCCESSFUL_PAGE) ||
    routeMatches(FORGOT_PASSWORD_PAGE) ||
    routeMatches(FORGOT_PASSWORD_MAIL_SENT) ||
    routeMatches(ADMIN_LOGIN_PAGE) ||
    dynamicRouteMatches(RESET_PASSWORD_PAGE)
  ) {
    return true;
  }
  return false;
};
export const isAdminRoute = () => {
  if (
    routeMatches(ADMIN_LOGIN_PAGE) ||
    routeMatches(ADMIN_HOME_PAGE) ||
    routeMatches(ADMIN_USERS_PAGE) ||
    routeMatches(ADMIN_CATEGORIES_PAGE) ||
    dynamicRouteMatches(ADMIN_SUBCATEGORIES_PAGE) ||
    routeMatches(ADMIN_LOCATIONS_PAGE) ||
    routeMatches(ADMIN_PAYMENT_PAGE) ||
    dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE) ||
    dynamicRouteMatches(ADMIN_ITEM_DETAILS_PAGE) ||
    isInRoute(ADMIN_HOME_PAGE)
  )
    return true;
  return false;
};
export const dynamicRouteMatches = (dynamicRoute) => {
  let indexOfDynamicChar = dynamicRoute.indexOf(":");
  if (indexOfDynamicChar === -1) return false;
  const charactersToDelete = (dynamicRoute.length - indexOfDynamicChar) * -1;
  const newDynamicRoute = dynamicRoute.slice(0, charactersToDelete);
  return history.location.pathname.includes(newDynamicRoute);
};

export const isInRoute = (routeToCheck) => {
  return (
    history.location.pathname.includes(routeToCheck) ||
    dynamicRouteMatches(routeToCheck)
  );
};

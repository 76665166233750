import axios from "axios";
import jwt from "jsonwebtoken";
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../../constants/localStorage";
import {
  // addHeaderToken,
  attachBeforeRequestListener,
} from "../../request/index";
import {
  authScopeStringGetHelper,
  // authScopeSetHelper,
} from "../../util/helpers/authScopeHelpers";
import { logoutUser, refreshUserToken } from "../actions/login/loginActions";
// import { setUserAccessToken } from "../actions/user/userActions";

//Change URL with .env
// const baseURL = "http://192.168.88.143:3001/"; // DULE
// const baseURL = "http://192.168.88.175:3005/";
// const baseURL = "https://trampa-api.dilig.net/";
// const baseURL = "https://trampa-api-test.dilig.net/";
// const baseURL = "http://192.168.88.150:3001/"; // DJOLE
// const baseURL = "http://localhost:3001/";
const baseURL = process.env.REACT_APP_BASE_API_URL;

//Interceptor unique name
export const accessTokensMiddlewareInterceptorName = "ACCESS_TOKEN_INTERCEPTOR";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    attachBeforeRequestListener(async (response) => {
      const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
      const refresh = authScopeStringGetHelper(JWT_REFRESH_TOKEN);
      if (!jwtToken || !refresh) return Promise.resolve(response);
      const jwtTokenDecoded = jwt.decode(jwtToken);
      const refreshTokenDecoded = jwt.decode(refresh);
      if (!response.headers?.Authorization) {
        response.headers.Authorization = `Bearer ${jwtToken}`;
      }

      // If refresh token is expired, log out user
      if (new Date() > new Date(refreshTokenDecoded?.exp * 1000)) {
        dispatch(logoutUser());
        return Promise.resolve(response);
      }
      // If access token is expired, refresh access token
      if (new Date() > new Date(jwtTokenDecoded.exp * 1000)) {
        const axiosResponse = await axios.post(`${baseURL}auth/refresh`, {
          token: refresh,
        });
        const newToken = axiosResponse.data;

        response.headers.Authorization = `Bearer ${newToken}`;
        dispatch(refreshUserToken(newToken));
      }

      return Promise.resolve(response);
    }, accessTokensMiddlewareInterceptorName);

    next(action);
  };

import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const CheckButton = styled(PrimaryButton)`
  width: 224px;
  height: 48px;
  margin-top: 9px;
  margin-right: 9px;
  & button:hover {
    background-color: ${selectedTheme.colors.primaryPurple} !important;
    color: white !important;
  }
  @media (max-width: 1390px) {
    display: none;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

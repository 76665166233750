import { postRequest, getRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const forgotPasswordRequest = (payload) => {
  const encodedMail = encodeURIComponent(payload);
  return getRequest(
    `${apiEndpoints.authentications.forgotPassword}/${encodedMail}`
  );
};

export const resetPasswordRequest = (payload) =>
  postRequest(
    `${apiEndpoints.authentications.resetPassword}/${payload.token}`,
    { password: payload.password, password2: payload.password2 }
  );

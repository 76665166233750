import React from "react";
import PropTypes from "prop-types";
import {
  LogoHorizontalIcon,
  SidebarHeaderContainer,
} from "./SidebarHeader.styled";
import history from "../../../../store/utils/history";
import { ADMIN_HOME_PAGE } from "../../../../constants/pages";

const SidebarHeader = () => {
  const handleLogoClick = () => {
    history.push(ADMIN_HOME_PAGE);
  };
  return (
    <SidebarHeaderContainer>
      <LogoHorizontalIcon onClick={handleLogoClick} />
    </SidebarHeaderContainer>
  );
};

SidebarHeader.propTypes = {
  children: PropTypes.node,
};

export default SidebarHeader;

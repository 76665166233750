import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage as ErrorMessageContainer } from "../../FirstPart/FirstPartOfRegistration.styled";

const ErrorMessage = (props) => {
  const formik = props.formik;
  return (
    <>
      {formik.errors?.phoneNumber && formik.touched?.phoneNumber ? (
        <ErrorMessageContainer>
          {formik.errors.phoneNumber}
        </ErrorMessageContainer>
      ) : formik.errors?.location && formik.touched?.location ? (
        <ErrorMessageContainer>{formik.errors.location}</ErrorMessageContainer>
      ) : formik.errors?.website && formik.touched?.website ? (
        <ErrorMessageContainer>{formik.errors.website}</ErrorMessageContainer>
      ) : (
        <></>
      )}
    </>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.node,
  formik: PropTypes.any,
};

export default ErrorMessage;

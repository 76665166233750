import React from "react";
import PropTypes from "prop-types";
import {
  Arrow,
  PhoneLabel,
  PhoneNumber,
  PhonePopoverContainer,
} from "./PhonePopover.styled";
import { useTranslation } from "react-i18next";

const PhonePopover = (props) => {
  const { t } = useTranslation();
  return (
    <PhonePopoverContainer>
      <Arrow />
      <PhoneLabel>{t("common.labelPhoneNumber")}</PhoneLabel>
      <PhoneNumber>{props.phoneNumber}</PhoneNumber>
    </PhonePopoverContainer>
  );
};

PhonePopover.propTypes = {
  children: PropTypes.node,
  phoneNumber: PropTypes.string,
};

export default PhonePopover;

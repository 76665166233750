import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  FormContainer,
  RegisterDescription,
} from "./FirstPartOfRegistration.styled";
import { useFormik } from "formik";
// import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components/TextFields/TextField/TextField";
import { IconButton } from "../../../../components/Buttons/IconButton/IconButton";
import { ReactComponent as VisibilityOn } from "../../../../assets/images/svg/eye.svg";
import { ReactComponent as VisibilityOff } from "../../../../assets/images/svg/eye-striked.svg";
import { PrimaryButton } from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import selectedTheme from "../../../../themes";
import firstPartValidation from "../../../../validations/registerValidations/firstPartValidation";

const FirstPartOfRegistration = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [emailTakenStatus, setEmailTakenStatus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.error.length > 0) {
      setEmailTakenStatus(true);
    }
  }, [props.error]);

  const formik = useFormik({
    initialValues: {
      mail: props.informations?.mail ?? "",
      registerPassword: props.informations?.registerPassword ?? "",
    },
    validationSchema: firstPartValidation,
    onSubmit: props.handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (!formik.isValid) {
      if (formik.errors.mail) {
        formik.setFieldValue("mail", "");
        formik.setFieldTouched("mail", true);
      }
      if (formik.errors.registerPassword) {
        formik.setFieldValue("registerPassword", "");
        formik.setFieldTouched("registerPassword", true);
      }
    } else {
      formik.handleSubmit(event);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <FormContainer component="form" onSubmit={handleSubmitForm}>
      <RegisterDescription component="p" variant="p">
        {t("register.descriptionFirst")}
      </RegisterDescription>

      <TextField
        name="mail"
        placeholder={t("common.labelEmail")}
        margin="normal"
        value={formik.values.mail}
        onChange={(value) => formik.setFieldValue("mail", value.target.value)}
        error={
          (formik.touched.mail && Boolean(formik.errors.mail)) ||
          emailTakenStatus
        }
        helperText={formik.touched.mail && formik.errors.mail}
        autoFocus
        fullWidth
      />

      <TextField
        name="registerPassword"
        inputProps={{ autocomplete: "new-password" }}
        placeholder={t("common.labelPassword")}
        margin="normal"
        type={showPassword ? "text" : "password"}
        value={formik.values.registerPassword}
        onChange={(value) =>
          formik.setFieldValue("registerPassword", value.target.value)
        }
        error={
          formik.touched.registerPassword &&
          Boolean(formik.errors.registerPassword)
        }
        helperText={
          formik.touched.registerPassword && formik.errors.registerPassword
        }
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <VisibilityOn />}
            </IconButton>
          ),
        }}
      />

      {formik.errors.mail && formik.touched.mail ? (
        <ErrorMessage>{formik.errors.mail}</ErrorMessage>
      ) : formik.errors.registerPassword && formik.touched.registerPassword ? (
        <ErrorMessage>{formik.errors.registerPassword}</ErrorMessage>
      ) : (
        props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}

      <PrimaryButton
        type="submit"
        variant="contained"
        height="48px"
        fullWidth
        buttoncolor={selectedTheme.colors.primaryPurple}
        textcolor="white"
        disabled={
          formik.values.mail.length === 0 ||
          formik.values.registerPassword.length === 0 ||
          formik.values.mail === props.error
        }
      >
        {t("common.continue")}
      </PrimaryButton>
    </FormContainer>
  );
};

FirstPartOfRegistration.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  informations: PropTypes.any,
};

export default FirstPartOfRegistration;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Block } from "../../../../assets/images/svg/block.svg";
import selectedTheme from "../../../../themes";

export const BlockedProfileTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: ${(props) => !props.aboveTitle && `40px`};
  justify-content: center;
`;
export const BlockedProfileText = styled(Typography)`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  /* color: ${(props) =>
    props.redText ? selectedTheme.colors.blockedColor : "white"}; */
  color: ${selectedTheme.colors.blockedTextColor};
`;
export const BlockedContainer = styled(Box)`
  display: flex;
  flex-direction: ${(props) =>
    props.aboveTitle && !props.chatCard ? "row-reverse" : "row"};
  margin-bottom: ${(props) =>
    props.isAdmin ? "4px" : props.chatCard ? "0px" : "18px"};
  gap: 9px;
`;
export const BlockedIconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: ${(props) => !props.aboveTitle && `40px`};
  justify-content: center;
`;
export const BlockedIcon = styled(Block)`
  /* position: relative;
  top: 3px;
  left: 2px; */
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    top: 0;
    left: -2px;
  }
  & path {
    /* stroke: ${(props) =>
      props.redText ? selectedTheme.colors.blockedColor : "white"}; */
    stroke: ${selectedTheme.colors.blockedTextColor};
  }
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredLocations } from "../../store/actions/filters/filtersActions";
import { selectSelectedLocations } from "../../store/selectors/filtersSelectors";
import { selectLocations } from "../../store/selectors/locationsSelectors";

const useLocationsFilter = () => {
  const selectedLocations = useSelector(selectSelectedLocations);
  const dispatch = useDispatch();
  const allLocations = useSelector(selectLocations);
  const [selectedLocationsLocally, setSelectedLocationsLocally] = useState([]);

  useEffect(() => {
    setSelectedLocationsLocally(selectedLocations);
  }, [selectedLocations]);

  // Set selected locations globally
  const setSelectedLocations = (locations, immediateApply = false) => {
    setSelectedLocationsLocally(locations);
    if (immediateApply) {
      dispatch(setFilteredLocations(locations));
    }
  };

  // Find locations from array made from query string, and set locations globally
  const setSelectedLocationsFromArray = (locations) => {
    let locationsToPush = [];
    locations.forEach((locationName) => {
      locationsToPush.push(allLocations.find((p) => p.city === locationName));
    });
    setSelectedLocations([...locationsToPush]);
  };

  const apply = () => {
    dispatch(setFilteredLocations(selectedLocationsLocally));
  };

  const clear = () => {
    setSelectedLocationsLocally([]);
    dispatch(setFilteredLocations([]));
  };

  return {
    selectedLocations,
    selectedLocationsLocally,
    setSelectedLocations,
    setSelectedLocationsFromArray,
    allLocations,
    apply,
    clear,
  };
};

export default useLocationsFilter;

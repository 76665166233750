import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Delete } from "../../../assets/images/svg/trash.svg";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";

export const DeleteCategoryContainer = styled(Box)`
  width: 537px;
  height: ${(props) =>
    ["blockUser", "unblockUser", "deleteUser", "approveUser"].includes(
      props.type
    )
      ? "309px"
      : "274px"};
  position: fixed;
  z-index: 150;
  left: calc(50vw - 268px);
  top: ${(props) =>
    ["blockUser", "unblockUser", "deleteUser", "approveUser"].includes(
      props.type
    )
      ? `calc(50vh - 153px)`
      : `calc(50vh - 137px)`};
  padding-top: 36px;
  padding-bottom: 18px;
  background: white;
  & > * {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 600px) {
    width: 350px;
    left: calc(50vw - 175px);
    height: ${(props) =>
      ["blockUser", "unblockUser", "deleteUser", "approveUser"].includes(
        props.type
      )
        ? "281px"
        : "246px"};
    top: ${(props) =>
      ["blockUser", "unblockUser", "deleteUser", "approveUser"].includes(
        props.type
      )
        ? `calc(50vh - 140px)`
        : `calc(50vh - 123px)`};
  }
`;
export const DeleteIcon = styled(Delete)`
  z-index: 155;
  position: relative;
  top: 10px;
  left: 11px;
  & path {
    stroke: ${selectedTheme.colors.iconYellowColor};
  }
`;
export const CategoryName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  line-height: 21px;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const ReassuranceText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 312px;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 16px;
  line-height: 21px;
  color: ${selectedTheme.colors.messageText};
  margin-top: 36px;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-top: 27px;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin: 36px auto;
  @media (max-width: 600px) {
    margin-bottom: 18px;
    margin-top: 27px;
  }
`;
export const CancelButton = styled(PrimaryButton)`
  width: 180px;
  height: 49px;
  @media (max-width: 600px) {
    width: 149px;
    height: 45px;
  }
`;
export const SaveButton = styled(PrimaryButton)`
  width: 180px;
  height: 49px;
  @media (max-width: 600px) {
    width: 149px;
    height: 45px;
  }
`;

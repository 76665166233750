import React from "react";
import PropTypes from "prop-types";
import SkeletonOfferCard from "../../Cards/OfferCard/SkeletonOfferCard/SkeletonOfferCard";
import OffersList from "./OffersList/OffersList";
import OffersFilterButton from "./OffersFilterButton/OffersFilterButton";
import OffersSearchField from "./OffersSearchField/OffersSearchField";
import OffersNotFound from "./OffersNotFound/OffersNotFound";

const Offers = (props) => {
  const offers = props?.offers;
  const arrayForMapping = Array.apply(null, Array(4)).map(() => {});
  console.log("rerender");

  return (
    <>
      <OffersFilterButton
        offers={props?.offers}
        isAdmin={props?.isAdmin}
        myOffers={props?.myOffers}
        toggleFilters={props?.toggleFilters}
      />
      <OffersSearchField myOffers={props?.myOffers} offers={offers} />
      {!props?.skeleton && <OffersNotFound />}
      <OffersList
        loading={props?.skeleton}
        offers={offers}
        isAdmin={props.isAdmin}
        isGrid={props?.isGrid}
        isUsers={props?.isUsers}
        users={props?.users}
        myOffers={props?.myOffers}
      />
      {props?.skeleton &&
        arrayForMapping.map((item, index) => (
          <SkeletonOfferCard key={index} skeleton={props?.skeleton} />
        ))}
    </>
  );
};

Offers.propTypes = {
  children: PropTypes.node,
  isGrid: PropTypes.bool,
  myOffers: PropTypes.bool,
  skeleton: PropTypes.bool,
  offers: PropTypes.shape({
    apply: PropTypes.func,
    search: PropTypes.shape({
      searchOffers: PropTypes.func,
    }),
    filters: PropTypes.shape({
      numOfFiltersChosen: PropTypes.number,
    }),
  }),
  toggleFilters: PropTypes.func,
  isAdmin: PropTypes.bool,
  isUsers: PropTypes.bool,
  users: PropTypes.array,
};

Offers.defaultProps = {
  myOffers: false,
  users: [],
  isAdmin: false,
  isUsers: false,
  isGrid: false,
  skeleton: false,
  toggleFilters: () => {},
  offers: {
    apply: () => {},
    search: {
      searchOffers: () => {},
    },
    filters: {
      numOfFiltersChosen: 0,
    },
  },
};

export default Offers;

import { css } from 'styled-components';
import { mediaBelow, pxToRem } from './helper';
import primary from './primaryTheme';
import { variables } from './variables';

export const uFlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const uFlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const uFlex = css`
  flex: 1;
`;

export const uButtonClear = css`
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const uOutlineNone = css`
  &,
  &:active,
  &:focus {
    outline: none;
  }
`;

export const uResetPosition = css`
  position: relative;
  top: initial;
  left: initial;
  right: initial;
  bottom: initial;
`;

export const uTextEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const uLineClamp = ({ lines }) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const uTransition = css`
  transition: all ease-in-out ${variables.transitionSpeed.transitionSpeed};
`;

export const uPositionRelative = css`
  position: relative;
`;

export const uColumn = css`
  ${uFlexColumn};
`;

export const uDisplayNone = css`
  display: none;
`;

export const uSuperscript = css`
  font-size: ${pxToRem(14)};
  font-weight: medium;
`;

export const uTextAlignRight = css`
  text-align: right;
`;

export const uTextAlignLeft = css`
  text-align: left;
`;

export const uTextAlignCenter = css`
  text-align: center;
`;

export const uHide = css`
  width: 0;
  height: 0;
  visibility: hidden;
  display: none;
  position: fixed;
  top: -20px;
  right: -20px;
  z-index: -1;
`;

export const uDanger = css`
  color: ${primary.colors.red};

  > * {
    color: ${primary.colors.red};
  }
`;

export const uLabel = css`
  color: ${primary.colors.borderNormal};

  > * {
    color: ${primary.colors.borderNormal};
  }
`;

export const uBold = css`
  font-weight: 600;
`;

export const uItalic = css`
  font-style: italic;
`;

export const uPrimaryColor = css`
  color: ${primary.colors.primaryPurple};

  > * {
    color: ${primary.colors.primaryPurple};
  }
`;

export const uNoUnderline = css`
  text-decoration: none !important;
`;

export const uFullWidth = css`
  width: 100%;
`;

export const uMaxWidth342 = css`
  max-width: 342px;
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    max-width: 100%;
  }
`;

export const uMaxWidth450 = css`
max-width: 450px;
width: 100%;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    max-width: 100%;
  }
`;

export const uTextCapitalize = css`
  text-transform: capitalize;
`;

export const uTextUppercase = css`
  text-transform: uppercase;
`;

export const mr24 = css`
  margin-right: ${pxToRem(32)};
`;

export const ml32 = css `
  margin-left: ${pxToRem(32)};
`;




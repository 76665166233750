import { Box, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { ReactComponent as User } from "../../../assets/images/svg/user.svg";

export const ItemDetailsHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${(props) => (!props.halfwidth ? "100%" : "49%")};
  box-sizing: border-box;
  margin: 10px 0;
  background-color: ${(props) =>
    props.isMyProfile ? selectedTheme.colors.primaryPurple : "white"};
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.borderNormal};
  max-width: 2000px;
  position: relative;

  @media screen and (max-width: 600px) {
    width: calc(100vw - 36px);
  }
`;
export const HeaderTop = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 18px;
  gap: 18px;
`;
export const OfferImage = styled.img`
  border-radius: 50%;
  width: 144px;
  height: 144px;
  @media (max-width: 600px) {
    width: 90px;
    height: 90px;
  }
`;
export const OfferInfo = styled(Box)`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 18px;
`;
export const OfferTitle = styled(Typography)`
  margin-bottom: 12px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${(props) =>
    props.isMyProfile
      ? selectedTheme.colors.primaryYellow
      : selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const OfferAuthor = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const OfferAuthorName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const OfferLocation = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
`;
export const OfferPIB = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${(props) =>
    props.isMyProfile
      ? selectedTheme.colors.primaryDarkTextThird
      : selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
`;
export const OfferDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: ${(props) => (!props.halfwidth ? "no-wrap" : "wrap")};
  justify-content: start;
`;

export const OfferCategory = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 33%;
`;
export const OfferPackage = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 34%;
`;
export const OfferViews = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 34%;
`;
export const OfferDescriptionTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 16px;
`;
export const OfferDescriptionText = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 22px;
  max-width: calc(100% - 230px);
  max-height: 120px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;
export const OfferDescription = styled(Box)`
  flex: 3;
  margin: auto 0;
  padding-left: 35px;
`;
export const Line = styled(Box)`
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 100px;
  width: 0;
  margin: auto 0;
`;

export const CheckButton = styled(PrimaryButton)`
  width: 180px;
  height: 48px;
  position: absolute;
  bottom: 9px;
  right: 9px;
  &:hover button {
    background-color: ${selectedTheme.colors.primaryPurple} !important;
    color: white !important;
  }
`;
export const MessageIcon = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  padding-top: 2px;
  /* position: absolute;
  top: 10px;
  right: 10px; */
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${selectedTheme.colors.borderNormal};
      background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
      & button svg path {
        stroke: ${selectedTheme.colors.iconStrokePurpleDisabledColor};
        padding-top: 1px;
      }
    `}
  text-align: center;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    & button svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: ${(props) => (props.disabled ? "-5px" : "-4px")};
      left: ${(props) => (props.disabled ? "-3px" : "-2px")};
    }
  }
`;
export const UserIconContainer = styled(MessageIcon)`
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
`;
export const UserIcon = styled(User)``;

export const TooltipInnerContainer = styled(Box)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import Link from "../../Link/Link";

export const AboutHeaderContainer = styled(Box)`
  flex: 5;
  z-index: 3000;
  display: flex;
  color: black;
  flex-direction: row;
  justify-content: center;
  gap: 36px;

  @media (min-width: 900px) and (max-width: 1200px) {
    margin-right: -250px;
  }
`;
export const LinkRoute = styled(Link)`
  text-decoration: none;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  border-bottom: 0px solid ${selectedTheme.iconYellowColor};
  &:hover {
    border-bottom: 1px solid ${selectedTheme.colors.iconYellowColor};
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

import { Box, TextField } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const TextFieldContainer = styled(Box)`
  width: 100%;
  height: ${(props) => props.height};
  box-sizing: border-box;
  margin: 16px 0;
  padding-left: 0;
`;

export const TextFieldStyled = styled(TextField)`
  background-color: ${selectedTheme.colors.primaryBackgroundColor};
  width: ${(props) => props.width};
  font-style: ${(props) =>
    props.italicplaceholder === "true" ? "italic" : "normal"};
  padding-left: 0;
  margin: 0;
  padding: 0;
  height: ${(props) => props.height};
  box-sizing: border-box;
  overflow-y: hidden;

  & div {
    padding-left: 2px;
    ${(props) =>
      props.multiline &&
      `
      padding: 10px 16px;
      max-height: ${props.height};
      position: relative;
      height: 100%;
      & textarea {
        height: 100% !important;
        width: 100% ;
        overflow: auto;
        font-size: 16px;
      }
      
    `}
  }
  & div input {
    height: ${(props) => props.height};
    box-sizing: border-box;

    font-size: ${(props) =>
      props.textsize ? props.textsize : "16px"} !important;
    font-family: ${(props) => (props.font ? props.font : "")};
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { CloseIcon, FilterHeaderContainer, Title } from "./FilterHeader.styled";
import { useTranslation } from "react-i18next";
import Link from "../../../Link/Link";
import useIsMobile from "../../../../hooks/useIsMobile";
import selectedTheme from "../../../../themes";

const FilterHeader = (props) => {
  const filters = props.filters;
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const clearFilters = () => {
    if (!props.payments) {
      if (props.isMyOffers) {
        filters.clear();
      } else {
        filters.clearOnlyFiltersAndApply();
      }
      props.toggleFilters();
      props.closeAllSections();
    } else {
      filters.clearOnlyFiltersAndApply();
      props.toggleDrawer();
    }
  };
  return (
    <FilterHeaderContainer>
      <Title>{t("filters.title")}</Title>
      {isMobile ? (
        <CloseIcon
          onClick={props.payments ? props.toggleDrawer : props.toggleFilters}
        />
      ) : (
        <Link
          to="#"
          textsize={"12px"}
          font={selectedTheme.fonts.textFont}
          onClick={clearFilters}
        >
          {t("filters.cancel")}
        </Link>
      )}
    </FilterHeaderContainer>
  );
};

FilterHeader.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.any,
  closeAllSections: PropTypes.func,
  isMyOffers: PropTypes.bool,
  toggleFilters: PropTypes.func,
  toggleDrawer: PropTypes.func,
  payments: PropTypes.bool,
};

export default FilterHeader;

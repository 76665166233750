import styled from "styled-components";
import { Box } from "@mui/material";
import { ReactComponent as Block } from "../../../../assets/images/svg/block.svg";
import { ReactComponent as UnblockGold } from "../../../../assets/images/svg/unblock-gold.svg";
import { ReactComponent as ApproveUser } from "../../../../assets/images/svg/approve-user.svg";
import selectedTheme from "../../../../themes";

export const ButtonsContainer = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  @media (max-width: 600px) {
    gap: 12px;
  }
`;

export const BlockLabelIcon = styled(Block)`
  width: 18px;
  height: 18px;
  position: relative;
  top: 10px;
  left: 11px;
  & path {
    stroke: ${selectedTheme.colors.iconYellowColor};
  }
`;

export const UnblockLabelIcon = styled(UnblockGold)`
  width: 18px;
  height: 18px;
  position: relative;
  top: 10px;
  left: 11px;
`;

export const ApproveUserIcon = styled(ApproveUser)`
  width: 18px;
  height: 18px;
  position: relative;
  top: 10px;
  left: 11px;
  & path {
    stroke: ${selectedTheme.colors.iconYellowColor};
  }
`;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const RequestExchangeMessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 314px;
  margin-bottom: 10px;

  @media (max-width: 445px) {
    width: 100%;
  }
`;
export const RequestExchangeMessageText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;
export const RequestExchangeMessageButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;

  @media (max-width: 375px) {
    gap: 9px;
  }
`;
export const RequestExchangeMessageButton = styled(PrimaryButton)`
  flex: 1;
  height: 45px;
  background: ${(props) => props.white && "white"};
  & button {
    width: 100%;
    font-weight: 600;
    font-family: ${selectedTheme.fonts.textFont};
    letter-spacing: 1.5px;
  }

  @media (max-width: 375px) {
    height: 40px;

    & button {
      letter-spacing: 1px;
    }
  }
`;
export const AlreadyFinishedExhangeMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  line-height: 22px;
  color: ${selectedTheme.colors.primaryText};
`;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox/Checkbox";
import DropdownItem from "../../../../../../Dropdown/DropdownItem/DropdownItem";
import { filterCompanies } from "../../../../../../../util/helpers/filterCompanies";
import {
  REGEXP_FIRST,
  REGEXP_SECOND,
  REGEXP_THIRD,
} from "../../../../../../../constants/filterCompanies";
import {
  SmallDropdownContainer,
  SmallDropdownContent,
  SmallDropdownIcon,
  SmallDropdownText,
} from "./FilterSmallDropdown.styled";

const FilterSmallDropdown = (props) => {
  const [data, setData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setData([...props.dataToShow]);
  }, [props.dataToShow]);

  let dataFiltered;

  if (props.letters === "A-H") {
    dataFiltered = filterCompanies(data, REGEXP_FIRST);
  } else if (props.letters === "I-Q") {
    dataFiltered = filterCompanies(data, REGEXP_SECOND);
  } else if (props.letters === "R-Z") {
    dataFiltered = filterCompanies(data, REGEXP_THIRD);
  }

  const handleChange = (item) => {
    if (props.oneValueAllowed) {
      props.setItemsSelected([item]);
    } else {
      if (
        props.filters.find(
          (itemInList) =>
            itemInList?.companyName?.toString() ===
            item?.companyName?.toString()
        )
      ) {
        props.setItemsSelected([
          ...props.filters.filter(
            (p) => p?.companyName?.toString() !== item?.companyName?.toString()
          ),
        ]);
        props.offers.applyFilters();
      } else {
        props.setItemsSelected([...props.filters, item]);
        props.offers.applyFilters();
      }
    }
  };

  const setDropdownHandler = () => {
    setShowDropdown((prevState) => !prevState);
  };

  return (
    <>
      <SmallDropdownContainer onClick={() => setDropdownHandler()}>
        <SmallDropdownText>{props.letters}</SmallDropdownText>
        <SmallDropdownIcon dropdown={showDropdown} />
      </SmallDropdownContainer>
      <SmallDropdownContent dropdown={showDropdown}>
        {dataFiltered.map((item) => {
          return (
            <DropdownItem key={item._id}>
              <Checkbox
                item={item}
                filters={props.filters}
                onChange={() => handleChange(item)}
                companies={props.companies}
              />
            </DropdownItem>
          );
        })}
      </SmallDropdownContent>
    </>
  );
};

FilterSmallDropdown.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  data: PropTypes.array,
  title: PropTypes.string,
  oneValueAllowed: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setItemsSelected: PropTypes.func,
  filters: PropTypes.array,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  companies: PropTypes.bool,
  letters: PropTypes.any,
  dataToShow: PropTypes.array,
  offers: PropTypes.any,
};
FilterSmallDropdown.defaultProps = {
  oneValueAllowed: false,
};

export default FilterSmallDropdown;

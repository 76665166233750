import { createSelector } from "reselect";

const offersSelector = (state) => state.offers;

export const selectOffers = createSelector(
  offersSelector,
  (state) => state.offers
);

export const selectOffer = createSelector(
  offersSelector,
  (state) => state.selectedOffer
);

export const selectOffersError = createSelector(
  offersSelector,
  (state) => state.error
);
export const selectNoMoreOffers = createSelector(
  offersSelector,
  (state) => state.noMoreOffers
);
export const selectPinnedOffers = createSelector(
  offersSelector,
  (state) => state.pinnedOffers
);
export const selectTotalOffers = createSelector(
  offersSelector,
  (state) => state.total
);
export const selectMineOffers = createSelector(
  offersSelector,
  (state) => state.mineOffers
);
export const selectProfileOffers = createSelector(
  offersSelector,
  (state) => state.profileOffers
);
export const selectFeaturedOfferPage = createSelector(
  offersSelector,
  (state) => state.featuredOfferPage
);
export const selectOfferPage = createSelector(
  offersSelector,
  (state) => state.offerPage
);
export const selectMineHeaderOffers = createSelector(
  offersSelector,
  (state) => state.mineHeaderOffers
);

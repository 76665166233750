import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SET_USER,
  SET_USER_ACCESS_TOKEN,
  SET_USER_ERROR,
  SET_USER_REFRESH_TOKEN,
} from "./userActionConstants";

export const forgotPassword = (payload) => ({
  type: FORGOT_PASSWORD,
  payload,
});
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS
})
export const forgotPasswordError = () => ({
    type: FORGOT_PASSWORD_ERROR
})

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});
export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
})
export const resetPasswordError = () => ({
    type: RESET_PASSWORD_ERROR
})

export const setUserAccessToken = (payload) => ({
  type: SET_USER_ACCESS_TOKEN,
  payload,
});
export const setUserRefreshToken = (payload) => ({
  type: SET_USER_REFRESH_TOKEN,
  payload,
});
export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setUserError = (payload) => ({
  type: SET_USER_ERROR,
  payload,
});

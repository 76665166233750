import styled from "styled-components";
import selectedTheme from "../../../themes";
import { Icon } from "../../Icon/Icon";
import { TextField } from "../TextField/TextField";
import { ReactComponent as Search } from "../../../assets/images/svg/magnifying-glass.svg";


export const EndIcon = styled(Icon)``;
export const SearchIcon = styled(Search)`
  position: relative;
  top: 10px;
  left: 4px;
  cursor: pointer;
  color: ${selectedTheme.colors.primaryPurple};
  & path {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 600px) {
    height: 14px;
    width: 14px;
    left: 11px;
  }
`;
export const SearchInput = styled(TextField)`
  position: relative;
  top: ${props => props.isAdmin && `-60px`};
  & div {
    height: 46px;
    background-color: white;
  }
  & div div input::placeholder {
    font-style: italic;
    font-size: 14px;
    position: relative;
    top: -2px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 36px;
  }
`;

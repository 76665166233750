import React from "react";
import PropTypes from "prop-types";
import { Content, LeftCard, MainLayoutContainer } from "./MainLayout.styled";
import { Grid } from "@mui/material";

const MainLayout = (props) => {
  return (
    <MainLayoutContainer className={props.className}>
      {props.children}
      <Grid container maxHeight="xl">
        <LeftCard item xs={0} sm={0} md={3.5} lg={3.5} xl={3}>
          {props.leftCard}
        </LeftCard>
        <Content item xs={12} sm={12} md={8.5} lg={8.5} xl={9}>
          {props.content}
        </Content>
      </Grid>
    </MainLayoutContainer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  leftCard: PropTypes.node,
  content: PropTypes.node,
  rightCard: PropTypes.node,
  className: PropTypes.string,
};

export default MainLayout;

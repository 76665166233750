import styled from "styled-components";
import { Card, Typography, Grid, Box } from "@mui/material";
import selectedTheme from "../../../themes";

export const ProfileCardContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px;
  margin-top: 34px;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0 36px 0 0;
  }
  @media (max-width: 600px) {
    padding: 0 ${(props) => (props.isAdmin ? "18px" : "0")};
  }
`;

export const ProfileCardWrapper = styled(Card)`
  border: 1px solid ${selectedTheme.colors.borderNormal};
  background: ${(props) =>
    props.blocked
      ? selectedTheme.colors.blockedColor
      : props.isMyProfile
      ? selectedTheme.colors.primaryPurple
      : "white"};
  width: 100%;
  min-width: fit-content;
  padding: 1rem;
  position: relative;
  border-radius: 0 0 4px 4px;
`;

export const ProfileCardHeader = styled(Grid)`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 11px;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  position: relative;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ProfileInfoContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 600px) {
  }
`;
export const ProfileInfoAndContactContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.isAdmin && "36px"};
  @media (max-width: 600px) {
    padding-bottom: ${(props) => (props.isAdmin ? "0" : "18px")};
    margin-bottom: ${(props) => props.isAdmin && "0"};
    ${(props) => props.bigProfileCard && "gap: 18px;"}
    flex-direction: ${(props) => (props.bigProfileCard ? "row" : "column")};
  }
`;

export const BlockedProfileContainer = styled(Box)`
  @media (max-width: 600px) {
    position: absolute;
    top: 16px;
    left: 16px;
  }
`;

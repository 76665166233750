import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const InfoGroup = styled(Box)`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  gap: 4px;
  @media (max-width: 600px) {
    max-width: 82px;
  }
`;
export const InfoIcon = styled(Box)`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
`;
export const InfoText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  text-transform: capitalize;
  color: ${selectedTheme.colors.primaryText};
  font-size: 12px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
  @media (max-width: 360px) {
    max-width: 50px;
  }
`;

import React, { useMemo, useEffect } from "react";
import { PropTypes } from "prop-types";
import Header from "./Header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  ItemDetailsContainer,
  OfferIconContainer,
  OfferIconText,
} from "./ItemDetails.styled";
import ItemDetailsCard from "../Cards/ItemDetailsCard/ItemDetailsCard";
import ItemDetailsHeaderCard from "./ItemDetailsHeaderCard/ItemDetailsHeaderCard";
import { selectOffer } from "../../store/selectors/offersSelectors";
import { selectUserId } from "../../store/selectors/loginSelectors";
import { ReactComponent as OfferIcon } from "../../assets/images/svg/package-gray.svg";
import { useTranslation } from "react-i18next";
import { ONE_OFFER_SCOPE } from "../../store/actions/offers/offersActionConstants";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import SkeletonItemDetails from "./SkeletonItemDetails/SkeletonItemDetails";
import { fetchChats } from "../../store/actions/chat/chatActions";

const ItemDetails = (props) => {
  const offer = useSelector(selectOffer);
  const userId = useSelector(selectUserId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId)
      dispatch(
        fetchChats({
          currentPage: 1,
        })
      );
  }, [userId]);

  const isLoadingOffer = useSelector(
    selectIsLoadingByActionType(ONE_OFFER_SCOPE)
  );
  let isMyProfile = useMemo(() => {
    if (offer?.user?._id?.toString() === userId?.toString() || props.isAdmin)
      return true;
    return false;
  }, [offer, userId, props.isAdmin]);

  return (
    <ItemDetailsContainer>
      <Header />
      {isLoadingOffer || isLoadingOffer === undefined ? (
        <SkeletonItemDetails />
      ) : (
        <>
          {!props.singleOffer && (
            <ItemDetailsHeaderCard
              offer={offer}
              isMyProfile={isMyProfile}
              isAdmin={props?.isAdmin}
            />
          )}
          {props.singleOffer && (
            <OfferIconContainer>
              <OfferIcon />
              <OfferIconText>{t("offer.product")}</OfferIconText>
            </OfferIconContainer>
          )}
          <ItemDetailsCard
            offer={offer}
            isMyOffer={isMyProfile}
            isAdmin={props.isAdmin}
            singleOffer
          />
        </>
      )}
    </ItemDetailsContainer>
  );
};

ItemDetails.propTypes = {
  singleOffer: PropTypes.any,
  isAdmin: PropTypes.bool,
};

export default ItemDetails;

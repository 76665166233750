import { useEffect, useState } from "react";

function getScreenWidth() {
  return window.innerWidth;
}

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(window.innerWidth < 900);
  useEffect(() => {
    const resize = () => {
      if (getScreenWidth() < 900) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  return { isTablet };
};
export default useIsTablet;

import { Box, Grid } from "@mui/material";
// import { Container } from "@mui/system";
import styled from "styled-components";


export const ChatGridLayoutContainer = styled(Box)`
    margin-top: 120px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    @media (max-width: 600px) {
        margin-top: 80px;
    }
`;
export const GridContainer = styled(Grid)`
    max-width: none;
`
export const GridContent = styled(Grid)`
`
export const GridLeftCard = styled(Grid)`
`
import React from "react";
import PropTypes from "prop-types";
import { LinkStyled } from "./Link.styled";

const Link = (props) => {
  return (
    <LinkStyled {...props} href={props.href} onClick={props.onClick}>
      {props.children}
    </LinkStyled>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  font: PropTypes.string,
  align: PropTypes.oneOf(["left", "right", "center"]),
  textsize: PropTypes.string,
  lineheight: PropTypes.string,
  onClick: PropTypes.func,
};
Link.defaultProps = {
  font: "Poppins",
  align: "left",
  textsize: "14px",
};

export default Link;

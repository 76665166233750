import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import {
  BackgroundTransition,
  ItemsTransition,
} from "../../../Styles/globalStyleComponents";

export const SkeletonHeaderContainer = styled(Box)`
  display: ${(props) => (props.skeleton ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  margin-top: 36px;
`;
export const SkeletonUpperPartContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SkeletonHeaderLine = styled(BackgroundTransition)`
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  width: 234px;
  height: 18px;
  @media (max-width: 600px) {
    width: 209px;
  }
`;
export const SkeletonRowGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: -11px;
`;
export const CircleGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
  position: relative;
  top: -3px;
  margin-right: 46px;
  @media (max-width: 1500px) {
    display: none;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;
export const SkeletonHeaderCircle = styled(BackgroundTransition)`
  width: 40px;
  height: 40px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  border-radius: 100%;
  @media (max-width: 600px) {
    &:nth-child(1) {
      display: none;
    }
  }
`;
export const SkeletonHeaderRightLine = styled(BackgroundTransition)`
  width: 209px;
  height: 34px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  @media (max-width: 600px) {
    display: none;
  }
`;
export const SkeletonLowerPartContainer = styled(Box)`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  @media (max-width: 600px) {
    display: flex;
  }
`;
export const SkeletonLowerPartLineOne = styled(BackgroundTransition)`
  width: 72px;
  height: 18px;
`;
export const SkeletonLowerPartLineSecond = styled(BackgroundTransition)`
  width: 137px;
  height: 29px;
`;
export const SkeletonMyOffersHeaderContainer = styled(BackgroundTransition)`
  display: none;
  padding: 16px 12px;
  width: 100%;
  height: 46px;
  margin-top: 10px;
  @media (max-width: 600px) {
    display: ${(props) => (props.myOffers ? "block" : "none")};
  }
`;
export const SkeletonMyOffersHeaderLine = styled(ItemsTransition)`
  width: 108px;
  height: 14px;
`;

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import { ReactComponent as Remove } from "../../../../assets/images/svg/trash-gold.svg";
import { ReactComponent as Pin } from "../../../../assets/images/svg/pin-outlined.svg";
import { ReactComponent as Unpin } from "../../../../assets/images/svg/unpin-outlined.svg";
import selectedTheme from "../../../../themes";
import { Icon } from "../../../Icon/Icon";

export const DeleteOfferContainer = styled(Box)`
  width: 537px;
  height: 309px;
  position: fixed;
  top: calc(50% - 155px);
  left: calc(50% - 268px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px 18px 80px;
  z-index: 150;

  @media screen and (max-width: 600px) {
    width: 350px;
    height: 281px;
    display: block;
    padding: 0 18px 18px 18px;
    left: calc(50% - 175px);
  }
`;

export const OfferInfo = styled(Box)`
  width: 211px;
  height: 90px;
  border: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  padding: 18px;
  margin-top: 36px;
  gap: 9px !important;
  background-color: ${selectedTheme.colors.chatHeaderColor};

  @media screen and (max-width: 600px) {
    margin-left: calc(50% - 105px) !important;
  }
`;

export const OfferImageContainer = styled(Box)`
  width: 54px;
  min-width: 54px;
  height: 54px;
  min-height: 54px;
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    /* margin-right: 13px; */
  }
`;

export const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;

export const DeleteQuestion = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 36px 0;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    display: block;
    margin: 27px auto;
    text-align: center;
    width: 220px;
  }
`;

export const RemoveIconBorder = styled(Icon)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  right: 143px;
  background-color: ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;

  @media screen and (max-width: 600px) {
    right: 50px;
  }
`;

export const RemoveIconContainer = styled(RemoveIconBorder)`
  cursor: default;
  & span {
    position: relative;
    top: 4px;
  }
`;

export const RemoveIcon = styled(Remove)`
  cursor: default;
  position: relative;
  top: 2px;
`;
export const PinIcon = styled(Pin)`
  cursor: default;
  position: relative;
  top: 3px;
  & g path {
    stroke: ${selectedTheme.colors.iconYellowColor};
  }
`;
export const UnpinIcon = styled(Unpin)`
  cursor: default;
  position: relative;
  top: 3px;
  & path {
    stroke: ${selectedTheme.colors.iconYellowColor};
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

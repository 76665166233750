import { Box } from "@mui/material";
import styled from "styled-components";

export const SkeletonChatColumnContainer = styled(Box)`
    margin: 0 18px;
`
export const SkeletonChatCardsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 18px;
`
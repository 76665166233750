import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonChatCardCircle,
  SkeletonChatCardColumnContainer,
  SkeletonChatCardContainer,
  SkeletonChatCardImage,
  SkeletonChatCardLineOne,
  SkeletonChatCardLineSecond,
  SkeletonChatCardLineThird,
} from "./SkeletonChatCard.styled";

const SkeletonChatCard = () => {
  return (
    <SkeletonChatCardContainer>
      <SkeletonChatCardImage />
      <SkeletonChatCardColumnContainer>
        <SkeletonChatCardLineOne />
        <SkeletonChatCardLineSecond />
        <SkeletonChatCardLineThird />
      </SkeletonChatCardColumnContainer>
      <SkeletonChatCardCircle />
    </SkeletonChatCardContainer>
  );
};

SkeletonChatCard.propTypes = {
  children: PropTypes.node,
};

export default SkeletonChatCard;

import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

export const ReviewQuoteContainer = styled(Box)`
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  align-items: center;
  justify-content: start;
  margin-bottom: 21px;
`;
export const ThumbContainer = styled(Grid)`
  max-width: 20px;
`;
export const ReviewQuoteTextContainer = styled(Box)`
  flex: 1;
`;
export const ReviewQuoteText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
  position: relative;
  left: 10px;
  ${(props) => props.quote && `display: none;`}
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const ThumbUp = styled(ThumbUpIcon)`
  position: relative;
  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
    top: 3px;
  }
`;
export const ThumbDown = styled(ThumbDownIcon)`
  top: 3px;
  position: relative;
  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
    top: 3px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  CategoryIcon,
  CategoryIconContainer,
  OfferCategoryContainer,
  OfferDescriptionCategory,
  OfferDescriptionContainer,
  OfferDescriptionTitle,
} from "./OfferDescription.styled";
import selectedTheme from "../../../../../themes";

const OfferDescription = (props) => {
  return (
    <OfferDescriptionContainer className={props?.className}>
      <OfferDescriptionTitle>{props.offerName}</OfferDescriptionTitle>
      <OfferCategoryContainer>
        <CategoryIconContainer
          color={selectedTheme.colors.iconStrokeDisabledColor}
          component="span"
          size="16px"
        >
          <CategoryIcon />
        </CategoryIconContainer>
        <OfferDescriptionCategory>
          {props.categoryName}
        </OfferDescriptionCategory>
      </OfferCategoryContainer>
    </OfferDescriptionContainer>
  );
};

OfferDescription.propTypes = {
  offerName: PropTypes.string,
  categoryName: PropTypes.string,
  className: PropTypes.string,
};

export default OfferDescription;

import { createGlobalStyle } from 'styled-components';
// import OpenSans from "./fonts/OpenSans-Regular.ttf"
// import Poppins from "./fonts/Poppins-Regular.ttf"
// import Mulish from "./fonts/Mulish-Regular.ttf"
import { BaseStyle } from './base';
import { ResetStyle } from './reset';
import { LayoutStyle } from './layout';
import { customClasses } from './customClasses';
const GlobalStyle = createGlobalStyle`

  ${ResetStyle}
  ${BaseStyle}
  ${LayoutStyle}
  ${customClasses}
`;


// @font-face {
//     font-family: 'Open Sans';
//     src: url("Open Sans") format('truetype');
//     font-weight: 400;
//     font-style: normal;
//     font-display: auto;
//   }
//   @font-face {
//     font-family: 'Poppins';
//     src: url(Poppins) format('truetype');
//     font-weight: 400;
//     font-style: normal;
//     font-display: auto;
//   }
//   @font-face {
//     font-family: "Mulish";
//     src: url(${Mulish}) format('truetype');
//     font-weight: 400;
//     font-style: normal;
//     font-display: auto;
//   } }

export default GlobalStyle;
import React from "react";
import PropTypes from "prop-types";
import {
  DownArrow,
  HeaderSelect,
  SelectOption,
} from "./SelectSortField.styled";
import { sortEnum } from "../../../../enums/sortEnum";
import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const SelectSortField = forwardRef((props, ref) => {
  const [sortOption, setSortOption] = useState(sortEnum.INITIAL);

  useImperativeHandle(ref, () => ({
    setSortOption,
    sortOption
  }))

  const handleChangeSelect = (event) => {
    let chosenOption;
    for (const sortOption in sortEnum) {
      if (sortEnum[sortOption].value === event.target.value) {
        chosenOption = sortEnum[sortOption];
        setSortOption(chosenOption);
        props.setSortOption(chosenOption);
      }
    }
  };
  return (
    <HeaderSelect
      value={sortOption?.value ? sortOption.value : sortEnum.INITIAL.value}
      IconComponent={DownArrow}
      onChange={handleChangeSelect}
    >
      <SelectOption
        value={sortEnum.INITIAL.value}
        key={sortEnum.INITIAL.value}
        style={{ display: "none" }}
      >
        {sortEnum.INITIAL.mainText}
      </SelectOption>
      {Object.keys(sortEnum).map((property) => {
        if (sortEnum[property].value === sortEnum.INITIAL.value) return;
        return (
          <SelectOption
            value={sortEnum[property].value}
            key={sortEnum[property].value}
          >
            {sortEnum[property].mainText}
          </SelectOption>
        );
      })}
    </HeaderSelect>
  );
});

SelectSortField.displayName = "SelectSortField";

SelectSortField.propTypes = {
  offersToShow: PropTypes.array,
  setSortOption: PropTypes.func,
};

export default SelectSortField;

import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../themes";
import { CheckButton } from "./CategoryCheckButton.styled";
import { useTranslation } from "react-i18next";

const CategoryCheckButton = (props) => {
  const { t } = useTranslation();
  return (
    <CheckButton
      onClick={props.onClick}
      variant={"outlined"}
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor={selectedTheme.colors.primaryPurple}
      style={{ fontWeight: "600" }}
    >
      {t("admin.categories.checkCategory")}
    </CheckButton>
  );
};

CategoryCheckButton.propTypes = {
  category: PropTypes.any,
  onClick: PropTypes.func,
};

export default CategoryCheckButton;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DrawerButton, DrawerOption } from "../../Drawer.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { PricesIcon } from "./PricesButton.styled";
import history from "../../../../../store/utils/history";
import { ABOUT_PAGE } from "../../../../../constants/pages";
import scrollConstants from "../../../../../constants/scrollConstants";

const PricesButton = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.toggleDrawer();
    history.push({
      pathname: ABOUT_PAGE,
      state: {
        navigation: scrollConstants.about.pricesPage,
        clicked: true,
      },
    });
  };
  return (
    <DrawerButton onClick={handleClick}>
      <IconButton sx={{ borderRadius: "4px" }}>
        <PricesIcon />
      </IconButton>
      <DrawerOption>{t("header.prices")}</DrawerOption>
    </DrawerButton>
  );
};

PricesButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default PricesButton;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";

export const AdminButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 18px;
  background: ${(props) =>
    props.isSelected
      ? selectedTheme.colors.backgroundSponsoredColor
      : "transparent"};
  width: 100vw;
  height: 61px;
  padding: 18px;
  border-radius: 4px;
`;
export const AdminButtonText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: ${(props) => (props.isSelected ? "600" : "400")};
  font-size: 18px;
  line-height: 25px;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const AdminButtonIcon = styled(Box)`
  & svg {
    width: 24px;
    height: 24px;
  }
  & svg path {
    stroke: ${(props) =>
      props.isSelected
        ? selectedTheme.colors.iconYellowColor
        : selectedTheme.colors.iconStrokeDisabledColor};
  }
  & svg g path {
    stroke: ${(props) =>
      props.isSelected
        ? selectedTheme.colors.iconYellowColor
        : selectedTheme.colors.iconStrokeDisabledColor};
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabelStyled,
  RadioButtonContainer,
  RadioButtonStyled,
} from "./RadioButton.styled";
import { ReactComponent as RadioChecked } from "../../../assets/images/svg/radio-checked.svg";
import { ReactComponent as RadioUnchecked } from "../../../assets/images/svg/radio-unchecked.svg";
import { Label } from "../../CheckBox/Label";

const RadioButton = (props) => {
  return (
    <RadioButtonContainer fullwidth={props.fullWidth ? 1 : 0}>
      <FormControlLabelStyled
        value={props.value}
        fullwidth={props.fullWidth ? 1 : 0}
        control={
          <RadioButtonStyled
            icon={<RadioUnchecked />}
            onChange={() => props.onChange(props.value)}
            checkedIcon={<RadioChecked />}
            checked={props.checked}
          />
        }
        label={
          <Label
            leftText={props.label}
            rightText={props.number}
            onClick={() => props.onChange(props.value)}
          />
        }
      />
    </RadioButtonContainer>
  );
};

RadioButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  label: PropTypes.string,
  number: PropTypes.number,
  fullWidth: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

RadioButton.defaultProps = {
  fullWidth: false,
};

export default RadioButton;

import React from "react";
import PropTypes from "prop-types";
import {
  PrimaryButtonContainer,
  PrimaryButtonStyled,
} from "./PrimaryButton.styled";
import selectedTheme from "../../../themes";

export const PrimaryButton = (props) => {
  return (
    <PrimaryButtonContainer
      style={props.containerStyle}
      className={props.className}
    >
      <PrimaryButtonStyled
        {...props}
        buttoncolor={props.buttoncolor}
        sx={props.style}
      >
        {props.children}
      </PrimaryButtonStyled>
    </PrimaryButtonContainer>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  style: PropTypes.any,
  containerStyle: PropTypes.any,
  fullWidth: PropTypes.bool,
  buttoncolor: PropTypes.string,
  textcolor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  font: PropTypes.string,
};

PrimaryButton.defaultProps = {
  font: selectedTheme.fonts.textFont,
};

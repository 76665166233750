import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AddOfferButton,
  AddPlusIcon,
  MarketPlaceContainer,
} from "./MarketPlace.styled";
import Header from "./Header/Header";
import Offers from "./Offers/Offers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchChats } from "../../store/actions/chat/chatActions";
import { selectUserId } from "../../store/selectors/loginSelectors";
import { toggleCreateOfferModal } from "../../store/actions/modal/modalActions";
import useIsMobile from "../../hooks/useIsMobile";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";

const MarketPlace = (props) => {
  const [isGrid, setIsGrid] = useState(false);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { isLoggedIn } = useIsLoggedIn();
  useEffect(() => {
    console.log("ABG", userId);
    if (userId)
      dispatch(
        fetchChats({
          currentPage: 1,
        })
      );
  }, [userId]);
  const offers = props.offers;
  const addOfferHandler = () => {
    dispatch(toggleCreateOfferModal());
  };
  return (
    <MarketPlaceContainer>
      <Header
        isGrid={isGrid}
        setIsGrid={setIsGrid}
        myOffers={props.myOffers}
        sorting={props.offers.sorting}
        offers={props.offers}
        skeleton={props.skeleton}
        isAdmin={props.isAdmin}
        users={props.users}
      />

      <Offers
        isGrid={isGrid}
        myOffers={props.myOffers}
        skeleton={props.skeleton}
        offers={offers}
        toggleFilters={props.toggleFilters}
        isAdmin={props.isAdmin}
        isUsers={props.users}
        users={props.allUsers}
      />
      {isMobile && isLoggedIn && (
        <AddOfferButton onClick={addOfferHandler}>
          <AddPlusIcon />
        </AddOfferButton>
      )}
    </MarketPlaceContainer>
  );
};

MarketPlace.propTypes = {
  children: PropTypes.node,
  myOffers: PropTypes.bool,
  skeleton: PropTypes.bool,
  offers: PropTypes.any,
  toggleFilters: PropTypes.func,
  isAdmin: PropTypes.bool,
  users: PropTypes.bool,
  allUsers: PropTypes.array,
};
MarketPlace.defaultProps = {
  offers: {
    sorting: {},
    filters: {
      numOfFiltersChosen: 0,
    },
  },
};

export default MarketPlace;

import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const ResetPasswordPageContainer = styled(Container)`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 900px) {
    margin-top: 140px;
  }
  @media (max-height: 800px) {
    margin-top: 70px;
  }
`;
export const ResetPasswordTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
`;
export const ResetPasswordDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 270px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
`;
export const FormContainer = styled(Box)`
  width: 335px;
  height: 216px;
`;

export const Footer = styled(Box)`
  position: absolute;
  bottom: 36px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;
export const FooterText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  padding-right: 6px;
  text-align: center;
  width: 340px;
  line-height: 22px;
  font-weight: 400;
  padding: 0;
  font-size: 16px;
`;

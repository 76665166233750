import {
  CHECK_ID_NUMBER_EXIST_FETCH,
  CHECK_ID_NUMBER_EXIST_FETCH_ERROR,
  CHECK_ID_NUMBER_EXIST_FETCH_SUCCESS,
  REGISTER_USER_FETCH,
  REGISTER_USER_FETCH_ERROR,
  REGISTER_USER_FETCH_SUCCESS,
} from "./registerActionConstants";

export const fetchRegisterUser = (payload) => ({
  type: REGISTER_USER_FETCH,
  payload,
});
export const fetchRegisterUserSuccess = () => ({
  type: REGISTER_USER_FETCH_SUCCESS,
});
export const fetchRegisterUserError = () => ({
  type: REGISTER_USER_FETCH_ERROR,
});
export const fetchCheckIdNumberExist = (payload) => ({
  type: CHECK_ID_NUMBER_EXIST_FETCH,
  payload,
});
export const fetchCheckIdNumberExistSuccess = () => ({
  type: CHECK_ID_NUMBER_EXIST_FETCH_SUCCESS,
});
export const fetchCheckIdNumberExistError = () => ({
  type: CHECK_ID_NUMBER_EXIST_FETCH_ERROR,
});

import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  BlogIcon,
  CategoryIcon,
  FilterButtonContainer,
  FilterButtonIcon,
  HeaderContainer,
  HeaderOptions,
  HeaderWrapperContainer,
  LocationIcon,
  PageTitleContainer,
  PaymentsIcon,
  SubcategoryIcon,
  SwapsHeaderIcon,
  SwapsIcon,
  SwapsTitle,
  UserIcon,
} from "./Header.styled";
import { sortEnum } from "../../../enums/sortEnum";
import { useTranslation } from "react-i18next";
import SkeletonHeader from "./SkeletonHeader/SkeletonHeader";
import useIsTablet from "../../../hooks/useIsTablet";

// import { ArrowButton } from "../../Buttons/ArrowButton/ArrowButton";
import TooltipHeader from "./TooltipHeader/TooltipHeader";
import GridButtons from "./GridButtons/GridButtons";
import HeaderSelect from "./HeaderSelect/HeaderSelect";
// import DrawerContainer from "../../Header/DrawerContainer/DrawerContainer";
// import HeaderTitle from "../../Profile/ProfileOffers/HeaderTitle/HeaderTitle";
import Drawer from "./Drawer/Drawer";
import HeaderBack from "../../Profile/Header/Header";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const drawerRef = useRef(null);
  const history = useHistory();
  const isAdminCategories =
    history.location.pathname.includes("/admin/categories/");
  const { t } = useTranslation();
  const sorting = props?.sorting;
  const { isTablet } = useIsTablet();

  const disableSmallTitle = useMemo(() => {
    if (
      history.location.pathname.includes("/admin/users") ||
      history.location.pathname.includes("/blog")
    ) {
      return true;
    } else {
      return false;
    }
  }, [history.location.pathname]);

  // Changing header string on refresh or on load
  const altString = useMemo(() => {
    if (!props?.users) {
      if (sorting?.selectedSortOption === sortEnum.OLD) {
        return `: ${t("header.oldOffers")}`;
      }
      if (sorting?.selectedSortOption === sortEnum.POPULAR) {
        return `: ${t("header.popularOffers")}`;
      }
    }
    return `: ${t("header.newOffers")}`;
  }, [sorting?.selectedSortOption]);

  const headerTitle = useMemo(() => {
    return props.users
      ? t("admin.users.headerTitle")
      : props.categories
      ? t("admin.categories.headerTitle")
      : props.subcategories
      ? t("admin.subcategories.subcategoriesHeaderTitle")
      : props.category
      ? t("admin.subcategories.headerTitle")
      : props.location
      ? t("admin.locations.headerTitle")
      : props.payments
      ? t("admin.payment.headerTitle")
      : props.blog
      ? t("blog.headerTitle")
      : !isTablet
      ? t("header.myOffers")
      : props.myOffers
      ? t("header.myOffers")
      : t("offer.offers");
  }, [props, t, isTablet]);
  const headerIcon = useMemo(() => {
    return props.users ? (
      <UserIcon />
    ) : props.categories || props.category ? (
      <CategoryIcon />
    ) : props.subcategories ? (
      <SubcategoryIcon />
    ) : props.location ? (
      <LocationIcon />
    ) : props.payments ? (
      <PaymentsIcon />
    ) : props.blog ? (
      <BlogIcon />
    ) : isTablet ? (
      <SwapsIcon />
    ) : (
      <SwapsHeaderIcon />
    );
  });

  return (
    <>
      {props?.skeleton && (
        <SkeletonHeader skeleton={props?.skeleton} myOffers={props?.myOffers} />
      )}
      <HeaderWrapperContainer
        className={props.className}
        skeleton={props?.skeleton}
        isAdmin={props?.isAdmin}
      >
        {props?.myOffers && <HeaderBack />}
        <HeaderContainer myOffers={props?.myOffers}>
          {/* Setting appropriate header title if page is market place or my offers */}
          <TooltipHeader
            altText={altString}
            headerTitle={headerTitle}
            headerIcon={headerIcon}
            offers={props?.offers}
            myOffers={props?.myOffers}
            hideBackButton={props?.hideBackButton}
          />
          {/* <HeaderTitle isMyProfile={props?.myOffers} /> */}
          {/* ^^^^^^ */}

          <HeaderOptions>
            <GridButtons
              hideGrid={props?.hideGrid}
              isGrid={props?.isGrid}
              setIsGrid={props?.setIsGrid}
            />

            {/* Select option to choose sorting */}
            {!props?.hideSorting && (
              <HeaderSelect
                myOffers={props?.myOffers}
                sorting={sorting}
                hideSorting={props?.hideSorting}
              />
            )}
            {props.payments && (
              <FilterButtonContainer
                onClick={drawerRef.current?.handleToggleDrawer}
              >
                <FilterButtonIcon />
              </FilterButtonContainer>
            )}
            {/* ^^^^^^ */}
          </HeaderOptions>
        </HeaderContainer>
        {isTablet && (
          <PageTitleContainer
            myOffers={props?.myOffers}
            isAdminCategories={isAdminCategories}
            disableSmallTitle={disableSmallTitle}
          >
            {headerIcon}
            <SwapsTitle>{headerTitle}</SwapsTitle>
          </PageTitleContainer>
        )}
      </HeaderWrapperContainer>
      <Drawer ref={drawerRef} />
      {/* <DrawerContainer ref={drawerRef} /> */}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  setIsGrid: PropTypes.func,
  isGrid: PropTypes.bool,
  offers: PropTypes.any,
  myOffers: PropTypes.bool,
  skeleton: PropTypes.bool,
  sorting: PropTypes.any,
  isAdmin: PropTypes.bool,
  users: PropTypes.bool,
  categories: PropTypes.bool,
  hideGrid: PropTypes.bool,
  className: PropTypes.string,
  hideBackButton: PropTypes.bool,
  category: PropTypes.bool,
  subcategories: PropTypes.bool,
  hideSorting: PropTypes.bool,
  location: PropTypes.bool,
  payments: PropTypes.bool,
  blog: PropTypes.bool,
};
Header.defaultProps = {
  isGrid: false,
};

export default Header;

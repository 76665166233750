import React from "react";
import SkeletonOfferCard from "../../Cards/OfferCard/SkeletonOfferCard/SkeletonOfferCard";
import {
  SkeletonDirectChatHeadingFirst,
  SkeletonDirectChatHeadingSecond,
} from "./SkeletonDirectChat.styled";

const SkeletonDirectChat = () => {
  return (
    <>
      <SkeletonDirectChatHeadingFirst />
      <SkeletonDirectChatHeadingSecond />
      <SkeletonOfferCard aboveChat />
    </>
  );
};

export default SkeletonDirectChat;

import styled from "styled-components";
import { Box } from "@mui/material";
import selectedTheme from "../../../themes";

export const AutoSuggestTextFieldContainer = styled(Box)`
  & .react-autosuggest__container {
    width: 100%;
    height: ${(props) => (props.editLocation ? "43px" : "48px")};
    position: relative;
    z-index: 20;
    & input {
      width: 100%;
      height: ${(props) => (props.editLocation ? "43px" : "48px")};
      padding: 4px 14px;
      border-radius: 4px;
      border: 1px solid
        ${(props) =>
          props.error
            ? selectedTheme.colors.errorColor
            : "rgba(0, 0, 0, 0.23)"};
      outline-width: 0;
      background-color: initial;
      font-family: ${selectedTheme.fonts.textFont};
      font-size: 16px;
      padding-bottom: 6px;
    }
    /* & input:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
    } */
    & input:focus-visible {
      border: 2px solid
        ${(props) =>
          props.error
            ? selectedTheme.colors.errorColor
            : selectedTheme.colors.primaryPurple};
    }
    & input::placeholder {
      color: rgba(0, 0, 0, 0.38);
    }

    & div {
      z-index: 3000;
      background-color: ${selectedTheme.colors.primaryBackgroundColor};
    }

    & div ul {
      max-height: 220px;
      border: 1px solid black;
      border-radius: 4px;
      display: ${(props) => props.hideSuggestions && "none"};
      padding: 10px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 3px;
      }
    }

    & div ul li {
      padding-left: 16px;
      cursor: pointer;
      height: 40px;
      padding-top: 10px;
      font-family: ${selectedTheme.fonts.textFont};
      border-radius: 4px;
    }

    & div ul li:hover {
      background-color: ${selectedTheme.colors.primaryPurple};
      color: ${selectedTheme.colors.primaryBackgroundColor};
    }
  }

  @media (max-width: 600px) {
    & .react-autosuggest__container {
      & input {
        /* font-size: 13px; */
      }
    }
  }
`;

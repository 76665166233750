import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { Label } from "../../CheckBox/Label";
import { TextField } from "../../TextFields/TextField/TextField";

export const LinkPopoverContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 9px 18px;
  gap: 9px;
  width: 311px;
  background: white;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;
export const FieldLabel = styled(Label)`
  position: relative;
  bottom: -4px;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
  }
  @media (max-width: 600px) {
    & label {
      font-size: 9px;
    }
  }
`;
export const FinishButton = styled(PrimaryButton)`
  height: 42px;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple};
    color: white !important;
    border-radius: 4px;
  }
`;
export const LinkField = styled(TextField)`
    margin: 0;
`
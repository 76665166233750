import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import { CompanyIcon } from "./CompanyChoser.styled";
import { useTranslation } from "react-i18next";
import FilterSubDropdown from "../../FilterDropdown/Checkbox/FilterSubDropdown/FilterSubDropdown";

const CompanyChoser = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(false);
  const filters = props.filters;
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    closeSection: () => {
      setIsOpened(false);
    },
  }));

  useEffect(() => {
    if (filters.companies.selectedCompaniesLocally.length > 0 && !isOpened) {
      setIsOpened(true);
    }
  }, [filters.companies.selectedCompaniesLocally]);

  useEffect(() => {
    if (appliedFilters) {
      props?.offers?.apply();
      setAppliedFilters(false);
    }
  }, [filters.companies.selectedCompaniesLocally]);

  const handleSetItemsSelected = (items) => {
    filters.companies.setSelectedCompanies(items);
    setAppliedFilters(true);
  };

  return (
    <FilterSubDropdown
      searchPlaceholder={t("filters.company.placeholder")}
      data={[...filters.companies.allCompanies]}
      filters={[...filters.companies.selectedCompaniesLocally]}
      open={isOpened}
      handleOpen={() => setIsOpened((prevIsOpened) => !prevIsOpened)}
      icon={<CompanyIcon />}
      title={t("filters.company.title")}
      setItemsSelected={handleSetItemsSelected}
      companies
      offers={props.offers}
    />
  );
});

CompanyChoser.displayName = "CompanyChoser";

CompanyChoser.propTypes = {
  filters: PropTypes.any,
  offers: PropTypes.any,
};

export default CompanyChoser;

import React from "react";
import PropTypes from "prop-types";
import {
  InfoIcon,
  MessageContent,
  MessageDate,
  MessageText,
  RequestExchangeCardContainer,
} from "./RequestExchangeCard.styled";
import { getMessageDate } from "../../../util/helpers/dateHelpers";
import { useSelector } from "react-redux";
import {
  selectExchange,
  selectRequester,
} from "../../../store/selectors/exchangeSelector";
import { useMemo } from "react";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import requesterStatus from "../../../constants/requesterStatus";
import { useTranslation } from "react-i18next";
import RequestExchangeMessage from "./RequestExchangeMessage/RequestExchangeMessage";

const RequestExchangeCard = (props) => {
  const dateString = getMessageDate(new Date(props?.message?._created));
  const { t } = useTranslation();
  const userId = useSelector(selectUserId);
  const requester = useSelector(selectRequester);
  const exchange = useSelector(selectExchange);
  const amIBuyer = useMemo(
    () => exchange?.buyer?.user?._id === userId,
    [exchange, userId]
  );
  const haveIAccepted = useMemo(
    () => (amIBuyer ? exchange?.buyer?.accepted : exchange?.seller?.accepted),
    [amIBuyer, exchange]
  );
  const interlocutorUserId = useMemo(
    () => (amIBuyer ? exchange?.seller?.user?._id : exchange?.buyer?.user?._id),
    [exchange, amIBuyer]
  );
  const message = useMemo(() => {
    if (requester === requesterStatus.ME) {
      if (props.isMyMessage) {
        return (
          <MessageText ismymessage={props.isMyMessage}>
            {t("messages.requestSent")}
          </MessageText>
        );
      } else {
        return (
          <MessageText ismymessage={props.isMyMessage}>
            {t("messages.requestAccepted")}
          </MessageText>
        );
      }
    } else if (requester === requesterStatus.interlocutor) {
      return (
        <RequestExchangeMessage
          haveIAccepted={haveIAccepted}
          chatId={props.chatId}
          userId={userId}
          interlocutorUserId={interlocutorUserId}
        />
      );
    }
    return "";
  }, [requester, t, haveIAccepted, interlocutorUserId, userId, exchange]);
  return (
    <RequestExchangeCardContainer ismymessage={props.isMyMessage}>
      <InfoIcon />
      <MessageContent ismymessage={props.isMyMessage}>
        {message}
        <MessageDate ismymessage={props.isMyMessage}>{dateString}</MessageDate>
      </MessageContent>
    </RequestExchangeCardContainer>
  );
};

RequestExchangeCard.propTypes = {
  isMyMessage: PropTypes.bool,
  message: PropTypes.any,
  chatId: PropTypes.string,
};

export default RequestExchangeCard;

import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { ItemDetailsPageContainer } from "./ItemDetailsPage.styled";
import { useDispatch, useSelector } from "react-redux";
import ItemDetails from "../../components/ItemDetails/ItemDetails";
import ItemDetailsLayout from "../../layouts/ItemDetailsLayout/ItemDetailsLayout";
import {
  clearSelectedOffer,
  fetchOneOffer,
} from "../../store/actions/offers/offersActions";
import UserReviews from "../../components/UserReviews/UserReviews";
import { selectOffer } from "../../store/selectors/offersSelectors";
import ProfileMini from "../../components/ProfileMini/ProfileMini";
import history from "../../store/utils/history";

const ItemDetailsPage = (props) => {
  const dispatch = useDispatch();
  const selectedOffer = useSelector(selectOffer);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const offerId = props.match.params.offerId;

  useEffect(() => {
    const view = history?.location?.state?.view;
    console.log(view)
    dispatch(fetchOneOffer({ offerId, view }));
    () => dispatch(clearSelectedOffer());
  }, []);

  useEffect(() => {
    if (!selectedOffer?.offer && isInitiallyLoaded) {
      dispatch(fetchOneOffer(offerId));
    }
    if (selectedOffer?.offer) {
      setIsInitiallyLoaded(true);
    }
  }, [selectedOffer?.offer]);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  return (
    <ItemDetailsPageContainer>
      <ItemDetailsLayout
        singleOffer
        content={<ItemDetails singleOffer />}
        rightCard={
          <>
            <ProfileMini /> <UserReviews rightReviews />
          </>
        }
      />
    </ItemDetailsPageContainer>
  );
};

ItemDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      offerId: PropTypes.string,
    }),
  }),
};

export default ItemDetailsPage;

import React from "react";
import PropTypes from "prop-types";
import { IconWithNumberContainer, Number } from "./IconWithNumber.styled";

const IconWithNumber = (props) => {
  return (
    <IconWithNumberContainer
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
      {props.number > 0 && <Number>{props.number}</Number>}
    </IconWithNumberContainer>
  );
};

IconWithNumber.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconWithNumber;

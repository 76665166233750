import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const CategoryCardContainer = styled(Box)`
  background: white;
  height: 84px;
  width: calc(100% - 10px);
  margin: 5px;
  margin-top: 13px;
  margin-bottom: 13px;
  border: 1px solid ${selectedTheme.colors.borderNormal};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1050px) {
    height: 102px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 102px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;
export const CategoryCardLeftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const CategoryCardRightContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  @media (max-width: 345px) {
    position: absolute;
    right: 0;
  }
`;
export const CategoryCardDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  @media (max-width: 1050px) {
    position: absolute;
    bottom: 50px;
    left: -13px;
    max-height: 16px;
  }
  @media (max-width: 600px) {
    position: absolute;
    bottom: 18px;
    left: 0;
    max-height: 16px;
  }
`;

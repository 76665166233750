import i18next from "i18next";
import { attachPostRequestListener } from "../../request";
import { logoutUser } from "../actions/login/loginActions";
import { makeErrorToastMessage } from "../utils/makeToastMessage";

//Interceptor unique name
export const authenticationMiddlewareInterceptorName =
  "AUTHENTICATION_MIDDLEWARE";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    attachPostRequestListener((error) => {
      if (!error.response) {
        makeErrorToastMessage(i18next.t("apiErrors.somethingWentWrong"));
        return Promise.reject(error);
      }
      if (error.response.status === 400) {
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        dispatch(logoutUser());
        return Promise.reject(error);
      }
      return Promise.resolve();
      // return Promise.resolve(error);
    }, authenticationMiddlewareInterceptorName);

    next(action);
  };

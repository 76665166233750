import { Container } from "@mui/system";
import styled from "styled-components";
import { transitionOnLoadFromRight } from "../../components/Styles/globalStyleComponents";

export const DirectChatPageContainer = styled(Container)`
max-width: none;
  @media (max-width: 600px) {
    animation: 0.2s ease 0s 1 ${transitionOnLoadFromRight};
  }
`;
export const SwiperContainer = styled.div``;

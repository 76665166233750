import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  ReviewList,
  ReviewsBox,
  ReviewsHeader,
  ReviewsHeaderTitle,
  ReviewsTitle,
  UserReviewsContainer,
} from "./UserReviews.styled";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import UserReviewsCard from "../Cards/UserReviewsCard/UserReviewsCard";
import NoReviews from "./NoReviews/NoReviews";
import { useDispatch, useSelector } from "react-redux";
import { selectOffer } from "../../store/selectors/offersSelectors";
import { selectSelectedReviews } from "../../store/selectors/reviewSelector";
import { useRouteMatch } from "react-router-dom";
import { fetchReviews } from "../../store/actions/review/reviewActions";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import SkeletonUserReviews from "./SkeletonUserReviews/SkeletonUserReviews";
import { ONE_OFFER_SCOPE } from "../../store/actions/offers/offersActionConstants";
import { REVIEW_GET_SCOPE } from "../../store/actions/review/reviewActionConstants";
import ReviewsSorting from "./ReviewsSorting/ReviewsSorting";
import { dynamicRouteMatches } from "../../util/helpers/routeHelpers";
import {
  ADMIN_SINGLE_USER_PAGE,
  DIRECT_CHAT_PAGE,
  PROFILE_PAGE,
} from "../../constants/pages";
import { selectUserId } from "../../store/selectors/loginSelectors";
import { reviewEnum } from "../../enums/reviewEnum";

const UserReviews = (props) => {
  const [isGiven, setIsGiven] = useState(true);
  const [positiveReviews, setPositiveReviews] = useState(false);
  const [negativeReviews, setNegativeReviews] = useState(false);
  const [filteredReviews, setFilteredReviews] = useState();
  const [recivedReviews, setRecivedReviews] = useState();
  const { t } = useTranslation();
  const offer = useSelector(selectOffer);
  const reviews = useSelector(selectSelectedReviews);
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const sortRef = useRef(null);
  const mineUserId = useSelector(selectUserId);
  const isLoadingReview = useSelector(
    selectIsLoadingByActionType(
      props.givingReview ? ONE_OFFER_SCOPE : REVIEW_GET_SCOPE
    )
  );
  useEffect(() => {
    setRecivedReviews(reviews.filter((review) => review.user._id !== userId));
  }, [reviews]);

  useEffect(() => {
    if (!positiveReviews && !negativeReviews)
      setFilteredReviews(recivedReviews);
    if (positiveReviews)
      setFilteredReviews(
        recivedReviews
          .filter((review) => review.user._id !== userId)
          .filter(
            (review) =>
              review.succeeded === reviewEnum.YES.backendText &&
              review.communication === reviewEnum.YES.backendTextSecond
          )
      );
    if (negativeReviews)
      setFilteredReviews(
        recivedReviews.filter(
          (review) => review.succeeded === reviewEnum.NO.backendText
        )
      );
  }, [recivedReviews, positiveReviews, negativeReviews]);

  const userId = useMemo(() => {
    if (
      dynamicRouteMatches(PROFILE_PAGE) ||
      dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE)
    ) {
      return routeMatch.params.profileId;
    }
    if (dynamicRouteMatches(DIRECT_CHAT_PAGE)) {
      return mineUserId;
    }
    return offer?.user?._id;
  }, [offer, routeMatch.params]);

  useEffect(() => {
    if (!props?.givingReview && userId) {
      dispatch(fetchReviews(userId));
    }
  }, [props.givingReview, userId]);

  const lastThreeReviews = useMemo(() => {
    if (props.givingReview) return [props.givingReview];
    if (Array.isArray(reviews) && !props.isAdmin) {
      return reviews.filter((singleReview) => {
        let userWhoRecievedReview =
          singleReview?.exchange?.buyer?.user?._id === singleReview?.user?._id
            ? "seller"
            : "buyer";
        return (
          singleReview?.exchange[userWhoRecievedReview]?.user?._id === userId
        );
      });
    }
    if (Array.isArray(reviews)) {
      return reviews.reduce((result, singleReview) => {
        let userWhoGaveReview =
          singleReview?.exchange?.buyer?.user?._id === singleReview?.user?._id
            ? "buyer"
            : "seller";
        let userWhoRevievedReview =
          userWhoGaveReview === "buyer" ? "seller" : "buyer";
        if (props.isAdmin) {
          if (isGiven) {
            if (
              userId === singleReview?.exchange[userWhoGaveReview]?.user?._id
            ) {
              result.push(singleReview);
            }
          } else {
            if (
              userId ===
              singleReview?.exchange[userWhoRevievedReview]?.user?._id
            ) {
              result.push(singleReview);
            }
          }
        }
        return result;
      }, []);
    }
    return [];
  }, [props.profileReviews, offer, props.isProfileReviews, reviews, isGiven]);

  const handleChangeSorting = (newSortOption) => {
    listRef.current.scrollTo({ top: 0, behaviour: "smooth" });
    if (props.isAdmin) {
      if (newSortOption.value === 1) {
        setIsGiven(true);
      } else {
        setIsGiven(false);
      }
    } else {
      if (newSortOption.value === 1) {
        setPositiveReviews(true);
        setNegativeReviews(false);
      }
      if (newSortOption.value === 2) {
        setNegativeReviews(true);
        setPositiveReviews(false);
      }
    }
  };

  return (
    <UserReviewsContainer
      className={props.className}
      givingReview={props.givingReview}
    >
      {!props.givingReview &&
      (isLoadingReview || isLoadingReview === undefined) ? (
        <SkeletonUserReviews />
      ) : (
        <ReviewsBox
          isAdmin={props.isAdmin}
          profile={props.isProfileReviews}
          numOfReviews={lastThreeReviews?.length}
        >
          {!props.givingReview && (
            <ReviewsHeader
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ mb: 1.4 }}
            >
              <ReviewsHeaderTitle>
                <StarBorderIcon
                  color="action"
                  sx={{ mr: 0.9, position: "relative", top: 6 }}
                />
                <ReviewsTitle>{t("reviews.rates")}</ReviewsTitle>
              </ReviewsHeaderTitle>
              <ReviewsSorting
                changeSorting={handleChangeSorting}
                ref={sortRef}
                isAdmin={props.isAdmin}
              />
            </ReviewsHeader>
          )}
          <ReviewList ref={listRef} isProfileReviews={props.isProfileReviews}>
            {dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE) ? (
              lastThreeReviews?.length > 0 ? (
                lastThreeReviews?.map((review, index) => (
                  <UserReviewsCard
                    showRemoveIcon={props.isAdmin}
                    review={review}
                    key={index}
                    isProfileReviews={props?.isProfileReviews}
                    userId={userId}
                    isAdmin={props.isAdmin}
                    hasGivenReview={isGiven}
                    givingReview={props.givingReview}
                    rightReviews={props.rightReviews}
                  />
                ))
              ) : (
                <NoReviews fullHeight={props.fullHeight} />
              )
            ) : filteredReviews?.length > 0 ? (
              filteredReviews?.map((review, index) => (
                <UserReviewsCard
                  showRemoveIcon={props.isAdmin}
                  review={review}
                  key={index}
                  isProfileReviews={props?.isProfileReviews}
                  userId={userId}
                  isAdmin={props.isAdmin}
                  hasGivenReview={isGiven}
                  givingReview={props.givingReview}
                  rightReviews={props.rightReviews}
                />
              ))
            ) : (
              <NoReviews fullHeight={props.fullHeight} />
            )}
          </ReviewList>
        </ReviewsBox>
      )}
    </UserReviewsContainer>
  );
};

UserReviews.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  isProfileReviews: PropTypes.bool,
  profileReviews: PropTypes.any,
  className: PropTypes.string,
  givingReview: PropTypes.bool,
  offer: PropTypes.any,
  isAdmin: PropTypes.bool,
  rightReviews: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
UserReviews.defaultProps = {
  isProfileReviews: false,
  profileReviews: [],
};

export default UserReviews;

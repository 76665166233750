import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import selectedTheme from "../../themes";

export const Container = styled(Box)`
  width: 100%;
  height: 100vh;
`;

export const ErrorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  height: 100vh;
`;

export const ErrorImageContainer = styled(Box)`
  @media screen and (max-width: 600px) {
    width: 196px;
    svg {
      width: 196px;
      height: 90px;
    }
  }
`;

export const ErrorHeading = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 72px;
  font-weight: 700;
  color: ${selectedTheme.colors.primaryPurple};

  @media screen and (max-width: 600px) {
    font-size: 36px;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 400;
  color: #818181;
  margin-bottom: 45px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Button = styled(PrimaryButton)`
  width: 190px;
  height: 49px;
  font-weight: 600;
  color: #000;

  @media screen and (max-width: 600px) {
    width: 180px;
    height: 44px;
  }
`;

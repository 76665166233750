import { createClearType, createSetType } from "../actionHelpers";

const FILTERS_SCOPE = "FILTERS";
export const SET_FILTERS = createSetType(FILTERS_SCOPE);
export const CLEAR_FILTERS = createClearType(FILTERS_SCOPE);
export const SET_CATEGORY = createSetType("FILTERS_SET_CATEGORY");
export const SET_SUBCATEGORY = createSetType("FILTERS_SET_SUBCATEGORY");
export const SET_LOCATIONS = createSetType("FILTERS_SET_LOCATIONS");
export const SET_PAYMENTS = createSetType("FILTERS_SET_PAYMENTS");
export const SET_COMPANY = createSetType("FILTERS_SET_COMPANY");
export const SET_SORT_OPTION = createSetType("FILTERS_SET_SORT_OPTION");
export const SET_IS_APPLIED = createSetType("FILTERS_SET_IS_APPLIED");
export const SET_QUERY_STRING = createSetType("FILTERS_SET_QUERY_STRING");
export const SET_HEADER_STRING = createSetType("FILTERS_SET_HEADER_STRING");
export const SET_SEARCH_STRING = createSetType("FILTERS_SET_SEARCH");
export const SET_MANUAL_SEARCH_STRING = createSetType(
  "FILTERS_SET_MANUAL_SEARCH_STRING"
);

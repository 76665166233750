import { deleteRequest, getRequest, postRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptGiveReview = (userId, payload) => {
  return postRequest(
    replaceInUrl(apiEndpoints.reviews.postReview, { userId: userId }),
    payload
  );
};
export const attemptFetchReview = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.reviews.getUserReviews, { userId: payload })
  );
};
export const attemptRemoveReview = (payload) => {
  return deleteRequest(
    replaceInUrl(apiEndpoints.reviews.removeReview, { id: payload })
  );
};
export const attemptFetchReviewsAsAdmin = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.admin.reviews.getUserReviewsAsAdmin, {userId: payload})
  );
};
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton } from "../Buttons/PrimaryButton/PrimaryButton";
import { ReactComponent as Close } from "../../assets/images/svg/close-modal.svg";
import { ReactComponent as ArrowBack } from "../../assets/images/svg/arrow-back.svg";
import selectedTheme from "../../themes";
import { IconButton } from "../Buttons/IconButton/IconButton";

export const CreateReviewContainer = styled(Box)`
  background-color: #fff;
  position: fixed;
  overflow-y: auto;
  max-height: 90vh;
  width: 600px;
  overflow-x: hidden;
  box-sizing: border-box;
  top: ${(props) =>
    props.currentStep === 1 ? "calc(50% - 400px);" : "calc(50% - 320px);"};
  left: calc(50% - 300px);
  z-index: 150;
  padding: ${(props) => (props.currentStep !== 3 ? "0 120px" : "0 130px")};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;

  @media screen and (max-width: 628px) {
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    left: 0;
    top: 0;
    padding: 0 18px;
  }
`;
export const NextButton = styled(PrimaryButton)`
  @media (max-width: 600px) {
    height: 42px;
    position: absolute;
    bottom: 9px;
    width: calc(100vw - 36px);
    left: 0;
    margin-left: 9px;
  }
`;
export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 36px;
  right: 36px;
  @media (max-width: 600px) {
    top: 32px;
    right: 22px;
  }
`;
export const CloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
  }
`;
export const BackIcon = styled(Box)`
  cursor: pointer;
  position: absolute;

  top: 36px;
  left: 36px;

  @media (max-width: 600px) {
    top: 40px;
    left: 24px;
    width: 18px;
    height: 18px;
  }
`;
export const ArrowBackIcon = styled(ArrowBack)`
  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
  }
`;
export const CreateReviewTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 36px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 24px;
  }
`;
export const CreateReviewFlexContainer = styled(Box)`
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`

import React from "react";
import PropTypes from "prop-types";
import {
  ProfileContactContainer,
  LocationIcon,
  ContactItem,
  MailIcon,
  GlobeIcon,
} from "./ProfileContact.styled";
import { Stack } from "@mui/material";

const ProfileContact = (props) => {
  return (
    <ProfileContactContainer
      container
      bigProfileCard={props.bigProfileCard}
      isAdmin={props.isAdmin}
      direction={{ xs: "column", sm: "row" }}
      justifyContent={{ xs: "center", sm: "start" }}
      alignItems={{ xs: "start", sm: "center" }}
    >
      {props.profile?.company?.contacts?.location && (
        <Stack direction="row">
          <LocationIcon isMyProfile={props.isMyProfile} />
          <ContactItem
            bigProfileCard={props.bigProfileCard}
            isAdmin={props.isAdmin}
            isMyProfile={props.isMyProfile}
            variant="subtitle2"
            isBlocked={props.isBlocked}
          >
            {props.profile?.company?.contacts?.location}
          </ContactItem>
        </Stack>
      )}
      <Stack direction="row">
        <MailIcon isMyProfile={props.isMyProfile} />
        <ContactItem
          bigProfileCard={props.bigProfileCard}
          isAdmin={props.isAdmin}
          isMyProfile={props.isMyProfile}
          variant="subtitle2"
          isBlocked={props.isBlocked}
        >
          {props.profile?.email}
        </ContactItem>
      </Stack>
      {props.profile?.company?.contacts?.web && (
        <Stack direction="row">
          <GlobeIcon isMyProfile={props.isMyProfile} />
          <ContactItem
            bigProfileCard={props.bigProfileCard}
            isAdmin={props.isAdmin}
            isMyProfile={props.isMyProfile}
            variant="subtitle2"
            isBlocked={props.isBlocked}
          >
            {props.profile?.company?.contacts?.web}
          </ContactItem>
        </Stack>
      )}
    </ProfileContactContainer>
  );
};

ProfileContact.propTypes = {
  profile: PropTypes.object,
  isMyProfile: PropTypes.bool,
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
  bigProfileCard: PropTypes.bool,
  isBlocked: PropTypes.bool,
};

export default ProfileContact;

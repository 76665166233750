import { createSelector } from "reselect";

const chatSelector = (state) => state.chat;

export const selectLatestChats = createSelector(
    chatSelector,
    (state) => state.latestChats
)
export const selectChatsTotal = createSelector(
    chatSelector,
    (state) => state.total
)
export const selectSelectedChat = createSelector(
    chatSelector,
    (state) => state.selectedChat
)
import { getRequest, patchRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchExchange = (payload) => {
  return getRequest(apiEndpoints.exchange.getExchange + `/${payload}`);
};

export const attemptValidateExchange = (payload) => {
  return patchRequest(apiEndpoints.exchange.validateExchange + `/${payload}`);
};
export const attemptAcceptExchange = (userId, exchangeId) => {
  return patchRequest(
    replaceInUrl(apiEndpoints.exchange.acceptExchange, {
      userId,
      exchangeId,
    })
  );
};

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  HorizontalScrollerContainer,
  ListContainer,
} from "./HorizontalScroller.styled";
import { ArrowButton } from "../Buttons/ArrowButton/ArrowButton";
import useIsMobile from "../../hooks/useIsMobile";
import { debounceHelper } from "../../util/helpers/debounceHelper";

const HorizontalScroller = (props) => {
  const scrollRef = useRef(null);
  const { isMobile } = useIsMobile();
  const [isDisabledLeftButton, setIsDisabledLeftButton] = useState(true);
  const [isDisabledRightButton, setIsDisabledRightButton] = useState(false);


  const handleScroll = (event) => {
    if (!event.external) {
      if (scrollRef.current.scrollLeft === 0) {
        if (isDisabledLeftButton !== true) setIsDisabledLeftButton(true);
      } else {
        if (isDisabledLeftButton !== false) setIsDisabledLeftButton(false);
      }
      if (
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft ===
        scrollRef.current.clientWidth
      ) {
        if (isDisabledRightButton !== true) setIsDisabledRightButton(true);
      } else {
        if (isDisabledRightButton !== false) setIsDisabledRightButton(false);
      }
    }
    if (props.infiniteScroll) {
      if (
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft <
        scrollRef.current.clientWidth + 170
      ) {
        debounceHelper(() => props.infiniteScroll(), 500);
      }
    }
  };

  const handleRight = () => {
    if (scrollRef.current.scrollLeft + 50 !== 0) {
      if (isDisabledLeftButton !== false) setIsDisabledLeftButton(false);
    }
    if (
      scrollRef.current.scrollWidth - scrollRef.current.scrollLeft - 50 <=
      scrollRef.current.clientWidth
    ) {
      if (isDisabledRightButton !== true) setIsDisabledRightButton(true);
    }
    if (props.isCarousel) {
      if (isMobile) {
        scrollRef.current.scrollBy({ left: 239, behaviour: "smooth" });
      } else {
        scrollRef.current.scrollBy({ left: 599, behaviour: "smooth" });
      }
    } else {
      scrollRef.current.scrollBy({ left: 50, behaviour: "smooth" });
    }
  };
  const handleLeft = () => {
    if (scrollRef.current.scrollLeft - 50 <= 0) {
      if (isDisabledLeftButton !== true) setIsDisabledLeftButton(true);
    }
    if (
      scrollRef.current.scrollWidth - scrollRef.current.scrollLeft + 50 !==
      scrollRef.current.clientWidth
    ) {
      if (isDisabledRightButton !== false) setIsDisabledRightButton(false);
    }
    if (props.isCarousel) {
      if (isMobile) {
        scrollRef.current.scrollBy({ left: -239, behaviour: "smooth" });
      } else {
        scrollRef.current.scrollBy({ left: -599, behaviour: "smooth" });
      }
    } else {
      scrollRef.current.scrollBy({ left: -50, behaviour: "smooth" });
    }
  };
  return (
    <HorizontalScrollerContainer
      style={props.containerStyle}
      className={props.className}
    >
      {!props.hideArrows && (
        <ArrowButton
          onClick={handleLeft}
          disabled={isDisabledLeftButton}
          side={"left"}
        ></ArrowButton>
      )}
      <ListContainer
        innerRef={scrollRef}
        style={props.listStyle}
        onScroll={handleScroll}
      >
        {props.children}
      </ListContainer>
      {!props.hideArrows && (
        <ArrowButton
          onClick={handleRight}
          disabled={isDisabledRightButton}
          side={"right"}
        ></ArrowButton>
      )}
    </HorizontalScrollerContainer>
  );
};

HorizontalScroller.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerStyle: PropTypes.any,
  listStyle: PropTypes.any,
  hideArrows: PropTypes.bool,
  isCarousel: PropTypes.bool,
  infiniteScroll: PropTypes.bool,
};

export default HorizontalScroller;

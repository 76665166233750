import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FieldLabel, SelectOption } from "../FirstPartCreateOffer.styled";
import { SelectField } from "../../CreateOffer.styled";

const OfferLocationField = (props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <>
      <FieldLabel leftText={t("offer.location")} />
      <SelectField
        defaultValue={formik.values.location}
        onChange={(value) => {
          formik.setFieldValue("location", value.target.value);
        }}
        value={formik.values.location}
      >
        <SelectOption style={{ display: "none" }} value="default">
          {t("offer.choseLocation")}
        </SelectOption>
        {props.locations.map((loc) => {
          return (
            <SelectOption key={loc._id} value={loc.city}>
              {loc.city}
            </SelectOption>
          );
        })}
      </SelectField>
    </>
  );
};

OfferLocationField.propTypes = {
  formik: PropTypes.any,
  locations: PropTypes.array,
};

export default OfferLocationField;

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { HeaderContainer, HeaderText, ButtonContainer } from "./Header.styled";
import { ArrowButton } from "../../Buttons/ArrowButton/ArrowButton";
import { useTranslation } from "react-i18next";
import { HOME_PAGE } from "../../../constants/pages";
import { isAdminRoute } from "../../../util/helpers/routeHelpers";

const Header = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isPaymentRoute = history.location.pathname.includes("/admin/payment");

  const handleBackButton = () => {
    if (props.isAdmin) history.goBack();
    else history.push(HOME_PAGE);
  };

  return (
    <HeaderContainer
      onClick={handleBackButton}
      component="header"
      className={props.className}
      isAdmin={props.isAdmin}
      isPaymentRoute={isPaymentRoute}
    >
      {!isAdminRoute() && (
        <ButtonContainer onClick={handleBackButton}>
          <ArrowButton side={"left"} onClick={handleBackButton} />
          <HeaderText>
            {props.isAdmin ? t("admin.users.goBack") : t("profile.backToHome")}
          </HeaderText>
        </ButtonContainer>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  setIsGrid: PropTypes.func,
  isGrid: PropTypes.bool,
  filters: PropTypes.array,
  category: PropTypes.string,
  className: PropTypes.string,
  isAdmin: PropTypes.bool,
};
Header.defaultProps = {
  isGrid: false,
};

export default Header;

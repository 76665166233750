import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { Label } from "../../../CheckBox/Label";

export const CreateOfferFormContainer = styled(Box)`
  padding-top: 20px;
  margin-top: 20px;
  position: relative;
`;

export const FieldLabel = styled(Label)`
  position: relative;
  top: 12px;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
  }
`;


export const SupportedFormats = styled(Typography)`
  font-size: 13px;
  width: 100%;
  text-align: center;
  font-family: ${selectedTheme.fonts.textFont};
  /* margin-top: 0px; */
`;

import React from "react";
import PropTypes from "prop-types";
import { LogoutIcon, MiddleButton } from "./LogoutButton.styled";
import { PopoverButton } from "../../HeaderPopover/HeaderPopover.styled";
import { useDispatch } from "react-redux";
import { LOGIN_PAGE } from "../../../../constants/pages";
import { logoutUser } from "../../../../store/actions/login/loginActions";
import selectedTheme from "../../../../themes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const handleLogoutSuccess = () => {
    history.replace(LOGIN_PAGE);
  };
  const handleLogout = () => {
    dispatch(logoutUser(handleLogoutSuccess));
  };
  return (
    <MiddleButton>
      <PopoverButton
        sx={{
          mr: 2,
          mb: 2,
        }}
        variant="text"
        endIcon={<LogoutIcon color={selectedTheme.colors.iconYellowColor} />}
        onClick={handleLogout}
      >
        {t("header.logout")}
      </PopoverButton>
    </MiddleButton>
  );
};

LogoutButton.propTypes = {
  children: PropTypes.node,
};

export default LogoutButton;

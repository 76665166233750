import React from "react";
import PropTypes from "prop-types";
import {
  CircleGroup,
  SkeletonHeaderCircle,
  SkeletonHeaderContainer,
  SkeletonHeaderLine,
  SkeletonHeaderRightLine,
  SkeletonLowerPartContainer,
  SkeletonLowerPartLineOne,
  SkeletonLowerPartLineSecond,
  SkeletonMyOffersHeaderContainer,
  SkeletonMyOffersHeaderLine,
  SkeletonRowGroup,
  SkeletonUpperPartContainer,
} from "./SkeletonHeader.styled";

const SkeletonHeader = (props) => {
  return (
    <SkeletonHeaderContainer skeleton={props.skeleton}>
      <SkeletonUpperPartContainer>
        <SkeletonHeaderLine />
        <SkeletonRowGroup>
          <CircleGroup>
            <SkeletonHeaderCircle />
            <SkeletonHeaderCircle />
          </CircleGroup>
          <SkeletonHeaderRightLine />
        </SkeletonRowGroup>
      </SkeletonUpperPartContainer>
      <SkeletonLowerPartContainer>
        <SkeletonLowerPartLineOne />
        <SkeletonLowerPartLineSecond />
      </SkeletonLowerPartContainer>
      <SkeletonMyOffersHeaderContainer myOffers={props.myOffers}>
        <SkeletonMyOffersHeaderLine />
      </SkeletonMyOffersHeaderContainer>
    </SkeletonHeaderContainer>
  );
};

SkeletonHeader.propTypes = {
  skeleton: PropTypes.bool,
  myOffers: PropTypes.bool,
};

export default SkeletonHeader;

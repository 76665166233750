import { reviewEnum } from "../../enums/reviewEnum";

export const sortReviews = (reviews = [], positive = false) => {
  console.log(reviews);
  let newReviews;
  if (!Array.isArray(reviews)) return [];
  if (positive) {
    newReviews = [
      ...reviews?.filter(
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.YES.backendTextSecond
      ),
      ...reviews?.filter(
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.NOT_BAD.backendText
      ),
      ...reviews?.filter(
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.NO.backendTextSecond
      ),
      ...reviews?.filter(
        (review) => review.succeeded === reviewEnum.NO.backendText
      ),
    ];
  } else {
    newReviews = [
      ...reviews?.filter(
        // 4
        (review) => review.succeeded === reviewEnum.NO.backendText
      ),
      ...reviews?.filter(
        // 3
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.NO.backendTextSecond
      ),
      ...reviews?.filter(
        // 2
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.NOT_BAD.backendText
      ),
      ...reviews?.filter(
        // prvo
        (review) =>
          review.succeeded === reviewEnum.YES.backendText &&
          review.communication === reviewEnum.YES.backendTextSecond
      ),
    ];
  }
  return newReviews;
};

import React from "react";
import PropTypes from "prop-types";
import { DetailContainer, DetailText, PIBIcon } from "./PIBDetail.styled";
import selectedTheme from "../../../../../themes";
import { useTranslation } from "react-i18next";
import { ReactComponent as PIB } from "../../../../../assets/images/svg/pib.svg";

const PIBDetail = (props) => {
  const { t } = useTranslation();
  const offer = props.offer;
  return (
    <DetailContainer>
      <PIBIcon color={selectedTheme.colors.iconStrokeColor} component="span">
        <PIB />
      </PIBIcon>
      <DetailText isMyProfile={props.isMyProfile}>
        {`${t("itemDetailsCard.PIB")}${offer?.user?.company?.idNumber}`}
      </DetailText>
    </DetailContainer>
  );
};

PIBDetail.propTypes = {
  isMyProfile: PropTypes.bool,
  offer: PropTypes.any,
  icon: PropTypes.node,
};

export default PIBDetail;

import React from "react";
import PropTypes from "prop-types";
import {
  FirstStepCreateReviewContainer,
  CreateReviewTitle,
  ProfileImage,
  ProfileImageContainer,
  ProfileName,
} from "./FirstStepCreateReview.styled";
import { useTranslation } from "react-i18next";
import LittleOfferCard from "../../Cards/LittleOfferCard/LittleOfferCard";
import { useFormik } from "formik";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import { useMemo } from "react";
import firstPartCreateReviewInitialValues from "../../../initialValues/createReviewInitialValues/firstPartCreateReviewInitialValues";
import firstPartCreateReviewValidation from "../../../validations/createReviewValidations/firstPartCreateReviewValidation";
import CorrectCommunicationField from "./CorrectCommunicationField/CorrectCommunicationField";
import SuccessfulSwapField from "./SuccessfulSwapField/SuccessfulSwapField";
import CommentField from "./CommentField/CommentField";
import NextButton from "./NextButton/NextButton";

const FirstStepCreateReview = (props) => {
  const offer = props.offer;
  const interlocutor = props.interlocutor;
  console.log(props);
  const { t } = useTranslation();
  const handleSubmit = (values) => {
    props.goToNextStep(values);
  };

  const initialValues = useMemo(() => {
    return firstPartCreateReviewInitialValues(props.informations);
  }, [props.informations]);

  const formik = useFormik({
    initialValues,
    validationSchema: firstPartCreateReviewValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <FirstStepCreateReviewContainer
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <CreateReviewTitle>{t("reviews.modalTitle")}</CreateReviewTitle>
      <ProfileImageContainer>
        <ProfileImage
          src={getImageUrl(
            interlocutor.image,
            variants.createReviewCard,
            false
          )}
        />
      </ProfileImageContainer>
      <ProfileName>{interlocutor.company.name}</ProfileName>
      <LittleOfferCard
        image={offer?.images[0]}
        name={offer?.name}
        categoryName={offer?.category?.name}
      />

      <CorrectCommunicationField formik={formik} />
      <SuccessfulSwapField formik={formik} />
      <CommentField formik={formik} />
      <NextButton formik={formik} />
    </FirstStepCreateReviewContainer>
  );
};

FirstStepCreateReview.propTypes = {
  children: PropTypes.node,
  offer: PropTypes.any,
  interlocutor: PropTypes.any,
  goToNextStep: PropTypes.func,
  informations: PropTypes.shape({
    exchangeSucceed: PropTypes.string,
    correctCommunication: PropTypes.string,
    comment: PropTypes.string,
  }),
};

export default FirstStepCreateReview;

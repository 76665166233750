import React from "react";
import PropTypes from "prop-types";
import { LogoutButtonContainer, LogoutIcon } from "./LogoutButton.styled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../../../store/actions/login/loginActions";

const LogoutButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutAdmin());
  };

  return (
    <LogoutButtonContainer onClick={logoutHandler}>
      <LogoutIcon /> {t("admin.navigation.logout")}
    </LogoutButtonContainer>
  );
};

LogoutButton.propTypes = {
  children: PropTypes.node,
};

export default LogoutButton;

import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logout } from "../../../../assets/images/svg/log-out.svg";
import selectedTheme from "../../../../themes";

export const LogoutButtonContainer = styled(Box)`
  font-family: "DM Sans";
  font-size: 16px;
  padding-top: 1.7vh;
  padding-bottom: 1.7vh;
  padding-left: 18px;
  margin-bottom: 18px;
  margin-left: 18px;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${selectedTheme.colors.primaryPurple};
  cursor: pointer;

  &:hover {
    background-color: #f5edff;
    font-weight: 700;
  }
`;
export const LogoutIcon = styled(Logout)`
  position: relative;
  top: 3px;
`;

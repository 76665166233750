import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { ReactComponent as DropdownDown } from "../../../../../../../assets/images/svg/down-arrow.svg";
import selectedTheme from "../../../../../../../themes";

export const SmallDropdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  margin-left: -20px;
  margin-bottom: 14px;
  cursor: pointer;
`;

export const SmallDropdownText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
`;

export const SmallDropdownIcon = styled(DropdownDown)`
  width: 12px;
  ${(props) =>
    props.dropdown &&
    `
    transform: rotate(180deg);
  `}
`;

export const SmallDropdownContent = styled(Box)`
  display: ${(props) => (props.dropdown ? "block" : "none")};
`;

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  VerticalScrollerContainer,
  ListContainer,
  ArrowIconContainer,
} from "./VerticalScroller.styled";
import { ArrowButton } from "../Buttons/ArrowButton/ArrowButton";

const VerticalScroller = (props) => {
  const scrollRef = useRef(null);
  const [isDisabledUpButton, setIsDisabledUpButton] = useState(true);
  const [isDisabledDownButton, setIsDisabledDownButton] = useState(false);

  const handleScroll = (event) => {
    if (!event.external) {
      if (scrollRef.current.scrollTop === 0) {
        if (isDisabledUpButton !== true) setIsDisabledUpButton(true);
      } else {
        if (isDisabledUpButton !== false) setIsDisabledUpButton(false);
      }
      if (
        scrollRef.current.scrollHeight - scrollRef.current.scrollTop ===
        scrollRef.current.clientHeight
      ) {
        if (isDisabledDownButton !== true) setIsDisabledDownButton(true);
      } else {
        if (isDisabledDownButton !== false) setIsDisabledDownButton(false);
      }
    }
  };

  const handleRight = () => {
    if (scrollRef.current.scrollTop + 50 !== 0) {
      if (isDisabledUpButton !== false) setIsDisabledUpButton(false);
    }
    if (
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop - 50 <=
      scrollRef.current.clientHeight
    ) {
      if (isDisabledDownButton !== true) setIsDisabledDownButton(true);
    }
    scrollRef.current.scrollBy({ top: 50, behaviour: "smooth" });
  };
  const handleLeft = () => {
    if (scrollRef.current.scrollHeight - 50 <= 0) {
      if (isDisabledUpButton !== true) setIsDisabledUpButton(true);
    }
    if (
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop + 50 !==
      scrollRef.current.clientHeight
    ) {
      if (isDisabledDownButton !== false) setIsDisabledDownButton(false);
    }
    scrollRef.current.scrollBy({ top: -50, behaviour: "smooth" });
  };
  return (
    <VerticalScrollerContainer
      style={props.containerStyle}
      className={props.className}
    >
      {!props.hideArrows && (
        <ArrowIconContainer side="up">
          <ArrowButton
            onClick={handleLeft}
            disabled={isDisabledUpButton}
            side={"up"}
          ></ArrowButton>
        </ArrowIconContainer>
      )}
      <ListContainer
        innerRef={scrollRef}
        style={props.listStyle}
        onScroll={handleScroll}
      >
        {props.children}
      </ListContainer>
      {!props.hideArrows && (
        <ArrowIconContainer>
          <ArrowButton
            onClick={handleRight}
            disabled={isDisabledDownButton}
            side={"down"}
          ></ArrowButton>
        </ArrowIconContainer>
      )}
    </VerticalScrollerContainer>
  );
};

VerticalScroller.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerStyle: PropTypes.any,
  listStyle: PropTypes.any,
  hideArrows: PropTypes.bool,
};

export default VerticalScroller;

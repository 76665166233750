import React from "react";
import PropTypes from "prop-types";
import { useSlate } from "slate-react";
import { Editor } from "slate";
import { LinkButtonContainer, LinkIcon } from "./LinkButton.styled";
import { useState } from "react";
import LinkPopover from "../../Popovers/LinkPopover/LinkPopover";
import PopoverComponent from "../../Popovers/PopoverComponent";

const toggleMark = (editor, format, link) => {
  Editor.addMark(editor, format, link.url);
  if (
    (editor?.selection &&
      Editor.string(editor, editor.selection).length === 0) ||
    !editor?.selection
  ) {
    Editor.insertNode(editor, { text: link.link, a: link.url });
  }
};

const LinkButton = (props) => {
  const editor = useSlate();
  const [isLinkPopoverShowing, setIsLinkPopoverShowing] = useState(false);
  const [linkPopoverAnchor, setLinkPopoverAnchor] = useState(null);

  const callbackFunction = (linkObject) => {
    setIsLinkPopoverShowing(false);
    toggleMark(editor, "a", linkObject);
  };
  const handleClickLinkButton = (event) => {
    setIsLinkPopoverShowing(true);
    setLinkPopoverAnchor(event.currentTarget);
  };
  const handleCloseLinkPopover = () => {
    setIsLinkPopoverShowing(false);
  };
  console.log();
  return (
    <>
      <LinkButtonContainer
        format={props?.format}
        onMouseDown={handleClickLinkButton}
      >
        <LinkIcon />
      </LinkButtonContainer>
      <PopoverComponent
        anchorEl={linkPopoverAnchor}
        open={isLinkPopoverShowing}
        onClose={handleCloseLinkPopover}
        content={<LinkPopover callbackFunction={callbackFunction} />}
      />
    </>
  );
};
LinkButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
  selectedColor: PropTypes.any,
  setSelectedColor: PropTypes.func,
};
export default LinkButton;

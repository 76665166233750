import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { Icon } from "../../../../Icon/Icon";

export const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  margin-bottom: 7px;
  font-size: 12px;
  @media (max-width: 600px) {
    ${(props) => props.shouldHideResponsive && `display: none;`}
    margin-bottom: 3px;
  }
`;
export const DetailIcon = styled(Icon)`
  display: flex;
  align-items: center;
  & svg {
    width: 22px;
    position: relative;
  }
`;
export const DetailText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${(props) =>
    props.isMyProfile ? "white" : selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 16px;
  position: relative;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export const PIBIcon = styled(DetailIcon)`
  position: relative;
  top: 1px;
  & span svg {
    width: 22px;
    height: 22px;
    @media (max-width: 600px) {
      width: 14px;
      height: 14px;
    }
  }
`;

import { Box, Button } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ReactComponent as DownArrow } from "../../assets/images/svg/arrow-down.svg";

export const PagingContainer = styled(Box)`
  width: calc(100% / 12 * 8.5);
  text-align: center;
  font-family: ${selectedTheme.fonts.textFont};
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  margin: auto;
  left: 0;
  right: 0;
`;
export const ArrowIcon = styled(DownArrow)`
  ${(props) =>
    props.side === "left" &&
    `
        transform: rotate(180deg);
    `}
  width: 18px;
  height: 18px;
  & path {
    ${(props) =>
      props.disabled &&
      `
        stroke: ${selectedTheme.colors.iconStrokeDisabledColor}
        `}
  }
`;
export const Arrow = styled(Button)`
  border: 1px solid ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  min-width: 36px;
  width: 36px;
  height: 36px;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 8px;
  padding-top: 8px;
  margin: auto 10px;
  transition: 0.2s all ease;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple};
    & svg path {
      stroke: white;
    }
  }
  ${(props) =>
    props.disabled &&
    `
        border 1px solid ${selectedTheme.colors.iconStrokeDisabledColor};
        & svg path {
            stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
            transition: 0.2s all ease;
            
        }
    `}
  @media (max-width: 600px) {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding-top: 5px;
    padding-left: 5px;
  }
`;
export const PageNumber = styled(Box)`
  color: ${(props) =>
    !props.current ? selectedTheme.colors.primaryPurple : "white"};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  font-family: ${selectedTheme.fonts.textFont};
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  width: 40px !important;
  margin: 5px;
  padding-top: 10px;
  background-color: ${(props) =>
    props.current && selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  position: relative;
  top: 1px;
  &:hover {
    cursor: pointer;
    ${(props) =>
      !props.current &&
      `
    color: ${props.current ? selectedTheme.colors.primaryPurple : "white"};
    background-color: ${!props.current && selectedTheme.colors.primaryPurple};
    `}
  }
  @media (max-width: 600px) {
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    width: 30px !important;
    padding-top: 5px;
    font-size: 14px;
    margin: 1px;
  }
`;
export const ThreeDots = styled(Box)`
  color: ${(props) =>
    !props.current ? selectedTheme.colors.primaryPurple : "white"};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  font-family: ${selectedTheme.fonts.textFont};
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  width: 40px !important;
  margin: 5px;
  padding-top: 10px;
  background-color: ${(props) =>
    props.current && selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  position: relative;
  top: 1px;
  @media (max-width: 600px) {
    height: 10px;
    min-width: 10px;
    max-width: 10px;
    width: 10px !important;
    padding-top: 6px;
    font-size: 14px;
    margin: 1px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  GridButton,
  GridButtonsContainer,
  GridLineIcon,
  GridSquareIcon,
} from "./GridButtons.styled";
import selectedTheme from "../../../../themes";

const GridButtons = (props) => {
  return (
    <GridButtonsContainer hideGrid={props?.hideGrid}>
      {/* Setting display of offer cards to full width */}
      <GridButton
        iconColor={
          props?.isGrid
            ? selectedTheme.colors.iconStrokeColor
            : selectedTheme.colors.primaryPurple
        }
        onClick={() => props?.setIsGrid(false)}
      >
        <GridLineIcon />
      </GridButton>
      {/* ^^^^^^ */}

      {/* Setting display of offer cards to half width (Grid) */}
      <GridButton
        iconColor={
          props?.isGrid
            ? selectedTheme.colors.primaryPurple
            : selectedTheme.colors.iconStrokeColor
        }
        onClick={() => props?.setIsGrid(true)}
      >
        <GridSquareIcon />
      </GridButton>
      {/* ^^^^^^ */}
    </GridButtonsContainer>
  );
};

GridButtons.propTypes = {
  isGrid: PropTypes.bool,
  setIsGrid: PropTypes.func,
  hideGrid: PropTypes.bool,
};
GridButtons.defaultProps = {
  isGrid: false,
  setIsGrid: () => {},
  hideGrid: false,
};

export default GridButtons;

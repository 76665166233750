import React from "react";
import PropTypes from "prop-types";
import { ErrorText } from "../../CreateOffer.styled";

const ErrorMessage = (props) => {
  const formik = props.formik;
  return (
    <>
      {formik.errors.nameOfProduct && formik.touched.nameOfProduct ? (
        <ErrorText>{formik.errors.nameOfProduct}</ErrorText>
      ) : formik.errors.description && formik.touched.description ? (
        <ErrorText>{formik.errors.description}</ErrorText>
      ) : formik.errors.location && formik.touched.location ? (
        <ErrorText>{formik.errors.location}</ErrorText>
      ) : formik.errors.category && formik.touched.category ? (
        <ErrorText>{formik.errors.category}</ErrorText>
      ) : formik.errors.subcategory && formik.touched.subcategory ? (
        <ErrorText>{formik.errors.subcategory}</ErrorText>
      ) : (
        <></>
      )}
    </>
  );
};

ErrorMessage.propTypes = {
  formik: PropTypes.any,
};

export default ErrorMessage;

import styled from "styled-components";
import { Label } from "../../../../CheckBox/Label";

export const InputFieldLabelLocation = styled(Label)`
  position: relative;
  bottom: -8px;
  margin: 10px 0;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #808080;
    cursor: auto;
    letter-spacing: 0.2px;
  }

  @media screen and (max-width: 600px) {
    bottom: -12px;
    margin: 5px 0 17px 0;
    & label {
      font-size: 9px;
      margin-top: 0;
    }
  }
`;
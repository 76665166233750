import React from "react";
import PropTypes from "prop-types";
import {
  ProfileOffersHeaderSkeletonContainer,
  ProfileOffersHeaderSkeletonGroupOne,
  ProfileOffersHeaderSkeletonGroupSecond,
  ProfileOffersHeaderSkeletonLineForth,
  ProfileOffersHeaderSkeletonLineOne,
  ProfileOffersHeaderSkeletonLineSecond,
  ProfileOffersHeaderSkeletonLineThird,
} from "./ProfileOffersHeaderSkeleton.styled";

const ProfileOffersHeaderSkeleton = () => {
  return (
    <ProfileOffersHeaderSkeletonContainer>
      <ProfileOffersHeaderSkeletonGroupOne>
        <ProfileOffersHeaderSkeletonLineOne />
        <ProfileOffersHeaderSkeletonLineSecond />
      </ProfileOffersHeaderSkeletonGroupOne>
      <ProfileOffersHeaderSkeletonGroupSecond>
        <ProfileOffersHeaderSkeletonLineThird />
        <ProfileOffersHeaderSkeletonLineForth />
      </ProfileOffersHeaderSkeletonGroupSecond>
    </ProfileOffersHeaderSkeletonContainer>
  );
};

ProfileOffersHeaderSkeleton.propTypes = {
  children: PropTypes.node,
};

export default ProfileOffersHeaderSkeleton;

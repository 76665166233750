import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/svg/logo-vertical.svg";
import {
  ResetPasswordPageContainer,
  ResetPasswordDescription,
  ResetPasswordTitle,
  FormContainer,
  Footer,
  FooterText,
} from "./ResetPasswordPage.styled";
import { TextField } from "../../components/TextFields/TextField/TextField";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LOGIN_PAGE } from "../../constants/pages";
import selectedTheme from "../../themes";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/actions/user/userActions";
import { Trans } from "react-i18next";
import { ReactComponent as VisibilityOn } from "../../assets/images/svg/eye-striked.svg";
import { ReactComponent as VisibilityOff } from "../../assets/images/svg/eye.svg";
import { IconButton } from "../../components/Buttons/IconButton/IconButton";
import jwt from "jsonwebtoken";
import resetPasswordValidation from "../../validations/resetPasswordValidation";
import ErrorMessage from "./ErrorMessage/ErrorMessage";

const ResetPasswordPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const routeMatch = useRouteMatch();

  useEffect(() => {
    const tokenFromParams = routeMatch.params.token;
    setToken(tokenFromParams);
    const data = jwt.decode(tokenFromParams);
    if (!data || new Date() > new Date(data?.exp * 1000)) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (error) {
      history.replace("/");
    }
  }, [error]);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((prevState) => !prevState);
  };

  const handleResponseSuccess = () => {
    history.push(LOGIN_PAGE);
  };

  const handleSubmit = (values) => {
    dispatch(
      resetPassword({
        token: token,
        password: values.password,
        password2: values.passwordConfirm,
        handleResponseSuccess,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: resetPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <ResetPasswordPageContainer>
      <Logo />

      <ResetPasswordTitle component="h1" variant="h5">
        {t("resetPassword.title")}
      </ResetPasswordTitle>

      <ResetPasswordDescription component="h1" variant="h6">
        {t("resetPassword.description")}
      </ResetPasswordDescription>

      <FormContainer component="form" onSubmit={formik.handleSubmit}>
        {/* <Backdrop position="absolute" isLoading={isLoading} /> */}

        <TextField
          name="password"
          placeholder={t("resetPassword.passwordLabel")}
          margin="normal"
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && formik.errors.password?.length > 0}
          helperText={formik.touched.password && formik.errors.password}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOn /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <TextField
          name="passwordConfirm"
          placeholder={t("resetPassword.passwordConfirmLabel")}
          margin="normal"
          type={showPasswordConfirm ? "text" : "password"}
          value={formik.values.passwordConfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.passwordConfirm &&
            formik.errors.passwordConfirm?.length > 0
          }
          helperText={
            formik.touched.passwordConfirm && formik.errors.passwordConfirm
          }
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPasswordConfirm}>
                {showPasswordConfirm ? <VisibilityOn /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />

        <ErrorMessage formik={formik} />

        <PrimaryButton
          type="submit"
          variant="contained"
          height="48px"
          fullWidth
          buttoncolor={selectedTheme.colors.primaryPurple}
          textcolor="white"
          disabled={
            formik.values.password.length < 8 ||
            formik.values.passwordConfirm.length < 8
          }
        >
          {t("resetPassword.buttonText")}
        </PrimaryButton>
      </FormContainer>

      <Footer>
        <FooterText>
          <Trans i18nKey="forgotPassword.checkSpam" />
        </FooterText>
      </Footer>
    </ResetPasswordPageContainer>
  );
};

export default ResetPasswordPage;

import {
  CHAT_ADD_MESSAGE,
  CHAT_CLEAR,
  CHAT_ONE_SET,
  CHAT_SET,
  CHAT_TOTAL_SET,
} from "../../actions/chat/chatActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  latestChats: [],
  selectedChat: {},
  total: 0,
};

export default createReducer(
  {
    [CHAT_SET]: setChats,
    [CHAT_TOTAL_SET]: setChatsTotal,
    [CHAT_ONE_SET]: setOneChat,
    [CHAT_CLEAR]: clearChats,
    [CHAT_ADD_MESSAGE]: addNewMessage,
  },
  initialState
);

function setChats(state, action) {
  return {
    ...state,
    latestChats: [...action.payload],
  };
}
function setChatsTotal(state, action) {
  return {
    ...state,
    total: action.payload,
  };
}
function setOneChat(state, action) {
  return {
    ...state,
    selectedChat: action.payload,
  };
}
function clearChats() {
  return initialState;
}
function addNewMessage(state, { payload }) {
  let allChats = [...state.latestChats];
  let chat = allChats.find((item) => item._id === payload._id);
  if (chat) {
    chat = {
      ...chat,
      messages: [...chat.messages, payload.message],
    };
    allChats = allChats.filter((item) => item._id !== chat._id);
    allChats = [chat, ...allChats];
  }
  let newSelectedChat = {};
  if (state.selectedChat.chat) newSelectedChat = { ...state.selectedChat };
  newSelectedChat = { ...chat };
  return {
    ...state,
    latestChats: [...allChats],
    selectedChat: { ...newSelectedChat },
  };
}

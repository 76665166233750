export const BASE_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const FORGOT_PASSWORD_PAGE = "/forgot-password";
export const ADMIN_LOGIN_PAGE = "/admin/login";
export const HOME_PAGE = "/home";
export const ERROR_PAGE = "/error-page";
export const NOT_FOUND_PAGE = "/not-found";
export const FORGOT_PASSWORD_MAIL_SENT = "/forgot-password/mail-sent";
export const REGISTER_PAGE = "/register";
export const REGISTER_SUCCESSFUL_PAGE = "/register/success";
export const RESET_PASSWORD_PAGE = "/reset-password/:token";
export const CREATE_OFFER_PAGE = "/create-offer";
export const ITEM_DETAILS_PAGE = "/offers/:offerId";
export const PROFILE_PAGE = "/profiles/:profileId";
export const MESSAGES_LIST_PAGE = "/messages";
export const DIRECT_CHAT_PAGE = "/messages/:chatId";
export const MY_OFFERS_PAGE = "/myoffers";
export const ABOUT_PAGE = "/about";
export const PRICES_PAGE = "/prices";
export const POLICY_PRIVACY_PAGE = "/policy";
export const ADMIN_HOME_PAGE = "/admin";
export const ADMIN_USERS_PAGE = "/admin/users";
export const ADMIN_SINGLE_USER_PAGE = "/admin/profiles/:profileId";
export const ADMIN_ITEM_DETAILS_PAGE = "/admin/offers/:offerId";
export const ADMIN_CATEGORIES_PAGE = "/admin/categories";
export const ADMIN_LOCATIONS_PAGE = "/admin/locations";
export const ADMIN_PAYMENT_PAGE = "/admin/payment";
export const ADMIN_SUBCATEGORIES_PAGE = "/admin/categories/:categoryId";
export const MARKETPLACE_PAGE = "/marketplace";
export const BLOG_PAGE = "/blog";
export const BLOG_DETAILS_PAGE = "/blog/:blogId";

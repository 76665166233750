import React from "react";
import {
  SkeletonChatContentContainer,
  SkeletonChatContentHeader,
  SkeletonChatContentHeaderFirstColumn,
  SkeletonChatContentHeaderFirstColumnFirstLine,
  SkeletonChatContentHeaderFirstColumnImage,
  SkeletonChatContentHeaderSecondColumnImageSmall,
  SkeletonChatContentHeaderFirstColumnInfo,
  SkeletonChatContentHeaderFirstColumnSecondLine,
  SkeletonChatContentMain,
  SkeletonChatContentMainRow,
  SkeletonChatContentMainImage,
  SkeletonChatContentMainLeftRowInfo,
  SkeletonChatContentMainFirstRowInfoFirstLine,
  SkeletonChatContentMainFirstRowInfoSecondLine,
  SkeletonChatContentMainRightRowInfo,
  SkeletonChatContentSecondRowFirstLine,
  SkeletonChatContentSecondRowSecondLine,
  SkeletonChatContentThirdRowFirstLine,
  SkeletonChatContentThirdRowSecondLine,
  SkeletonChatContentFourthRowFirstLine,
  SkeletonChatContentFourthRowSecondLine,
  SkeletonChatContentFifthRowFirstLine,
  SkeletonChatContentFifthRowSecondLine,
  SkeletonChatContentHorizontalLine,
  SkeletonChatContentVerticalLine,
  SkeletonChatContentFooter,
  SkeletonChatContentFooterFirstColumn,
  SkeletonChatContentFooterColumnInside,
  SkeletonChatContentFooterSecondColumn,
} from "./SkeletonDirectChatContent.styled";

const SkeletonDirectChatContent = () => {
  return (
    <SkeletonChatContentContainer>
      <SkeletonChatContentHeader>
        <SkeletonChatContentHeaderFirstColumn>
          <SkeletonChatContentHeaderFirstColumnImage />
          <SkeletonChatContentHeaderFirstColumnInfo>
            <SkeletonChatContentHeaderFirstColumnFirstLine />
            <SkeletonChatContentHeaderFirstColumnSecondLine />
          </SkeletonChatContentHeaderFirstColumnInfo>
        </SkeletonChatContentHeaderFirstColumn>
        <SkeletonChatContentHeaderSecondColumnImageSmall />
      </SkeletonChatContentHeader>
      <SkeletonChatContentMain>
        <SkeletonChatContentMainRow>
          <SkeletonChatContentMainImage />
          <SkeletonChatContentMainLeftRowInfo>
            <SkeletonChatContentMainFirstRowInfoFirstLine />
            <SkeletonChatContentMainFirstRowInfoSecondLine />
          </SkeletonChatContentMainLeftRowInfo>
        </SkeletonChatContentMainRow>
        <SkeletonChatContentMainRow>
          <SkeletonChatContentMainRightRowInfo>
            <SkeletonChatContentSecondRowFirstLine />
            <SkeletonChatContentSecondRowSecondLine />
          </SkeletonChatContentMainRightRowInfo>
          <SkeletonChatContentMainImage />
        </SkeletonChatContentMainRow>
        <SkeletonChatContentMainRow>
          <SkeletonChatContentMainImage />
          <SkeletonChatContentMainLeftRowInfo>
            <SkeletonChatContentThirdRowFirstLine />
            <SkeletonChatContentThirdRowSecondLine />
          </SkeletonChatContentMainLeftRowInfo>
        </SkeletonChatContentMainRow>
        <SkeletonChatContentMainRow>
          <SkeletonChatContentMainRightRowInfo>
            <SkeletonChatContentFourthRowFirstLine />
            <SkeletonChatContentFourthRowSecondLine />
          </SkeletonChatContentMainRightRowInfo>
          <SkeletonChatContentMainImage />
        </SkeletonChatContentMainRow>
        <SkeletonChatContentMainRow>
          <SkeletonChatContentMainImage />
          <SkeletonChatContentMainLeftRowInfo>
            <SkeletonChatContentFifthRowFirstLine />
            <SkeletonChatContentFifthRowSecondLine />
          </SkeletonChatContentMainLeftRowInfo>
        </SkeletonChatContentMainRow>
        <SkeletonChatContentHorizontalLine />
        <SkeletonChatContentVerticalLine />
        <SkeletonChatContentFooter>
          <SkeletonChatContentFooterFirstColumn>
            <SkeletonChatContentFooterColumnInside />
          </SkeletonChatContentFooterFirstColumn>
          <SkeletonChatContentFooterSecondColumn>
            <SkeletonChatContentFooterColumnInside />
          </SkeletonChatContentFooterSecondColumn>
        </SkeletonChatContentFooter>
      </SkeletonChatContentMain>
    </SkeletonChatContentContainer>
  );
};

export default SkeletonDirectChatContent;

import React from "react";
import PropTypes from "prop-types";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { ToggleDrawerButton } from "./OpenDrawerButton.styled";

const OpenDrawerButton = (props) => {
  return (
    <ToggleDrawerButton>
      <IconButton onClick={props.onClick}>
        <MenuOutlinedIcon />
      </IconButton>
    </ToggleDrawerButton>
  );
};

OpenDrawerButton.propTypes = {
  onClick: PropTypes.func,
};

export default OpenDrawerButton;

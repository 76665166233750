import React from "react";
import PropTypes from "prop-types";
// import useIsMobile from "../../../hooks/useIsMobile";
// import { Drawer as HeaderDrawer } from "../Drawer/Drawer";
import Drawer from "../../../MUI/DrawerComponent";
import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import FilterCard from "../../../Cards/FilterCard/FilterCard";
import useOffers from "../../../../hooks/useOffers/useOffers";

const DrawerContainer = forwardRef((props, ref) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const offers = useOffers();
  // const { isMobile } = useIsMobile();

  const toggleFilters = () => {
    setFiltersOpened((prevFiltersOpened) => !prevFiltersOpened);
  };

  useImperativeHandle(ref, () => ({
    handleToggleDrawer,
  }));

  const handleToggleDrawer = () => {
    setOpenDrawer((prevOpenDrawer) => !prevOpenDrawer);
  };
  // if (!isMobile) return <></>;
  return (
    <Drawer
      open={openDrawer}
      toggleOpen={handleToggleDrawer}
      content={
        <FilterCard
          payments
          offers={offers}
          filtersOpened={filtersOpened}
          toggleFilters={toggleFilters}
          toggleDrawer={handleToggleDrawer}
        />
      }
    />
  );
});

DrawerContainer.displayName = "DrawerContainer";

DrawerContainer.propTypes = {
  showCreateOfferModal: PropTypes.func,
};

export default DrawerContainer;

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredCompany } from "../../store/actions/filters/filtersActions";
import { fetchAllProfiles } from "../../store/actions/profile/profileActions";
import { selectSelectedCompany } from "../../store/selectors/filtersSelectors";
import { selectAllProfiles } from "../../store/selectors/profileSelectors";

const useCompaniesFilter = () => {
  const selectedCompaniesRedux = useSelector(selectSelectedCompany);
  const dispatch = useDispatch();
  const allCompaniesRedux = useSelector(selectAllProfiles);
  const [selectedCompaniesLocally, setSelectedCompaniesLocally] = useState([]);
  useEffect(() => {
    dispatch(fetchAllProfiles());
  }, []);

  const selectedCompanies = useMemo(() =>
    Array.isArray(selectedCompaniesRedux) ? selectedCompaniesRedux : []
  );

  const allCompanies = useMemo(() =>
    Array.isArray(allCompaniesRedux) ? allCompaniesRedux : []
  );

  useEffect(() => {
    setSelectedCompaniesLocally(selectedCompanies);
  }, [selectedCompanies]);

  const setSelectedCompanies = (companies, immediateApply = false) => {
    setSelectedCompaniesLocally(companies);
    if (immediateApply) {
      dispatch(setFilteredCompany(companies));
    }
  };

  const setSelectedCompaniesFromArray = (companies) => {
    let companiesToPush = [];
    companies.forEach((companyName) => {
      companiesToPush.push(
        allCompanies.find((p) => p.companyName === companyName)
      );
    });
    setSelectedCompanies([...companiesToPush]);
  };

  const apply = () => {
    dispatch(setFilteredCompany(selectedCompaniesLocally));
  };

  const clear = () => {
    setSelectedCompaniesLocally([]);
    dispatch(setFilteredCompany([]));
  };

  return {
    selectedCompanies,
    selectedCompaniesLocally,
    setSelectedCompanies,
    setSelectedCompaniesFromArray,
    allCompanies,
    apply,
    clear,
  };
};

export default useCompaniesFilter;

import styled from "styled-components";
import { ReactComponent as About } from "../../../../../assets/images/svg/info.svg";
import selectedTheme from "../../../../../themes";
import { DrawerOption } from "../../Drawer.styled";
export const AboutIcon = styled(About)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke-width: 0;
  }
`;
export const GrayDrawerOption = styled(DrawerOption)`
  color: ${selectedTheme.colors.primaryPurple};
  letter-spacing: 0.05em;
`;

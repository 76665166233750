import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseBlack } from "../../../../../../../assets/images/svg/close-black.svg";
import { ClearText, SearchBar } from "./SearchField.styled";
import selectedTheme from "../../../../../../../themes";

const SearchField = (props) => {
  const handleClear = () => {
    props.onChange("");
  };
  return (
    <SearchBar
      placeholder={props.placeholder}
      italicPlaceholder
      value={props.value}
      onChange={props.onChange}
      textsize={"12px"}
      font={selectedTheme.fonts.textFont}
      fullWidth
      height={"40px"}
      containerStyle={{ marginTop: "6px" }}
      InputProps={{
        endAdornment:
          props.value.length > 0 ? (
            <ClearText onClick={handleClear}>
              <CloseBlack />
            </ClearText>
          ) : (
            <React.Fragment />
          ),
      }}
    />
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchField;

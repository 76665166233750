import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearLoginErrors,
  fetchLogin,
} from "../../store/actions/login/loginActions";
import { selectLoginError } from "../../store/selectors/loginSelectors";
import { HOME_PAGE, ADMIN_HOME_PAGE, LOGIN_PAGE } from "../../constants/pages";
import { ReactComponent as Logo } from "../../assets/images/svg/logo-vertical.svg";
// import { ReactComponent as LogoAdmin } from "../../assets/images/svg/logo-vertical-admin.svg";
import {
  LoginPageContainer,
  LoginFormContainer,
  LogoAdmin,
} from "./Login.styled";
import loginValidation from "../../validations/loginValidation";
import loginInitialValues from "../../initialValues/loginInitialValues";
import LoginTitle from "./Title/LoginTitle";
import LoginDescription from "./Description/LoginDescription";
import EmailField from "./Fields/Email/EmailField";
import PasswordField from "./Fields/Password/PasswordField";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import ForgotPasswordLink from "./ForgotPasswordLink/ForgotPasswordLink";
import LoginButton from "./LoginButton/LoginButton";
import RegisterLink from "./RegisterLink/RegisterLink";

const Login = (props) => {
  const dispatch = useDispatch();
  const error = useSelector(selectLoginError);
  const history = useHistory();
  const passwordRef = useRef(null);
  const [passwordReset, setPasswordReseted] = useState(false);

  // Clear login errors when user firstly enters the page
  useEffect(() => {
    dispatch(clearLoginErrors());
  }, []);

  // Api response callback function on success
  const handleApiResponseSuccess = () => {
    props.isAdmin
      ? history.push({
          pathname: ADMIN_HOME_PAGE,
          state: {
            from: history.location.pathname,
          },
        })
      : history.push({
          pathname: HOME_PAGE,
          state: {
            from: history.location.pathname,
          },
        });
  };

  // Resets password to blank field when there is need to
  useEffect(() => {
    if (passwordReset) {
      formik.setFieldValue("password", "");
    }
  }, [passwordReset]);

  // Api response callback function on error
  const handleApiResponseError = () => {
    setPasswordReseted(true);
    history.push({
      pathname: LOGIN_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  // Checks if form is valid, and send it if it is valid
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!formik.isValid) {
      formik.setFieldValue("password", "");
    }
    formik.handleSubmit(e);
  };

  const handleSubmit = (values) => {
    const { email, password } = values;
    if (formik.isValid) {
      dispatch(clearLoginErrors());
      dispatch(
        fetchLogin({
          email,
          password,
          handleApiResponseSuccess,
          handleApiResponseError,
          isAdmin: props.isAdmin,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  // Clear API errors and replace them with validation errors
  useEffect(() => {
    if (error) {
      if (formik.errors.email || formik.errors.password) {
        if (!passwordReset) {
          dispatch(clearLoginErrors());
        } else {
          setPasswordReseted(false);
        }
      }
    }
  }, [formik.errors.email, formik.errors.password]);

  return (
    <LoginPageContainer>
      {props.isAdmin ? <LogoAdmin /> : <Logo />}
      <LoginTitle isAdmin={props.isAdmin} />
      <LoginDescription isAdmin={props.isAdmin} />
      <LoginFormContainer component="form" onSubmit={handleSubmitForm}>
        <EmailField formik={formik} />
        <PasswordField formik={formik} ref={passwordRef} />
        <ErrorMessage formik={formik} />
        {!props.isAdmin ? <ForgotPasswordLink /> : <></>}
        <LoginButton formik={formik} />
        {!props.isAdmin ? <RegisterLink /> : <></>}
      </LoginFormContainer>
    </LoginPageContainer>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  isAdmin: PropTypes.bool,
};
export default Login;

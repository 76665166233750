import React from "react";
import { IconButtonContainer, IconButtonStyled } from "./IconButton.styled";
import PropTypes from "prop-types";
import { useMemo } from "react";

export const IconButton = (props) => {
  const disabled = useMemo(() => {
    if (props?.customDisabled) return false;
    return props?.disabled;
  });
  return (
    <IconButtonContainer
      style={props.containerStyle}
      className={props.className}
      onClick={(event) => event.stopPropagation()}
    >
      <IconButtonStyled
        // disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          if (!disabled && props?.onClick) props?.onClick(event);
        }}
        sx={props.style}
        iconcolor={props.iconColor}
        // {...props}
      >
        {props.children}
      </IconButtonStyled>
    </IconButtonContainer>
  );
};

IconButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  containerStyle: PropTypes.any,
  style: PropTypes.any,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  customDisabled: PropTypes.bool,
};

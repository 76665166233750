import React, { useMemo } from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import { HOME_PAGE } from "../../constants/pages";
// import { selectRoles } from "../../store/selectors/loginSelectors";
import { selectMineProfile } from "../../store/selectors/profileSelectors";

const AdminRoute = ({ ...props }) => {
  // const role = useSelector(selectRoles);
  const profile = useSelector(selectMineProfile);

  const isUserAdmin = useMemo(() => {
    if (!profile?.roles?.includes("Admin")) return false;
    return true;
  }, [profile]);

  return isUserAdmin ? <Route {...props} /> : <Redirect to={HOME_PAGE} />;
};

export default AdminRoute;

import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const MailSentContainer = styled(Container)`
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 900px) {
    margin-top: 200px;
  }
  @media (max-height: 800px) {
    margin-top: 150px;
  }
`;
export const Title = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 6px;
`;
export const Description = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 279px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 36px;
`;
export const FormContainer = styled(Box)`
  width: 335px;
  height: 216px;
`;
export const StandardText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  font-size: 16px;
  padding-right: 6px;
  line-height: 14px;
  text-align: center;
`
export const SendAgainTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
`
export const Footer = styled(Box)`
  position: absolute;
  bottom: 36px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`
export const FooterText = styled(StandardText)`
  width: 340px;
  line-height: 22px;
  font-weight: 400;
  padding: 0;
  font-size: 16px;
`
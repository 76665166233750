import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CheckboxDropdownList from "../CheckboxDropdownList/CheckboxDropdownList";
import FilterSmallDropdown from "./FilterSmallDropdown/FilterSmallDropdown";
import { useTranslation } from "react-i18next";

const FilterSubDropdown = (props) => {
  const [dataToShow, setDataToShow] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [toSearch, setToSearch] = useState("");
  const { t } = useTranslation();
  const { data } = props;

  useEffect(() => {
    setDataToShow([...data]);
  }, [data]);

  useEffect(() => {
    if (toSearch.length > 0) {
      setDataToShow(
        data.filter((item) =>
          props.companies
            ? item.companyName.toLowerCase().includes(toSearch.toLowerCase())
            : item.city.toLowerCase().includes(toSearch.toLowerCase())
        )
      );
    } else {
      setDataToShow([...data]);
    }
  }, [toSearch]);

  useEffect(() => {
    if (props.filters?.length > 0) {
      setIsOpened(true);
    }
  }, [props.filters]);

  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
    if (props.handleOpen) props.handleOpen();
  };

  return (
    <CheckboxDropdownList
      toSearch={toSearch}
      setToSearch={setToSearch}
      title={props.title}
      filters={props.filters}
      icon={props.icon}
      data={data}
      offers={props?.offers}
      searchPlaceholder={props.searchPlaceholder}
      open={props?.open !== undefined ? props.open : isOpened}
      handleOpen={handleOpen}
      setItemsSelected={props.setItemsSelected}
      companies={props.companies}
    >
      {(isOpened || props?.open) && (
        <>
          <FilterSmallDropdown
            letters={t("filters.company.firstSort")}
            dataToShow={dataToShow}
            filters={props.filters}
            setItemsSelected={props.setItemsSelected}
            companies={props.companies}
            offers={props.offers}
          />
          <FilterSmallDropdown
            letters={t("filters.company.secondSort")}
            dataToShow={dataToShow}
            filters={props.filters}
            setItemsSelected={props.setItemsSelected}
            companies={props.companies}
            offers={props.offers}
          />
          <FilterSmallDropdown
            letters={t("filters.company.thirdSort")}
            dataToShow={dataToShow}
            filters={props.filters}
            setItemsSelected={props.setItemsSelected}
            companies={props.companies}
            offers={props.offers}
          />
        </>
      )}
    </CheckboxDropdownList>
  );
};

FilterSubDropdown.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  data: PropTypes.array,
  title: PropTypes.string,
  oneValueAllowed: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setItemsSelected: PropTypes.func,
  filters: PropTypes.array,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  companies: PropTypes.bool,
  offers: PropTypes.any,
};
FilterSubDropdown.defaultProps = {
  oneValueAllowed: false,
};

export default FilterSubDropdown;

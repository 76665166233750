import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  BottomDetails,
  HeaderDetails,
  StatusText,
  StatusValue,
} from "./StatisticDetails.styled";
import { useTranslation } from "react-i18next";

const StatisticDetails = (props) => {
  const { t } = useTranslation();
  const offer = props.offer;
  const percentOfSucceededExchanges = useMemo(() => {
    if (offer?.user?.statistics?.exchanges?.succeeded === 0) {
      return 0 + "%";
    } else {
      return (
        Math.ceil(
          (offer?.user?.statistics?.exchanges?.total /
            offer?.user?.statistics?.exchanges?.succeeded) *
            100
        ) + "%"
      );
    }
  });

  return (
    <HeaderDetails>
      <BottomDetails>
        <StatusText>
          <StatusValue>
            {offer?.user?.statistics?.publishes?.count}
          </StatusValue>
          {t("itemDetailsCard.offers")}
        </StatusText>
        <StatusText>
          <StatusValue>
            {offer?.user?.statistics?.views?.count}
          </StatusValue>
          {t("itemDetailsCard.totalViews")}
        </StatusText>
        <StatusText>
          <StatusValue>{percentOfSucceededExchanges}</StatusValue>
          {t("itemDetailsCard.successfulExchanges")}
        </StatusText>
        <StatusText>
          <StatusValue>{percentOfSucceededExchanges}</StatusValue>
          {t("itemDetailsCard.correctCommunications")}
        </StatusText>
      </BottomDetails>
    </HeaderDetails>
  );
};

StatisticDetails.propTypes = {
  offer: PropTypes.any,
};

export default StatisticDetails;

import React from "react";
import PropTypes from "prop-types";
import {
  DateContainer,
  MiniChatCardContainer,
  ProfileDetails,
  ProfileImage,
  ProfileName,
  ProfileNameContainer,
  ProfileProductName,
} from "./MiniChatCard.styled";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import history from "../../../store/utils/history";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import { DIRECT_CHAT_PAGE } from "../../../constants/pages";
import BlockedProfile from "../ProfileCard/BlockedProfile/BlockedProfile";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { formatDateLocaleTwoDigitsYear } from "../../../util/helpers/dateHelpers";

const MiniChatCard = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectUserId);
  const changeChat = () => {
    history.push(
      replaceInRoute(DIRECT_CHAT_PAGE, {
        chatId: props?.chat?._id,
      })
    );
  };
  const interlocutorData = useMemo(() => {
    if (props?.chat?.participants) {
      let interlocutor = props?.chat?.participants[0]._id === userId ? 1 : 0;
      return props?.chat?.participants[interlocutor];
    }
    return {};
  }, [props?.chat]);

  let date = formatDateLocaleTwoDigitsYear(
    new Date(
      props?.chat?.messages?.[props?.chat?.messages?.length - 1]?._created
    )
  );

  const lastMessage = useMemo(() => {
    if (props?.chat?.messages?.[props?.chat?.messages?.length - 1]?.text === "")
      return t("common.trampaIsFinished");
    return props?.chat?.messages?.[props?.chat?.messages?.length - 1]?.text;
  }, [props.chat]);

  return (
    <MiniChatCardContainer selected={props.selected} onClick={changeChat}>
      <ProfileImage
        src={getImageUrl(interlocutorData?.image, variants.chatCard, isMobile)}
      />
      <ProfileDetails isBlocked={interlocutorData?._blocked}>
        <ProfileNameContainer>
          <ProfileName selected={props.selected}>
            {props?.chat?.offer?.name}
          </ProfileName>
          {(interlocutorData?._blocked || interlocutorData?._deleted) && (
            <BlockedProfile
              redText
              chatCard
              shortText
              aboveTitle
              hideIcon
              deleted={interlocutorData?._deleted}
            />
          )}
        </ProfileNameContainer>
        <ProfileProductName selected={props.selected}>
          {lastMessage}
        </ProfileProductName>
      </ProfileDetails>
      <DateContainer selected={props.selected}>{date}</DateContainer>
    </MiniChatCardContainer>
  );
};

MiniChatCard.propTypes = {
  children: PropTypes.node,
  chat: PropTypes.any,
  selected: PropTypes.bool,
};

export default MiniChatCard;

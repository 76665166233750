import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const StepProgressContainer = styled(Box)`
  display: flex;
  height: 35px;
  flex-direction: row;
  position: relative;
  left: 2px;
  width: 332px;
  gap: 0 !important;
  /* ${(props) => props.current === 3 && `margin-bottom: 30px;`} */

  @media screen and (min-width: 468px) and (max-width: 600px) {
    width: 80%;
    gap: 0 !important;
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
  @media (max-width: 345px) {
    max-width: 300px;
    margin: auto;
  }
`;

export const StepLine = styled(Box)`
  margin-top: 13px;
  background-color: ${(props) => props.lineColor};
  width: 100%;
  margin-left: -1px;
  height: 9px;
`;

export const StepBar = styled(Box)`
  flex: 0 0 35px;
  background-color: ${(props) =>
    props.done
      ? selectedTheme.colors.primaryPurple
      : selectedTheme.colors.primaryDarkGrayText};
  border-radius: 100%;
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  border: 5px solid
    ${(props) =>
      props.current || props.done
        ? selectedTheme.colors.primaryPurple
        : props.lineColor};
  padding-top: 2px;
  line-height: 19px;
  margin-left: -2px;
  font-size: 14px;
  color: #1d1d1d;
  z-index: 1;
  transition: background-color 1s ease;
  ${(props) => props.done && `cursor: pointer;`}
`;
export const Progress = styled(Box)`
  height: 9px;
  width: ${(props) => (props.done ? "100%" : "0")};
  background-color: ${selectedTheme.colors.primaryPurple};
  transition: width 1s;
`;

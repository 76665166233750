import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCategories } from "../../../store/actions/categories/categoriesActions";
import { selectCategories } from "../../../store/selectors/categoriesSelectors";
import { useTranslation } from "react-i18next";
import {
  AdminSubcategoriesHeader,
  AdminSubcategoriesPageContainer,
  AdminSubcategoriesSearchField,
  ButtonContainer,
  HeaderText,
  NewSubcategoryButton,
  SponsoredCategoryCard,
  SubcategoriesHeader,
  SubcategoriesList,
} from "./AdminSubcategoriesPage.styled";
import { selectManualSearchString } from "../../../store/selectors/filtersSelectors";
import { useMemo } from "react";
import { setManualSearchString } from "../../../store/actions/filters/filtersActions";
import { useRouteMatch } from "react-router-dom";
import CategoryCard from "../../../components/Cards/CategoryCard/CategoryCard";
import selectedTheme from "../../../themes";
import useSorting from "../../../hooks/useOffers/useSorting";
import { sortCategoriesEnum } from "../../../enums/sortEnum";
import { adminSortMethod } from "../../../util/helpers/adminSortHelper";
import { ArrowButton } from "../../../components/Buttons/ArrowButton/ArrowButton";
import history from "../../../store/utils/history";
import { toggleCreateCategoryModal } from "../../../store/actions/modal/modalActions";
import { ADD_TYPE } from "../../../constants/adminMethodConstants";
import { SUBCATEGORIES_TYPE } from "../../../constants/adminTypeConstants";

const AdminSubcategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const routeMatch = useRouteMatch();
  const sorting = useSorting(() => {}, sortCategoriesEnum);
  const manualSearchString = useSelector(selectManualSearchString);

  useEffect(() => {
    dispatch(fetchCategories());
    return () => dispatch(setManualSearchString(""));
  }, []);

  const handleSearch = (value) => {
    dispatch(setManualSearchString(value));
  };

  const category = useMemo(() => {
    if (routeMatch.params?.categoryId) {
      const categoryId = routeMatch.params.categoryId;
      return categories.find((item) => item._id === categoryId);
    }
    return {};
  }, [routeMatch, categories]);

  const subcategories = useMemo(() => {
    if (category) {
      return adminSortMethod(
        category.subcategories,
        manualSearchString,
        sorting
      );
    }
    return [];
  }, [category, manualSearchString, sorting.selectedSortOptionLocally]);
  const goBack = () => {
    history.goBack();
  };
  const showAddSubcategoryModal = () => {
    dispatch(
      toggleCreateCategoryModal({
        hideImagePicker: true,
        type: SUBCATEGORIES_TYPE,
        method: ADD_TYPE,
        showSecondButton: true,
        category,
        categoryId: category?._id,
      })
    );
  };

  return (
    <>
      <AdminSubcategoriesPageContainer>
        <AdminSubcategoriesSearchField
          isAdmin
          handleSearch={handleSearch}
          placeholder={t("admin.subcategories.placeholder")}
        />
        <SubcategoriesHeader>
          <ButtonContainer onClick={goBack}>
            <ArrowButton onClick={goBack} side={"left"}></ArrowButton>
            <HeaderText>{t("admin.subcategories.goBack")}</HeaderText>
          </ButtonContainer>
          <NewSubcategoryButton
            variant="contained"
            buttoncolor={selectedTheme.colors.iconYellowColor}
            textcolor={selectedTheme.colors.messageText}
            onClick={showAddSubcategoryModal}
          >
            {t("admin.subcategories.addSubcategory")}
          </NewSubcategoryButton>
        </SubcategoriesHeader>
        <AdminSubcategoriesHeader
          hideSorting
          myOffers
          category
          hideGrid
          isAdmin
          hideBackButton
        />
        <SubcategoriesList>
          <SponsoredCategoryCard
            subcategory
            category={category}
            hideSecondLabel
            type="categories"
            hideCheckButton
          />
        </SubcategoriesList>
        <AdminSubcategoriesHeader
          sorting={sorting}
          myOffers
          subcategories
          hideGrid
          isAdmin
          hideBackButton
        />
        <SubcategoriesList>
          {subcategories.map((subcategory) => (
            <CategoryCard
              categoryId={category._id}
              subcategory
              key={subcategory._id}
              category={subcategory}
              type="subcategories"
              hideSecondLabel
              hideCheckButton
            />
          ))}
        </SubcategoriesList>
      </AdminSubcategoriesPageContainer>
    </>
  );
};

AdminSubcategoriesPage.propTypes = {
  children: PropTypes.node,
};

export default AdminSubcategoriesPage;

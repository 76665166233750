import {
  ADMIN_FETCH,
  ADMIN_FETCH_ERROR,
  ADMIN_FETCH_SUCCESS,
  ADMIN_QUERY_STRING,
} from "./adminActionConstants";

export const fetchAdminMethod = (payload) => ({
  type: ADMIN_FETCH,
  payload,
});
export const fetchAdminMethodSuccess = () => ({
  type: ADMIN_FETCH_SUCCESS,
});
export const fetchAdminMethodError = () => ({
  type: ADMIN_FETCH_ERROR,
});
export const setAdminQueryString = () => ({
  type: ADMIN_QUERY_STRING
})
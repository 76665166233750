import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as HalfLogo } from "../../../../assets/images/svg/half-logo.svg";
import selectedTheme from "../../../../themes";

export const NoProfileOffersContainer = styled(Box)`
  text-align: center;
  background-color: white;
  height: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 36px;
`;
export const NoProfileOffersIcon = styled(HalfLogo)`
  margin-top: 85px;
  text-align: center;
  width: 100%;
`;
export const NoProfileOffersMainText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 10px;
  line-height: 33px;
`;
export const NoProfileOffersAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryGrayText};
  text-align: center;
  margin-top: 9px;
  line-height: 22px;
`;

import { postRequest } from "./index";
import apiEndpoints from "./apiEndpoints";

export const attemptLogin = (payload) =>
  postRequest(apiEndpoints.authentications.login, payload);

export const logoutUserRequest = (payload) =>
  postRequest(apiEndpoints.authentications.logout, payload);

export const logoutAdminRequest = (payload) =>
  postRequest(apiEndpoints.authentications.logout, payload);

import styled, { css } from "styled-components";
import { List, Box, Typography, Grid } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import selectedTheme from "../../themes";
import { ReactComponent as DownArrow } from "../../assets/images/svg/up-arrow.svg";

export const UserReviewsContainer = styled(Box)`
  ${(props) => props.givingReview && `overflow: hidden;`}
`;

export const ReviewsBox = styled(Box)`
  width: 100%;
  min-width: 350px;
  ${(props) =>
    props.profile &&
    css`
      height: calc(100vh - 138px);
      position: relative;
      top: -15px;
    `}
  @media (max-width: 1350px) {
    min-width: 0;
  }
  /* margin-top: ${(props) => (props.profile ? "60px" : "0")}; */
  @media (max-width: 600px) {
    position: relative;
    min-width: 290px;
    top: -45px;
    overflow: hidden;
    max-height: ${(props) =>
      props.numOfReviews > 2
        ? "650px"
        : props.numOfReviews > 1
        ? "450px"
        : "350px"};
    padding: 0;
    width: 100%;
    margin: 0
      ${(props) => (props.isAdmin && props.isProfileReviews ? "18px" : "0")};
    margin-top: 60px;
  }
`;
export const ReviewsHeader = styled(Grid)`
  justify-content: space-between;
  @media (max-width: 600px) {
    margin-bottom: 18px;
    font-size: 12px;
  }
`;

export const ReviewsTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkTextThird};
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ReviewList = styled(List)`
  background: white;
  padding: 0 18px;
  border-radius: 4px 0 0 4px;
  padding-right: 0.4rem;
  height: 100%;
  width: 100%;
  border: 1px solid ${selectedTheme.colors.borderNormal};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) => (props.isProfileReviews ? "80vh" : "39vh")};

  @media (max-width: 600px) {
    height: 450px;
  }

  /* overflow-y: auto; */
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
`;

export const ThumbUp = styled(ThumbUpIcon)`
  position: relative;
  left: -8px;
`;
export const ThumbDown = styled(ThumbDownIcon)`
  position: relative;
  left: -8px;
`;
export const NoReviewsContainer = styled(Box)``;
export const NoReviewsText = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-size: 24px;
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  font-weight: 700;
  width: 100%;
`;
export const NoReviewsAltText = styled(Typography)`
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
`;
export const ProfileImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 100%;
`;
export const ProfileImageContainer = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  margin-right: 14px;
`;
export const ReviewQuote = styled(Grid)`
  position: relative;
  left: 8px;
`;
export const ThumbBox = styled(Grid)``;
export const ReviewQuoteBox = styled(Grid)``;
export const ReviewQuoteText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const ReviewDetails = styled(Grid)``;
export const ReviewDetailsText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  font-style: italic;
  letter-spacing: 0.02em;
`;
export const ReviewDetailsValue = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-style: normal;
  font-weight: 600;
`;
export const ProfileName = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
`;
export const ReviewContainer = styled(Box)``;
export const ReviewSortDescription = styled(Typography)`
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkTextThird};
`;
export const ReviewSortOption = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 600;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const ReviewSortIcon = styled(DownArrow)`
  transform: rotate(180deg);
  position: relative;
  margin-left: 4px;
  top: 1px;
`;
export const ReviewSortContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: relative;
  top: 4px;
`;
export const ReviewSortOptionContainer = styled(Box)`
  cursor: pointer;
`;
export const ReviewsHeaderTitle = styled(Box)``;

import styled from "styled-components";
import { ReactComponent as Mail } from "../../../../../assets/images/svg/mail.svg";
import selectedTheme from "../../../../../themes";
export const MailIcon = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;

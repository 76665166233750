import { Box } from "@mui/material";
import styled from "styled-components";

export const EditableContainer = styled(Box)`
  font-size: 16px;
  line-height: 22px;
  white-space: pre-line;
  max-width: 100%;
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

import styled, { keyframes } from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../themes";

const skeletonAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonItems};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonItems}
    }
`;

const skeletonBackgroundAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground}
    }
`;

export const SkeletonItemDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  /* margin-left: 36px; */
  /* margin-right: -36px; */
  max-width: 45vw;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
`;

export const SkeletonItemDetailsHeader = styled(Box)`
  width: 145px;
  height: 18px;
  margin-top: 34px;
  margin-bottom: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 72px;
  }
`;

export const SkeletonItemDetailsContent = styled(Box)`
  padding: 18px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

export const SkeletonItemDetailsLineContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SkeletonItemDetailsLineSmallContainer = styled(Box)`
  display: flex;
  gap: 27px;
`;

export const SkeletonItemDetailsLineSmallFirst = styled(Box)`
  width: 72px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 78px;
  }
`;

export const SkeletonItemDetailsLineSmallSecond = styled(Box)`
  width: 72px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 49px;
  }
`;

export const SkeletonItemDetailsLineSmallThird = styled(Box)`
  width: 72px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 49px;
  }
`;

export const SkeletonItemDetailsLineSmallFourth = styled(Box)`
  width: 72px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 39px;
  }
`;

export const SkeletonItemDetailsLineBig = styled(Box)`
  width: 108px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonItemDetailsGalleryMainContainer = styled(Box)`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 60px;
  }
`;

export const SkeletonItemDetailsGallery = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  @media (max-width: 600px) {
    flex-direction: row;
    gap: 0;
  }
`;

export const SkeletonItemDetailsArrow = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

export const SkeletonItemDetailsImagesContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    flex-direction: row;
  }
`;

export const SkeletonItemDetailsImage = styled(Box)`
  width: 144px;
  min-height: 144px;
  border-radius: 4px;
  margin-bottom: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    margin-left: 9px;
    margin-bottom: 0;
  }
`;

export const SkeletonItemDetailsImageHalf = styled(Box)`
  width: 144px;
  height: 83px;
  border-radius: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    transform: rotate(90deg);
    margin-top: 28px;
    margin-left: -15px;
  }
`;

export const SkeletonItemDetailsMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const SkeletonItemDetailsMainMarginS = styled(Box)`
  margin-bottom: 4px;
`;

export const SkeletonItemDetailsMainMarginL = styled(Box)`
  margin-bottom: 30px;

  @media (max-width: 600px) {
    margin-bottom: 3px;
  }
`;

export const SkeletonItemDetailsMainFirstLine = styled(Box)`
  width: 90px;
  height: 27px;
  border-radius: 2px;
  margin-bottom: 15px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    position: absolute;
    top: 370px;
    left: 36px;
    width: 123px;
    height: 20px;
  }
`;

export const SkeletonItemDetailsMainDescription = styled(Box)`
  width: 72px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 26px;
    margin-top: 17px;
  }
`;

export const SkeletonItemDetailsMainSecondLine = styled(Box)`
  width: 449px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 370px;
  }
  @media (max-width: 600px) {
    width: 232px;
  }
`;

export const SkeletonItemDetailsMainThirdLine = styled(Box)`
  width: 427px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 350px;
  }
  @media (max-width: 600px) {
    width: 271px;
  }
`;

export const SkeletonItemDetailsMainFourthLine = styled(Box)`
  width: 445px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 365px;
  }
  @media (max-width: 600px) {
    width: 146px;
  }
`;

export const SkeletonItemDetailsMainFifthLine = styled(Box)`
  width: 449px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 361px;
  }
  @media (max-width: 600px) {
    width: 271px;
  }
`;

export const SkeletonItemDetailsMainSixthLine = styled(Box)`
  width: 445px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 340px;
  }
  @media (max-width: 600px) {
    width: 296px;
  }
`;

export const SkeletonItemDetailsMainSeventhLine = styled(Box)`
  width: 427px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 320px;
  }
  @media (max-width: 600px) {
    width: 160px;
  }
`;

export const SkeletonItemDetailsMainEighthLine = styled(Box)`
  width: 445px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 340px;
  }
  @media (max-width: 600px) {
    width: 192px;
  }
`;

export const SkeletonItemDetailsMainNinethLine = styled(Box)`
  width: 449px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 335px;
  }
  @media (max-width: 600px) {
    width: 249px;
  }
`;

export const SkeletonItemDetailsMainTenthLine = styled(Box)`
  width: 449px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 345px;
  }

  @media (max-width: 600px) {
    width: 237px;
    margin-bottom: 18px;
  }
`;

export const SkeletonItemDetailsMainEleventhLine = styled(Box)`
  width: 427px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 350px;
  }

  @media (max-width: 600px) {
    width: 274px;
  }
`;

export const SkeletonItemDetailsMainTwelfthLine = styled(Box)`
  width: 449px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 342px;
  }

  @media (max-width: 600px) {
    width: 288px;
  }
`;

export const SkeletonItemDetailsMainThirteenthLine = styled(Box)`
  width: 445px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 1200px) {
    width: 352px;
  }

  @media (max-width: 600px) {
    width: 283px;
  }
`;

export const SkeletonItemDetailsMainFourteenthLine = styled(Box)`
  width: 307px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 4px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonItemDetailsMainButtons = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 268px;
  margin-left: auto;

  @media (max-width: 600px) {
    margin-top: 35px;
  }
`;

export const SkeletonItemDetailsMainButtonRoundFirst = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 59px;
    top: 363px;
  }
`;

export const SkeletonItemDetailsMainButtonRoundSecond = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 18px;
    top: 363px;
  }
`;

export const SkeletonItemDetailsMainButton = styled(Box)`
  width: 108px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

export const SkeletonItemDetailsMainButtonContainer = styled(Box)`
  border-radius: 4px;
  padding: 17px 36px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonItemDetailsMainMobileButtonLinesCont = styled(Box)`
  position: absolute;
  left: 36px;
`;

export const SkeletonItemDetailsMainMobileButtonFirstLine = styled(Box)`
  width: 53px;
  height: 14px;
  border-radius: 2px;
  margin-bottom: 3px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonItemDetailsMainMobileButtonSecondLine = styled(Box)`
  width: 67px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;
`;

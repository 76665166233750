export const typeSelectEnum = {
  SERVICE: {
    value: 1,
    mainText: "Usluga",
  },
  PRODUCT: {
    value: 2,
    mainText: "Proizvod",
  },
};

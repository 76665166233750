import { Box } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import Header from "../../../components/MarketPlace/Header/Header";
import SearchField from "../../../components/TextFields/SearchField/SearchField";

export const AdminLocationsPageContainer = styled(Box)`
  padding: 60px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 38px;
  min-height: 100vh;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 18px;
    min-height: (100vh - 72px);
    padding-bottom: 100px;
    top: 38px;
  }
`;
export const AdminLocationsHeader = styled(Header)`
  top: 0;
  @media (min-width: 600px) and (max-width: 900px) {
    & > div:nth-child(2) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    top: 0px;
    margin-top: 0px;
    & > div {
      margin-top: 0px;
    }
    /* & > div > div > div {
      top: 25px;
      left: 0;
    } */
  }
`;
export const LocationsList = styled(Box)`
  padding-top: 10px;
`;
export const AdminLocationsSearchField = styled(SearchField)`
  top: -15px;

  @media (max-width: 900px) {
    margin-top: 72px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
export const NewLocationButton = styled(PrimaryButton)`
  position: relative;
  margin-left: auto;
  height: 48px;
  width: 224px;
  & button {
    font-weight: 700;
  }
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;

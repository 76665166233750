import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logout } from "../../../../assets/images/svg/log-out.svg";

export const MiddleButton = styled(Box)`
  margin: 0 16px;
  padding-top: 14px;
  padding-bottom: -2px;
  border-top: 1px solid rgba(77, 77, 77, 0.12);
  border-bottom: 1px solid rgba(77, 77, 77, 0.12);
  text-align: right;
  & button {
    margin-right: 0;
    position: relative;
    top: 2px;
  }
`;
export const LogoutIcon = styled(Logout)``;

import { createSelector } from "reselect";

const filtersSelector = (state) => state.filters;

export const selectFilters = createSelector(
  filtersSelector,
  (state) => state.filters
);
export const selectSelectedCategory = createSelector(
  filtersSelector,
  (state) => state.filters.category
);
export const selectSelectedSubcategory = createSelector(
  filtersSelector,
  (state) => state.filters.subcategory
);
export const selectSelectedLocations = createSelector(
  filtersSelector,
  (state) => state.filters.locations
);
export const selectSelectedPayments = createSelector(
  filtersSelector,
  (state) => state.filters.payments
);
export const selectSelectedCompany = createSelector(
  filtersSelector,
  (state) => state.filters.company
);
export const selectSelectedSortOption = createSelector(
  filtersSelector,
  (state) => state.filters.sortOption
);
export const selectAppliedStatus = createSelector(
  filtersSelector,
  (state) => state.filters.isApplied
);
export const selectHeaderString = createSelector(
  filtersSelector,
  (state) => state.filters.headerString
);
export const selectSearchString = createSelector(
  filtersSelector,
  (state) => state.filters.searchString
);
export const selectQueryString = createSelector(
  filtersSelector,
  (state) => state.filters.queryString
);
export const selectManualSearchString = createSelector(
  filtersSelector,
  (state) => state.filters.manualSearchString
);

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { TextField } from "../../../../TextFields/TextField/TextField";

export const SelectedItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const ClearText = styled(Box)`
  padding-top: 1px;
  border-radius: 100%;
  cursor: pointer;
  padding-right: 2px;
  position: relative;
  left: 6px;
  width: 21px;
  height: 21px;
  &:hover {
    background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  }
`;
export const SearchBar = styled(TextField)`
  & div {
    background-color: white;
  }
`;

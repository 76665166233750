import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Info } from "../../../assets/images/svg/info-circled.svg";
import selectedTheme from "../../../themes";

export const RequestExchangeCardContainer = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.ismymessage ? `row-reverse` : `row`)};
  margin-bottom: 18px;
`;
export const MessageContent = styled(Box)`
  background-color: ${selectedTheme.colors.messageBackground};
  border-radius: ${(props) =>
    props.ismymessage ? "9px 0px 9px 9px" : "0px 9px 9px 9px"};
  padding: 9px;
  padding-bottom: 31px;
  position: relative;
  min-height: 65px;
  margin: 0 18px;
  min-width: 110px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
    ${(props) =>
      props.ismymessage ? "margin-right: 9px;" : "margin-left: 9px;"}
  }
`;
export const MessageText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  line-height: 22px;
  color: ${selectedTheme.colors.messageText};
`;
export const MessageDate = styled(Typography)`
  color: ${(props) =>
    props.ismymessage
      ? selectedTheme.colors.messageMyDate
      : selectedTheme.colors.messageDate};
  font-size: 12px;
  font-style: italic;
  position: absolute;
  bottom: 9px;
  left: 9px;
`;
export const InfoIcon = styled(Info)``;

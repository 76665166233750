import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { attemptFetchLocations } from "../../request/locationsRequest";
import { LOCATIONS_FETCH } from "../actions/locations/locationsActionConstants";
import {
  fetchLocationsError,
  fetchLocationsSuccess,
  setLocations,
} from "../actions/locations/locationsActions";

function* fetchLocations() {
  try {
    const data = yield call(attemptFetchLocations);
    if (!data?.data) throw new Error();
    yield put(setLocations(data.data));
    yield put(fetchLocationsSuccess());
  } catch (e) {
    yield put(fetchLocationsError());
  }
}

export default function* locationsSaga() {
  yield all([takeLatest(LOCATIONS_FETCH, fetchLocations)]);
}

import {
  OFFERS_ADD,
  OFFERS_CLEAR,
  OFFERS_ERROR,
  OFFERS_MINE_SET,
  OFFERS_NO_MORE,
  OFFERS_PINNED_ADD,
  OFFERS_PINNED_SET,
  OFFERS_SET,
  OFFER_ADD,
  OFFER_SET,
  ONE_OFFER_ERROR,
  ONE_OFFER_SUCCESS,
  OFFERS_SET_TOTAL,
  OFFERS_PROFILE_SET,
  OFFERS_FEATURED_CLEAR,
  OFFERS_FEATURED_SET,
  OFFER_INDEX_SET,
  OFFER_INDEX_CLEAR,
  OFFER_PAGE_SET,
  OFFER_FEATURED_PAGE_SET,
  OFFER_SELECTED_CLEAR,
  OFFERS_HEADER_SET,
  OFFERS_PROFILE_ADD,
} from "../../actions/offers/offersActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  offers: [],
  pinnedOffers: [],
  mineOffers: [],
  mineHeaderOffers: [],
  profileOffers: [],
  total: 0,
  error: "",
  newOffer: "",
  selectedOffer: "",
  noMoreOffers: false,
  offerIndex: null,
  offerPage: null,
  featuredOfferPage: null,
};

export default createReducer(
  {
    [OFFERS_ERROR]: fetchOffersError,
    [OFFERS_CLEAR]: clearOffers,
    [OFFERS_SET]: setOffers,
    [OFFERS_ADD]: addOffers,
    [OFFER_ADD]: addOffer,
    [OFFER_SET]: setOffer,
    [ONE_OFFER_ERROR]: fetchOneOfferError,
    [ONE_OFFER_SUCCESS]: fetchOneOfferSuccess,
    [OFFERS_NO_MORE]: setNoMoreOffersStatus,
    [OFFERS_PINNED_ADD]: addPinnedOffers,
    [OFFERS_PINNED_SET]: setPinnedOffers,
    [OFFERS_SET_TOTAL]: setTotalOffers,
    [OFFERS_MINE_SET]: setMineOffers,
    [OFFERS_HEADER_SET]: setHeaderOffers,
    [OFFERS_PROFILE_SET]: setProfileOffers,
    [OFFERS_PROFILE_ADD]: addProfileOffers,
    [OFFERS_FEATURED_CLEAR]: clearFeaturedOffers,
    [OFFERS_FEATURED_SET]: setFeaturedOffers,
    [OFFER_INDEX_SET]: setOfferIndex,
    [OFFER_INDEX_CLEAR]: clearOfferIndex,
    [OFFER_PAGE_SET]: setOfferPage,
    [OFFER_FEATURED_PAGE_SET]: setFeaturedOfferPage,
    [OFFER_SELECTED_CLEAR]: clearSelectedOffer,
  },
  initialState
);

function fetchOffersError(state, action) {
  return { ...state, error: action.payload };
}

function clearOffers(state) {
  return { ...state, offers: [] };
}
function setOffers(state, action) {
  return {
    ...state,
    offers: action.payload,
  };
}
function setOffer(state, action) {
  return {
    ...state,
    selectedOffer: action.payload,
  };
}
function addOffers(state, action) {
  return {
    ...state,
    offers: [...state.offers, ...action.payload],
  };
}
function setPinnedOffers(state, action) {
  return {
    ...state,
    pinnedOffers: [...action.payload],
  };
}
function addOffer(state, action) {
  return {
    ...state,
    offer: action.payload,
  };
}
function fetchOneOfferSuccess(state, action) {
  return {
    ...state,
    selectedOffer: action.payload,
  };
}
function fetchOneOfferError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}
function addPinnedOffers(state, action) {
  return {
    ...state,
    pinnedOffers: [...state.pinnedOffers, ...action.payload],
  };
}
function setNoMoreOffersStatus(state, action) {
  return {
    ...state,
    noMoreOffers: action.payload,
  };
}
function setTotalOffers(state, action) {
  return {
    ...state,
    total: action.payload,
  };
}
function setMineOffers(state, action) {
  return {
    ...state,
    mineOffers: action.payload,
  };
}
function setProfileOffers(state, action) {
  return {
    ...state,
    profileOffers: action.payload,
  };
}

function setFeaturedOffers(state, action) {
  return {
    ...state,
    pinnedOffers: action.payload,
  };
}
function clearFeaturedOffers(state) {
  return {
    ...state,
    pinnedOffers: [],
  };
}
function setOfferIndex(state, { payload }) {
  return {
    ...state,
    offerIndex: payload,
  };
}
function clearOfferIndex(state) {
  return {
    ...state,
    offerIndex: null,
  };
}
function setOfferPage(state, { payload }) {
  return {
    ...state,
    offerPage: payload,
  };
}
function setFeaturedOfferPage(state, { payload }) {
  return {
    ...state,
    featuredOfferPage: payload,
  };
}
function clearSelectedOffer(state) {
  return {
    ...state,
    selectedOffer: "",
  };
}
function setHeaderOffers(state, { payload }) {
  return {
    ...state,
    mineHeaderOffers: payload,
  };
}
function addProfileOffers(state, { payload }) {
  return {
    ...state,
    profileOffers: [...state.profileOffers, ...payload],
  };
}

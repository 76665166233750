import React, { useRef } from "react";
import PropTypes from "prop-types";
import { ContentContainer, FilterCardContainer } from "./FilterCard.styled";
// import HeaderBack from "../../ItemDetails/Header/Header";
import FilterHeader from "./FilterHeader/FilterHeader";
// import FilterFooter from "./FilterFooter/FilterFooter";
import CategoryChoser from "./Choser/CategoryChoser/CategoryChoser";
import SubcategoryChoser from "./Choser/SubcategoryChoser/SubcategoryChoser";
import LocationChoser from "./Choser/LocationChoser/LocationChoser";
import CompanyChoser from "./Choser/CompanyChoser/CompanyChoser";
import SkeletonFilterCard from "./Skeleton/SkeletonFilterCard";
import FilterFooter from "./FilterFooter/FilterFooter";

const FilterCard = (props) => {
  const offers = props.offers;
  const filters = offers.filters;
  const categoryRef = useRef(null);
  const subcategoryRef = useRef(null);
  const locationRef = useRef(null);
  const companyRef = useRef(null);
  const closeAllSections = () => {
    categoryRef?.current?.closeSection();
    subcategoryRef?.current?.closeSection();
    locationRef?.current?.closeSection();
    companyRef?.current?.closeSection();
  };

  return (
    <FilterCardContainer
      filtersOpened={props.filtersOpened}
      myOffers={props.myOffers}
      skeleton={props.skeleton}
      payments={props.payments}
    >
      {props?.skeleton && <SkeletonFilterCard skeleton={props.skeleton} />}
      {/* Header title for my offers */}
      {/* {props.myOffers && <HeaderBack />} */}

      <FilterHeader
        filters={offers}
        closeAllSections={closeAllSections}
        isMyOffers={props.myOffers}
        toggleFilters={props.toggleFilters}
        toggleDrawer={props.toggleDrawer}
        payments={props.payments}
      />

      <ContentContainer>
        {/* Categories */}
        {!props.payments && (
          <CategoryChoser filters={filters} ref={categoryRef} offers={offers} />
        )}

        {/* Subcategories */}
        {!props.payments && (
          <SubcategoryChoser
            filters={filters}
            queryStringHook={offers.queryStringHook}
            ref={subcategoryRef}
            categoryOpened={categoryRef.current?.isOpened}
            myOffers={props.myOffers}
            offers={offers}
          />
        )}

        {/* Locations */}
        {!props.myOffers && !props.payments && (
          <LocationChoser filters={filters} ref={locationRef} offers={offers} />
        )}

        {/* Companies */}
        {!props.myOffers && (
          <CompanyChoser filters={filters} ref={companyRef} offers={offers} />
        )}
      </ContentContainer>

      <FilterFooter
        toggleFilters={props.toggleFilters}
        filters={offers}
        closeAllSections={closeAllSections}
        isMyOffers={props.myOffers}
        toggleDrawer={props.toggleDrawer}
        payments={props.payments}
      />
    </FilterCardContainer>
  );
};

FilterCard.propTypes = {
  children: PropTypes.node,
  offers: PropTypes.any,
  responsive: PropTypes.bool,
  responsiveOpen: PropTypes.bool,
  closeResponsive: PropTypes.func,
  myOffers: PropTypes.bool,
  skeleton: PropTypes.bool,
  filtersOpened: PropTypes.bool,
  toggleFilters: PropTypes.func,
  payments: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

FilterCard.defaultProps = {
  responsive: false,
  responsiveOpen: false,
};

export default FilterCard;

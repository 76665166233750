import React from "react";
import PropTypes from "prop-types";
import { ListContainer } from "./RichTextElement.styled";
import selectedTheme from "../../../themes";

const RichTextElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ListContainer style={style} {...attributes}>
          {children}
        </ListContainer>
      );
    case "link":
      return (
        <a href="" style={style} {...attributes}>
          {children}
        </a>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <div
          style={style}
          {...attributes}
          color={selectedTheme.colors.colorPicker.darkGray}
        >
          {children}
        </div>
      );
  }
};

RichTextElement.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.any,
};

export default RichTextElement;

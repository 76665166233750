import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  AdminLocationsHeader,
  AdminLocationsPageContainer,
  AdminLocationsSearchField,
  LocationsList,
  NewLocationButton,
} from "./AdminLocationsPage.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../store/selectors/locationsSelectors";
import { fetchLocations } from "../../../store/actions/locations/locationsActions";
import CategoryCard from "../../../components/Cards/CategoryCard/CategoryCard";
import selectedTheme from "../../../themes";
import { setManualSearchString } from "../../../store/actions/filters/filtersActions";
import { selectManualSearchString } from "../../../store/selectors/filtersSelectors";
import useSorting from "../../../hooks/useOffers/useSorting";
import { sortCategoriesEnum } from "../../../enums/sortEnum";
import { adminSortMethod } from "../../../util/helpers/adminSortHelper";
import { toggleCreateCategoryModal } from "../../../store/actions/modal/modalActions";

const AdminLocationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sorting = useSorting(() => {}, sortCategoriesEnum);
  const locations = useSelector(selectLocations);
  const manualSearchString = useSelector(selectManualSearchString);
  useEffect(() => {
    dispatch(fetchLocations());
    return () => {
      dispatch(setManualSearchString(""));
      sorting.clear();
    };
  }, []);
  const handleSearch = (value) => {
    dispatch(setManualSearchString(value));
  };
  const locationsToShow = useMemo(() => {
    if (locations) {
      return adminSortMethod(locations, manualSearchString, sorting);
    }
  }, [locations, manualSearchString, sorting.selectedSortOptionLocally]);
  const showAddCategoryModal = () => {
    dispatch(
      toggleCreateCategoryModal({
        hideImagePicker: true,
        type: "locations",
        method: "add",
        showSecondButton: true,
      })
    );
  };
  return (
    <>
      <AdminLocationsPageContainer>
        <AdminLocationsSearchField
          isAdmin
          handleSearch={handleSearch}
          placeholder={t("admin.locations.placeholder")}
        />
        <NewLocationButton
          variant="contained"
          buttoncolor={selectedTheme.colors.iconYellowColor}
          textcolor={selectedTheme.colors.messageText}
          onClick={showAddCategoryModal}
        >
          {t("admin.locations.addLocation")}
        </NewLocationButton>
        <AdminLocationsHeader
          myOffers
          location
          hideGrid
          isAdmin
          hideBackButton
          sorting={sorting}
        />
        <LocationsList>
          {locationsToShow.map((category) => (
            <CategoryCard
              dontNavigate
              key={category._id}
              category={category}
              type="locations"
              hideCheckButton
              secondLabel={t("admin.locations.noOfCompanies")}
            />
          ))}
        </LocationsList>
      </AdminLocationsPageContainer>
    </>
  );
};

AdminLocationsPage.propTypes = {
  children: PropTypes.node,
};

export default AdminLocationsPage;

import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const ForgotPasswordPageContainer = styled(Container)`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 900px) {
    margin-top: 160px;
  }
  @media (max-height: 800px) {
    margin-top: 120px;
  }
`;
export const ForgotPasswordTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
`;
export const ForgotPasswordDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
`;
export const FormContainer = styled(Box)`
  width: 335px;
  height: 216px;

  @media (max-width: 345px) {
    width: 300px;
  }
`;
export const ErrorMessage = styled(Typography)`
  color: red;
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  top: -7px;
  font-size: 14px;
`;
export const LoginAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  font-size: 14px;
  padding-right: 6px;
  line-height: 14px;
`;
export const LoginTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
  @media (max-height: 800px) {
    margin-top: 26px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  NotAllowedChatContainer,
  NotAllowedChatText,
} from "./NotAllowedChat.styled";
import { useTranslation } from "react-i18next";

const NotAllowedChat = (props) => {
  const { t } = useTranslation();
  return (
    <NotAllowedChatContainer>
      <NotAllowedChatText>
        {props.mineProfileBlocked
          ? t("profile.mineProfileBlocked")
          : props.blocked
          ? t("profile.blockedProfile")
          : props.deleted
          ? t("messages.notAllowedChatDeleted")
          : t("messages.notAllowedChat")}
      </NotAllowedChatText>
    </NotAllowedChatContainer>
  );
};

NotAllowedChat.propTypes = {
  children: PropTypes.node,
  blocked: PropTypes.bool,
  mineProfileBlocked: PropTypes.bool,
  deleted: PropTypes.bool,
};

export default NotAllowedChat;

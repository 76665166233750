import styled, { keyframes } from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../../themes";

const skeletonAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonItems};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonItems}
    }
`;

const skeletonBackgroundAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground}
    }
`;

export const SkeletonMiniChatColumnContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonMiniChatColumnHeading = styled(Box)`
  width: 90px;
  height: 18px;
  margin-bottom: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

export const SkeletonMiniChatColumnItem = styled(Box)`
  display: flex;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  padding: 18px;
  margin-bottom: 18px;
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

export const SkeletonMiniChatItemImage = styled(Box)`
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonMiniChatItemInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 13px;
`;

export const SkeletonMiniChatItemInfoFirstLine = styled(Box)`
  width: 117px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 9px;
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonMiniChatItemInfoSecondLine = styled(Box)`
  width: 90px;
  height: 9px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 4px;
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonMiniChatItemInfoThirdLine = styled(Box)`
  width: 90px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;
`;

import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { attemptFetchCategories } from "../../request/categoriesRequest";
import { CATEGORIES_FETCH } from "../actions/categories/categoriesActionConstants";
import {
  fetchCategoriesError,
  fetchCategoriesSuccess,
  setCategories,
} from "../actions/categories/categoriesActions";

function* fetchCategories() {
  try {
    const data = yield call(attemptFetchCategories);
    if (!data?.data) throw new Error();
    yield put(setCategories(data.data));
    yield put(fetchCategoriesSuccess());
  } catch (e) {
    yield put(fetchCategoriesError());
    console.dir(e);
  }
}

export default function* categoriesSaga() {
  yield all([takeLatest(CATEGORIES_FETCH, fetchCategories)]);
}

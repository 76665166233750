import React from "react";
import PropTypes from "prop-types";
import BlogDetails from "../../components/BlogDetails/BlogDetails";
import { BlogDetailsPageContainer, Layout } from "./BlogDetailsPage.styled";
import BlogComponent from "../../components/Blog/BlogComponent";

const BlogDetailsPage = (props) => {
  const blogId = props.match.params.blogId;
  return (
    <BlogDetailsPageContainer>
      <Layout
        content={<BlogDetails blogId={blogId} sidebar />}
        rightCard={<BlogComponent sidebar />}
        profile
        sidebar
      />
    </BlogDetailsPageContainer>
  );
};

BlogDetailsPage.propTypes = {
  children: PropTypes.node,
  match: PropTypes.any,
};

export default BlogDetailsPage;

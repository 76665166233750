import React, { useState } from "react";
import PropTypes from "prop-types";
import { MyOffersContainer } from "./MyOffers.styled";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import FilterCard from "../../components/Cards/FilterCard/FilterCard";
import MarketPlace from "../../components/MarketPlace/MarketPlace";
import useMyOffers from "../../hooks/useOffers/useMyOffers";
import { useSelector } from "react-redux";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { OFFERS_MINE_SCOPE } from "../../store/actions/offers/offersActionConstants";

const MyOffers = () => {
  const offers = useMyOffers();
  const isLoadingMineOffers = useSelector(
    selectIsLoadingByActionType(OFFERS_MINE_SCOPE)
  );
  const [filtersOpened, setFiltersOpened] = useState(false);

  const toggleFilters = () => {
    setFiltersOpened((prevFiltersOpened) => !prevFiltersOpened);
  };
  console.log(offers);
  return (
    <MyOffersContainer>
      <MainLayout
        leftCard={
          <FilterCard
            myOffers
            offers={offers}
            filtersOpened={filtersOpened}
            toggleFilters={toggleFilters}
            skeleton={isLoadingMineOffers}
          />
        }
        content={
          <MarketPlace
            myOffers={true}
            offers={offers}
            skeleton={isLoadingMineOffers}
            toggleFilters={toggleFilters}
          />
        }
      />
    </MyOffersContainer>
  );
};

MyOffers.propTypes = {
  children: PropTypes.node,
};

export default MyOffers;

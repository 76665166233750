import React from "react";
import PropTypes from "prop-types";
import ImagePicker from "../../../../ImagePicker/ImagePicker";
import { Scroller } from "./OfferImagePicker.styled";

const OfferImagePicker = (props) => {
  return (
    <Scroller>
      {props.images.map((item, index) => {
        return (
          <ImagePicker
            key={index}
            image={item}
            setImage={(image) => props.setImage(index, image)}
            deleteImage={() => props.setImage(index, null)}
            showDeleteIcon
          />
        );
      })}
    </Scroller>
  );
};

OfferImagePicker.propTypes = {
  images: PropTypes.array,
  setImage: PropTypes.func,
};

export default OfferImagePicker;

import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { useTranslation } from "react-i18next";

const LoginButton = (props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <PrimaryButton
      type="submit"
      variant="contained"
      height="48px"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor="white"
      disabled={
        formik.values.email.length === 0 || formik.values.password.length === 0
      }
    >
      {t("login.logIn")}
    </PrimaryButton>
  );
};

LoginButton.propTypes = {
  formik: PropTypes.any,
};

export default LoginButton;

import React from "react";
import PropTypes from "prop-types";
import {
  CategoryDetailContainer,
  CategoryDetailLabel,
  CategoryDetailValue,
} from "./CategoryDetail.styled";

const CategoryDetail = (props) => {
  return (
    <CategoryDetailContainer payments={props.payments}>
      <CategoryDetailLabel payments={props.payments}>
        {props.label}
      </CategoryDetailLabel>
      <CategoryDetailValue payments={props.payments}>
        {props.value}
      </CategoryDetailValue>
    </CategoryDetailContainer>
  );
};

CategoryDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  payments: PropTypes.bool,
};

export default CategoryDetail;

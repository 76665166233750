import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as ArrowUpRight } from "../../../../assets/images/svg/arrow-up-right-from-square.svg";
import { ReactComponent as Marketplace } from "../../../../assets/images/svg/package.svg";
import selectedTheme from "../../../../themes";

export const MarketplaceButtonContainer = styled(Box)`
  font-family: "DM Sans";
  font-size: 16px;
  padding-top: 1.7vh;
  padding-bottom: 1.7vh;
  padding-left: 18px;
  margin-top: 8vh;
  margin-left: 18px;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${selectedTheme.colors.primaryPurple};
  cursor: pointer;

  &:hover {
    background-color: #f5edff;
    font-weight: 700;
  }
`;

export const MarketplaceIcon = styled(Marketplace)`
  position: relative;
  top: 3px;
  width: 18px;
  height: 18px;
`;
export const ArrowUpRightIcon = styled(ArrowUpRight)`
  position: relative;
  left: 4px;
`;
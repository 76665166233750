import { Box, FormControlLabel, Radio } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const RadioButtonContainer = styled(Box)`
  ${(props) =>
    props.fullwidth &&
    `
        width: 100%;
        display: flex;
        flex: 1;
    `}
`;
export const RadioButtonStyled = styled(Radio)`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 9px;
  width: 14px;
  height: 14px;
`;
export const FormControlLabelStyled = styled(FormControlLabel)`
  ${(props) =>
    props.fullwidth &&
    `
        width: 100%;
        display: flex;
        flex: 1;
    `}
  margin-right: 0;
  & label {
    font-family: ${selectedTheme.fonts.textFont};
    font-size: 14px;
  }
  & span:nth-child(1) svg {
    width: 16px;
    height: 16px;
  }
  & span:nth-child(2) {
    flex: 1;
  }
`;

import { LOCATIONS_SET } from "../../actions/locations/locationsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  locations: [],
};

export default createReducer(
  {
    [LOCATIONS_SET]: setLocations,
  },
  initialState
);

function setLocations(state, action) {
  return {
    ...state,
    locations: action.payload,
  };
}

import React, { useCallback, useMemo } from "react";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor, Editor } from "slate";
import PropTypes from "prop-types";

import RichTextElement from "./RichTextElement/RichTextElement";
import MarkButton from "./MarkButton/MarkButton";
import RichTextLeaf from "./RichTextLeaf/RichTextLeaf";
import { ReactComponent as BulletedList } from "../../assets/images/svg/bulleted-list.svg";
import {
  EditableContainer,
  EditableInnerContainer,
  RichTextComponentContainer,
  SlateButtonsContainer,
} from "./RichTextComponent.styled";
import { useState } from "react";
import BlockButton from "./BlockButton/BlockButton";
import ColorButton from "./ColorButton/ColorButton";
import selectedTheme from "../../themes";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { isJsonString } from "../../util/helpers/jsonHelper";
import { useEffect } from "react";
import LinkButton from "./LinkButton/LinkButton";

const RichTextComponent = (props) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState({
    color: selectedTheme.colors.colorPicker.darkGray,
    colorName: "darkGray",
  });
  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  useEffect(() => {
    setValue(props?.value);
    editor.children = isJsonString(props?.value)
      ? JSON.parse(props?.value)
      : value;
  }, [props?.value]);

  const renderElement = useCallback(
    (props) => <RichTextElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <RichTextLeaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), [props?.value]);

  return (
    <RichTextComponentContainer
      offerCard={props?.offerCard}
      readOnly={props?.readOnly}
      itemDetails={props?.itemDetails}
    >
      <Slate
        editor={editor}
        value={isJsonString(props?.value) ? JSON.parse(props?.value) : value}
        onChange={(newValue) => {
          const marks = Editor.marks(editor);
          if (marks) {
            if (
              ((editor?.selection &&
                Editor.string(editor, editor.selection).length === 0) ||
                !editor?.selection) &&
              marks?.a
            ) {
              Editor.removeMark(editor, "a");
            }
          }
          console.log(newValue);
          if (props?.onChange) props?.onChange(JSON.stringify(newValue));
          else setValue(newValue);
        }}
      >
        {!isMobile && (
          <SlateButtonsContainer readOnly={props?.readOnly}>
            <MarkButton format="bold" icon="B" />
            <MarkButton format="italic" icon="I" />
            <MarkButton format="underline" icon="U" />
            <LinkButton />
            <BlockButton format="bulleted-list" icon={<BulletedList />} />
            <ColorButton
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </SlateButtonsContainer>
        )}
        <EditableContainer
          offerCard={props?.offerCard}
          itemDetails={props?.itemDetails}
        >
          <EditableInnerContainer readOnly={props?.readOnly}>
            <Editable
              placeholder={t("offer.description")}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              autoFocus
              on
              readOnly={props?.readOnly}
            />
          </EditableInnerContainer>
        </EditableContainer>
      </Slate>
    </RichTextComponentContainer>
  );
};

// const initialValue = [
//   {
//     type: "paragraph",
//     children: [{ text: "" }],
//   },
// ];
RichTextComponent.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  offerCard: PropTypes.bool,
  itemDetails: PropTypes.bool,
};

export default RichTextComponent;

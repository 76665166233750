import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  PAYMENTS_ADD,
  PAYMENTS_DELETE,
  PAYMENTS_EDIT,
  PAYMENTS_FETCH,
} from "../actions/payment/paymentActionConstants";
import {
  fetchPaymentsError,
  fetchPaymentsSuccess,
  setPayments,
} from "../actions/payment/paymentActions";
import {
  attemptAddNewPayment,
  attemptDeletePayment,
  attemptEditPayment,
  attemptFetchPayments,
} from "../../request/paymentsRequest";
import { fetchPayments } from "../actions/payment/paymentActions";

function* fetchAllPayments() {
  try {
    const data = yield call(attemptFetchPayments);
    if (!data?.data) throw new Error();
    yield put(setPayments(data.data));
    yield put(fetchPaymentsSuccess());
  } catch (e) {
    yield put(fetchPaymentsError());
  }
}

function* createPayment(payload) {
  try {
    yield call(attemptAddNewPayment, payload.payload);
    yield put(fetchPayments());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    console.dir(e);
  }
}

function* editPayment(payload) {
  try {
    yield call(attemptEditPayment, {
      id: payload.payload.id,
      body: {
        type: payload.payload.type,
        payerName: payload.payload.payerName,
        userId: payload.payload.userId,
        offerId: payload.payload.offerId,
        date: payload.payload.date,
      },
    });
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    console.dir(e);
  }
}

function* deletePayment(payload) {
  try {
    yield call(attemptDeletePayment, { id: payload.payload.id });
    if (payload.payload.handleApiDeletePaymentResponseSuccess) {
      yield call(payload.payload.handleApiDeletePaymentResponseSuccess);
    }
  } catch (e) {
    console.dir(e);
  }
}

export default function* paymentSaga() {
  yield all([
    takeLatest(PAYMENTS_FETCH, fetchAllPayments),
    takeLatest(PAYMENTS_ADD, createPayment),
    takeLatest(PAYMENTS_EDIT, editPayment),
    takeLatest(PAYMENTS_DELETE, deletePayment),
  ]);
}

import * as React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import selectedTheme from "../../themes";

const BasicChip = (props) => {
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label={props.label}
        variant="outlined"
        size="small"
        sx={{
          "& .MuiChip-label": {
            textTransform: "capitalize",
            fontFamily: `${selectedTheme.fonts.textFont}`,
          },
        }}
      />
    </Stack>
  );
};

BasicChip.propTypes = {
  label: PropTypes.string,
};

export default BasicChip;

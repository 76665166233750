import { createErrorType, createFetchType, createSetType, createSuccessType } from "../actionHelpers";


const FORGOT_PASSWORD_SCOPE = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD = createFetchType(FORGOT_PASSWORD_SCOPE);
export const FORGOT_PASSWORD_SUCCESS = createSuccessType(FORGOT_PASSWORD_SCOPE);
export const FORGOT_PASSWORD_ERROR = createErrorType(FORGOT_PASSWORD_SCOPE);

const RESET_PASSWORD_SCOPE = "RESET_PASSWORD";
export const RESET_PASSWORD = createFetchType(RESET_PASSWORD_SCOPE);
export const RESET_PASSWORD_SUCCESS = createSuccessType(RESET_PASSWORD_SCOPE);
export const RESET_PASSWORD_ERROR = createErrorType(RESET_PASSWORD_SCOPE);

export const SET_USER_ACCESS_TOKEN = createSetType("SET_USER_ACCESS_TOKEN");
export const SET_USER_REFRESH_TOKEN = createSetType("SET_USER_REFRESH_TOKEN");
export const SET_USER = createSetType('SET_USER');
export const SET_USER_ERROR = createSetType('SET_USER_ERROR');
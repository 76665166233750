import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { IconButton } from "../../Buttons/IconButton/IconButton";

export const DropdownListContainer = styled(Box)`
  width: ${(props) =>
    props.fullwidth ? "100%" : props.width ? props.width : "250px"};
  padding: 8px 0;
`;

export const DropdownTitle = styled(Typography)`
  display: flex;
  flex: 1;
  cursor: pointer;
  padding-left: 9px;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 5px;
  padding-right: 0.9rem;
  width: 185px;
  margin-right: 15px;
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  max-height: 26px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${(props) =>
    props.disabled
      ? selectedTheme.colors.iconStrokeDisabledColor
      : props.textcolor
      ? props.textcolor
      : selectedTheme.coloros.primaryText};
  @media (max-width: 600px) {
    font-size: 14px;
    width: 260px;
    max-width: 260px;
  }
`;

export const ToggleIconOpened = styled(IconButton)`
  cursor: pointer;
  color: ${selectedTheme.colors.primaryPurple};
  & span {
    ${(props) =>
      props.backgroundColor ? `background-color: ${props.backgroundColor}` : ``}
  }
`;

export const ToggleIconClosed = styled(IconButton)`
  cursor: pointer;
  color: ${selectedTheme.colors.primaryPurple};
  &:hover button {
    ${(props) =>
      props.disabled &&
      `background-color: ${selectedTheme.colors.primaryIconBackgroundColor}`}
  }
  & span {
    ${(props) =>
      props.backgroundColor || !props.disabled
        ? `background-color: ${props.backgroundColor}`
        : ``}
  }
  & svg path {
    ${(props) =>
      props.disabled &&
      `
      stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
    `}
  }
`;

export const DropdownIcon = styled(IconButton)`
  font-size: 22px !important;

  & svg {
    font-size: 22px;
    & path {
      ${(props) =>
        props.disabled &&
        `
        stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
        `}
    }
  }
`;

export const ListContainer = styled(Box)`
  padding-left: 15px;
  margin-left: 15px;
`;

export const DropdownHeader = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const DropdownOptions = styled(Box)``;
export const ToggleContainer = styled(Box)`
  display: ${(props) => (props.shouldShow ? "block" : "none")};
`;

import styled from "styled-components";
import selectedTheme from "../../../themes";
import { TextField } from "../../TextFields/TextField/TextField";
import { ReactComponent as Search } from "../../../assets/images/svg/magnifying-glass.svg";
import { Icon } from "../../Icon/Icon";

export const SearchInputContainer = styled(TextField)`
  background-color: ${selectedTheme.colors.primaryBackgroundColor};
  flex: 1;
  max-width: 520px;
  margin-left: 25px;
  font-family: ${selectedTheme.fonts.textFont};
  /* @media (max-width: 1700px) {
    margin-left: 15%;
  }
  @media (max-width: 1550px) {
    margin-left: 15%;
  }
  @media (max-width: 1320px) {
    margin-left: 7%;
  }
  @media (max-width: 1250px) {
    margin-left: 5%;
  } */
  @media (max-width: 550px) {
    display: block;
    width: 80%;
    max-width: 50%;
    height: 46px;
    margin-left: 1px;
    ${(props) => props.wider && `flex: 3;`}
    font-family: ${selectedTheme.fonts.textFont};
    & div {
      height: 46px;
      overflow: visible;
      & input {
        font-size: 14px !important;
      }
    }
  }
`;
export const EndIcon = styled(Icon)``;
export const SearchIcon = styled(Search)`
  position: relative;
  top: 11px;
  left: 4px;
  cursor: pointer;
  color: ${selectedTheme.colors.primaryPurple};
  & path {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 600px) {
    height: 14px;
    width: 14px;
    left: 11px;
  }
`;

import React, { useEffect, useState } from "react";
import { EyeIcon, PostsImgSuit } from "./MyPosts.styled";
import PropTypes from "prop-types";
import HeaderPopover from "../HeaderPopover/HeaderPopover";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectMineHeaderOffers, selectOffer } from "../../../store/selectors/offersSelectors";
import { fetchMineHeaderOffers } from "../../../store/actions/offers/offersActions";
import { selectProfileName } from "../../../store/selectors/profileSelectors";
import { useHistory } from "react-router-dom";
import { ITEM_DETAILS_PAGE, MY_OFFERS_PAGE } from "../../../constants/pages";
import { useMemo } from "react";
import { dynamicRouteMatches, replaceInRoute, routeMatches } from "../../../util/helpers/routeHelpers";

export const MyPosts = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mineOffers = useSelector(selectMineHeaderOffers);
  const name = useSelector(selectProfileName);
  const offer = useSelector(selectOffer);
  const history = useHistory();
  const [arrayOfMineOffers, setArrayOfMineOffers] = useState([]);
  useEffect(() => {
    dispatch(fetchMineHeaderOffers());
  }, []);
  useEffect(() => {
    if (mineOffers.length > 1) {
      setArrayOfMineOffers(
        [mineOffers[0], mineOffers[1]].map((item) => ({
          src: item.images[0],
          title: item.name,
          onClick: () => goToOffer(item._id),
          text: (
            <React.Fragment>
              <PostsImgSuit /> {name}
            </React.Fragment>
          ),
        }))
      );
    } else if (mineOffers.length > 0) {
      setArrayOfMineOffers(
        [mineOffers[0]].map((item) => ({
          alt: "Photo",
          src: item.images[0],
          title: item.name,
          onClick: () => goToOffer(item._id),
          text: (
            <React.Fragment>
              <PostsImgSuit /> {name}
            </React.Fragment>
          ),
        }))
      );
    } else {
      setArrayOfMineOffers([]);
    }
  }, [mineOffers]);

  const mineOffersToRender = useMemo(() => {
    return [...arrayOfMineOffers];
  }, [arrayOfMineOffers, mineOffers]);

  const goToOffer = (id) => {
    if (!dynamicRouteMatches(ITEM_DETAILS_PAGE) || offer?._id !== id) {
      history.push(
        replaceInRoute(ITEM_DETAILS_PAGE, {
          offerId: id,
        })
      );
    }
    props.closePopover();
  };
  const goToMySwaps = () => {
    if (!routeMatches(MY_OFFERS_PAGE)) {
      history.push(MY_OFFERS_PAGE);
    }
    props.closePopover();
  };
  return (
    <HeaderPopover
      title={t("header.myOffers")}
      items={mineOffersToRender}
      buttonText={t("header.checkEverything")}
      buttonOnClick={goToMySwaps}
      buttonIcon={<EyeIcon color={mineOffers.length === 0} />}
      noPosts={mineOffers.length === 0}
      addOffer={props.addOffer}
    />
  );
};

MyPosts.propTypes = {
  closePopover: PropTypes.func,
  addOffer: PropTypes.func,
};

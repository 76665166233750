import styled from "styled-components";
import { Card, Grid, Box } from "@mui/material";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const ProfileCardContainer = styled(Box)`
  width: ${(props) => (props.halfwidth ? `49%` : `100%`)};
  box-sizing: border-box;
  max-height: min-content;
  margin-top: 34px;
  overflow: hidden;
  border-radius: 4px 4px;
  cursor: pointer;
  &:nth-child(1) {
    margin-top: 20px;
  }
  &:nth-child(2) {
    margin-top: ${(props) => props.halfwidth && `20px;`};
  }
  @media (max-width: 1200px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    max-height: fit-content;
    margin-top: 18px;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
`;

export const ProfileCardWrapper = styled(Card)`
  background: ${(props) =>
    props.isMyProfile ? selectedTheme.colors.primaryPurple : "white"};
  width: 100%;
  min-width: fit-content;
  padding: 1rem;
  border-radius: 0 0 4px 4px;
  position: relative;
`;

export const CheckButton = styled(PrimaryButton)`
  width: 180px;
  height: 48px;
  position: absolute;
  bottom: 11px;
  right: 9px;
  display: ${(props) => (props.halfwidth ? `none` : `block`)};
  & button:hover {
    background-color: ${selectedTheme.colors.primaryPurple} !important;
    color: white !important;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

export const ProfileInfoContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: start;
  @media (max-width: 600px) {
    flex-direction: row;
    gap: 18px;
    /* justify-content: space-between; */
  }
`;

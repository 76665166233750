import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";
import selectedTheme from "../../../../themes";
const skeletonAnimation = keyframes`
    0% { 
        opacity: 1;
    }
    50% {
        opacity: 0.63;
    }
    100% {
        opacity: 1
    }
`;

export const SkeletonItemBackgroundColor = styled(Box)`
  background-color: ${selectedTheme.colors.filterSkeletonItems};
  animation: ${skeletonAnimation} 1.2s infinite;
`;
export const SkeletonBackgroundColor = styled(Box)`
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonAnimation} 1.2s infinite;
`;

export const SkeletonProfileCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 0 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-right: 18px !important;
  }
  @media (max-width: 1200px) {
    margin-left: 0;
    margin-right: 36px;
  }
  @media (max-width: 600px) {
    margin-right: 0 !important;
  }
`;
export const SkeletonProfileCardHeader = styled(SkeletonItemBackgroundColor)`
  width: 148px;
  height: 18px;

  @media (max-width: 600px) {
    width: 72px;
    height: 18px;
  }
`;
export const SkeletonProfileCardContent = styled(SkeletonBackgroundColor)`
  height: 347px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 39px;
  position: relative;

  @media (max-width: 600px) {
    gap: 18px;
  }
`;
export const SkeletonProfileCardUpperPart = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
  padding-bottom: 0;
`;
export const SkeletonProfileCardLeftPart = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 18px;
`;
export const SkeletonProfileCardBigCircle = styled(SkeletonItemBackgroundColor)`
  width: 144px;
  height: 144px;
  border-radius: 100%;

  @media (max-width: 600px) {
    width: 90px;
    height: 90px;
  }
`;
export const SkeletonProfileCardCircleLines = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 600px) {
    gap: 9px;
  }
`;
export const SkeletonProfileCardCircleLineOne = styled(
  SkeletonItemBackgroundColor
)`
  width: 90px;
  height: 27px;

  @media (max-width: 600px) {
    height: 20px;
  }
`;
export const SkeletonProfileCardCircleLineSecond = styled(
  SkeletonItemBackgroundColor
)`
  width: 117px;
  height: 18px;

  @media (max-width: 600px) {
    width: 139px;
    height: 14px;
  }
`;
export const SkeletonProfileCardSmallCircle = styled(
  SkeletonItemBackgroundColor
)`
  width: 40px;
  height: 40px;
  border-radius: 100%;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;
export const SkeletonProfileCardLowerPart = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 27px;
  padding-left: 18px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 18px;
  }
`;
export const SkeletonProfileCardLine = styled(SkeletonItemBackgroundColor)`
  width: 108px;
  height: 18px;

  @media (max-width: 600px) {
    width: 84px;
    height: 14px;
  }
`;
export const SkeletonProfileCardLineSecond = styled(
  SkeletonItemBackgroundColor
)`
  width: 108px;
  height: 18px;

  @media (max-width: 600px) {
    width: 116px;
    height: 14px;
  }
`;
export const SkeletonProfileCardLineThird = styled(SkeletonItemBackgroundColor)`
  width: 108px;
  height: 18px;

  @media (max-width: 600px) {
    width: 106px;
    height: 14px;
  }
`;
export const SkeletonProfileCardFooter = styled(SkeletonItemBackgroundColor)`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 88px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 36px;
  padding-left: 18px;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 127px;
    gap: 12px;
  }
`;
export const SkeletonProfileCardGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;
export const SkeletonProfileCardFooterLineOne = styled(SkeletonBackgroundColor)`
  height: 18px;
  width: 107px;

  @media (max-width: 600px) {
    margin-top: 18px;
    height: 14px;
    width: 49px;
  }
`;
export const SkeletonProfileCardFooterLineSecond = styled(
  SkeletonBackgroundColor
)`
  height: 18px;
  width: 144px;

  @media (max-width: 600px) {
    height: 14px;
    width: 134px;
  }
`;
export const SkeletonProfileCardFooterLineThird = styled(
  SkeletonBackgroundColor
)`
  height: 18px;
  width: 197px;

  @media (max-width: 600px) {
    height: 14px;
    width: 141px;
  }
`;
export const SkeletonProfileCardFooterLineForth = styled(
  SkeletonBackgroundColor
)`
  height: 18px;
  width: 144px;

  @media (max-width: 600px) {
    height: 14px;
    width: 160px;
  }
`;

import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
//import { IconButton } from "../../Buttons/IconButton/IconButton";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { Icon } from "../../Icon/Icon";
import { ReactComponent as Category } from "../../../assets/images/svg/category.svg";
import { ReactComponent as Subcategory } from "../../../assets/images/svg/subcategory.svg";
import { ReactComponent as Quantity } from "../../../assets/images/svg/quantity.svg";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-striked.svg";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { ReactComponent as Edit } from "../../../assets/images/svg/edit.svg";
import { ReactComponent as Pin } from "../../../assets/images/svg/pin-outlined.svg";
import { ReactComponent as Unpin } from "../../../assets/images/svg/unpin-outlined.svg";
import { ReactComponent as Remove } from "../../../assets/images/svg/trash.svg";
import { ReactComponent as Location } from "../../../assets/images/svg/location.svg";
import { ReactComponent as Date } from "../../../assets/images/svg/calendar.svg";

export const ItemDetailsCardContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  /* width: ${(props) => (!props.halfwidth ? "100%" : "49%")}; */
  box-sizing: border-box;
  margin: ${(props) => (props.singleOffer ? "10px 36px" : "10px 0")};
  border: 1px solid ${selectedTheme.colors.borderNormal};
  background-color: ${(props) =>
    props.sponsored === "true"
      ? selectedTheme.colors.backgroundSponsoredColor
      : "white"};
  border-radius: 4px;
  padding: 18px;
  max-width: 2000px;
  position: relative;
  width: auto;
  /* ${(props) => !props.previewCard && `height: 654px;`} */
  /* height: 654px; */
  /* padding-bottom: 70px; */

  /* @media (max-width: 600px) {
    margin: 18px 0;
    padding: 10px;
    padding-bottom: 65px;
  } */

  @media screen and (max-width: 1200px) {
    margin: 10px 0;
    /* ${(props) =>
      props.previewCard === true && `width: 90% !important; margin: auto;`} */
  }

  @media (min-width: 601px) and (max-width: 628px) {
    margin-left: 10px;
  }

  @media (max-width: 600px) {
    width: auto !important;
    margin-top: 22px;
  }

  @media (max-height: 708px) {
    /* height: 400px; */
  }
`;
export const OfferInfo = styled(Box)`
  display: flex;
  ${(props) => props.previewCard && `flex: 2;`}
  /* flex: 2; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 18px 0;
  @media (max-width: 600px) {
    margin: 0;
    flex: 0;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 18px;

  /* @media screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
  } */
`;

export const EditDeleteButtons = styled(Box)`
  display: flex;

  /* @media screen and (max-width: 600px) {
    position: absolute;
  } */
`;
export const EditIconContainer = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: relative;
  top: 4px;
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;
  /* margin-left: 18px; */

  @media screen and (max-width: 600px) {
    width: 32px;
    top: 0;
    height: 32px;
  }
`;
export const EditIcon = styled(Edit)`
  @media screen and (max-width: 600px) {
    width: 16px;
    height: 16px;
    position: relative;
    top: -4px;
    left: -2px;
  }
`;

export const RemoveIconContainer = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: relative;
  top: 4px;
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;
  /* margin-left: 18px; */

  @media screen and (max-width: 600px) {
    width: 32px;
    top: 0;
    height: 32px;
  }
`;
export const RemoveIcon = styled(Remove)`
  @media screen and (max-width: 600px) {
    width: 16px;
    height: 16px;
    position: relative;
    top: -4px;
    left: -2px;
  }
`;
export const PinIconContainer = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: relative;
  top: 4px;
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;
  /* margin-left: 18px; */

  @media screen and (max-width: 600px) {
    width: 32px;
    top: 0;
    height: 32px;
  }
`;

export const PinIcon = styled(Pin)`
  @media screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
    position: relative;
    top: -4px;
    left: -2px;
  }
`;
export const UnpinIcon = styled(Unpin)`
  @media screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
    position: relative;
    top: -4px;
    left: -2px;
  }
`;

export const DateButtonsContainer = styled(Box)`
  display: flex;

  @media screen and (max-width: 600px) {
    position: absolute;
    bottom: initial;
    top: 58px;
    right: 12px;
  }
`;

export const PostDate = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryText};
  @media (max-width: 600px) {
    font-size: 9px;
    font-family: ${selectedTheme.fonts.textFont};
  }

  @media (max-width: 600px) {
    position: absolute;
    bottom: 23px;
    left: 18px;
    width: 70px;
    flex-direction: column;
    align-items: start;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    ${(props) => !props.previewCard && `display: none;`}
  }
  @media (max-width: 600px) {
    ${(props) => props.previewCard && `display: none;`}
  }
`;
export const Info = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  @media (max-width: 600px) {
    margin-bottom: 15px;
    max-width: 92%;
  }
`;
export const OfferAuthor = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const OfferAuthorName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const OfferLocation = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
`;

export const OfferCategory = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 33%;
`;
export const OfferPackage = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 34%;
`;
export const OfferViews = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  width: 34%;
`;
export const OfferDescriptionTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 16px;
  @media (max-width: 600px) {
    font-size: 9px;
    line-height: 13px;
  }
`;
export const OfferDescriptionText = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 22px;
  padding-bottom: 20px;
  max-width: ${(props) =>
    props.showBarterButton ? "calc(100% - 230px)" : "100%"};
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 100%;
    max-height: 100px;
  }
  /* max-width: calc(100% - 230px); */
  /* overflow: hidden; */
  /* display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical; */
`;
export const OfferDescription = styled(Box)`
  flex: 3;
`;
export const Line = styled(Box)`
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 100px;
  width: 0;
  margin: auto 0;
`;
export const DetailIcon = styled(Icon)`
  & svg {
    width: 14px;
    position: relative;
    top: -1px;
  }
`;
export const DetailText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 3px;
`;
export const CheckButton = styled(PrimaryButton)`
  width: 180px;
  height: 48px;

  font-weight: 600;
  &:hover button {
    color: white !important;
    background-color: ${(props) =>
      !props.disabled
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.primaryPurpleDisabled} !important;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  /* @media (max-width: 600px) {
    width: 94%;
    height: 44px;
    position: absolute;
    bottom: 9px;
    right: 9px;
  } */
`;
// export const OfferImage = styled.img`
// `

export const PublishButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
export const CategoryIcon = styled(Category)`
  width: 14px;
`;
export const SubcategoryIcon = styled(Subcategory)`
  width: 14px;
`;
export const QuantityIcon = styled(Quantity)`
  width: 22px;
  height: 16px;
`;

export const EyeIcon = styled(Eye)`
  width: 18px;
  height: 20px;
`;
export const ButtonContainer = styled(Box)`
  position: absolute;
  bottom: 18px;
  right: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 18px;
  @media (max-width: 600px) {
    position: static;
  }
`;

export const LocationIcon = styled(Location)`
  width: 18px;
`;

export const DateIcon = styled(Date)``;

export const InfoContentLeft = styled(Box)`
  display: flex;
  gap: 18px;
`;

export const InfoContentRight = styled(Box)``;

export const ChipContainer = styled(Box)`
  margin-top: 6px;
`;

import React from "react";
import { ArrowContainer, ArrowIcon } from "./ArrowButton.styled";
import PropTypes from "prop-types";

export const ArrowButton = (props) => {
  return (
    <ArrowContainer
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    >
      <ArrowIcon side={props.side} disabled={props.disabled} />
    </ArrowContainer>
  );
};

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  side: PropTypes.string,
  disabled: PropTypes.bool,
};

import styled from "styled-components";
import { ReactComponent as Package } from "../../../../../assets/images/svg/package.svg";
import selectedTheme from "../../../../../themes";

export const PackageIcon = styled(Package)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;

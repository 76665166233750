import {
  PAYMENTS_ADD,
  PAYMENTS_ADD_ERROR,
  PAYMENTS_ADD_SUCCESS,
  PAYMENTS_DELETE,
  PAYMENTS_DELETE_ERROR,
  PAYMENTS_DELETE_SUCCESS,
  PAYMENTS_EDIT,
  PAYMENTS_EDIT_ERROR,
  PAYMENTS_EDIT_SUCCESS,
  PAYMENTS_FETCH,
  PAYMENTS_FETCH_ERROR,
  PAYMENTS_FETCH_SUCCESS,
  PAYMENTS_SET,
} from "./paymentActionConstants";

export const fetchPayments = () => ({
  type: PAYMENTS_FETCH,
});

export const setPayments = (payload) => ({
  type: PAYMENTS_SET,
  payload,
});
export const fetchPaymentsSuccess = () => ({
  type: PAYMENTS_FETCH_SUCCESS,
});
export const fetchPaymentsError = () => ({
  type: PAYMENTS_FETCH_ERROR,
});
export const addPayment = (payload) => ({
  type: PAYMENTS_ADD,
  payload,
});
export const addPaymentSuccess = () => ({
  type: PAYMENTS_ADD_SUCCESS,
});
export const addPaymentError = () => ({
  type: PAYMENTS_ADD_ERROR,
});
export const editPayment = (payload) => ({
  type: PAYMENTS_EDIT,
  payload,
});
export const editPaymentSuccess = () => ({
  type: PAYMENTS_EDIT_SUCCESS,
});
export const editPaymentError = () => ({
  type: PAYMENTS_EDIT_ERROR,
});
export const deletePayment = (payload) => ({
  type: PAYMENTS_DELETE,
  payload,
});
export const deletePaymentSuccess = () => ({
  type: PAYMENTS_DELETE_SUCCESS,
});
export const deletePaymentError = () => ({
  type: PAYMENTS_DELETE_ERROR,
});

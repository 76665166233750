import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateOfferContainer,
  CreateOfferTitle,
  ModalCreateOfferContainer,
  ModalHeader,
} from "./CreateOffer.styled";
import StepProgress from "../../StepProgress/StepProgress";
import FirstPartCreateOffer from "./FirstPart/FirstPartCreateOffer";
import SecondPartCreateOffer from "./SecondPart/SecondPartCreateOffer";
import ThirdPartCreateOffer from "./ThirdPart/ThirdPartCreateOffer";
import {
  addOffer,
  fetchOffers,
  fetchOneOffer,
  fetchProfileOffers,
} from "../../../store/actions/offers/offersActions";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { editOneOffer } from "../../../store/actions/offers/offersActions";
import { useTranslation } from "react-i18next";
import BackdropComponent from "../../MUI/BackdropComponent";
import CloseButton from "./CloseButton/CloseButton";
import BackButton from "./BackButton/BackButton";
import selectedTheme from "../../../themes";
import { useMemo } from "react";
// import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  ADMIN_ITEM_DETAILS_PAGE,
  ADMIN_SINGLE_USER_PAGE,
  BASE_PAGE,
  HOME_PAGE,
  ITEM_DETAILS_PAGE,
  PROFILE_PAGE,
} from "../../../constants/pages";
import {
  dynamicRouteMatches,
  replaceInRoute,
} from "../../../util/helpers/routeHelpers";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import {
  OFFER_ADD_SCOPE,
  OFFER_EDIT_SCOPE,
} from "../../../store/actions/offers/offersActionConstants";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { selectQueryString } from "../../../store/selectors/queryStringSelectors";
import { routeMatches } from "../../../util/helpers/routeHelpers";
import { fetchProfile } from "../../../store/actions/profile/profileActions";

const CreateOffer = ({ editOffer, offer, isAdmin, customUserId }) => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const history = useHistory();
  const queryString = useSelector(selectQueryString);
  const [informations, setInformations] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();
  const userId = useSelector(selectUserId);
  const isLoading = useSelector(
    selectIsLoadingByActionType(editOffer ? OFFER_EDIT_SCOPE : OFFER_ADD_SCOPE)
  );

  const closeCreateOfferModal = () => dispatch(closeModal());
  console.log("dynamicRoute", dynamicRouteMatches(PROFILE_PAGE));

  const handleApiResponseSuccess = () => {
    if (routeMatches(BASE_PAGE) || routeMatches(HOME_PAGE))
      dispatch(fetchOffers({ queryString }));
    else if (
      dynamicRouteMatches(PROFILE_PAGE) ||
      dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE)
    ) {
      if (isAdmin) {
        dispatch(fetchProfileOffers({ idProfile: customUserId, isAdmin }));
      } else {
        dispatch(fetchProfileOffers(userId));
        dispatch(fetchProfile(userId));
      }
      console.log("tu je gde treba");
    } else if (
      dynamicRouteMatches(ITEM_DETAILS_PAGE) ||
      dynamicRouteMatches(ADMIN_ITEM_DETAILS_PAGE)
    )
      dispatch(fetchOneOffer(offer._id));
    else
      history.push(
        replaceInRoute(PROFILE_PAGE, {
          profileId: userId,
        })
      );
    closeCreateOfferModal();
  };

  // Go to next step and save typed values
  const handleNext = (values) => {
    setInformations({ ...informations, ...values });
    setCurrentStep((prevState) => prevState + 1);
  };

  // Get new images from 2nd step
  const newImgs = useMemo(
    () =>
      informations.images &&
      informations.images.filter((img) => img !== undefined),
    [informations.images]
  );

  // Make offer data object with typed values
  const offerData = useMemo(
    () => ({
      name: informations.nameOfProduct,
      description: informations.description,
      location: {
        city: informations.location,
      },
      condition: informations.condition,
      category: {
        name: informations.category,
      },
      subcategory: informations.subcategory,
      images: newImgs,
    }),
    [informations, newImgs]
  );

  // Create (or edit) offer
  const handleSubmitOffer = () => {
    if (editOffer) {
      dispatch(
        editOneOffer({
          offerId: offer._id,
          offerData,
          isAdmin,
          handleApiResponseSuccess,
        })
      );
    } else {
      dispatch(addOffer({ offerData, handleApiResponseSuccess }));
    }
  };

  const goStepBack = (stepNumber) => {
    setCurrentStep(stepNumber);
    // Here goes any additional logic
  };

  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeCreateOfferModal}
        position="fixed"
      />
      <ModalCreateOfferContainer currentstep={currentStep}>
        <CreateOfferContainer currentstep={currentStep}>
          {/* Modal header */}
          <ModalHeader>
            <BackButton
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <CreateOfferTitle component="h1" variant="h5">
              {currentStep === 3
                ? `${t("offer.review")}`
                : `${
                    editOffer
                      ? `${t("offer.changeOffer")}`
                      : `${t("offer.newOffer")}`
                  }`}
            </CreateOfferTitle>
            <CloseButton closeCreateOfferModal={closeCreateOfferModal} />
          </ModalHeader>
          {/* ^^^^^^^^ */}

          <StepProgress
            lineColor={selectedTheme.colors.stepProgressAltColor}
            current={currentStep}
            numberOfSteps={3}
            functions={[() => goStepBack(1), () => goStepBack(2)]}
          />

          {currentStep === 1 && (
            <FirstPartCreateOffer
              handleNext={handleNext}
              offer={offer}
              editOffer={editOffer}
              informations={informations}
            />
          )}

          {currentStep === 2 && (
            <SecondPartCreateOffer
              handleNext={handleNext}
              offer={offer}
              informations={informations}
            />
          )}

          {currentStep === 3 && (
            <ThirdPartCreateOffer
              handleSubmitOffer={handleSubmitOffer}
              offer={offer}
              informations={informations}
              isLoading={isLoading}
            />
          )}
        </CreateOfferContainer>
      </ModalCreateOfferContainer>
    </>
  );
};

CreateOffer.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  closeCreateOfferModal: PropTypes.func,
  editOffer: PropTypes.bool,
  offer: PropTypes.object,
  isAdmin: PropTypes.bool,
  customUserId: PropTypes.string,
};
export default CreateOffer;

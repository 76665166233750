import { all } from "redux-saga/effects";
import adminSaga from "./adminSaga";
import categoriesSaga from "./categoriesSaga";
import chatSaga from "./chatSaga";
import counterSaga from "./counterSaga";
import exchangeSaga from "./exchangeSaga";
import forgotPasswordSaga from "./forgotPasswordSaga";
import locationsSaga from "./locationsSaga";
import loginSaga from "./loginSaga";
import offersSaga from "./offersSaga";
import profileSaga from "./profileSaga";
import queryStringSaga from "./queryStringSaga";
import registerSaga from "./registerSaga";
import reviewSaga from "./reviewSaga";
import paymentSaga from "./paymentSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    registerSaga(),
    forgotPasswordSaga(),
    offersSaga(),
    categoriesSaga(),
    locationsSaga(),
    profileSaga(),
    chatSaga(),
    queryStringSaga(),
    exchangeSaga(),
    reviewSaga(),
    counterSaga(),
    adminSaga(),
    paymentSaga(),
  ]);
}

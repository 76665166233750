import React from "react";
import PropTypes from "prop-types";
import {
  ButtonContainer,
  DirectChatHeaderTitleContainer,
  HeaderText,
  HeaderTitleContent,
  MessageIcon,
} from "./DirectChatHeaderTitle.styled";
import { useTranslation } from "react-i18next";
import { ArrowButton } from "../../Buttons/ArrowButton/ArrowButton";
import useIsMobile from "../../../hooks/useIsMobile";
import history from "../../../store/utils/history";

const DirectChatHeaderTitle = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const goBackToMessages = () => {
    history.goBack();
  };
  return (
    <DirectChatHeaderTitleContainer>
      {isMobile && (
        <ButtonContainer onClick={goBackToMessages}>
          <ArrowButton side={"left"}></ArrowButton>
          <HeaderText>{t("messages.goBack")}</HeaderText>
        </ButtonContainer>
      )}
      <MessageIcon />
      <HeaderTitleContent>{t("messages.headerTitle")}</HeaderTitleContent>
    </DirectChatHeaderTitleContainer>
  );
};

DirectChatHeaderTitle.propTypes = {
  children: PropTypes.node,
};

export default DirectChatHeaderTitle;

import { all, takeLatest, call, put } from "@redux-saga/core/effects";
import i18n from "../../i18n";
import { forgotPasswordRequest, resetPasswordRequest } from "../../request/forgotPasswordRequest";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "../actions/user/userActionConstants";
import { forgotPasswordError, forgotPasswordSuccess } from "../actions/user/userActions";
import { makeToastMessage } from "../utils/makeToastMessage";

function* forgotPassword({payload}) {
    try {
        const data = yield call(forgotPasswordRequest, payload.email);
        if (data) {
            yield put(forgotPasswordSuccess());
            if (payload.handleResponseSuccess) {
                yield call(payload.handleResponseSuccess);
            }
        }
    }
    catch(e) {
        console.dir(e);
        if (payload.handleResponseError) {
            yield call(payload.handleResponseError);
        }
        yield put(forgotPasswordError());
    }
}
function* resetPassword({payload}) {
    try {
        const data = yield call(resetPasswordRequest, {
            password: payload.password,
            password2: payload.password2,
            token: payload.token
        })
        if (data) {
            if (payload.handleResponseSuccess) {
                yield call(payload.handleResponseSuccess);
            }
            makeToastMessage(i18n.t("resetPassword.resetSuccess"))
        }
    }
    catch(e) {
        if (payload.handleResponseError) {
            yield call(payload.handleResponseError);
        }
    }
}

export default function* forgotPasswordSaga() {
    yield all([
        takeLatest(FORGOT_PASSWORD, forgotPassword),
        takeLatest(RESET_PASSWORD, resetPassword)
    ])
}
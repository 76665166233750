import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  CloseButton,
  CloseIcon,
  DrawerContainer,
  FooterButtons,
  HeaderTitle,
  Separator,
  ToolsContainer,
} from "./Drawer.styled";
import { useTranslation } from "react-i18next";
import LogoutButton from "./Buttons/LogoutButton/LogoutButton";
import MyProfileButton from "./Buttons/MyProfileButton/MyProfileButton";
import MyMessagesButton from "./Buttons/MyMessagesButton/MyMessagesButton";
import MyPostsButton from "./Buttons/MyPostsButton/MyPostsButton";
import LoginButton from "./Buttons/LoginButton/LoginButton";
import RegisterButton from "./Buttons/RegisterButton/RegisterButton";
import AddOfferButton from "./Buttons/AddOfferButton/AddOfferButton";
import PricesButton from "./Buttons/PricesButton/PricesButton";
import AboutButton from "./Buttons/AboutButton/AboutButton";
import PrivacyPolicyButton from "./Buttons/PrivacyPolicyButton/PrivacyPolicyButton";
import { useSelector } from "react-redux";
import {
  selectRoles,
  selectUserId,
} from "../../../store/selectors/loginSelectors";
import { isAdminRoute } from "../../../util/helpers/routeHelpers";
import { useLocation } from "react-router-dom";
import AdminButtons from "./Buttons/AdminButtons/AdminButtons";
import AdminInfo from "./AdminInfo/AdminInfo";
import { selectMineProfile } from "../../../store/selectors/profileSelectors";
import AdminPanelButton from "./Buttons/AdminPanelButton/AdminPanelButton";

export const Drawer = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserId);
  const mineProfile = useSelector(selectMineProfile);
  const location = useLocation();
  const isAdmin = useMemo(() => isAdminRoute(), [location.pathname]);
  const role = useSelector(selectRoles);
  const isUserAdmin = role?.includes("Admin");

  return (
    <DrawerContainer>
      <CloseButton onClick={props.toggleDrawer}>
        <CloseIcon />
      </CloseButton>
      <HeaderTitle>{t("header.navMenu")}</HeaderTitle>
      <React.Fragment>
        <ToolsContainer mobile isAdmin={isAdmin}>
          {user ? (
            isAdmin ? (
              <AdminButtons toggleDrawer={props.toggleDrawer} />
            ) : (
              <>
                <MyPostsButton toggleDrawer={props.toggleDrawer} />
                <MyMessagesButton toggleDrawer={props.toggleDrawer} />
                <MyProfileButton toggleDrawer={props.toggleDrawer} />
                {isUserAdmin && (
                  <AdminPanelButton toggleDrawer={props.toggleDrawer} />
                )}
                <Separator />
                <PricesButton toggleDrawer={props.toggleDrawer} />
                <AboutButton toggleDrawer={props.toggleDrawer} />
                <PrivacyPolicyButton toggleDrawer={props.toggleDrawer} />
                <Separator />
              </>
            )
          ) : (
            <>
              <LoginButton toggleDrawer={props.toggleDrawer} />
              <RegisterButton toggleDrawer={props.toggleDrawer} />
            </>
          )}
        </ToolsContainer>
        {user && (
          <FooterButtons isAdmin={isAdmin}>
            {isAdmin ? (
              <AdminInfo name={mineProfile?.company?.name} />
            ) : (
              <AddOfferButton toggleDrawer={props.toggleDrawer} />
            )}
            <LogoutButton toggleDrawer={props.toggleDrawer} />
          </FooterButtons>
        )}
      </React.Fragment>
    </DrawerContainer>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
};

import styled from "styled-components";
import { ReactComponent as Company } from "../../../../../assets/images/svg/user-gray.svg";

export const CompanyIcon = styled(Company)`
  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 2px;
  }
`;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { CreateOfferFormContainer } from "./FirstPartCreateOffer.styled";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import OfferTitleField from "./OfferTitleField/OfferTitleField";
import OfferDescriptionField from "./OfferDescriptionField/OfferDescriptionField";
import OfferLocationField from "./OfferLocationField/OfferLocationField";
import OfferCategoryField from "./OfferCategoryField/OfferCategoryField";
import OfferSubcategoryField from "./OfferSubcategoryField/OfferSubcategoryField";
import NextButton from "./NextButton/NextButton";
import firstPartCreateOfferInitialValues from "../../../../initialValues/createOfferInitialValues/firstPartCreateOfferInitialValues";
import firstPartCreateOfferValidation from "../../../../validations/createOfferValidation/firstPartCreateOfferValidation";
import ErrorMessage from "./ErrorMessage/ErrorMessage";

const FirstPartCreateOffer = (props) => {
  const [subcategories, setSubcategories] = useState([]);
  const locations = useSelector((state) => state.locations.locations);
  const categories = useSelector((state) => state.categories.categories);

  // Change subcategory select options on category change
  const handleSubcategories = (category) => {
    setSubcategories(
      categories
        .find((cat) => cat.name === category)
        ?.subcategories?.map((subcategory) => subcategory.name)
    );
  };

  // Get initial values
  const initialValues = useMemo(() => {
    const newInitialValues = firstPartCreateOfferInitialValues(
      props.offer,
      props.informations
    );
    if (categories) {
      handleSubcategories(newInitialValues.category);
    }
    return newInitialValues;
  }, [props.offer, props.informations, categories]);

  // Get validation schema
  const validationSchema = useMemo(() => {
    return firstPartCreateOfferValidation(locations, categories, subcategories);
  }, [subcategories]);

  const handleSubmit = (values) => {
    props.handleNext(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    // validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <CreateOfferFormContainer component="form" onSubmit={formik.handleSubmit}>
      <OfferTitleField formik={formik} />
      <OfferDescriptionField formik={formik} />
      <OfferLocationField formik={formik} locations={locations} />
      <OfferCategoryField
        formik={formik}
        categories={categories}
        handleSubcategories={handleSubcategories}
      />
      <OfferSubcategoryField formik={formik} subcategories={subcategories} />
      <ErrorMessage formik={formik} />
      <NextButton formik={formik} />
    </CreateOfferFormContainer>
  );
};

FirstPartCreateOffer.propTypes = {
  children: PropTypes.any,
  handleNext: PropTypes.func,
  offer: PropTypes.node,
  editOffer: PropTypes.bool,
  informations: PropTypes.any,
};

export default FirstPartCreateOffer;

import {
  OFFERS_ADD,
  OFFERS_CLEAR,
  OFFERS_ERROR,
  OFFERS_FEATURED_CLEAR,
  OFFERS_FEATURED_ERROR,
  OFFERS_FEATURED_FETCH,
  OFFERS_FEATURED_SET,
  OFFERS_FEATURED_SUCCESS,
  OFFERS_FETCH,
  OFFERS_FETCH_MORE,
  OFFERS_FETCH_MORE_ERROR,
  OFFERS_FETCH_MORE_SUCCESS,
  OFFERS_HEADER_SET,
  OFFERS_MINE_FETCH,
  OFFERS_MINE_FETCH_ERROR,
  OFFERS_MINE_FETCH_SUCCESS,
  OFFERS_MINE_HEADER_FETCH,
  OFFERS_MINE_HEADER_FETCH_ERROR,
  OFFERS_MINE_HEADER_FETCH_SUCCESS,
  OFFERS_MINE_SET,
  OFFERS_NO_MORE,
  OFFERS_PINNED_ADD,
  OFFERS_PINNED_SET,
  OFFERS_PROFILE_ADD,
  OFFERS_PROFILE_ERROR,
  OFFERS_PROFILE_FETCH,
  OFFERS_PROFILE_SET,
  OFFERS_PROFILE_SUCCESS,
  OFFERS_SET,
  OFFERS_SET_TOTAL,
  OFFERS_SUCCESS,
  OFFER_ADD,
  OFFER_ADD_ERROR,
  OFFER_ADD_SUCCESS,
  OFFER_EDIT,
  OFFER_EDIT_ERROR,
  OFFER_EDIT_SUCCESS,
  OFFER_FEATURED_PAGE_SET,
  OFFER_PAGE_SET,
  OFFER_PIN,
  OFFER_PIN_ERROR,
  OFFER_PIN_SUCCESS,
  OFFER_REMOVE,
  OFFER_REMOVE_ERROR,
  OFFER_REMOVE_SUCCESS,
  OFFER_SELECTED_CLEAR,
  OFFER_SET,
  ONE_OFFER_ERROR,
  ONE_OFFER_FETCH,
  ONE_OFFER_SUCCESS,
} from "./offersActionConstants";

// Fetch offers
export const fetchOffers = (payload) => ({
  type: OFFERS_FETCH,
  payload,
});
export const fetchOffersSuccess = (payload) => ({
  type: OFFERS_SUCCESS,
  payload,
});
export const fetchOffersError = (payload) => ({
  type: OFFERS_ERROR,
  payload,
});
export const clearOffers = () => ({
  type: OFFERS_CLEAR,
});

// Fetch offers
export const fetchAllOffers = (payload) => ({
  type: OFFERS_FETCH,
  payload,
});
export const fetchAllOffersSuccess = (payload) => ({
  type: OFFERS_SUCCESS,
  payload,
});
export const fetchAllOffersError = (payload) => ({
  type: OFFERS_ERROR,
  payload,
});

// Fetch featured offers
export const fetchFeaturedOffers = (payload) => ({
  type: OFFERS_FEATURED_FETCH,
  payload,
});
export const fetchFeaturedOffersSuccess = (payload) => ({
  type: OFFERS_FEATURED_SUCCESS,
  payload,
});
export const fetchFeaturedOffersError = (payload) => ({
  type: OFFERS_FEATURED_ERROR,
  payload,
});
export const clearFeaturedOffers = () => ({
  type: OFFERS_FEATURED_CLEAR,
});

// Fetch more offers
export const fetchMoreOffers = (payload) => ({
  type: OFFERS_FETCH_MORE,
  payload,
});
export const fetchMoreOffersSuccess = () => ({
  type: OFFERS_FETCH_MORE_SUCCESS,
});
export const fetchMoreOffersError = () => ({
  type: OFFERS_FETCH_MORE_ERROR,
});

// Fetch mine offers
export const fetchMineOffers = () => ({
  type: OFFERS_MINE_FETCH,
});
export const fetchMineOffersSuccess = () => ({
  type: OFFERS_MINE_FETCH_SUCCESS,
});
export const fetchMineOffersError = () => ({
  type: OFFERS_MINE_FETCH_ERROR,
});

// Fetch header offers
export const fetchMineHeaderOffers = () => ({
  type: OFFERS_MINE_HEADER_FETCH,
});
export const fetchMineHeaderOffersSuccess = () => ({
  type: OFFERS_MINE_HEADER_FETCH_SUCCESS,
});
export const fetchMineHeaderOffersError = () => ({
  type: OFFERS_MINE_HEADER_FETCH_ERROR,
});

// Fetch profile offers
export const fetchProfileOffers = (payload) => ({
  type: OFFERS_PROFILE_FETCH,
  payload,
});
export const fetchProfileOffersSuccess = () => ({
  type: OFFERS_PROFILE_SUCCESS,
});
export const fetchProfileOffersError = () => ({
  type: OFFERS_PROFILE_ERROR,
});

// One offer
export const fetchOneOffer = (payload) => ({
  type: ONE_OFFER_FETCH,
  payload,
});
export const fetchOneOfferError = (payload) => ({
  type: ONE_OFFER_ERROR,
  payload,
});
export const fetchOneOfferSuccess = (payload) => ({
  type: ONE_OFFER_SUCCESS,
  payload,
});

// Add offer
export const addOffer = (payload) => ({
  type: OFFER_ADD,
  payload,
});
export const addOfferSuccess = () => ({
  type: OFFER_ADD_SUCCESS,
});
export const addOfferError = () => ({
  type: OFFER_ADD_ERROR,
});

// Remove offer
export const removeOffer = (payload) => ({
  type: OFFER_REMOVE,
  payload,
});
export const removeOfferSuccess = () => ({
  type: OFFER_REMOVE_SUCCESS,
});
export const removeOfferError = () => ({
  type: OFFER_REMOVE_ERROR,
});

// Edit offer
export const editOneOffer = (payload) => ({
  type: OFFER_EDIT,
  payload,
});
export const editOfferSuccess = () => ({
  type: OFFER_EDIT_SUCCESS,
});
export const editOfferError = () => ({
  type: OFFER_EDIT_ERROR,
});

// Pin/unpin offer
export const pinOffer = (payload) => ({
  type: OFFER_PIN,
  payload,
});
export const pinOfferSuccess = () => ({
  type: OFFER_PIN_SUCCESS,
});
export const pinOfferError = () => ({
  type: OFFER_PIN_ERROR,
});

export const setOffers = (payload) => ({
  type: OFFERS_SET,
  payload,
});
export const setPinnedOffers = (payload) => ({
  type: OFFERS_PINNED_SET,
  payload,
});
export const addPinnedOffers = (payload) => ({
  type: OFFERS_PINNED_ADD,
  payload,
});
export const addOffers = (payload) => ({
  type: OFFERS_ADD,
  payload,
});

export const setNoMoreOffersStatus = (payload) => ({
  type: OFFERS_NO_MORE,
  payload,
});
export const setTotalOffers = (payload) => ({
  type: OFFERS_SET_TOTAL,
  payload,
});
export const setMineHeaderOffers = (payload) => ({
  type: OFFERS_HEADER_SET,
  payload,
});
export const setMineOffers = (payload) => ({
  type: OFFERS_MINE_SET,
  payload,
});
export const setProfileOffers = (payload) => ({
  type: OFFERS_PROFILE_SET,
  payload,
});
export const addProfileOffers = (payload) => ({
  type: OFFERS_PROFILE_ADD,
  payload,
});
export const setOffer = (payload) => ({
  type: OFFER_SET,
  payload,
});
export const setFeaturedOffers = (payload) => ({
  type: OFFERS_FEATURED_SET,
  payload,
});
export const setOfferPage = (payload) => ({
  type: OFFER_PAGE_SET,
  payload,
});
export const setFeaturedOfferPage = (payload) => ({
  type: OFFER_FEATURED_PAGE_SET,
  payload,
});
export const clearSelectedOffer = () => ({
  type: OFFER_SELECTED_CLEAR,
});

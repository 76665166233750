import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import CheckOffersButton from "../CheckOffersButton/CheckOffersButton";

export const AboutSectionContainer = styled(Box)`
  ${(props) =>
    props.reverse
      ? `
    margin-right: 72px
  `
      : `margin-left: 72px`};
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  gap: 144px;
  margin-bottom: 72px;
  ${(props) => props.reverse && `text-align: right;`}

  @media (max-width: 1430px) {
    ${(props) =>
      props.reverse
        ? `
    margin-right: 45px
  `
        : `margin-left: 45px`};
  }

  @media (max-width: 1069px) {
    flex-direction: column;
    margin-bottom: 41px;
  }

  @media (max-width: 1200px) {
    ${(props) =>
      props.reverse
        ? `
    margin-right: 36px
  `
        : `margin-left: 36px`};
  }

  @media (max-width: 1319px) {
    gap: 40px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 36px;
    gap: 27px;
    ${(props) => props.reverse && `text-align: right;`}
  }
`;
export const AboutSectionTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-bottom: 18px;

  @media (max-width: 1069px) {
    ${(props) => props.reverse && `margin-left: 36px;`}
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const AboutSectionText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${selectedTheme.colors.primaryGrayText};

  @media (min-width: 1069px) and (max-width: 1230px) {
    ${(props) => props.reverse && `padding-bottom: 50px;`}
  }

  @media (max-width: 1069px) {
    ${(props) => props.reverse && `margin-left: 36px;`}
  }

  @media (max-width: 600px) {
    font-size: 14px;
    ${(props) => props.reverse && `padding-bottom: 0;`}
  }
`;
export const AboutSectionImage = styled.img`
  display: flex;
  flex: 1;
  width: 648px;
  height: 345px;
  object-fit: cover;

  @media (max-width: 1069px) {
    width: 80%;
    ${(props) => !props.reverse && `align-self: end;`}
  }

  @media (max-width: 600px) {
    width: 100%;
    ${(props) =>
      !props.reverse ? `padding-left: 36px` : `padding-right: 36px`}
  }
`;
export const AboutSectionTextContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 600px) {
    margin: 0 36px;
  }
`;
export const CheckButton = styled(CheckOffersButton)``;

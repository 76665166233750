import React, { useState } from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../SecondPartCreateOffer.styled";
import {
  SelectAltText,
  SelectField,
  SelectText,
} from "../../CreateOffer.styled";
import { SelectOption } from "../../FirstPart/FirstPartCreateOffer.styled";
import { typeSelectEnum } from "../../../../../enums/typeEnum";
import { useTranslation } from "react-i18next";
import { InputButtonContainer } from "./OfferTypeField.styled";

const OfferTypeField = (props) => {
  const [typeValue, setTypeValue] = useState("");
  const { t } = useTranslation();
  const formik = props.formik;
  const typeHandle = (value) => {
    console.log(value);
    if (value === "Usluga") {
      formik.setFieldValue("condition", value);
    }
    setTypeValue(value);
  };
  props.onTypeHandler(typeValue);
  console.log(typeValue);
  // useEffect(() => {
  //   if (typeValue === "Usluga") {
  //     formik.setFieldValue("condition", typeValue);
  //   }
  // }, [typeValue]);
  return (
    <InputButtonContainer>
      <FieldLabel leftText={t("offer.type")} />
      <SelectField
        onChange={(event) => {
          formik.setFieldValue("type", event.target.value);
          typeHandle(event.target.value);
        }}
        value={formik.values.type}
      >
        <SelectOption style={{ display: "none" }} value="default">
          {t("offer.choseType")}
        </SelectOption>
        {Object.keys(typeSelectEnum).map((key) => {
          var item = typeSelectEnum[key];
          return (
            <SelectOption value={item.mainText} key={item.value}>
              <SelectText>{item.mainText}</SelectText>
              <SelectAltText>{item.altText}</SelectAltText>
            </SelectOption>
          );
        })}
      </SelectField>
    </InputButtonContainer>
  );
};

OfferTypeField.propTypes = {
  formik: PropTypes.any,
  onTypeHandler: PropTypes.func,
};

export default OfferTypeField;

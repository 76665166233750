import React from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../MUI/BackdropComponent";
import {
  ButtonsContainer,
  CategoryTitleField,
  EditCategoryContainer,
  // EditCategoryImagePicker,
  EditCategoryTitle,
  FieldLabel,
  InputContainer,
  SaveButton,
  // SupportedFormats,
  XIcon,
} from "./EditCategory.styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchAdminMethod } from "../../../store/actions/admin/adminActions";
import { useRef } from "react";
import { closeModal } from "../../../store/actions/modal/modalActions";

const EditCategory = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clickedOnNext, setClickedOnNext] = useState(false);
  // const setImage = useState("")[1];
  const inputRef = useRef(null);
  const title = useMemo(() => {
    return t(`admin.${props.type}.${props.method}.title`);
  }, [props.type, props.method]);

  const placeholder = useMemo(() => {
    return t(`admin.${props.type}.${props.method}.placeholder`);
  }, [props.type, props.method]);

  const fieldLabel = useMemo(() => {
    return t(`admin.${props.type}.${props.method}.fieldTitle`);
  }, [props.type, props.method]);

  const firstButtonText = useMemo(() => {
    return t(`admin.${props.type}.${props.method}.next`);
  }, [props.type, props.method]);
  const secondButtonText = useMemo(() => {
    return t(`admin.${props.type}.${props.method}.save`);
  }, [props.type, props.method]);
  // const setImageHandler = (image) => {
  //   setImage(image);
  //   formik.setFieldValue("image", image);
  // };

  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const handleApiResponseSuccess = () => {
    if (clickedOnNext && props?.showSecondButton) {
      formik.resetForm();
      inputRef.current.focus();
    } else closeModalHandler();
  };
  const handleSubmit = (values) => {
    dispatch(
      fetchAdminMethod({
        type: props.type,
        method: props.method,
        values,
        name: props?.category?.name,
        id: props?.category?._id,
        categoryId: props.categoryId,
        handleApiResponseSuccess,
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      image: "",
      title: props?.category?.name || props?.category?.city || "",
    },
    onSubmit: handleSubmit,
  });
  const handleClick = (next) => {
    if (next !== clickedOnNext) setClickedOnNext(next);
    formik.handleSubmit();
  };
  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeModalHandler}
        position="fixed"
      />
      <EditCategoryContainer hideImagePicker={props.hideImagePicker}>
        <XIcon onClick={closeModalHandler} />
        <EditCategoryTitle>{title}</EditCategoryTitle>
        {/* {!props.hideImagePicker && (
          <>
            <EditCategoryImagePicker setImage={setImageHandler} singleImage />
            <SupportedFormats>
              <Trans i18nKey="offer.supportedImagesFormats" />
            </SupportedFormats>
          </>
        )} */}
        <InputContainer hideImagePicker={props.hideImagePicker}>
          <FieldLabel leftText={fieldLabel} />
          <CategoryTitleField
            name="title"
            ref={inputRef}
            placeholder={placeholder}
            italicPlaceholder
            margin="normal"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && formik.errors.title}
            helperText={formik.touched.title && formik.errors.title}
            autoFocus
            fullWidth
          />
        </InputContainer>
        <ButtonsContainer>
          <SaveButton
            showSecondButton={props.showSecondButton}
            variant={props.firstOutlined ? "outlined" : "contained"}
            height="48px"
            onClick={() => handleClick(true)}
          >
            {firstButtonText}
          </SaveButton>
          {props.showSecondButton && (
            <SaveButton
              variant={props.secondOutlined ? "outlined" : "contained"}
              showSecondButton={props.showSecondButton}
              onClick={() => handleClick(false)}
            >
              {secondButtonText}
            </SaveButton>
          )}
        </ButtonsContainer>
      </EditCategoryContainer>
    </>
  );
};

EditCategory.propTypes = {
  category: PropTypes.any,
  setOpenedEditModal: PropTypes.func,
  hideImagePicker: PropTypes.bool,
  type: PropTypes.string,
  showSecondButton: PropTypes.bool,
  method: PropTypes.string,
  firstOutlined: PropTypes.bool,
  secondOutlined: PropTypes.bool,
  categoryId: PropTypes.string,
};

EditCategory.defaultProps = {
  firstOutlined: true,
};

export default EditCategory;

import React, { useMemo } from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import { BASE_PAGE } from "../../constants/pages";
import { selectUserId } from "../../store/selectors/loginSelectors";

const AuthRoute = ({ ...props }) => {
  const userId = useSelector(selectUserId);
  const isUserAuthenticated = useMemo(() => {
    if (userId?.length === 0) return false;
    return true;
  }, [userId]);

  return !isUserAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={BASE_PAGE} />
  );
};

export default AuthRoute;

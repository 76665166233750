import { Box } from "@mui/material";
import styled from "styled-components";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { ReactComponent as GridSquare } from "../../../../assets/images/svg/offer-grid-square.svg";
import { ReactComponent as GridLine } from "../../../../assets/images/svg/offer-grid-line.svg";

export const GridButton = styled(IconButton)`
  padding: 2px 10px;
  @media (max-width: 1500px) {
    display: none;
  }
`;
export const GridButtonsContainer = styled(Box)`
  flex-direction: row;
  display: ${(props) => (props.hideGrid ? "none" : "flex")};
  justify-content: space-between;
  margin-right: 40px;
`;
export const GridSquareIcon = styled(GridSquare)``;
export const GridLineIcon = styled(GridLine)``;

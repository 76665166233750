import { createClearType, createSetType } from "../actionHelpers";

export const TOGGLE_CREATE_OFFER = createSetType("TOGGLE_CREATE_OFFER");
export const TOGGLE_EDIT_OFFER = createSetType("TOGGLE_EDIT_OFFER");
export const TOGGLE_DELETE_OFFER = createSetType("TOGGLE_DELETE_OFFER");
export const TOGGLE_CREATE_CATEGORY = createSetType("TOGGLE_CREATE_CATEGORY");
export const TOGGLE_EDIT_CATEOGRY = createSetType("TOGGLE_EDIT_CATEOGRY");
export const TOGGLE_DELETE_CATEGORY = createSetType("TOGGLE_DELETE_CATEGORY");
export const TOGGLE_CREATE_REVIEW = createSetType("TOGGLE_CREATE_REVIEW");
export const TOGGLE_DELETE_REVIEW = createSetType("TOGGLE_DELETE_REVIEW");
export const TOGGLE_EDIT_PROFILE = createSetType("TOGGLE_EDIT_PROFILE");
export const TOGGLE_CREATE_PAYMENT = createSetType("TOGGLE_CREATE_PAYMENT");
export const TOGGLE_EDIT_PAYMENT = createSetType("TOGGLE_EDIT_PAYMENT");
export const TOGGLE_DELETE_PAYMENT = createSetType("TOGGLE_DELETE_PAYMENT");
export const SET_MODAL_TYPE = createSetType("SET_MODAL_TYPE");
export const CLOSE_MODAL = createClearType("CLOSE_MODAL");

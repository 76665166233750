import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { SubcategoryIcon } from "./SubcategoryChoser.styled";
import FilterRadioDropdown from "../../FilterDropdown/Radio/FilterRadioDropdown";
import { useTranslation } from "react-i18next";

// const firstSubcategoryOption = {
//   label: "SVE PODKATEGORIJE",
//   value: { _id: 0 },
// };

const SubcategoryChoser = forwardRef((props, ref) => {
  const filters = props.filters;
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const subcategories = useMemo(() => {
    return filters.category.getSubcategories(
      filters.category.selectedCategoryLocally?.name
    );
  }, [filters.category.selectedCategoryLocally]);

  useImperativeHandle(ref, () => ({
    closeSection: () => {
      setIsOpened(false);
    },
  }));

  const handleSelectSubcategory = (subcategory) => {
    filters.subcategory.setSelectedSubcategory(subcategory, !props.myOffers);
    // if (props.myOffers) props.offers?.applyFilters();
  };

  useEffect(() => {
    if (props?.queryStringHook?.isInitiallyLoaded || props.myOffers) {
      if (
        (filters.category.selectedCategoryLocally &&
          !("_id" in filters.category.selectedCategoryLocally)) ||
        filters.category.selectedCategoryLocally?._id === 0 ||
        !filters.category.selectedCategoryLocally
      ) {
        setIsOpened(false);
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
        setIsOpened(true);
      }
    } else {
      if (
        (filters.subcategory.selectedSubcategoryLocally &&
          !("_id" in filters.subcategory.selectedSubcategoryLocally)) ||
        filters.subcategory.selectedSubcategoryLocally?._id === 0
      ) {
        setIsOpened(false);
        if (
          (filters.category.selectedCategoryLocally &&
            !("_id" in filters.category.selectedCategoryLocally)) ||
          filters.category.selectedCategoryLocally?._id === 0
        ) {
          setIsDisabled(true);
        }
      }
    }
  }, [filters.category.selectedCategoryLocally]);

  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
  };

  return (
    <FilterRadioDropdown
      data={subcategories}
      icon={<SubcategoryIcon />}
      title={
        filters.subcategory.selectedSubcategory?.name
          ? filters.subcategory.selectedSubcategory?.name
          : t("filters.subcategories.title")
      }
      searchPlaceholder={t("filters.subcategories.placeholder")}
      setSelected={handleSelectSubcategory}
      selected={filters.subcategory.selectedSubcategoryLocally}
      open={isOpened}
      disabled={isDisabled}
      handleOpen={handleOpen}
      firstOption={filters.subcategory.initialOption}
      offers={props.offers}
    />
  );
});

SubcategoryChoser.displayName = "SubcategoryChoser";

SubcategoryChoser.propTypes = {
  filters: PropTypes.any,
  categoryOpened: PropTypes.bool,
  queryStringHook: PropTypes.any,
  myOffers: PropTypes.bool,
  offers: PropTypes.any,
};

export default SubcategoryChoser;

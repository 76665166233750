import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const RegisterAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.altText};
  color: ${selectedTheme.colors.primaryText};
  font-size: 14px;
  padding-right: 6px;
  line-height: 14px;
`;
export const RegisterTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
  @media (max-width: 600px) {
    padding-bottom: 36px;
  }
`;
import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { RegisterButtonContainer } from "./RegisterButton.styled";
import { useTranslation } from "react-i18next";
import { REGISTER_PAGE } from "../../../constants/pages";
import history from "../../../store/utils/history";

const RegisterButton = () => {
  const { t } = useTranslation();
  const handleNavigateRegister = () => {
    history.push(REGISTER_PAGE);
  };
  return (
    <RegisterButtonContainer
      type="submit"
      variant="contained"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryYellow}
      textcolor={selectedTheme.colors.yellowButtonTextColor}
      onClick={handleNavigateRegister}
    >
      {t("register.headerTitle")}
    </RegisterButtonContainer>
  );
};

RegisterButton.propTypes = {
  children: PropTypes.node,
};

export default RegisterButton;

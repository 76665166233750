import { Box, styled } from "@mui/material";
import selectedTheme from "../../../themes";

export const LabeledCardContainer = styled(Box)`
  background: ${selectedTheme.colors.chatHeaderColor};
  border-radius: 2px;
  border: 1px solid ${selectedTheme.colors.borderNormal};
  position: relative;
  width: ${(props) => props.width || `min-content`};
  height: ${(props) => props.height || `57px`};
  /* max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis; */
  padding: 18px;
`;
export const LabeledCardIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  background: ${selectedTheme.colors.primaryPurple};
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 100%;
`;

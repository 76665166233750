import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  DirectChatContentContainer,
  MessageContainer,
  MessagesList,
} from "./DirectChatContent.styled";
import DirectChatContentHeader from "./DirectChatContentHeader/DirectChatContentHeader";
import MessageCard from "../../Cards/MessageCard/MessageCard";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { selectMineProfilePicture } from "../../../store/selectors/profileSelectors";
import DirectChatNewMessage from "../DirectChatNewMessage/DirectChatNewMessage";
import SkeletonDirectChatContent from "./SkeletonDirectChatContent/SkeletonDirectChatContent";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { CHAT_SCOPE } from "../../../store/actions/chat/chatActionConstants";
import RequestExchangeCard from "../../Cards/RequestExchangeCard/RequestExchangeCard";
import { selectRequester } from "../../../store/selectors/exchangeSelector";
import requesterStatus from "../../../constants/requesterStatus";

const DirectChatContent = (props) => {
  const userId = useSelector(selectUserId);
  const myProfileImage = useSelector(selectMineProfilePicture);
  const messagesRef = useRef(null);
  const requester = useSelector(selectRequester);
  const interlocutorProfileImage = props?.interlocutor?.image;
  const isLoadingChatContent = useSelector(
    selectIsLoadingByActionType(CHAT_SCOPE)
  );
  const messages = props?.chat?.messages;
  useEffect(() => {
    messagesRef.current?.scrollTo({
      top: messagesRef.current.scrollHeight,
      behaviour: "smooth",
    });
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [props?.chat?.messages, messagesRef, isLoadingChatContent]);

  const handleRefresh = () => {
    props.refreshChat();
  };
  return (
    <>
      {isLoadingChatContent || isLoadingChatContent === undefined ? (
        <SkeletonDirectChatContent />
      ) : (
        <DirectChatContentContainer>
          <DirectChatContentHeader
            interlocutor={props?.interlocutor}
            exchangeState={props.exchangeState}
          />
          <MessagesList ref={messagesRef} exchangeState={props?.exchangeState}>
            {messages?.map((item) => {
              const isMyMessage = userId === item.user?._id;
              const image = isMyMessage
                ? myProfileImage
                : interlocutorProfileImage;
              if (
                requester === requesterStatus.interlocutor &&
                isMyMessage &&
                item?.isAcceptRequest
              )
                return;
              return (
                <MessageContainer key={item?._id || item?._created}>
                  {item?.isAcceptRequest ? (
                    <RequestExchangeCard
                      isMyMessage={isMyMessage}
                      message={item}
                      chatId={props?.chat?._id}
                    />
                  ) : (
                    <MessageCard
                      message={item}
                      image={image}
                      isMyMessage={isMyMessage}
                    />
                  )}
                </MessageContainer>
              );
            })}
          </MessagesList>
          <DirectChatNewMessage
            chat={props?.chat}
            refreshChat={handleRefresh}
            interlocutor={props.interlocutor}
          />
        </DirectChatContentContainer>
      )}
    </>
  );
};

DirectChatContent.propTypes = {
  children: PropTypes.node,
  chat: PropTypes.any,
  interlocutor: PropTypes.any,
  refreshChat: PropTypes.func,
  exchangeState: PropTypes.any,
};

export default DirectChatContent;

import styled, { css, keyframes } from "styled-components";
import { Box } from "@mui/material";
import selectedTheme from "../../../../themes";

const skeletonAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonItems};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonItems}
    }
`;

// const skeletonBackgroundAnimation = keyframes`
//     0% {
//         background-color: ${selectedTheme.colors.filterSkeletonBackground};
//     }

//     50% {
//         background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
//     }

//     100% {
//         background-color: ${selectedTheme.colors.filterSkeletonBackground}
//     }
// `;

export const UserReviewsSkeletonContainer = styled(Box)`
  width: 100%;
`;
export const UserReviewsSkeletonItemsContainer = styled(Box)`
  width: 100%;
  padding: 18px 0;
`;
export const UserReviewsSkeletonLine = styled(Box)`
  display: flex;
  flex-direction: row;
  & * {
    background-color: ${selectedTheme.colors.skeletonItemColor};
    ${(props) =>
      props.skeleton &&
      css`
        animation: ${skeletonAnimation} 2s infinite;
      `}
  }
`;

export const UserReviewsSkeletonCircle = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.skeletonItemColor};
  ${(props) =>
    props.skeleton &&
    css`
      background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    `}
  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;
export const UserReviewsSkeletonLineOne = styled(Box)`
  width: 78px;
  height: 18px;
  margin-top: 18px;
  margin-left: 18px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
  ${(props) =>
    props.skeleton &&
    css`
      background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    `}
  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;
export const UserReviewsSkeletonLineSecond = styled(Box)`
  width: 200px;
  height: 14px;
  margin-top: 18px;
  margin-left: 9px;
  margin-bottom: 4px;
  flex: 1;
`;
export const UserReviewsSkeletonSquare = styled(Box)`
  width: 14px;
  height: 14px;
  margin-top: 18px;
`;
export const UserReviewsSkeletonLineThird = styled(Box)`
  width: 91px;
  height: 14px;
  margin-left: 23px;
`;
export const UserReviewsSkeletonLineForth = styled(Box)`
  width: 154px;
  height: 14px;
  margin-top: 20px;
  margin-bottom: 4px;
`;
export const UserReviewsSkeletonLineFifth = styled(Box)`
  width: 121px;
  height: 14px;
`;

export const UserReviewsSkeletonSquareImageContainer = styled(Box)`
  display: flex;
  margin-top: 18px;
`;

export const UserReviewsSkeletonSquareImage = styled(Box)`
  width: 54px;
  height: 54px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;

export const UserReviewsSkeletonSquareInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  justify-content: center;
`;

export const UserReviewsSkeletonSquareInfoFirstLine = styled(Box)`
  width: 121px;
  height: 14px;
  background-color: ${selectedTheme.colors.skeletonItemColor};

  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;

export const UserReviewsSkeletonSquareInfoSecondLine = styled(Box)`
  width: 78px;
  height: 18px;
  margin-top: 6px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;

export const UserReviewsSkeletonHrLine = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${(props) =>
    props.skeleton
      ? `${selectedTheme.colors.filterSkeletonBackgroundSecond}`
      : `${selectedTheme.colors.skeletonItemColor}`};

  ${(props) =>
    props.skeleton &&
    css`
      animation: ${skeletonAnimation} 2s infinite;
    `}
`;

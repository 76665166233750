import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { hexToRGB } from "../../../../util/helpers/colorHelper";

export const CategoryDetailContainer = styled(Box)`
  /* display: flex;
  flex-direction: row; */
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: ${(props) => (props.payments ? "220px" : "150px")};
  border-left: 1px solid ${hexToRGB(selectedTheme.colors.primaryText, 0.13)};
  ${(props) => !props.payments && ` text-align: center; `}
  @media (max-width: 600px) {
    margin: 0;
    min-width: 123px;
    &:nth-child(1) {
      border-left: 0;
    }
    &:nth-child(2) {
      ${(props) => !props.payments && ` padding-left: 18px; `}
    }
  }
`;
export const CategoryDetailLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  font-size: 12px;
  letter-spacing: 0.01rem;
  padding-top: 14px;
  padding-right: 3px;
  ${(props) => props.payments && ` padding-left: 18px; `}
  @media (max-width: 600px) {
    position: relative;
    bottom: 2.5px;
    padding-top: 0;
  }
`;
export const CategoryDetailValue = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  padding-top: 11px;
  color: ${selectedTheme.colors.primaryPurple};
  @media (max-width: 600px) {
    position: relative;
    bottom: 2.5px;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    ${(props) => props.payments && ` padding-right: 18px; `}
  }
`;

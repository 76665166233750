import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../themes";
import { NextButton as NextButtonContainer } from "../../CreateReview.styled";
import { useTranslation } from "react-i18next";

const NextButton = () => {
  const { t } = useTranslation();
  return (
    <NextButtonContainer
      variant="contained"
      buttoncolor={selectedTheme.colors.primaryPurple}
      fullWidth
      height="48px"
      type="submit"
      // disabled={props.formik.values.comment?.length < 5}
    >
      {t("common.continue")}
    </NextButtonContainer>
  );
};

NextButton.propTypes = {
  formik: PropTypes.any,
};

export default NextButton;

import React from "react";
import PropTypes from "prop-types";
import { CreateOfferContainer } from "./CreateOffer.styled";
import CreateOffer from "../../components/Cards/CreateOfferCard/CreateOffer";

const CreateOfferPage = () => {
  return (
    <CreateOfferContainer>
      <CreateOffer />
    </CreateOfferContainer>
  );
};

CreateOfferPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default CreateOfferPage;

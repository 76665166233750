import i18next from "i18next";
import defaultImage from "../constants/defaultImage";
export default {
  company: {
    name: i18next.t("notFoundData.companyName"),
    idNumber: i18next.t("notFoundData.PIB"),
  },
  email: i18next.t("notFoundData.email"),
  image: defaultImage,
  statistics: {
    exchanges: {
      failed: 0,
      succeeded: 0,
      total: 0,
    },
    publishes: {
      count: 0,
    },
    views: {
      count: 0,
    },
  },
};

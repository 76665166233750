import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import {
  BackgroundTransition,
  ItemsTransition,
} from "../../../Styles/globalStyleComponents";

export const SkeletonChatCardContainer = styled(BackgroundTransition)`
  height: 108px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 18px;
  padding: 18px;
  border: 1px solid ${selectedTheme.colors.borderNormal};
  border-radius: 4px;
`;
export const SkeletonChatCardImage = styled(ItemsTransition)`
  width: 72px;
  height: 72px;
  border-radius: 100%;
`;
export const SkeletonChatCardColumnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const SkeletonChatCardLineOne = styled(ItemsTransition)`
  width: 90px;
  height: 20px;
`;
export const SkeletonChatCardLineSecond = styled(ItemsTransition)`
  width: 72px;
  height: 14px;
  margin-top: 16px;
`;
export const SkeletonChatCardLineThird = styled(ItemsTransition)`
  width: 85px;
  height: 14px;
`;
export const SkeletonChatCardCircle = styled(ItemsTransition)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: absolute;
  top: 18px;
  right: 18px;
`;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DetailIcon,
  DetailText,
  EditIcon,
  EditIconContainer,
  // EyeIcon,
  Line,
  MessageIcon,
  OfferAuthor,
  OfferAuthorName,
  OfferCardContainer,
  OfferCategory,
  OfferDetails,
  OfferFlexContainer,
  OfferImage,
  OfferImageContainer,
  OfferInfo,
  OfferLocation,
  OfferTitle,
  // OfferTitleAboveImage,
  // OfferViews,
  OfferDate,
  // PinIcon,
  RemoveIcon,
  RemoveIconContainer,
  LikeIcon,
  LikeIconContainer,
  CategoryIcon,
  CalendarIcon,
  PinIconContainer,
  PinOutlinedIcon,
  ButtonsContainer,
  TooltipInnerContainer,
  UnpinOutlinedIcon,
  AcceptIconContainer,
  AcceptIcon,
} from "./OfferCard.styled";
import { ReactComponent as Message } from "../../../assets/images/svg/mail.svg";
import { ReactComponent as Favorite } from "../../../assets/images/svg/favorite.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import OfferDescription from "./OfferDescription/OfferDescription";
import CheckButton from "./CheckButton/CheckButton";
import { formatDateLocale } from "../../../util/helpers/dateHelpers";
import {
  toggleDeleteOfferModal,
  toggleEditOfferModal,
} from "../../../store/actions/modal/modalActions";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import {
  ADMIN_ITEM_DETAILS_PAGE,
  BLOG_DETAILS_PAGE,
  ITEM_DETAILS_PAGE,
  LOGIN_PAGE,
} from "../../../constants/pages";
import exchangeStatus from "../../../constants/exchangeStatus";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import MenuModal from "../../MenuModal/MenuModal";

const OfferCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(selectUserId);
  const { isMobile } = useIsMobile();
  const { isLoggedIn } = useIsLoggedIn();
  const date = formatDateLocale(new Date(props.offer?._created));
  const { t } = useTranslation();

  const pinOffer = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteOfferModal({
        offer: props.offer,
        pin: true,
        pinnedOffer: props.offer.pinned,
        deleteOffer: false,
      })
    );
  };
  const routeToItem = (event, itemId) => {
    event.stopPropagation();
    if (props.blog) {
      history.push(
        replaceInRoute(BLOG_DETAILS_PAGE, {
          blogId: itemId,
        })
      );
    } else {
      if (!props.disabledCheckButton) {
        if (props.isAdmin) {
          history.push(
            replaceInRoute(ADMIN_ITEM_DETAILS_PAGE, {
              offerId: itemId,
            })
          );
        } else {
          history.push({
            pathname: replaceInRoute(ITEM_DETAILS_PAGE, {
              offerId: itemId,
            }),
            state: {
              view: true,
            },
          });
        }
      }
    }
  };
  const messageUser = (event) => {
    event.stopPropagation();
    if (!isLoggedIn) {
      history.push({
        pathname: LOGIN_PAGE,
      });
    } else {
      props.messageUser(props.offer);
    }
  };
  const makeReview = (event) => {
    event.stopPropagation();
    if (!props.disabledReviews) {
      props.makeReview(props.offer);
    }
  };
  const openEditOfferModal = (event) => {
    event.stopPropagation();
    dispatch(
      toggleEditOfferModal({
        editOffer: true,
        offer: props.offer,
        isAdmin: props.isAdmin,
        customUserId: props?.offer?.user._id,
      })
    );
  };
  const openRemoveModal = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteOfferModal({
        offer: props.offer,
        isAdmin: props.isAdmin,
      })
    );
  };

  const acceptExchange = (event) => {
    event.stopPropagation();
    props.acceptExchange();
  };

  const showMessageIcon = useMemo(() => {
    if (userId === props.offer?.userId) {
      return false;
    }
    return true;
  }, [userId, props.offer]);

  console.log(props);

  return (
    <React.Fragment>
      <OfferCardContainer
        vertical={props.vertical}
        sponsored={
          props?.pinned !== undefined
            ? props?.pinned?.toString()
            : props?.offer?.pinned?.toString()
        }
        halfwidth={props.halfwidth ? 1 : 0}
        onClick={(event) => routeToItem(event, props?.offer?._id)}
      >
        {/* This only shows on vertical offer card */}
        {/* <OfferTitleAboveImage
          vertical={props.vertical}
          noOfButtons={
            props.isAdmin
              ? 3
              : props.isMyOffer
              ? props?.offer?.pinned
                ? 3
                : 2
              : props?.offer?.pinned
              ? 2
              : 1
          }
          onClick={(event) => routeToItem(event, props?.offer?._id)}
        >
          {props?.offer?.name}
        </OfferTitleAboveImage> */}
        {/* ^^^^^^^ */}

        <OfferFlexContainer vertical={props.vertical}>
          <OfferImageContainer vertical={props.vertical} blog={props?.blog}>
            <OfferImage
              src={
                props?.offer?.images
                  ? getImageUrl(
                      props?.offer?.images[0],
                      variants.offerCard,
                      isMobile
                    )
                  : props?.blog
                  ? props.offer.image
                  : ""
              }
              alt={t("offer.imageAlt")}
              vertical={props.vertical}
              blog={props?.blog}
            ></OfferImage>
          </OfferImageContainer>
          <OfferInfo vertical={props.vertical}>
            <OfferTitle
              vertical={props.vertical}
              onClick={(event) => routeToItem(event, props?.offer?._id)}
            >
              {!props?.blog ? props?.offer?.name : props?.offer?.title}
            </OfferTitle>
            <OfferAuthor vertical={props.vertical}>
              <OfferAuthorName vertical={props.vertical} blog={props?.blog}>
                {!props?.blog
                  ? props?.offer?.user?.company?.name
                  : props?.offer?.creator}
              </OfferAuthorName>
              <OfferLocation vertical={props.vertical}>
                {!props?.blog
                  ? props?.offer?.location?.city
                  : props?.offer?.creatorRole}
              </OfferLocation>
            </OfferAuthor>
            <OfferDetails>
              <OfferCategory vertical={props.vertical}>
                <DetailIcon color="black" component="span" size="16px">
                  <CategoryIcon />
                </DetailIcon>
                <DetailText>
                  {!props?.blog
                    ? props?.offer?.category?.name
                    : props?.offer?.category}
                </DetailText>
              </OfferCategory>

              {/* {props.dontShowViews ? (
                <></>
              ) : (
                <OfferViews vertical={props.vertical}>
                  <DetailIcon color="black" component="span" size="16px">
                    <EyeIcon />
                  </DetailIcon>
                  <DetailText>{props?.offer?.views?.count}</DetailText>
                </OfferViews>
              )} */}
              <OfferDate vertical={props.vertical}>
                <DetailIcon component="span" size="16px">
                  <CalendarIcon />
                </DetailIcon>
                <DetailText>
                  {!props?.blog ? date : props?.offer?.date}
                </DetailText>
              </OfferDate>
            </OfferDetails>
          </OfferInfo>
          {!props.halfwidth ? (
            <React.Fragment>
              {!props.sidebar && (
                <>
                  <Line />
                  <OfferDescription
                    blog={props?.blog}
                    description={props?.offer?.description}
                  />
                  <CheckButton
                    disabled={props.disabledCheckButton}
                    offerId={props?.offer?._id}
                    sponsored={props.sponsored}
                    blog={props?.blog}
                  />
                </>
              )}
            </React.Fragment>
          ) : (
            <></>
          )}
          <ButtonsContainer vertical={props.vertical}>
            {/* {props?.offer?.pinned && (
              <PinIcon vertical={props.vertical} isMyOffer={props.isMyOffer} />
            )} */}
            {props.isMyOffer && isMobile ? (
              <MenuModal offer={props?.offer} isAdmin={props?.isAdmin} />
            ) : props.isMyOffer ? (
              <>
                <Tooltip title={t("deleteOffer.tooltip")}>
                  <TooltipInnerContainer>
                    <RemoveIconContainer
                      vertical={props.vertical}
                      onClick={openRemoveModal}
                      sponsored={props?.offer?.pinned}
                    >
                      <RemoveIcon />
                    </RemoveIconContainer>
                  </TooltipInnerContainer>
                </Tooltip>
                <Tooltip title={t("offer.tooltip")}>
                  <TooltipInnerContainer>
                    <EditIconContainer
                      vertical={props.vertical}
                      onClick={openEditOfferModal}
                      sponsored={props?.offer?.pinned}
                    >
                      <EditIcon />
                    </EditIconContainer>
                  </TooltipInnerContainer>
                </Tooltip>
              </>
            ) : props.aboveChat ? (
              props.exchangeState === exchangeStatus.ACCEPTED ||
              props.exchangeState === exchangeStatus.REVIEWED ? (
                <LikeIconContainer
                  customDisabled={props.disabledReviews}
                  disabled={props.disabledReviews}
                  onClick={makeReview}
                >
                  <LikeIcon disabled={props.disabledReviews} />
                </LikeIconContainer>
              ) : (
                <AcceptIconContainer // MENJATI
                  // customDisabled={
                  //   props.exchangeState === exchangeStatus.I_OFFERED ||
                  //   props.disabledReviews
                  // }
                  disabled={
                    props.exchangeState === exchangeStatus.I_OFFERED ||
                    props.disabledReviews
                  }
                  onClick={acceptExchange}
                >
                  <AcceptIcon
                    disabled={
                      props.disabledReviews ||
                      props.exchangeState === exchangeStatus.I_OFFERED
                    }
                  />
                </AcceptIconContainer>
              )
            ) : !props?.blog ? (
              <Tooltip title={t("messages.tooltip")}>
                <TooltipInnerContainer>
                  <MessageIcon
                    aria-label={t("labels.messageUser")}
                    showMessageIcon={showMessageIcon}
                    vertical={props.vertical}
                    onClick={messageUser}
                    sponsored={props?.offer?.pinned}
                  >
                    <Message />
                  </MessageIcon>
                </TooltipInnerContainer>
              </Tooltip>
            ) : (
              !props.sidebar && (
                <Tooltip title={t("blog.tooltip")}>
                  <TooltipInnerContainer>
                    <MessageIcon
                      aria-label={t("labels.messageUser")}
                      showMessageIcon={showMessageIcon}
                      // vertical={props.vertical}
                      // onClick={messageUser}
                      // sponsored={props?.offer?.pinned}
                    >
                      <Favorite />
                    </MessageIcon>
                  </TooltipInnerContainer>
                </Tooltip>
              )
            )}
            {props.isAdmin &&
              (!props?.offer?.pinned ? (
                <Tooltip title={t("admin.pin.tooltip")}>
                  <TooltipInnerContainer>
                    <PinIconContainer
                      showMessageIcon
                      onClick={pinOffer}
                      vertical={props.vertical}
                    >
                      <PinOutlinedIcon />
                    </PinIconContainer>
                  </TooltipInnerContainer>
                </Tooltip>
              ) : (
                <Tooltip title={t("admin.unpin.tooltip")}>
                  <TooltipInnerContainer>
                    <PinIconContainer
                      showMessageIcon
                      onClick={pinOffer}
                      vertical={props.vertical}
                    >
                      <UnpinOutlinedIcon />
                    </PinIconContainer>
                  </TooltipInnerContainer>
                </Tooltip>
              ))}
          </ButtonsContainer>
        </OfferFlexContainer>
      </OfferCardContainer>
    </React.Fragment>
  );
};

OfferCard.propTypes = {
  children: PropTypes.node,
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  author: PropTypes.string,
  location: PropTypes.string,
  image: PropTypes.node,
  quantity: PropTypes.number,
  package: PropTypes.string,
  numberOfViews: PropTypes.number,
  halfwidth: PropTypes.bool,
  sponsored: PropTypes.bool,
  offer: PropTypes.any,
  pinned: PropTypes.bool,
  vertical: PropTypes.bool,
  isMyOffer: PropTypes.bool,
  aboveChat: PropTypes.bool,
  disabledReviews: PropTypes.bool,
  messageUser: PropTypes.func,
  makeReview: PropTypes.func,
  dontShowViews: PropTypes.bool,
  skeleton: PropTypes.bool,
  disabledCheckButton: PropTypes.bool,
  isAdmin: PropTypes.bool,
  exchangeState: PropTypes.any,
  acceptExchange: PropTypes.func,
  blog: PropTypes.bool,
  sidebar: PropTypes.bool,
};
OfferCard.defaultProps = {
  halfwidth: false,
  sponsored: false,
  messageUser: () => {},
  makeReview: () => {},
};

export default OfferCard;

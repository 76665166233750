import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectLoginError } from "../../../store/selectors/loginSelectors";
import { ErrorText } from "./ErrorMessage.styled";

const ErrorMessage = (props) => {
  const formik = props.formik;
  const error = useSelector(selectLoginError);
  return (
    <>
      {error.length > 0 ? (
        <ErrorText>{error}</ErrorText>
      ) : formik.errors.email?.length > 0 && formik.touched.email ? (
        <ErrorText>{formik.errors.email}</ErrorText>
      ) : (
        formik.errors.password?.length > 0 &&
        formik.touched.password && (
          <ErrorText>{formik.errors.password}</ErrorText>
        )
      )}
    </>
  );
};

ErrorMessage.propTypes = {
  formik: PropTypes.any,
};

export default ErrorMessage;

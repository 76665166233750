import React from "react";
import ProfileLayout from "../../layouts/ProfileLayout/ProfileLayout";
import { ProfilePageContainer } from "./ProfilePage.styled";
import Profile from "../../components/Profile/Profile";
import UserReviews from "../../components/UserReviews/UserReviews";

const ProfilePage = () => {
  return (
    <ProfilePageContainer>
      <ProfileLayout
        content={<Profile />}
        rightCard={<UserReviews isProfileReviews fullHeight />}
        profile
      />
    </ProfilePageContainer>
  );
};

export default ProfilePage;

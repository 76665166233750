import { ABOUT_ROUTE_SELECTED, ADD_LOADER, REMOVE_LOADER } from "./appActionConstants";

export const addLoader = (payload) => ({
  type: ADD_LOADER,
  payload
});
export const removeLoader = (payload) => ({
  type: REMOVE_LOADER,
  payload
})
export const setAboutRouteSelected = (payload) => ({
  type: ABOUT_ROUTE_SELECTED,
  payload
})
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchLocations = () =>
  getRequest(apiEndpoints.offers.locations);
export const attemptAddNewLocation = (payload) =>
  postRequest(apiEndpoints.admin.locations.newLocation, payload.body);

export const attemptEditLocation = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.admin.locations.editLocation, {
      locationId: payload.locationId,
    }),
    payload.body
  );

export const attemptDeleteLocation = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.admin.locations.deleteLocation, {
      locationId: payload.locationId,
    })
  );

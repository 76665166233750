import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const CreateOfferContainer = styled(Container)`
  margin-top: 0px;
  display: flex;
  width: 380px;
  flex-direction: column;
  align-items: center;
`;
export const CreateOfferTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
  margin-bottom: 40px;
`;
export const CreateOfferDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
`;
export const CreateOfferFormContainer = styled(Box)`
  width: 335px;
  height: 216px;
`;
export const RegisterAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  font-size: 14px;
  padding-right: 6px;
  line-height: 14px;
`
export const RegisterTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
`
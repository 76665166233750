import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/images/svg/logo-image.svg";
import selectedTheme from "../../../themes";

export const ThirdStepCreateReviewContainer = styled(Box)`
  width: 100%;
  height: 400px;
  text-align: center;
`;
export const LogoImage = styled(Logo)`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-self: center;
  align-content: center;
  margin-top: 100px;
  margin-bottom: 4px;
`;
export const MainTitle = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  font-family: ${selectedTheme.fonts.textFont};
  text-align: center;
  width: 100%;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const AltTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 400;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
`;

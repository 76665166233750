import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { AccountCircle } from "@mui/icons-material";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import {
  ProfileImage,
  UserButtonContainer,
  UserName,
} from "./UserButton.styled";
import PopoverComponent from "../../Popovers/PopoverComponent";
import { MyProfile } from "../../Popovers/MyProfile/MyProfile";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import useIsMobile from "../../../hooks/useIsMobile";

const UserButton = (props) => {
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [userPopoverOpen, setUserPopoverOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  useEffect(() => {
    setUserPopoverOpen(false);
  }, [location.pathname]);

  const openUserPopover = (event) => {
    setUserPopoverOpen(true);
    setUserAnchorEl(event.currentTarget);
  };
  const closeUserPopover = () => {
    setUserPopoverOpen(false);
    setUserAnchorEl(null);
  };
  return (
    <>
      <UserButtonContainer onClick={openUserPopover}>
        <UserName>{props.name}</UserName>
        {!props?.profile?.image ? (
          <IconButton
            aria-label={t("header.myProfile")}
            style={{
              background: selectedTheme.colors.primaryIconBackgroundColor,
              color: selectedTheme.colors.primaryPurple,
            }}
          >
            <AccountCircle onClick={openUserPopover} />
          </IconButton>
        ) : (
          <ProfileImage
            src={getImageUrl(
              props?.profile?.image,
              variants.profileCard,
              isMobile
            )}
          />
        )}
      </UserButtonContainer>
      <PopoverComponent
        anchorEl={userAnchorEl}
        open={userPopoverOpen}
        onClose={() => {
          setUserPopoverOpen(false);
          setUserAnchorEl(null);
        }}
        content={<MyProfile closePopover={closeUserPopover} />}
      />
    </>
  );
};

UserButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  profile: PropTypes.object,
};

export default UserButton;

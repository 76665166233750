import * as Yup from "yup";
import i18n from "../../i18n";

export default (locations) =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .min(6, i18n.t("register.phoneNumberNoOfCharacters"))
      .max(14, i18n.t("register.phoneNumberNoOfCharacters"))
      .required(i18n.t("register.phoneNumberRequired")),
    location: Yup.string()
      .oneOf(
        locations.map((l) => l.city),
        i18n.t("register.labelLocationValid")
      )
      .required(i18n.t("register.labelLocationRequired")),
    website: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      i18n.t("register.websiteError")
    ),
    // .required(i18n.t("register.websiteRequired")),
  });

import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import selectedTheme from "../../../themes";
export const AdminItemDetailsPageContainer = styled(Box)`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${selectedTheme.colors.offerBackgroundColor};

  @media screen and (max-width: 600px) {
    margin-top: 65px;
  }
`;
export const GridStyled = styled(Grid)``;

import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchOffers = (payload) => {
  if (payload) return getRequest(apiEndpoints.offers.getOffers + payload);
  return getRequest(apiEndpoints.offers.getOffers);
};
export const attemptFetchFeaturedOffers = (payload) => {
  if (payload)
    return getRequest(apiEndpoints.offers.getFeaturedOffers + payload);
  return getRequest(apiEndpoints.offers.getOffers);
};
export const attemptFetchOneOffer = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.offers.getOneOffer, {
      offerId: payload.offerId,
    }),
    payload.queryObject
  );
};
export const attemptFetchMoreOffers = (page, payload) => {
  if (payload)
    return getRequest(
      apiEndpoints.offers.getOffers + payload + `&size=10&page=${page}`
    );
  return getRequest(apiEndpoints.offers.getOffers + `?size=10&page=${page}`);
};
export const attemptAddOffer = (payload, data) => {
  // return postRequest(apiEndpoints.offers.addOffer, { userId: payload });
  return postRequest(
    replaceInUrl(apiEndpoints.offers.addOffer, { userId: payload }),
    data
  );
};
export const attemptFetchProfileOffers = (userId, queryString = "") => {
  return getRequest(
    replaceInUrl(apiEndpoints.offers.profileOffers, {
      userId: userId,
    }) + `?${queryString}`
  );
};
export const attemptFetchProfileOffersAsAdmin = (userId, queryString = "") => {
  return getRequest(
    replaceInUrl(apiEndpoints.offers.profileOffersAsAdmin, {
      userId: userId,
    }) + `?${queryString}`
  );
};
export const attemptRemoveOffer = (payload, offerId) => {
  return deleteRequest(
    replaceInUrl(apiEndpoints.offers.removeOffer, {
      userId: payload,
      offerId: offerId,
    })
  );
};
export const attemptRemoveOfferAsAdmin = (offerId) => {
  return deleteRequest(
    replaceInUrl(apiEndpoints.offers.removeOfferAsAdmin, {
      offerId: offerId,
    })
  );
};
export const attemptEditOffer = (payload, offerId, editedData) => {
  return putRequest(
    replaceInUrl(apiEndpoints.offers.editOffer, {
      userId: payload,
      offerId: offerId,
    }),
    editedData
  );
};
export const attemptEditOfferAsAdmin = (offerId, editedData) => {
  return putRequest(
    replaceInUrl(apiEndpoints.offers.editOfferAsAdmin, {
      offerId: offerId,
    }),
    editedData
  );
};
export const attemptPinOffer = (payload) => {
  return patchRequest(
    replaceInUrl(apiEndpoints.offers.pinOffer, { id: payload })
  );
};

import { Typography, Checkbox, Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const FormContainer = styled.form`
  width: 335px;

  @media (max-width: 345px) {
    width: 300px;
  }
`;
export const RegisterDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${selectedTheme.colors.primaryText};
  font-size: 12px;
  width: 100%;
  text-align: left;
  line-height: 16px;
  margin-top: 31px;
  margin-bottom: 2px;
  letter-spacing: 0.02em;
  @media (max-height: 800px) {
    margin-top: 14px;
  }
`;

export const CheckboxInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin: -5px 0 10px -5px;
`;

export const CheckboxInput = styled(Checkbox)``;

export const CheckboxInputText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 14px;
`;

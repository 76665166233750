import { NEW_CHAT } from "../../constants/chatConstants";
import { DIRECT_CHAT_PAGE } from "../../constants/pages";
import history from "../../store/utils/history";
import { replaceInRoute } from "./routeHelpers";

export const messageUserHelper = (chats, userId, offer) => {
  const chatItem = chats.find(
    (item) => item?.chat?.offerId === offer?.offer?._id
  );
  if (chatItem.chat === undefined) return;
  if (chatItem !== undefined) {
    history.push(
      replaceInRoute(DIRECT_CHAT_PAGE, { chatId: chatItem.chat._id })
    );
  } else {
    if (offer?.offer?.userId !== userId) {
      history.push({
        pathname: replaceInRoute(DIRECT_CHAT_PAGE, { chatId: NEW_CHAT }),
        state: {
          offerId: offer?.offer?._id,
        },
      });
    }
  }
};

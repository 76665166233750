import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { ReactComponent as Edit } from "../../../../assets/images/svg/edit.svg";

export const CategoryEditButtonContainer = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  position: relative;
  top: 22px;
  margin-right: 18px;
  padding-top: 2px;
  text-align: center;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    top: 18px;
    right: 18px;
    margin-right: 0;
    padding: 0;
    ${(props) =>
      props.vertical &&
      `
      display: none;
    `}
    & button svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: -3px;
      left: -1.5px;
    }
  }
`;
export const EditIcon = styled(Edit)`
    & path {
    ${(props) =>
      props.disabled &&
      `stroke: ${selectedTheme.colors.iconStrokePurpleDisabledColor};`}
  }
`
import React from "react";
import PropTypes from "prop-types";
import { InputField, InputFieldLabel } from "../EditProfile.styled";
import { useTranslation } from "react-i18next";

const FirmNameField = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <InputFieldLabel leftText={t("common.labelFirm").toUpperCase()} />
      <InputField
        name="firmName"
        value={props.formik.values.firmName}
        onChange={props.formik.handleChange}
        error={props.formik.touched.firmName && props.formik.errors.firmName}
        margin="normal"
        fullWidth
      />
    </>
  );
};

FirmNameField.propTypes = {
  formik: PropTypes.any,
};

export default FirmNameField;

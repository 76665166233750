import { Box, Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const CheckBoxContainer = styled(Box)`
  ${(props) =>
    props.fullWidth &&
    `
        width: 100%;
        display: flex;
        flex: 1;
    `}
`;

export const CheckBoxStyled = styled(Checkbox)`
  color: ${(props) => props.boxcolor} !important;
  padding: 6px;
`;
export const FormControlLabelStyled = styled(FormControlLabel)`
  ${(props) =>
    props.fullwidth &&
    `
        width: 100%;
        display: flex;
        flex: 1;
    `}
  margin-right: 0;
  & label {
    font-family: ${selectedTheme.fonts.textFont};
    font-size: 14px;
  }
  & span:nth-child(1) svg {
    width: 18px;
    height: 18px;
  }
  & span:nth-child(2) {
    flex: 1;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LoginTitle as Title } from "./LoginTitle.styled";

const LoginTitle = (props) => {
  const { t } = useTranslation();
  return (
    <Title component="h1" variant="h5">
      {
        props.isAdmin ? t("admin.login.logInTitle") : t("login.logInTitle")
      }
    </Title>
  );
};

LoginTitle.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};

export default LoginTitle;

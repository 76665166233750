import { useEffect, useMemo } from "react";
import useCategoryFilter from "./useCategoryFilter";
import useCompaniesFilter from "./useCompanyFilter";
import useLocationsFilter from "./useLocationsFilter";
import useSubcategoryFilter from "./useSubcategoryFilter";

const useFilters = (clearAll = false, applyFilters = () => {}) => {
  const category = useCategoryFilter();
  const subcategory = useSubcategoryFilter(applyFilters);
  const locations = useLocationsFilter();
  const companies = useCompaniesFilter();

  useEffect(() => {
    if (clearAll) {
      clear();
    }
    return () => {
      clear();
    }
  }, []);

  const numOfFiltersChosen = useMemo(() => {
    let sumOfFiltersChosen = 0;
    if (category.selectedCategoryLocally?._id) sumOfFiltersChosen++;
    if (subcategory.selectedSubcategoryLocally?._id) sumOfFiltersChosen++;
    sumOfFiltersChosen += locations.selectedLocationsLocally.length;
    return sumOfFiltersChosen;
  }, [
    category.selectedCategoryLocally,
    subcategory.selectedSubcategoryLocally,
    locations.selectedLocationsLocally,
    companies.selectedCompaniesLocally,
  ]);

  const apply = (immediatelyApply = false, applyAllFilters) => {
    category.apply();
    subcategory.apply();
    locations.apply();
    companies.apply();
    if (immediatelyApply) applyAllFilters();
  };

  const clear = () => {
    category.clear();
    subcategory.clear();
    locations.clear();
    companies.clear();
  };

  return {
    category,
    subcategory,
    locations,
    companies,
    numOfFiltersChosen,
    apply,
    clear,
  };
};
export default useFilters;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DropdownItem from "../../../../Dropdown/DropdownItem/DropdownItem";
import CheckboxDropdownList from "./CheckboxDropdownList/CheckboxDropdownList";
import Checkbox from "./Checkbox/Checkbox";

const FilterCheckboxDropdown = (props) => {
  const [dataToShow, setDataToShow] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [toSearch, setToSearch] = useState("");
  const { data } = props;

  useEffect(() => {
    setDataToShow([...data]);
  }, [data]);

  useEffect(() => {
    if (toSearch.length > 0) {
      setDataToShow(
        data.filter((item) =>
          props.companies
            ? item.companyName.toLowerCase().includes(toSearch.toLowerCase())
            : item.city.toLowerCase().includes(toSearch.toLowerCase())
        )
      );
    } else {
      setDataToShow([...data]);
    }
  }, [toSearch]);

  useEffect(() => {
    if (props.filters?.length > 0) {
      setIsOpened(true);
    }
  }, [props.filters]);

  const handleChange = (item) => {
    if (props.oneValueAllowed) {
      props.setItemsSelected([item]);
    } else {
      if (
        props.filters.find(
          (itemInList) =>
            itemInList?.city?.toString() === item?.city?.toString()
        )
      ) {
        props.setItemsSelected([
          ...props.filters.filter(
            (p) => p?.city?.toString() !== item?.city?.toString()
          ),
        ]);
        props.offers.applyFilters();
      } else {
        props.setItemsSelected([...props.filters, item]);
        props.offers.applyFilters();
      }
    }
  };
  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
    if (props.handleOpen) props.handleOpen();
  };

  return (
    <CheckboxDropdownList
      toSearch={toSearch}
      setToSearch={setToSearch}
      title={props.title}
      filters={props.filters}
      icon={props.icon}
      data={data}
      searchPlaceholder={props.searchPlaceholder}
      open={props?.open !== undefined ? props.open : isOpened}
      handleOpen={handleOpen}
      setItemsSelected={props.setItemsSelected}
      companies={props.companies}
      offers={props.offers}
    >
      {(isOpened || props?.open) &&
        dataToShow.map((item) => {
          return (
            <DropdownItem key={item.city}>
              <Checkbox
                item={item}
                filters={props.filters}
                onChange={() => handleChange(item)}
                companies={props.companies}
              />
            </DropdownItem>
          );
        })}
    </CheckboxDropdownList>
  );
};

FilterCheckboxDropdown.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  data: PropTypes.array,
  title: PropTypes.string,
  oneValueAllowed: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setItemsSelected: PropTypes.func,
  filters: PropTypes.array,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  companies: PropTypes.bool,
  offers: PropTypes.any,
};
FilterCheckboxDropdown.defaultProps = {
  oneValueAllowed: false,
};
export default FilterCheckboxDropdown;

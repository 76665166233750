import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import MailIcon from "@mui/icons-material/EmailOutlined";
import { Badge } from "@mui/material";
import { useState } from "react";
import PopoverComponent from "../../Popovers/PopoverComponent";
import { MyMessages } from "../../Popovers/MyMessages/MyMessages";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { selectLatestChats } from "../../../store/selectors/chatSelectors";
// import { selectUserId } from "../../../store/selectors/loginSelectors";
// import { useSelector } from "react-redux";

const MyMessagesButton = (props) => {
  // const [badge, setBadge] = useState(0);
  const location = useLocation();
  const { t } = useTranslation();
  const [msgPopoverOpen, setMsgPopoverOpen] = useState(false);
  const [msgAnchorEl, setMsgAnchorEl] = useState(null);
  // const allChats = useSelector(selectLatestChats);
  // const userId = useSelector(selectUserId);
  // let lastChatLength = allChats[0]?.messages?.map(
  //   (mesage) => mesage.user._id !== userId
  // ).length;
  // console.log(lastChatLength);

  // useEffect(() => {
  //   setBadge(badge + 1);
  // }, [lastChatLength]);

  useEffect(() => {
    setMsgPopoverOpen(false);
  }, [location.pathname]);
  const openMsgPopover = (event) => {
    setMsgPopoverOpen(true);
    setMsgAnchorEl(event.currentTarget);
    props.setBadge(0);
  };

  const closeMsgPopover = () => {
    setMsgPopoverOpen(false);
    setMsgAnchorEl(null);
  };
  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: selectedTheme.colors.primaryPurple,
      backgroundColor: selectedTheme.colors.iconYellowColor,
      fontWeight: 700,
    },
  };
  return (
    <>
      <IconButton
        aria-label={t("header.myMessages")}
        onClick={openMsgPopover}
        style={{
          background: selectedTheme.colors.primaryIconBackgroundColor,
          color: selectedTheme.colors.primaryPurple,
        }}
      >
        <Badge sx={badgeStyle} badgeContent={props.badge}>
          <MailIcon />
        </Badge>
      </IconButton>
      <PopoverComponent
        anchorEl={msgAnchorEl}
        open={msgPopoverOpen}
        onClose={() => {
          setMsgPopoverOpen(false);
          setMsgAnchorEl(null);
        }}
        content={<MyMessages closePopover={closeMsgPopover} />}
      />
    </>
  );
};

MyMessagesButton.propTypes = {
  onClick: PropTypes.func,
  badge: PropTypes.any,
  setBadge: PropTypes.func,
};

export default MyMessagesButton;

import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider } from "@mui/material";
import { useSelector } from "react-redux";
import i18next from "i18next";

import { selectJwtToken, selectUserId } from "./store/selectors/loginSelectors";
import history from "./store/utils/history";
import AppRoutes from "./AppRoutes";
import { socketInit } from "./socket/socket";

import Header from "./components/Header/Header";
import GlobalStyle from "./components/Styles/globalStyles";
import Modal from "./components/Modals/Modal";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const userId = useSelector(selectUserId);
  const token = useSelector(selectJwtToken);

  useEffect(() => {
    socketInit(userId, token);
  }, [userId, token]);
  return (
    <Router history={history}>
      <Helmet>
        <title>{i18next.t("app.title")}</title>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <Header />
        <GlobalStyle />
        <Modal />
        <ToastContainer bodyClassName="ToastBody" />
        <AppRoutes />
      </StyledEngineProvider>
    </Router>
  );
};

export default App;

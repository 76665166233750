import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchCategories = () =>
  getRequest(apiEndpoints.offers.categories);

export const attemptAddNewCategory = (payload) =>
  postRequest(apiEndpoints.admin.categories.newCategory, payload.body);

export const attemptEditCategory = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.admin.categories.editCategory, {
      categoryId: payload.categoryId,
    }),
    payload.body
  );
  
export const attemptDeleteCategory = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.admin.categories.deleteCategory, {
      categoryId: payload.categoryId,
    })
  );

export const attemptAddNewSubcategory = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.admin.subcategories.newSubcategory, {
      categoryId: payload.categoryId,
    }),
    payload.body
  );
export const attemptEditSubcategory = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.admin.subcategories.editSubcategory, {
      categoryId: payload.categoryId,
      subcategoryName: payload.subcategoryName,
    }),
    payload.body
  );
export const attemptDeleteSubcategory = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.admin.subcategories.deleteSubcategory, {
      categoryId: payload.categoryId,
      subcategoryName: payload.subcategoryName,
    })
  );

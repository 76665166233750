import React from "react";
import PropTypes from "prop-types";
import { DrawerButton, DrawerOption } from "../../Drawer.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { MyUsername, UserIcon } from "./MyProfileButton.styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProfileName } from "../../../../../store/selectors/profileSelectors";
import { selectUserId } from "../../../../../store/selectors/loginSelectors";
import history from "../../../../../store/utils/history";
import { PROFILE_PAGE } from "../../../../../constants/pages";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";

const MyProfileButton = (props) => {
  const name = useSelector(selectProfileName);
  const userId = useSelector(selectUserId);
  const { t } = useTranslation();
  const handleClick = () => {
    props.toggleDrawer();
    history.push(
      replaceInRoute(PROFILE_PAGE, {
        profileId: userId,
      })
    );
  };
  return (
    <DrawerButton onClick={handleClick}>
      <IconButton sx={{ borderRadius: "4px" }}>
        <UserIcon />
      </IconButton>
      <DrawerOption>{t("header.myProfile")}</DrawerOption>
      <MyUsername>({name})</MyUsername>
    </DrawerButton>
  );
};

MyProfileButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default MyProfileButton;

import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { selectOffer } from '../../../store/selectors/offersSelectors';
import { useState } from 'react';
import { useEffect } from 'react';
import { clearSelectedOffer, fetchOneOffer } from '../../../store/actions/offers/offersActions';
import { AdminItemDetailsPageContainer } from './AdminItemDetailsPage.styled';
import ItemDetailsLayout from '../../../layouts/ItemDetailsLayout/ItemDetailsLayout';
import ItemDetails from '../../../components/ItemDetails/ItemDetails';
import ProfileMini from '../../../components/ProfileMini/ProfileMini';
import UserReviews from '../../../components/UserReviews/UserReviews';

const AdminItemDetailsPage = (props) => {
    const dispatch = useDispatch();
    const selectedOffer = useSelector(selectOffer);
    const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
    const offerId = props.match.params?.offerId;
  
    useEffect(() => {
      dispatch(fetchOneOffer(offerId));
      () => dispatch(clearSelectedOffer());
    }, []);
  
    useEffect(() => {
      if (!selectedOffer?.offer && isInitiallyLoaded) {
        dispatch(fetchOneOffer(offerId));
      }
      if (selectedOffer?.offer) {
        setIsInitiallyLoaded(true);
      }
    }, [selectedOffer?.offer]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behaviour: "smooth" });
    }, []);
  
    return (
      <AdminItemDetailsPageContainer>
        <ItemDetailsLayout
          singleOffer
          content={<ItemDetails singleOffer isAdmin />}
          rightCard={
            <>
              <ProfileMini /> <UserReviews isAdmin rightReviews />
            </>
          }
        />
      </AdminItemDetailsPageContainer>
    );
  };
  
  AdminItemDetailsPage.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        offerId: PropTypes.string,
      }),
    }),
  };

export default AdminItemDetailsPage
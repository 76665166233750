import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DirectChatNewMessageContainer,
  NewMessageField,
  SendButton,
} from "./DirectChatNewMessage.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../../socket/socket";
import { useSelector } from "react-redux";
import { selectJwtToken, selectUserId } from "../../../store/selectors/loginSelectors";
import {
  addNewMessage,
  startNewChat,
} from "../../../store/actions/chat/chatActions";
import { useHistory, useLocation } from "react-router-dom";
import { selectExchange } from "../../../store/selectors/exchangeSelector";
import { validateExchange } from "../../../store/actions/exchange/exchangeActions";
import NotAllowedChat from "./NotAllowedChat/NotAllowedChat";
import { convertLocalDateToUTCDate } from "../../../util/helpers/dateHelpers";
import { selectAmIBlocked } from "../../../store/selectors/profileSelectors";

const DirectChatNewMessage = (props) => {
  const [typedValue, setTypedValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const exchange = useSelector(selectExchange);
  const dispatch = useDispatch();
  const mineProfileBlocked = useSelector(selectAmIBlocked);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const token = useSelector(selectJwtToken)
  const userId = useSelector(selectUserId);
  const handleSend = useCallback(
    (newChatId = undefined) => {
      if (typedValue?.length === 0) return;
      if (props?.chat?._id) {
        const chatId = props.chat?._id || newChatId;
        sendMessage(chatId, userId, typedValue, props.interlocutor._id, token);
        dispatch(
          addNewMessage({
            _id: chatId,
            message: {
              user: {
                _id: userId,
              },
              text: typedValue,
              _created: convertLocalDateToUTCDate(new Date()),
            },
          })
        );
        if (props.chat?._id) {
          if (!exchange.valid && exchange.seller.user._id === userId) {
            dispatch(validateExchange(exchange._id));
          }
        }
      } else {
        initiateNewChat(typedValue);
      }
      setTypedValue("");
    },
    [typedValue, props.chat?._id, userId, props.interlocutor]
  );
  const handleMessageSendSuccess = (newChatId) => {
    history.replace(`${newChatId}`);
  };

  const listener = useCallback(
    (event) => {
      // Event key code 13 = ENTER key
      if (event.keyCode === 13) handleSend();
    },
    [isFocused, typedValue]
  );

  useEffect(() => {
    if (isFocused) window.addEventListener("keypress", listener);
    return () => window.removeEventListener("keypress", listener);
  }, [typedValue, isFocused]);

  const initiateNewChat = (typedValue) => {
    const offerId = location.state.offerId;
    dispatch(
      startNewChat({
        offerId,
        message: typedValue,
        interlocutorUserId: props.interlocutor._id,
        handleMessageSendSuccess,
      })
    );
  };
  console.log(props)
  if (mineProfileBlocked) {
    return <NotAllowedChat mineProfileBlocked />;
  }
  if (props?.interlocutor?._deleted) {
    return <NotAllowedChat deleted />;
  }
  if (props?.chat?.offer?._deleted) {
    return <NotAllowedChat />;
  }
  // if (props?.interlocutor?._blocked) {
  //   return <NotAllowedChat blocked />;
  // }
  return (
    <>
      <DirectChatNewMessageContainer>
        <NewMessageField
          placeholder={t("messages.sendPlaceholder")}
          fullWidth
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          italicPlaceholder
          value={typedValue}
          onChange={(typed) => setTypedValue(typed.target.value)}
        />
        <SendButton
          onClick={handleSend}
          buttoncolor={selectedTheme.colors.primaryPurple}
          variant="contained"
        >
          {t("messages.send")}
        </SendButton>
      </DirectChatNewMessageContainer>
    </>
  );
};

DirectChatNewMessage.propTypes = {
  children: PropTypes.node,
  chatId: PropTypes.any,
  refreshChat: PropTypes.func,
  interlocutor: PropTypes.any,
  chat: PropTypes.any,
};

export default DirectChatNewMessage;

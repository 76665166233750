import styled from "styled-components";
import { Box } from "@mui/system";
import {
  BackgroundTransition,
  ItemsTransition,
} from "../../../Styles/globalStyleComponents";

export const SkeletonChatContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 19px;
`;

export const SkeletonChatContentHeader = styled(BackgroundTransition)`
  display: flex;
  padding: 17px 35px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const SkeletonChatContentHeaderFirstColumn = styled(Box)`
  display: flex;
`;

export const SkeletonChatContentHeaderFirstColumnImage = styled(
  ItemsTransition
)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  @media (max-width: 600px) {
    width: 72px;
    height: 72px;
  }
`;

export const SkeletonChatContentHeaderFirstColumnInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

export const SkeletonChatContentHeaderFirstColumnFirstLine = styled(
  ItemsTransition
)`
  width: 124px;
  height: 18px;
  margin-bottom: 15px;
`;

export const SkeletonChatContentHeaderFirstColumnSecondLine = styled(
  ItemsTransition
)`
  width: 72px;
  height: 14px;
  @media (max-width: 600px) {
    width: 141px;
  }
`;

export const SkeletonChatContentHeaderSecondColumnImageSmall = styled(
  ItemsTransition
)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 17px;
    right: 17px;
  }
`;

export const SkeletonChatContentMain = styled(ItemsTransition)`
  padding: 27px 18px 18px 18px;
`;

export const SkeletonChatContentMainRow = styled(Box)`
  display: flex;
  clear: both;

  &:nth-child(even) {
    float: right;
  }
  @media (max-width: 600px) {
    &:nth-child(5) {
      display: none;
    }
  }
`;

export const SkeletonChatContentMainImage = styled(BackgroundTransition)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonChatContentMainLeftRowInfo = styled(BackgroundTransition)`
  display: flex;
  flex-direction: column;
  padding: 9px;
  align-items: flex-start;
  margin-left: 18px;
  margin-bottom: 18px;
  border-radius: 0px 9px 9px 9px;
  @media (max-width: 600px) {
    &:nth-child(1) {
      display: none;
    }
    margin-left: 0px;
  }
`;

export const SkeletonChatContentMainFirstRowInfoFirstLine = styled(
  ItemsTransition
)`
  width: 715px;
  height: 18px;
  margin-bottom: 15px;

  @media (max-width: 1200px) {
    width: 480px;
  }

  @media (max-width: 600px) {
    width: calc(100vw - 86px);
  }
`;

export const SkeletonChatContentMainFirstRowInfoSecondLine = styled(
  ItemsTransition
)`
  width: 72px;
  height: 14px;
`;

export const SkeletonChatContentMainRightRowInfo = styled(BackgroundTransition)`
  display: flex;
  flex-direction: column;
  padding: 9px;
  align-items: flex-end;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 9px 0px 9px 9px;
  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

export const SkeletonChatContentSecondRowFirstLine = styled(ItemsTransition)`
  width: 354px;
  height: 18px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    width: 232px;
  }
`;

export const SkeletonChatContentSecondRowSecondLine = styled(ItemsTransition)`
  width: 72px;
  height: 14px;
`;

export const SkeletonChatContentThirdRowFirstLine = styled(ItemsTransition)`
  width: 394px;
  height: 18px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    width: 208px;
  }
`;

export const SkeletonChatContentThirdRowSecondLine = styled(ItemsTransition)`
  width: 93px;
  height: 14px;
  @media (max-width: 600px) {
    width: 72px;
  }
`;

export const SkeletonChatContentFourthRowFirstLine = styled(ItemsTransition)`
  width: 101px;
  height: 18px;
  margin-bottom: 15px;
  @media (max-width: 600px) {
    width: 110px;
  }
`;

export const SkeletonChatContentFourthRowSecondLine = styled(ItemsTransition)`
  width: 72px;
  height: 14px;
`;

export const SkeletonChatContentFifthRowFirstLine = styled(ItemsTransition)`
  width: 131px;
  height: 18px;
  margin-bottom: 15px;
`;

export const SkeletonChatContentFifthRowSecondLine = styled(ItemsTransition)`
  width: 87px;
  height: 14px;
`;

export const SkeletonChatContentHorizontalLine = styled(BackgroundTransition)`
  width: 100%;
  height: 1px;
  margin: 9px 0 18px 0;
  @media (max-width: 600px) {
    margin-top: 90px;
  }
`;

export const SkeletonChatContentVerticalLine = styled(BackgroundTransition)`
  width: 5px;
  height: 180px;
  position: absolute;
  top: 490px;
  right: 35px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonChatContentFooter = styled(Box)`
  display: flex;
`;

export const SkeletonChatContentFooterFirstColumn = styled(
  BackgroundTransition
)`
  flex: 1;
  padding: 17px 16px;
  margin-right: 36px;
`;

export const SkeletonChatContentFooterColumnInside = styled(ItemsTransition)`
  width: 108px;
  height: 14px;

  @media (max-width: 600px) {
    width: 71px;
  }
`;

export const SkeletonChatContentFooterSecondColumn = styled(
  BackgroundTransition
)`
  padding: 17px 36px;
  @media (max-width: 600px) {
    padding-right: 0;
    padding-left: 21px;
    width: 113px;
  }
`;

// import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { ReactComponent as Remove } from "../../../../../assets/images/svg/trash.svg";
import selectedTheme from "../../../../../themes";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";

export const RemoveButtonContainer = styled(IconButton)`
  position: absolute;
  top: ${(props) =>
    props.hasGivenReview && props.isProfileReviews ? "79px" : "16px"};
  right: 16px;
  background-color: ${(props) =>
    props.isRemoved
      ? "transparent"
      : selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  width: 32px;
  height: 32px;
  ${(props) =>
    props.isRemoved &&
    css`
      & button:hover {
        background-color: transparent;
        cursor: auto;
      }
    `}
  & button {
    width: 32px;
    height: 32px;
  }
`;
export const RemoveIcon = styled(Remove)`
  & path {
    stroke: ${(props) => props.isRemoved && selectedTheme.colors.blockedColor};
  }
`;

export default (informations, offer, images) => ({
  images: informations?.image || offer?.images || images,
  condition:
    informations?.condition ||
    (typeof offer?.condition === "string" &&
      offer?.condition?.charAt(0)?.toUpperCase() +
        offer?.condition?.slice(1)) ||
    "default",
  type: informations?.type || offer?.type || "default",
});

import React from "react";
import PropTypes from "prop-types";
import {
  LabeledCardContainer,
  LabeledCardIconContainer,
} from "./LabeledCard.styled";

const LabeledCard = (props) => {
  return (
    <LabeledCardContainer width={props.width} height={props.height}>
      <LabeledCardIconContainer>{props.icon}</LabeledCardIconContainer>
      {props.children}
    </LabeledCardContainer>
  );
};

LabeledCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LabeledCard;

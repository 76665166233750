import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditIcon, RemoveIcon } from "../Cards/OfferCard/OfferCard.styled";
import {
  toggleDeleteOfferModal,
  toggleEditOfferModal,
} from "../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";
import { MenuItemText } from "./MenuModal.styled";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

const MenuModal = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const openEditOfferModal = (event) => {
    event.stopPropagation();
    dispatch(
      toggleEditOfferModal({
        editOffer: true,
        offer: props.offer,
        isAdmin: props.isAdmin,
        customUserId: props?.offer?.user._id,
      })
    );
    setAnchorEl(null);
  };

  const openRemoveModal = (event) => {
    event.stopPropagation();
    dispatch(
      toggleDeleteOfferModal({
        offer: props.offer,
        isAdmin: props.isAdmin,
      })
    );
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={
          props?.offer?.pinned
            ? { border: "1px solid #5A3984", width: "30px", height: "30px" }
            : { backgroundColor: "#E4E4E4", width: "30px", height: "30px" }
        }
      >
        {/* <MoreIcon /> */}
        <MoreVertIcon style={{ color: "#5A3984", scale: "0.8" }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "15ch",
          },
        }}
      >
        <MenuItem key={1} onClick={openEditOfferModal}>
          <EditIcon />
          <MenuItemText>{t("admin.categories.edit.save")}</MenuItemText>
        </MenuItem>
        <MenuItem key={2} onClick={openRemoveModal}>
          <RemoveIcon />
          <MenuItemText>{t("admin.subcategories.delete")}</MenuItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

MenuModal.propTypes = {
  offer: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default MenuModal;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { routeMatches } from "../../../../../../util/helpers/routeHelpers";
import { useLocation } from "react-router-dom";
import {
  AdminButtonContainer,
  AdminButtonIcon,
  AdminButtonText,
} from "./AdminButton.styled";
import {
  ADMIN_HOME_PAGE,
  ADMIN_USERS_PAGE,
} from "../../../../../../constants/pages";
import history from "../../../../../../store/utils/history";
import { useDispatch } from "react-redux";
import { setManualSearchString } from "../../../../../../store/actions/filters/filtersActions";

const AdminButton = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSelected = useMemo(() => {
    if (routeMatches(ADMIN_HOME_PAGE) && props.route === ADMIN_USERS_PAGE)
      return true;
    return routeMatches(props.route);
  }, [props.route, location.pathname]);
  const goToRoute = () => {
    dispatch(setManualSearchString(""));
    history.push(props.route);
    props.toggleDrawer();
  };
  return (
    <AdminButtonContainer onClick={goToRoute} isSelected={isSelected}>
      <AdminButtonIcon isSelected={isSelected}>{props.icon}</AdminButtonIcon>
      <AdminButtonText isSelected={isSelected}>{props.title}</AdminButtonText>
    </AdminButtonContainer>
  );
};

AdminButton.propTypes = {
  icon: PropTypes.node,
  route: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  toggleDrawer: PropTypes.func,
};

export default AdminButton;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  FormContainer,
  // RegisterDescription,
} from "./SecondPartOfRegistration.styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components/TextFields/TextField/TextField";
import { PrimaryButton } from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import selectedTheme from "../../../../themes";
import { useDispatch } from "react-redux";
import { fetchCheckIdNumberExist } from "../../../../store/actions/register/registerActions";

const SecondPartOfRegistration = (props) => {
  const { t } = useTranslation();
  const [PIBTakenStatus, setPIBTakenStatus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.error.length > 0) {
      setPIBTakenStatus(true);
    }
  }, [props.error]);

  useEffect(() => {
    if (props.informations?.nameOfFirm) {
      formik.setFieldValue("nameOfFirm", props.informations?.nameOfFirm);
      formik.setFieldValue("idNumber", props.informations.idNumber);
    }
  }, [props.informations]);

  const handleResponseSuccess = (values) => {
    props.handleSubmit(values);
  };

  const handleApiResponseError = () => {
    setPIBTakenStatus(true);
  };

  const handleSubmit = (values) => {
    dispatch(
      fetchCheckIdNumberExist({
        values,
        handleApiResponseSuccess: () => handleResponseSuccess(values),
        handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      nameOfFirm: props.informations?.nameOfFirm ?? "",
      idNumber: props.informations?.idNumber ?? "",
    },
    validationSchema: Yup.object().shape({
      nameOfFirm: Yup.string().required(t("login.usernameRequired")),
      idNumber: Yup.string()
        .required(t("login.passwordRequired"))
        .min(8, t("register.PIBnoOfCharacters"))
        .max(8, t("register.PIBnoOfCharacters")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  console.log(props);
  return (
    <FormContainer component="form" onSubmit={formik.handleSubmit}>
      {/* <RegisterDescription component="p" variant="p">
        {t("register.descriptionSecond")}
      </RegisterDescription> */}

      <TextField
        name="nameOfFirm"
        placeholder={t("common.labelFirm")}
        margin="normal"
        value={formik.values.nameOfFirm}
        onChange={formik.handleChange}
        error={
          (formik.touched.nameOfFirm && Boolean(formik.errors.nameOfFirm)) ||
          props?.companyNameError
        }
        helperText={formik.touched.nameOfFirm && formik.errors.nameOfFirm}
        autoFocus
        fullWidth
      />

      <TextField
        name="idNumber"
        placeholder={t("common.labelPIB")}
        margin="normal"
        type="number"
        value={formik.values.idNumber}
        onChange={(event) => {
          formik.setFieldValue("idNumber", event.target.value);
          setPIBTakenStatus(false);
        }}
        error={
          (formik.touched.idNumber && Boolean(formik.errors.idNumber)) ||
          PIBTakenStatus
        }
        helperText={formik.touched.idNumber && formik.errors.idNumber}
        fullWidth
      />
      {formik.errors.idNumber && formik.touched.idNumber && (
        <ErrorMessage>{formik.errors.idNumber}</ErrorMessage>
      )}
      {(props.error || props?.companyNameError || PIBTakenStatus) && (
        <ErrorMessage>
          {props.errorMessage ||
            props?.companyNameError ||
            t("register.PIBTaken")}
        </ErrorMessage>
      )}

      <PrimaryButton
        type="submit"
        variant="contained"
        height="48px"
        fullWidth
        buttoncolor={selectedTheme.colors.primaryPurple}
        textcolor="white"
        disabled={
          formik.values.idNumber.length === 0 ||
          formik.values.nameOfFirm.length === 0 ||
          formik.values.idNumber.toString() === props.error
        }
      >
        {t("common.continue")}
      </PrimaryButton>
    </FormContainer>
  );
};

SecondPartOfRegistration.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  informations: PropTypes.any,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  companyNameError: PropTypes.string,
};

export default SecondPartOfRegistration;

import React from "react";
import PropTypes from "prop-types";
import {
  ContentRightCardContainer,
  Content,
  RightCard,
  ItemDetailsLayoutContainer,
} from "./ItemDetailsLayout.styled";

const ItemDetailsLayout = (props) => {
  return (
    <ItemDetailsLayoutContainer
      singleOffer={props.singleOffer}
      profile={props.profile}
    >
      {props.children}
      <ContentRightCardContainer container spacing={2} >
        <Content sm={12} md={7} lg={7} item>{props.content}</Content>
        <RightCard sm={0} md={5} lg={5} item singleOffer={props.singleOffer} profile={props.profile}>
          {props.rightCard}
        </RightCard>
      </ContentRightCardContainer>
    </ItemDetailsLayoutContainer>
  );
};

ItemDetailsLayout.propTypes = {
  children: PropTypes.node,
  leftCard: PropTypes.node,
  content: PropTypes.node,
  rightCard: PropTypes.node,
  singleOffer: PropTypes.bool,
  profile: PropTypes.bool,
};

export default ItemDetailsLayout;

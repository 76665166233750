import styled from "styled-components";
import { Box } from "@mui/system";

export const CloseIcon = styled(Box)`
  cursor: pointer;
  position: absolute;
  right: 40px;

  @media screen and (max-width: 600px) {
    right: 20px;

    & svg {
      width: 20px;
    }
  }
`;

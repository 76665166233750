import { Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const RegisterSuccessfulContainer = styled(Container)`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 536px;
  height: 500px;
  padding: 0;
  & div {
    height: 500px !important;
  }
  & div canvas {
    width: 500px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 70px;
    & div {
      width: 230px !important;
    }
  }
`;
export const RegisterTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 430px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 39px;
  line-height: 48px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 32px;
  @media (max-width: 600px) {
    width: 303px;
    font-size: 36px;
    line-height: 44px;
  }
`;
export const RegisterDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 36px;
  width: 430px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  display: block;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
  @media (max-width: 600px) {
    width: 241px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 9px;
  }
`;
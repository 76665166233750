import React from "react";
import PropTypes from "prop-types";
import {
  DetailContainer,
  DetailIcon,
  DetailText,
  LocationIcon,
} from "./CategoryDetail.styled";
import selectedTheme from "../../../../../themes";

const CategoryDetail = (props) => {
  const offer = props.offer;
  return (
    <DetailContainer shouldHideResponsive>
      <DetailIcon color={selectedTheme.colors.iconStrokeColor} size="22px">
        <LocationIcon />
      </DetailIcon>
      <DetailText ismyprofile={props.isMyProfile}>
        {offer?.user?.company?.contacts?.location}
      </DetailText>
    </DetailContainer>
  );
};

CategoryDetail.propTypes = {
  offer: PropTypes.any,
  isMyProfile: PropTypes.bool,
};

export default CategoryDetail;

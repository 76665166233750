import { Link } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const LinkStyled = styled(Link)`
  cursor: pointer;
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${(props) => props.font};
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.textsize ? props.textsize : "14px"};
  line-height: ${props => props.lineheight ? props.lineheight : "14px"};
  text-decoration-line: underline;
  display: block;
  text-align: ${props => props.align === "right" ? "right" : (props.align === "center" ? "center" : "left")};
  /* ${props => props.align === "right" && "display: block; text-align: right;"}
  ${props => props.align === "center" && "display: block; text-align: center;"} */
`;

import { Box, IconButton } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const IconButtonContainer = styled(Box)``;

export const IconButtonStyled = styled(IconButton)`
  height: ${(props) => (props.height ? props.height : "36px")};
  width: ${(props) => (props.width ? props.width : "36px")};
  padding: 0;
  ${(props) =>
    props.iconcolor &&
    `
        & svg path {
            stroke: ${props.iconcolor};
        }
    `}
  border: ${(props) =>
    props.border
      ? "1px solid " + selectedTheme.colors.backgroundSponsoredColor
      : "none"}
`;

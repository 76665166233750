import { ReactComponent as DownArrow } from "../../../assets/images/svg/arrow-down.svg";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { IconButton } from "../IconButton/IconButton";

export const ArrowIcon = styled(DownArrow)`
  ${(props) =>
    props.side === "left" &&
    `
    transform: rotate(180deg);
`}
  width: 18px;
  height: 18px;
  position: relative;
  top: 1px;
  left: 1px;
  & path {
    ${(props) =>
      props.disabled &&
      `
    stroke: ${selectedTheme.colors.iconStrokeDisabledColor}
    `}
  }
  @media (max-width: 600px) {
    top: -3px;
    left: -2px;
  }
`;
export const ArrowContainer = styled(IconButton)`
  border: 1px solid ${selectedTheme.colors.primaryPurple} !important;
  border-radius: 100%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.2s all ease;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple};
    & svg path {
      stroke: white;
    }
  }
  ${(props) =>
    props.disabled &&
    css`
    border 1px solid ${selectedTheme.colors.iconStrokeDisabledColor} !important;
    &:hover {
        background-color: inherit;
        & svg path {
            stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
        }
    }
    & svg path {
        stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
        transition: 0.2s all ease;
        
    }
    
`}

  @media screen and (max-width: 600px) {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
`;

import {
  PROFILE_CLEAR,
  PROFILE_ERROR,
  PROFILE_FETCH,
  PROFILE_MINE_FETCH,
  PROFILE_MINE_SET,
  PROFILE_SET,
  PROFILE_SUCCESS,
  PROFILE_EDIT,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_ERROR,
  PROFILE_EDIT_ADMIN,
  PROFILE_EDIT_ADMIN_SUCCESS,
  PROFILE_EDIT_ADMIN_ERROR,
  PROFILE_MINE_FETCH_SUCCESS,
  PROFILE_MINE_FETCH_ERROR,
  PROFILE_ALL_FETCH,
  PROFILE_ALL_SUCCESS,
  PROFILE_ALL_ERROR,
  PROFILE_ALL_ADMIN_FETCH,
  PROFILE_ALL_ADMIN_SUCCESS,
  PROFILE_ALL_ADMIN_ERROR,
  PROFILE_ALL_SET,
} from "./profileActionConstants";

export const fetchProfile = (payload) => ({
  type: PROFILE_FETCH,
  payload,
});
export const fetchProfileSuccess = (payload) => ({
  type: PROFILE_SUCCESS,
  payload,
});
export const fetchErrorProfile = (payload) => ({
  type: PROFILE_ERROR,
  payload,
});

export const fetchAllProfiles = (payload) => ({
  type: PROFILE_ALL_FETCH,
  payload,
});
export const fetchAllProfilesSuccess = (payload) => ({
  type: PROFILE_ALL_SUCCESS,
  payload,
});
export const fetchAllProfilesError = (payload) => ({
  type: PROFILE_ALL_ERROR,
  payload,
});

export const fetchAllProfilesAsAdmin = (payload) => ({
  type: PROFILE_ALL_ADMIN_FETCH,
  payload,
});
export const fetchAllProfilesAsAdminSuccess = (payload) => ({
  type: PROFILE_ALL_ADMIN_SUCCESS,
  payload,
});
export const fetchAllProfilesAsAdminError = (payload) => ({
  type: PROFILE_ALL_ADMIN_ERROR,
  payload,
});

export const fetchMineProfile = () => ({
  type: PROFILE_MINE_FETCH,
});
export const fetchMineProfileSuccess = () => ({
  type: PROFILE_MINE_FETCH_SUCCESS,
});
export const fetcHMineProfileError = () => ({
  type: PROFILE_MINE_FETCH_ERROR,
});

export const editProfile = (payload) => ({
  type: PROFILE_EDIT,
  payload,
});
export const editProfileSuccess = () => ({
  type: PROFILE_EDIT_SUCCESS,
});
export const editProfileError = () => ({
  type: PROFILE_EDIT_ERROR,
});

export const editProfileAsAdmin = (payload) => ({
  type: PROFILE_EDIT_ADMIN,
  payload,
});
export const editProfileAsAdminSuccess = () => ({
  type: PROFILE_EDIT_ADMIN_SUCCESS,
});
export const editProfileAsAdminError = () => ({
  type: PROFILE_EDIT_ADMIN_ERROR,
});

export const clearProfile = () => ({
  type: PROFILE_CLEAR,
});
export const setProfile = (payload) => ({
  type: PROFILE_SET,
  payload,
});
export const setAllProfiles = (payload) => ({
  type: PROFILE_ALL_SET,
  payload,
});
export const setMineProfile = (payload) => ({
  type: PROFILE_MINE_SET,
  payload,
});

import styled from "styled-components";
import { Box, TextField, Typography } from "@mui/material";
import ImagePicker from "../../../ImagePicker/ImagePicker";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";
import { Label } from "../../../CheckBox/Label";
import selectedTheme from "../../../../themes";
import { PrimaryAnimatedButton } from "../../../Styles/globalStyleComponents";

export const EditProfileContainer = styled(Box)`
  background-color: #fff;
  position: fixed;
  top: calc(50vh - 390px);
  left: calc(50% - 310px);
  z-index: 150;
  padding: 36px 144px;
  width: 623px;
  min-height: 781px;
  max-height: 802px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
  @media (max-height: 800px) {
    height: 90vh;
    top: 5vh;
  }

  @media screen and (max-width: 600px) {
    height: 100vh;
    max-height: 100vh;
    min-height: 90vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 38px 18px;
  }
`;

export const ProfileImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileImagePicker = styled(ImagePicker)`
  background: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%235A3984FF' stroke-width='2' stroke-dasharray='7%2c 12' stroke-dashoffset='44' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
`;

export const ProfileHeader = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 24px;
  font-weight: 700;
  margin-top: 9px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const BackButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 40px;
  left: 40px;

  @media screen and (max-width: 600px) {
    left: 22px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CloseButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 42px;
  right: 42px;

  @media screen and (max-width: 600px) {
    right: 22px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const InputFieldLabel = styled(Label)`
  position: relative;
  bottom: -14px;

  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #808080;
    cursor: auto;
    letter-spacing: 0.2px;
    ${(props) => props.labelWebsite && `margin-top: 8px`}
  }

  @media screen and (max-width: 600px) {
    & label {
      font-size: 9px;
      margin-top: 0;
    }
  }
`;

export const InputField = styled(TextField)`
  & input {
    padding: 10px 16px;
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    & input {
      padding: 12px 18px;
      font-size: 12px;
    }
  }
`;

export const SaveButton = styled(PrimaryButton)`
  font-size: 12px;
  letter-spacing: 1.5px;

  @media (max-width: 375px) {
    width: 100%;
  }
`;
export const FinishButton = styled(PrimaryAnimatedButton)`
  font-size: 12px;
  letter-spacing: 1.5px;

  @media (max-width: 375px) {
    width: 100%;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  margin-top: 28px;

  @media screen and (max-width: 600px) {
    margin-top: 100px;
    justify-content: space-between;
  }
`;

export const BasicInfo = styled(Box)``;

export const DetailsInfo = styled(Box)``;

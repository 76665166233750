export const conditionSelectEnum = {
  NEW: {
    value: 1,
    mainText: "Novo",
    altText: "",
  },
  USED: {
    value: 2,
    mainText: "Polovno",
    altText: " (korišćeno)",
  },
  LIKE_NEW: {
    value: 3,
    mainText: "Kao novo",
    altText: " (nekorišćeno)",
  },
};

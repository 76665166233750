import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

const ADMIN_SCOPE = "ADMIN";
export const ADMIN_FETCH = createFetchType(ADMIN_SCOPE);
export const ADMIN_FETCH_SUCCESS = createSuccessType(ADMIN_SCOPE);
export const ADMIN_FETCH_ERROR = createErrorType(ADMIN_SCOPE);
export const ADMIN_QUERY_STRING = createSetType("ADMIN_QUERY_STRING");

import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import selectedTheme from "../../../../themes";

export const ProfileStatsContainer = styled(Grid)`
  display: flex;
  justify-content: start;
  flex-direction: row;
  background: ${(props) =>
    props.isBlocked
      ? selectedTheme.colors.borderNormal
      : selectedTheme.colors.primaryDarkTextSecond};
  width: calc(100% + 36px);
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: -1rem;
  margin-left: -18px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  /* border-radius: 0 0 4px 4px; */
`;
export const ProfileStatsGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 3px;
`;
export const StatsItem = styled(Typography)`
  margin-right: 2rem;
  display: unset;
  margin-left: 1rem;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  margin-bottom: 2px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

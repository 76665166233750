import { Box } from "@mui/system";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const AboutPageContainer = styled(Box)`
    margin-top: 64px;
    background-color: ${selectedTheme.colors.staticBackgroundColor};
    position: relative;
    @media (max-width: 600px) {
        margin-top: 53px;
    }
`
import React from "react";
import PropTypes from "prop-types";
import {
  ReviewCard,
  SecondStepCreateReviewContainer,
} from "./SecondStepCreateReview.styled";
import { CreateReviewTitle } from "../CreateReview.styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMineProfile } from "../../../store/selectors/profileSelectors";
import NextButton from "./NextButton/NextButton";

const SecondStepCreateReview = (props) => {
  const { t } = useTranslation();
  const mineProfile = useSelector(selectMineProfile);

  const goToNextStep = () => {
    if (props.removingReview) props.closeModal();
    else props.goToNextStep();
  };

  return (
    <SecondStepCreateReviewContainer>
      <CreateReviewTitle>
        {props.removingReview
          ? t("admin.reviews.title")
          : t("reviews.modalTitle")}
      </CreateReviewTitle>
      <ReviewCard
        givingReview={{
          name: mineProfile?.company?.name,
          image: mineProfile?.image,
          offerName: props?.offer?.name,
          offerImage: props?.offer?.images[0],
          isGoodCommunication: props.review?.correctCommunication,
          isSuccessfulSwap: props.review?.exchangeSucceed,
          quote: props.review.comment,
        }}
      />
      <NextButton
        removingReview={props.removingReview}
        onClick={goToNextStep}
      />
    </SecondStepCreateReviewContainer>
  );
};

SecondStepCreateReview.propTypes = {
  children: PropTypes.node,
  review: PropTypes.any,
  offer: PropTypes.any,
  interlocutor: PropTypes.any,
  goToNextStep: PropTypes.func,
  removingReview: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default SecondStepCreateReview;

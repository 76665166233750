export default {
  accounts: {
    get: "accounts/{accountUid}",
    forgotPassword: "forgot-password",
    resetPassword: "reset-password",
    getCurrentUserPermissions:
      "accounts/{currentAccountUid}/users/{currentUserUid}/permissions",
    getAddresses: "accounts/{accountUid}/addresses",
    updateAddress: "account/{accountUid}/addresses/{addressUid}",
    deleteAddress: "accounts/{accountUid}/addresses/{addressUid}",
    getUsers: "accounts/{accountUid}/users",
    register: "users",
    updateUser: "account/{accountUid}/users/{userUid}?actionType={actionType}",
    deleteUser: "accounts/{accountUid}/users/{userUid}",
    getSettings: "accounts/{accountUid}/settings",
    getIraSettings: "accounts/{accountUid}/iraSettings",
    getSettingsRegistration: "application/settings",
    agreement: "accounts/agreement",
  },
  authentications: {
    getUsernames: "authenticate/usernames",
    login: "auth/token",
    logout: "auth/logout",
    getUserSecurityQuestion: "users/username/securityquestion",
    confirmSecurityQuestion: "authenticate/confirm",
    confirmForgotPassword: "users/passwords/reset_token",
    resetPassword: "auth/reset-password",
    forgotPassword: "auth/forgot-password",
    refreshToken: "/auth/refresh",
    generateToken: "/authenticate/generate",
    authenticate:
      "/authenticate?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}",
    confirmAuthentication:
      "/authenticate/confirm?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}",
  },
  bankAccounts: {
    get: "accounts/{accountUid}/bankaccounts",
    getBankAccount:
      "accounts/{accountUid}/bankaccounts/{bankAccountUid}?type={type}",
    getBankAccountsByType:
      "accounts/{accountUid}/bankaccounts?type={type}&active=true",
    getBankDetailsByRoutingNumber: "banks/{routingNumber}",
    newAccount: "accounts/{accountUid}/bankaccounts",
    deleteAccount:
      "accounts/{accountUid}/bankaccounts/{bankAccountUid}?type={type}",
    verify: "/accounts/{accountUid}/bankaccountverification/{bankAccountUid}",
    postBankAccountRegistration: "/accounts/{applicationUid}/bankaccounts",
    getRegistration: "banks/{applicationUid}/bankaccounts",
  },
  documents: {
    getDocuments: "accounts/{accountUid}/documents?year={year}",
    getDocument: "accounts/{accountUid}/documents/{documentType}",
  },
  countries: "/countries",
  metalStream: {
    getMetalStreamSettings: "accounts/{accountUid}/metalstream",
    getMetalStreamFundings: "applications/{applicationUid}/metalStreamFunding",
  },
  orders: {
    buyForStorage: "/accounts/{accountUid}/orders/buyForStorage",
    buyForDelivery: "/accounts/{accountUid}/orders/buyForDelivery",
    verifyBuyForDelivery: "/accounts/{accountUid}/orders/buyForDelivery/verify",
    sellFromStorage: "/accounts/{accountUid}/orders/sellFromStorage",
    fractionalConversion: "/accounts/{accountUid}/orders/fractionalConversion",
    deliverFromStorageVerify:
      "/accounts/{accountUid}/orders/deliverFromStorage/verify",
    deliverFromStorage: "/accounts/{accountUid}/orders/deliverFromStorage",
    iraCashDistribution: "/accounts/{accountUid}/orders/iraCashDistribution",
    iraCashTransfer: "/accounts/{accountUid}/orders/iraCashTransfer",
    iraFeeWithdrawal: "accounts/{accountUid}/orders/iraFeeWithdrawal",
    achDeposit: "accounts/{accountUid}/orders/achDeposit",
    wireWithdrawal: "/accounts/{accountUid}/orders/wireWithdrawal",
    checkWithdrawal: "/accounts/{accountUid}/orders/checkWithdrawal",
  },
  portfolio: {
    getPortfolioValuations: "accounts/{accountUid}/portfolio/valuations",
    getPortfolioMetalPrices: "marketprices",
    getPortfolioHoldings:
      "accounts/{accountUid}/portfolio/products?valuation=true",
    getPortfolioProductCodes: "/accounts/{accountUid}/portfolio/productcodes",
    getPortfolioBalances: "/accounts/{accountUid}/portfolio/balances",
    getPortfolioProductBySymbol:
      "/accounts/{accountUid}/portfolio/products/{symbol}",
    getPortfolioTransactions: "/accounts/{accountUid}/transactions",
    getPortfolioSingleTransaction:
      "/accounts/{accountUid}/transactions/{transactionUid}",
    getProductPortoflioTransactions: "accounts/{accountUid}/transactions",
    getRecentPortfolioTransactions:
      "accounts/{accountUid}/transactions?content=Recent",
    getFinancialPortfolioTransactions: "accounts/{accountUid}/transactions",
    getFinancialPortfolioPendingTransactions:
      "accounts/{accountUid}/transactions/fundinghistory",
    patchFinancialPortfolioPendingTransactions:
      "/accounts/{accountUid}/transactions/fundinghistory/{depositKey}",
  },
  products: {
    getPrices: "/accounts/{accountUid}/products/prices",
    prices: "accounts/{accountUid}/products/prices?side={side}",
    tiers:
      "/accounts/{accountUid}/products/prices/{symbol}/tiers?side={side}&location={location}",
    symbolPrices: "/accounts/{accountUid}/products/{symbol}/prices?side={side}",
    getPricesRegistration: "applications/{applicationUid}/products/prices",
  },
  settings: {
    get: "settings",
  },
  taxForms: {
    getTaxForms: "settings/taxForms/{applicationType}",
  },
  users: {
    getAccounts: "/users/{userUid}/accounts",
    getRegistrationAccounts: "/users/{userUid}/accounts",
    updateUser: "/users/{userUid}?updateUserActionType={actionType}",
    updateUserPassword: "/users/{userUid}/passwords",
    logout: "/auth/logout",
    getUsernames: "/users/email",
    createUser:
      "/users?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}",
    updateUserRegistration: "/users/{userUid}",
    invite: "/users/invite",
    getProfile: "users/",
    editProfile: "users/{userId}",
    getAllProfiles: "users/companies",
    editProfileAsAdmin: "admin/users/{userId}",
    getAllProfilesAsAdmin: "admin/users",
    deleteProfileAsAdmin: "admin/users/{userId}",
    blockProfileAsAdmin: "admin/users/{userId}/block",
    unblockProfileAsAdmin: "admin/users/{userId}/unblock",
    checkIdNumberExist: "users/checkIdNumberExist/{idNumber}",
    approveProfileAsAdmin: "admin/users/{userId}/approve",
  },
  applications: {
    application: "/applications/{applicationUid}",
    addPerson: "/applications/{applicationUid}/persons",
    updatePerson: "/applications/{applicationUid}/persons/{personUid}",
    addPersonWithGiftState:
      "/applications/{applicationUid}/UTMA/persons?giftState={giftState}",
    updatePersonWithGiftState:
      "/applications/{applicationUid}/UTMA/persons/{personUid}?giftState={giftState}",
    addPersonWithCompanyName:
      "/applications/{applicationUid}/IRA/persons?companyName={companyName}",
    updatePersonWithCompanyName:
      "/applications/{applicationUid}/IRA/persons/{personUid}?companyName={companyName}",
    submitLegalEntity: "/applications/{applicationUid}/legalEntities",
    updateLegalEntity:
      "/applications/{applicationUid}/legalEntities/{personUid}",
    postNonIraFunding: "/applications/{applicationUid}/funding",
    postIraFunding: "/applications/{applicationUid}/iraFunding",
    postMSFunding: "/applications/{applicationUid}/metalStreamFunding",
    consent: "/applications/{applicationUid}/consents",
    updateConsent: `/applications/{applicationUid}/consents/{agreementConsentUid}`,
    submitMetalStreamRegistration:
      "/applications/{applicationUid}/metalStreamFunding",
  },
  common: {
    getCountries: "/countries/",
    getTaxForms: "/taxForms/",
    getContributionYears: "contributionYears",
    getCountryStates: "/countries/{iso3CountryCode}/states/",
    getSecurityQuestions: "/registration/securityQuestions/",
    getPortalSecurityQuestions: "/securityQuestions",
  },
  plaid: {
    getToken: "/bankaccounts/createplaidlinktoken",
  },
  affiliate: {
    setCookie: "/affiliate/picture",
    setFingerprint: "/affiliate/fingerprint",
  },
  offers: {
    getOneOffer: "offers/{offerId}",
    getOffers: "offers",
    getFeaturedOffers: "offers/featured",
    addOffer: "/users/{userId}/offers",
    editOffer: "/users/{userId}/offers/{offerId}",
    editOfferAsAdmin: "/admin/offers/{offerId}",
    categories: "categories",
    locations: "locations",
    mineOffers: "users",
    profileOffers: "users/{userId}/offers",
    profileOffersAsAdmin: "admin/users/{userId}/offers",
    removeOffer: "/users/{userId}/offers/{offerId}",
    removeOfferAsAdmin: "/admin/offers/{offerId}",
    pinOffer: "admin/offers/{id}/pin",
  },
  chat: {
    getChat: "chats",
    getUserChats: "users/{userId}/chats",
    getOneChat: "users/{userId}/chats/{chatId}",
    createChat: "users/{userId}/offers/{offerId}/chat",
    sendMessage: "chats",
    getExistChat: "users/{userId}/offers/{offerId}/chat",
  },
  exchange: {
    getExchange: "exchanges",
    validateExchange: "exchanges",
    acceptExchange: "users/{userId}/exchanges/{exchangeId}/accept",
  },
  reviews: {
    getUserReviews: "/users/{userId}/reviews",
    postReview: "/users/{userId}/reviews",
    removeReview: "/admin/reviews/{id}",
  },
  admin: {
    categories: {
      newCategory: "admin/categories",
      editCategory: "admin/categories/{categoryId}",
      deleteCategory: "admin/categories/{categoryId}",
    },
    subcategories: {
      newSubcategory: "admin/categories/{categoryId}",
      editSubcategory: "admin/categories/{categoryId}/{subcategoryName}",
      deleteSubcategory: "admin/categories/{categoryId}/{subcategoryName}",
    },
    locations: {
      newLocation: "admin/locations",
      editLocation: "admin/locations/{locationId}",
      deleteLocation: "admin/locations/{locationId}",
    },
    reviews: {
      getUserReviewsAsAdmin: "admin/reviews/{userId}",
    },
  },
  payment: {
    getUsersPayments: "admin/payments",
    postUsersPayments: "admin/payments",
    putUsersPayments: "admin/payments/{id}",
    deleteUsersPayments: "admin/payments/{id}",
  },
};

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectTotalOffers } from "../../../../store/selectors/offersSelectors";
import { OffersContainer } from "./OffersList.styled";
import BigProfileCard from "../../../Cards/ProfileCard/BigProfileCard/BigProfileCard";
import OfferCard from "../../../Cards/OfferCard/OfferCard";
import Paging from "../../../Paging/Paging";
import { startChat } from "../../../../util/helpers/chatHelper";
import { selectLatestChats } from "../../../../store/selectors/chatSelectors";
import { selectUserId } from "../../../../store/selectors/loginSelectors";
import { setRequester } from "../../../../store/actions/exchange/exchangeActions";
import requesterStatus from "../../../../constants/requesterStatus";

const OffersList = (props) => {
  const totalOffers = useSelector(selectTotalOffers);
  const chats = useSelector(selectLatestChats);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const offers = props?.offers;
  const messageOneUser = (offer) => {
    dispatch(setRequester(requesterStatus.NOONE));
    startChat(chats, offer, userId);
  };
  return (
    <OffersContainer show={!props?.loading && totalOffers !== 0}>
      {props.isUsers
        ? props.users?.map((item) => (
            <BigProfileCard
              key={item._id}
              profile={item}
              halfwidth={props?.isGrid}
            />
          ))
        : offers?.allOffersToShow?.map((item) => {
            return (
              <OfferCard
                key={item._id}
                offer={item}
                halfwidth={props?.isGrid}
                messageUser={messageOneUser}
                isMyOffer={item?.user._id === userId || props?.isAdmin}
                isAdmin={props?.isAdmin}
              />
            );
          })}
      <Paging
        totalElements={offers?.totalOffers}
        elementsPerPage={10}
        current={parseInt(offers?.paging?.currentPage)}
        changePage={offers?.paging?.changePage}
      />
    </OffersContainer>
  );
};

OffersList.propTypes = {
  offers: PropTypes.any,
  isGrid: PropTypes.bool,
  isUsers: PropTypes.bool,
  users: PropTypes.array,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
};

OffersList.defaultProps = {
  isGrid: false,
  isUsers: false,
  users: [],
  isAdmin: false,
  offers: {
    allOffersToShow: [],
  },
  loading: false,
};

export default OffersList;

import styled from "styled-components";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const OffersNotFoundContainer = styled(Box)`
  display: ${props => props.show ? "flex" : "none"};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  text-align: center;
`;

export const OffersNotFoundLogo = styled(Box)`
  @media (max-width: 600px) {
    svg {
      width: 100px;
      height: 100px;
    }
  }
`;

export const OffersNotFoundHeading = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 72px;
  font-weight: 700;
  color: ${selectedTheme.colors.primaryPurple};

  @media (max-width: 1370px) {
    line-height: 1.2;
  }

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

export const OffersNotFoundDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: #818181;
  margin: 9px 0 46px 0;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Button = styled(PrimaryButton)`
  width: 190px;
  height: 49px;
  font-weight: 600;
  letter-spacing: 1.5px;
  min-width: max-content;

  @media (max-width: 600px) {
    width: 180px;
    height: 44px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useSlate } from "slate-react";
import { Editor } from "slate";
import { ColorButtonContainer, ColorButtonIcon } from "./ColorButton.styled";
import { useState } from "react";
import ColorIcon from "../ColorIcon/ColorIcon";
import ColorPicker from "../../Popovers/ColorPicker/ColorPicker";

// const colorMarks = ["darkGray", "gray", "purple", "pink", "yellow"];

const toggleMark = (editor, format, newColor) => {
    Editor.addMark(editor, format, newColor);
};

// const isMarkActive = (editor, format) => {
//   const marks = Editor.marks(editor);
//   return marks ? marks[format] === true : false;
// };

const ColorButton = (props) => {
  const editor = useSlate();
  const [isColorPickerPopoverShowing, setIsColorPickerPopoverShowing] =
    useState(false);

  const callbackFunction = (newColor) => {
    setIsColorPickerPopoverShowing(false);
    props?.setSelectedColor(newColor);
    toggleMark(editor, "color", newColor.color);
  };
  console.log();
  return (
    <>
      <ColorButtonContainer
        format={props?.format}
        onMouseDown={() => setIsColorPickerPopoverShowing(true)}
      >
        <ColorButtonIcon format={props?.format}>
          <ColorIcon selected color={props?.selectedColor.color} />
        </ColorButtonIcon>
      </ColorButtonContainer>
      {isColorPickerPopoverShowing && (
        <ColorPicker
          selectedColor={props?.selectedColor.color}
          selectedColorName={props?.selectedColor.colorName}
          handleClickColor={callbackFunction}
        />
      )}
    </>
  );
};
ColorButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
  selectedColor: PropTypes.any,
  setSelectedColor: PropTypes.func,
};
export default ColorButton;

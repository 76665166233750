import styled, { css } from "styled-components";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as Location } from "../../../../assets/images/svg/location.svg";
import { ReactComponent as Mail } from "../../../../assets/images/svg/mail.svg";
import { ReactComponent as Globe } from "../../../../assets/images/svg/globe.svg";
import selectedTheme from "../../../../themes";

export const ProfileContactContainer = styled(Grid)`
  padding-top: ${(props) => (props.isAdmin ? `20px` : `2rem`)};
  padding-bottom: ${(props) => (props.isAdmin ? "0" : "2rem")};
  @media (max-width: 1300px) {
    ${(props) =>
      props.isAdmin &&
      css`
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        padding-bottom: 0;
      `}
  }
  @media (max-width: 600px) {
    ${(props) => props.isAdmin && `overflow: hidden;`}
    padding-top: ${(props) =>
      props.isAdmin && (props.bigProfileCard ? "88px" : "58px")};
    padding-bottom: ${(props) => (props.isAdmin ? "1rem" : "0")};
    gap: 5px;
    width: ${(props) => props.bigProfileCard && "calc(100vw - 198px)"};
  }
`;
export const LocationIcon = styled(Location)`
  height: 22px;
  width: 22px;
  & path {
    stroke: ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.iconMineProfileColor
        : selectedTheme.colors.iconProfileColor};
  }
  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
  }
`;
export const ContactItem = styled(Typography)`
  margin-right: 2rem;
  margin-left: 0.4rem;
  color: ${(props) =>
    props.isMyProfile && !props.isBlocked
      ? "white"
      : selectedTheme.colors.primaryDarkText};
  /* color: ${selectedTheme.colors.primaryDarkText}; */
  display: unset;
  font-family: ${selectedTheme.fonts.textFont};
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  bottom: 1px;
  @media (max-width: 600px) {
    font-size: 14px;
    bottom: 4px;
    margin-right: 0;
    ${(props) =>
      props.bigProfileCard &&
      css`
        width: calc(100vw - 218px);
        overflow-wrap: break-word;
      `}
  }
`;
export const MailIcon = styled(Mail)`
  height: 24px;
  width: 24px;
  & path {
    stroke: ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.iconMineProfileColor
        : selectedTheme.colors.iconProfileColor};
  }
  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
  }
`;
export const GlobeIcon = styled(Globe)`
  height: 22px;
  width: 22px;
  & path {
    stroke: ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.iconMineProfileColor
        : selectedTheme.colors.iconProfileColor};
  }
  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
  }
`;

import * as Yup from "yup";
import i18n from "../i18n";
export default () =>
  Yup.object().shape({
    payerName: Yup.string().required(
      i18n.t("admin.payment.errors.nameRequired")
    ),
    companyName: Yup.string().required(
      i18n.t("admin.payment.errors.companyRequired")
    ),
    offerName: Yup.string().required(
      i18n.t("admin.payment.errors.offerRequired")
    ),
    type: Yup.string().required(i18n.t("admin.payment.errors.typeRequired")),
    date: Yup.string().required(i18n.t("admin.payment.errors.dateRequired")),
  });

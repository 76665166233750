import React, { useEffect, useState } from "react";
import { TextFieldContainer, TextFieldStyled } from "./TextField.styled";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";

export const TextField = React.forwardRef((props, ref) => {
  const [isFieldEmpty, setIsFieldEmpty] = useState(true);

  //  for italicPlaceholder
  useEffect(() => {
    if (props?.value?.length === 0) {
      setIsFieldEmpty(true);
    } else {
      setIsFieldEmpty(false);
    }
  }, [props.value]);
  return (
    <TextFieldContainer
      style={props.containerStyle}
      className={props.className}
      height={props.height}
    >
      <TextFieldStyled
        {...props.attributes}
        inputRef={ref}
        inputProps={props.inputProps}
        placeholder={props.placeholder}
        width={props.width}
        height={props.height}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        multiline={props.multiline}
        minRows={props.minRows}
        // helperText={props.helperText}
        autoFocus={props.autoFocus}
        fullWidth={props.fullWidth}
        type={props.type}
        textsize={props.textsize}
        font={props.font}
        InputProps={props.InputProps}
        sx={props.style}
        label={props.showAnimation ? props.placeholder : ""}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        italicplaceholder={
          props.italicPlaceholder && isFieldEmpty ? "true" : "false"
        }
        focused={props.focused}
      >
        {props.children}
      </TextFieldStyled>
    </TextFieldContainer>
  );
});

TextField.displayName = "TextField";

TextField.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.any,
  showAnimation: PropTypes.bool,
  containerStyle: PropTypes.any,
  italicPlaceholder: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  id: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  textsize: PropTypes.string,
  font: PropTypes.string,
  ref: PropTypes.any,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  focused: PropTypes.bool,
  inputProps: PropTypes.any,
  InputProps: PropTypes.shape({
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    style: PropTypes.any,
  }),
  attributes: PropTypes.any,
};

TextField.defaultProps = {
  italicPlaceholder: false,
  showAnimation: false,
  height: "48px",
  font: selectedTheme.fonts.textFont,
};

import React, { useEffect, useMemo } from "react";
import { Redirect, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/actions/login/loginActions";
import { LOGIN_PAGE } from "../../constants/pages";
import { selectUserId } from "../../store/selectors/loginSelectors";

const PrivateRoute = ({ ...props }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const isUserAuthenticated = useMemo(() => {
    if (userId?.length === 0) return false;
    return true;
  }, [userId]);

  useEffect(() => {
    if (!isUserAuthenticated) {
      dispatch(logoutUser());
    }
  }, [isUserAuthenticated]); // eslint-disable-line

  return isUserAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={LOGIN_PAGE} />
  );
};

export default PrivateRoute;

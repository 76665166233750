import React from "react";
import PropTypes from "prop-types";
import CategoryCard from "../../../components/Cards/CategoryCard/CategoryCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCategories } from "../../../store/actions/categories/categoriesActions";
import { selectCategories } from "../../../store/selectors/categoriesSelectors";
import { useTranslation } from "react-i18next";
import {
  AdminCategoriesHeader,
  AdminCategoriesPageContainer,
  AdminCategoriesSearchField,
  CategoriesList,
  NewCategoryButton,
} from "./AdminCategoriesPage.styled";
import { selectManualSearchString } from "../../../store/selectors/filtersSelectors";
import { useMemo } from "react";
import { setManualSearchString } from "../../../store/actions/filters/filtersActions";
import selectedTheme from "../../../themes";
import useSorting from "../../../hooks/useOffers/useSorting";
import { sortCategoriesEnum } from "../../../enums/sortEnum";
import { adminSortMethod } from "../../../util/helpers/adminSortHelper";
import { toggleCreateCategoryModal } from "../../../store/actions/modal/modalActions";

const AdminCategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sorting = useSorting(() => {}, sortCategoriesEnum);
  const categories = useSelector(selectCategories);
  const manualSearchString = useSelector(selectManualSearchString);
  useEffect(() => {
    dispatch(fetchCategories());
    return () => {
      dispatch(setManualSearchString(""));
      sorting.clear();
    };
  }, []);
  const handleSearch = (value) => {
    dispatch(setManualSearchString(value));
  };
  const categoriesToShow = useMemo(() => {
    if (categories) {
      return adminSortMethod(categories, manualSearchString, sorting);
    }
    return [];
  }, [categories, manualSearchString, sorting.selectedSortOptionLocally]);

  const showAddCategoryModal = () => {
    dispatch(
      toggleCreateCategoryModal({
        type: "categories",
        method: "add",
        showSecondButton: true,
      })
    );
  };
  return (
    <>
      <AdminCategoriesPageContainer>
        <AdminCategoriesSearchField
          isAdmin
          handleSearch={handleSearch}
          placeholder={t("admin.categories.placeholder")}
        />
        <NewCategoryButton
          variant="contained"
          buttoncolor={selectedTheme.colors.iconYellowColor}
          textcolor={selectedTheme.colors.messageText}
          onClick={showAddCategoryModal}
        >
          {t("admin.categories.addCategory")}
        </NewCategoryButton>
        <AdminCategoriesHeader
          myOffers
          categories
          hideGrid
          isAdmin
          sorting={sorting}
          hideBackButton
        />
        <CategoriesList>
          {categoriesToShow.map((category) => (
            <CategoryCard
              key={category._id}
              category={category}
              type="categories"
              secondLabel={t("admin.categories.noOfSubcategories")}
            />
          ))}
        </CategoriesList>
      </AdminCategoriesPageContainer>
    </>
  );
};

AdminCategoriesPage.propTypes = {
  children: PropTypes.node,
};

export default AdminCategoriesPage;

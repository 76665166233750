import React from "react";
import PropTypes from "prop-types";
// import { Tooltip } from "@mui/material";
import {
  ButtonContainer,
  CategoryHeaderIcon,
  HeaderAltLocation,
  HeaderCategoryString,
  HeaderCompanyString,
  HeaderLocation,
  HeaderLocationsMainString,
  HeaderLocationsString,
  HeaderSubcategoryString,
  HeaderText,
  HeaderTitleContainer,
  HeaderTitleText,
  TooltipInnerContainer,
} from "./TooltipHeader.styled";
import { useSelector } from "react-redux";
import { selectHeaderString } from "../../../../store/selectors/filtersSelectors";
import useIsMobile from "../../../../hooks/useIsMobile";
import { ArrowButton } from "../../../Buttons/ArrowButton/ArrowButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const TooltipHeader = (props) => {
  const headerString = useSelector(selectHeaderString);
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickCategory = () => {
    props?.offers?.filters?.locations.clear();
    props?.offers?.filters?.subcategory.clear();
    props?.offers?.filters?.companies?.clear();
    props?.offers?.applyFilters();
  };
  const handleClickSubcategory = () => {
    props?.offers?.filters?.locations.clear();
    props?.offers?.filters?.companies?.clear();
    props?.offers?.applyFilters();
  };
  const goBack = () => {
    history.goBack();
  };

  return (
    // <Tooltip
    //   title={
    //     history.location.pathname.includes("admin") ||
    //     history.location.pathname.includes("myoffers")
    //       ? ""
    //       : headerString.text
    //   }
    // >
    <TooltipInnerContainer>
      {!props?.myOffers ? (
        <>
          <CategoryHeaderIcon />
          <HeaderLocation>
            <HeaderCategoryString
              component="span"
              onClick={handleClickCategory}
            >
              {headerString.categoryString}
            </HeaderCategoryString>
            <HeaderSubcategoryString
              component="span"
              onClick={handleClickSubcategory}
            >
              {headerString.subcategoryString}
            </HeaderSubcategoryString>
            <HeaderLocationsString component="span">
              <HeaderLocationsMainString component="span">
                {headerString.locationsString}
              </HeaderLocationsMainString>
              <HeaderCompanyString>
                {headerString.companiesString}
              </HeaderCompanyString>
              <HeaderAltLocation component="span">
                {props?.altText}
              </HeaderAltLocation>
            </HeaderLocationsString>
          </HeaderLocation>
        </>
      ) : (
        <>
          {!isMobile ? (
            <HeaderTitleContainer>
              {props?.headerIcon}
              <HeaderTitleText>{props?.headerTitle}</HeaderTitleText>
            </HeaderTitleContainer>
          ) : (
            !props.hideBackButton && (
              <ButtonContainer onClick={goBack}>
                <ArrowButton side={"left"}></ArrowButton>
                <HeaderText>{t("messages.goBack")}</HeaderText>
              </ButtonContainer>
            )
          )}
        </>
      )}
    </TooltipInnerContainer>
    // </Tooltip>
  );
};

TooltipHeader.propTypes = {
  myOffers: PropTypes.bool,
  altText: PropTypes.string,
  headerIcon: PropTypes.node,
  headerTitle: PropTypes.string,
  hideBackButton: PropTypes.bool,
  offers: PropTypes.any,
};
TooltipHeader.defaultProps = {
  myOffers: false,
  altText: "",
  headerIcon: <></>,
  headerString: "",
  hideBackButton: false,
};

export default TooltipHeader;

import { Box, ListItem, Typography } from "@mui/material"
import styled from "styled-components"
import selectedTheme from "../../../../../themes"

export const ProfileImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  cursor: pointer;
`
export const ProfileImageContainer = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  margin-right: 14px;
`
export const ProfileName = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  position: relative;
  top: 12px;
  cursor: pointer;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
`
export const ProfileContainer = styled(ListItem)`
    align-items: flex-start;
    padding: 0;
`
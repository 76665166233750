import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";

export const SlateButtonsContainer = styled(Box)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  flex-direction: row;
  height: 36px;
  background-color: ${selectedTheme.colors.stylingTextBackground};
  padding: 8px 16px;
  border-radius: 4px;
  gap: 20px;
`;
export const RichTextComponentContainer = styled(Box)`
  width: 100%;
  position: relative;
  top: 14px;
  height: ${(props) =>
    props?.offerCard || props?.itemDetails ? "auto" : "140px"};
  ${(props) =>
    props?.offerCard &&
    css`
      max-height: 100px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    `}
  border-radius: 4px;
  border: ${(props) =>
    props.readOnly ? "0" : `1px solid ${selectedTheme.colors.borderNormal}`};
  margin-bottom: 14px;
  @media (max-width: 600px) {
    /* height: 40px; */
    height: auto;
  }
`;
export const EditableContainer = styled(Box)`
  ${(props) => !props?.itemDetails && "max-height: 104px;"}
  ${(props) => !props?.offerCard && "overflow: auto;"}
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
  @media (max-width: 600px) {
    /* max-height: 39px; */
  }
`;
export const EditableInnerContainer = styled(Box)`
  padding: ${(props) => (props?.readOnly ? "0" : "10px 16px 0 16px")};
  & > div {
    ${(props) => !props.readOnly && `height: 90px;`}
    min-height: 84px;
  }
  & * {
    font-family: ${selectedTheme.fonts.textFont};
  }
  & span[data-slate-placeholder="true"] {
    font-style: italic;
    color: ${selectedTheme.colors.colorPicker.darkGray};
  }
  @media (max-width: 600px) {
    & > div {
      min-height: 0;
    }
    & * {
      font-size: 12px;
    }
  }
`;

import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { TextField } from "../../../TextFields/TextField/TextField";
import { ReactComponent as Search } from "../../../../assets/images/svg/magnifying-glass.svg";
import { Icon } from "../../../Icon/Icon";

export const SearchInput = styled(TextField)`
  position: relative;
  top: 15px;
  & div {
    background-color: white;
  }
  & div fieldset {
    border-color: ${selectedTheme.colors.primaryPurple} !important;
  }
  margin-bottom: 24px;
  @media (max-width: 600px) {
    top: 5px;
    height: 46px;
    & div {
      background-color: white;
    }
  }
`;
export const SearchIcon = styled(Search)`
  width: 18px;
  height: 18px;
`;
export const IconContainer = styled(Icon)`
  cursor: pointer;
  position: relative;
  top: 4px;
`;

import { TextField } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-striked.svg";
import selectedTheme from "../../../themes";

export const SearchInput = styled(TextField)`
  margin-left: 3.8rem;
  background-color: #f4f4f4;
  width: 45%;
  max-width: 100%;
  @media (max-width: 1100px) {
    width: 36%;
  }
  @media (max-width: 900px) {
    width: 54%;
  }
  @media (max-width: 600px) {
    width: 36%;
  }
`;

export const EyeIcon = styled(Eye)`
  & path {
    stroke: ${(props) =>
      props.color
        ? selectedTheme.colors.iconStrokeDisabledColor
        : selectedTheme.colors.iconYellowColor};
  }
`;

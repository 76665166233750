import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Message } from "../../../assets/images/svg/message.svg";
import selectedTheme from "../../../themes";

export const DirectChatHeaderTitleContainer = styled(Box)``;
export const MessageIcon = styled(Message)``;
export const HeaderTitleContent = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  position: relative;
  left: 9px;
  bottom: 7px;
  color: ${selectedTheme.colors.primaryText};
`;
export const ButtonContainer = styled(Link)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  margin-bottom: 36px;
`;
export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
`;

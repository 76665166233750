import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const LoginTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
`;
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  AdminPaymentPageContainer,
  AdminPaymentsHeader,
  AdminPaymentsSearchField,
  NewPaymentButton,
  PaymentsList,
} from "./AdminPaymentPage.styled";
import { useTranslation } from "react-i18next";
import useSorting from "../../../hooks/useOffers/useSorting";
import { sortPaymentsEnum } from "../../../enums/sortEnum";
import CategoryCard from "../../../components/Cards/CategoryCard/CategoryCard";
import selectedTheme from "../../../themes";
import { useDispatch, useSelector } from "react-redux";
import { setManualSearchString } from "../../../store/actions/filters/filtersActions";
import { toggleCreatePaymentModal } from "../../../store/actions/modal/modalActions";
import { selectPayments } from "../../../store/selectors/paymentSelector";
import { selectManualSearchString } from "../../../store/selectors/filtersSelectors";
import { fetchPayments } from "../../../store/actions/payment/paymentActions";
import { adminSortMethod } from "../../../util/helpers/adminSortHelper";
import { selectAllProfiles } from "../../../store/selectors/profileSelectors";
import { fetchAllProfiles } from "../../../store/actions/profile/profileActions";
import useQueryString from "../../../hooks/useOffers/useQueryString";

const AdminPaymentPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sorting = useSorting(() => {}, sortPaymentsEnum);
  const payments = useSelector(selectPayments);
  const users = useSelector(selectAllProfiles);
  const manualSearchString = useSelector(selectManualSearchString);
  const queryObject = useQueryString();

  useEffect(() => {
    dispatch(fetchPayments());
    dispatch(fetchAllProfiles());
    return () => {
      dispatch(setManualSearchString(""));
      sorting.clear();
    };
  }, []);

  const checkedUsers = useMemo(() => {
    if (queryObject.queryObject.companyName) {
      return users?.filter?.((user) =>
        queryObject?.queryObject?.companyName?.includes(user.companyName)
      );
    } else {
      return "";
    }
  }, [users, queryObject?.queryObject]);

  const checkedUsersIds =
    checkedUsers && checkedUsers?.map((item) => item?._id);

  const paymentsToShow = useMemo(() => {
    if (payments && !queryObject?.queryObject?.companyName) {
      return adminSortMethod(payments, manualSearchString, sorting);
    }
    if (queryObject?.queryObject?.companyName.length > 0) {
      const filteredPayments = payments?.filter((payment) =>
        checkedUsersIds.includes(payment?.user?._id)
      );
      return adminSortMethod(filteredPayments, manualSearchString, sorting);
    }
  }, [
    payments,
    manualSearchString,
    sorting.selectedSortOptionLocally,
    queryObject.queryObject.companyName,
  ]);

  const handleSearch = (value) => {
    dispatch(setManualSearchString(value));
  };
  const showAddPaymentModal = () => {
    dispatch(toggleCreatePaymentModal());
  };

  return (
    <AdminPaymentPageContainer>
      <AdminPaymentsSearchField
        placeholder={t("admin.payment.placeholder")}
        isAdmin
        handleSearch={handleSearch}
      />
      <NewPaymentButton
        variant="contained"
        buttoncolor={selectedTheme.colors.iconYellowColor}
        textcolor={selectedTheme.colors.messageText}
        onClick={showAddPaymentModal}
      >
        {t("admin.payment.addPayment")}
      </NewPaymentButton>

      <AdminPaymentsHeader
        myOffers
        payments
        hideGrid
        isAdmin
        sorting={sorting}
        hideBackButton
      />
      <PaymentsList>
        {paymentsToShow?.map((payment) => (
          <CategoryCard
            key={payment._id}
            category={payment}
            type="payment"
            secondLabel={t("admin.payment.date")}
            dontNavigate
            hideCheckButton
            payments
          />
        ))}
      </PaymentsList>
    </AdminPaymentPageContainer>
  );
};

AdminPaymentPage.propTypes = {
  children: PropTypes.node,
};

export default AdminPaymentPage;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ReactComponent as Plus } from "../../assets/images/svg/plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/svg/trash.svg";

export const ImagePickerContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-basis: 144px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 144px;
  width: 144px;
  margin: 0 9px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: ${selectedTheme.colors.imagePickerBackground};
  background-image: linear-gradient(
      to right,
      ${selectedTheme.colors.primaryPurple} 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(
      ${selectedTheme.colors.primaryPurple} 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(
      to right,
      ${selectedTheme.colors.primaryPurple} 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(
      ${selectedTheme.colors.primaryPurple} 50%,
      rgba(255, 255, 255, 0) 0%
    );
  background-position: bottom, right, top, left;
  background-size: 20px 1px, 1px 20px, 20px 1px, 1px 20px;
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${(props) =>
    props.hasImage &&
    `
    background-image: none;
    border: 1px solid ${selectedTheme.colors.primaryPurple};
  `}
  ${props => props.singleImage && `margin: 0;`}
`;
export const AddIcon = styled(Plus)`
  margin: auto;
  z-index: 1;
  width: 60px;
  height: 60px;
`;
export const AddFile = styled.input`
  display: none;
`;
export const ImageUploaded = styled.img`
  width: 144px;
  height: 144px;
  /* border-radius: 100px; */
  /* object-fit: scale-down; */
  object-fit: cover;
  z-index: 1;
`;
export const ImageUploadedContainer = styled(Box)`
  width: 144px;
  height: 144px;
  overflow: hidden;
`;
export const ImageOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  overflow: hidden;
`;
export const Tooltip = styled(Box)`
  background-color: rgba(255, 255, 255, 0.5);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
`;
export const Trash = styled(TrashIcon)`
  cursor: pointer;
  margin: auto;
  width: 22px;
  height: 22px;
  & path {
    stroke: white;
  }
`;
export const Tools = styled(Box)`
  position: absolute;
  padding-top: 44px;
  padding-left: ${(props) => (props.showDeleteIcon ? "16px" : "45px")};
  z-index: 4;
  flex-direction: row;
  display: flex;
  & div {
    background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
    border-radius: 100px;
    display: flex;
    flex: 1;
    margin: 10px;
  }
`;

import styled from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../themes";
import { BackgroundTransition } from "../../Styles/globalStyleComponents";

export const SkeletonDirectChatHeadingFirst = styled(BackgroundTransition)`
  width: 209px;
  height: 18px;
  margin-bottom: 46px;
`;

export const SkeletonDirectChatHeadingSecond = styled(BackgroundTransition)`
  width: 90px;
  height: 18px;
  margin-bottom: 18px;
`;

export const SkeletonDirectChatContainer = styled(Box)`
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  display: flex;
  border: 1px solid ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  padding: 18px;
  justify-content: space-between;
`;

export const SkeletonDirectChatImage = styled(Box)`
  width: 144px;
  height: 144px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};

  @media (max-width: 600px) {
    width: 108px;
    height: 108px;
    position: absolute;
    margin-top: 20px;
  }
`;

export const SkeletonDirectChatFirstColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;

  @media (max-width: 600px) {
    margin-left: 125px;
  }
`;

export const SkeletonDirectChatFirstLine = styled(Box)`
  width: 90px;
  height: 27px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 28px;
`;

export const SkeletonDirectChatSecondLine = styled(Box)`
  width: 117px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 4px;
`;

export const SkeletonDirectChatThirdLine = styled(Box)`
  width: 90px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 35px;
`;

export const SkeletonDirectChatFourthLineContainer = styled(Box)`
  display: flex;
`;

export const SkeletonDirectChatFourthLine = styled(Box)`
  width: 72px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-right: 27px;

  @media (max-width: 600px) {
    width: 50px;
  }
`;

export const SkeletonDirectChatVerticalLine = styled(Box)`
  width: 1px;
  height: 108px;
  background-color: #4d4d4d;
  opacity: 0.12;
  margin-top: 18px;
  margin-left: 12px;
  margin-right: 36px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SkeletonDirectChatSecondColumn = styled(Box)`
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SkeletonDirectChatSecondColumnFirstLine = styled(Box)`
  width: 72px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-top: 18px;
  margin-bottom: 4px;
`;

export const SkeletonDirectChatSecondColumnSecondLine = styled(Box)`
  width: 221px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  margin-bottom: 4px;
`;

export const SkeletonDirectChatThirdColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 65px;
`;

export const SkeletonDirectChatRoundImage = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
`;

export const SkeletonDirectChatThirdColumnSecondLine = styled(Box)`
  width: 180px;
  height: 48px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  padding: 17px 36px;
`;

export const SkeletonDirectChatThirdColumnSecondLineInside = styled(Box)`
  width: 108px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import {
  BackgroundTransition,
  ItemsTransition,
} from "../../../Styles/globalStyleComponents";

export const SkeletonOfferCardsContainer = styled(Box)`
  display: ${props => props.skeleton ? "flex" : "none"};
`;

export const SkeletonOfferCardContainer = styled(BackgroundTransition)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin: 14px 0;
  border-radius: 4px;
  ${(props) =>
    props.sponsored === "true" &&
    `border: 1px solid ${selectedTheme.colors.borderSponsoredColor};`}
  padding: 16px;
  max-width: 2000px;
  height: 180px;
  position: relative;
  & * {
    border-radius: 4px;
  }

  @media (max-width: 550px) {
    height: 194px;
    padding: 18px;
    padding-top: 12px;
    ${(props) =>
      props.vertical &&
      `
      height: 373px;
      width: 180px;
      margin: 0 18px;
      margin-left: 0;
    `}
  }
  /* @media (max-width: 600px) {
    width: 180px;
    height: 373px;
    margin-right: 18px;
  } */
`;
export const LeftPart = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-right: 40px;
  @media (max-width: 600px) {
    gap: 18px;
    ${(props) => props.vertical && `gap: 9px; flex-direction: column;`}
  }
  /* @media (max-width: 600px) {
    flex-direction: column;
  } */
`;
export const SpreadLine = styled(Box)`
  height: 108px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.12;
  border: 1px solid black;
  @media (max-width: 1050px) {
    display: none;
  }
`;
export const RightPart = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  margin-left: 36px;
  padding-top: 20px;
  @media (max-width: 1050px) {
    display: none;
  }
`;
export const SkeletonImage = styled(ItemsTransition)`
  width: 144px;
  height: 144px;
  @media (max-width: 600px) {
    margin-top: 54px;
    width: 108px;
    height: 108px;
    ${(props) =>
      props.vertical && `margin-top: 18px; width: 144px; height: 144px;`}
  }
`;
export const SkeletonColumnContainer = styled(Box)`
  display: flex;
  margin-left: 18px;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  @media (max-width: 600px) {
    max-height: 107px;
    margin-left: 0;
    margin-top: 9px;
    flex: 0;
  }
`;
export const SkeletonTitle = styled(ItemsTransition)`
  width: 90px;
  height: 27px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const SkeletonGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const SkeletonAuthor = styled(ItemsTransition)`
  width: 117px;
  height: 18px;
  @media (max-width: 600px) {
    visibility: hidden;
    ${props => props.vertical && `display: none;`}
  }
`;
export const SkeletonLocation = styled(ItemsTransition)`
  width: 90px;
  height: 18px;

  @media (max-width: 600px) {
    width: 117px;
    margin-top: 26px;
    ${props => props.vertical && `margin-top: 0;`}
  }
`;
export const SkeletonRowGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & div:nth-child(4) {
    display: none;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 4px;
    & div {
      display: none;
    }
    & div:nth-child(1) {
      display: block;
      width: 90px;
      margin-top: 4px;
      ${(props) => props.aboveChat && `margin-bottom: 18px;`}
    }
    & div:nth-child(2) {
      display: ${(props) => (props.aboveChat ? "none" : "block")};
      width: 85px;
    }
    & div:nth-child(4) {
      display: block;
      margin-top: 0;
    }
  }
`;
export const SkeletonDetail = styled(ItemsTransition)`
  width: 72px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonItems};

  @media (max-width: 600px) {
    margin-top: 32px;
  }
`;
export const SkeletonDescription = styled(ItemsTransition)`
  width: 72px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonItems};
`;
export const SkeletonDescriptionLine = styled(ItemsTransition)`
  width: 221px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonItems};
  @media (max-width: 1400px) {
    ${(props) => props.removeOnSmallerScreens && `display: none;`}
  }
`;
export const SkeletonMessageButton = styled(ItemsTransition)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonItems};
  position: absolute;
  top: 18px;
  right: 18px;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    ${props => props.vertical && `
      top: initial;
      right: initial;
      bottom: 18px;
      left: 18px;
    `}
  }
`;
export const SkeletonMessageButtonSecond = styled(ItemsTransition)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonItems};
  position: absolute;
  top: 18px;
  right: 18px;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    /* top: 323px; */
    /* left: 59px; */
    ${props => props.vertical && `
      top: initial;
      right: initial;
      bottom: 18px;
      left: 59px;
    `}
  }
`;
export const SkeletonExchangeButton = styled(ItemsTransition)`
  width: 180px;
  height: 48px;
  background-color: ${selectedTheme.colors.filterSkeletonItems};
  bottom: 18px;
  right: 18px;
  position: absolute;
  padding-top: 17px;
  @media (max-width: 1400px) {
    display: none;
  }
`;
export const SkeletonExchangeLine = styled(BackgroundTransition)`
  width: 108px;
  height: 14px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  margin: auto;
`;
export const SkeletonTitleAboveImage = styled(ItemsTransition)`
  display: none;
  @media (max-width: 600px) {
    display: block;
    width: 90px;
    height: 20px;
    position: absolute;
    top: 18px;
    left: 18px;
    ${(props) => props.vertical && `display: none;`}
  }
`;

import { css } from "styled-components";
import { variables } from "./variables";

export const LayoutStyle = css`
    .l-page {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding-bottom: 7rem;
        position:relative;
    }
    .l-section {
        padding: 0 3.25rem;
    }
    @media only screen and (max-width: ${variables.breakpoints.bpXl}) {
        .l-page {
            padding-bottom: 4rem;
        }
    } 
    @media only screen and (max-width: ${variables.breakpoints.bpXl}) {
        .l-section {
            padding-bottom: 0;
        }
    }  
    `;


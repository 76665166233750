import React from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "./CloseButton.styled";
import { ReactComponent as CloseButtonIcon } from "../../../../assets/images/svg/close-modal.svg";

const CloseButton = (props) => {
  const closeModalHandler = () => {
    props.closeCreateOfferModal(false);
  };

  return (
    <CloseIcon onClick={closeModalHandler}>
      <CloseButtonIcon />
    </CloseIcon>
  );
};

CloseButton.propTypes = {
  closeCreateOfferModal: PropTypes.func,
};

export default CloseButton;

import React from "react";
import PropTypes from "prop-types";
import {
  PostsNotFoundButton,
  PostsNotFoundContainer,
  PostsNotFoundText,
  PostsNotFoundTextSecond,
  PostsNotFoundSkeleton,
  PostsNotFoundSkeletonImage,
} from "./MyPostsNotFound.styled";
import { useTranslation } from "react-i18next";
import {
  SkeletonFirstLine,
  SkeletonLinesContainer,
  SkeletonSecondLine,
} from "../../MyMessages/MyMessagesNotFound/MyMessagesNotFound.styled";

const MyPostsNotFound = (props) => {
  const { t } = useTranslation();
  return (
    <PostsNotFoundContainer>
      <PostsNotFoundText>{t("header.noItems")}</PostsNotFoundText>
      <PostsNotFoundTextSecond>
        <PostsNotFoundButton onClick={props.addOffer}>
          {t("header.addItem")}
        </PostsNotFoundButton>
        {t("header.yourFirstOffer")}
      </PostsNotFoundTextSecond>
      <PostsNotFoundSkeleton>
        <PostsNotFoundSkeletonImage />
        <SkeletonLinesContainer>
          <SkeletonFirstLine />
          <SkeletonSecondLine />
        </SkeletonLinesContainer>
      </PostsNotFoundSkeleton>
    </PostsNotFoundContainer>
  );
};

MyPostsNotFound.propTypes = {
  addOffer: PropTypes.func,
};

export default MyPostsNotFound;

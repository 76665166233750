import styled from 'styled-components';
import { Grid, Box } from '@mui/material';

export const ProfilePageWrapper = styled(Grid)`
    background: #F4F4F4;
    min-height: 100vh;
    max-height: fit-content;
    padding-bottom: 2rem;
`;
export const ProfilePageContainer = styled(Box)`
    margin-top: 80px;
    @media (max-width: 600px) {
        margin-top: 40px;
    }
`
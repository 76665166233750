import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DrawerButton } from "../../Drawer.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import {
  GrayDrawerOption,
  PrivacyPolicyIcon,
} from "./PrivacyPolicyButton.styled";
import { ABOUT_PAGE } from "../../../../../constants/pages";
import scrollConstants from "../../../../../constants/scrollConstants";
import history from "../../../../../store/utils/history";

const PrivacyPolicyButton = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.toggleDrawer();
    history.push({
      pathname: ABOUT_PAGE,
      state: {
        navigation: scrollConstants.about.privacyPolicyPage,
        clicked: true,
      },
    });
  };
  return (
    <DrawerButton onClick={handleClick}>
      <IconButton sx={{ borderRadius: "4px" }}>
        <PrivacyPolicyIcon />
      </IconButton>
      <GrayDrawerOption>{t("header.privacy")}</GrayDrawerOption>
    </DrawerButton>
  );
};

PrivacyPolicyButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default PrivacyPolicyButton;

import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Link } from "../../../assets/images/svg/link.svg";

export const LinkButtonContainer = styled(Box)`
  display: inline;
  cursor: pointer;
  position: relative;
  top: 2px;
`;
export const LinkIcon = styled(Link)``

import React from "react";
import PropTypes from "prop-types";
import {
  ReviewOfferContainer,
  ReviewOfferDescription,
  ReviewOfferDetails,
  ReviewOfferImage,
  ReviewOfferTitle,
} from "./ReviewOffer.styled";
import { getImageUrl, variants } from "../../../../../util/helpers/imageUrlGetter";
import { useTranslation } from "react-i18next";

const ReviewOffer = (props) => {
  const {t} = useTranslation();
  return (
    <ReviewOfferContainer>
      <ReviewOfferImage src={getImageUrl(props.image, variants.reviewCard)} />
      <ReviewOfferDetails>
        <ReviewOfferDescription>{t("reviews.offerTitle")}</ReviewOfferDescription>
        <ReviewOfferTitle>{props.name}</ReviewOfferTitle>
      </ReviewOfferDetails>
    </ReviewOfferContainer>
  );
};

ReviewOffer.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  image: PropTypes.string,
};

export default ReviewOffer;

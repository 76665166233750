import styled from "styled-components";
import { Box } from "@mui/material";

export const SidebarContainer = styled(Box)`
  margin-top: -30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 20%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  min-width: 281px;
  overflow-y: auto;
  height: 100vh;
  @media (max-width: 1536px) {
    width: 25%;
  }
  @media (max-width: 1100px) {
    min-width: 0;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const SidebarContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const ButtonsContainer = styled(Box)``;

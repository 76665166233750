import { Typography } from "@mui/material";
import styled from "styled-components";
import {ReactComponent as User} from "../../../../../assets/images/svg/user.svg"
import selectedTheme from "../../../../../themes";

export const MyUsername = styled(Typography)`
  font-size: 12px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  position: relative;
  top: 12px;
  left: 4px;
  letter-spacing: 2%;
`
export const UserIcon = styled(User)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`
import {
  attemptAddOffer,
  attemptEditOffer,
  attemptEditOfferAsAdmin,
  attemptFetchFeaturedOffers,
  attemptFetchOffers,
  attemptFetchOneOffer,
  attemptFetchProfileOffersAsAdmin,
  attemptPinOffer,
  attemptRemoveOffer,
  attemptRemoveOfferAsAdmin,
} from "../../request/offersRequest";
import {
  // OFFERS_ALL_FETCH,
  OFFERS_FEATURED_FETCH,
  OFFERS_FETCH,
  OFFERS_MINE_HEADER_FETCH,
  OFFERS_PROFILE_FETCH,
  OFFER_ADD,
  OFFER_EDIT,
  OFFER_PIN,
  OFFER_REMOVE,
  ONE_OFFER_FETCH,
} from "../actions/offers/offersActionConstants";
import {
  setOffers,
  setProfileOffers,
  fetchOneOfferSuccess,
  addOfferSuccess,
  fetchMineOffersSuccess,
  fetchProfileOffersSuccess,
  removeOfferSuccess,
  editOfferSuccess,
  fetchOffersSuccess,
  fetchOffersError,
  fetchMoreOffersSuccess,
  fetchMoreOffersError,
  addOfferError,
  fetchOneOfferError,
  fetchMineOffersError,
  fetchProfileOffersError,
  removeOfferError,
  editOfferError,
  clearFeaturedOffers,
  setMineHeaderOffers,
  fetchMineHeaderOffersSuccess,
  fetchMineHeaderOffersError,
  pinOfferSuccess,
  pinOfferError,
  addProfileOffers,
  clearSelectedOffer,
  // fetchAllOffersSuccess,
  // fetchAllOffersError,
  // setFeaturedOfferPage,
} from "../actions/offers/offersActions";
import { all, takeLatest, call, put, select } from "@redux-saga/core/effects";
import {
  attemptFetchProfileOffers,
  attemptFetchMoreOffers,
} from "../../request/offersRequest";
import { convertQueryStringForBackend } from "../../util/helpers/queryHelpers";
// import { setQueryString } from "../actions/filters/filtersActions";
import {
  OFFERS_FETCH_MORE,
  OFFERS_MINE_FETCH,
} from "../actions/offers/offersActionConstants";
import {
  addOffers,
  addPinnedOffers,
  clearOffers,
  setMineOffers,
  setNoMoreOffersStatus,
  setPinnedOffers,
  setTotalOffers,
} from "../actions/offers/offersActions";
import { selectUserId } from "../selectors/loginSelectors";
import {
  // selectFeaturedOfferPage,
  // selectOfferPage,
  selectOffers,
  selectPinnedOffers,
  selectTotalOffers,
} from "../selectors/offersSelectors";
// import history from "../utils/history";
// import { NOT_FOUND_PAGE } from "../../constants/pages";
import { makeErrorToastMessage } from "../utils/makeToastMessage";
import i18next from "i18next";
import {
  KEY_NAME,
  KEY_PAGE,
  KEY_SIZE,
  KEY_SORTBY,
} from "../../constants/queryStringConstants";
import { selectQueryString } from "../selectors/filtersSelectors";
import { setQueryString } from "../actions/filters/filtersActions";

function* fetchOffers(payload) {
  try {
    yield put(clearOffers());
    const newQueryString = new URLSearchParams(
      convertQueryStringForBackend(payload.payload.queryString)
    );
    const data = yield call(
      attemptFetchOffers,
      "?" + newQueryString.toString()
    );
    yield put(setTotalOffers(data.data.total));
    yield put(setOffers(data.data.offers.filter((offer) => !offer.pinned)));
    yield put(
      setPinnedOffers(data.data.offers.filter((offer) => offer.pinned))
    );
    yield put(fetchOffersSuccess());
  } catch (e) {
    yield put(fetchOffersError());
    yield call(console.log, e);
  }
}

function* fetchFeaturedOffers(payload) {
  try {
    yield put(clearFeaturedOffers());
    const newQueryString = new URLSearchParams(
      convertQueryStringForBackend(payload.payload.queryString)
    );
    const data = yield call(
      attemptFetchFeaturedOffers,
      "?" + newQueryString.toString()
    );
    yield put(setPinnedOffers(data.data));
    yield put(fetchOffersSuccess());
  } catch (e) {
    yield put(fetchOffersError());
    yield call(console.log, e);
  }
}
function* fetchMoreOffers(payload) {
  try {
    const data = yield call(
      attemptFetchMoreOffers,
      payload.payload?.page,
      payload.payload?.queryString
    );
    // if (payload.payload.queryString) {
    //   yield put(setQueryString(payload.payload.queryString));
    // }
    const oldOffers = yield select(selectOffers);
    const oldPinnedOffers = yield select(selectPinnedOffers);
    const totalNumberOfOffers = yield select(selectTotalOffers);
    if (oldOffers.length + oldPinnedOffers.length < totalNumberOfOffers) {
      yield put(addOffers(data.data.items.regularOffers));
      yield put(addPinnedOffers(data.data.items.pinnedOffers));
    }
    if (
      data.data.items.pinnedOffers.length +
        data.data.items.regularOffers.length <
        10 ||
      data.data.items.pinnedOffers.length +
        data.data.items.regularOffers.length >
        data.data.total
    ) {
      yield put(setNoMoreOffersStatus(true));
    }
    yield put(fetchMoreOffersSuccess());
  } catch (e) {
    yield put(fetchMoreOffersError());
    console.dir(e);
  }
}

function* createOffer(payload) {
  try {
    console.log(payload);
    const userId = yield select(selectUserId);
    const offerData = payload.payload.offerData;
    const formData = new FormData();
    formData.append("category[name]", offerData.category.name);
    formData.append(
      "condition",
      offerData.condition === "Novo"
        ? "novo"
        : offerData.condition === "Polovno"
        ? "polovno"
        : offerData.condition === "Kao novo"
        ? "kao novo"
        : "usluga"
    );
    formData.append("description", offerData.description);
    // formData.append("file", JSON.stringify(offerData.images))
    for (var i = 0; i < offerData.images.length; i++) {
      formData.append("file", offerData.images[i]);
    }
    formData.append("location[city]", offerData.location.city);
    formData.append("name", offerData.name);
    formData.append("subcategory", offerData.subcategory);
    console.log(formData);
    yield call(attemptAddOffer, userId, formData);
    yield put(addOfferSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(addOfferError());
    console.dir(e);
  }
}

function* fetchOneOffer(payload) {
  try {
    let offerId = payload.payload;
    console.log("sagabb", payload.payload);
    const queryObject = new URLSearchParams();
    if (typeof payload.payload === "object") {
      offerId = payload.payload.offerId;
      queryObject.set("view", "true");
    }
    const data = yield call(attemptFetchOneOffer, {
      offerId,
      queryObject,
    });
    if (!data?.data) throw new Error();
    yield put(clearSelectedOffer());
    yield put(fetchOneOfferSuccess(data?.data));
  } catch (e) {
    console.log(e?.response?.status);
    // if (e.response.status === 400) {
    //   yield call(history.push, NOT_FOUND_PAGE);
    // }
    yield call(makeErrorToastMessage, i18next.t("apiErrors.offerNotFound"));
    yield put(fetchOneOfferError());
  }
}

// function* createOffer(payload) {
function* fetchMineOffers() {
  try {
    const userId = yield select(selectUserId);
    const data = yield call(attemptFetchProfileOffers, userId);
    yield put(setMineOffers(data.data.offers));
    yield put(setTotalOffers(data.data.total));
    yield put(fetchMineOffersSuccess());
  } catch (e) {
    yield put(fetchMineOffersError());
    console.dir(e);
  }
}
function* fetchMineHeaderOffers() {
  try {
    const userId = yield select(selectUserId);
    const data = yield call(attemptFetchProfileOffers, userId);
    yield put(setMineHeaderOffers(data.data.offers.slice(0, 2)));
    yield put(fetchMineHeaderOffersSuccess());
  } catch (e) {
    yield put(fetchMineHeaderOffersError());
    console.dir(e);
  }
}

function* fetchProfileOffers(payload) {
  try {
    console.log(payload.payload);
    let userId;

    if (typeof payload.payload === "object") {
      userId = payload.payload.idProfile;
    } else userId = payload.payload;

    if (!userId || userId?.length === 0)
      throw new Error("User id is not defined!");

    let queryString = new URLSearchParams();
    queryString.set(KEY_SIZE, 10);
    if (payload.payload[KEY_PAGE]) {
      queryString.set(KEY_PAGE, payload.payload.page);
      if (payload.payload.searchValue?.length > 0)
        queryString.set(KEY_NAME, payload.payload.searchValue);
      queryString.set(KEY_SORTBY, payload.payload.sortOption.queryString);
      yield put(setQueryString(queryString.toString()));
    } else {
      queryString = yield select(selectQueryString);
    }

    console.log(queryString.toString());
    let data;
    if (payload.payload.isAdmin) {
      data = yield call(
        attemptFetchProfileOffersAsAdmin,
        userId,
        convertQueryStringForBackend(queryString)
      );
    } else {
      data = yield call(
        attemptFetchProfileOffers,
        userId,
        convertQueryStringForBackend(queryString)
      );
    }
    if (payload.payload.append) {
      yield put(addProfileOffers(data.data.offers));
    } else {
      yield put(setProfileOffers(data.data.offers));
    }
    yield put(setTotalOffers(data.data.total));
    yield put(fetchProfileOffersSuccess());
  } catch (e) {
    console.dir(e);
    // if (e.response.status === 400) {
    //   yield call(history.push, NOT_FOUND_PAGE);
    // }
    yield put(fetchProfileOffersError());
  }
}

function* removeOffer(payload) {
  try {
    const userId = yield select(selectUserId);
    const offerId = payload.payload.offerId;
    if (payload.payload.isAdmin) yield call(attemptRemoveOfferAsAdmin, offerId);
    else yield call(attemptRemoveOffer, userId, offerId);
    yield put(removeOfferSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(removeOfferError());
    console.dir(e);
  }
}
function* pinOffer(payload) {
  try {
    const offerId = payload.payload.offerId;
    yield call(attemptPinOffer, offerId);
    yield put(pinOfferSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(pinOfferError());
    console.dir(e);
  }
}

function* editOffer(payload) {
  try {
    const userId = yield select(selectUserId);
    const offerId = payload.payload.offerId;
    // const editedData = payload.payload.offerData;
    const offerData = payload.payload.offerData;
    const formData = new FormData();
    formData.append("category[name]", offerData.category.name);
    formData.append("condition", offerData.condition.toLowerCase());
    formData.append("description", offerData.description);
    // const oldImages = [];
    for (var i = 0; i < offerData.images.length; i++) {
      if (offerData.images[i] !== null) {
        if (typeof offerData.images[i] === "string") {
          formData.append("images[]", offerData.images[i]);
        } else {
          formData.append("file", offerData.images[i]);
        }
      }
    }
    // if (oldImages.length > 0) {
    //   formData.append("images", JSON.stringify(oldImages));
    // }
    // if (oldImages.length === offerData.images.length) {
    //   formData.append("file", "");
    // }
    formData.append("location[city]", offerData.location.city);
    formData.append("name", offerData.name);
    formData.append("subcategory", offerData.subcategory);
    if (payload.payload.isAdmin) {
      yield call(attemptEditOfferAsAdmin, offerId, formData);
    } else {
      yield call(attemptEditOffer, userId, offerId, formData);
    }
    yield put(editOfferSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(editOfferError());
    console.dir(e);
  }
}

export default function* offersSaga() {
  yield all([
    takeLatest(OFFERS_FETCH, fetchOffers),
    takeLatest(OFFER_ADD, createOffer),
    takeLatest(ONE_OFFER_FETCH, fetchOneOffer),
    takeLatest(OFFERS_FETCH_MORE, fetchMoreOffers),
    takeLatest(OFFERS_MINE_FETCH, fetchMineOffers),
    takeLatest(OFFERS_PROFILE_FETCH, fetchProfileOffers),
    takeLatest(OFFER_REMOVE, removeOffer),
    takeLatest(OFFER_EDIT, editOffer),
    takeLatest(OFFERS_MINE_HEADER_FETCH, fetchMineHeaderOffers),
    takeLatest(OFFERS_FEATURED_FETCH, fetchFeaturedOffers),
    takeLatest(OFFER_PIN, pinOffer),
    // takeLatest(OFFERS_ALL_FETCH, fetchAllOffers),
  ]);
}

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const CategoryCardNameContainer = styled(Box)`
  width: 234px;
  max-width: 234px;
  display: flex;
  padding-left: 18px;
  /* max-width: 300px; */
  flex-direction: row;
`;
export const CategoryCardNameTextContainer = styled(Box)`
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    height: 60px;
  }
`
export const CategoryCardNameText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${selectedTheme.colors.primaryPurple};
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 600px) {
    font-size: 18px;
    padding: 0;
  }
`;
export const CategoryCardImageContainer = styled(Box)`
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    height: 60px;
  }
`
export const CategoryCardImage = styled.img`
  width: 18px;
  height: 18px;
  filter: invert(58%) sepia(100%) saturate(431%) hue-rotate(355deg) brightness(104%) contrast(105%);
  margin-right: 9px;
  position: relative;
  top: 1px;
`;

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllProfiles,
  selectTotalProfiles,
} from "../../../store/selectors/profileSelectors";
import { fetchAllProfilesAsAdmin } from "../../../store/actions/profile/profileActions";
import {
  AdminUsersHeader,
  AdminUsersList,
  AdminUsersPageContainer,
  AdminUsersSearchField,
} from "./AdminUsersPage.styled";
import BigProfileCard from "../../../components/Cards/ProfileCard/BigProfileCard/BigProfileCard";
import { useTranslation } from "react-i18next";
import usePaging from "../../../hooks/useOffers/usePaging";
import { useState } from "react";
import Paging from "../../../components/Paging/Paging";
import useSorting from "../../../hooks/useOffers/useSorting";
import { sortUsersEnum } from "../../../enums/sortEnum";

const AdminUsersPage = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const paging = usePaging();
  const sorting = useSorting(() => {}, sortUsersEnum);
  const allUsers = useSelector(selectAllProfiles);
  const totalUsers = useSelector(selectTotalProfiles);
  const allUsersToShow = useMemo(
    () => (Array.isArray(allUsers?.users) ? allUsers?.users : []),
    [allUsers]
  );
  useEffect(() => {
    dispatch(
      fetchAllProfilesAsAdmin({
        currentPage: paging.currentPage,
        searchValue: searchValue,
        sortOption: sorting.selectedSortOptionLocally,
      })
    );
  }, [paging.currentPage, searchValue, sorting.selectedSortOptionLocally]);

  const handleChangePage = (newPage) => {
    paging.changePage(newPage);
  };

  const handleSearch = (data) => {
    paging.changePage(1);
    setSearchValue(data);
  };

  return (
    <AdminUsersPageContainer>
      <>
        <AdminUsersSearchField
          isAdmin
          handleSearch={handleSearch}
          placeholder={t("admin.users.searchPlaceholder")}
        />
        <AdminUsersHeader
          myOffers
          category
          hideGrid
          isAdmin
          users
          hideBackButton
          sorting={sorting}
        />
        <AdminUsersList>
          {allUsersToShow.map((singleUser) => (
            <BigProfileCard key={singleUser._id} profile={singleUser} />
          ))}
        </AdminUsersList>
        <Paging
          totalElements={totalUsers}
          current={paging.currentPage}
          changePage={handleChangePage}
        />
      </>
    </AdminUsersPageContainer>
  );
};

AdminUsersPage.propTypes = {
  children: PropTypes.node,
};

export default AdminUsersPage;

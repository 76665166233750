import React from "react";
import PropTypes from "prop-types";
import {
  SidebarProfileContainer,
  SidebarProfileImage,
  SidebarProfileName,
  SidebarProfileRole,
} from "./SidebarProfile.styled";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMineProfile } from "../../../../store/selectors/profileSelectors";

const SidebarProfile = () => {
  const { isMobile } = useIsMobile();
  const profile = useSelector(selectMineProfile);
  const { t } = useTranslation();

  return (
    <SidebarProfileContainer>
      <SidebarProfileImage
        src={getImageUrl(profile.image, variants.profileImage, isMobile)}
      />
      <SidebarProfileName>{profile.company.name}</SidebarProfileName>
      <SidebarProfileRole>{t("admin.navigation.role")}</SidebarProfileRole>
    </SidebarProfileContainer>
  );
};

SidebarProfile.propTypes = {
  profile: PropTypes.shape({
    image: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default SidebarProfile;

import React from "react";
import PropTypes from "prop-types";
import {
  Logo,
  NoReviewsAltText,
  NoReviewsContainer,
  NoReviewsText,
} from "./NoReviews.styled";
import { useTranslation } from "react-i18next";

const NoReviews = (props) => {
  const { t } = useTranslation();
  return (
    <NoReviewsContainer fullHeight={props.fullHeight}>
      <Logo />
      <NoReviewsText>{t("reviews.title")}</NoReviewsText>
      <NoReviewsAltText>{t("reviews.altTitle")}</NoReviewsAltText>
    </NoReviewsContainer>
  );
};

NoReviews.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
};

export default NoReviews;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import UserReviews from "../../UserReviews/UserReviews";

export const SecondStepCreateReviewContainer = styled(Box)`
  padding: 36px;
  @media (max-width: 600px) {
    padding: 0;
    padding-top: 36px;
  }
`;
export const ReviewCard = styled(UserReviews)`
  & > div {
    margin-bottom: 36px;
    margin-top: 18px;
    & ul {
      background-color: ${selectedTheme.colors.chatHeaderColor};
      padding: 0 14px;
    }
    & ul li {
      margin: 0;
    }
  }
  & * {
    overflow:hidden;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -24px;
    & > div {
      position: static;
      margin-top: 0px;
    }
  }
`;

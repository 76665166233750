import { createSelector } from "reselect";

const exchangeSelector = (state) => state.exchange;

export const selectExchange = createSelector(
    exchangeSelector,
    (state) => state.exchange
)
export const selectRequester = createSelector(
    exchangeSelector,
    (state) => state.requester
)
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../../../themes";

export const PostsNotFoundContainer = styled(Box)``;

export const PostsNotFoundText = styled(Typography)`
  width: 100%;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 18px;
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
`;

export const PostsNotFoundTextSecond = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  padding-left: 18px;
`;

export const PostsNotFoundButton = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryPurple};
  text-decoration: underline;
  cursor: pointer;
  vertical-align: unset;
`;

export const PostsNotFoundSkeleton = styled(Box)`
  margin: 18px 0 0 18px;
  display: flex;
`;

export const PostsNotFoundSkeletonImage = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
`;

import React from "react";
import PropTypes from "prop-types";
import { FilterContainer, FilterIcon } from "./OffersFilterButton.styled";

const OffersFilterButton = (props) => {
  const toggleFilters = () => {
    props?.toggleFilters();
  };
  return (
    <FilterContainer
      isAdmin={props?.isAdmin}
      onClick={toggleFilters}
      number={props?.offers?.filters?.numOfFiltersChosen}
      myOffers={props?.myOffers}
    >
      <FilterIcon />
    </FilterContainer>
  );
};

OffersFilterButton.propTypes = {
  isAdmin: PropTypes.bool,
  offers: PropTypes.shape({
    filters: PropTypes.shape({
      numOfFiltersChosen: PropTypes.number,
    }),
  }),
  myOffers: PropTypes.bool,
  toggleFilters: PropTypes.func,
};

OffersFilterButton.defaultProps = {
  isAdmin: false,
  offers: {
    filters: {
      numOfFiltersChosen: 0,
    },
  },
  myOffers: false,
  toggleFilters: () => {},
};

export default OffersFilterButton;

import { Box, Select } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const SelectStyled = styled(Select)`
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 2px;
    font-size: 16px;
    font-weight: 600;
    font-family: ${selectedTheme.fonts.textFont};
    cursor: pointer;
    & fieldset {
        border-color: ${props => props.borderColor ? props.borderColor : selectedTheme.colors.primaryPurple} !important;
    }
`
export const SelectIcon = styled(Box)`
    position: relative;
    top: -1px;
    left: -8px;
    cursor: pointer;
`
const CLOUDFLARE = "CLOUDFLARE";
// const IMAGE_KIT = "IMAGEKIT";

const IMAGE_PLATFORM = CLOUDFLARE;

export const variants = {
  offerCard: "offerCard",
  offerDetails: "offerDetails",
  profileImage: "profileImage",
  reviewCard: "reviewCard",
  chatHeader: "chatHeader",
  chatMessage: "chatMessage",
  chatCard: "chatCard",
  deleteChat: "chatHeader",
  profileCard: "profileCard",
  createReviewCard: "createReviewCard",
  carousel: "carousel",
  adminProfileCard: "reviewCard",
  categoryIcon: "categoryIcon"
};

const cloudFlareVariants = {
  offerCard: "primary",
  offerCardMobile: "primaryMobile",
  offerDetails: "primary",
  offerDetailsMobile: "primary",
  profileImage: "primary",
  profileImageMobile: "profileMobile",
  reviewCard: "review",
  reviewCardMobile: "review",
  chatHeader: "chatHeader",
  chatHeaderMobile: "chatHeader",
  chatMessage: "chatHeader",
  chatMessageMobile: "chatHeader",
  chatCard: "chatCard",
  chatCardMobile: "chatCard",
  profileCard: "profileCard",
  createReviewCard: "primaryMobile",
  carousel: "carousel",
  carouselMobile: "carouselMobile",
  categoryIcon: "categoryIcon"
};
export const getImageUrl = (imageUrl, variant, isMobile = false) => {
  let imageVariant = "";
  if (IMAGE_PLATFORM === CLOUDFLARE) {
    imageVariant = isMobile
      ? cloudFlareVariants[variant + "Mobile"]
      : cloudFlareVariants[variant];
  }
  return imageUrl + imageVariant;
};

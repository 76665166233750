import React from "react";
import PropTypes from "prop-types";
import { OptionIcon, OptionStyled } from "./Option.styled";

const Option = (props) => {
  return (
    <OptionStyled {...props} value={props.value}>
      {props.startIcon ? (
        <OptionIcon color={props.color}>{props.startIcon}</OptionIcon>
      ) : (
        <></>
      )}
      {props.children}
    </OptionStyled>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  color: PropTypes.any,
  startIcon: PropTypes.any,
  value: PropTypes.any,
  selected: PropTypes.bool,
};
Option.defaultProps = {
  // selected: true
};

export default Option;

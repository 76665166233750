import React from "react";
import PropTypes from "prop-types";
import { NextButton as NextButtonContainer } from "../../CreateReview.styled";
import selectedTheme from "../../../../themes";
import { useTranslation } from "react-i18next";

const NextButton = (props) => {
  const { t } = useTranslation();
  return (
    <NextButtonContainer
      variant="contained"
      buttoncolor={selectedTheme.colors.primaryPurple}
      fullWidth
      height="48px"
      onClick={props.onClick}
    >
      {props.removingReview
        ? t("admin.reviews.confirm")
        : t("reviews.leaveComment")}
    </NextButtonContainer>
  );
};

NextButton.propTypes = {
  onClick: PropTypes.func,
  removingReview: PropTypes.bool,
};

export default NextButton;

import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { SwapsIcon } from "./MySwapsButton.styled";
import PopoverComponent from "../../Popovers/PopoverComponent";
import { MyPosts } from "../../Popovers/MyPosts/MyPosts";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MySwapsButton = (props) => {
  const {t} = useTranslation();
  const location = useLocation();
  const [postsPopoverOpen, setPostsPopoverOpen] = useState(false);
  const [postsAnchorEl, setPostsAnchorEl] = useState(null);

  useEffect(() => {
    setPostsPopoverOpen(false);
  }, [location.pathname]);

  const openPostsPopover = (event) => {
    setPostsPopoverOpen(true);
    setPostsAnchorEl(event.currentTarget);
  };
  const closePostsPopover = () => {
    setPostsPopoverOpen(false);
    setPostsAnchorEl(null);
  };
  const closePopover = () => {
    setPostsPopoverOpen(false);
    props.handleAddOfferClick();
  };
  return (
    <>
      <IconButton
        aria-label={t("header.myOffers")}
        onClick={openPostsPopover}
        style={{
          background: selectedTheme.colors.primaryIconBackgroundColor,
          color: selectedTheme.colors.primaryPurple,
        }}
      >
        <SwapsIcon />
      </IconButton>
      <PopoverComponent
        anchorEl={postsAnchorEl}
        open={postsPopoverOpen}
        onClose={() => {
          setPostsPopoverOpen(false);
          setPostsAnchorEl(null);
        }}
        content={
          <MyPosts addOffer={closePopover} closePopover={closePostsPopover} />
        }
      />
    </>
  );
};

MySwapsButton.propTypes = {
  onClick: PropTypes.func,
  handleAddOfferClick: PropTypes.func,
};

export default MySwapsButton;

import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../../../../themes";

export const GivenReviewsHeaderContainer = styled(Box)`
  display: ${(props) => (!props.hasGivenReview ? "none" : "flex")};
  width: 100%;
  background-color: #f5edff;
  padding-top: 11px;
  padding-left: 18px;
  padding-bottom: 10px;
  margin-bottom: 18px;
  font-family: ${selectedTheme.fonts.textFont};
  border-radius: 4px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const CompanyLabel = styled(Box)`
  position: relative;
  top: 3px;
`

export const CompanyName = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

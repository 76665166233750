import { REVIEW_SET } from "../../actions/review/reviewActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  selectedReviews: [],
};

export default createReducer(
  {
    [REVIEW_SET]: setReviews,
  },
  initialState
);

function setReviews(state, action) {
  return {
    ...state,
    selectedReviews: action.payload,
  };
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import DropdownList from "../../../../../Dropdown/DropdownList/DropdownList";
import selectedTheme from "../../../../../../themes";
import { ReactComponent as DropdownDown } from "../../../../../../assets/images/svg/down-arrow.svg";
import { ReactComponent as DropdownUp } from "../../../../../../assets/images/svg/up-arrow.svg";
import {
  CloseIcon,
  NumberedIcon,
  SelectedItem,
  SelectedItemsContainer,
} from "./CheckboxDropdownList.styled";
import SearchField from "./SearchField/SearchField";

const CheckboxDropdownList = (props) => {
  const data = props.data;
  const [isOpened, setIsOpened] = useState(false);
  const handleDelete = (item) => {
    console.log(item);
    console.log(props.filters)
    props.setItemsSelected([...props.filters.filter((p) => p?._id !== item?._id)]);
  };
  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
    if (props.handleOpen) props.handleOpen();
  };
  return (
    <DropdownList
      title={props.title}
      textcolor={
        props.filters.length > 0
          ? selectedTheme.colors.primaryPurple
          : selectedTheme.colors.primaryText
      }
      dropdownIcon={
        <NumberedIcon number={props.filters.length}>{props.icon}</NumberedIcon>
      }
      toggleIconClosed={<DropdownDown />}
      toggleIconOpened={<DropdownUp />}
      fullWidth
      open={props?.open !== undefined ? props.open : isOpened}
      setIsOpened={handleOpen}
      toggleIconStyles={{
        backgroundColor: props.open
          ? "white"
          : selectedTheme.colors.primaryIconBackgroundColor,
      }}
      headerOptions={
        <React.Fragment>
          <SelectedItemsContainer>
            {props.filters.map((item) => (
              <SelectedItem
                key={props.companies ? item?.companyName : item.city}
                onClick={() => handleDelete(item)}
              >
                {props.companies
                  ? data.find(
                      (p) =>
                        p?.companyName?.toString() ===
                        item?.companyName?.toString()
                    )?.companyName
                  : data.find(
                      (p) => p?.city?.toString() === item?.city?.toString()
                    )?.city}

                <CloseIcon />
              </SelectedItem>
            ))}
          </SelectedItemsContainer>
          <SearchField
            placeholder={props.searchPlaceholder}
            value={props.toSearch}
            onChange={(event) => props.setToSearch(event.target.value)}
          />
        </React.Fragment>
      }
    >
      {props.children}
    </DropdownList>
  );
};

CheckboxDropdownList.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  filters: PropTypes.any,
  icon: PropTypes.node,
  setToSearch: PropTypes.func,
  setItemsSelected: PropTypes.func,
  data: PropTypes.any,
  searchPlaceholder: PropTypes.string,
  toSearch: PropTypes.string,
  setIsOpened: PropTypes.func,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  companies: PropTypes.bool,
  offers: PropTypes.any,
};

export default CheckboxDropdownList;

import React from "react";
import PropTypes from "prop-types";
import { PreviewCard, PublishButton } from "./ThirdPartCreateOffer.styled";
import selectedTheme from "../../../../themes";
import { CreateOfferFormContainer } from "../CreateOffer.styled";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const ThirdPartCreateOffer = (props) => {
  const { t } = useTranslation();

  const offer = useMemo(
    () => ({
      offer: {
        category: {
          name: props.informations.category,
        },
        subcategory: props.informations.subcategory,
        condition: props.informations.condition,
        _created: props?.offer?._created || new Date().toString(),
        images: props.informations.images.filter(
          (item) => item !== undefined && item !== null
        ),
        name: props.informations.nameOfProduct,
        description: props.informations.description,
        location: props.informations.location,
      },
    }),
    [props.informations]
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!props.isLoading) {
      props.handleSubmitOffer();
    }
  };

  return (
    <>
      <CreateOfferFormContainer
        currentStep={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <PreviewCard
          offer={offer}
          showBarterButton={false}
          showPublishButton={false}
          showExchangeButton={false}
          createOffer
          hideViews
          singleOffer
          previewCard
        />
      </CreateOfferFormContainer>
      <PublishButton
        type="submit"
        variant="contained"
        height="48px"
        buttoncolor={selectedTheme.colors.primaryPurple}
        textcolor="white"
        isLoading={props.isLoading}
        onClick={handleSubmit}
      >
        {t("offer.publish")}
      </PublishButton>
    </>
  );
};

ThirdPartCreateOffer.propTypes = {
  children: PropTypes.any,
  handleSubmitOffer: PropTypes.func,
  informations: PropTypes.object,
  isLoading: PropTypes.bool,
  offer: PropTypes.any,
};

export default ThirdPartCreateOffer;

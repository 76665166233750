import * as Yup from "yup";
import i18n from "../i18n";
export default (locations) =>
  Yup.object().shape({
    firmName: Yup.string().required(i18n.t("editProfile.labelNameRequired")),
    firmPIB: Yup.string()
      // .required(i18n.t("editProfile.labelPIBRequired"))
      .min(8, i18n.t("register.PIBnoOfCharacters"))
      .max(8, i18n.t("register.PIBnoOfCharacters")),
    firmLocation: Yup.string()
      .oneOf(
        locations.map((l) => l.city),
        i18n.t("editProfile.labelLocationValid")
      )
      .required(i18n.t("register.labelLocationRequired")),
    firmWebsite: Yup.string(),
    // firmApplink: Yup.string(),
    firmPhone: Yup.string()
      .min(6, i18n.t("editProfile.labelPhoneValid"))
      .max(14, i18n.t("editProfile.labelPhoneValid"))
      .required(i18n.t("register.phoneNumberRequired")),
  });

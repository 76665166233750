import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const REVIEW_GIVE_SCOPE = "REVIEW_GIVE_SCOPE";
export const REVIEW_GIVE = createFetchType(REVIEW_GIVE_SCOPE);
export const REVIEW_GIVE_SUCCESS = createSuccessType(REVIEW_GIVE_SCOPE);
export const REVIEW_GIVE_ERROR = createErrorType(REVIEW_GIVE_SCOPE);

export const REVIEW_GET_SCOPE = "REVIEW_GET_SCOPE";
export const REVIEW_GET = createFetchType(REVIEW_GET_SCOPE);
export const REVIEW_GET_SUCCESS = createSuccessType(REVIEW_GET_SCOPE);
export const REVIEW_GET_ERROR = createErrorType(REVIEW_GET_SCOPE);

export const REVIEW_REMOVE_SCOPE = "REVIEW_REMOVE_SCOPE";
export const REVIEW_REMOVE = createFetchType(REVIEW_REMOVE_SCOPE);
export const REVIEW_REMOVE_SUCCESS = createSuccessType(REVIEW_REMOVE_SCOPE);
export const REVIEW_REMOVE_ERROR = createErrorType(REVIEW_REMOVE_SCOPE);

export const REVIEW_GET_AS_ADMIN_SCOPE = 'REVIEW_GET_AS_ADMIN_SCOPE';
export const REVIEW_GET_AS_ADMIN = createFetchType(REVIEW_GET_AS_ADMIN_SCOPE);
export const REVIEW_GET_AS_ADMIN_SUCCESS = createSuccessType(REVIEW_GET_AS_ADMIN_SCOPE);
export const REVIEW_GET_AS_ADMIN_ERROR = createErrorType(REVIEW_GET_AS_ADMIN_SCOPE);

export const REVIEW_SET = createSetType("REVIEW_SET");

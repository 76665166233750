import { Box } from "@mui/material";
import styled from "styled-components";
import { PrimaryAnimatedButton } from "../../../Styles/globalStyleComponents";
import ItemDetailsCard from "../../ItemDetailsCard/ItemDetailsCard";

export const CreateOfferFormContainer = styled(Box)`
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  width: 380px;
`;
export const PreviewCard = styled(ItemDetailsCard)`
  width: auto;
  border: 0;
  margin: 0;
  padding-bottom: 0;
`;
export const PublishButton = styled(PrimaryAnimatedButton)`
  width: 332px;
  margin-top: 16px;
  /* position: absolute;
  bottom: 12px; */

  @media (max-width: 600px) {
    width: 339px;
    height: 44px;
    position: absolute;
    bottom: 26px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }

  @media (max-height: 629px) {
    position: relative;
    margin-top: 40px;
  }
`;

import { ReactComponent as Category } from "../../../../../assets/images/svg/category.svg";
import { ReactComponent as CategoryChosen } from "../../../../../assets/images/svg/category-chosen.svg";
import styled from "styled-components";

export const CategoryChosenIcon = styled(CategoryChosen)`
  @media (max-width: 600px) {
    width: 21px;
    height: 24px;
    position: relative;
    bottom: 2px;
  }
`;
export const CategoryIcon = styled(Category)`
  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 2px;
  }
`;

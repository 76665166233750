import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { HeaderSelectContainer, SelectOption } from "./HeaderSelect.styled";

const HeaderSelect = (props) => {
  const sorting = props?.sorting;
  const { t } = useTranslation();
  const handleChangeSelect = (event) => {
    sorting?.changeSorting(event.target.value);
  };
  return (
    <HeaderSelectContainer
      hideSorting={props?.hideSorting}
      value={
        sorting?.selectedSortOption?.value
          ? sorting?.selectedSortOption
          : "default"
      }
      onChange={handleChangeSelect}
      myOffers={props?.myOffers}
      isMyOffers={props?.isMyOffers}
    >
      <SelectOption style={{ display: "none" }} value="default">
        {t("reviews.sortBy")}
      </SelectOption>
      {Object.keys(sorting?.sortOptions).map((property) => {
        if (sorting?.sortOptions[property].value === 0) return;
        return (
          <SelectOption
            value={sorting?.sortOptions[property]}
            key={sorting?.sortOptions[property].value}
          >
            {sorting?.sortOptions[property].mainText}
          </SelectOption>
        );
      })}
    </HeaderSelectContainer>
  );
};

HeaderSelect.propTypes = {
  sorting: PropTypes.any,
  myOffers: PropTypes.bool,
  hideSorting: PropTypes.bool,
  isMyOffers: PropTypes.bool,
};
HeaderSelect.defaultProps = {
  myOffers: false,
  hideSorting: false,
};

export default HeaderSelect;

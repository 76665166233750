import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchHeaderChats } from "../../../store/actions/chat/chatActions";
import {
  selectLatestChats,
  selectSelectedChat,
} from "../../../store/selectors/chatSelectors";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import HeaderPopover from "../HeaderPopover/HeaderPopover";
import PropTypes from "prop-types";
import { makeErrorToastMessage } from "../../../store/utils/makeToastMessage";
import { EyeIcon } from "./MyMessages.styled";
import { DIRECT_CHAT_PAGE, MESSAGES_LIST_PAGE } from "../../../constants/pages";
import {
  dynamicRouteMatches,
  replaceInRoute,
} from "../../../util/helpers/routeHelpers";
import useIsMobile from "../../../hooks/useIsMobile";

export const MyMessages = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectUserId);
  const chats = useSelector(selectLatestChats);
  const selectedChat = useSelector(selectSelectedChat);
  const history = useHistory();
  const [lastChats, setLastChats] = useState([]);

  const convertMessages = (messages) => {
    return messages
      .map((item) => {
        let interlocutor = userId === item.participants[0]._id ? 1 : 0;
        return {
          src: item.participants[interlocutor]?.image,
          title: item.participants[interlocutor]?.company?.name,
          onClick: () => goToMessage(item?._id),
          text: "Proizvod: ",
          bigText: item.offer.name,
        };
      })
      .slice(0, 2);
  };

  useEffect(() => {
    if (history.location.pathname.includes("messages")) return;
    if (userId?.length > 1) {
      dispatch(fetchHeaderChats(userId));
    }
  }, [userId]);
  useEffect(() => {
    if (chats?.length > 0) {
      setLastChats([...convertMessages(chats)]);
    }
  }, [chats]);
  const goToMessages = () => {
    if (lastChats.length !== 0) {
      console.log(chats);
      if (isMobile) {
        history.push(MESSAGES_LIST_PAGE);
      } else {
        goToMessage(chats[0]?._id);
      }
    } else {
      makeErrorToastMessage(t("messages.noMessagesToast"));
      props.closePopover();
    }
  };
  const goToMessage = (chatId) => {
    if (
      !dynamicRouteMatches(DIRECT_CHAT_PAGE) ||
      selectedChat?._id !== chatId
    ) {
      history.push(
        replaceInRoute(DIRECT_CHAT_PAGE, {
          chatId,
        })
      );
    }
    props.closePopover();
  };
  return (
    <HeaderPopover
      title={t("header.myMessages")}
      items={lastChats}
      buttonOnClick={goToMessages}
      buttonText={t("header.checkEverything")}
      buttonIcon={<EyeIcon color={chats?.length === 0} />}
      noMessages={chats?.length === 0}
    />
  );
};

MyMessages.propTypes = {
  closePopover: PropTypes.func,
};

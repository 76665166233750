import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { TextField } from "../../TextFields/TextField/TextField";

export const DirectChatNewMessageContainer = styled(Box)`
  border-top: 1px solid ${selectedTheme.colors.messageBackground};
  height: 82px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 72px);
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 36px;
  @media (max-width: 600px) {
    margin-left: 18px;
    margin-right: 18px;
    width: calc(100% - 36px);
    gap: 18px;
  }
`;
export const NewMessageField = styled(TextField)`
  height: 48px;
  margin: 0;
  flex-grow: 1;
  & div {
    height: 48px;
  }
`;
export const SendButton = styled(PrimaryButton)`
  width: 180px;
  height: 48px;
  @media (max-width: 600px) {
    width: 30vw;
    float: right;
  }
`;

import {
  CLEAR_FILTERS,
  SET_CATEGORY,
  SET_COMPANY,
  SET_FILTERS,
  SET_HEADER_STRING,
  SET_IS_APPLIED,
  SET_LOCATIONS,
  SET_MANUAL_SEARCH_STRING,
  SET_PAYMENTS,
  SET_QUERY_STRING,
  SET_SEARCH_STRING,
  SET_SORT_OPTION,
  SET_SUBCATEGORY,
} from "./filtersActionConstants";

export const setFilters = (payload) => ({
  type: SET_FILTERS,
  payload,
});
export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});
export const setFilteredCategory = (payload) => ({
  type: SET_CATEGORY,
  payload,
});
export const setFilteredSubcategory = (payload) => ({
  type: SET_SUBCATEGORY,
  payload,
});
export const setManualSearchString = (payload) => ({
  type: SET_MANUAL_SEARCH_STRING,
  payload,
});
export const setFilteredLocations = (payload) => ({
  type: SET_LOCATIONS,
  payload,
});
export const setFilteredPayments = (payload) => ({
  type: SET_PAYMENTS,
  payload,
});
export const setFilteredCompany = (payload) => ({
  type: SET_COMPANY,
  payload,
});
export const setFilteredSortOption = (payload) => ({
  type: SET_SORT_OPTION,
  payload,
});
export const setIsAppliedStatus = (payload) => ({
  type: SET_IS_APPLIED,
  payload,
});
export const setQueryString = (payload) => ({
  type: SET_QUERY_STRING,
  payload,
});
export const setHeaderString = (payload) => ({
  type: SET_HEADER_STRING,
  payload,
});
export const setSearchString = (payload) => ({
  type: SET_SEARCH_STRING,
  payload,
});

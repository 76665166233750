import React from "react";
import PropTypes from "prop-types";
import {
  BackButtonContainer,
  BackButtonText,
  BlogCreator,
  BlogCreatorContainer,
  BlogCreatorRole,
  BlogDetailsContainer,
  BlogImage,
  BlogImageContainer,
  BlogText,
  BlogTitle,
} from "./BlogDetails.styled";
import { useMemo } from "react";
import { BLOG_DATA } from "../../enums/blogDummy";
import { ArrowButton } from "../Buttons/ArrowButton/ArrowButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BLOG_PAGE } from "../../constants/pages";

const BlogDetails = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const singleBlog = useMemo(() => {
    if (props.blogId) {
      return BLOG_DATA.filter((item) => item._id == props.blogId);
    }
  }, [props.blogId]);
  console.log(singleBlog);
  const handleBackButton = () => {
    history.push(BLOG_PAGE);
  };
  return (
    <BlogDetailsContainer sidebar={props.sidebar}>
      <BackButtonContainer>
        <ArrowButton side="left" onClick={handleBackButton} />
        <BackButtonText onClick={handleBackButton}>
          {t("blog.backButton")}
        </BackButtonText>
      </BackButtonContainer>
      <BlogCreatorContainer>
        <BlogCreator>{singleBlog[0].creator}</BlogCreator>
        <BlogCreatorRole>{singleBlog[0].creatorRole}</BlogCreatorRole>
      </BlogCreatorContainer>
      <BlogTitle>{singleBlog[0].title}</BlogTitle>
      <BlogText>{singleBlog[0].description}</BlogText>
      <BlogImageContainer sidebar={props.sidebar}>
        <BlogImage src={singleBlog[0].image} sidebar={props.sidebar} />
      </BlogImageContainer>
      <BlogText>{singleBlog[0].description2}</BlogText>
    </BlogDetailsContainer>
  );
};

BlogDetails.displayName = "BlogDetails";

BlogDetails.propTypes = {
  children: PropTypes.node,
  blogId: PropTypes.string,
  sidebar: PropTypes.bool,
};

export default BlogDetails;

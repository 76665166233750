import styled from "styled-components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import selectedTheme from "../../themes";
import ProfileStats from "../Cards/ProfileCard/ProfileStats/ProfileStats";
import ItemDetailsHeaderCard from "../ItemDetails/ItemDetailsHeaderCard/ItemDetailsHeaderCard";

export const ProfileHeader = styled(Box)`
  margin-top: 60px;

  @media screen and (max-width: 600px) {
    width: calc(100vw - 36px);
    position: absolute;
    top: 25px;
    left: 18px;
  }
`;
export const ProfileHeaderIconContainer = styled(Box)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin-bottom: 18px;
    svg {
      width: 14px;
    }
  }
`;
export const ProfileHeaderText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;

  color: ${selectedTheme.colors.primaryDarkTextThird};
  margin-left: 7.2px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;
export const ProfileMiniHeader = styled(ItemDetailsHeaderCard)``;
export const ProfileMiniStats = styled(ProfileStats)`
  position: relative;
  width: 100%;
  margin-left: 0;
  margin-top: -1rem;
  margin-bottom: 36px;
  border-radius: 0 0 4px 4px;
  border-left: 1px solid
    ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.borderNormal};
  border-right: 1px solid
    ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.borderNormal};
  border-bottom: 1px solid
    ${(props) =>
      props.isMyProfile
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.borderNormal};

  @media (max-width: 600px) {
    width: calc(100vw - 36px);
  }
`;

import { QUERY_STRING_SET_REDUX } from "../../actions/queryString/queryStringActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  queryString: ""
};

export default createReducer(
  {
    [QUERY_STRING_SET_REDUX]: setQueryStringRedux,
  },
  initialState
);

function setQueryStringRedux(state, action) {
  return {
    ...state,
    queryString: action.payload,
  };
}

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ChatInfo,
  ChatCardContainer,
  UserImage,
  UserImgWrapper,
  UserName,
  ProfileNameContainer,
  Date,
} from "./ChatCard.styled";
import MobileOfferDetails from "./MobileOfferDetails/MobileOfferDetails";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import BlockedProfile from "../ProfileCard/BlockedProfile/BlockedProfile";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { formatDateLocaleTwoDigitsYear } from "../../../util/helpers/dateHelpers";
import { useTranslation } from "react-i18next";

// Chat card is shown only on mobile phones (route /messages)
const ChatCard = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectUserId);

  const chat = useMemo(() => {
    return props.chat;
  }, [props.chat]);

  const routeToItem = () => {
    props.navigateToChat(chat?._id);
  };
  const interlocutor = useMemo(() => {
    return userId === chat?.participants[0]._id ? 1 : 0;
  }, [userId, chat]);

  console.log(chat);

  let date = formatDateLocaleTwoDigitsYear(
    new window.Date(
      props?.chat?.messages?.[props?.chat?.messages?.length - 1]?._created
    )
  );

  const lastMessage = useMemo(() => {
    if (chat?.messages?.[chat?.messages?.length - 1]?.text === "")
      return t("common.trampaIsFinished");
    return chat?.messages?.[chat?.messages?.length - 1]?.text;
  }, [chat]);

  console.log(lastMessage);

  return (
    <ChatCardContainer onClick={routeToItem}>
      <UserImgWrapper>
        <UserImage
          src={getImageUrl(
            chat?.participants[interlocutor].image,
            variants.chatCard,
            isMobile
          )}
        />
      </UserImgWrapper>
      <ChatInfo isBlocked={props.chat.participants[interlocutor]?._blocked}>
        <ProfileNameContainer>
          <UserName>{chat?.offer?.name}</UserName>
          {(props.chat?.participants[interlocutor]?._blocked ||
            props.chat?.participants[interlocutor]?._deleted) && (
            <BlockedProfile
              redText
              chatCard
              shortText
              aboveTitle
              hideIcon
              deleted={props.chat?.participants[interlocutor]?._deleted}
            />
          )}
        </ProfileNameContainer>
        <MobileOfferDetails chat={lastMessage} />
      </ChatInfo>

      <Date>{date}</Date>
    </ChatCardContainer>
  );
};

ChatCard.propTypes = {
  chat: PropTypes.any,
  navigateToChat: PropTypes.func,
};
ChatCard.defaultProps = {
  halfwidth: false,
  sponsored: false,
};

export default ChatCard;

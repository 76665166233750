import React from "react";
import { useHistory } from "react-router-dom";
import { ChatColumn } from "../../components/ChatColumn/ChatColumn";
import { DIRECT_CHAT_PAGE } from "../../constants/pages";
import ChatLayout from "../../layouts/ChatLayout/ChatLayout";
import { replaceInRoute } from "../../util/helpers/routeHelpers";
import { MessagesListPageContainer } from "./MessagesListPage.styled";

export const MessagesListPage = () => {
  const history = useHistory();
  const navigateToChat = (chatId) => {
    setTimeout(() => {
      history.push(
        replaceInRoute(DIRECT_CHAT_PAGE, {
          chatId: chatId,
        })
      );
    }, 120);
  };
  return (
    <MessagesListPageContainer>
      <ChatLayout content={<ChatColumn navigateToChat={navigateToChat} />} />
    </MessagesListPageContainer>
  );
};

MessagesListPage.propTypes = {};

export default MessagesListPage;

import React from "react";
import PropTypes from "prop-types";
import { AddOfferButtonContainer } from "./AddOfferButton.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../../../themes";
import { useDispatch } from "react-redux";
import { toggleCreateOfferModal } from "../../../../../store/actions/modal/modalActions";

const AddOfferButton = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => {
    props.toggleDrawer();
    dispatch(toggleCreateOfferModal({}))
  };
  return (
    <AddOfferButtonContainer
      type="submit"
      variant="contained"
      buttoncolor={selectedTheme.colors.primaryYellow}
      textcolor="black"
      onClick={handleClick}
    >
      {t("header.addOffer")}
    </AddOfferButtonContainer>
  );
};

AddOfferButton.propTypes = {
  toggleDrawer: PropTypes.func,
  addOffer: PropTypes.func,
};

export default AddOfferButton;

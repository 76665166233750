import React from "react";
import PropTypes from "prop-types";
import {
  CategoryCardImage,
  CategoryCardImageContainer,
  CategoryCardNameContainer,
  CategoryCardNameText,
  CategoryCardNameTextContainer,
} from "./CategoryCardName.styled";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";

const CategoryCardName = (props) => {
  return (
    <CategoryCardNameContainer onClick={props.onClick}>
      {props.image && (
        <CategoryCardImageContainer>
          <CategoryCardImage
            src={getImageUrl(props.image, variants.categoryIcon)}
          />
        </CategoryCardImageContainer>
      )}
      <CategoryCardNameTextContainer>
        <CategoryCardNameText>{props.categoryName}</CategoryCardNameText>
      </CategoryCardNameTextContainer>
    </CategoryCardNameContainer>
  );
};

CategoryCardName.propTypes = {
  children: PropTypes.node,
  categoryName: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string,
};

export default CategoryCardName;

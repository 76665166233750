import React from "react";
import PropTypes from "prop-types";
import {
  UserReviewsSkeletonCircle,
  UserReviewsSkeletonContainer,
  UserReviewsSkeletonHrLine,
  UserReviewsSkeletonItemsContainer,
  UserReviewsSkeletonLine,
  UserReviewsSkeletonLineFifth,
  UserReviewsSkeletonLineForth,
  UserReviewsSkeletonLineOne,
  UserReviewsSkeletonLineSecond,
  UserReviewsSkeletonLineThird,
  UserReviewsSkeletonSquare,
  UserReviewsSkeletonSquareImage,
  UserReviewsSkeletonSquareImageContainer,
  UserReviewsSkeletonSquareInfo,
  UserReviewsSkeletonSquareInfoFirstLine,
  UserReviewsSkeletonSquareInfoSecondLine,
} from "./UserReviewsSkeleton.styled";

const UserReviewsSkeleton = (props) => {
  const array = Array.apply(null, Array(props.numOfElements)).map(() => {});
  return (
    <UserReviewsSkeletonContainer>
      {array.map((item, index) => (
        <>
          <UserReviewsSkeletonItemsContainer key={index}>
            <UserReviewsSkeletonLine skeleton={props.skeleton}>
              <UserReviewsSkeletonCircle skeleton={props.skeleton} />
              <UserReviewsSkeletonLineOne skeleton={props.skeleton} />
            </UserReviewsSkeletonLine>
            <UserReviewsSkeletonLine skeleton={props.skeleton}>
              <UserReviewsSkeletonSquare />
              <UserReviewsSkeletonLineSecond />
            </UserReviewsSkeletonLine>
            <UserReviewsSkeletonLine skeleton={props.skeleton}>
              <UserReviewsSkeletonLineThird />
            </UserReviewsSkeletonLine>
            <UserReviewsSkeletonLine skeleton={props.skeleton}>
              <UserReviewsSkeletonLineForth />
            </UserReviewsSkeletonLine>
            <UserReviewsSkeletonLine skeleton={props.skeleton}>
              <UserReviewsSkeletonLineFifth />
            </UserReviewsSkeletonLine>
            <UserReviewsSkeletonSquareImageContainer>
              <UserReviewsSkeletonSquareImage skeleton={props.skeleton} />
              <UserReviewsSkeletonSquareInfo>
                <UserReviewsSkeletonSquareInfoFirstLine
                  skeleton={props.skeleton}
                />
                <UserReviewsSkeletonSquareInfoSecondLine
                  skeleton={props.skeleton}
                />
              </UserReviewsSkeletonSquareInfo>
            </UserReviewsSkeletonSquareImageContainer>
          </UserReviewsSkeletonItemsContainer>
          <UserReviewsSkeletonHrLine skeleton={props.skeleton} />
        </>
      ))}
    </UserReviewsSkeletonContainer>
  );
};

UserReviewsSkeleton.propTypes = {
  children: PropTypes.node,
  numOfElements: PropTypes.number,
  skeleton: PropTypes.bool,
};

export default UserReviewsSkeleton;

import styled from "styled-components";
import { ReactComponent as Prices } from "../../../../../assets/images/svg/dollar.svg";
import selectedTheme from "../../../../../themes";

export const PricesIcon = styled(Prices)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke-width: 0;
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;

import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/svg/logo-vertical.svg";
import {
  ForgotPasswordPageContainer,
  ForgotPasswordDescription,
  ForgotPasswordTitle,
  FormContainer,
  ErrorMessage,
  LoginTextContainer,
  LoginAltText,
} from "./ForgotPassword.styled";
import { TextField } from "../../components/TextFields/TextField/TextField";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { NavLink, useHistory } from "react-router-dom";
import { FORGOT_PASSWORD_MAIL_SENT } from "../../constants/pages";
import selectedTheme from "../../themes";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/actions/user/userActions";
import forgotPasswordValidation from "../../validations/forgotPasswordValidation";
import forgotPasswordInitialValues from "../../initialValues/forgotPasswordInitialValues";
import Link from "../../components/Link/Link";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailNotFoundStatus, setEmailNotFoundStatus] = useState(false);

  const handleResponseSuccess = () => {
    history.push({
      pathname: FORGOT_PASSWORD_MAIL_SENT,
      state: { email: formik.values.email },
    });
  };
  const handleResponseError = () => {
    setEmailNotFoundStatus(true);
  };

  const handleSubmit = (values) => {
    dispatch(
      forgotPassword({
        email: values.email,
        handleResponseSuccess,
        handleResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: forgotPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <ForgotPasswordPageContainer>
      <Logo />

      <ForgotPasswordTitle component="h1" variant="h5">
        {t("forgotPassword.title")}
      </ForgotPasswordTitle>

      <ForgotPasswordDescription component="h1" variant="h6">
        {t("forgotPassword.description")}
      </ForgotPasswordDescription>

      <FormContainer component="form" onSubmit={formik.handleSubmit}>
        {/* <Backdrop position="absolute" isLoading={isLoading} /> */}

        <TextField
          name="email"
          placeholder={t("common.labelEmail")}
          margin="normal"
          value={formik.values.email}
          error={
            (formik.touched.email && Boolean(formik.errors.email)) ||
            emailNotFoundStatus
          }
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          autoFocus
          fullWidth
        />

        {formik.errors.email && formik.touched.email && (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        )}
        {emailNotFoundStatus && (
          <ErrorMessage>{t("forgotPassword.mailNotFound")}</ErrorMessage>
        )}

        <PrimaryButton
          type="submit"
          variant="contained"
          height="48px"
          fullWidth
          buttoncolor={selectedTheme.colors.primaryPurple}
          textcolor="white"
          disabled={formik.values.email?.length === 0}
        >
          {t("common.send")}
        </PrimaryButton>

        <LoginTextContainer>
          <LoginAltText>{t("register.loginText")}</LoginAltText>

          <Link
            to="/login"
            component={NavLink}
            underline="hover"
            align="center"
          >
            {t("register.login")}
          </Link>
        </LoginTextContainer>
      </FormContainer>
    </ForgotPasswordPageContainer>
  );
};

export default ForgotPasswordPage;

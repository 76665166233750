import { all, takeLatest, call, put, select } from "@redux-saga/core/effects";
import {
  attemptFetchReview,
  attemptGiveReview,
  attemptRemoveReview,
  attemptFetchReviewsAsAdmin,
} from "../../request/reviewRequest";
import {
  REVIEW_GET,
  REVIEW_GIVE,
  REVIEW_REMOVE,
  REVIEW_GET_AS_ADMIN,
} from "../actions/review/reviewActionConstants";
import {
  fetchReviewsError,
  fetchReviewsSuccess,
  giveReviewError,
  giveReviewSuccess,
  removeReviewError,
  removeReviewSuccess,
  fetchReviewsAsAdminSuccess,
  fetchReviewsAsAdminError,
  setReviews,
} from "../actions/review/reviewActions";
import { selectUserId } from "../selectors/loginSelectors";

function* fetchReviews(payload) {
  try {
    console.log(payload);
    const data = yield call(attemptFetchReview, payload.payload);
    yield put(setReviews([...data.data.reviews]));
    yield put(fetchReviewsSuccess());
  } catch (e) {
    yield put(fetchReviewsError());
    console.dir(e);
  }
}

function* giveReview(payload) {
  try {
    const userId = yield select(selectUserId);
    yield call(attemptGiveReview, userId, payload.payload.review);
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
    yield put(giveReviewSuccess());
  } catch (e) {
    yield put(giveReviewError());
    console.dir(e);
  }
}

function* removeReview(payload) {
  try {
    console.log(payload);
    yield call(attemptRemoveReview, payload.payload.reviewId);
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
    yield put(removeReviewSuccess());
  } catch (e) {
    yield put(removeReviewError());
    console.dir(e);
  }
}

function* fetchReviewsAsAdmin(payload) {
  try {
    console.log(payload);
    const data = yield call(attemptFetchReviewsAsAdmin, payload.payload);
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
    console.log(data);
    yield put(setReviews(data.data));
    yield put(fetchReviewsAsAdminSuccess());
  } catch (e) {
    yield put(fetchReviewsAsAdminError());
    console.dir(e);
  }
}

export default function* reviewSaga() {
  yield all([
    takeLatest(REVIEW_GET, fetchReviews),
    takeLatest(REVIEW_GIVE, giveReview),
    takeLatest(REVIEW_REMOVE, removeReview),
    takeLatest(REVIEW_GET_AS_ADMIN, fetchReviewsAsAdmin),
  ]);
}

import React from 'react'
import PropTypes from 'prop-types'
import Login from '../../components/Login/Login'

const LoginPage = () => {
  return (
    <Login isAdmin={false}/>
  )
}

LoginPage.propTypes = {
  children: PropTypes.node,
}

export default LoginPage
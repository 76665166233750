import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as Settings } from "../../../assets/images/svg/settings.svg";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-striked.svg";
import { ProfileAvatar } from "../MyProfile/MyProfile.styled";

export const HeaderPopoverContainer = styled(Box)``;
export const PopoverTitle = styled(Typography)`
  background-color: ${selectedTheme.colors.primaryPurple};
  color: white;
  width: 100%;
  min-width: 270px;
  font-family: ${selectedTheme.fonts.textFont};
  @media (max-width: 600px) {
    min-width: 227px;
    font-size: 14px;
  }
`;
export const PopoverList = styled(List)`
  width: 100%;
  max-width: 270px;
  background-color: "white";
`;
export const PopoverListItem = styled(ListItem)`
  align-items: flex-start;
`;
export const PopoverListItemAvatar = styled(Avatar)`
  position: relative;
  cursor: pointer;
  top: 4px;
`;
export const PopoverListItemProfileAvatar = styled(ProfileAvatar)`
  cursor: pointer;
`;
export const PopoverListItemAvatarContainer = styled(ListItemAvatar)``;
export const PopoverButton = styled(Button)`
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 0;
`;
export const PopoverListItemTextContainer = styled(ListItemText)`
  & span {
    font-weight: 700;
    color: ${selectedTheme.colors.primaryPurple};
    cursor: pointer;
    max-width: 170px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  & p {
    overflow: hidden;
    /* max-height: 32px; */
    font-size: 0.81rem;
    & svg {
      position: relative;
      top: 2px;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
`;
export const SettingsIcon = styled(Settings)``;
export const EyeIcon = styled(Eye)`
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;
export const PopoverButtonsContainer = styled(Box)`
  flex-direction: column;
  display: ${(props) => (props.hideButtons ? "none" : "flex")};
  align-items: flex-end;
`;
export const PopoverNoItemsText = styled(Typography)`
  /* text-align: center; */
  width: 100%;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 18px;
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
`;
export const PopoverAddItemText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  padding-left: 18px;
  padding-bottom: 91px;
`;
export const PopoverAddItemButton = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryPurple};
  text-decoration: underline;
  cursor: pointer;
`;
export const NameOfProduct = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${selectedTheme.fonts.textFont};
  letter-spacing: 0.02em;
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const SecondaryTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
export const SecondaryText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 9px;
  letter-spacing: 0.01em;
  color: ${selectedTheme.colors.primaryDarkText};
`;

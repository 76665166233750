import styled from "styled-components";
import { PrimaryButton } from "../../../../Buttons/PrimaryButton/PrimaryButton";

export const AddOfferButtonContainer = styled(PrimaryButton)`
  width: 165px;
  height: 44px;
  position: relative;
  bottom: -5px;
  font-weight: 600;
`;

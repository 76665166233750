import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PrivacyPolicyContainer } from "../../../pages/PrivacyPolicy/PrivacyPolicyPage.styled";
import {
  PrivacyPolicyHeaderLine,
  // PrivacyPolicyHeaderParagraph,
  PrivacyPolicyHeaderTitle,
} from "./PrivacyPolicyHeader.styled";

const PrivacyPolicyHeader = () => {
  const { t } = useTranslation();
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyHeaderTitle>
        {t("privacyPolicy.header.title")}
      </PrivacyPolicyHeaderTitle>
      <PrivacyPolicyHeaderLine />
      {/* <PrivacyPolicyHeaderParagraph>{t("privacyPolicy.header.paragraph")}</PrivacyPolicyHeaderParagraph> */}
    </PrivacyPolicyContainer>
  );
};

PrivacyPolicyHeader.propTypes = {
  children: PropTypes.node,
};

export default PrivacyPolicyHeader;

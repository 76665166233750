import { Box } from "@mui/material";
import styled from "styled-components";

export const OffersContainer = styled(Box)`
  display: ${props => props.show ? "flex" : "none"};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  position: relative;
  padding-bottom: 60px;
`;
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const UserButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
export const UserName = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  padding-top: 8px;
  padding-right: 10px;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 600;
  white-space: nowrap;
`;
export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "./FormikErrorMessage.styled";

const FormikErrorMessage = (props) => {
  return (
    <>
      {props.showBasic &&
        (props.formik.errors.firmName && props.formik.touched.firmName ? (
          <ErrorMessage>{props.formik.errors.firmName}</ErrorMessage>
        ) : props.formik.errors.firmPIB && props.formik.touched.firmPIB ? (
          <ErrorMessage>{props.formik.errors.firmPIB}</ErrorMessage>
        ) : (
          props.formik.errors.firmLocation &&
          props.formik.touched.firmLocation && (
            <ErrorMessage>{props.formik.errors.firmLocation}</ErrorMessage>
          )
        ))}
      {props.showDetails &&
        (props.formik.errors.firmWebsite && props.formik.touched.firmWebsite ? (
          <ErrorMessage>{props.formik.errors.firmWebsite}</ErrorMessage>
        ) : (
          props.formik.errors.firmPhone &&
          props.formik.touched.firmPhone && (
            <ErrorMessage>{props.formik.errors.firmPhone}</ErrorMessage>
          )
        ))}
    </>
  );
};

FormikErrorMessage.propTypes = {
  formik: PropTypes.any,
  showDetails: PropTypes.bool,
  showBasic: PropTypes.bool,
};

export default FormikErrorMessage;

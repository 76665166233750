import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const ColorIconContainer = styled(Box)`
  cursor: pointer;
  width: ${(props) =>
    props.aboveEditor ? "13.5px" : props.selectedInPopover ? "17px" : "15px"};
  height: ${(props) =>
    props.aboveEditor ? "13.5px" : props.selectedInPopover ? "17px" : "15px"};
  background-color: ${(props) => props.backgroundColor};
  border: 1.24px solid
    ${(props) =>
      props.selected ? selectedTheme.colors.colorPicker.border : "transparent"};
`;

import React from "react";
import PropTypes from "prop-types";
import { EditableContainer } from "./OfferDescription.styled";
import RichTextComponent from "../../../../RichTextComponent/RichTextComponent";
import { isJsonString } from "../../../../../util/helpers/jsonHelper";
import { useMemo } from "react";

const OfferDescription = (props) => {
  const description = useMemo(
    () =>
      isJsonString(props?.value) ? (
        <RichTextComponent itemDetails value={props?.value || ""} readOnly />
      ) : (
        <></>
      ),
    [props?.value]
  );
  return <EditableContainer>{description}</EditableContainer>;
};

OfferDescription.propTypes = {
  value: PropTypes.string,
};

export default OfferDescription;

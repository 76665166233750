import React from "react";
import PropTypes from "prop-types";
import BlogComponent from "../../components/Blog/BlogComponent";

const BlogPage = () => {
  return <BlogComponent />;
};

BlogPage.propTypes = {
  children: PropTypes.node,
};

export default BlogPage;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as PIB } from "../../../../assets/images/svg/pib.svg";
import selectedTheme from "../../../../themes";

export const UserLabeledCardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 9px;
  @media (max-width: 600px) {
    max-height: 216px;
  }
`;
export const UserLabeledCardDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;
export const UserName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${selectedTheme.colors.primaryPurple};
  padding-top: 7px;
  white-space: nowrap;
  @media (max-width: 600px) {
    padding-top: 9px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
`;
export const PIBContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
export const PIBIcon = styled(PIB)`
  width: 12px;
  height: 12px;
  position: relative;
  top: 1.5px;
`;
export const PIBText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${selectedTheme.colors.primaryDarkText};
  white-space: nowrap;
`;
export const UserImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 100%;
`;

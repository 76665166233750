import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { PrimaryButton } from "../../../Buttons/PrimaryButton/PrimaryButton";

export const CheckButtonContainer = styled(PrimaryButton)`
  width: 180px;
  height: 48px;
  position: absolute;
  bottom: 9px;
  right: 9px;
  &:hover button {
    background-color: ${props => !props.disabled && `${selectedTheme.colors.primaryPurple} !important`};
    color: ${props => !props.disabled && `white !important`};
  }
  @media (max-width: 1150px) {
    display: none;
  }
`;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const NotAllowedChatText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const NotAllowedChatContainer = styled(Box)`
    text-align: center;
    border-top: 1px solid ${selectedTheme.colors.messageBackground};
    margin: 0 36px;
    padding-top: 30px;
`
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FieldLabel, SelectOption } from "../FirstPartCreateOffer.styled";
import { SelectField } from "../../CreateOffer.styled";
import { CategoryContainer } from "./OfferCategoryField.styled";
// import {
  // getImageUrl,
  // variants,
// } from "../../../../../util/helpers/imageUrlGetter";
// import useIsMobile from "../../../../../hooks/useIsMobile";

const OfferCategoryField = (props) => {
  const { t } = useTranslation();
  // const { isMobile } = useIsMobile();
  const formik = props.formik;
  return (
    <>
      <FieldLabel leftText={t("offer.category")} />
      <SelectField
        defaultValue={formik.values.category}
        onChange={(value) => {
          formik.setFieldValue("category", value.target.value);
        }}
        value={formik.values.category}
      >
        <SelectOption style={{ display: "none" }} value="default">
          {t("offer.choseCategory")}
        </SelectOption>
        {props.categories.map((cat, i) => {
          return (
            <SelectOption
              key={i}
              value={cat.name}
              onClick={() => props.handleSubcategories(cat.name)}
            >
              <CategoryContainer>
                {/* <CategoryIcon
                  src={getImageUrl(cat.image, variants.categoryIcon, isMobile)}
                /> */}
                {cat.name}
              </CategoryContainer>
            </SelectOption>
          );
        })}
      </SelectField>
    </>
  );
};

OfferCategoryField.propTypes = {
  formik: PropTypes.any,
  handleSubcategories: PropTypes.func,
  categories: PropTypes.array,
};

export default OfferCategoryField;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";


export const IconWithNumberContainer = styled(Box)`
    position: relative;
    padding-top: 3px;
`
export const Number = styled(Box)`
    background-color: ${selectedTheme.colors.primaryPurple};
    border-radius: 100%;
    color: white;
    position: absolute;
    width: 16px;
    height: 16px;
    font-size: 10px;
    bottom: 0;
    right: -5px;
    font-family: ${selectedTheme.fonts.textFont};
`
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredCategory } from "../../store/actions/filters/filtersActions";
import { selectCategories } from "../../store/selectors/categoriesSelectors";
import { selectSelectedCategory } from "../../store/selectors/filtersSelectors";

const useCategoryFilter = () => {
  const selectedCategory = useSelector(selectSelectedCategory);
  const allCategories = useSelector(selectCategories);
  const dispatch = useDispatch();
  const [selectedCategoryLocally, setSelectedCategoryLocally] = useState({});
  const initialOption = useMemo(() => {
    return {
      _id: 0,
    };
  }, []);

  useEffect(() => {
    setSelectedCategoryLocally(selectedCategory);
  }, [selectedCategory]);

  // Set selected category locally in state
  // If second argument is true, then selected category is also updated in redux
  const setSelectedCategory = (category) => {
    setSelectedCategoryLocally(category);
    // if (immediateApply) {
    //   dispatch(setFilteredCategory(category));
    // }
  };

  // Find category object by providing its name
  const findCategory = (categoryName) => {
    return allCategories.find((category) => category.name === categoryName);
  };

  // Get all subcategories by providing its category name
  const getSubcategories = (categoryName) => {
    let category = findCategory(categoryName);
    return category?.subcategories ? category.subcategories : [];
  };

  // Update selected category in redux
  const apply = () => {
    dispatch(setFilteredCategory(selectedCategoryLocally));
  };

  // Clear category chosen
  const clear = () => {
    setSelectedCategoryLocally(initialOption);
    dispatch(setFilteredCategory(initialOption));
  };

  return {
    selectedCategory,
    selectedCategoryLocally,
    setSelectedCategory,
    getSubcategories,
    findCategory,
    allCategories,
    apply,
    clear,
  };
};

export default useCategoryFilter;

import {
  CHAT_ADD_MESSAGE,
  CHAT_CLEAR,
  CHAT_FETCH,
  CHAT_FETCH_ERROR,
  CHAT_FETCH_SUCCESS,
  CHAT_HEADER_FETCH,
  CHAT_HEADER_FETCH_ERROR,
  CHAT_HEADER_FETCH_SUCCESS,
  CHAT_NEW_FETCH,
  CHAT_NEW_FETCH_ERROR,
  CHAT_NEW_FETCH_SUCCESS,
  CHAT_ONE_FETCH,
  CHAT_ONE_FETCH_ERROR,
  CHAT_ONE_FETCH_SUCCESS,
  CHAT_ONE_SET,
  CHAT_REMOVE_MESSAGE,
  CHAT_SEND_ERROR,
  CHAT_SEND_FETCH,
  CHAT_SEND_SUCCESS,
  CHAT_SET,
  CHAT_TOTAL_SET,
  GET_EXIST_CHAT,
  GET_EXIST_CHAT_ERROR,
  GET_EXIST_CHAT_SUCCESS,
} from "./chatActionConstants";

export const fetchChats = (payload) => ({
  type: CHAT_FETCH,
  payload,
});
export const fetchHeaderChats = (payload) => ({
  type: CHAT_HEADER_FETCH,
  payload,
});
export const setChats = (payload) => ({
  type: CHAT_SET,
  payload,
});
export const setChatsTotal = (payload) => ({
  type: CHAT_TOTAL_SET,
  payload,
});
export const fetchOneChat = (payload) => ({
  type: CHAT_ONE_FETCH,
  payload,
});
export const setOneChat = (payload) => ({
  type: CHAT_ONE_SET,
  payload,
});
export const sendMessage = (payload) => ({
  type: CHAT_SEND_FETCH,
  payload,
});
export const clearChat = () => ({
  type: CHAT_CLEAR,
});
export const startNewChat = (payload) => ({
  type: CHAT_NEW_FETCH,
  payload,
});
export const fetchChatsSuccess = () => ({
  type: CHAT_FETCH_SUCCESS,
});
export const fetchHeaderChatsSuccess = () => ({
  type: CHAT_HEADER_FETCH_SUCCESS,
});
export const fetchOneChatSuccess = () => ({
  type: CHAT_ONE_FETCH_SUCCESS,
});
export const sendMessageSuccess = () => ({
  type: CHAT_SEND_SUCCESS,
});
export const startNewChatSuccess = () => ({
  type: CHAT_NEW_FETCH_SUCCESS,
});
export const fetchChatsError = () => ({
  type: CHAT_FETCH_ERROR,
});
export const fetchHeaderChatsError = () => ({
  type: CHAT_HEADER_FETCH_ERROR,
});
export const fetchOneChatError = () => ({
  type: CHAT_ONE_FETCH_ERROR,
});
export const sendMessageError = () => ({
  type: CHAT_SEND_ERROR,
});
export const startNewChatError = () => ({
  type: CHAT_NEW_FETCH_ERROR,
});
export const addNewMessage = (payload) => ({
  type: CHAT_ADD_MESSAGE,
  payload,
});
export const removeLastMessage = () => ({
  type: CHAT_REMOVE_MESSAGE,
});
export const getExistChat = (payload) => ({
  type: GET_EXIST_CHAT,
  payload,
});
export const getExistChatSuccess = () => ({
  type: GET_EXIST_CHAT_SUCCESS,
});
export const getExistChatError = () => ({
  type: GET_EXIST_CHAT_ERROR,
});

import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "../../assets/images/svg/arrow-down.svg";
import selectedTheme from "../../themes";
import ScrollContainer from "react-indiana-drag-scroll";
import { IconButton } from "../Buttons/IconButton/IconButton";

export const VerticalScrollerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-right: 5px;
  /* height: 100%; */

  /* @media screen and (max-width: 1200px) {
    height: 465px;
  } */

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 340px;
  }
`;
export const Arrow = styled(IconButton)`
  border: 1px solid ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 8px;
  padding-top: 10px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.2s all ease;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple};
    & svg path {
      stroke: white;
    }
  }

  ${(props) =>
    props.disabled &&
    `
        border 1px solid ${selectedTheme.colors.iconStrokeDisabledColor};
        & svg path {
            stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
            transition: 0.2s all ease;
            
        }
    `}
`;
export const ListContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  margin: 18px 18px;
  user-select: none;

  img {
    margin: 0;
    margin-bottom: 18px;
  }

  @media (max-width: 1200px) {
    margin: 18px 0;
  }
`;
export const ArrowIconContainer = styled(Box)`
  transform: ${(props) =>
    props.side === "up" ? "rotate(-90deg)" : "rotate(90deg)"};
`;

export const ArrowIcon = styled(DownArrow)`
  /* ${(props) =>
    props.side === "up" &&
    `
        transform: rotate(-90deg);
    `} */
  width: 18px;
  height: 18px;
  & path {
    ${(props) =>
      props.disabled &&
      `
        stroke: ${selectedTheme.colors.iconStrokeDisabledColor}
        `}
  }
`;

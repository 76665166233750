import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchPayments = () =>
  getRequest(apiEndpoints.payment.getUsersPayments);
export const attemptAddNewPayment = (payload) =>
  postRequest(apiEndpoints.payment.postUsersPayments, payload);

export const attemptEditPayment = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.payment.putUsersPayments, {
      id: payload.id,
    }),
    payload.body
  );

export const attemptDeletePayment = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.payment.deleteUsersPayments, {
      id: payload.id,
    })
  );

import React from "react";
import PropTypes from "prop-types";
import {
  Arrow,
  ArrowIcon,
  PageNumber,
  PagingContainer,
  ThreeDots,
} from "./Paging.styled";
import { useTranslation } from "react-i18next";

const Paging = (props) => {
  const {t} = useTranslation();

  // Determining total pages
  const pages = props.pages
    ? props.pages
    : props.totalElements
    ? Math.ceil(props.totalElements / props.elementsPerPage)
    : 1;

  let moving = 0;
  // Making array of pages which contains 2 pages before and after current page
  const pagesAsArray = Array.apply(null, Array(5)).map(() => {});

  // Showing 3 dots if current page is away more than 3 of starting or ending page
  const threeDotsBefore = props.current - 2 > 1;
  const threeDotsAfter = props.current + 2 < pages;
  return (
    <PagingContainer className={props.className}>
      {/* Left arrow */}
      <Arrow
        aria-label={t("labels.prevPage")}
        onClick={() => props.changePage(props.current - 1)}
        disabled={props.current - 1 < 1}
      >
        <ArrowIcon side="left" />
      </Arrow>

      {props.customPaging ? (
        props.children
      ) : (
        <>
          {threeDotsBefore && (
            <React.Fragment>
              <PageNumber onClick={() => props.changePage(1)}>1</PageNumber>
              {props.current - 3 !== 1 && <ThreeDots>...</ThreeDots>}
            </React.Fragment>
          )}
          {/* Pages */}
          {pagesAsArray.map((item, index) => {
            const pageNum = props.current - 2 + moving++;
            if (pageNum > pages) return;
            if (pageNum < 1) return;
            return (
              <PageNumber
                current={pageNum === props.current}
                key={index}
                onClick={() => props.changePage(pageNum)}
              >
                {pageNum}
              </PageNumber>
            );
          })}
          {threeDotsAfter && (
            <React.Fragment>
              {props.current + 3 !== pages && <ThreeDots>...</ThreeDots>}
              <PageNumber onClick={() => props.changePage(pages)}>
                {pages}
              </PageNumber>
            </React.Fragment>
          )}{" "}
        </>
      )}

      {/* Right arrow */}
      <Arrow
        aria-label={t("labels.nextPage")}
        onClick={() => props.changePage(props.current + 1)}
        disabled={props.current + 1 > pages}
      >
        <ArrowIcon side="right" />
      </Arrow>
    </PagingContainer>
  );
};

Paging.propTypes = {
  children: PropTypes.any,
  totalElements: PropTypes.number,
  elementsPerPage: PropTypes.number,
  pages: PropTypes.number,
  current: PropTypes.number,
  changePage: PropTypes.func,
  customPaging: PropTypes.bool,
  className: PropTypes.string,
};
Paging.defaultProps = {
  elementsPerPage: 10,
};

export default Paging;

import { Container, Grid } from "@mui/material";
import styled from "styled-components";

export const ItemDetailsLayoutContainer = styled(Container)`
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-right: 54px;
  max-width: 100vw;
  position: relative;
  height: 100%;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const ContentRightCardContainer = styled(Grid)`
  margin: 0;
  width: 100%;
`;

export const RightCard = styled(Grid)`
  margin-top: 0;
  margin-left: 0;
  padding-right: 18px;
  padding-left: 0;
  ${(props) => props.profile && `min-width: 350px;`}
  margin-bottom: 18px;

  @media screen and (min-width: 600px) {
    margin-top: 34px;
    /* margin-left: ${(props) => (props.profile ? "0" : "36px")}; */
    /* padding-left: ${(props) => (props.singleOffer ? "36px" : 0)}; */
    border-top-right-radius: 4px;
    min-width: 0;
    ${(props) => props.singleOffer && `width: 100%`}
  }

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media (max-width: 600px) {
    width: 100%;

  }
`;
export const Content = styled(Grid)`
  width: 100%;
  @media (max-width: 600px) {
    padding-right: 16px;
  }
`;

import * as Yup from "yup";
import YupPassword from 'yup-password';
YupPassword(Yup);
import i18n from "../../i18n";
export default Yup.object().shape({
  mail: Yup.string()
    .email(i18n.t("forgotPassword.emailFormat"))
    .required(i18n.t("login.usernameRequired")),
  registerPassword: Yup.string()
    .required(i18n.t("login.passwordRequired"))
    .min(8, i18n.t("login.passwordLength"))
    .minLowercase(1, i18n.t("password.strongPassword"))
    .minUppercase(1, i18n.t("password.strongPassword"))
    .minSymbols(1, i18n.t("password.strongPassword"))
    .minNumbers(1, i18n.t("password.strongPassword"))
});

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { Icon } from "../../../../Icon/Icon";
import { ReactComponent as Location } from "../../../../../assets/images/svg/location.svg";

export const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  margin-bottom: 7px;
  font-size: 12px;
  /* @media (max-width: 600px) {
    ${(props) => props.shouldHideResponsive && `display: none;`}
  } */
  @media (max-width: 600px) {
    margin-bottom: 3px;
  }
`;
export const DetailIcon = styled(Icon)`
  display: flex;
  align-items: center;
  & svg {
    width: 22px;
    position: relative;
  }
  @media (max-width: 600px) {
    & svg {
      width: 14px;
    }
  }
`;
export const DetailText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${(props) =>
    props.ismyprofile ? "white" : selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 16px;
  position: relative;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export const LocationIcon = styled(Location)``;

import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import { ReactComponent as Close } from "../../../../../../assets/images/svg/close-white.svg";
import IconWithNumber from "../../../../../Icon/IconWithNumber/IconWithNumber";

export const SelectedItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;
export const SelectedItem = styled(Box)`
  margin-top: 2px;
  background-color: ${selectedTheme.colors.primaryPurple};
  border-radius: 8px;
  color: white;
  padding-left: 8px;
  padding-right: 6px;
  line-height: 12px;
  letter-spacing: 0.02em;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  cursor: pointer;
  margin-right: 3px;
  height: 22px;
`;
export const CloseIcon = styled(Close)`
  position: relative;
  top: 3px;
  left: 2px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;
export const NumberedIcon = styled(IconWithNumber)`
  @media (max-width: 600px) {
    & div {
      width: 14px;
      height: 14px;
    }
  }
`
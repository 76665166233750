import styled, { keyframes } from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../themes";

const skeletonAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonItems};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonItems}
    }
`;

const skeletonBackgroundAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground}
    }
`;

export const SkeletonUserReviewsHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const SkeletonUserReviewsHeaderFirstline = styled(Box)`
  width: 145px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonUserReviewsHeaderFilter = styled(Box)`
  display: flex;
`;

export const SkeletonUserReviewsHeaderSecondline = styled(Box)`
  width: 118px;
  height: 18px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonUserReviewsHeaderFilterRound = styled(Box)`
  width: 18px;
  height: 18px;
  margin-left: 9px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonAnimation} 2s infinite;
`;

export const SkeletonUserReviewsMainContainer = styled(Box)`
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

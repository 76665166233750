import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CheckboxInput,
  CheckboxInputContainer,
  CheckboxInputText,
  FormContainer,
  // RegisterDescription,
} from "./ThirdPartOfRegistration.styled";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components/TextFields/TextField/TextField";
import AutoSuggestTextField from "../../../../components/TextFields/AutoSuggestTextField/AutoSuggestTextField";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../store/selectors/locationsSelectors";
import { fetchLocations } from "../../../../store/actions/locations/locationsActions";
import thirdPartInitialValues from "../../../../initialValues/registerInitialValues/thirdPartInitialValues";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import thirdPartValidation from "../../../../validations/registerValidations/thirdPartValidation";
import { PrimaryAnimatedButton } from "../../../../components/Styles/globalStyleComponents";

const ThirdPartOfRegistration = (props) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const { t } = useTranslation();
  const locations = useSelector(selectLocations);
  const dispatch = useDispatch();
  console.log(checkboxValue);
  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const handleSubmit = () => {
    if (
      formik.values.website?.length !== 0 &&
      !formik.values.website.match(
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
      )
    ) {
      formik.setFieldError("website");
    } else {
      props.handleSubmit(formik.values);
    }
  };

  const formik = useFormik({
    initialValues: thirdPartInitialValues(props.informations),
    validationSchema: thirdPartValidation(locations),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormContainer component="form" onSubmit={formik.handleSubmit}>
      {/* <RegisterDescription component="p" variant="p">
        {t("register.descriptionThird")}
      </RegisterDescription> */}
      <TextField
        name="phoneNumber"
        placeholder={t("common.labelPhone")}
        margin="normal"
        type="number"
        value={formik.values.phoneNumber}
        onChange={(event) => {
          console.log(event);
          formik.setFieldValue("phoneNumber", event.target.value);
        }}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        autoFocus
        fullWidth
        onInput={(e) => {
          e.target.value =
            e.target.value[0] === "0" && e.target.value.length > 1
              ? "0" +
                String(
                  Math.max(0, parseInt(e.target.value)).toString().slice(0, 14)
                )
              : Math.max(0, parseInt(e.target.value)).toString().slice(0, 14);
        }}
      />
      <AutoSuggestTextField
        placeholder={t("common.labelLocation")}
        data={locations.map((item) => ({ name: item.city }))}
        value={formik.values.location}
        error={formik.touched.location && Boolean(formik.errors.location)}
        onChange={(event, { newValue }) =>
          formik.setFieldValue("location", newValue)
        }
      />
      <TextField
        name="website"
        placeholder={t("common.labelWebsite")}
        margin="normal"
        type="text"
        value={formik.values.website}
        onChange={formik.handleChange}
        error={formik.touched.website && Boolean(formik.errors.website)}
        helperText={formik.touched.website && formik.errors.website}
        fullWidth
      />
      <CheckboxInputContainer>
        <CheckboxInput onClick={() => setCheckboxValue(!checkboxValue)} />
        <CheckboxInputText>
          {t("register.acceptTermsCheckbox")}
        </CheckboxInputText>
      </CheckboxInputContainer>

      <ErrorMessage formik={formik} />
      <PrimaryAnimatedButton
        type="submit"
        variant="contained"
        height="48px"
        fullWidth
        textcolor="white"
        isLoading={props.isLoading}
        disabled={
          formik.values.phoneNumber.length === 0 ||
          formik.values.location.length === 0 ||
          !checkboxValue
        }
      >
        {t("common.continue")}
      </PrimaryAnimatedButton>
    </FormContainer>
  );
};

ThirdPartOfRegistration.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  informations: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default ThirdPartOfRegistration;

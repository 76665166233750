import React from "react";
import PropTypes from "prop-types";
import {
  ReviewQuoteContainer,
  ReviewQuoteTextContainer,
  ReviewQuoteText,
  ThumbContainer,
  ThumbDown,
  ThumbUp,
} from "./ReviewQuote.styled";
import { reviewEnum } from "../../../../../enums/reviewEnum";

const ReviewQuote = (props) => {
  return (
    <ReviewQuoteContainer>
      <ThumbContainer item>
        {props.isSuccessfulSwap?.toLowerCase() ===
        reviewEnum.YES.mainText.toLowerCase() ? (
          <ThumbUp color="success" />
        ) : (
          <ThumbDown color="error" />
        )}
      </ThumbContainer>
      <ReviewQuoteTextContainer item>
        <ReviewQuoteText quote={props?.quote === ""}>
          &quot;{props.quote}&quot;
        </ReviewQuoteText>
      </ReviewQuoteTextContainer>
    </ReviewQuoteContainer>
  );
};

ReviewQuote.propTypes = {
  quote: PropTypes.string,
  isSuccessfulSwap: PropTypes.string,
};

export default ReviewQuote;

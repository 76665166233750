import React from "react";
import PropTypes from "prop-types";
import {
  SidebarNavigationContainer,
  SidebarNavigationItem,
  SidebarNavigationItemIcon,
  SidebarNavigationListContainer,
  SidebarNavigationTitle,
} from "./SidebarNavigation.styled";
import { useTranslation } from "react-i18next";
import { isInRoute, routeMatches } from "../../../../util/helpers/routeHelpers";
import { ADMIN_HOME_PAGE, ADMIN_USERS_PAGE } from "../../../../constants/pages";
import { ADMIN_NAVIGATION } from "../../../../constants/adminNavigation";
import history from "../../../../store/utils/history";

const SidebarNavigation = () => {
  const { t } = useTranslation();
  const goToRoute = (route) => {
    history.push(route);
  };
  return (
    <SidebarNavigationContainer>
      <SidebarNavigationTitle>
        {t("admin.navigation.menu")}
      </SidebarNavigationTitle>
      <SidebarNavigationListContainer>
        {ADMIN_NAVIGATION.map((value) => {
          let isRouteActive = isInRoute(value.route);
          if (
            routeMatches(ADMIN_HOME_PAGE) &&
            routeMatches(ADMIN_USERS_PAGE, value.route)
          )
            isRouteActive = true;
          return (
            <SidebarNavigationItem
              active={isRouteActive}
              key={value.text}
              onClick={() => goToRoute(value.route)}
            >
              <SidebarNavigationItemIcon active={isRouteActive}>
                {value.icon}
              </SidebarNavigationItemIcon>
              {value.text}
            </SidebarNavigationItem>
          );
        })}
      </SidebarNavigationListContainer>
    </SidebarNavigationContainer>
  );
};

SidebarNavigation.propTypes = {
  children: PropTypes.node,
};

export default SidebarNavigation;

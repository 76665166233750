import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonOfferCardsContainer,
  LeftPart,
  RightPart,
  SkeletonAuthor,
  SkeletonColumnContainer,
  SkeletonDescription,
  SkeletonDescriptionLine,
  SkeletonDetail,
  SkeletonExchangeButton,
  SkeletonExchangeLine,
  SkeletonGroup,
  SkeletonImage,
  SkeletonLocation,
  SkeletonMessageButton,
  SkeletonMessageButtonSecond,
  SkeletonOfferCardContainer,
  SkeletonRowGroup,
  SkeletonTitle,
  SkeletonTitleAboveImage,
  SpreadLine,
} from "./SkeletonOfferCard.styled";
import useIsMobile from "../../../../hooks/useIsMobile";

const SkeletonOfferCard = (props) => {
  const { isMobile } = useIsMobile();
  return (
    <SkeletonOfferCardsContainer skeleton={props.skeleton}>
      <SkeletonOfferCardContainer vertical={props.vertical}>
        <SkeletonTitleAboveImage vertical={props.vertical} />
        <LeftPart vertical={props.vertical}>
          <SkeletonImage vertical={props.vertical} />
          <SkeletonColumnContainer>
            <SkeletonTitle />
            <SkeletonGroup>
              <SkeletonAuthor vertical={props.vertical} />
              <SkeletonLocation vertical={props.vertical} />
            </SkeletonGroup>
            <SkeletonRowGroup aboveChat={props.aboveChat}>
              <SkeletonDetail />
              <SkeletonDetail />
              <SkeletonDetail />
              <SkeletonDetail />
            </SkeletonRowGroup>
          </SkeletonColumnContainer>
        </LeftPart>
        <SpreadLine />
        <RightPart>
          <SkeletonDescription />
          <SkeletonDescriptionLine />
          <SkeletonDescriptionLine />
          <SkeletonDescriptionLine />
          <SkeletonDescriptionLine />
        </RightPart>
        <SkeletonExchangeButton>
          <SkeletonExchangeLine />
        </SkeletonExchangeButton>
        <SkeletonMessageButton vertical={props.vertical} />
        {isMobile && <SkeletonMessageButtonSecond vertical={props.vertical} />}
      </SkeletonOfferCardContainer>
    </SkeletonOfferCardsContainer>
  );
};

SkeletonOfferCard.propTypes = {
  children: PropTypes.node,
  skeleton: PropTypes.bool,
  vertical: PropTypes.bool,
  aboveChat: PropTypes.bool,
};
SkeletonOfferCard.defaultProps = {
  skeleton: false,
  vertical: false,
  aboveChat: false,
};

export default SkeletonOfferCard;

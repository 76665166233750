import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const ErrorText = styled(Typography)`
  color: red;
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  top: -12px;
  height: 20px;
  font-size: 14px;
`;
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import HorizontalScroller from "../../../Scroller/HorizontalScroller";
import VerticalScroller from "../../../Scroller/VerticalScroller";

export const Details = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.singleOffer ? "row" : "column-reverse")};
  justify-content: start;
  ${(props) => !props.previewCard && `flex: 2;`}
  /* flex: 2; */
  gap: 12px;
  ${(props) => props.hasScrollBar && !props.exchange && `height: 340px;`}
  /* overflow-y: auto; */
  overflow-y: hidden;
  ${(props) => !props.previewCard && `padding-bottom: 50px;`}

  /* ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 3px;
  } */

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 15px;
    height: 100%;
    /* min-height: 300px; */
    ${(props) =>
      !props.hasScrollBar &&
      props.exchange &&
      `
    overflow: hidden;
    max-height: none;`}
  }
`;

export const OfferTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  flex: 1;
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 20px;
  padding: ${(props) => (props.singleOffer ? 0 : "0 60px")};

  @media screen and (max-width: 600px) {
    padding: 0 8px;
    margin-bottom: 18px;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
  }
`;
export const OfferLittleDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: ${(props) => (!props.halfwidth ? "no-wrap" : "wrap")};
  justify-content: space-between;
  padding: ${(props) => (props.singleOffer ? 0 : "0 60px")};
  margin-top: 10px;
  padding-right: 18px;
  overflow-y: ${(props) => !props.previewCard && `auto`};
  ${(props) =>
    !props.singleOffer &&
    `
    position: relative;
    top: 180px;
    height: 0;
  `}
  @media (max-width: 600px) {
    padding: 0 8px;
    /* position: absolute; */
    /* top: 270px; */
    justify-content: start;
    /* height: 300px; */
    ${(props) => props.previewCard && `height: 100px;`}
    width: 97%;
  }
`;
export const ScrollerHorizontal = styled(HorizontalScroller)`
  min-height: 144px;
  min-width: 144px;
  max-width: 100%;
  max-height: 144px;
  align-items: start;
  /* & div {
    margin: 0 9px;
  } */
`;
export const ScrollerVertical = styled(VerticalScroller)`
  min-height: 144px;
  min-width: 144px;
  max-width: 100%;
  /* & div {
    margin: 0 9px;
  } */
`;
export const OfferInfoContainer = styled(Box)`
  ${(props) => props.previewCard === undefined && `height: 500px;`}
  overflow-y: auto;
  width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 3px;
  }
  @media screen and (min-width: 601px) and (max-width: 1200px) {
    ${(props) => props.singleOffer && `flex: 2`};
  }
  @media (max-width: 600px) {
    height: auto;
  }
`;
export const OfferDescriptionTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 16px;
  @media (max-width: 600px) {
    font-size: 9px;
    line-height: 13px;
  }
`;
export const OfferDescriptionText = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 22px;
  white-space: pre-line;
  max-width: 100%;
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 100%;
  }
`;
export const DesciprtionPostDate = styled(Typography)`
  display: none;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryText};
  margin-top: 18px;
  &::before {
    content: "Objavljeno: ";
    @media (max-width: 600px) {
      font-size: 9px;
      font-family: ${selectedTheme.fonts.textFont};
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    ${(props) => !props.previewCard && `display: block;`}
    align-self: flex-end;
  }
`;
export const OfferImage = styled.img`
  min-width: 144px;
  min-height: 144px;
  width: 144px;
  height: 144px;
  margin-right: 20px;
  object-fit: cover;
  ${(props) => !props.previewCard && `cursor: pointer;`}

  @media screen and (max-width: 600px) {
    min-width: 144px;
    margin-right: 13px;
  }
`;

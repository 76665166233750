import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../../themes";
import { useTranslation } from "react-i18next";
import { SaveButtonContainer } from "./SaveButton.styled";

const SaveButton = (props) => {
  const { t } = useTranslation();
  return (
    <SaveButtonContainer
      type="submit"
      variant={props.pin ? "contained" : "outlined"}
      height="48px"
      width="180px"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor={props.pin ? "white" : selectedTheme.colors.primaryPurple}
      onClick={props.onClick}
    >
      {props.deleteOffer !== false
        ? t("deleteOffer.delete")
        : !props.pinnedOffer
        ? t("admin.pin.confirm")
        : t("admin.unpin.confirm")}
    </SaveButtonContainer>
  );
};

SaveButton.propTypes = {
  onClick: PropTypes.func,
  pin: PropTypes.bool,
  deleteOffer: PropTypes.bool,
  pinnedOffer: PropTypes.bool,
};

export default SaveButton;

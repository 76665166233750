import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FieldLabel,
  FinishButton,
  LinkField,
  LinkPopoverContainer,
} from "./LinkPopover.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";

const LinkPopover = (props) => {
  const { t } = useTranslation();
  const [linkValue, setLinkValue] = useState("");

  const handleClickFinishButton = () => {
    let urlLink = linkValue.trim();
    if (urlLink.startsWith("http://") || urlLink.startsWith("https://"))
      props?.callbackFunction(urlLink);
    else
      props?.callbackFunction({
        url: `http://${urlLink}`,
        link: urlLink,
      });
  };

  return (
    <LinkPopoverContainer>
      <FieldLabel leftText={t("labels.link")} />
      <LinkField
        placeholder={t("examples.link")}
        italicPlaceholder
        value={linkValue}
        onChange={(event) => setLinkValue(event.target.value)}
        autoFocus
        fullWidth
      />
      <FinishButton
        buttoncolor={selectedTheme.colors.primaryPurple}
        textcolor={"white"}
        variant={"contained"}
        style={{ fontWeight: "600" }}
        fullWidth
        onClick={handleClickFinishButton}
      >
        {t("common.fillIn")}
      </FinishButton>
    </LinkPopoverContainer>
  );
};

LinkPopover.propTypes = {
  callbackFunction: PropTypes.func,
};

export default LinkPopover;

import React from 'react'
import PropTypes from 'prop-types'
import { SkeletonChooserTitleContainer, SkeletonChooserTitleLine } from './SkeletonChooserTitle.styled'

const SkeletonChooserTitle = (props) => {
  return (
    <SkeletonChooserTitleContainer center={props.center}  >
        <SkeletonChooserTitleLine center={props.center} />
    </SkeletonChooserTitleContainer>
  )
}

SkeletonChooserTitle.propTypes = {
    children: PropTypes.any,
    center: PropTypes.bool,
}

export default SkeletonChooserTitle
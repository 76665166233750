import React from "react";
import PropTypes from "prop-types";
// import useIsMobile from "../../../hooks/useIsMobile";
import { Drawer as HeaderDrawer } from "../Drawer/Drawer";
import Drawer from "../../MUI/DrawerComponent";
import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const DrawerContainer = forwardRef((props, ref) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  // const { isMobile } = useIsMobile();

  useImperativeHandle(ref, () => ({
    handleToggleDrawer,
  }));

  const handleToggleDrawer = () => {
    setOpenDrawer((prevOpenDrawer) => !prevOpenDrawer);
  };
  // if (!isMobile) return <></>;
  return (
    <Drawer
      open={openDrawer}
      toggleOpen={handleToggleDrawer}
      content={<HeaderDrawer toggleDrawer={handleToggleDrawer} />}
    />
  );
});

DrawerContainer.displayName = "DrawerContainer";

DrawerContainer.propTypes = {
  showCreateOfferModal: PropTypes.func,
};

export default DrawerContainer;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const AdminInfoContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const AdminName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: ${selectedTheme.colors.primaryPurple};
`;
export const AdminText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${selectedTheme.colors.primaryPurple};
`;

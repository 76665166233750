import { Box } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import selectedTheme from "../../themes";
import { PrimaryButton } from "../Buttons/PrimaryButton/PrimaryButton";

const SkeletonBackgroundAnimation = keyframes`
  0% {
    background-color: ${selectedTheme.colors.filterSkeletonBackground};
  }
  50% {
    background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  }
  100% {
    background-color: ${selectedTheme.colors.filterSkeletonBackground};
  }
`;
export const SkeletonItemAnimation = keyframes`
  0% {
    background-color: ${selectedTheme.colors.filterSkeletonItems};
  }
  50% {
    background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
  }
  100% {
    background-color: ${selectedTheme.colors.filterSkeletonItems};
  }
`;
export const ButtonLoadingAnimation = keyframes`
  0% {
    background-color: ${selectedTheme.colors.primaryPurple};
  }
  50% {
    background-color: ${selectedTheme.colors.primaryPurpleAnimation};
  }
  100% {
    background-color: ${selectedTheme.colors.primaryPurple};
  }
`;
export const ButtonLoadingEllipseAnimation = keyframes`
  0% {
    left: -24px;
  }
  50% {
    left: calc(100% + 24px);
  }
  100% {
    left: -48px;
  }
`;

export const BackgroundTransition = styled(Box)`
  border-radius: 4px;
  animation: ${SkeletonBackgroundAnimation} 1.6s infinite;
`;

export const ItemsTransition = styled(Box)`
  border-radius: 4px;
  animation: ${SkeletonItemAnimation} 1.6s infinite;
`;
export const transitionOnLoadFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;
export const transitionOnLoadFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;
export const PrimaryAnimatedButton = styled(PrimaryButton)`
  ${(props) =>
    props.isLoading &&
    css`
      cursor: default;
    `}
  & button {
    position: relative;
    /* background-color: green !important; */
    animation: ${(props) =>
      props.isLoading
        ? css`
            ${ButtonLoadingAnimation} 2.2s infinite;
          `
        : ""};
    /* animation: ${ButtonLoadingAnimation} 2s infinite; */
  }
  ${(props) =>
    props.isLoading &&
    css`
      & button::after {
        content: "";
        width: 47px;
        height: 96px;
        background-color: green;
        position: absolute;
        top: -24px;
        background-color: ${selectedTheme.colors.borderSponsoredColor};
        filter: blur(45px);
        animation: ${ButtonLoadingEllipseAnimation} 2.2s infinite;
      }
    `}
`;

import * as Yup from "yup";
import i18next from "i18next";

export default (locations, categories, subcategories) =>
  Yup.object().shape({
    nameOfProduct: Yup.string().required(i18next.t("login.nameOfProductError")),
    description: Yup.string()
      .required(i18next.t("login.descriptionRequiredError"))
      .min(8, i18next.t("login.descriptionNoOfCharsError")),
    location: Yup.string().oneOf(
      locations.map((l) => l.city),
      i18next.t("login.locationError")
    ),
    category: Yup.string().oneOf(
      categories.map((c) => c.name),
      i18next.t("login.categoryError")
    ),
    subcategory: Yup.string().oneOf(
      subcategories || [],
      i18next.t("login.subcategoryError")
    ),
  });

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  AdminSingleUserPageContainer,
  AdminSingleUserPageProfile,
  AdminSingleUserPageReviews,
} from "./AdminSingleUserPage.styled";

const AdminSingleUserPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);
  return (
    <AdminSingleUserPageContainer>
      <AdminSingleUserPageProfile isAdmin />
      <AdminSingleUserPageReviews isProfileReviews isAdmin />
    </AdminSingleUserPageContainer>
  );
};

AdminSingleUserPage.propTypes = {
  children: PropTypes.node,
};

export default AdminSingleUserPage;

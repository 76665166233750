export const reviewEnum = {
  YES: {
    value: 1,
    mainText: "Da",
    // Unsuccessful swap
    backendText: "succeeded",
    // Not good communication
    backendTextSecond: "yes",
  },
  NO: {
    value: 2,
    mainText: "Ne",
    // Unsuccessful swap
    backendText: "failed",
    // Not good communication
    backendTextSecond: "no",
  },
  NOT_BAD: {
    value: 3,
    mainText: "Može bolje",
    backendText: "could be better",
  },
};

export const reviewSortEnum = {
  INITIAL: {
    value: 0,
    mainText: "Filtriraj",
    queryString: "",
  },
  POSITIVE: {
    value: 1,
    mainText: "Pozitivne",
  },
  NEGATIVE: {
    value: 2,
    mainText: "Negativne",
  },
};

import { Box } from "@mui/material";
import styled from "styled-components";
import { ItemsTransition } from "../../../../../Styles/globalStyleComponents";

export const SkeletonSectionOptionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const OptionLeftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 9px;
`;
export const Circle = styled(ItemsTransition)`
  width: 14px;
  height: 14px;
  border-radius: 100% !important;
`;
export const Line = styled(ItemsTransition)`
  width: 86px;
  height: 14px;
`;
export const EndLine = styled(ItemsTransition)`
  width: 23px;
  height: 14px;
`;

import apiEndpoints from "./apiEndpoints";

import { getRequest, postRequest, replaceInUrl } from ".";

export const attemptRegister = (payload) =>
  postRequest(apiEndpoints.accounts.register, payload);

export const attemptCheckIdNumberExist = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.users.checkIdNumberExist, { idNumber: payload })
  );
};

import React, { useEffect, useState } from "react";
import {
  AdminText,
  GrayButtonsContainer,
  ProfileImgPIB,
} from "./MyProfile.styled";
import HeaderPopover from "../HeaderPopover/HeaderPopover";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMineProfile,
  selectProfile,
} from "../../../store/selectors/profileSelectors";
import {
  selectRoles,
  selectUserId,
} from "../../../store/selectors/loginSelectors";
import { fetchMineProfile } from "../../../store/actions/profile/profileActions";
import selectedTheme from "../../../themes";
import { EyeIcon, SettingsIcon } from "../HeaderPopover/HeaderPopover.styled";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import LogoutButton from "./LogoutButton/LogoutButton";
import AboutButton from "./AboutButton/AboutButton";
import PrivacyPolicyButton from "./PrivacyPolicyButton/PrivacyPolicyButton";
// import PricesButton from "./PricesButton/PricesButton";
import { ADMIN_HOME_PAGE, PROFILE_PAGE } from "../../../constants/pages";
import {
  dynamicRouteMatches,
  replaceInRoute,
} from "../../../util/helpers/routeHelpers";

export const MyProfile = (props) => {
  const { t } = useTranslation();
  const profile = useSelector(selectMineProfile);
  const selectedProfile = useSelector(selectProfile);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [profileAsArray, setProfileAsArray] = useState([]);
  const role = useSelector(selectRoles);
  const isAdmin = role?.includes("Admin");
  useEffect(() => {
    if (userId?.length > 1) {
      dispatch(fetchMineProfile());
    }
  }, [userId]);
  useEffect(() => {
    if (profile?.statistics) {
      setProfileAsArray([
        {
          src: profile.image,
          title: profile.company.name,
          onClick: () => seeMyProfile(),
          text: (
            <React.Fragment>
              {isAdmin ? (
                <AdminText>{t("profile.admin")}</AdminText>
              ) : (
                <>
                  <ProfileImgPIB />
                  {t("itemDetailsCard.PIB")}
                  {profile.company.idNumber}
                </>
              )}
            </React.Fragment>
          ),
        },
      ]);
    }
  }, [profile]);
  const seeMyProfile = () => {
    if (!dynamicRouteMatches(PROFILE_PAGE) || selectedProfile?._id !== userId)
      history.push(
        replaceInRoute(PROFILE_PAGE, {
          profileId: userId,
        })
      );

    props.closePopover();
  };
  const goToAdminHome = () => {
    history.push({
      pathname: ADMIN_HOME_PAGE,
    });
    props.closePopover();
  };

  return (
    <HeaderPopover
      title={t("header.myProfile")}
      items={profileAsArray}
      isProfile
      buttonText={t("header.checkProfile")}
      buttonIcon={<EyeIcon color={selectedTheme.colors.iconYellowColor} />}
      buttonOnClick={() => seeMyProfile()}
      adminButtonText={t("header.adminPanel")}
      adminButtonIcon={<SettingsIcon />}
      adminButtonOnClick={() => goToAdminHome()}
    >
      <GrayButtonsContainer>
        {/* <PricesButton closePopover={props.closePopover} /> */}
        <AboutButton closePopover={props.closePopover} />
        <PrivacyPolicyButton closePopover={props.closePopover} />
      </GrayButtonsContainer>
      <LogoutButton />
    </HeaderPopover>
  );
};

MyProfile.propTypes = {
  closePopover: PropTypes.func,
};

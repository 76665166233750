import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import App from "./App";
import { store, persistor } from "./store";
import GlobalStyle from "./themes/primaryTheme/globalStyles";

import "./i18n";
import ColorModeProvider from "./context/ColorModeContext";
import { PersistGate } from "redux-persist/integration/react";
import Elmahio from "elmah.io.javascript";

new Elmahio({
  apiKey: "04f4d40349904838b2096bfa2aa90a93",
  logId: "b1b4acd8-4d86-41ae-b205-4aca339348ba",
});

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <ColorModeProvider>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyle />
            <App />
          </PersistGate>
        </ColorModeProvider>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);

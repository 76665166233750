import {
  createErrorType,
  createFetchType,
  createSuccessType,
} from "../actionHelpers";

const COUNTER_SCOPE = "COUNTER_SCOPE";
export const COUNTER_INCREASE = createFetchType(COUNTER_SCOPE);
export const COUNTER_INCREASE_SUCCESS = createSuccessType(COUNTER_SCOPE);
export const COUNTER_INCREASE_ERROR = createErrorType(COUNTER_SCOPE);

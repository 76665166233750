import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { ReactComponent as Location } from "../../../../assets/images/svg/location.svg";
import { ReactComponent as Phone } from "../../../../assets/images/svg/phone.svg";
import { IconButton } from "../../../Buttons/IconButton/IconButton";

export const DirectChatContentHeaderContainer = styled(Box)`
  height: 90px;
  background-color: ${selectedTheme.colors.chatHeaderColor};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 17px;
  padding-left: 35px;
  justify-content: space-between;
`;
export const DirectChatContentHeaderFlexContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const ProfileImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
`;
export const ProfileDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;
export const ProfileName = styled(Box)`
  font-weight: 600;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  cursor: pointer;
`;
export const ProfileLocation = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const ProfileLocationText = styled(Box)`
  color: ${selectedTheme.colors.primaryDarkText};
  font-size: 12px;
  font-family: ${selectedTheme.fonts.textFont};
  margin-left: 5.5px;
`;
export const ProfileLocationIcon = styled(Location)`
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
`;
export const PhoneIcon = styled(Phone)`
  position: relative;
  top: 2.5px;
  left: 1.5px;
  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
    top: -2px;
    left: -2px;
  }
`;
export const PhoneIconContainer = styled(IconButton)`
  background-color: ${(props) =>
    props.disabled ? selectedTheme.colors.primaryIconBackgroundColor : "white"};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transition: 0.2s all;
  ${(props) =>
    props.disabled &&
    `
        & svg path {
            stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
        }
    `}
  &:hover button:hover {
    background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  }
  &:hover {
    background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
    cursor: pointer;
  }
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;
export const DirectChatHeaderStatusContainer = styled(Box)`
  background-color: ${selectedTheme.colors.primaryPurple};
  /* height: 39px; */
  width: 100%;
  padding: 9px 36px;
  @media (max-width: 600px) {
    height: 36px;
    padding: 8px 18px;
  }
`;
export const DirectChatHeaderStatusText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: italic;
  font-size: 16px;
  line-height: 21px;
  color: white;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputField, InputFieldLabel } from "../EditProfile.styled";

const PIBField = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <InputFieldLabel leftText={t("editProfile.idNumber")} />
      <InputField
        name="firmPIB"
        type="number"
        value={props.formik.values.firmPIB}
        onChange={(event) => {
          console.dir(event.target);
          props.formik.setFieldValue("firmPIB", event.target.value);
        }}
        error={props.formik.touched.firmPIB && props.formik.errors.firmPIB}
        margin="normal"
        fullWidth
        disabled={!props.isAdmin}
      />
    </>
  );
};

PIBField.propTypes = {
  formik: PropTypes.any,
  isAdmin: PropTypes.bool,
};

export default PIBField;

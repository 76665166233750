import { MenuItem } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import Option from "../../../Select/Option/Option";
import Select from "../../../Select/Select";

export const HeaderSelectContainer = styled(Select)`
  width: 210px;
  height: 35px;
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 3px;
  font-weight: 400;
  position: relative;
  background-color: white;
  /* display: ${(props) => props.hideSorting && "none"}; */
  & div:first-child {
    padding-left: 8px;
  }

  ${(props) =>
    !props.myOffers
      ? `
    @media (max-width: 600px) {
    width: 148px;
    height: 30px;
    font-size: 14px;
    top: -3px;
    left: -40px;
  }
  `
      : `
  @media (max-width: 600px) {
    width: 148px;
    height: 30px;
    font-size: 14px;
    right: 0;
  }
  `}
`;
export const SelectItem = styled(MenuItem)`
  font-family: ${selectedTheme.fonts.textFont};
`;
export const SelectOption = styled(Option)`
  @media (max-width: 600px) {
    height: 20px !important;
    min-height: 35px;
    margin: 2px;
  }
`;

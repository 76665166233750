import React from "react";
import PropTypes from "prop-types";
import {
  AltTitle,
  LogoImage,
  MainTitle,
  ThirdStepCreateReviewContainer,
} from "./ThirdStepCreateReview.styled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";

const ThirdStepCreateReview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  setTimeout(() => {
    dispatch(closeModal());
  }, 3000);
  return (
    <ThirdStepCreateReviewContainer>
      <LogoImage />
      <MainTitle>{t("reviews.finishedReviewTitle")}</MainTitle>
      <AltTitle>{t("reviews.finishedReviewAltTitle")}</AltTitle>
    </ThirdStepCreateReviewContainer>
  );
};

ThirdStepCreateReview.propTypes = {
  children: PropTypes.node,
};

export default ThirdStepCreateReview;

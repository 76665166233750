import styled from "styled-components";
import { ReactComponent as Location } from "../../../../../assets/images/svg/location.svg";

export const LocationIcon = styled(Location)`
    @media (max-width: 600px) {
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 2px;
    }
`
import React from "react";
import PropTypes from "prop-types";
import { ChatContent, ChatLayoutContainer } from "./ChatLayout.styled";

export const ChatLayout = (props) => {
  return (
    <ChatLayoutContainer>
      {props.children}

      <ChatContent
        maxHeight="xl"
        justifyContent={"center"}
        item
        xs={12}
        md={10}
      >
        {props.content}
      </ChatContent>
    </ChatLayoutContainer>
  );
};

ChatLayout.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
};

export default ChatLayout;

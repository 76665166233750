import React from "react";
import PropTypes from "prop-types";
import {
  CategoryEditButtonContainer,
  EditIcon,
} from "./CategoryEditButton.styled";

const CategoryEditButton = (props) => {
  return (
    <CategoryEditButtonContainer disabled={props.disabled} onClick={props.onClick}>
      <EditIcon disabled={props.disabled} />
    </CategoryEditButtonContainer>
  );
};

CategoryEditButton.propTypes = {
  category: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CategoryEditButton;

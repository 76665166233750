import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { ReactComponent as Remove } from "../../../../../assets/images/svg/trash-gold.svg";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import OfferDescription from "../OfferDescription/OfferDescription";
export const OfferInfo = styled(Box)`
  width: 211px;
  height: 90px;
  border: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  padding: 18px;
  margin-top: 36px;

  @media screen and (max-width: 600px) {
    margin-left: calc(50% - 105px) !important;
  }
`;

export const OfferImageContainer = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    /* margin-right: 13px; */
  }
`;

export const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;
export const RemoveIconBorder = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  right: 143px;
  background-color: ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;

  @media screen and (max-width: 600px) {
    right: 50px;
  }
`;

export const RemoveIconContainer = styled(RemoveIconBorder)`
  cursor: default;
`;

export const RemoveIcon = styled(Remove)`
  cursor: default;
`;
export const OfferDescriptionContainer = styled(OfferDescription)`
  margin-left: 0;
`

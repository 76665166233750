import React from 'react'
import PropTypes from 'prop-types'
import { NoProfileOffersAltText, NoProfileOffersContainer, NoProfileOffersIcon, NoProfileOffersMainText } from './NoProfileOffers.styled'
import { useTranslation } from 'react-i18next'

const NoProfileOffers = () => {
    const {t} = useTranslation();
  return (
    <NoProfileOffersContainer>
        <NoProfileOffersIcon />
        <NoProfileOffersMainText>{t("profile.noOffers.mainText")}</NoProfileOffersMainText>
        <NoProfileOffersAltText>{t("profile.noOffers.altText")}</NoProfileOffersAltText>
    </NoProfileOffersContainer>
  )
}

NoProfileOffers.propTypes = {
    children: PropTypes.node,
}

export default NoProfileOffers
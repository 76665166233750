import { CATEGORIES_FETCH, CATEGORIES_FETCH_ERROR, CATEGORIES_FETCH_SUCCESS, CATEGORIES_SET } from "./categoriesActionConstants";

export const fetchCategories = () => ({
    type: CATEGORIES_FETCH
})

export const setCategories = (payload) => ({
    type: CATEGORIES_SET,
    payload
})
export const fetchCategoriesSuccess = () => ({
    type: CATEGORIES_FETCH_SUCCESS
})
export const fetchCategoriesError = () => ({
    type: CATEGORIES_FETCH_ERROR
})
import styled from "styled-components";
import { ReactComponent as Subcategory } from "../../../../../assets/images/svg/subcategory.svg";

export const SubcategoryIcon = styled(Subcategory)`
    @media (max-width: 600px) {
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 2px;
    }
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import { BackgroundTransition, ItemsTransition } from "../../../Styles/globalStyleComponents";

export const SkeletonFilterCardContainer = styled(BackgroundTransition)`
  display: ${props => props.skeleton ? "block" : "none"};
  width: 100%;
  height: 100%;
  padding: 36px;
  & * {
    display: flex;
    border-radius: 4px;
  }
`;
export const SkeletonHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SkeletonHeaderLineOne = styled(ItemsTransition)`
  width: 90px;
  height: 27px;
`;
export const SkeletonHeaderLineSecond = styled(ItemsTransition)`
  width: 78px;
  height: 14px;
  position: relative;
  top: 7px;
`;

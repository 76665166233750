import {
  createErrorType,
  createFetchType,
  createSuccessType,
} from "../actionHelpers";

export const REGISTER_USER_SCOPE = "REGISTER_USER";
export const REGISTER_USER_FETCH = createFetchType(REGISTER_USER_SCOPE);
export const REGISTER_USER_FETCH_ERROR = createErrorType(REGISTER_USER_SCOPE);
export const REGISTER_USER_FETCH_SUCCESS =
  createSuccessType(REGISTER_USER_SCOPE);

export const CHECK_ID_NUMBER_EXIST_SCOPE = "CHECK_ID_NUMBER_EXIST_SCOPE";
export const CHECK_ID_NUMBER_EXIST_FETCH = createFetchType(
  CHECK_ID_NUMBER_EXIST_SCOPE
);
export const CHECK_ID_NUMBER_EXIST_FETCH_ERROR = createErrorType(
  CHECK_ID_NUMBER_EXIST_SCOPE
);
export const CHECK_ID_NUMBER_EXIST_FETCH_SUCCESS = createSuccessType(
  CHECK_ID_NUMBER_EXIST_SCOPE
);

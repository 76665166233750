import React from "react";
import {
  SkeletonMiniChatColumnContainer,
  SkeletonMiniChatColumnHeading,
  SkeletonMiniChatColumnItem,
  SkeletonMiniChatItemImage,
  SkeletonMiniChatItemInfo,
  SkeletonMiniChatItemInfoFirstLine,
  SkeletonMiniChatItemInfoSecondLine,
  SkeletonMiniChatItemInfoThirdLine,
} from "./SkeletonMiniChatColumn.styled";

const SkeletonMiniChatColumn = () => {
  return (
    <SkeletonMiniChatColumnContainer>
      <SkeletonMiniChatColumnHeading />
      <SkeletonMiniChatColumnItem>
        <SkeletonMiniChatItemImage />
        <SkeletonMiniChatItemInfo>
          <SkeletonMiniChatItemInfoFirstLine />
          <SkeletonMiniChatItemInfoSecondLine />
          <SkeletonMiniChatItemInfoThirdLine />
        </SkeletonMiniChatItemInfo>
      </SkeletonMiniChatColumnItem>
      <SkeletonMiniChatColumnItem>
        <SkeletonMiniChatItemImage />
        <SkeletonMiniChatItemInfo>
          <SkeletonMiniChatItemInfoFirstLine />
          <SkeletonMiniChatItemInfoSecondLine />
          <SkeletonMiniChatItemInfoThirdLine />
        </SkeletonMiniChatItemInfo>
      </SkeletonMiniChatColumnItem>
      <SkeletonMiniChatColumnItem>
        <SkeletonMiniChatItemImage />
        <SkeletonMiniChatItemInfo>
          <SkeletonMiniChatItemInfoFirstLine />
          <SkeletonMiniChatItemInfoSecondLine />
          <SkeletonMiniChatItemInfoThirdLine />
        </SkeletonMiniChatItemInfo>
      </SkeletonMiniChatColumnItem>
      <SkeletonMiniChatColumnItem>
        <SkeletonMiniChatItemImage />
        <SkeletonMiniChatItemInfo>
          <SkeletonMiniChatItemInfoFirstLine />
          <SkeletonMiniChatItemInfoSecondLine />
          <SkeletonMiniChatItemInfoThirdLine />
        </SkeletonMiniChatItemInfo>
      </SkeletonMiniChatColumnItem>
    </SkeletonMiniChatColumnContainer>
  );
};

export default SkeletonMiniChatColumn;

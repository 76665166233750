import React from "react";
import PropTypes from "prop-types";
import {
  ChatGridLayoutContainer,
  GridContainer,
  GridContent,
  GridLeftCard,
} from "./ChatGridLayout.styled";

export const ChatGridLayout = (props) => {
  return (
    <ChatGridLayoutContainer>
      {props.children}
      <GridContainer container maxHeight="xl" spacing={2}>
        <GridLeftCard item xs={0} lg={3} xl={3} md={4}>
          {props.leftCard}
        </GridLeftCard>
        <GridContent item xs={12} lg={9} xl={9} md={8}>
          {props.content}
        </GridContent>
      </GridContainer>
    </ChatGridLayoutContainer>
  );
};

ChatGridLayout.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  leftCard: PropTypes.node,
};

export default ChatGridLayout;

import React from "react";
import PropTypes from "prop-types";
import {
  AboutFooterContainer,
  AboutFooterText,
  Arrow,
  ButtonContainer,
  LinkText,
} from "./AboutFooter.styled";
import { useTranslation } from "react-i18next";

const AboutFooter = () => {
  const { t } = useTranslation();
  const handleGoStart = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <AboutFooterContainer>
      <AboutFooterText>{t("aboutFooter.middleText")}</AboutFooterText>
      <ButtonContainer onClick={handleGoStart}>
        <LinkText>{t("aboutFooter.goStart")}</LinkText>
        <Arrow />
      </ButtonContainer>
    </AboutFooterContainer>
  );
};

AboutFooter.propTypes = {
  children: PropTypes.node,
};

export default AboutFooter;

import React from "react";
import PropTypes from "prop-types";
import {
  LogoutButtonContainer,
  LogoutIcon,
  LogoutText,
} from "./LogoutButton.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../../store/actions/login/loginActions";

const LogoutButton = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => {
    props.toggleDrawer();
    dispatch(logoutUser());
  };
  return (
    <LogoutButtonContainer>
      <IconButton onClick={handleClick}>
        <LogoutIcon />
      </IconButton>
      <LogoutText>{t("common.logout")}</LogoutText>
    </LogoutButtonContainer>
  );
};

LogoutButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default LogoutButton;

import { createSelector } from "reselect";

const categoriesSelector = (state) => state.categories;

export const selectCategories = createSelector(
  categoriesSelector,
  (state) => state.categories
);
export const selectSubcategories = (category) =>
  createSelector(categoriesSelector, (state) =>
    state.categories?.find(
      (item) => item?.name?.toString() === category?.toString()
    )?.subcategories
  );

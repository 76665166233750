import { useEffect, useState } from "react";

const usePaging = (applyAllFilters, disableScroll) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitallyLoaded, setIsInitiallyLoaded] = useState(false);

  // If state currentPage is changed, new request to backend should be sent,
  // except on initial load
  useEffect(() => {
    if (isInitallyLoaded && applyAllFilters) {
      applyAllFilters();
    }
    if (!disableScroll) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIsInitiallyLoaded(true);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const goToPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return {
    currentPage,
    changePage,
    goToNextPage,
    goToPrevPage,
  };
};
export default usePaging;

import styled from "styled-components";
import { PrimaryButton } from "../../../../Buttons/PrimaryButton/PrimaryButton";
import { ReactComponent as Login } from "../../../../../assets/images/svg/log-out.svg";
import selectedTheme from "../../../../../themes";

export const LoginButtonContainer = styled(PrimaryButton)`
  height: 49px;
  width: 218px;
  font-weight: 600;
  position: absolute;
  bottom: 85px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
`;
export const LoginIcon = styled(Login)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  AboutSectionContainer,
  AboutSectionImage,
  AboutSectionText,
  AboutSectionTextContainer,
  AboutSectionTitle,
  CheckButton,
} from "./AboutSection.styled";

const AboutSection = (props) => {
  return (
    <AboutSectionContainer reverse={props.reverse}>
      <AboutSectionTextContainer>
        <AboutSectionTitle reverse={props.reverse}>
          {props.title}
        </AboutSectionTitle>
        <AboutSectionText reverse={props.reverse}>
          {props.text}
        </AboutSectionText>
      </AboutSectionTextContainer>
      {props.reverse && <CheckButton />}
      <AboutSectionImage src={props.image} reverse={props.reverse} />
    </AboutSectionContainer>
  );
};

AboutSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

export default AboutSection;

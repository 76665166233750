import { Box, styled } from "@mui/material";
import selectedTheme from "../../themes";

export const PrivacyPolicyContainer = styled(Box)`
    margin-top: 80px;
    position: relative;
    background-color: ${selectedTheme.colors.staticBackgroundColor};
    @media (max-width: 600px) {
        margin-top: 53px;
    }
`
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { reviewEnum } from "../../../enums/reviewEnum";
import UserReviewsSingleCard from "./UserReviewsSingleCard/UserReviewsSingleCard";
import { useDispatch } from "react-redux";
import { toggleDeleteReviewModal } from "../../../store/actions/modal/modalActions";

const UserReviewsCard = (props) => {
  const dispatch = useDispatch();
  const review = useMemo(() => {
    if (props.givingReview) {
      return {
        ...props.review,
      };
    }
    let userWhoGaveReview =
      props?.review?.exchange?.buyer?.user?._id === props?.review?.user?._id
        ? "buyer"
        : "seller";
    let userWhoRevievedReview =
      userWhoGaveReview === "buyer" ? "seller" : "buyer";
    let isSuccessfulSwap = reviewEnum.YES.mainText.toUpperCase();
    if (
      props.review.succeeded === reviewEnum.NO.backendText ||
      props.review.isSuccessfulSwap === reviewEnum.NO.mainText
    )
      isSuccessfulSwap = reviewEnum.NO.mainText.toUpperCase();
    let isGoodCommunication = reviewEnum.YES.mainText.toUpperCase();
    if (
      props.review.communication === reviewEnum.NOT_BAD.backendText ||
      props.review.isCorrectCommunication === reviewEnum.NOT_BAD.mainText
    )
      isGoodCommunication = reviewEnum.NOT_BAD.mainText.toUpperCase();
    if (
      props.review.communication === reviewEnum.NO.backendTextSecond ||
      props.review.isCorrectCommunication === reviewEnum.NO.mainText
    )
      isGoodCommunication = reviewEnum.NO.mainText.toUpperCase();
    return {
      _id: props.review._id,
      name: props?.review?.exchange[userWhoGaveReview]?.user?.company?.name,
      image: props?.review?.exchange[userWhoGaveReview]?.user?.image,
      userId: props.review.user?._id,
      isGoodCommunication,
      isSuccessfulSwap,
      quote: props?.review?.message,
      offerName: props?.review?.exchange?.offer?.name,
      offerImage: props?.review?.exchange?.offer?.images[0],
      userWhoReceived:
        props?.review?.exchange[userWhoRevievedReview]?.user?.company?.name,
      _deleted: props?.review?._deleted,
    };
  }, [props.review]);
  const handleRemove = () => {
    dispatch(
      toggleDeleteReviewModal({
        review: review,
      })
    );
  };
  return (
    <UserReviewsSingleCard
      review={review}
      showRemoveIcon={props.showRemoveIcon}
      handleRemove={handleRemove}
      hasGivenReview={props.hasGivenReview}
      rightReviews={props.rightReviews}
      isProfileReviews={props?.isProfileReviews}
      isAdmin={props.isAdmin}
    />
  );
};

UserReviewsCard.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  isProfileReviews: PropTypes.bool,
  profileReviews: PropTypes.any,
  className: PropTypes.string,
  review: PropTypes.any,
  givingReview: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  hasGivenReview: PropTypes.bool,
  rightReviews: PropTypes.bool,
  isAdmin: PropTypes.bool,
  userId: PropTypes.string,
};
UserReviewsCard.defaultProps = {
  isProfileReviews: false,
  profileReviews: [],
};

export default UserReviewsCard;

import { toast } from 'react-toastify';

const defaultOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
  }

export const makeToastMessage = (text, options = defaultOptions) => toast(text, options);
export const makeErrorToastMessage = (text, options = defaultOptions) => toast.error(text, options);
import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../../themes";
import { useTranslation } from "react-i18next";
import { NextButtonContainer } from "../../CreateOffer.styled";

const NextButton = (props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <NextButtonContainer
      type="submit"
      variant="contained"
      height="48px"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor="white"
      onClick={formik.handleSubmit}
      disabled={
        formik.values?.nameOfProduct?.length === 0 ||
        !formik.values?.nameOfProduct ||
        formik.values?.description?.length === 0 ||
        !formik.values?.description ||
        formik.values?.category?.length === 0 ||
        !formik.values?.category ||
        formik.values?.category === "default" ||
        formik.values?.subcategory?.length === 0 ||
        !formik.values?.subcategory ||
        formik.values?.subcategory === "default" ||
        formik.values?.location?.length === 0 ||
        !formik.values?.location ||
        formik.values?.location === "default"
      }
    >
      {t("offer.continue")}
    </NextButtonContainer>
  );
};

NextButton.propTypes = {
  formik: PropTypes.any,
};

export default NextButton;

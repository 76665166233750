import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const FormContainer = styled.form`
  width: 335px;

  @media (max-width: 345px) {
    width: 300px;
  }
`;
export const RegisterDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${selectedTheme.colors.primaryGrayText};
  font-size: 12px;
  width: 100%;
  text-align: left;
  line-height: 16px;
  margin-top: 31px;
  margin-bottom: 2px;
  letter-spacing: 0.02em;
  @media (max-height: 800px) {
    margin-top: 14px;
  }
`;
export const ErrorMessage = styled(Typography)`
  color: red;
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  top: -7px;
  font-size: 14px;
`;

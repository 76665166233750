import {
  CLEAR_FILTERS,
  SET_CATEGORY,
  SET_COMPANY,
  SET_FILTERS,
  SET_HEADER_STRING,
  SET_IS_APPLIED,
  SET_LOCATIONS,
  SET_MANUAL_SEARCH_STRING,
  SET_PAYMENTS,
  SET_QUERY_STRING,
  SET_SEARCH_STRING,
  SET_SORT_OPTION,
  SET_SUBCATEGORY,
} from "../../actions/filters/filtersActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  filters: {
    category: null,
    subcategory: null,
    locations: [],
    payments: [],
    company: [],
    sortOption: null,
    isApplied: false,
    queryString: "",
    headerString: {
      categoryString: "",
      subcategoryString: "",
      locationsString: "",
      paymentsString: "",
      companyString: "",
      text: "",
    },
    searchString: "",
    manualSearchString: "",
  },
};

export default createReducer(
  {
    [SET_FILTERS]: setFilters,
    [CLEAR_FILTERS]: clearFilters,
    [SET_CATEGORY]: setFilteredCategory,
    [SET_SUBCATEGORY]: setFilteredSubcategory,
    [SET_LOCATIONS]: setFilteredLocations,
    [SET_PAYMENTS]: setFilteredPayments,
    [SET_COMPANY]: setFilteredCompany,
    [SET_SORT_OPTION]: setFilteredSortOption,
    [SET_IS_APPLIED]: setIsAppliedStatus,
    [SET_HEADER_STRING]: setHeaderString,
    [SET_SEARCH_STRING]: setSearchString,
    [SET_QUERY_STRING]: setQueryString,
    [SET_MANUAL_SEARCH_STRING]: setManualSearchString,
  },
  initialState
);

function setManualSearchString(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      manualSearchString: payload,
    },
  };
}

function setFilters(state, { payload }) {
  return {
    ...state,
    filters: payload,
  };
}

function clearFilters() {
  return initialState;
}

function setFilteredCategory(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      category: payload,
    },
  };
}

function setFilteredSubcategory(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      subcategory: payload,
    },
  };
}

function setFilteredLocations(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      locations: payload,
    },
  };
}

function setFilteredPayments(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      payments: payload,
    },
  };
}
function setFilteredCompany(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      company: payload,
    },
  };
}
function setFilteredSortOption(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      sortOption: payload,
    },
  };
}
function setIsAppliedStatus(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      isApplied: payload,
    },
  };
}
function setHeaderString(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      headerString: payload,
    },
  };
}
function setSearchString(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      searchString: payload,
    },
  };
}
function setQueryString(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      queryString: payload,
    },
  };
}

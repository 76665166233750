import React from "react";
import PropTypes from "prop-types";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../../../util/helpers/imageUrlGetter";
import {
  ProfileContainer,
  ProfileImage,
  ProfileImageContainer,
  ProfileName,
} from "./ReviewerProfile.styled";
import history from "../../../../../store/utils/history";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";
import { PROFILE_PAGE } from "../../../../../constants/pages";

const ReviewerProfile = (props) => {
  const { isMobile } = useIsMobile();
  const routeToUser = () => {
    history.push(replaceInRoute(PROFILE_PAGE, {
      profileId: props.userId
    }))
  }
  return (
    <ProfileContainer>
      <ProfileImageContainer>
        <ProfileImage
          onClick={routeToUser}
          src={getImageUrl(props.profileImage, variants.reviewCard, isMobile)}
        />
      </ProfileImageContainer>
      <ProfileName onClick={routeToUser}>{props.profileName}</ProfileName>
    </ProfileContainer>
  );
};

ReviewerProfile.propTypes = {
  profileName: PropTypes.string,
  profileImage: PropTypes.string,
  userId: PropTypes.string,
};

export default ReviewerProfile;

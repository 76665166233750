import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../../../themes";

export const MessagesNotFoundContainer = styled(Box)``;

export const MessagesNotFoundText = styled(Typography)`
  width: 100%;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 18px;
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
`;
export const MessagesNotFoundTextSecond = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  padding-left: 18px;
`;

export const MessagesNotFoundSkeleton = styled(Box)`
  margin: 18px 0 0 18px;
  display: flex;
`;

export const SkeletonImage = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.skeletonItemColor};
`;

export const SkeletonLinesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
`;

export const SkeletonFirstLine = styled(Box)`
  width: 109px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 6px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
`;

export const SkeletonSecondLine = styled(Box)`
  width: 62px;
  height: 14px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.skeletonItemColor};
`;

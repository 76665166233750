import { LOCATIONS_FETCH, LOCATIONS_FETCH_ERROR, LOCATIONS_FETCH_SUCCESS, LOCATIONS_SET } from "./locationsActionConstants";

export const fetchLocations = () => ({
  type: LOCATIONS_FETCH,
});

export const setLocations = (payload) => ({
    type: LOCATIONS_SET,
    payload
})
export const fetchLocationsSuccess = () => ({
  type: LOCATIONS_FETCH_SUCCESS
})
export const fetchLocationsError = () => ({
    type: LOCATIONS_FETCH_ERROR 
})
import { createGlobalStyle } from 'styled-components';
// import OpenSans from "./fonts/OpenSans-Regular.ttf"
// import Poppins from "./fonts/Poppins-Regular.ttf"
// import Mulish from "./fonts/Mulish-Regular.ttf"
const GlobalStyle = createGlobalStyle`
  ${'' /* @font-face {
    font-family: 'DM Sans';
    src: url(${OpenSans}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${Poppins}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "Mulish";
    src: url(${Mulish}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  } */}
`;

export default GlobalStyle;
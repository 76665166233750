import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const PROFILE_SCOPE = "PROFILE_SCOPE";
export const PROFILE_FETCH = createFetchType(PROFILE_SCOPE);
export const PROFILE_SUCCESS = createSuccessType(PROFILE_SCOPE);
export const PROFILE_ERROR = createErrorType(PROFILE_SCOPE);

export const PROFILE_ALL_SCOPE = "PROFILE_ALL_SCOPE";
export const PROFILE_ALL_FETCH = createFetchType(PROFILE_ALL_SCOPE);
export const PROFILE_ALL_SUCCESS = createSuccessType(PROFILE_ALL_SCOPE);
export const PROFILE_ALL_ERROR = createErrorType(PROFILE_ALL_SCOPE);

export const PROFILE_ALL_ADMIN_SCOPE = "PROFILE_ALL_ADMIN_SCOPE";
export const PROFILE_ALL_ADMIN_FETCH = createFetchType(PROFILE_ALL_ADMIN_SCOPE);
export const PROFILE_ALL_ADMIN_SUCCESS = createSuccessType(PROFILE_ALL_ADMIN_SCOPE);
export const PROFILE_ALL_ADMIN_ERROR = createErrorType(PROFILE_ALL_ADMIN_SCOPE);

export const PROFILE_MINE_SCOPE = "PROFILE_MINE_SCOPE";
export const PROFILE_MINE_FETCH = createFetchType(PROFILE_MINE_SCOPE);
export const PROFILE_MINE_FETCH_SUCCESS = createSuccessType(PROFILE_MINE_SCOPE);
export const PROFILE_MINE_FETCH_ERROR = createErrorType(PROFILE_MINE_SCOPE);

export const PROFILE_SET = createSetType("PROFILE_SET");
export const PROFILE_MINE_SET = createSetType("PROFILE_MINE_SET");
export const PROFILE_ALL_SET = createSetType("PROFILE_ALL_SET");

export const PROFILE_EDIT_SCOPE = "PROFILE_EDIT_SCOPE";
export const PROFILE_EDIT = createFetchType(PROFILE_EDIT_SCOPE);
export const PROFILE_EDIT_SUCCESS = createSuccessType(PROFILE_EDIT_SCOPE);
export const PROFILE_EDIT_ERROR = createErrorType(PROFILE_EDIT_SCOPE);

export const PROFILE_EDIT_ADMIN_SCOPE = "PROFILE_EDIT_ADMIN_SCOPE";
export const PROFILE_EDIT_ADMIN = createFetchType(PROFILE_EDIT_ADMIN_SCOPE);
export const PROFILE_EDIT_ADMIN_SUCCESS = createSuccessType(PROFILE_EDIT_ADMIN_SCOPE);
export const PROFILE_EDIT_ADMIN_ERROR = createErrorType(PROFILE_EDIT_ADMIN_SCOPE);

export const PROFILE_CLEAR = createClearType("PROFILE_CLEAR");

import styled from "styled-components";
import Select from "../../../Select/Select";
import Option from "../../../Select/Option/Option";
import { ReactComponent as Down } from "../../../../assets/images/svg/down-arrow.svg";
import selectedTheme from "../../../../themes";

export const HeaderSelect = styled(Select)`
  width: 210px;
  height: 35px;
  font-family: ${selectedTheme.fonts.textFont};
  background: white;
  margin-top: 3px;
  font-weight: 400;
  position: absolute;
  top: -8px;
  right: 50px;
  & div:first-child {
    padding-left: 8px;
  }

  @media (max-width: 1200px) {
    right: 36px;
  }

  @media (max-width: 650px) {
    width: 144px;
    height: 30px;
    font-size: 14px;
    right: 1px;
  }
`;
export const SelectOption = styled(Option)`
  @media (max-width: 600px) {
    height: 20px !important;
    min-height: 35px;
    margin: 2px;
  }
`;
export const DownArrow = styled(Down)``;
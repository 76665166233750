import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredSubcategory } from "../../store/actions/filters/filtersActions";
import { selectSelectedSubcategory } from "../../store/selectors/filtersSelectors";

const useSubcategoryFilter = (applyFilters = () => {}) => {
  const selectedSubcategory = useSelector(selectSelectedSubcategory);
  const dispatch = useDispatch();
  const initialOption = {
    label: "SVE PODKATEGORIJE",
    _id: 0,
  };
  const [selectedSubcategoryLocally, setSelectedSubcategoryLocally] =
    useState(initialOption);

  useEffect(() => {
    if (selectedSubcategory)
      if ("_id" in selectedSubcategory) {
        setSelectedSubcategoryLocally(selectedSubcategory);
        applyFilters();
      }
  }, [selectedSubcategory]);

  useEffect(() => {
    if (selectedSubcategoryLocally)
      if (Object.keys(selectedSubcategoryLocally)?.length === 0) {
        setSelectedSubcategoryLocally(initialOption);
      }
  }, [initialOption]);

  const setSelectedSubcategory = (subcategory) => {
    setSelectedSubcategoryLocally(subcategory);
    // if (immediateApply) {
      dispatch(setFilteredSubcategory(subcategory));
    // }
  };

  const apply = () => {
    if (
      Number(selectedSubcategory?._id) !==
      Number(selectedSubcategoryLocally?._id)
    )
      dispatch(setFilteredSubcategory(selectedSubcategoryLocally));
  };

  const clear = () => {
    setSelectedSubcategoryLocally({});
    dispatch(setFilteredSubcategory({}));
  };

  return {
    selectedSubcategory,
    selectedSubcategoryLocally,
    setSelectedSubcategory,
    initialOption,
    apply,
    clear,
  };
};

export default useSubcategoryFilter;

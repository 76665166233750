import React from "react";
import PropTypes from "prop-types";
import {
  GivenReviewsHeaderContainer,
  CompanyName,
  CompanyLabel,
} from "./GivenReviewsHeader.styled";
import { useTranslation } from "react-i18next";

const GivenReviewsHeader = (props) => {
  const { t } = useTranslation();
  return (
    <GivenReviewsHeaderContainer hasGivenReview={props.hasGivenReview}>
      <CompanyLabel>{t("reviews.givenHeaderTitle")}&nbsp;</CompanyLabel>
      <CompanyName>{props.userWhoReceived}</CompanyName>
    </GivenReviewsHeaderContainer>
  );
};

GivenReviewsHeader.propTypes = {
  hasGivenReview: PropTypes.bool,
  userWhoReceived: PropTypes.any,
};

export default GivenReviewsHeader;

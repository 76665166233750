import { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VALUE_SORTBY_NEW,
  VALUE_SORTBY_OLD,
  VALUE_SORTBY_POPULAR,
} from "../../constants/queryStringConstants";
import { sortEnum } from "../../enums/sortEnum";
import { setFilteredSortOption } from "../../store/actions/filters/filtersActions";
import { selectSelectedSortOption } from "../../store/selectors/filtersSelectors";

const useSorting = (applyAllFilters, newSortOptions = sortEnum) => {
  const selectedSortOption = useSelector(selectSelectedSortOption);
  const [selectedSortOptionLocally, setSelectedSortOptionLocally] = useState(
    sortEnum.INITIAL
  );
  const [isInitiallyLoaded, setIsInitallyLoaded] = useState(false);
  const dispatch = useDispatch();
  const sortOptions = useMemo(() => {
    return newSortOptions || sortEnum;
  }, [newSortOptions])

  // On every change of sorting option, new request to backend should be sent
  useEffect(() => {
    if (isInitiallyLoaded) {
      if (applyAllFilters) applyAllFilters();
    }
  }, [isInitiallyLoaded, selectedSortOption]);

  const changeSorting = (newSortOption) => {
    dispatch(setFilteredSortOption(newSortOption));
    setSelectedSortOptionLocally(newSortOption);
    if (!isInitiallyLoaded) {
      setIsInitallyLoaded(true);
    }
  };

  // Change sorting by name of sorting option that is shown on frontned
  const changeSortingFromName = (sortingName) => {
    if (sortingName === VALUE_SORTBY_NEW) {
      changeSorting(sortEnum.NEW, true);
    }
    if (sortingName === VALUE_SORTBY_OLD) {
      changeSorting(sortEnum.OLD, true);
    }
    if (sortingName === VALUE_SORTBY_POPULAR) {
      changeSorting(sortEnum.POPULAR, true);
    }
  };

  const apply = () => {
    // For future changes
  };

  const clear = () => {
    dispatch(setFilteredSortOption(sortEnum.INITIAL));
  };

  return {
    selectedSortOption,
    selectedSortOptionLocally,
    changeSorting,
    changeSortingFromName,
    apply,
    clear,
    sortOptions
  };
};
export default useSorting;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { SelectStyled, SelectIcon } from "./Select.styled";
import { ReactComponent as Down } from "../../assets/images/svg/down-arrow.svg";

// import {Select as SelectMUI} from "@mui/material";

const Select = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
  };
  return (
    <SelectStyled
      defaultValue={props.defaultValue}
      fullWidth={props.fullwidth}
      open={isOpened}
      onClick={() => handleOpen()}
      value={props.value}
      width={props.width}
      height={props.height}
      className={props.className}
      onChange={props.onChange}
      IconComponent={(iconProps) => (
        <SelectIcon {...iconProps}>
          <Down />
        </SelectIcon>
      )}
    >
      {props.children}
    </SelectStyled>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  fullwidth: PropTypes.bool,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};
Select.defaultProps = {
  fullwidth: true,
  height: "48px",
};

export default Select;

import { createSelector } from "reselect";

const profileSelector = (state) => state.profile;

export const selectProfileName = createSelector(
  profileSelector,
  (state) => state?.mineProfile?.company?.name
);
export const selectProfile = createSelector(
  profileSelector,
  (state) => state.profile
);
export const selectMineProfile = createSelector(
  profileSelector,
  (state) => state?.mineProfile
);
export const selectMineProfilePicture = createSelector(
  profileSelector,
  (state) => state?.mineProfile?.image
);
export const selectAllProfiles = createSelector(
  profileSelector,
  (state) => state?.allProfiles
);
export const selectTotalProfiles = createSelector(
  profileSelector,
  state => state.allProfiles?.total
)
export const selectAmIBlocked = createSelector(
  profileSelector,
  state => state?.mineProfile?._blocked
)

import { NEW_CHAT } from "../../constants/chatConstants";
import { DIRECT_CHAT_PAGE } from "../../constants/pages";
import history from "../../store/utils/history";
import { replaceInRoute } from "./routeHelpers";

export const startChat = (chats, offer, userId) => {
  const chatItem = chats.find(
    (item) => item.offer?._id === offer?._id && offer?.user?._id !== userId
  );
  if (chatItem !== undefined) {
    history.push(replaceInRoute(DIRECT_CHAT_PAGE, { chatId: chatItem?._id }));
  } else {
    if (offer?.user?._id !== userId) {
      history.push({
        pathname: replaceInRoute(DIRECT_CHAT_PAGE, {
          chatId: NEW_CHAT,
        }),
        state: {
          offerId: offer?._id,
        },
      });
    }
  }
};

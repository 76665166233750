import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../../MUI/BackdropComponent";
import {
  EditProfileContainer,
  ProfileImageContainer,
  BackButton,
  CloseButton,
  SaveButton,
  ProfileHeader,
  BasicInfo,
  DetailsInfo,
  ButtonsContainer,
  ProfileImagePicker,
  FinishButton,
} from "./EditProfile.styled";
import selectedTheme from "../../../../themes";
import { useFormik } from "formik";
import { ReactComponent as ArrowBack } from "../../../../assets/images/svg/arrow-back.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/svg/close-modal.svg";
import { useTranslation } from "react-i18next";
import {
  editProfile,
  editProfileAsAdmin,
  fetchAllProfilesAsAdmin,
  fetchMineProfile,
} from "../../../../store/actions/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../../../store/selectors/loginSelectors";
import editProfileValidation from "../../../../validations/editProfileValidation";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useMemo } from "react";
import editProfileInitialValues from "../../../../initialValues/editProfileInitialValues";
import FirmNameField from "./FirmNameField/FirmNameField";
import PIBField from "./PIBField/PIBField";
import LocationField from "./LocationField/LocationField";
import WebsiteField from "./WebsiteField/WebsiteField";
// import AppLinkField from "./AppLinkField/AppLinkField";
import PhoneField from "./PhoneField/PhoneField";
import FormikErrorMessage from "./FormikErrorMessage/FormikErrorMessage";
import {
  dynamicRouteMatches,
  routeMatches,
} from "../../../../util/helpers/routeHelpers";
import {
  ADMIN_HOME_PAGE,
  ADMIN_USERS_PAGE,
  PROFILE_PAGE,
} from "../../../../constants/pages";
import { selectIsLoadingByActionType } from "../../../../store/selectors/loadingSelectors";
import {
  PROFILE_EDIT_ADMIN_SCOPE,
  PROFILE_EDIT_SCOPE,
} from "../../../../store/actions/profile/profileActionConstants";
import { closeModal } from "../../../../store/actions/modal/modalActions";

const EditProfile = (props) => {
  const [profileImage, setProfileImage] = useState(props.profile.image);
  const [showBasic, setShowBasic] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoadingEdit = useSelector(
    selectIsLoadingByActionType(
      props.isAdmin ? PROFILE_EDIT_ADMIN_SCOPE : PROFILE_EDIT_SCOPE
    )
  );
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectUserId);
  const locations = useSelector((state) => state.locations.locations);

  useEffect(() => {
    setShowDetails(!isMobile);
  }, [isMobile]);

  const handleApiResponseSuccess = () => {
    if (dynamicRouteMatches(PROFILE_PAGE)) dispatch(fetchMineProfile(userId));
    if (routeMatches(ADMIN_USERS_PAGE) || routeMatches(ADMIN_HOME_PAGE))
      dispatch(fetchAllProfilesAsAdmin());
    props.reFetchProfile();
    closeEditModalHandler();
  };

  const handleSubmit = (values) => {
    if (props.isAdmin) {
      dispatch(
        editProfileAsAdmin({
          userId: props.userId,
          ...values,
          firmLogo: profileImage === props.profile.image ? null : profileImage,
          handleApiResponseSuccess,
        })
      );
    } else {
      dispatch(
        editProfile({
          ...values,
          firmLogo: profileImage === props.profile.image ? null : profileImage,
          handleApiResponseSuccess,
        })
      );
    }
  };
  const initialValues = useMemo(
    () => editProfileInitialValues(props?.profile),
    [props?.profile]
  );
  const validationSchema = useMemo(() => {
    return editProfileValidation(locations);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const closeEditModalHandler = () => {
    dispatch(closeModal());
  };

  const showDetailsHandler = () => {
    setShowDetails(!showDetails);
    setShowBasic(!showBasic);
  };

  const setImage = (image) => {
    setProfileImage(image);
  };

  return (
    <>
      <BackdropComponent
        handleClose={closeEditModalHandler}
        isLoading
        position="fixed"
      />
      <EditProfileContainer component="form" onSubmit={formik.handleSubmit}>
        {!showBasic && (
          <BackButton onClick={showDetailsHandler}>
            <ArrowBack />
          </BackButton>
        )}
        <ProfileImageContainer>
          <ProfileImagePicker
            image={profileImage}
            setImage={setImage}
          ></ProfileImagePicker>
          <ProfileHeader>{props.profile.company.name}</ProfileHeader>
        </ProfileImageContainer>
        <CloseButton onClick={closeEditModalHandler}>
          <CloseIcon />
        </CloseButton>
        {showBasic && (
          <BasicInfo>
            <FirmNameField formik={formik} />
            <PIBField formik={formik} isAdmin={props.isAdmin} />
            <LocationField formik={formik} />
          </BasicInfo>
        )}
        {showDetails && (
          <DetailsInfo>
            <WebsiteField formik={formik} />
            {/* <AppLinkField formik={formik} /> */}
            <PhoneField formik={formik} />
          </DetailsInfo>
        )}
        <FormikErrorMessage
          formik={formik}
          showBasic={showBasic}
          showDetails={showDetails}
        />

        <ButtonsContainer>
          {isMobile && (
            <>
              <SaveButton
                height="44px"
                width="155px"
                buttoncolor={selectedTheme.colors.primaryPurple}
                textcolor={selectedTheme.colors.primaryPurple}
                onClick={showDetailsHandler}
              >
                {showDetails
                  ? t("editProfile.showBasic")
                  : t("editProfile.showDetails")}
              </SaveButton>
            </>
          )}
          <FinishButton
            type="submit"
            variant="contained"
            height={isMobile ? "44px" : "48px"}
            width={isMobile ? "155px" : "335px"}
            buttoncolor={selectedTheme.colors.primaryPurple}
            textcolor="white"
            isLoading={isLoadingEdit}
          >
            {t("common.save")}
          </FinishButton>
        </ButtonsContainer>
      </EditProfileContainer>
    </>
  );
};

EditProfile.propTypes = {
  children: PropTypes.node,
  profile: PropTypes.any,
  closeModalHandler: PropTypes.func,
  setImage: PropTypes.func,
  reFetchProfile: PropTypes.func,
  isAdmin: PropTypes.bool,
  userId: PropTypes.string,
};

export default EditProfile;

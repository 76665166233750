import styled from "styled-components";
import { BackgroundTransition, ItemsTransition } from "../../../../Styles/globalStyleComponents";

export const SkeletonChooserTitleContainer = styled(ItemsTransition)`
  margin-top: ${(props) => (props.center ? "44px" : "18px")};
  width: 100%;
  height: 40px;
  padding: 13px 18px;
`;
export const SkeletonChooserTitleLine = styled(BackgroundTransition)`
  width: 108px;
  height: 14px;
  ${(props) =>
    props.center &&
    `
        margin: auto;
    `}
`;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { AutoSuggestTextFieldContainer } from "./AutoSuggestTextField.styled";
import { useMemo } from "react";

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const AutoSuggestTextField = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const data = [...props.data];

  const hideSuggestions = useMemo(() => {
    if (suggestions.length === 1) {
      if (suggestions[0].isAddNew) return true;
    }
    return false;
  });

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());

    // if (escapedValue === "") {
    //   return [];
    // }

    const regex = new RegExp("^" + escapedValue, "i");
    const suggestions = data.filter((dataItem) => regex.test(dataItem.name));

    if (suggestions.length === 0) {
      return [{ isAddNew: true }];
    }

    return suggestions;
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion) => {
    return suggestion.name;
  };
  const inputProps = {
    placeholder: props.placeholder,
    value: props.value,
    onChange: props.onChange,
  };

  return (
    <AutoSuggestTextFieldContainer
      editLocation={props.editLocation}
      error={props.error}
      hideSuggestions={hideSuggestions}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={() => true}
      />
    </AutoSuggestTextFieldContainer>
  );
};

AutoSuggestTextField.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  editLocation: PropTypes.bool,
  error: PropTypes.bool,
};
AutoSuggestTextField.defaultProps = {
  error: false,
  editLocation: false,
};

export default AutoSuggestTextField;

import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonFilterCardContainer,
  SkeletonHeader,
  SkeletonHeaderLineOne,
  SkeletonHeaderLineSecond,
} from "./SkeletonFilterCard.styled";
import SkeletonChooserHeader from "./SkeletonChooserHeader/SkeletonChooserHeader";
import SkeletonChooserTitle from "./SkeletonChooserTitle/SkeletonChooserTitle";
import SkeletonSection from "./SkeletonSection/SkeletonSection";

const SkeletonFilterCard = (props) => {

  return (
    <SkeletonFilterCardContainer skeleton={props.skeleton}>
      <SkeletonHeader>
        <SkeletonHeaderLineOne  />
        <SkeletonHeaderLineSecond />
      </SkeletonHeader>
      <SkeletonChooserHeader/>
      <SkeletonChooserTitle />
      <SkeletonSection numberOfOptions={14} />
      <SkeletonChooserHeader />
      <SkeletonChooserHeader />
      <SkeletonChooserTitle />
      <SkeletonSection numberOfOptions={3} />
      <SkeletonChooserTitle center />
    </SkeletonFilterCardContainer>
  );
};

SkeletonFilterCard.propTypes = {
  children: PropTypes.any,
  skeleton: PropTypes.bool,
};

export default SkeletonFilterCard;

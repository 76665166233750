import { getRequest, postRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchChats = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.chat.getUserChats + "?" + payload.queryString, {
      userId: payload.userId,
    })
  );
};
export const attemptFetchHeaderChats = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.chat.getUserChats, { userId: payload }) +
      `?page=1&size=2`
  );
};
export const attemptFetchOneChat = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.chat.getOneChat, {
      userId: payload.userId,
      chatId: payload.chatId,
    })
  );
};
export const attemptSendMessage = (chatId, message) => {
  return postRequest(apiEndpoints.chat.sendMessage + `/${chatId}`, message);
};
export const attemptCreateNewChat = (payload) => {
  return postRequest(
    replaceInUrl(apiEndpoints.chat.createChat, {
      userId: payload.userId,
      offerId: payload.offerId,
    })
  );
};

export const attemptGetExistChat = (payload) => {
  return getRequest(
    replaceInUrl(apiEndpoints.chat.getExistChat, {
      userId: payload.userId,
      offerId: payload.offerId,
    })
  );
};

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { HeaderContainer, HeaderText, ButtonContainer } from "./Header.styled";
import { ArrowButton } from "../../Buttons/ArrowButton/ArrowButton";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleBackButton = () => {
    console.log("clicked");
    history.goBack();
  };

  return (
    <HeaderContainer
      onClick={handleBackButton}
      component="header"
      className={props.className}
    >
      <ButtonContainer>
        <ArrowButton side={"left"} onClick={handleBackButton}></ArrowButton>
        <HeaderText>{t("itemDetailsCard.headerTitle")}</HeaderText>
      </ButtonContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  setIsGrid: PropTypes.func,
  isGrid: PropTypes.bool,
  filters: PropTypes.array,
  category: PropTypes.string,
  className: PropTypes.string,
};
Header.defaultProps = {
  isGrid: false,
};

export default Header;

import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { DrawerOption } from "../../Drawer.styled";
import { ReactComponent as BuildingShield } from "../../../../../assets/images/svg/building-shield.svg";

export const GrayDrawerOption = styled(DrawerOption)`
  color: ${selectedTheme.colors.primaryPurple};
  letter-spacing: 0.05em;
`;
export const PrivacyPolicyIcon = styled(BuildingShield)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke-width: 0;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  CategoryRemoveButtonContainer,
  RemoveIcon,
} from "./CategoryRemoveButton.styled";

const CategoryRemoveButton = (props) => {
  return (
    <CategoryRemoveButtonContainer disabled={props.disabled} onClick={props.onClick}>
      <RemoveIcon disabled={props.disabled} />
    </CategoryRemoveButtonContainer>
  );
};

CategoryRemoveButton.propTypes = {
  category: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CategoryRemoveButton;

import React from "react";
import UserReviewsSkeleton from "../NoReviews/UserReviewsSkeleton/UserReviewsSkeleton";
import {
  SkeletonUserReviewsHeader,
  SkeletonUserReviewsHeaderFirstline,
  SkeletonUserReviewsHeaderFilter,
  SkeletonUserReviewsHeaderSecondline,
  SkeletonUserReviewsHeaderFilterRound,
  SkeletonUserReviewsMainContainer,
} from "./SkeletonUserReviews.styled";

const SkeletonUserReviews = () => {
  return (
    <>
      <SkeletonUserReviewsHeader>
        <SkeletonUserReviewsHeaderFirstline />
        <SkeletonUserReviewsHeaderFilter>
          <SkeletonUserReviewsHeaderSecondline />
          <SkeletonUserReviewsHeaderFilterRound />
        </SkeletonUserReviewsHeaderFilter>
      </SkeletonUserReviewsHeader>
      <SkeletonUserReviewsMainContainer>
        <UserReviewsSkeleton skeleton />
        <UserReviewsSkeleton skeleton />
      </SkeletonUserReviewsMainContainer>
    </>
  );
};

export default SkeletonUserReviews;

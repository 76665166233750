import * as Yup from "yup";
// import { conditionSelectEnum } from "../../enums/conditionEnum";
// import { typeSelectEnum } from "../../enums/typeEnum";

export default Yup.object().shape({
  condition: Yup.string()
    .required()
    .oneOf(Object.values(["Novo", "Polovno", "Kao novo", "Usluga", "default"])),
  type: Yup.string().oneOf(Object.values(["Usluga", "Proizvod", "default"])),
});

import React from "react";
import PropTypes from "prop-types";
import {
  RegisterSuccessfulContainer,
  RegisterDescription,
  RegisterTitle,
} from "./RegisterSuccessful.styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { HOME_PAGE } from "../../../constants/pages";
import RegisterRiv from "../../../assets/animations/register.riv";
import { useRive } from "rive-react";
import { useEffect } from "react";

const RegisterSuccessful = () => {
  const { t } = useTranslation();
  const history = useHistory();

  //Redirect to Login page when registration is successful
  useEffect(() => {
    setTimeout(() => {
      history.replace(HOME_PAGE);
    }, 1700);
  }, []);

  const { RiveComponent } = useRive({
    src: RegisterRiv,
    autoplay: true,
  });

  return (
    <RegisterSuccessfulContainer>
      <RiveComponent/>

      <RegisterTitle component="h1" variant="h5">
        {t("register.success")}
      </RegisterTitle>

      <RegisterDescription component="h1" variant="h6">
        {t("register.welcome")}
      </RegisterDescription>
    </RegisterSuccessfulContainer>
  );
};

RegisterSuccessful.propTypes = {
  children: PropTypes.node,
};

export default RegisterSuccessful;

import React from "react";
import PropTypes from "prop-types";
import { AboutHeaderContainer, LinkRoute } from "./AboutHeader.styled";
import { useTranslation } from "react-i18next";
import scrollConstants from "../../../constants/scrollConstants";
import { useSelector } from "react-redux";
import { selectAboutRouteSelected } from "../../../store/selectors/appSelectors";
import { useHistory } from "react-router-dom";

const AboutHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const aboutRouteSelected = useSelector(selectAboutRouteSelected);
  const navigateAbout = () => {
    history.replace({
      state: {
        clicked: true,
        navigation: scrollConstants.about.aboutPage,
      },
    });
  };
  // const navigatePrices = () => {
  //   history.replace({
  //     state: {
  //       clicked: true,
  //       navigation: scrollConstants.about.pricesPage,
  //     },
  //   });
  // };
  const navigatePrivacyPolicy = () => {
    history.replace({
      state: {
        clicked: true,
        navigation: scrollConstants.about.privacyPolicyPage,
      },
    });
  };
  return (
    <AboutHeaderContainer>
      <LinkRoute
        selected={aboutRouteSelected === scrollConstants.about.aboutPage}
        onClick={navigateAbout}
      >
        {t("about.header.navigation")}
      </LinkRoute>
      {/* <LinkRoute
        selected={aboutRouteSelected === scrollConstants.about.pricesPage}
        onClick={navigatePrices}
      >
        {t("prices.header.navigation")}
      </LinkRoute> */}
      <LinkRoute
        selected={
          aboutRouteSelected === scrollConstants.about.privacyPolicyPage
        }
        onClick={navigatePrivacyPolicy}
      >
        {t("privacyPolicy.header.navigation")}
      </LinkRoute>
    </AboutHeaderContainer>
  );
};

AboutHeader.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.string,
};

export default AboutHeader;

import React from "react";
import PropTypes from "prop-types";
import { CheckBox as CheckboxButton } from "../../../../../CheckBox/CheckBox";

const Checkbox = (props) => {
  const item = props.item;

  return (
    <CheckboxButton
      leftText={props.companies ? item.companyName : item.city}
      rightText={props.companies && item.offerCount}
      value={item}
      checked={
        props.filters.find((itemInList) =>
          props.companies
            ? itemInList?.companyName.toString() ===
              item?.companyName.toString()
            : itemInList?.city?.toString() === item?.city?.toString()
        )
          ? true
          : false
      }
      onChange={props.onChange}
      fullWidth
    />
  );
};

Checkbox.propTypes = {
  item: PropTypes.any,
  filters: PropTypes.any,
  onChange: PropTypes.func,
  companies: PropTypes.bool,
};

export default Checkbox;

import styled from "styled-components";
import selectedTheme from "../../../themes";
import Option from "../../Select/Option/Option";
import Select from "../../Select/Select";
import { ReactComponent as DownArrow } from "../../../assets/images/svg/down-arrow.svg";

export const HeaderSelect = styled(Select)`
  width: 133px;
  height: 35px;
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 3px;
  font-weight: 400;
  position: relative;
  left: -5px;
  background-color: white;
  & div:first-child {
    padding-right: 0 !important;
    padding-left: 8px;
  }

  @media (max-width: 650px) {
    width: 144px;
    height: 30px;
    font-size: 14px;
  }
`;
export const SelectOption = styled(Option)`
  @media (max-width: 600px) {
    height: 36px !important;
    min-height: 35px;
    margin: 4px;
    font-size: 14px;
  }
`;
export const DownArrowIcon = styled(DownArrow)``;

import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import PropTypes from "prop-types";
import {
  DownArrowIcon,
  HeaderSelect,
  SelectOption,
} from "./ReviewsSorting.styled";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedReviews } from "../../../store/selectors/reviewSelector";
import { sortReviews } from "../../../util/helpers/reviewsHelper";
import { reviewSortEnum } from "../../../enums/reviewEnum";
import { setReviews } from "../../../store/actions/review/reviewActions";
import { sortAdminEnum } from "../../../enums/sortEnum";

const ReviewsSorting = forwardRef((props, ref) => {
  const reviews = useSelector(selectSelectedReviews);
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const changeValue = (event) => {
    if (props.isAdmin) {
      // if(event.target.value.value === 1) {
      //   dispatch(setReviews(reviews.givenReviews));
      // } else {
      //   dispatch(setReviews(reviews.receivedReviews));
      // }
    } else {
      dispatch(
        setReviews(
          sortReviews(
            reviews,
            event.target.value.value === reviewSortEnum.POSITIVE.value
          )
        )
      );
    }
    props.changeSorting(event.target.value);
    setValue(event.target.value);
  };
  const sortEnum = useMemo(() => {
    if (props.isAdmin) return sortAdminEnum;
    return reviewSortEnum;
  }, [props.isAdmin]);

  const initialSortOption = useMemo(() => {
    if (props.isAdmin) return sortAdminEnum.GIVEN;
    return reviewSortEnum.INITIAL;
  }, [props.isAdmin]);

  useImperativeHandle(ref, () => ({
    sortValue: value,
    hasGivenReview: value?.value === sortAdminEnum.GIVEN.value,
  }));
  return (
    <HeaderSelect
      value={value || initialSortOption}
      IconComponent={DownArrowIcon}
      onChange={changeValue}
    >
      <SelectOption style={{ display: "none" }} value={initialSortOption}>
        {initialSortOption.mainText}
      </SelectOption>
      {Object.keys(sortEnum).map((property) => {
        if (sortEnum[property].value === 0) return;
        return (
          <SelectOption
            value={sortEnum[property]}
            key={sortEnum[property].value}
          >
            {sortEnum[property].mainText}
          </SelectOption>
        );
      })}
    </HeaderSelect>
  );
});

ReviewsSorting.displayName = "ReviewsSorting";

ReviewsSorting.propTypes = {
  children: PropTypes.node,
  changeSorting: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default ReviewsSorting;

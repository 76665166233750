import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { RegisterAltText, RegisterTextContainer } from "./RegisterLink.styled";
import Link from "../../Link/Link";
import { NavLink } from "react-router-dom";

const RegisterLink = () => {
  const { t } = useTranslation();
  return (
    <RegisterTextContainer>
      <RegisterAltText>
        {t("login.dontHaveAccount").padEnd(2, " ")}
      </RegisterAltText>

      <Link to="/register" component={NavLink} underline="hover" align="center">
        {t("login.signUp")}
      </Link>
    </RegisterTextContainer>
  );
};

RegisterLink.propTypes = {
  children: PropTypes.any,
};

export default RegisterLink;

import styled from "styled-components";
import { PrimaryButton } from "../../../../Buttons/PrimaryButton/PrimaryButton";
import { ReactComponent as Register } from "../../../../../assets/images/svg/register.svg";
import selectedTheme from "../../../../../themes";

export const RegisterButtonContainer = styled(PrimaryButton)`
  height: 49px;
  width: 218px;
  font-weight: 600;
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
`;
export const RegisterIcon = styled(Register)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;

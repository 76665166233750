import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ReactComponent as PlusIcon } from "../../assets/images/svg/plus.svg";

export const MarketPlaceContainer = styled(Box)`
  height: 100%;
  margin: 0 70px;
  @media (max-width: 550px) {
    margin: -30px 1.8rem;
    margin-left: 18px;
    margin-right: 18px;
  }
`;

export const AddOfferButton = styled(Box)`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.primaryPurple};
  position: fixed;
  right: 12px;
  bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddPlusIcon = styled(PlusIcon)`
  width: 25px;
  path {
    stroke: ${selectedTheme.colors.iconYellowColor};
    stroke-width: 6px;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {
  HeaderPopoverContainer,
  NameOfProduct,
  PopoverButton,
  PopoverButtonsContainer,
  PopoverList,
  PopoverListItem,
  PopoverListItemAvatar,
  PopoverListItemAvatarContainer,
  PopoverListItemProfileAvatar,
  PopoverListItemTextContainer,
  PopoverTitle,
  SecondaryText,
  SecondaryTextContainer,
} from "./HeaderPopover.styled";
import useIsMobile from "../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../util/helpers/imageUrlGetter";
import { useMemo } from "react";
import MyMessagesNotFound from "../MyMessages/MyMessagesNotFound/MyMessagesNotFound";
import MyPostsNotFound from "../MyPosts/MyPostsNotFound/MyPostsNotFound";
import { useSelector } from "react-redux";
import { selectMineProfile } from "../../../store/selectors/profileSelectors";
// import { selectRoles } from "../../../store/selectors/loginSelectors";

const HeaderPopover = (props) => {
  // const role = useSelector(selectRoles);
  const profile = useSelector(selectMineProfile);
  const isAdmin = profile?.roles?.includes("Admin");
  const { isMobile } = useIsMobile();
  const items = useMemo(() => props.items, [props.items]);
  return (
    <HeaderPopoverContainer>
      <PopoverTitle p={2}>{props.title}</PopoverTitle>
      <PopoverList>
        {items?.length > 0 ? (
          items.map((item, index) => {
            return (
              <PopoverListItem key={index}>
                <PopoverListItemAvatarContainer>
                  {props.isProfile ? (
                    <PopoverListItemProfileAvatar
                      alt={item.alt}
                      src={getImageUrl(
                        item.src,
                        variants.profileCard,
                        isMobile
                      )}
                      onClick={item?.onClick}
                    />
                  ) : (
                    <PopoverListItemAvatar
                      alt={item.alt}
                      src={getImageUrl(
                        item.src,
                        variants.profileCard,
                        isMobile
                      )}
                      onClick={item?.onClick}
                    />
                  )}
                </PopoverListItemAvatarContainer>
                <PopoverListItemTextContainer
                  primaryTypographyProps={{
                    onClick: item.onClick,
                  }}
                  primary={item.title}
                  secondary={
                    <SecondaryTextContainer>
                      <SecondaryText>{item.text}</SecondaryText>
                      <NameOfProduct>{item?.bigText}</NameOfProduct>
                    </SecondaryTextContainer>
                  }
                ></PopoverListItemTextContainer>
              </PopoverListItem>
            );
          })
        ) : (
          <>
            {props.noMessages ? (
              <MyMessagesNotFound />
            ) : (
              <MyPostsNotFound addOffer={props.addOffer} />
            )}
          </>
        )}
      </PopoverList>
      <PopoverButtonsContainer hideButtons={props.hideButtons}>
        {isAdmin && (
          <PopoverButton
            sx={{
              mr: 2,
              mb: 2,
            }}
            variant="text"
            endIcon={props.adminButtonIcon}
            onClick={props.adminButtonOnClick}
            disabled={props.noMessages || props.noPosts}
          >
            {props.adminButtonText}
          </PopoverButton>
        )}
        <PopoverButton
          sx={{
            mr: 2,
            mb: 2,
          }}
          variant="text"
          endIcon={props.buttonIcon}
          onClick={props.buttonOnClick}
          disabled={props.noMessages || props.noPosts}
        >
          {props.buttonText}
        </PopoverButton>
        {props.secondButtonText && (
          <PopoverButton
            sx={{
              mr: 2,
              mb: 2,
            }}
            variant="text"
            endIcon={props.secondButtonIcon}
            onClick={props.secondButtonOnClick}
          >
            {props.secondButtonText}
          </PopoverButton>
        )}
      </PopoverButtonsContainer>
      {props.children}
    </HeaderPopoverContainer>
  );
};

HeaderPopover.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  items: PropTypes.array,
  buttonText: PropTypes.string,
  isProfile: PropTypes.bool,
  secondButtonText: PropTypes.string,
  buttonIcon: PropTypes.any,
  secondButtonIcon: PropTypes.any,
  buttonOnClick: PropTypes.func,
  secondButtonOnClick: PropTypes.func,
  hideButtons: PropTypes.bool,
  bigText: PropTypes.string,
  noMessages: PropTypes.bool,
  noPosts: PropTypes.bool,
  addOffer: PropTypes.func,
  adminButtonText: PropTypes.string,
  adminButtonIcon: PropTypes.any,
  adminButtonOnClick: PropTypes.func,
};

export default HeaderPopover;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const MiniChatColumnContainer = styled(Box)`
  position: relative;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const MinIChatColumnList = styled(Box)`
  /* max-height: calc(100vh - 173px); */
  overflow-y: auto;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
`;
export const ChatPagingText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  position: relative;
  top: 6px;
  margin-left: 36px;
  margin-right: 36px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  line-height: 21px;
`;

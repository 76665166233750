import {
  ALL_CATEGORIES,
  COMMA,
  SPREAD,
} from "../../constants/marketplaceHeaderTitle";
import {
  initialSize,
  KEY_CATEGORY,
  KEY_COMPANY,
  KEY_LOCATION,
  KEY_NAME,
  KEY_PAGE,
  KEY_SEARCH,
  KEY_SIZE,
  KEY_SORTBY,
  KEY_SORT_DATE,
  KEY_SORT_POPULAR,
  KEY_SUBCATEGORY,
  VALUE_SORTBY_NEW,
  VALUE_SORTBY_OLD,
  VALUE_SORTBY_POPULAR,
} from "../../constants/queryStringConstants";
import { sortEnum } from "../../enums/sortEnum";
// import { ReactComponent as CategoryHeader } from "../../assets/images/svg/category-header.svg"
// import qs from "query-string";

export const convertQueryStringForFrontend = (queryURL) => {
  const queryObject = new URLSearchParams(queryURL);
  const queryObjectToReturn = new URLSearchParams(queryURL);
  if (queryObject.has(KEY_SORT_DATE)) {
    queryObjectToReturn.delete(KEY_SORT_DATE);
    if (queryObject.get(KEY_SORT_DATE) === "true") {
      queryObjectToReturn.append(KEY_SORTBY, sortEnum.NEW.queryString);
    } else {
      queryObjectToReturn.append(KEY_SORTBY, sortEnum.OLD.queryString);
    }
  }
  if (queryObject.has(KEY_NAME)) {
    queryObjectToReturn.delete(KEY_NAME);
    queryObjectToReturn.append(KEY_SEARCH, queryObject.get(KEY_NAME));
  }
  if (queryObject.has(KEY_SORT_POPULAR)) {
    queryObjectToReturn.delete(KEY_SORT_POPULAR);
    queryObjectToReturn.append(KEY_SORTBY, sortEnum.POPULAR.queryString);
  }
  if (queryObject.has(KEY_SIZE)) {
    queryObjectToReturn.delete(KEY_SIZE);
  }
  if (queryObject.has(KEY_PAGE)) {
    if (queryObject.get(KEY_PAGE) === "1") {
      queryObjectToReturn.delete(KEY_PAGE);
      queryObjectToReturn.delete(KEY_SIZE);
      return queryObjectToReturn.toString();
    } else {
      queryObjectToReturn.set(KEY_PAGE, queryObject.get(KEY_PAGE));
      return queryObjectToReturn.toString();
    }
  }
  return queryObjectToReturn.toString();
};

export const combineQueryStrings = (firstQuery, secondQuery) => {
  const firstQueryObject = new URLSearchParams(firstQuery);
  const secondQueryObject = new URLSearchParams(secondQuery);
  const thirdQueryObject = new URLSearchParams(secondQueryObject.toString());
  let arrayOfProperties = Object.getOwnPropertyNames(
    Object.fromEntries(firstQueryObject)
  );
  arrayOfProperties.forEach((property) => {
    if (!secondQueryObject.has(property)) {
      thirdQueryObject.append(property, firstQueryObject.get(property));
    }
  });

  return thirdQueryObject.toString();
};

export const convertQueryStringForBackend = (queryURL) => {
  const queryObject = new URLSearchParams(queryURL);
  const newQueryObject = new URLSearchParams();
  if (queryObject.has(KEY_CATEGORY)) {
    newQueryObject.append(
      KEY_CATEGORY,
      queryObject.getAll(KEY_CATEGORY)[
        queryObject.getAll(KEY_CATEGORY).length - 1
      ]
    );
  }
  if (queryObject.has(KEY_SUBCATEGORY)) {
    newQueryObject.append(
      KEY_SUBCATEGORY,
      queryObject.getAll(KEY_SUBCATEGORY)[
        queryObject.getAll(KEY_SUBCATEGORY).length - 1
      ]
    );
  }
  if (queryObject.has(KEY_SEARCH) && queryObject.get(KEY_SEARCH)?.length > 0) {
    newQueryObject.append(
      KEY_NAME,
      queryObject.getAll(KEY_SEARCH)[queryObject.getAll(KEY_SEARCH).length - 1]
    );
  }
  if (queryObject.has(KEY_NAME)) {
    newQueryObject.append(
      KEY_NAME,
      queryObject.getAll(KEY_NAME)[queryObject.getAll(KEY_NAME).length - 1]
    );
  }
  if (queryObject.has(KEY_LOCATION)) {
    const arrayOfLocations = queryObject.getAll(KEY_LOCATION);
    arrayOfLocations.forEach((item) => {
      newQueryObject.append(KEY_LOCATION, item);
    });
  }
  if (queryObject.has(KEY_COMPANY)) {
    const arrayOfCompanies = queryObject.getAll(KEY_COMPANY);
    arrayOfCompanies.forEach((item) => {
      newQueryObject.append(KEY_COMPANY, item);
    });
  }
  if (queryObject.has(KEY_SORTBY)) {
    newQueryObject.delete(KEY_SORTBY);
    if (queryObject.get(KEY_SORTBY) === VALUE_SORTBY_NEW) {
      newQueryObject.append(KEY_SORT_DATE, "true");
    }
    if (queryObject.get(KEY_SORTBY) === VALUE_SORTBY_OLD) {
      newQueryObject.append(KEY_SORT_DATE, "false");
    }
    if (queryObject.get(KEY_SORTBY) === VALUE_SORTBY_POPULAR) {
      newQueryObject.append(KEY_SORT_POPULAR, "true");
    }
  }
  if (queryObject.has(KEY_SORT_DATE)) {
    newQueryObject.append(KEY_SORT_DATE, queryObject.get(KEY_SORT_DATE));
  }
  if (queryObject.has(KEY_SORT_POPULAR)) {
    newQueryObject.append(KEY_SORT_POPULAR, queryObject.get(KEY_SORT_POPULAR));
  }
  newQueryObject.append(KEY_SIZE, initialSize);
  if (!queryObject.has(KEY_PAGE)) {
    newQueryObject.append(KEY_PAGE, "1");
  } else {
    if (
      queryObject.has(KEY_CATEGORY) ||
      queryObject.has(KEY_LOCATION) ||
      queryObject.has(KEY_COMPANY)
    ) {
      newQueryObject.delete(KEY_PAGE);
    } else {
      newQueryObject.append(KEY_PAGE, queryObject.get(KEY_PAGE));
    }
  }
  return newQueryObject.toString();
};

export const getQueryObjectHelper = (queryString) => {
  let newObject = {};
  const queryObject = new URLSearchParams(queryString);
  if (queryObject.has(KEY_CATEGORY)) {
    newObject[KEY_CATEGORY] = queryObject.get(KEY_CATEGORY);
  }
  if (queryObject.has(KEY_SUBCATEGORY)) {
    newObject[KEY_SUBCATEGORY] = queryObject.get(KEY_SUBCATEGORY);
  }
  if (queryObject.has(KEY_SEARCH)) {
    newObject[KEY_SEARCH] = queryObject.get(KEY_SEARCH);
  }
  if (queryObject.has(KEY_NAME)) {
    newObject[KEY_NAME] = queryObject.get(KEY_NAME);
  }
  if (queryObject.has(KEY_LOCATION)) {
    const arrayOfLocations = queryObject.getAll(KEY_LOCATION);
    newObject[KEY_LOCATION] = [];
    arrayOfLocations.forEach((item) => {
      newObject[KEY_LOCATION].push(item);
    });
  }
  if (queryObject.has(KEY_COMPANY)) {
    const arrayOfCompanies = queryObject.getAll(KEY_COMPANY);
    newObject[KEY_COMPANY] = [];
    arrayOfCompanies.forEach((item) => {
      newObject[KEY_COMPANY].push(item);
    });
  }
  if (queryObject.has(KEY_SORTBY)) {
    newObject[KEY_SORTBY] = queryObject.get(KEY_SORTBY);
  }
  if (queryObject.has(KEY_SORT_DATE)) {
    newObject[KEY_SORT_DATE] = queryObject.get(KEY_SORT_DATE);
  }
  if (queryObject.has(KEY_SORT_POPULAR)) {
    newObject[KEY_SORT_POPULAR] = queryObject.get(KEY_SORT_POPULAR);
  }
  if (queryObject.has(KEY_PAGE)) {
    newObject[KEY_PAGE] = queryObject.get(KEY_PAGE);
  }
  if (queryObject.has(KEY_SIZE)) {
    newObject[KEY_SIZE] = queryObject.get(KEY_SIZE);
  }
  return newObject;
};

export const makeHeaderStringHelper = (filters) => {
  let categoryString = `${ALL_CATEGORIES}`;
  let subcategoryString = "";
  let locationsString = "";
  let companiesString = "";
  // Adding category to header string
  if (filters?.category?.selectedCategory?.name) {
    categoryString = filters.category.selectedCategory?.name;
    // Adding subcategories to header string
    if (filters.subcategory.selectedSubcategory?.name) {
      subcategoryString = `${SPREAD}${filters.subcategory.selectedSubcategory.name}`;
    }
  }
  // Adding locations to header string
  if (
    filters?.locations?.selectedLocations &&
    filters?.locations?.selectedLocations?.length > 0
  ) {
    locationsString = SPREAD;

    filters.locations.selectedLocations.forEach((location, index) => {
      // Checking if item is last
      if (index + 1 === filters.locations.selectedLocations.length) {
        locationsString += location.city;
      } else {
        locationsString += location.city + COMMA;
      }
    });
  }
  if (
    filters?.companies?.selectedCompanies &&
    filters?.companies?.selectedCompanies?.length > 0
  ) {
    companiesString = SPREAD;

    filters.companies.selectedCompanies.forEach((company, index) => {
      // Checking if item is last
      if (index + 1 === filters.companies.selectedCompanies.length) {
        companiesString += company.companyName;
      } else {
        companiesString += company.companyName + COMMA;
      }
    });
  }
  let headerStringLocal =
    categoryString + subcategoryString + locationsString + companiesString;
  return {
    categoryString,
    subcategoryString,
    locationsString,
    companiesString,
    text: headerStringLocal,
  };
};
export const makeHeaderStringFromQueryObjectHelper = (queryObject) => {
  new URLSearchParams().get;
  let categoryString = `${ALL_CATEGORIES}`;
  let subcategoryString = "";
  let locationsString = "";
  let companiesString = "";
  if (KEY_CATEGORY in queryObject) {
    categoryString = queryObject[KEY_CATEGORY];
    if (KEY_SUBCATEGORY in queryObject) {
      subcategoryString = `${SPREAD}${queryObject[KEY_SUBCATEGORY]}`;
    }
  }
  if (KEY_LOCATION in queryObject) {
    locationsString = SPREAD;
    if (!Array.isArray(queryObject[KEY_LOCATION])) {
      locationsString += queryObject[KEY_LOCATION];
    } else {
      queryObject[KEY_LOCATION].forEach((location, index) => {
        // Checking if item is last
        if (index + 1 === queryObject[KEY_LOCATION].length) {
          locationsString += location;
        } else {
          locationsString += location + COMMA;
        }
      });
    }
  }
  if (KEY_COMPANY in queryObject) {
    companiesString = SPREAD;
    if (!Array.isArray(queryObject[KEY_COMPANY])) {
      companiesString += queryObject[KEY_COMPANY];
    } else {
      queryObject[KEY_COMPANY].forEach((company, index) => {
        // Checking if item is last
        if (index + 1 === queryObject[KEY_COMPANY].length) {
          companiesString += company;
        } else {
          companiesString += company + COMMA;
        }
      });
    }
  }
  let headerStringLocal =
    categoryString + subcategoryString + locationsString + companiesString;

  return {
    categoryString,
    subcategoryString,
    locationsString,
    companiesString,
    text: headerStringLocal,
  };
};
export const makeQueryStringHelper = (filters, paging, search, sorting) => {
  const newQueryString = new URLSearchParams();
  if (filters.category.selectedCategoryLocally?.name) {
    newQueryString.append(
      KEY_CATEGORY,
      filters.category.selectedCategoryLocally.name
    );
  }
  if (filters.subcategory.selectedSubcategoryLocally?.name) {
    newQueryString.append(
      KEY_SUBCATEGORY,
      filters.subcategory.selectedSubcategoryLocally.name
    );
  }
  if (filters.locations.selectedLocationsLocally?.length > 0) {
    filters.locations.selectedLocationsLocally.forEach((location) =>
      newQueryString.append(KEY_LOCATION, location?.city)
    );
  }
  if (filters.companies.selectedCompaniesLocally?.length > 0) {
    filters.companies.selectedCompaniesLocally.forEach((company) =>
      newQueryString.append(KEY_COMPANY, company?.companyName)
    );
  }
  if (sorting.selectedSortOption?.value) {
    if (sorting.selectedSortOption?.value === sortEnum.NEW.value) {
      newQueryString.append(KEY_SORTBY, VALUE_SORTBY_NEW);
    }
    if (sorting.selectedSortOption?.value === sortEnum.OLD.value) {
      newQueryString.append(KEY_SORTBY, VALUE_SORTBY_OLD);
    }
    if (sorting.selectedSortOption?.value === sortEnum.POPULAR.value) {
      newQueryString.append(KEY_SORTBY, VALUE_SORTBY_POPULAR);
    }
  }
  if (paging.currentPage !== 1) {
    newQueryString.append(KEY_PAGE, paging.currentPage);
  }
  newQueryString.append(KEY_SEARCH, search.searchString ?? "");
  return newQueryString;
};
export const changePageQueryStringHelper = (queryString, newPage) => {
  const newQueryString = new URLSearchParams(queryString);
  if (newQueryString.has(KEY_PAGE)) {
    newQueryString.delete(KEY_PAGE);
  }
  newQueryString.set(KEY_PAGE, newPage);
  return newQueryString.toString();
};
export const changePageQueryObjectHelper = (queryObject, newPage) => {
  if (queryObject.has(KEY_PAGE)) {
    queryObject.delete(KEY_PAGE);
  }
  queryObject.set(KEY_PAGE, newPage);
  return queryObject;
};
export const removePageAndSizeHelper = (queryString) => {
  const newQueryString = new URLSearchParams(queryString);
  if (newQueryString.has(KEY_PAGE)) newQueryString.delete(KEY_PAGE);
  if (newQueryString.has(KEY_SIZE)) newQueryString.delete(KEY_SIZE);
  return newQueryString.toString();
};

import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import selectedTheme from "../../themes";
export const ItemDetailsPageContainer = styled(Container)`
  padding: 0;
  margin: 0;
  margin-top: 72px;
  height: 100%;
  width: 100%;
  max-width: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${selectedTheme.colors.offerBackgroundColor};

  @media screen and (max-width: 600px) {
    margin-top: 65px;
  }
`;
export const GridStyled = styled(Grid)``;

import React from "react";
import PropTypes from "prop-types";
import { LoginDescription as Description } from "./LoginDescription.styled";
import { useTranslation } from "react-i18next";

const LoginDescription = (props) => {
  const { t } = useTranslation();
  return (
    <Description component="h1" variant="h6">
      {props.isAdmin?t("admin.login.welcomeText"):t("login.welcomeText")}
    </Description>
  );
};

LoginDescription.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};

export default LoginDescription;

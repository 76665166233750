import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import PrivacyPolicyHeader from "./PrivacyPolicyHeader/PrivacyPolicyHeader";
import { PrivacyPolicyContainer } from "./PrivacyPolicyComponent.styled";
// import PrivacyPolicySection from "./PrivacyPolicySection/PrivacyPolicySection";
// import { useTranslation } from "react-i18next";

const PrivacyPolicyComponent = forwardRef((props, ref) => {
  // const { t } = useTranslation();
  return (
    <PrivacyPolicyContainer id={props.id} ref={ref}>
      <PrivacyPolicyHeader />
      {/* <PrivacyPolicySection
        title={t("privacyPolicy.firstSection.title")}
        text={t("privacyPolicy.firstSection.text")}
      />
      <PrivacyPolicySection
        title={t("privacyPolicy.secondSection.title")}
        text={t("privacyPolicy.secondSection.text")}
      />
      <PrivacyPolicySection
        title={t("privacyPolicy.thirdSection.title")}
        text={t("privacyPolicy.thirdSection.text")}
      />
      <PrivacyPolicySection
        title={t("privacyPolicy.forthSection.title")}
        text={t("privacyPolicy.forthSection.text")}
      /> */}
    </PrivacyPolicyContainer>
  );
});

PrivacyPolicyComponent.displayName = "PrivacyPolicyComponent";

PrivacyPolicyComponent.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default PrivacyPolicyComponent;

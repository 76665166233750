import { useEffect, useState } from "react";

function getScreenWidth() {
  return window.innerWidth;
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  useEffect(() => {
    const resize = () => {
      if (getScreenWidth() < 600) {
        setIsMobile(true);
      } else {
         setIsMobile(false);
      }
    };
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  return { isMobile };
};
export default useIsMobile;

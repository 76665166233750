import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { Icon } from "../../../../Icon/Icon";
import { ReactComponent as Category } from "../../../../../assets/images/svg/category.svg";

export const OfferDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  gap: 0 !important;
`;

export const OfferDescriptionTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${selectedTheme.fonts.textFont};
  overflow: hidden;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 38px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const OfferDescriptionCategory = styled(Typography)`
  font-size: 12px;
  letter-spacing: 2%;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const OfferCategoryContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`

export const CategoryIconContainer = styled(Icon)`
  margin-right: 4px;
  position: relative;
  top: 2px;
  display: inline;
  & svg {
    width: 14px;
    position: relative;
    top: -4px;
  }
`;
export const CategoryIcon = styled(Category)``;

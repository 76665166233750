import {
  REVIEW_GET,
  REVIEW_GET_ERROR,
  REVIEW_GET_SUCCESS,
  REVIEW_GIVE,
  REVIEW_GIVE_ERROR,
  REVIEW_GIVE_SUCCESS,
  REVIEW_REMOVE,
  REVIEW_REMOVE_ERROR,
  REVIEW_REMOVE_SUCCESS,
  REVIEW_GET_AS_ADMIN,
  REVIEW_GET_AS_ADMIN_SUCCESS,
  REVIEW_GET_AS_ADMIN_ERROR,
  REVIEW_SET,
} from "./reviewActionConstants";

export const fetchReviews = (payload) => ({
  type: REVIEW_GET,
  payload,
});
export const fetchReviewsSuccess = () => ({
  type: REVIEW_GET_SUCCESS,
});
export const fetchReviewsError = () => ({
  type: REVIEW_GET_ERROR,
});
export const giveReview = (payload) => ({
  type: REVIEW_GIVE,
  payload,
});
export const giveReviewSuccess = () => ({
  type: REVIEW_GIVE_SUCCESS,
});
export const giveReviewError = () => ({
  type: REVIEW_GIVE_ERROR,
});
export const removeReview = (payload) => ({
  type: REVIEW_REMOVE,
  payload,
});
export const removeReviewSuccess = () => ({
  type: REVIEW_REMOVE_SUCCESS,
});
export const removeReviewError = () => ({
  type: REVIEW_REMOVE_ERROR,
});
export const fetchReviewsAsAdmin = (payload) => ({
  type: REVIEW_GET_AS_ADMIN,
  payload,
});
export const fetchReviewsAsAdminSuccess = () => ({
  type: REVIEW_GET_AS_ADMIN_SUCCESS,
});
export const fetchReviewsAsAdminError = () => ({
  type: REVIEW_GET_AS_ADMIN_ERROR
})
export const setReviews = (payload) => ({
  type: REVIEW_SET,
  payload,
});


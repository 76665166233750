import React from "react";
import PropTypes from "prop-types";
import { IconContainer, SearchIcon, SearchInput } from "./SearchBar.styled";
import { useCallback } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";

const SearchBar = forwardRef((props, ref) => {
  const searchRef = useRef(null);
  const [value, setSearchValue] = useState("");
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    changeSearchValue: (newValue) => {
      setSearchValue(newValue);
    },
    searchValue: value,
  }));
  let listener = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        props.handleSearch(searchRef.current?.value);
      }
    },
    [searchRef]
  );

  const handleFocusSearch = () => {
    searchRef.current.addEventListener("keyup", listener);
  };
  const handleBlurSearch = () => {
    searchRef.current.removeEventListener("keyup", listener);
  };

  return (
    <SearchInput
      fullWidth
      ref={searchRef}
      value={value}
      onChange={(event) => setSearchValue(event.target.value)}
      onFocus={handleFocusSearch}
      onBlur={handleBlurSearch}
      placeholder={t("header.searchOffers")}
      italicPlaceholder
      InputProps={{
        endAdornment: (
          <IconContainer
            onClick={() => props.handleSearch(searchRef.current?.value)}
          >
            <SearchIcon />
          </IconContainer>
        ),
      }}
    />
  );
});

SearchBar.displayName = "SearchBar";

SearchBar.propTypes = {
  handleSearch: PropTypes.func,
  value: PropTypes.string,
};

export default SearchBar;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import {ReactComponent as Mail} from "../../../../assets/images/svg/mail.svg"
import selectedTheme from "../../../../themes";


export const MiniChatColumnHeaderContainer = styled(Box)`
    margin-bottom: 10px;
`
export const MailIcon = styled(Mail)`
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
    & path {
        stroke: ${selectedTheme.colors.primaryDarkTextThird};
    }
`
export const HeaderTitleContent = styled(Typography)`
    font-family: ${selectedTheme.fonts.textFont};
    font-size: 16px;
    position: relative;
    left: 9px;
    color: ${selectedTheme.colors.primaryText};
    bottom: 7px;
`
import React from "react";
import PropTypes from "prop-types";
import { HOME_PAGE } from "../../../../constants/pages";
import {
  ArrowUpRightIcon,
  MarketplaceButtonContainer,
  MarketplaceIcon,
} from "./MarketplaceButton.styled";
import { useTranslation } from "react-i18next";
import history from "../../../../store/utils/history";

const MarketplaceButton = () => {
  const { t } = useTranslation();
  const handleButtonClick = () => {
    history.push({
      pathname: HOME_PAGE,
    });
  };
  return (
    <MarketplaceButtonContainer onClick={handleButtonClick}>
      <MarketplaceIcon /> {t("admin.navigation.marketplace")}
      <ArrowUpRightIcon />
    </MarketplaceButtonContainer>
  );
};

MarketplaceButton.propTypes = {
  children: PropTypes.node,
};

export default MarketplaceButton;

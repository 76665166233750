import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

const EXCHANGE_SCOPE = "EXCHANGE_SCOPE";
export const EXCHANGE_FETCH = createFetchType(EXCHANGE_SCOPE);
export const EXCHANGE_FETCH_SUCCESS = createSuccessType(EXCHANGE_SCOPE);
export const EXCHANGE_FETCH_ERROR = createErrorType(EXCHANGE_SCOPE);

const EXCHANGE_VALIDATE_SCOPE = "EXCHANGE_VALIDATE_SCOPE";
export const EXCHANGE_VALIDATE_FETCH = createFetchType(EXCHANGE_VALIDATE_SCOPE);
export const EXCHANGE_VALIDATE_FETCH_SUCCESS = createSuccessType(
  EXCHANGE_VALIDATE_SCOPE
);
export const EXCHANGE_VALIDATE_FETCH_ERROR = createErrorType(
  EXCHANGE_VALIDATE_SCOPE
);
const EXCHANGE_ACCEPT_SCOPE = "EXCHANGE_ACCEPT_SCOPE";
export const EXCHANGE_ACCEPT_FETCH = createFetchType(EXCHANGE_ACCEPT_SCOPE);
export const EXCHANGE_ACCEPT_FETCH_SUCCESS = createSuccessType(
  EXCHANGE_ACCEPT_SCOPE
);
export const EXCHANGE_ACCEPT_FETCH_ERROR = createErrorType(
  EXCHANGE_ACCEPT_SCOPE
);

export const EXCHANGE_SET = createSetType("EXCHANGE_SET");
export const REQUESTER_SET = createSetType("REQUESTER_SET");

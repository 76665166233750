import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import selectedTheme from "../../themes";
export const HomePageContainer = styled(Container)`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${selectedTheme.colors.primaryBackgroundColor};
`;
export const GridStyled = styled(Grid)`
  
`;

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAboutRouteSelected } from "../../store/selectors/appSelectors";
import scrollConstants from "../../constants/scrollConstants";
import { setAboutRouteSelected } from "../../store/actions/app/appActions";
import { AboutPageContainer } from "./AboutPageContent.styled";
import AboutComponent from "./AboutComponent";
// import PricesComponent from "../Prices/PricesComponent";
import PrivacyPolicyComponent from "../PrivacyPolicy/PrivacyPolicyComponent";
import AboutFooter from "../Footer/AboutFooter";

const AboutPageContent = () => {
  const aboutRef = useRef(null);
  // const pricesRef = useRef(null);
  const privacyPolicyRef = useRef(null);
  const dispatch = useDispatch();
  const aboutRouteSelected = useSelector(selectAboutRouteSelected);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state?.clicked) {
      if (location.state.navigation === scrollConstants.about.aboutPage) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (aboutRouteSelected !== scrollConstants.about.aboutPage) {
          dispatch(setAboutRouteSelected(scrollConstants.about.aboutPage));
        }
      }
      // if (location.state.navigation === scrollConstants.about.pricesPage) {
      //   const yAxis = pricesRef.current.offsetTop;
      //   window.scrollTo({ top: yAxis, behavior: "smooth" });
      //   if (aboutRouteSelected !== scrollConstants.about.pricesPage) {
      //     dispatch(setAboutRouteSelected(scrollConstants.about.pricesPage));
      //   }
      // }
      if (
        location.state.navigation === scrollConstants.about.privacyPolicyPage
      ) {
        const yAxis = privacyPolicyRef.current.offsetTop - 64;
        window.scrollTo({ top: yAxis, behavior: "smooth" });
        if (aboutRouteSelected !== scrollConstants.about.privacyPolicyPage) {
          dispatch(
            setAboutRouteSelected(scrollConstants.about.privacyPolicyPage)
          );
        }
      }
      location.state = {};
    }
  }, [location]);

  useEffect(() => {
    const listener = () => {
      if (
        window.scrollY >
        privacyPolicyRef.current.offsetTop - window.innerHeight / 2
      ) {
        if (
          window.scrollY >
          privacyPolicyRef.current.offsetTop - window.innerHeight / 2
        ) {
          if (aboutRouteSelected !== scrollConstants.about.privacyPolicyPage) {
            dispatch(
              setAboutRouteSelected(scrollConstants.about.privacyPolicyPage)
            );
          }
        } else if (aboutRouteSelected !== scrollConstants.about.pricesPage) {
          dispatch(setAboutRouteSelected(scrollConstants.about.pricesPage));
        }
      } else {
        if (aboutRouteSelected !== scrollConstants.about.aboutPage) {
          dispatch(setAboutRouteSelected(scrollConstants.about.aboutPage));
        }
      }
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, [aboutRouteSelected]);
  return (
    <AboutPageContainer>
      <AboutComponent ref={aboutRef} id={scrollConstants.about.aboutPage} />
      {/* <PricesComponent ref={pricesRef} id={scrollConstants.about.pricesPage} /> */}
      <PrivacyPolicyComponent
        ref={privacyPolicyRef}
        id={scrollConstants.about.privacyPolicyPage}
      />
      <AboutFooter />
    </AboutPageContainer>
  );
};

AboutPageContent.propTypes = {
  children: PropTypes.node,
};

export default AboutPageContent;

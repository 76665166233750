import React from "react";
import {
  MessagesNotFoundContainer,
  MessagesNotFoundText,
  MessagesNotFoundTextSecond,
  MessagesNotFoundSkeleton,
  SkeletonImage,
  SkeletonLinesContainer,
  SkeletonFirstLine,
  SkeletonSecondLine,
} from "./MyMessagesNotFound.styled";
import { useTranslation } from "react-i18next";

const MyMessagesNotFound = () => {
  const { t } = useTranslation();
  return (
    <MessagesNotFoundContainer>
      <MessagesNotFoundText> {t("messages.noMessages")}</MessagesNotFoundText>
      <MessagesNotFoundTextSecond>
        {t("messages.noMessagesSecond")}
      </MessagesNotFoundTextSecond>
      <MessagesNotFoundSkeleton>
        <SkeletonImage />
        <SkeletonLinesContainer>
          <SkeletonFirstLine />
          <SkeletonSecondLine />
        </SkeletonLinesContainer>
      </MessagesNotFoundSkeleton>
    </MessagesNotFoundContainer>
  );
};

export default MyMessagesNotFound;

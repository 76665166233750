import { Box, Container, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { PrimaryButton } from "../../Buttons/PrimaryButton/PrimaryButton";
import { Label } from "../../CheckBox/Label";
import Select from "../../Select/Select";

export const ModalCreateOfferContainer = styled(Box)`
  background-color: #fff;
  position: fixed;
  ${(props) => props.currentstep === 3 && `overflow-y: auto;`}
  max-height: 90vh;
  top: ${(props) =>
    props.currentstep === 1 ? "calc(50% - 400px);" : "calc(50% - 350px);"};
  left: ${(props) =>
    props.currentstep !== 3 ? "calc(50% - 310px);" : "calc(50% - 405px);"};
  z-index: 150;
  padding: ${(props) => (props.currentstep !== 3 ? "0 120px" : "0 36px")};
  overflow-y: auto;
  overflow-x: hidden;
  /* ${(props) => props.currentstep === 3 && `height: 712px;`} */
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;

  @media (max-height: 820px) {
    top: ${(props) =>
      props.currentstep === 1 ? "calc(50% - 340px)" : "calc(50% - 340px)"};
  }

  @media (max-width: 810px) {
    left: ${(props) =>
      props.currentstep === 3 &&
      `
      calc(50% - 336px);
      `};
  }

  @media screen and (max-width: 628px) {
    height: 100vh;
    max-height: 100vh;
    min-height: 90vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 0 30px;
    ${(props) => props.currentstep === 3 && `padding: 0;`}
  }
`;

export const ModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackIcon = styled(Box)`
  cursor: pointer;
  position: absolute;
  left: 40px;

  @media screen and (max-width: 600px) {
    left: 20px;

    & svg {
      width: 20px;
    }
  }
`;

// export const CloseIcon = styled(Box)`
//   cursor: pointer;
//   position: absolute;
//   right: 40px;

//   @media screen and (max-width: 600px) {
//     right: 20px;

//     & svg {
//       width: 20px;
//     }
//   }
// `;

export const CreateOfferContainer = styled(Container)`
  margin-top: 0px;
  display: flex;
  width: ${(props) => (props.currentstep === 3 ? "739px" : "380px")};
  flex-direction: column;
  align-items: center;
  ${(props) => props.currentstep === 3 && `padding-bottom: 20px;`}

  @media (max-width: 810px) {
    ${(props) =>
      props.currentstep === 3 &&
      `
        width: 600px;
        
    `}
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    ${(props) => props.currentstep === 3 && `padding: 0 18px;`}
  }
`;
export const CreateOfferTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 30px;
  }
`;
export const CreateOfferDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
`;
export const CreateOfferFormContainer = styled(Box)`
  width: 335px;
  height: 700px;
  ${(props) =>
    props.currentStep === 3 &&
    css`
      width: 100%;
      border-radius: 4px;
      height: 420px;
      border: 1px solid ${selectedTheme.colors.borderNormal};
      margin-top: 40px;
    `}
  @media (max-width: 600px) {
    height: 350px;
  }
`;
export const RegisterAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.altText};
  color: ${selectedTheme.fonts.textFont};
  font-size: 14px;
  padding-right: 6px;
  line-height: 14px;
`;
export const RegisterTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
`;
export const FieldLabel = styled(Label)`
  position: relative;
  bottom: -14px;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
  }
  @media (max-width: 600px) {
    & label {
      font-size: 9px;
    }
  }
`;
export const SelectText = styled(Typography)`
  font-size: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 400;
`;
export const SelectField = styled(Select)`
  position: relative;
  top: 15px;
  margin-bottom: 18px;
  @media (max-width: 600px) {
    height: 40px;
    font-size: 12px;
  }
  & div {
    ${SelectText} {
      font-weight: 600;
    }
  }
`;

export const SelectAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};

  font-style: italic;
  white-space: pre;
  font-size: 12px;
  position: relative;
  bottom: -1px;
`;
export const NextButtonContainer = styled(PrimaryButton)`
  margin-top: 16px;
  width: 100%;

  @media (min-width: 601px) {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 600px) {
    /* position: absolute; */
    /* bottom: 18px; */
    height: 44px;
    width: 339px;
    /* left: 9px; */
    & button {
      height: 44px;
    }
  }

  @media (max-width: 360px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }

  @media (max-height: 660px) {
    position: relative;
    width: 100%;
    margin-top: 34px;
  }
`;
export const ErrorText = styled(Typography)`
  color: red;
  font-family: ${selectedTheme.fonts.textFont};
  height: 20px;
  font-size: 14px;
`;

import { css } from "styled-components";
import { uButtonClear, uFlexCenter, uOutlineNone } from "./utility";

export const customClasses = css`
.c-icon-button {
    
    ${uFlexCenter}
    ${uOutlineNone}
    ${uButtonClear}
    user-select: none;
    cursor: pointer;
  }
`;
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { ProfileContainer } from "./Profile.styled";
import ProfileCard from "../Cards/ProfileCard/ProfileCard";
import ProfileOffers from "./ProfileOffers/ProfileOffers";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../store/selectors/loginSelectors";
import { useRouteMatch } from "react-router-dom";
import { fetchProfile } from "../../store/actions/profile/profileActions";
import Header from "./Header/Header";
import { fetchChats } from "../../store/actions/chat/chatActions";

const Profile = (props) => {
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const profileId = useMemo(() => routeMatch.params?.profileId, [routeMatch]);
  useEffect(() => {
    if (profileId?.length > 0) {
      dispatch(fetchProfile(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    if (userId)
      dispatch(
        fetchChats({
          currentPage: 1,
        })
      );
  }, [userId]);
  const isMyProfile = useMemo(() => {
    return userId === routeMatch.params?.profileId;
  }, [userId, routeMatch]);
  return (
    <ProfileContainer className={props.className}>
      <Header isAdmin={props.isAdmin} />
      <ProfileCard isAdmin={props.isAdmin} isMyProfile={isMyProfile} />
      <ProfileOffers
        isAdmin={props.isAdmin}
        isMyProfile={isMyProfile}
        idProfile={profileId}
      />
    </ProfileContainer>
  );
};

Profile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default Profile;

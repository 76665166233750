import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "../../assets/images/svg/arrow-down.svg";
import selectedTheme from "../../themes";
import ScrollContainer from "react-indiana-drag-scroll";
import { IconButton } from "../Buttons/IconButton/IconButton";

export const HorizontalScrollerContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-right: 5px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 340px;
  }

  @media screen and (max-width: 360px) {
    width: 300px;
  }
`;
export const Arrow = styled(IconButton)`
  border: 1px solid ${selectedTheme.colors.primaryPurple};
  border-radius: 100%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 8px;
  padding-top: 10px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.2s all ease;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple};
    & svg path {
      stroke: white;
    }
  }

  ${(props) =>
    props.disabled &&
    `
        border 1px solid ${selectedTheme.colors.iconStrokeDisabledColor};
        & svg path {
            stroke: ${selectedTheme.colors.iconStrokeDisabledColor};
            transition: 0.2s all ease;
            
        }
    `}
`;
export const ListContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  margin: 0 18px;
  user-select: none;
`;
export const ArrowIcon = styled(DownArrow)`
  ${(props) =>
    props.side === "left" &&
    `
        transform: rotate(180deg);
    `}
  width: 18px;
  height: 18px;
  & path {
    ${(props) =>
      props.disabled &&
      `
        stroke: ${selectedTheme.colors.iconStrokeDisabledColor}
        `}
  }
`;

import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import CategoryCard from "../../../components/Cards/CategoryCard/CategoryCard";
import Header from "../../../components/MarketPlace/Header/Header";
import SearchField from "../../../components/TextFields/SearchField/SearchField";
import selectedTheme from "../../../themes";

export const AdminSubcategoriesPageContainer = styled(Box)`
  padding: 60px;
  /* display: flex; */
  position: relative;
  padding-top: 38px;
  padding-bottom: 100px;
  min-height: 100vh;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 18px;
    min-height: (100vh - 72px);
    padding-bottom: 100px;
    top: 65px;
  }
`;
export const AdminSubcategoriesHeader = styled(Header)`
  top: 4px;
  @media (min-width: 600px) and (max-width: 900px) {
    & > div:nth-child(2) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    height: 10px;
    top: -65px;
    &:nth-child(4) {
      top: 4px;
      & div:nth-child(2) {
        margin-top: 0;
      }
    }
    margin-top: 18px;
    & > div:nth-child(1) {
      margin-top: 10px;
    }
    & div div:nth-child(2) {
      top: 57px;
    }
  }
`;
export const AdminSubcategoriesSearchField = styled(SearchField)`
  top: -15px;
  @media (max-width: 900px) {
    margin-top: 72px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
export const SubcategoriesList = styled(Box)`
  padding-top: 18px;
  @media (max-width: 600px) {
    &:nth-child(4) {
      padding-top: 0;
    }
  }
`;
export const SponsoredCategoryCard = styled(CategoryCard)`
  background: ${selectedTheme.colors.backgroundSponsoredColor};
  border: 1px solid ${selectedTheme.colors.borderSponsoredColor};
`;
export const NewSubcategoryButton = styled(PrimaryButton)`
  height: 48px;
  width: 224px;
  & button {
    font-weight: 700;
  }

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;
export const ButtonContainer = styled(Link)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  text-decoration: none;
`;
export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  text-decoration: none;
  border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
`;

export const SubcategoriesHeader = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

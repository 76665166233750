import { Box } from "@mui/material";
import styled from "styled-components";
import {
  SkeletonBackgroundColor,
  SkeletonItemBackgroundColor,
} from "../../../Cards/ProfileCard/SkeletonProfileCard/SkeletonProfileCard.styled";

export const ProfileOffersHeaderSkeletonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: 600px) {
    margin-bottom: 18px;
  }
`;
export const ProfileOffersHeaderSkeletonGroupOne = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ProfileOffersHeaderSkeletonGroupSecond = styled(
  SkeletonBackgroundColor
)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15.5px;
`;
export const ProfileOffersHeaderSkeletonLineOne = styled(
  SkeletonBackgroundColor
)`
  width: 148px;
  height: 18px;
  @media (max-width: 600px) {
    width: 72px;
    height: 18px;
    position: relative;
    top: 5px;
  }
`;
export const ProfileOffersHeaderSkeletonLineSecond = styled(
  SkeletonBackgroundColor
)`
  width: 209px;
  height: 34px;
  @media (max-width: 600px) {
    width: 137px;
    height: 29px;
  }
`;
export const ProfileOffersHeaderSkeletonLineThird = styled(
  SkeletonItemBackgroundColor
)`
  width: 120px;
  margin-top: 2px;
  height: 14px;
  @media (max-width: 600px) {
    width: 108px;
  }
`;
export const ProfileOffersHeaderSkeletonLineForth = styled(
  SkeletonItemBackgroundColor
)`
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    display: none;
  }
`;

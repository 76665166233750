import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  ChatPagingText,
  MiniChatColumnContainer,
  MinIChatColumnList,
} from "./MiniChatColumn.styled";
import MiniChatCard from "../../Cards/MiniChatCard/MiniChatCard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChatsTotal,
  selectLatestChats,
  selectSelectedChat,
} from "../../../store/selectors/chatSelectors";
import { fetchChats, setOneChat } from "../../../store/actions/chat/chatActions";
import MiniChatColumnHeader from "./MiniChatColumnHeader/MiniChatColumnHeaderTitle";
import { useLocation } from "react-router-dom";
import { selectOffer } from "../../../store/selectors/offersSelectors";
import SkeletonMiniChatColumn from "./SkeletonMiniChatColumn/SkeletonMiniChatColumn";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { CHAT_SCOPE } from "../../../store/actions/chat/chatActionConstants";
import usePaging from "../../../hooks/useOffers/usePaging";
import Paging from "../../Paging/Paging";

const MiniChatColumn = () => {
  const chats = useSelector(selectLatestChats);
  const total = useSelector(selectChatsTotal);
  const selectedChat = useSelector(selectSelectedChat);
  const offer = useSelector(selectOffer);
  const location = useLocation();
  const dispatch = useDispatch();
  const [changedPage, setChangedPage] = useState(false);
  const paging = usePaging();
  const isLoadingMiniChat = useSelector(
    selectIsLoadingByActionType(CHAT_SCOPE)
  );
  const newChat = useMemo(() => {
    if (location.state?.offerId) {
      return {
        participants: [
          {
            image: offer?.user?.image,
            company: {
              name: offer?.user?.company?.name,
            },
          },
        ],
        offer: {
          name: offer?.name,
        },
      };
    }
    return {};
  }, [offer, location.state]);

  useEffect(() => {
    if (changedPage && !isLoadingMiniChat) {
      dispatch(setOneChat(chats[0]))
    }
  }, [changedPage, isLoadingMiniChat])

  useEffect(() => {
    dispatch(
      fetchChats({
        currentPage: paging.currentPage,
      })
    );
  }, [paging.currentPage]);
  const handleChangePage = (newPage) => {
    setChangedPage(true);
    paging.changePage(newPage);
  };
  return (
    <>
      {isLoadingMiniChat || isLoadingMiniChat === undefined ? (
        <SkeletonMiniChatColumn />
      ) : (
        <MiniChatColumnContainer>
          <MiniChatColumnHeader />
          <MinIChatColumnList>
            {location.state?.offerId && (
              <MiniChatCard chat={newChat} selected />
            )}
            {chats.map((item) => {
              return (
                <MiniChatCard
                  key={Date.now() * Math.random()}
                  chat={item}
                  selected={item?._id === selectedChat?._id}
                />
              );
            })}
          </MinIChatColumnList>
          <Paging
            elementsPerPage={6}
            customPaging
            current={paging.currentPage}
            totalElements={total}
            changePage={handleChangePage}
          >
            <ChatPagingText>
              {(paging.currentPage - 1) * 6 + 1}-
              {(paging.currentPage - 1) * 6 + chats.length}
            </ChatPagingText>
          </Paging>
        </MiniChatColumnContainer>
      )}
    </>
  );
};

MiniChatColumn.propTypes = {
  children: PropTypes.node,
};

export default MiniChatColumn;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ChatCard from "../Cards/ChatCard/ChatCard";
import {
  ChatColumnContainer,
  ChatPagingText,
  HeaderBack,
  HeaderSelect,
  ListContainer,
  SelectOption,
  TitleSortContainer,
} from "./ChatColumn.styled";
import { sortEnum } from "../../enums/sortEnum";
import { ReactComponent as Down } from "../../assets/images/svg/down-arrow.svg";
import { IconStyled } from "../Icon/Icon.styled";
import { Grid } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { HeaderTitle } from "../Cards/ProfileCard/ProfileCard.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectLatestChats } from "../../store/selectors/chatSelectors";
import {
  addNewMessage,
  fetchChats,
} from "../../store/actions/chat/chatActions";
import useSorting from "../../hooks/useOffers/useSorting";
import { addMessageListener, removeMessageListener } from "../../socket/socket";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { CHAT_SCOPE } from "../../store/actions/chat/chatActionConstants";
import SkeletonChatColumn from "./SkeletonChatColumn/SkeletonChatColumn";
import Paging from "../Paging/Paging";
import usePaging from "../../hooks/useOffers/usePaging";
import useIsMobile from "../../hooks/useIsMobile";

export const DownArrow = (props) => {
  <IconStyled {...props}>
    <Down />
  </IconStyled>;
};

export const ChatColumn = (props) => {
  const dispatch = useDispatch();
  const sorting = useSorting();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const [sortOption, setSortOption] = useState(sortEnum.INITIAL);
  const chats = useSelector(selectLatestChats);
  const paging = usePaging();
  const isLoadingChats = useSelector(selectIsLoadingByActionType(CHAT_SCOPE));
  useEffect(() => {
    dispatch(
      fetchChats({
        currentPage: paging.currentPage,
      })
    );
  }, [paging.currentPage]);

  useEffect(() => {
    if (!isMobile) {
      history.goBack();
      return;
    }
    addMessageListener(({ succeed, data }) => {

      if (succeed) {
        dispatch(
          addNewMessage({
            _id: data.chatId,
            message: data.message,
          })
        );
      } else {
        dispatch(fetchChats());
      }
    });
    return () => removeMessageListener();
  }, []);

  useEffect(() => {
    setSortOption(sorting.selectedSortOption);
  }, [sorting.selectedSortOption]);

  const handleChangeSelect = (event) => {
    let chosenOption;
    for (const sortOption in sortEnum) {
      if (sortEnum[sortOption].value === event.target.value) {
        chosenOption = sortEnum[sortOption];
        sorting.changeSorting(chosenOption);
      }
    }
  };
  const handleChangePage = (newPage) => paging.changePage(newPage);

  return (
    <>
      {isLoadingChats || isLoadingChats === undefined ? (
        <SkeletonChatColumn />
      ) : (
        <ChatColumnContainer>
          <HeaderBack />
          <TitleSortContainer>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <MailOutlineIcon color="action" sx={{ mr: 0.9 }} />
              <HeaderTitle>{t("header.myMessages")}</HeaderTitle>
            </Grid>
            <HeaderSelect
              value={
                sortOption?.value ? sortOption.value : sortEnum.INITIAL.value
              }
              IconComponent={DownArrow}
              onChange={handleChangeSelect}
            >
              {Object.keys(sortEnum).map((property) => {
                return (
                  <SelectOption
                    value={sortEnum[property].value}
                    key={sortEnum[property].value}
                  >
                    {sortEnum[property].mainText}
                  </SelectOption>
                );
              })}
            </HeaderSelect>
          </TitleSortContainer>
          <ListContainer>
            {chats.map((item, index) => (
              <ChatCard key={index} chat={item} navigateToChat={props.navigateToChat} />
            ))}
          </ListContainer>
          <Paging
            elementsPerPage={6}
            customPaging
            current={paging.currentPage}
            pages={
              chats.length === 6 ? paging.currentPage + 1 : paging.currentPage
            }
            changePage={handleChangePage}
          >
            <ChatPagingText>
              {chats?.length > 0 ? (paging.currentPage - 1) * 6 + 1 : 0}-
              {(paging.currentPage - 1) * 6 + chats.length}
            </ChatPagingText>
          </Paging>
        </ChatColumnContainer>
      )}
    </>
  );
};

ChatColumn.propTypes = {
  navigateToChat: PropTypes.func,
}

export default ChatColumn;

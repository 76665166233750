import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../FirstPartCreateOffer.styled";
import { useTranslation } from "react-i18next";
import { TitleField } from "./OfferTitleField.styled";

const OfferTitleField = (props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <>
      <FieldLabel leftText={t("offer.title")} />
      <TitleField
        name="nameOfProduct"
        placeholder={t("offer.productName")}
        italicPlaceholder
        margin="normal"
        value={formik.values.nameOfProduct}
        onChange={formik.handleChange}
        error={formik.touched.nameOfProduct && formik.errors.nameOfProduct}
        helperText={formik.touched.nameOfProduct && formik.errors.nameOfProduct}
        autoFocus
        fullWidth
      />
    </>
  );
};

OfferTitleField.propTypes = {
  formik: PropTypes.any,
};

export default OfferTitleField;

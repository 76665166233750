import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const LoginDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
`;
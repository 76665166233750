import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectModalValues } from "../../store/selectors/modalSelectors";
import CreateOffer from "../Cards/CreateOfferCard/CreateOffer";
import EditCategory from "./EditCategory/EditCategory";
import DeleteCategory from "./DeleteCategory/DeleteCategory";
import CreateReview from "../CreateReview/CreateReview";
import EditProfile from "../Cards/ProfileCard/EditProfile/EditProfile";
import DeleteReview from "./DeleteReview/DeleteReview";
import DeleteOffer from "../Cards/OfferCard/DeleteOffer/DeleteOffer";
import CreatePayment from "./CreatePayment/CreatePayment";

const Modal = () => {
  const modals = useSelector(selectModalValues);
  const isModalShown = useMemo(() => {
    const properties = Object.getOwnPropertyNames(modals);
    let isModalShownLocal = false;
    properties.forEach((singleModal) => {
      if (modals[singleModal] === true) isModalShownLocal = true;
    });
    return isModalShownLocal;
  }, [modals]);
  if (isModalShown) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  return (
    <>
      {modals?.createOfferModal && <CreateOffer {...modals?.props} />}
      {modals?.editOfferModal && <CreateOffer editOffer {...modals?.props} />}
      {modals?.deleteOfferModal && <DeleteOffer {...modals?.props} />}
      {modals?.createCategoryModal && (
        <EditCategory method="add" {...modals?.props} />
      )}
      {modals?.editCategoryModal && (
        <EditCategory method="edit" {...modals?.props} />
      )}
      {modals?.createPaymentModal && <CreatePayment {...modals?.props} />}
      {modals?.editPaymentModal && (
        <CreatePayment editPayment {...modals?.props} />
      )}
      {modals?.deleteCategoryModal && <DeleteCategory {...modals?.props} />}
      {modals?.createReviewModal && <CreateReview {...modals?.props} />}
      {modals?.deleteReviewModal && <DeleteReview {...modals?.props} />}
      {modals?.editProfileModal && <EditProfile {...modals?.props} />}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;

import {  CATEGORIES_SET } from "../../actions/categories/categoriesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  categories: [],
};

export default createReducer(
  {
    [CATEGORIES_SET]: setCategories
  },
  initialState
);

function setCategories(state, action) {
    return {
        ...state,
        categories: action.payload
    }
}

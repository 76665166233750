import { Box, Icon } from "@mui/material";
import styled from "styled-components";

export const IconContainer = styled(Box)`
    
`

export const IconStyled = styled(Icon)`
    color: ${props => props.color};
    width: ${props => props.size};
    height: ${props => props.size};
    & svg path {
        stroke: ${props => props.color};
    }
`
import React from "react";
import PropTypes from "prop-types";
import { AdminInfoContainer, AdminName, AdminText } from "./AdminInfo.styled";
import { useTranslation } from "react-i18next";

const AdminInfo = (props) => {
  const { t } = useTranslation();
  return (
    <AdminInfoContainer>
      <AdminName>{props.name}</AdminName>
      <AdminText>{t("admin.navigation.role")}</AdminText>
    </AdminInfoContainer>
  );
};

AdminInfo.propTypes = {
  name: PropTypes.string,
};

export default AdminInfo;

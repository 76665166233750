import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const HeaderContainer = styled(Box)`
  margin-top: 20px;
`;
export const ButtonContainer = styled(Link)`
    width:fit-content;
    cursor:pointer;
    display: flex;
    justify-content: start;
    align-items:center;
    gap:12px;
    text-decoration: none;
`
export const HeaderText = styled(Typography) `
    font-family: ${selectedTheme.fonts.textFont};
    line-height: 22px;
    font-size: 16px;
    color: ${selectedTheme.colors.primaryPurple};
    text-decoration: none;
    border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
`
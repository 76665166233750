import {
  PROFILE_ALL_SET,
  PROFILE_CLEAR,
  PROFILE_MINE_SET,
  PROFILE_SET,
} from "../../actions/profile/profileActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  profile: {},
  mineProfile: {},
  allProfiles: {},
};

export default createReducer(
  {
    [PROFILE_SET]: setProfile,
    [PROFILE_ALL_SET]: setAllProfiles,
    [PROFILE_MINE_SET]: setMineProfile,
    [PROFILE_CLEAR]: clearProfile,
  },
  initialState
);

function setProfile(state, action) {
  return {
    ...state,
    profile: action.payload,
  };
}
function setMineProfile(state, action) {
  return {
    ...state,
    mineProfile: action.payload,
  };
}
function clearProfile() {
  return initialState;
}
function setAllProfiles(state, { payload }) {
  return {
    ...state,
    allProfiles: payload,
  };
}

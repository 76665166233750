import React from "react";
import PropTypes from "prop-types";
import { selectLoginError } from "../../../../store/selectors/loginSelectors";
import { useSelector } from "react-redux";
import { TextField } from "../../../TextFields/TextField/TextField";
import { useTranslation } from "react-i18next";

const EmailField = (props) => {
  const { t } = useTranslation();
  const error = useSelector(selectLoginError);
  const formik = props.formik;
  return (
    <TextField
      name="email"
      placeholder={t("common.labelEmail")}
      value={formik.values.email}
      onChange={formik.handleChange}
      error={(formik.touched.email && formik.errors.email) || error.length > 0}
      helperText={formik.touched.email && formik.errors.email}
      autoFocus
      fullWidth
    />
  );
};

EmailField.propTypes = {
  formik: PropTypes.any,
};

export default EmailField;

import { Box } from "@mui/material";
import styled from "styled-components";
import { ItemsTransition } from "../../../../Styles/globalStyleComponents";

export const SkeletonChooserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 39px;
  margin-bottom: 3px;
`;

export const CircleOne = styled(ItemsTransition)`
  width: 24px;
  height: 24px;
  border-radius: 100% !important;
  position: relative;
  bottom: 3px;
`;
export const Line = styled(ItemsTransition)`
  width: 117px;
  height: 18px;
`;
export const CircleSecond = styled(ItemsTransition)`
  width: 18px;
  height: 18px;
  border-radius: 100% !important;
`;
export const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 9px;
`;

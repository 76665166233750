import React from "react";
import PropTypes from "prop-types";
import { ColorIconContainer } from "./ColorIcon.styled";

const ColorIcon = (props) => {
  return (
    <ColorIconContainer
      selected={props?.selected}
      backgroundColor={props?.color}
      aboveEditor={props?.aboveEditor}
      selectedInPopover={props?.selectedInPopover}
      onClick={props?.onClick}
    />
  );
};

ColorIcon.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  selected: PropTypes.bool,
  aboveEditor: PropTypes.bool,
  selectedInPopover: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ColorIcon;

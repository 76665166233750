import { Box, MenuItem } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const OptionStyled = styled(MenuItem)`
  background-color: ${(props) =>
    props.selected ? selectedTheme.colors.primaryPurple : "white"} !important;
  color: ${(props) =>
    props.selected ? "white" : selectedTheme.colors.selectOptionTextColor};
  margin: 2px 9px;
  border-radius: 4px;
  &:hover {
    background-color: ${selectedTheme.colors.primaryPurple} !important;
    color: white;
  }
`;
export const OptionIcon = styled(Box)``;

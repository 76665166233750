import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonChatColumnHeaderContainer,
  SkeletonChatColumnHeaderLine,
  SkeletonChatColumnRow,
  SkeletonChatColumnRowLineOne,
  SkeletonChatColumnRowLineSecond,
} from "./SkeletonChatColumnHeader.styled";

const SkeletonChatColumnHeader = () => {
  return (
    <SkeletonChatColumnHeaderContainer>
      <SkeletonChatColumnHeaderLine />
      <SkeletonChatColumnRow>
        <SkeletonChatColumnRowLineOne />
        <SkeletonChatColumnRowLineSecond />
      </SkeletonChatColumnRow>
    </SkeletonChatColumnHeaderContainer>
  );
};

SkeletonChatColumnHeader.propTypes = {
  children: PropTypes.node,
};

export default SkeletonChatColumnHeader;

import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonProfileMiniContainer,
  SkeletonProfileMiniContent,
  SkeletonProfileMiniHeader,
  SkeletonProfileMiniImageColumn,
  SkeletonProfileMiniImage,
  SkeletonProfileMiniColumn,
  SkeletonProfileMiniFirstRow,
  SkeletonProfileMiniSecondRow,
  SkeletonProfileMiniThirdRow,
  SkeletonProfileMiniImageSmall,
} from "./SkeletonProfileMini.styled";

const SkeletonProfileMini = () => {
  return (
    <SkeletonProfileMiniContainer>
      <SkeletonProfileMiniHeader />
      <SkeletonProfileMiniContent>
        <SkeletonProfileMiniImageColumn>
          <SkeletonProfileMiniImage />
          <SkeletonProfileMiniColumn>
            <SkeletonProfileMiniFirstRow />
            <SkeletonProfileMiniSecondRow />
            <SkeletonProfileMiniThirdRow />
          </SkeletonProfileMiniColumn>
        </SkeletonProfileMiniImageColumn>
        <SkeletonProfileMiniImageSmall />
      </SkeletonProfileMiniContent>
    </SkeletonProfileMiniContainer>
  );
};

SkeletonProfileMini.propTypes = {
  animationStage: PropTypes.number,
};

export default SkeletonProfileMini;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";
import { AddOfferButtonContainer } from "./AddOfferButton.styled";

const AddOfferButton = (props) => {
  const { t } = useTranslation();
  return (
    <AddOfferButtonContainer
      type="submit"
      variant="contained"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryYellow}
      textcolor={selectedTheme.colors.yellowButtonTextColor}
      onClick={props.onClick}
    >
      {t("header.addOffer")}
    </AddOfferButtonContainer>
  );
};

AddOfferButton.propTypes = {
  onClick: PropTypes.func,
};

export default AddOfferButton;

import { Box } from "@mui/material";
import styled from "styled-components";
import Paging from "../../Paging/Paging";
import HorizontalScroller from "../../Scroller/HorizontalScroller";

export const ProfileOffersContainer = styled(Box)`
  width: ${(props) => !props.isAdmin && "100%"};
  box-sizing: border-box;
  padding: 0 50px;
  margin-top: 34px;
  position: relative;
  @media (max-width: 1200px) {
    padding: 0 36px 0 0;
  }
  @media (max-width: 600px) {
    padding: 0;
    margin: 34px ${(props) => (props.isAdmin ? "18px" : "0")};
    margin-bottom: ${(props) => !props.isAdmin && "0"};
  }
`;
export const OffersContainer = styled(Box)`
  margin-top: 30px;
`;
export const OffersScroller = styled(HorizontalScroller)`
  ${(props) => props.noOffers && `width: 100%;`}
  margin-left: 0;
  & div {
    margin-left: 0;
    margin-right: 0;
    gap: 18px;
  }
`;
export const SkeletonContainer = styled(Box)`
  display: flex;
  width: calc(100vw - 36px);
  max-width: calc(100vw - 36px);
  overflow: hidden;
`;
export const ProfilePaging = styled(Paging)`
  position: static;
  margin-bottom: 9px;
`

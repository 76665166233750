import requesterStatus from "../../../constants/requesterStatus"
import { EXCHANGE_SET, REQUESTER_SET } from "../../actions/exchange/exchangeActionConstants"
import createReducer from "../../utils/createReducer"

const initialState = {
    exchange: {},
    requester: requesterStatus.NOONE,
}

export default createReducer(
    {
        [EXCHANGE_SET]: setExchange,
        [REQUESTER_SET]: setRequester,
    },
    initialState
)

function setExchange(state, action) {
    return {
        ...state,
        exchange: action.payload
    }
}
function setRequester(state, action) {
    return {
        ...state,
        requester: action.payload
    }
}
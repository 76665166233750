import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";

export const MarkButtonContainer = styled(Box)`
  display: inline;
  cursor: pointer;
`;
export const MarkButtonIcon = styled(Box)`
  font-size: 16px;
  font-family: "Source Code Pro";
  ${(props) =>
    props.format === "bold"
      ? `font-weight: bold;`
      : props.format === "italic"
      ? "font-style: italic;"
      : props.format === "underline"
      ? `text-decoration: underline;`
      : ""}
  color: ${selectedTheme.colors.primaryGrayText};
  line-height: 20px;
  ${(props) =>
    props.active &&
    css`
      color: ${selectedTheme.colors.primaryText};
    `}
`;

import React, { useEffect, useState } from "react";
import {
  DropdownListContainer,
  DropdownHeader,
  DropdownIcon,
  DropdownTitle,
  ListContainer,
  ToggleIconClosed,
  ToggleIconOpened,
  DropdownOptions,
  ToggleContainer,
} from "./DropdownList.styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DropdownList = (props) => {
  const [listShown, setListShown] = useState(props.defaultOpen);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.open !== null || props.open !== undefined)
      setListShown(props.open);
  }, [props.open]);

  const handleShow = () => {
    if (props.setIsOpened) props.setIsOpened(!listShown);
    if (!props.disabled) setListShown((prevState) => !prevState);
    if (!(props.open !== null || props.open !== undefined))
      setListShown((prevState) => !prevState);
  };

  return (
    <DropdownListContainer fullwidth={props.fullWidth ? 1 : 0}>
      <DropdownHeader>
        {props.dropdownIcon && (
          <DropdownIcon
            aria-label={t("labels.dropdownIcon")}
            onClick={!props.disabled ? () => handleShow() : () => {}}
            disabled={props.disabled}
          >
            {props.dropdownIcon}
          </DropdownIcon>
        )}
        <DropdownTitle
          onClick={!props.disabled ? () => handleShow() : () => {}}
          textcolor={props.textcolor}
          disabled={props.disabled}
        >
          {props.title}
        </DropdownTitle>
        {(
          props.open !== null && props.open !== undefined
            ? props.open
            : listShown
        ) ? (
          <ToggleIconOpened
            aria-label={t("labels.dropdownClose")}
            style={props.toggleIconStyles}
            onClick={!props.disabled ? () => handleShow() : () => {}}
          >
            {props.toggleIconOpened}
          </ToggleIconOpened>
        ) : (
          <ToggleIconClosed
            aria-label={t("labels.dropdownOpen")}
            style={props.toggleIconStyles}
            onClick={!props.disabled ? () => handleShow() : () => {}}
            disabled={props.disabled}
          >
            {props.toggleIconClosed}
          </ToggleIconClosed>
        )}
      </DropdownHeader>
      <ToggleContainer
        shouldShow={
          props.open !== null && props.open !== undefined
            ? props.open
            : listShown
        }
      >
        <DropdownOptions>{props.headerOptions}</DropdownOptions>
        <ListContainer>{props.children}</ListContainer>
      </ToggleContainer>
    </DropdownListContainer>
  );
};
export default DropdownList;
DropdownList.propTypes = {
  title: PropTypes.string,
  dropdownIcon: PropTypes.node,
  toggleIconOpened: PropTypes.node,
  toggleIconClosed: PropTypes.node,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  toggleIconStyles: PropTypes.any,
  headerOptions: PropTypes.node,
  textcolor: PropTypes.string,
  setIsOpened: PropTypes.func,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};
DropdownList.defaultProps = {
  fullWidth: false,
  defaultOpen: false,
};

import React from "react";
import PropTypes from "prop-types";
import {
  SkeletonProfileCardBigCircle,
  SkeletonProfileCardCircleLineOne,
  SkeletonProfileCardCircleLines,
  SkeletonProfileCardCircleLineSecond,
  SkeletonProfileCardContainer,
  SkeletonProfileCardContent,
  SkeletonProfileCardFooter,
  SkeletonProfileCardFooterLineForth,
  SkeletonProfileCardFooterLineOne,
  SkeletonProfileCardFooterLineSecond,
  SkeletonProfileCardFooterLineThird,
  SkeletonProfileCardGroup,
  SkeletonProfileCardHeader,
  SkeletonProfileCardLeftPart,
  SkeletonProfileCardLine,
  SkeletonProfileCardLineSecond,
  SkeletonProfileCardLineThird,
  SkeletonProfileCardLowerPart,
  SkeletonProfileCardSmallCircle,
  SkeletonProfileCardUpperPart,
} from "./SkeletonProfileCard.styled";

const SkeletonProfileCard = () => {
  return (
    <SkeletonProfileCardContainer>
      <SkeletonProfileCardHeader />
      <SkeletonProfileCardContent>
        <SkeletonProfileCardUpperPart>
          <SkeletonProfileCardLeftPart>
            <SkeletonProfileCardBigCircle />
            <SkeletonProfileCardCircleLines>
              <SkeletonProfileCardCircleLineOne />
              <SkeletonProfileCardCircleLineSecond />
            </SkeletonProfileCardCircleLines>
          </SkeletonProfileCardLeftPart>
          <SkeletonProfileCardSmallCircle />
        </SkeletonProfileCardUpperPart>
        <SkeletonProfileCardLowerPart>
          <SkeletonProfileCardLine />
          <SkeletonProfileCardLineSecond />
          <SkeletonProfileCardLineThird />
        </SkeletonProfileCardLowerPart>
        <SkeletonProfileCardFooter>
          <SkeletonProfileCardGroup>
            <SkeletonProfileCardFooterLineOne />
            <SkeletonProfileCardFooterLineSecond />
          </SkeletonProfileCardGroup>
          <SkeletonProfileCardGroup>
            <SkeletonProfileCardFooterLineThird />
            <SkeletonProfileCardFooterLineForth />
          </SkeletonProfileCardGroup>
        </SkeletonProfileCardFooter>
      </SkeletonProfileCardContent>
    </SkeletonProfileCardContainer>
  );
};

SkeletonProfileCard.propTypes = {
  children: PropTypes.node,
};

export default SkeletonProfileCard;

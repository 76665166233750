import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ABOUT_PAGE } from "../../../../constants/pages";
import { GrayButton } from "../MyProfile.styled";
import { PopoverButton } from "../../HeaderPopover/HeaderPopover.styled";
import { InfoIcon } from "./AboutButton.styled";
import { useHistory } from "react-router-dom";
import scrollConstants from "../../../../constants/scrollConstants";
import { routeMatches } from "../../../../util/helpers/routeHelpers";

const AboutButton = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const seeAbout = () => {
    if (!routeMatches(ABOUT_PAGE)) {
      history.push({
        pathname: ABOUT_PAGE,
        state: {
          navigation: scrollConstants.about.aboutPage,
          clicked: true,
        },
      });
    } else {
      history.replace({
        state: {
          clicked: true,
          navigation: scrollConstants.about.aboutPage,
        },
      });
    }
    props.closePopover();
  };
  return (
    <GrayButton>
      <PopoverButton
        sx={{
          mr: 2,
          mb: 2,
        }}
        variant="text"
        endIcon={<InfoIcon />}
        onClick={seeAbout}
      >
        {t("header.about")}
      </PopoverButton>
    </GrayButton>
  );
};

AboutButton.propTypes = {
  children: PropTypes.node,
  closePopover: PropTypes.func,
};

export default AboutButton;

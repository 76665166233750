import { Box } from "@mui/material";
import styled from "styled-components";
import { BackgroundTransition } from "../../../Styles/globalStyleComponents";

export const SkeletonChatColumnHeaderContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 41px;
`
export const SkeletonChatColumnHeaderLine = styled(BackgroundTransition)`
    width: 209px;
    height: 18px;
`
export const SkeletonChatColumnRow = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const SkeletonChatColumnRowLineOne = styled(BackgroundTransition)`
    width: 72px;
    height: 18px;
    position: relative;
    top: 5.5px;
`
export const SkeletonChatColumnRowLineSecond = styled(BackgroundTransition)`
    width: 137px;
    height: 29px;
`
import { all, takeLatest, call, put } from "@redux-saga/core/effects";
import { attemptIncreaseCounter } from "../../request/counterRequest";
import { COUNTER_INCREASE } from "../actions/counter/counterActionConstants";
import {
  increaseCounterError,
  increaseCounterSuccess,
} from "../actions/counter/counterActions";

function* increaseCounter(payload) {
  try {
    yield call(attemptIncreaseCounter, payload.payload);
    yield put(increaseCounterSuccess());
  } catch (e) {
    yield put(increaseCounterError());
    console.dir(e);
  }
}

export default function* counterSaga() {
  yield all([takeLatest(COUNTER_INCREASE, increaseCounter)]);
}

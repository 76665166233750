import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../../themes";
import { CancelButtonContainer } from "./CancelButton.styled";
import { useTranslation } from "react-i18next";

const CancelButton = (props) => {
  const { t } = useTranslation();
  return (
    <CancelButtonContainer
      variant={props.pin ? "outlined" : "contained"}
      height="48px"
      width="180px"
      fullWidth
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor={props.pin ? selectedTheme.colors.primaryPurple : "white"}
      onClick={props.onClick}
    >
      {t("deleteOffer.cancel")}
    </CancelButtonContainer>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func,
  pin: PropTypes.bool,
};

export default CancelButton;

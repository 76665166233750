import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import AboutHeader from "./AboutHeader/AboutHeader";
import AboutSection from "./AboutSection/AboutSection";
import { useTranslation } from "react-i18next";
import SectionImage1 from "../../assets/images/about/about-1.png";
import SectionImage2 from "../../assets/images/about/about-2.png";
// import CheckOffersButton from "./CheckOffersButton/CheckOffersButton";
import { AboutComponentContainer } from "./AboutComponent.styled";
// import useIsMobile from "../../hooks/useIsMobile";

const AboutComponent = forwardRef((props, ref) => {
  // const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  return (
    <AboutComponentContainer ref={ref}>
      <AboutHeader />
      <AboutSection
        // title={t("about.history.title")}
        text={t("about.history.text")}
        image={SectionImage1}
      />
      <AboutSection
        // title={t("about.vision.title")}
        text={t("about.vision.text")}
        image={SectionImage2}
        reverse
      />
      {/* <CheckOffersButton /> */}
    </AboutComponentContainer>
  );
});

AboutComponent.displayName = "AboutComponent";

AboutComponent.propTypes = {
  children: PropTypes.node,
};

export default AboutComponent;

import React from "react";
import PropTypes from "prop-types";
import {
  ProfileCardContainer,
  ProfileCardWrapper,
  ProfileInfoContainer,
  CheckButton,
} from "./BigProfileCard.styled";
import ProfileMainInfo from "../ProfileMainInfo/ProfileMainInfo";
import ProfileContact from "../ProfileContact/ProfileContact";
import selectedTheme from "../../../../themes";
import { useTranslation } from "react-i18next";
import history from "../../../../store/utils/history";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import { ADMIN_SINGLE_USER_PAGE } from "../../../../constants/pages";
import ProfileControl from "../ProfileControl/ProfileControl";

const BigProfileCard = (props) => {
  const { t } = useTranslation();
  const goToUser = () => {
    history.push(
      replaceInRoute(ADMIN_SINGLE_USER_PAGE, {
        profileId: props.profile?._id,
      })
    );
  };
  return (
    <ProfileCardContainer halfwidth={props.halfwidth} onClick={goToUser}>
      <ProfileCardWrapper variant="outlined">
        {/* Profile Control (edit, remove, block, unblock)  */}
        <ProfileControl profile={props.profile} isAdmin bigProfileCard />

        <ProfileInfoContainer>
          {/* Profile Main Info  */}
          <ProfileMainInfo profile={props.profile} bigProfileCard isAdmin />
          {/* Profile Contact */}
          <ProfileContact profile={props.profile} bigProfileCard isAdmin />
        </ProfileInfoContainer>
        <CheckButton
          halfwidth={props.halfwidth}
          variant={"outlined"}
          buttoncolor={selectedTheme.colors.primaryPurple}
          textcolor={selectedTheme.colors.primaryPurple}
          style={{ fontWeight: "600" }}
          onClick={goToUser}
        >
          {t("admin.users.checkProfile")}
        </CheckButton>
      </ProfileCardWrapper>
    </ProfileCardContainer>
  );
};

BigProfileCard.propTypes = {
  profile: PropTypes.any,
  halfwidth: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default BigProfileCard;

import styled, { css } from "styled-components";
import { Grid, Typography } from "@mui/material";
import selectedTheme from "../../../../themes";
import { ReactComponent as Pocket } from "../../../../assets/images/svg/pocket.svg";

export const ProfileMainInfoContainer = styled(Grid)`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: ${(props) =>
      props.bigProfileCard ? `column-reverse` : `row`};
    gap: 18px;
    max-width: 108px;
    ${(props) =>
      props.isAdmin &&
      !props.bigProfileCard &&
      css`
        align-items: center;
        position: relative;
        top: 36px;
        max-width: none;
      `}
  }
`;
export const AvatarImageContainer = styled(Grid)`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const AvatarImage = styled.img`
  /* border: 1px solid black; */
  min-height: ${(props) => (props.isAdmin ? `108px` : `144px`)};
  min-width: ${(props) => (props.isAdmin ? `108px` : `144px`)};
  width: ${(props) => (props.isAdmin ? `108px` : `144px`)};
  height: ${(props) => (props.isAdmin ? `108px` : `144px`)};
  border-radius: 100%;
  @media (max-width: 600px) {
    min-height: ${(props) => (props.isAdmin ? "108px" : "90px")};
    min-width: ${(props) => (props.isAdmin ? "108px" : "90px")};
    width: ${(props) => (props.isAdmin ? "108px" : "90px")};
    height: ${(props) => (props.isAdmin ? "108px" : "90px")};
  }
`;

export const BlockedProfileText = styled(Typography)`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.blockedColor};
`;

export const ProfileMainInfoGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 16px;
  @media (max-width: 600px) {
    margin-left: 0;
    ${(props) =>
      props.bigProfileCard &&
      css`
        width: calc(100vw - 196px);
      `}
  }
`;
export const ProfileName = styled(Typography)`
  color: ${(props) =>
    props.isBlocked
      ? selectedTheme.colors.primaryPurple
      : props.isMyProfile
      ? selectedTheme.colors.primaryYellow
      : selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 24px;
  min-width: 200px;
  font-family: ${selectedTheme.fonts.textFont};
  margin-bottom: 5px;
  cursor: ${(props) => props.isAdmin && `pointer`};
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    ${(props) =>
      props.bigProfileCard &&
      css`
        max-width: calc(100vw - 200px);
        min-width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    ${(props) =>
      props.isAdmin &&
      !props.bigProfileCard &&
      css`
        max-width: calc(100vw - 200px);
        min-width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        max-height: 48px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      `}
  }
`;
export const ProfilePIBContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 5px;
`;
export const PocketIcon = styled(Pocket)`
  width: 22px;
  height: 22px;
  position: relative;
  left: -5px;
  top: 2px;
  & path {
    stroke: #b4b4b4;
  }
  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
  }
`;
export const ProfilePIB = styled(Typography)`
  color: ${(props) =>
    props.isMyProfile && !props.isBlocked
      ? "white"
      : selectedTheme.colors.primaryDarkText};
  /* color: ${selectedTheme.colors.primaryDarkText}; */
  margin-top: 0.18rem;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 400;
  padding-top: 1px;
  white-space: nowrap;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const VerifiedUserContainer = styled.span`
  margin-left: 9px;
`;

import React from "react";
import PropTypes from "prop-types";
import SearchField from "../../../TextFields/SearchField/SearchField";
import { useTranslation } from "react-i18next";

const OffersSearchField = (props) => {
  const offers = props?.offers;
  const { t } = useTranslation();
  if (props?.myOffers || props?.isAdmin)
    return (
      <SearchField
        searchMyOffers={offers?.search?.searchOffers}
        handleSearch={offers?.applyFilters}
        isAdmin={props?.isAdmin}
        offers={offers}
        isUsers={props.isUsers}
        placeholder={
          props.isUsers
            ? t("admin.users.searchPlaceholder")
            : t("header.searchOffers")
        }
      />
    );
  return <></>;
};

OffersSearchField.propTypes = {
  offers: PropTypes.shape({
    apply: PropTypes.func,
    search: PropTypes.shape({
      searchOffers: PropTypes.func,
    }),
  }),
  isUsers: PropTypes.bool,
  isAdmin: PropTypes.bool,
  myOffers: PropTypes.bool,
};
OffersSearchField.defaultProps = {
  offers: {
    apply: () => {},
    search: {
      searchOffers: () => {},
    },
  },
  isUsers: false,
  isAdmin: false,
  myOffers: false,
};

export default OffersSearchField;

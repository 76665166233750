import { Box, FormLabel } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const LabelContainer = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: ${(props) => props.maxWidth};
`;

export const LeftLabel = styled(FormLabel)`
  font-family: ${selectedTheme.fonts.textFont};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  flex: 1;
  cursor: pointer;
  color: ${selectedTheme.colors.primaryText};
`;
export const RightLabel = styled(FormLabel)`
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  cursor: pointer;
  color: ${selectedTheme.colors.primaryText};
  font-family: ${selectedTheme.fonts.textFont};
`;

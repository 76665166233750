import { Box } from "@mui/material";
import styled from "styled-components";

export const PrivacyPolicyContainer = styled(Box)`
  margin: 72px;

  @media (max-width: 834px) {
    margin: 45px;
  }

  @media (max-width: 600px) {
    margin: 36px 36px 69px 36px;
  }
`;

import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  KEY_NAME,
  KEY_PAGE,
  KEY_SIZE,
  KEY_SORT_DATE,
} from "../../constants/queryStringConstants";
import { sortUsersEnum } from "../../enums/sortEnum";
import {
  attemptEditProfile,
  attemptEditProfileAsAdmin,
  attemptFetchAllProfiles,
  attemptFetchAllProfilesAsAdmin,
  attemptFetchProfile,
} from "../../request/profileRequest";
import {
  PROFILE_FETCH,
  PROFILE_MINE_FETCH,
  PROFILE_EDIT,
  PROFILE_ALL_FETCH,
  PROFILE_EDIT_ADMIN,
  PROFILE_ALL_ADMIN_FETCH,
} from "../actions/profile/profileActionConstants";
import {
  editProfileAsAdminError,
  editProfileAsAdminSuccess,
  editProfileError,
  editProfileSuccess,
  fetchAllProfilesAsAdminError,
  fetchAllProfilesAsAdminSuccess,
  fetchAllProfilesError,
  fetchAllProfilesSuccess,
  fetchErrorProfile,
  fetcHMineProfileError,
  fetchMineProfileSuccess,
  fetchProfileSuccess,
  setAllProfiles,
  //   editProfile,
  setMineProfile,
  setProfile,
} from "../actions/profile/profileActions";
import { setQueryStringRedux } from "../actions/queryString/queryStringActions";
import { selectUserId } from "../selectors/loginSelectors";
import { selectQueryString } from "../selectors/queryStringSelectors";

function* fetchProfile(payload) {
  try {
    const data = yield call(attemptFetchProfile, payload.payload);
    if (data) yield put(setProfile(data.data));
    yield put(fetchProfileSuccess());
  } catch (e) {
    yield put(fetchErrorProfile());
    console.dir(e);
  }
}

function* fetchMineProfile() {
  try {
    const userId = yield select(selectUserId);
    const data = yield call(attemptFetchProfile, userId);
    if (data) yield put(setMineProfile(data.data));
    yield put(fetchMineProfileSuccess());
  } catch (e) {
    yield put(fetcHMineProfileError());
    console.dir(e);
  }
}
function* fetchAllProfiles() {
  try {
    const data = yield call(attemptFetchAllProfiles);
    if (data) yield put(setAllProfiles(data.data));
    yield put(fetchAllProfilesSuccess());
  } catch (e) {
    yield put(fetchAllProfilesError());
    console.dir(e);
  }
}

function* fetchAllProfilesAsAdmin({ payload }) {
  try {
    yield call(console.log, payload);
    let queryString;
    if (payload?.currentPage) {
      const queryObject = new URLSearchParams();
      queryObject.set(KEY_SIZE, "10");
      if (payload?.searchValue && payload?.searchValue?.length !== 0)
        queryObject.set(KEY_NAME, payload.searchValue);
      if (payload?.sortOption?.value === sortUsersEnum.OLD.value)
        queryObject.set(KEY_SORT_DATE, "false");
      if (payload?.sortOption?.value === sortUsersEnum.NEW.value)
        queryObject.set(KEY_SORT_DATE, "true");
      queryObject.set(KEY_PAGE, payload.currentPage);
      queryString = queryObject.toString();
      yield put(setQueryStringRedux(queryString));
    } else {
      queryString = yield select(selectQueryString);
    }

    const data = yield call(attemptFetchAllProfilesAsAdmin, queryString);
    console.log(data);
    if (data) yield put(setAllProfiles(data.data));
    yield put(fetchAllProfilesAsAdminSuccess());
  } catch (e) {
    yield put(fetchAllProfilesAsAdminError());
    console.dir(e);
  }
}

function* changeMineProfile(payload) {
  try {
    const requestBody = new FormData();
    if (typeof payload.payload.firmLogo !== "string")
      requestBody.append("file", payload.payload.firmLogo);
    requestBody.append("company[name]", payload.payload.firmName);
    // requestBody.append("company[PIB]", payload.payload.firmPIB);
    if (payload.payload.firmPhone.toString().length !== 0)
      requestBody.append(
        "company[contacts][telephone]",
        payload.payload.firmPhone
      );
    if (payload.payload.firmLocation.toString().length !== 0)
      requestBody.append(
        "company[contacts][location]",
        payload.payload.firmLocation
      );
    if (payload.payload.firmWebsite.toString().length !== 0)
      requestBody.append("company[contacts][web]", payload.payload.firmWebsite);

    let userId;
    if (payload.payload?.userId) {
      userId = payload.payload.userId;
    } else {
      userId = yield select(selectUserId);
    }
    yield call(attemptEditProfile, userId, requestBody);
    yield put(editProfileSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(editProfileError());
    console.dir(e);
  }
}

function* changeProfileAsAdmin(payload) {
  try {
    const requestBody = new FormData();
    console.log(payload);
    if (typeof payload.payload?.firmLogo !== "string")
      requestBody.append("file", payload.payload.firmLogo);
    requestBody.append("company[name]", payload.payload.firmName);
    requestBody.append("company[idNumber]", payload.payload.firmPIB);
    if (payload.payload.firmPhone.toString().length !== 0)
      requestBody.append(
        "company[contacts][telephone]",
        payload.payload.firmPhone
      );
    if (payload.payload.firmLocation.toString().length !== 0)
      requestBody.append(
        "company[contacts][location]",
        payload.payload.firmLocation
      );
    if (payload.payload.firmWebsite.toString().length !== 0)
      requestBody.append("company[contacts][web]", payload.payload.firmWebsite);

    const userId = payload.payload.userId;
    yield call(attemptEditProfileAsAdmin, userId, requestBody);
    yield put(editProfileAsAdminSuccess());
    if (payload.payload.handleApiResponseSuccess) {
      yield call(payload.payload.handleApiResponseSuccess);
    }
  } catch (e) {
    yield put(editProfileAsAdminError());
    console.dir(e);
  }
}

export default function* profileSaga() {
  yield all([
    takeLatest(PROFILE_FETCH, fetchProfile),
    takeLatest(PROFILE_MINE_FETCH, fetchMineProfile),
    takeLatest(PROFILE_EDIT, changeMineProfile),
    takeLatest(PROFILE_ALL_FETCH, fetchAllProfiles),
    takeLatest(PROFILE_ALL_ADMIN_FETCH, fetchAllProfilesAsAdmin),
    takeLatest(PROFILE_EDIT_ADMIN, changeProfileAsAdmin),
  ]);
}

import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import styled from "styled-components";
import selectedTheme from "../../themes";
import Header from "../ItemDetails/Header/Header";
import Option from "../Select/Option/Option";
import Select from "../Select/Select";

export const ChatColumnContainer = styled(Container)`
  margin-bottom: 10px;
  padding-bottom:60px;
  position: relative;
`;

export const ListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 600px) {
    gap: 18px;
    margin-top: 10px;
  }
`;

export const ListHeader = styled(Box)`
  ${(props) =>
    props.vertical &&
    `
    position: absolute;
    bottom: 15px;
  `}
`;

export const TitleSortContainer = styled(Box)`
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderSelect = styled(Select)`
  width: 210px;
  height: 35px;
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 3px;
  font-weight: 400;
  position: relative;
  left: -5px;
  & div:first-child {
    padding-left: 8px;
  }

  @media (max-width: 650px) {
    width: 144px;
    height: 30px;
    font-size: 14px;
    background-color: white;
    & fieldset {
      border: 1px solid ${selectedTheme.colors.borderNormal} !important;
    }
  }
`;
export const SelectOption = styled(Option)`
  @media (max-width: 600px) {
    height: 20px !important;
    min-height: 35px;
    margin: 2px;
  }
`;
export const HeaderBack = styled(Header)`
  @media (max-width: 600px) {
    margin-top: 0;
    position: relative;
    top: -12px;
  }
`;
export const ChatPagingText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  position: relative;
  top: 6px;
  margin-left: 36px;
  margin-right: 36px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  white-space: nowrap;
`;

import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../FirstStepCreateReview.styled";
import { CommentFieldContainer } from "./CommentField.styled";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";

const CommentField = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  return (
    <>
      <FieldLabel leftText={t("reviews.comment")} />
      <CommentFieldContainer
        fullWidth
        multiline
        minRows={4}
        value={props.formik.values.comment}
        name="comment"
        onChange={props.formik.handleChange}
        height={isMobile ? "64px" : "100px"}
      />
    </>
  );
};

CommentField.propTypes = {
  formik: PropTypes.any,
};

export default CommentField;

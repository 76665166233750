import React from "react";
import PropTypes from "prop-types";
const RichTextLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }
  if (leaf.a) {
    // prettier-ignore
    children = (
      <a href={leaf.a} target="_blank">{/*eslint-disable-line*/}
        {children}
      </a>
    );
  }
  return <span {...attributes}>{children}</span>;
};

RichTextLeaf.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  leaf: PropTypes.any,
  selectedColor: PropTypes.any,
};
export default RichTextLeaf;

import React from "react";
import PropTypes from "prop-types";
import { useSlate } from "slate-react";
import { Editor } from "slate";
import { MarkButtonContainer, MarkButtonIcon } from "./MarkButton.styled";

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <MarkButtonContainer
      active={isMarkActive(editor, format)}
      format={format}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <MarkButtonIcon format={format} active={isMarkActive(editor, format)}>
        {icon}
      </MarkButtonIcon>
    </MarkButtonContainer>
  );
};
MarkButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
};
export default MarkButton;

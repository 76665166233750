import styled from "styled-components";
import { Box, Container } from "@mui/material";
import { ReactComponent as AdminLogo } from "../../assets/images/svg/logo-vertical-admin.svg";

export const LoginPageContainer = styled(Container)`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 900px) {
    margin-top: 110px;
  }
  @media (max-height: 800px) {
    margin-top: 70px;
  }
  @media (max-width: 320px) {
    margin-top: 30px;
  }
`;
export const LogoAdmin = styled(AdminLogo)``;
export const LoginFormContainer = styled(Box)`
  width: 335px;
  height: 216px;

  @media (max-width: 320px) {
    width: 100%;
  }
`;

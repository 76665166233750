import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const SidebarNavigationContainer = styled(Box)`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
`;

export const SidebarNavigationTitle = styled(Typography)`
  font-family: "DM Sans";
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4vh;
  margin-left: 18px;
`;

export const SidebarNavigationListContainer = styled.ul``;
export const SidebarNavigationItem = styled.li`
  padding-top: 1.7vh;
  padding-bottom: 1.7vh;
  padding-left: 18px;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  display: flex;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  align-items: center;
  color: ${selectedTheme.colors.primaryPurple};
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
  background-color: #f5edff;
  font-weight: 700;
`}
  path {
    stroke: #c4c4c4;
  }

  &:hover {
    background-color: #f5edff;
    font-weight: 700;

    path {
      stroke: #feb005;
    }
  }
`;

export const SidebarNavigationItemIcon = styled(Box)`
  margin-right: 9px;
  position: relative;
  top: 2px;

  ${(props) =>
    props.active &&
    `
  path {
    stroke: #feb005;
  }
`}
`;

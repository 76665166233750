import React from "react";
import PropTypes from "prop-types";
import {
  BlockedContainer,
  BlockedIcon,
  BlockedIconContainer,
  BlockedProfileText,
  BlockedProfileTextContainer,
} from "./BlockedProfile.styled";
import { useTranslation } from "react-i18next";

const BlockedProfile = (props) => {
  const { t } = useTranslation();
  return (
    <BlockedContainer
      aboveTitle={props.aboveTitle}
      isAdmin={props.isAdmin}
      chatCard={props.chatCard}
    >
      <BlockedProfileTextContainer
        aboveTitle={props.aboveTitle}
        chatCard={props.chatCard}
      >
        <BlockedProfileText redText={props.redText}>
          {props.chatCard
            ? props.deleted
              ? t("messages.chatCardDeleted")
              : t("messages.chatCardBlocked")
            : props.shortText
            ? t("profile.blockedProfileShort")
            : t("profile.blockedProfile")}
        </BlockedProfileText>
      </BlockedProfileTextContainer>
      {!props.hideIcon && (
        <BlockedIconContainer
          aboveTitle={props.aboveTitle}
          chatCard={props.chatCard}
          
        >
          <BlockedIcon redText={props.redText} />
        </BlockedIconContainer>
      )}
    </BlockedContainer>
  );
};

BlockedProfile.propTypes = {
  aboveTitle: PropTypes.bool,
  hideIcon: PropTypes.bool,
  isAdmin: PropTypes.bool,
  redText: PropTypes.bool,
  shortText: PropTypes.bool,
  chatCard: PropTypes.bool,
  deleted: PropTypes.bool,
};

export default BlockedProfile;

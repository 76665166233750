import React from "react";
import PropTypes from "prop-types";
import { SkeletonSectionContainer } from "./SkeletonSection.styled";
import SkeletonSectionOption from "./SkeletonSectionOption/SkeletonSectionOption";

const SkeletonSection = (props) => {
  const arrayForMapping = Array.apply(null, Array(props.numberOfOptions)).map(
    () => {}
  );
  return (
    <SkeletonSectionContainer>
      {arrayForMapping.map((item, index) => (
        <SkeletonSectionOption key={index} />
      ))}
    </SkeletonSectionContainer>
  );
};

SkeletonSection.propTypes = {
  children: PropTypes.node,
  numberOfOptions: PropTypes.number,
};

export default SkeletonSection;

import createReducer from '../../utils/createReducer';
import {
  SET_USER,
  SET_USER_ACCESS_TOKEN,
  SET_USER_ERROR,
  SET_USER_REFRESH_TOKEN,
} from '../../actions/user/userActionConstants';

const initialState = {
  accessToken: {},
  refreshToken: {},
  userId: {}
};

export default createReducer(
  {
    [SET_USER]: setUser,
    [SET_USER_ERROR]: setUserError,
    [SET_USER_ACCESS_TOKEN]: setUserAccessToken,
    [SET_USER_REFRESH_TOKEN]: setUserRefreshToken
  },
  initialState,
);

function setUser(state, action) {
  return {
    ...state,
    userId: action.payload,
  };
}

function setUserError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
function setUserAccessToken(state, action) {
  return {
    ...state,
    accessToken: action.payload
  }
}
function setUserRefreshToken(state, action) {
  return {
    ...state,
    refreshToken: action.payload
  }
}

import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const OFFERS_SCOPE = "OFFERS_SCOPE";
export const OFFERS_FETCH = createFetchType(OFFERS_SCOPE);
export const OFFERS_SUCCESS = createSuccessType(OFFERS_SCOPE);
export const OFFERS_ERROR = createErrorType(OFFERS_SCOPE);
export const OFFERS_CLEAR = createClearType(OFFERS_SCOPE);

export const OFFERS_FEATURED_SCOPE = "OFFERS_FEATURED_SCOPE";
export const OFFERS_FEATURED_FETCH = createFetchType(OFFERS_FEATURED_SCOPE);
export const OFFERS_FEATURED_SUCCESS = createSuccessType(OFFERS_FEATURED_SCOPE);
export const OFFERS_FEATURED_ERROR = createErrorType(OFFERS_FEATURED_SCOPE);
export const OFFERS_FEATURED_CLEAR = createClearType(OFFERS_FEATURED_SCOPE);

export const OFFERS_ALL_SCOPE = "OFFERS_ALL_SCOPE";
export const OFFERS_ALL_FETCH = createFetchType(OFFERS_ALL_SCOPE);
export const OFFERS_ALL_SUCCESS = createSuccessType(OFFERS_ALL_SCOPE);
export const OFFERS_ALL_ERROR = createErrorType(OFFERS_ALL_SCOPE);

export const OFFERS_MORE_SCOPE = "OFFERS_MORE_SCOPE";
export const OFFERS_FETCH_MORE = createFetchType(OFFERS_MORE_SCOPE);
export const OFFERS_FETCH_MORE_SUCCESS = createSuccessType(OFFERS_MORE_SCOPE);
export const OFFERS_FETCH_MORE_ERROR = createErrorType(OFFERS_MORE_SCOPE);

export const OFFERS_MINE_SCOPE = "OFFERS_MINE_SCOPE";
export const OFFERS_MINE_FETCH = createFetchType(OFFERS_MINE_SCOPE);
export const OFFERS_MINE_FETCH_SUCCESS = createSuccessType(OFFERS_MINE_SCOPE);
export const OFFERS_MINE_FETCH_ERROR = createErrorType(OFFERS_MINE_SCOPE);

export const OFFERS_MINE_HEADER_SCOPE = "OFFERS_MINE_HEADER_SCOPE";
export const OFFERS_MINE_HEADER_FETCH = createFetchType(OFFERS_MINE_HEADER_SCOPE);
export const OFFERS_MINE_HEADER_FETCH_SUCCESS =
  createSuccessType(OFFERS_MINE_HEADER_SCOPE);
export const OFFERS_MINE_HEADER_FETCH_ERROR =
  createErrorType(OFFERS_MINE_HEADER_SCOPE);

export const OFFERS_PROFILE_SCOPE = "OFFERS_PROFILE_SCOPE";
export const OFFERS_PROFILE_FETCH = createFetchType(OFFERS_PROFILE_SCOPE);
export const OFFERS_PROFILE_SUCCESS = createSuccessType(OFFERS_PROFILE_SCOPE);
export const OFFERS_PROFILE_ERROR = createErrorType(OFFERS_PROFILE_SCOPE);

export const ONE_OFFER_SCOPE = "ONE_OFFER_SCOPE";
export const ONE_OFFER_FETCH = createFetchType(ONE_OFFER_SCOPE);
export const ONE_OFFER_SUCCESS = createSuccessType(ONE_OFFER_SCOPE);
export const ONE_OFFER_ERROR = createErrorType(ONE_OFFER_SCOPE);

export const OFFERS_PINNED_SET = createSetType("OFFERS_PINNED_SET");
export const OFFERS_PINNED_ADD = createSetType("OFFERS_PINNED_ADD");
export const OFFERS_SET = createSetType("OFFERS_SET");
export const OFFERS_HEADER_SET = createSetType("OFFERS_HEADER_SET");
export const OFFERS_FEATURED_SET = createSetType("OFFERS_FEATURED_SET");
export const OFFER_SET = createSetType("OFFER_SET");
export const OFFERS_ADD = createSetType("OFFERS_ADD");
export const OFFERS_NO_MORE = createSetType("OFFERS_NO_MORE");
export const OFFERS_SET_TOTAL = createSetType("OFFERS_SET_TOTAL");
export const OFFERS_PROFILE_SET = createSetType("OFFERS_PROFILE_SET");
export const OFFERS_PROFILE_ADD = createSetType("OFFERS_PROFILE_ADD");
export const OFFERS_MINE_SET = createSetType("OFFERS_MY_ADD");
export const OFFER_INDEX_SET = createSetType("OFFER_INDEX_SET");
export const OFFER_INDEX_CLEAR = createClearType("OFFER_INDEX_CLEAR");
export const OFFER_PAGE_SET = createSetType("OFFER_PAGE_SET");
export const OFFER_FEATURED_PAGE_SET = createSetType("OFFER_FEATURED_PAGE_SET");
export const OFFER_SELECTED_CLEAR = createClearType("OFFER_SELECTED_CLEAR");

export const OFFER_ADD_SCOPE = "OFFER_ADD_SCOPE";
export const OFFER_ADD = createFetchType(OFFER_ADD_SCOPE);
export const OFFER_ADD_SUCCESS = createSuccessType(OFFER_ADD_SCOPE);
export const OFFER_ADD_ERROR = createErrorType(OFFER_ADD_SCOPE);

export const OFFER_PIN_SCOPE = "OFFER_PIN_SCOPE";
export const OFFER_PIN = createFetchType(OFFER_PIN_SCOPE);
export const OFFER_PIN_SUCCESS = createSuccessType(OFFER_PIN_SCOPE);
export const OFFER_PIN_ERROR = createErrorType(OFFER_PIN_SCOPE);

export const OFFER_REMOVE_SCOPE = "OFFER_REMOVE_SCOPE";
export const OFFER_REMOVE = createFetchType(OFFER_REMOVE_SCOPE);
export const OFFER_REMOVE_SUCCESS = createSuccessType(OFFER_REMOVE_SCOPE);
export const OFFER_REMOVE_ERROR = createErrorType(OFFER_REMOVE_SCOPE);

export const OFFER_EDIT_SCOPE = "OFFER_EDIT_SCOPE";
export const OFFER_EDIT = createFetchType(OFFER_EDIT_SCOPE);
export const OFFER_EDIT_SUCCESS = createSuccessType(OFFER_EDIT_SCOPE);
export const OFFER_EDIT_ERROR = createErrorType(OFFER_EDIT_SCOPE);

import {
  CLOSE_MODAL,
  SET_MODAL_TYPE,
  TOGGLE_CREATE_CATEGORY,
  TOGGLE_CREATE_OFFER,
  TOGGLE_CREATE_REVIEW,
  TOGGLE_DELETE_CATEGORY,
  TOGGLE_DELETE_OFFER,
  TOGGLE_DELETE_REVIEW,
  TOGGLE_EDIT_CATEOGRY,
  TOGGLE_EDIT_OFFER,
  TOGGLE_EDIT_PROFILE,
  TOGGLE_CREATE_PAYMENT,
  TOGGLE_EDIT_PAYMENT,
} from "../../actions/modal/modalActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  createOfferModal: false,
  editOfferModal: false,
  deleteOfferModal: false,
  createCategoryModal: false,
  editCategoryModal: false,
  deleteCategoryModal: false,
  createReviewModal: false,
  editProfile: false,
  toggleDeleteReview: false,
  createPaymentModal: false,
  editPaymentModal: false,
  props: {},
};

export default createReducer(
  {
    [TOGGLE_CREATE_OFFER]: toggleCreateOffer,
    [TOGGLE_EDIT_OFFER]: toggleEditOffer,
    [TOGGLE_DELETE_OFFER]: toggleDeleteOffer,
    [TOGGLE_CREATE_CATEGORY]: toggleCreateCategory,
    [TOGGLE_EDIT_CATEOGRY]: toggleEditCategory,
    [TOGGLE_DELETE_CATEGORY]: toggleDeleteCategory,
    [TOGGLE_CREATE_REVIEW]: toggleCreateReview,
    [TOGGLE_DELETE_REVIEW]: toggleDeleteReview,
    [TOGGLE_EDIT_PROFILE]: toggleEditProfile,
    [TOGGLE_CREATE_PAYMENT]: toggleCreatePayment,
    [TOGGLE_EDIT_PAYMENT]: toggleEditPayment,
    [SET_MODAL_TYPE]: setModalType,
    [CLOSE_MODAL]: closeModal,
  },
  initialState
);
function toggleCreateOffer(state, { payload }) {
  return {
    ...state,
    createOfferModal: !state.createOfferModal,
    props: payload,
  };
}
function toggleEditOffer(state, { payload }) {
  return {
    ...state,
    editOfferModal: !state.editOfferModal,
    props: payload,
  };
}
function toggleDeleteOffer(state, { payload }) {
  return {
    ...state,
    deleteOfferModal: !state.deleteOfferModal,
    props: payload,
  };
}
function toggleCreateCategory(state, { payload }) {
  return {
    ...state,
    createCategoryModal: !state.createCategoryModal,
    props: payload,
  };
}
function toggleEditCategory(state, { payload }) {
  return {
    ...state,
    editCategoryModal: !state.editCategoryModal,
    props: payload,
  };
}
function toggleDeleteCategory(state, { payload }) {
  return {
    ...state,
    deleteCategoryModal: !state.deleteCategoryModal,
    props: payload,
  };
}
function toggleCreateReview(state, { payload }) {
  return {
    ...state,
    createReviewModal: !state.createReviewModal,
    props: payload,
  };
}
function toggleDeleteReview(state, { payload }) {
  return {
    ...state,
    deleteReviewModal: !state.deleteReviewModal,
    props: payload,
  };
}
function toggleEditProfile(state, { payload }) {
  return {
    ...state,
    editProfileModal: !state.editProfileModal,
    props: payload,
  };
}
function toggleCreatePayment(state, { payload }) {
  return {
    ...state,
    createPaymentModal: !state.createPaymentModal,
    props: payload,
  };
}
function toggleEditPayment(state, { payload }) {
  return {
    ...state,
    editPaymentModal: !state.editPaymentModal,
    props: payload,
  };
}
function setModalType(state, { payload }) {
  return {
    ...state,
    type: payload,
  };
}
function closeModal() {
  return initialState;
}

import React from 'react';
import { IconContainer, IconStyled } from './Icon.styled';
import PropTypes from "prop-types";


export const Icon = (props) => {
  return (
    <IconContainer style={props.containerStyle} className={props.className} onClick={props.onClick} component={props.component}>
        <IconStyled sx={props.style} color={props.color} size={props.size} fontSize={props.iconsize}>
            {props.children}
        </IconStyled>
    </IconContainer>
  )
}

Icon.propTypes = {
    children: PropTypes.node,
    containerStyle: PropTypes.any,
    style: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.string,
    iconsize: PropTypes.string,
    className: PropTypes.any,
    onClick: PropTypes.func,
    component: PropTypes.any,
}

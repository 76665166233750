import io from "socket.io-client";

// export const socket = io("https://trampa-api-test.dilig.net/", {
// export const socket = io("http://localhost:3001/", {
export const socket = io(process.env.REACT_APP_BASE_API_URL, {
  transports: ["websocket"],
  reconnectionAttempts: 5,
});

export const socketInit = (userId, token) => {
  if (socket.connected) socket.disconnect();
  socket.auth = {
    userId,
    token,
  };
  socket.connect();
};

export const sendMessage = (chatId, userId, text, receiverUserId) => {
  socket.emit("private_message", {
    chatId,
    receiverUserId,
    message: {
      user: {
        _id: userId,
      },
      text,
    },
  });
};
export const acceptExchangeSocket = (
  chatId,
  userId,
  receiverUserId,
  callbackFn
) => {
  socket.emit("private_message", {
    chatId,
    receiverUserId,
    message: {
      user: {
        _id: userId,
      },
      isAcceptRequest: true,
      text: "",
    },
  });
  callbackFn();
};

export const addMessageListener = (listener) => {
  socket.on("private_message", (data) =>
    listener({
      succeed: true,
      data,
    })
  );
  socket.on("sending_message_failed", (data) =>
    listener({
      succeed: false,
      data,
    })
  );
};
export const removeMessageListener = () => {
  socket.off("private_message");
  socket.off("sending_message_failed");
};

export const KEY_SIZE = "size";
export const KEY_PAGE = "page";
export const KEY_CATEGORY = "category";
export const KEY_SUBCATEGORY = "subcategory";
export const KEY_SORTBY = "sortBy";
export const KEY_SORT_DATE = "_des_date";
export const KEY_SORT_POPULAR = "_des_popular";
export const KEY_LOCATION = "location";
export const KEY_COMPANY = "companyName";
export const KEY_NAME = "name";
export const KEY_SEARCH = "search";
export const VALUE_SORTBY_NEW = "newest";
export const VALUE_SORTBY_OLD = "oldest";
export const VALUE_SORTBY_POPULAR = "popular";
export const initialSize = "10";

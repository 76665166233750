import { Box, Typography } from "@mui/material"
import styled from "styled-components"
import selectedTheme from "../../../themes"
import {ReactComponent as Category} from "../../../assets/images/svg/category.svg";
import { Icon } from "../../Icon/Icon";
import {ReactComponent as Swaps} from "../../../assets/images/svg/refresh.svg";

export const LittleOfferCardContainer = styled(Box)`
    background-color: ${selectedTheme.colors.chatHeaderColor};
    border: 1px solid ${selectedTheme.colors.borderNormal};
    border-radius: 2px;
    min-width: 211px;
    max-width: 300px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: 600px) {
        width: 211px;
    }
`
export const OfferImage = styled.img`
    width: 54px;
    height: 54px;
    margin-top: 18px;
    margin-left: 18px;
    border-radius: 2px;
    overflow: hidden;
`
export const OfferDetails = styled(Box)`
    display: flex;
    text-align: left;
    flex-direction: column;
    margin-top: 25px;
    margin-left: 9px;
    flex-grow: 1;
`
export const OfferName = styled(Typography)`
    font-weight: 600;
    font-size: 16px;
    font-family: ${selectedTheme.fonts.textFont};
    color: ${selectedTheme.colors.primaryPurple};
`
export const OfferCategory = styled(Typography)`
    font-family: ${selectedTheme.fonts.textFont};
    font-size: 12px;
    color: ${selectedTheme.colors.primaryDarkText};
`
export const OfferCategoryIcon = styled(Category)`
    width: 12px;
    height: 12px;
    position: relative;
    top: 1.5px;
    right: 2px;
    & path {
        stroke-width: 1;
    }
`
export const OfferSwapsIconContainer = styled(Icon)`
    width: 40px;
    height: 40px;
    background-color: ${selectedTheme.colors.primaryPurple};
    border-radius: 100%;
    position: absolute;
    top: -19px;
    right: -19px;
    & span {
        width: 40px;
        height: 40px;
    }
    @media (max-width: 600px) {
        width: 32px;
        height: 32px;
        top: -15px;
        right: -15px;
    }
`
export const OfferSwapsIcon = styled(Swaps)`
    width: 18px;
    height: 18px;
    position: relative;
    top: 10px;
    @media (max-width: 600px) {
        width: 14px;
        height: 14px;
        top: 2px;
        left: -4px;
    }
`
import React from "react";
import PropTypes from "prop-types";
import {
  AlreadyFinishedExhangeMessage,
  RequestExchangeMessageButton,
  RequestExchangeMessageButtonsContainer,
  RequestExchangeMessageContainer,
  RequestExchangeMessageText,
} from "./RequestExchangeMessage.styled";
import { useTranslation } from "react-i18next";
import { acceptExchangeSocket } from "../../../../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExchange,
  selectRequester,
} from "../../../../store/selectors/exchangeSelector";
import {
  acceptExchange,
  setRequester,
} from "../../../../store/actions/exchange/exchangeActions";
import { addNewMessage } from "../../../../store/actions/chat/chatActions";
import { convertLocalDateToUTCDate } from "../../../../util/helpers/dateHelpers";
import requesterStatus from "../../../../constants/requesterStatus";
import { selectJwtToken } from "../../../../store/selectors/loginSelectors";
import { selectOffers } from "../../../../store/selectors/offersSelectors";

const RequestExchangeMessage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const exchange = useSelector(selectExchange);
  const requester = useSelector(selectRequester);
  const token = useSelector(selectJwtToken);
  const offers = useSelector(selectOffers);
  const isOfferExist = offers.filter(
    (offer) => offer._id === exchange?.offer?._id
  );

  const handleAcceptExchangeSuccess = () => {
    console.log("accept salje i prima 3 POZVANA RESPONSE FUNKCIJA");
    acceptExchangeSocket(
      props.chatId,
      props.userId,
      props.interlocutorUserId,
      token,
      () => {
        console.log("accept salje i prima 4 SOCKET FUNKCIJA");
        dispatch(
          addNewMessage({
            _id: props.chatId,
            message: {
              user: {
                _id: props.userId,
              },
              text: "",
              isAcceptRequest: true,
              _created: convertLocalDateToUTCDate(new Date()),
            },
          })
        );
        if (requester === requesterStatus.NOONE) {
          dispatch(setRequester(requesterStatus.ME));
        }
      }
    );
  };
  const handleAcceptExchange = () => {
    console.log("accept salje i prima 1 POZVANA FUNKCIJA");
    dispatch(
      acceptExchange({
        exchangeId: exchange._id,
        handleApiResponseSuccess: handleAcceptExchangeSuccess,
      })
    );
  };

  return (
    <>
      {isOfferExist.length > 0 ? (
        <RequestExchangeMessageContainer>
          <RequestExchangeMessageText>
            {t("messages.requestReceived")}
          </RequestExchangeMessageText>
          <RequestExchangeMessageButtonsContainer>
            {!props.haveIAccepted && (
              <RequestExchangeMessageButton variant="outlined" white>
                {t("messages.declineRequest")}
              </RequestExchangeMessageButton>
            )}
            <RequestExchangeMessageButton
              variant="contained"
              onClick={handleAcceptExchange}
              disabled={props.haveIAccepted}
            >
              {props.haveIAccepted
                ? t("messages.acceptedRequest")
                : t("messages.acceptRequest")}
            </RequestExchangeMessageButton>
          </RequestExchangeMessageButtonsContainer>
        </RequestExchangeMessageContainer>
      ) : (
        <AlreadyFinishedExhangeMessage>
          {t("messages.notAllowedChat")}
        </AlreadyFinishedExhangeMessage>
      )}
    </>
  );
};

RequestExchangeMessage.propTypes = {
  children: PropTypes.node,
  chatId: PropTypes.string,
  userId: PropTypes.string,
  interlocutorUserId: PropTypes.string,
  haveIAccepted: PropTypes.any,
};

export default RequestExchangeMessage;

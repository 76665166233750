import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../themes";

export const BlogDetailsContainer = styled(Box)`
  max-width: 80%;
  ${(props) =>
    props.sidebar &&
    `
    @media (max-width: 1460px) {
      max-width: 100%;
      margin-right: 50px; 
    }
   ;
  `}
`;

export const BackButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 18px;
`;

export const BackButtonText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
  cursor: pointer;
`;

export const BlogCreatorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 36px 0 4px 0;
`;

export const BlogCreator = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 500;
`;

export const BlogCreatorRole = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 14px;
  font-weight: 400;
  color: ${selectedTheme.colors.chatDateColor};
`;

export const BlogTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 60px;
  font-weight: 700;
  color: ${selectedTheme.colors.primaryPurple};
`;

export const BlogText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  font-weight: 400;
  color: ${selectedTheme.colors.headerGray};
  margin: 36px 0 27px 0;
`;

export const BlogImageContainer = styled(Box)`
  text-align: center;
  ${(props) =>
    props.sidebar &&
    `
    @media (max-width: 1460px) {
      width: 500px;
      margin: auto;
    }
    @media (max-width: 600px) {
      width: 400px;
      margin: auto;
    }
    @media (max-width: 440px) {
      width: 370px;
      margin: auto;
    }
  `}
`;

export const BlogImage = styled.img`
  ${(props) =>
    props.sidebar &&
    `
    @media (max-width: 1460px) {
      width: 100%;
    }
  `}
`;

import React from "react";
import PropTypes from "prop-types";
import SkeletonChatColumnHeader from "./SkeletonChatColumnHeader/SkeletonChatColumnHeader";
import SkeletonChatCard from "../../Cards/ChatCard/SkeletonChatCard/SkeletonChatCard";
import { SkeletonChatCardsContainer, SkeletonChatColumnContainer } from "./SkeletonChatColumn.styled";

const numberOfSkeletonChatCards = 7;

const SkeletonChatColumn = () => {
  const arrayForMapping = Array.apply(
    null,
    Array(numberOfSkeletonChatCards)
  ).map(() => {});
  return (
    <SkeletonChatColumnContainer>
      <SkeletonChatColumnHeader />
      <SkeletonChatCardsContainer>
        {arrayForMapping.map((item, index) => (
          <SkeletonChatCard key={index} />
        ))}
      </SkeletonChatCardsContainer>
    </SkeletonChatColumnContainer>
  );
};

SkeletonChatColumn.propTypes = {
  children: PropTypes.node,
};

export default SkeletonChatColumn;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  EndIcon,
  SearchIcon,
  SearchInputContainer,
} from "./SearchInput.styled";
import { forwardRef } from "react";
// import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { routeMatches } from "../../../util/helpers/routeHelpers";
import {
  ABOUT_PAGE,
  BASE_PAGE,
  HOME_PAGE,
  MARKETPLACE_PAGE,
} from "../../../constants/pages";
import { debounceHelper } from "../../../util/helpers/debounceHelper";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";

const SearchInput = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useIsLoggedIn();
  const handleSearch = () => {
    if (
      routeMatches(HOME_PAGE) ||
      routeMatches(BASE_PAGE) ||
      routeMatches(MARKETPLACE_PAGE)
    ) {
      console.log("uslo unutra");
      debounceHelper(() => props.handleSearch(ref.current.value), 500);
    }
  };
  console.log(routeMatches(HOME_PAGE));
  const handleManualSearch = () => {
    debounceHelper(() => {}, 500);
    props.handleSearch(ref.current.value);
  };
  const listener = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        handleManualSearch();
      }
    },
    [ref.current]
  );
  const handleFocusSearch = () => {
    ref.current.addEventListener("keyup", listener);
  };
  const handleBlurSearch = () => {
    ref.current.removeEventListener("keyup", listener);
  };
  if (
    routeMatches(ABOUT_PAGE) ||
    (!isLoggedIn && (routeMatches(HOME_PAGE) || routeMatches(BASE_PAGE)))
  ) {
    return <></>;
  }
  return (
    <SearchInputContainer
      fullWidth
      wider={!props.user}
      InputProps={{
        endAdornment: (
          <EndIcon size="36px">
            <SearchIcon onClick={handleManualSearch} />
          </EndIcon>
        ),
      }}
      onChange={handleSearch}
      placeholder={t("header.searchOffers")}
      onFocus={handleFocusSearch}
      onBlur={handleBlurSearch}
      ref={ref}
    />
  );
});

SearchInput.displayName = "SearchInput";

SearchInput.propTypes = {
  handleSearch: PropTypes.func,
  user: PropTypes.bool,
};

export default SearchInput;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MailSentContainer,
  Description,
  Footer,
  FooterText,
  FormContainer,
  SendAgainTextContainer,
  StandardText,
  Title,
} from "./MailSent.styled";
import { ReactComponent as MailSentImage } from "../../../assets/images/svg/mail-sent.svg";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Link from "../../../components/Link/Link";
import { Trans, useTranslation } from "react-i18next";
import { LOGIN_PAGE } from "../../../constants/pages";
import selectedTheme from "../../../themes";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../store/actions/user/userActions";

const MailSent = () => {
  const [mail, setEmail] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state.email) {
      setEmail(location.state.email);
    } else {
      history.push(LOGIN_PAGE);
    }
  }, []);

  const navigateLogin = () => {
    history.replace(LOGIN_PAGE);
  };

  const handleResend = () => {
    dispatch(forgotPassword({ email: mail }));
  };

  return (
    <MailSentContainer>
      <MailSentImage />

      <Title component="h1" variant="h5">
        {t("forgotPassword.mailSent")}
      </Title>

      <Description component="h1" variant="h6">
        {t("forgotPassword.mailSentDescription")}
      </Description>

      <FormContainer>
        <PrimaryButton
          type="submit"
          variant="contained"
          height="48px"
          fullWidth
          buttoncolor={selectedTheme.colors.primaryPurple}
          textcolor="white"
          onClick={navigateLogin}
        >
          {t("login.logIn")}
        </PrimaryButton>

        <SendAgainTextContainer>
          <StandardText>{t("forgotPassword.notRecievedMail")}</StandardText>

          <Link
            to="#"
            component={NavLink}
            underline="hover"
            align="center"
            textsize="16px"
            onClick={handleResend}
          >
            {t("common.sendAgain")}
          </Link>
        </SendAgainTextContainer>
      </FormContainer>

      <Footer>
        <FooterText>
          <Trans i18nKey="forgotPassword.checkSpam" />
        </FooterText>
      </Footer>
    </MailSentContainer>
  );
};

MailSent.propTypes = {
  children: PropTypes.node,
};

export default MailSent;

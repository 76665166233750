import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  BlogComponentContainer,
  BlogComponentList,
  BlogIcon,
  BlogSidebarContainer,
  BlogSidebarTitle,
} from "./BlogComponent.styled";
import Header from "../MarketPlace/Header/Header";
import useSorting from "../../hooks/useOffers/useSorting";
import { sortBlogEnum } from "../../enums/sortEnum";
import { Trans } from "react-i18next";
import { BLOG_DATA } from "../../enums/blogDummy";
import OfferCard from "../Cards/OfferCard/OfferCard";

const BlogComponent = (props) => {
  const [isGrid, setIsGrid] = useState(false);
  console.log(isGrid);
  const sorting = useSorting(() => {}, sortBlogEnum);
  // const { isMobile } = useIsMobile();
  // const { t } = useTranslation();
  return (
    <BlogComponentContainer sidebar={props.sidebar}>
      {!props?.sidebar && (
        <Header
          myOffers
          blog
          // hideGrid
          // isAdmin
          // users
          hideBackButton={false}
          sorting={sorting}
          isGrid={isGrid}
          setIsGrid={setIsGrid}
        />
      )}
      {props.sidebar && (
        <BlogSidebarContainer>
          <BlogIcon />
          <BlogSidebarTitle>
            <Trans i18nKey="blog.sidebarTitle" />
          </BlogSidebarTitle>
        </BlogSidebarContainer>
      )}
      <BlogComponentList isGrid={isGrid}>
        {BLOG_DATA.map((item) => {
          return (
            <OfferCard
              blog
              key={item.id}
              offer={item}
              sidebar={props?.sidebar || isGrid}
              // halfwidth={props?.isGrid}
              // messageUser={messageOneUser}
              // isMyOffer={item?.user._id === userId || props?.isAdmin}
              // isAdmin={props?.isAdmin}
            />
          );
        })}
      </BlogComponentList>
    </BlogComponentContainer>
  );
};

BlogComponent.displayName = "BlogComponent";

BlogComponent.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.bool,
};

export default BlogComponent;

import { Box } from "@mui/material";
import styled from "styled-components";
import Header from "../../../components/MarketPlace/Header/Header";
import SearchField from "../../../components/TextFields/SearchField/SearchField";

export const AdminUsersPageContainer = styled(Box)`
  padding: 60px;
  /* display: flex; */
  position: relative;
  padding-top: 38px;
  padding-bottom: 100px;
  min-height: 100vh;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 18px;
    min-height: (100vh - 72px);
    padding-bottom: 54px;
    top: 38px;
  }
`;
export const AdminUsersHeader = styled(Header)`
  top: 4px;
  @media (max-width: 600px) {
    top: 0px;
    /* margin-top: 18px; */
    & > div {
      margin-top: 0;
    }
    & > div > div > div {
      /* left: 0;
      top: 25px; */
    }
    & > div:nth-child(2) {
      white-space: nowrap;
    }
  }
`;
export const AdminUsersSearchField = styled(SearchField)`
  top: -15px;

  @media (max-width: 900px) {
    margin-top: 72px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
export const AdminUsersList = styled(Box)`
  padding-top: 18px;
`;

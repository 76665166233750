import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Close } from "../../../assets/images/svg/close-modal.svg";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import { IconButton } from "../../Buttons/IconButton/IconButton";

export const DrawerContainer = styled(Box)`
  width: 100vw;
  position: relative;
  height: 100%;
  overflow: hidden;
`;
export const ToolsContainer = styled(Box)`
  display: flex;
  width: calc(100% - 72px);
  flex-direction: column;
  justify-content: ${(props) => (props.mobile ? "center" : "space-between")};
  align-items: ${(props) => (props.mobile ? "start" : "center")};
  ${(props) => !props.mobile && `width: 100%;`}
  & div button {
    ${(props) => props.mobile && `width: auto;`}
  }
  position: absolute;
  top: 0px;
  bottom: 70px;
  left: ${(props) => (props.isAdmin ? "18px" : "36px")};
  gap: ${(props) => (props.isAdmin ? "0" : "26px")};

  @media (max-width: 320px) {
    gap: 6px;
  }
`;

export const AuthButtonsDrawerContainer = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 18px;
  z-index: 1000;
`;
export const CloseIcon = styled(Close)`
  color: ${selectedTheme.colors.primaryYellow};
  width: 16px;
  height: 16px;
`;
export const DrawerOption = styled(Typography)`
  font-weight: 600;
  font-family: ${selectedTheme.fonts.textFont};
  letter-spacing: 0.05em;
  color: ${selectedTheme.colors.primaryPurple};
  font-size: 18px;
  position: relative;
  top: 4px;
`;
export const DrawerButton = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const FooterButtons = styled(Box)`
  position: absolute;
  bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  width: calc(100vw - 72px);
  justify-content: space-between;
  /* border-top: 3px solid ${hexToRGB(
    selectedTheme.colors.borderNormal,
    0.12
  )}; */
`;

export const HeaderTitle = styled(Typography)`
  font-weight: 700;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 18px;
  color: ${selectedTheme.colors.primaryDarkTextThird};
  position: absolute;
  top: 36px;
  left: 36px;

  @media (max-width: 375px) {
    top: 18px;
  }
`;
export const Separator = styled(Box)`
  border-bottom: 1px solid ${selectedTheme.colors.primaryPurpleDisabled};
  opacity: 0.12;
  width: 100%;
`;

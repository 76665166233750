import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";
import selectedTheme from "../../../themes";
import { ReactComponent as ProfilePictureSVG } from "../../../assets/images/profile-picture.svg";

export const RegisterPageContainer = styled(Container)`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 335px;
  padding: 0;
  flex: 1;
  position: relative;
  /* position: relative; */
  transition: 1s all;
  margin-bottom: 84px;
  ${(props) => props.currentstep === 3 && `margin-top: 40px;`};
  @media (max-height: 900px) {
    margin-top: 60px;
  }
  @media (max-height: 800px) {
    margin-top: 30px;
    /* flex: none; */
    /* height: 95vh; */
    /* ${(props) => props.currentstep === 3 && `height: 105vh`};
    ${(props) => props.currentstep === 2 && `height: 100vh`}; */
  }
  @media (max-width: 600px) {
    margin-bottom: 60px;
  }
`;
export const RegisterTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 34px;
  @media (max-height: 800px) {
    margin-top: 26px;
  }
`;
export const RegisterDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 20px;
  @media (max-height: 800px) {
    margin-bottom: 14px;
    margin-top: 6px;
  }
`;
export const FormContainer = styled(Box)`
  width: 335px;
`;
export const LoginAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.altText};
  color: ${selectedTheme.colors.primaryText};
  font-size: 14px;
  padding-right: 6px;
  line-height: 14px;
`;
export const LoginTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  margin-bottom: 12px;
  justify-content: center;
  @media (max-height: 800px) {
    margin-top: 26px;
  }
`;
export const ProgressContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;
export const Footer = styled(Box)`
  position: absolute;
  bottom: -48px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;
export const FooterText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: #505050;
  text-align: center;
  width: 330px;
  line-height: 16px;
  font-weight: 400;
  padding: 0;
  font-size: 12px;
`;

export const ProfileImagePicker = styled(ImagePicker)`
  background: none;
  /* margin: 36px; */
  margin-top: 36px;
  background: ${selectedTheme.colors.primaryIconBackgroundColor};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%235A3984FF' stroke-width='2' stroke-dasharray='7%2c 12' stroke-dashoffset='44' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
`;
export const ProfilePicture = styled(ProfilePictureSVG)`
  position: absolute;
  left: 36px;
  top: 24px;
  z-index: 0;
`;
export const RegisterPageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 335px;
  padding: 0;
  flex: 1;
  position: relative;
  /* margin-bottom: 100px; */
  /* @media (max-height: 800px) {
    flex: none;
    height: 95vh;
    ${(props) => props.currentstep === 3 && `height: 105vh`};
    ${(props) => props.currentstep === 2 && `height: 100vh`};
  } */
`;
export const ErrorMessage = styled(Box)`
  color: red;
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  top: 5px;
  text-align: left;
  font-size: 14px;
  width: 100%;
`;

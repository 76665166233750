import React from 'react'
import PropTypes from 'prop-types'
import { DropdownItemContainer, DropdownItemStyled } from './DropdownItem.styled'

const DropdownItem = (props) => {
  return (
    <DropdownItemContainer onClick={props.onClick}>
        <DropdownItemStyled>
            {props.children}
        </DropdownItemStyled>
    </DropdownItemContainer>
  )
}

DropdownItem.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export default DropdownItem;
import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
  createSubmitType,
  createUpdateType,
} from "../actionHelpers";

const LOGIN_USER_SCOPE = "LOGIN_USER";
export const LOGIN_USER_FETCH = createFetchType(LOGIN_USER_SCOPE);
export const LOGIN_USER_SUCCESS = createSuccessType(LOGIN_USER_SCOPE);
export const LOGIN_USER_ERROR = createErrorType(LOGIN_USER_SCOPE);
export const CLEAR_LOGIN_USER_ERROR = createClearType(
  `${LOGIN_USER_SCOPE}_ERROR`
);
export const LOGIN_USER_LOADING = createLoadingType(LOGIN_USER_SCOPE);

export const UPDATE_USER_JWT_TOKEN = createUpdateType("UPDATE_USER_JWT_TOKEN");
export const RESET_LOGIN_STATE = createClearType("UPDATE_USER_JWT_TOKEN");
export const AUTHENTICATE_USER = createUpdateType("AUTHENTICATE_USER");
export const LOGOUT_USER = createUpdateType("LOGOUT_USER");
export const LOGOUT_ADMIN = createUpdateType("LOGOUT_ADMIN");
export const REFRESH_TOKEN = createUpdateType("REFRESH_TOKEN");

const GENERATE_TOKEN_SCOPE = "GENERATE_TOKEN";
export const GENERATE_TOKEN = createSubmitType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_SUCCESS = createSuccessType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_ERROR = createErrorType(GENERATE_TOKEN_SCOPE);

import { Box } from "@mui/material";
import { ReactComponent as LogoHorizontal } from "../../../../assets/images/svg/logo-horizontal.svg";
import styled from "styled-components";

export const SidebarHeaderContainer = styled(Box)`
  background-color: #f5edff;
  padding-top: 3.3vh;
  padding-left: 36px;
  padding-bottom: 3.3vh;
`;
export const LogoHorizontalIcon = styled(LogoHorizontal)`
  cursor: pointer;
`;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const ItemDetailsContainer = styled(Box)``;

export const OfferIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 34px;
  margin-left: 36px;

  @media screen and (max-width: 600px) {
    margin-left: 0;
    margin-top: 345px;

    svg {
      width: 14px;
    }
  }
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`;

export const OfferIconText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkTextThird};
  font-size: 16px;
  margin-left: 7.2px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

import { Container } from "@mui/system";
import styled from "styled-components";
import { transitionOnLoadFromLeft } from "../../components/Styles/globalStyleComponents";

export const MessagesListPageContainer = styled(Container)`
  padding: 0;
  margin: 0;
  animation: 0.2s ease 0s 1 ${transitionOnLoadFromLeft};
  margin-top: 80px;
  height: 100%;
  width: 100%;
  max-width: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0px;
  @media (max-width: 600px) {
    margin-top: 100px;
  }
`;

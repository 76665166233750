import { ReactComponent as User } from "../../../../../assets/images/svg/user.svg";
import { ReactComponent as Location } from "../../../../../assets/images/svg/location.svg";
import { ReactComponent as Category } from "../../../../../assets/images/svg/category.svg";
import { ReactComponent as Dollar } from "../../../../../assets/images/svg/dollar-sign.svg";
import { ReactComponent as Marketplace } from "../../../../../assets/images/svg/package.svg";
import styled from "styled-components";
export const LocationIcon = styled(Location)``;
export const UserIcon = styled(User)``;
export const CategoryIcon = styled(Category)``;
export const DollarIcon = styled(Dollar)``;
export const MarketplaceIcon = styled(Marketplace)``;

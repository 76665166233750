import { Box, styled, Typography } from "@mui/material";
import selectedTheme from "../../../themes";

export const PrivacyPolicyHeaderContainer = styled(Box)`
  margin: 72px;
`;
export const PrivacyPolicyHeaderTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 98px;
  color: ${selectedTheme.colors.primaryPurple};

  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 49px;
  }
`;
export const PrivacyPolicyHeaderParagraph = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${selectedTheme.colors.primaryGrayText};

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export const PrivacyPolicyHeaderLine = styled(Box)`
  border-top: 1px solid ${selectedTheme.colors.iconYellowColor};
  width: 90px;
  margin-bottom: 26px;

  @media (max-width: 600px) {
    width: 54px;
  }
`;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
// import MarketPlace from "../../components/MarketPlace/MarketPlace";
// import useOffers from "../../hooks/useOffers/useOffers";
import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import { useSelector } from "react-redux";
// import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
// import { OFFERS_SCOPE } from "../../store/actions/offers/offersActionConstants";
import AdminUsersPage from "./AdminUsersPage/AdminUsersPage";
import { selectMineProfile } from "../../store/selectors/profileSelectors";
import { Switch, useHistory } from "react-router-dom";
import {
  ADMIN_CATEGORIES_PAGE,
  ADMIN_ITEM_DETAILS_PAGE,
  ADMIN_LOCATIONS_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_SINGLE_USER_PAGE,
  ADMIN_SUBCATEGORIES_PAGE,
  ADMIN_USERS_PAGE,
  HOME_PAGE,
} from "../../constants/pages";
import { selectUserId } from "../../store/selectors/loginSelectors";
import AdminCategoriesPage from "./AdminCategoriesPage/AdminCategoriesPage";
import AdminRoute from "../../components/Router/AdminRoute";
import AdminSubcategoriesPage from "./AdminSubcategoriesPage/AdminSubcategoriesPage";
import AdminLocationsPage from "./AdminLocationsPage/AdminLocationsPage";
import AdminPaymentPage from "./AdminPaymentPage/AdminPaymentPage";
import AdminSingleUserPage from "./AdminUsersPage/AdminSingleUserPage/AdminSingleUserPage";
import { AdminLayoutHomePage } from "./AdminHomePage.styled";
import AdminItemDetailsPage from "./AdminItemDetailsPage/AdminItemDetailsPage";

const AdminHomePage = () => {
  const profile = useSelector(selectMineProfile);
  const userId = useSelector(selectUserId);
  const history = useHistory();
  const isUserLogin = useMemo(() => {
    if (userId?.length === 0) return false;
    return true;
  }, [userId]);
  if (!profile.roles.includes("Admin") || !isUserLogin) {
    history.push(HOME_PAGE);
  }
  return (
    <AdminLayoutHomePage
      leftCard={<Sidebar />}
      content={
        <Switch>
          <AdminRoute
            exact
            path={ADMIN_USERS_PAGE}
            component={AdminUsersPage}
          />
          <AdminRoute
            exact
            path={ADMIN_CATEGORIES_PAGE}
            component={AdminCategoriesPage}
          />
          <AdminRoute
            exact
            path={ADMIN_SINGLE_USER_PAGE}
            component={AdminSingleUserPage}
          />
          <AdminRoute
            path={ADMIN_ITEM_DETAILS_PAGE}
            component={AdminItemDetailsPage}
          />
          <AdminRoute
            path={ADMIN_SUBCATEGORIES_PAGE}
            component={AdminSubcategoriesPage}
          />
          <AdminRoute
            path={ADMIN_LOCATIONS_PAGE}
            component={AdminLocationsPage}
          />
          <AdminRoute path={ADMIN_PAYMENT_PAGE} component={AdminPaymentPage} />
          <AdminRoute
            path={ADMIN_SUBCATEGORIES_PAGE}
            component={AdminSubcategoriesPage}
          />
          <AdminRoute component={AdminUsersPage} />
        </Switch>
      }
    />
  );
};

AdminHomePage.propTypes = {
  children: PropTypes.node,
};

export default AdminHomePage;

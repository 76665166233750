import styled, { keyframes } from "styled-components";
import { Box } from "@mui/system";
import selectedTheme from "../../../themes";

const skeletonAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonItems};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonItemsSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonItems}
    }
`;

const skeletonBackgroundAnimation = keyframes`
    0% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground};
    }

    50% {
        background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
    }

    100% {
        background-color: ${selectedTheme.colors.filterSkeletonBackground}
    }
`;

export const SkeletonProfileMiniContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 60px 0 40px 0;
`;

export const SkeletonProfileMiniHeader = styled(Box)`
  width: 145px;
  height: 18px;
  margin-bottom: 18px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 72px;
  }
`;

export const SkeletonProfileMiniContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  background-color: ${selectedTheme.colors.filterSkeletonBackground};
  animation: ${skeletonBackgroundAnimation} 2s infinite;
`;

export const SkeletonProfileMiniImageColumn = styled(Box)`
  display: flex;
`;

export const SkeletonProfileMiniImage = styled(Box)`
  width: 108px;
  height: 108px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 90px;
    height: 90px;
  }
`;

export const SkeletonProfileMiniColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

export const SkeletonProfileMiniFirstRow = styled(Box)`
  width: 90px;
  height: 27px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    height: 20px;
  }
`;

export const SkeletonProfileMiniSecondRow = styled(Box)`
  width: 163px;
  height: 18px;
  margin: 27px 0 9px 0;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 136px;
    height: 14px;
    margin-top: 9px;
  }
`;

export const SkeletonProfileMiniThirdRow = styled(Box)`
  width: 117px;
  height: 18px;
  border-radius: 2px;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SkeletonProfileMiniImageSmall = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${selectedTheme.colors.filterSkeletonBackgroundSecond};
  animation: ${skeletonAnimation} 2s infinite;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

import * as Yup from "yup";
import YupPassword from "yup-password";
import i18n from "../i18n";
YupPassword(Yup);

export default Yup.object().shape({
  password: Yup.string()
    .required(i18n.t("login.passwordRequired"))
    .min(8, i18n.t("login.passwordLength"))
    .minLowercase(1, i18n.t("password.strongPassword"))
    .minUppercase(1, i18n.t("password.strongPassword"))
    .minSymbols(1, i18n.t("password.strongPassword"))
    .minNumbers(1, i18n.t("password.strongPassword")),
  passwordConfirm: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      i18n.t("login.passwordConfirmIncorrect")
    )
    .required(i18n.t("login.passwordConfirmRequired")),
});

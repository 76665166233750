import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const PhonePopoverContainer = styled(Box)`
  width: 138px;
  height: 49px;
  filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.04));
  position: relative;
  overflow: visible !important;
`;
export const Arrow = styled(Box)`
  position: absolute;
  width: 13px;
  height: 13px;
  right: -6px;
  top: 18px;
  background: white;
  border-radius: 0.72px;
  transform: rotate(45deg);
`;
export const PhoneLabel = styled(Box)`
  color: ${selectedTheme.colors.primaryDarkText};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  align-items: center;
  display: flex;
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  left: 18px;
  top: 5px;
`;
export const PhoneNumber = styled(Box)`
  position: relative;
  left: 18px;
  top: 5px;
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: ${selectedTheme.colors.primaryPurple};
`;

import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

const PAYMENTS_SCOPE = "PAYMENTS_SCOPE";
export const PAYMENTS_FETCH = createFetchType(PAYMENTS_SCOPE);
export const PAYMENTS_FETCH_SUCCESS = createSuccessType(PAYMENTS_SCOPE);
export const PAYMENTS_FETCH_ERROR = createErrorType(PAYMENTS_SCOPE);

export const PAYMENTS_ADD_SCOPE = "PAYMENTS_ADD_SCOPE";
export const PAYMENTS_ADD = createFetchType(PAYMENTS_ADD_SCOPE);
export const PAYMENTS_ADD_SUCCESS = createSuccessType(PAYMENTS_ADD_SCOPE);
export const PAYMENTS_ADD_ERROR = createErrorType(PAYMENTS_ADD_SCOPE);

export const PAYMENTS_EDIT_SCOPE = "PAYMENTS_EDIT_SCOPE";
export const PAYMENTS_EDIT = createFetchType(PAYMENTS_EDIT_SCOPE);
export const PAYMENTS_EDIT_SUCCESS = createSuccessType(PAYMENTS_EDIT_SCOPE);
export const PAYMENTS_EDIT_ERROR = createErrorType(PAYMENTS_EDIT_SCOPE);

export const PAYMENTS_DELETE_SCOPE = "PAYMENTS_DELETE_SCOPE";
export const PAYMENTS_DELETE = createFetchType(PAYMENTS_DELETE_SCOPE);
export const PAYMENTS_DELETE_SUCCESS = createSuccessType(PAYMENTS_DELETE_SCOPE);
export const PAYMENTS_DELETE_ERROR = createErrorType(PAYMENTS_DELETE_SCOPE);

export const PAYMENTS_SET = createSetType("PAYMENTS_SET");

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DrawerButton } from "../../Drawer.styled";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { AboutIcon, GrayDrawerOption } from "./AboutButton.styled";
import history from "../../../../../store/utils/history";
import { ABOUT_PAGE } from "../../../../../constants/pages";
import scrollConstants from "../../../../../constants/scrollConstants";

const AboutButton = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.toggleDrawer();
    history.push({
      pathname: ABOUT_PAGE,
      state: {
        navigation: scrollConstants.about.aboutPage,
        clicked: true,
      },
    });
  };
  return (
    <DrawerButton onClick={handleClick}>
      <IconButton sx={{ borderRadius: "4px" }}>
        <AboutIcon />
      </IconButton>
      <GrayDrawerOption>{t("header.about")}</GrayDrawerOption>
    </DrawerButton>
  );
};

AboutButton.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default AboutButton;

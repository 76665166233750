import React from "react";
import PropTypes from "prop-types";
import {
  AboutHeaderContainer,
  AboutHeaderLine,
  AboutHeaderParagraph,
  AboutHeaderTitle,
} from "./AboutHeader.styled";
import { useTranslation } from "react-i18next";

const AboutHeader = () => {
  const { t } = useTranslation();
  return (
    <AboutHeaderContainer>
      <AboutHeaderTitle>{t("about.header.title")}</AboutHeaderTitle>
      <AboutHeaderLine />
      <AboutHeaderParagraph>{t("about.header.paragraph")}</AboutHeaderParagraph>
    </AboutHeaderContainer>
  );
};

AboutHeader.propTypes = {
  children: PropTypes.node,
};

export default AboutHeader;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowBackIcon,
  BackIcon,
  CloseButton,
  CloseIcon,
  CreateReviewContainer,
} from "./CreateReview.styled";
import FirstStepCreateReview from "./FirstStep/FirstStepCreateReview";
import SecondStepCreateReview from "./SecondStep/SecondStepCreateReview";
import ThirdStepCreateReview from "./ThirdStep/ThirdStepCreateReview";
import { useDispatch } from "react-redux";
import { giveReview } from "../../store/actions/review/reviewActions";
import { reviewEnum } from "../../enums/reviewEnum";
import { fetchExchange } from "../../store/actions/exchange/exchangeActions";
import { closeModal } from "../../store/actions/modal/modalActions";
import BackdropComponent from "../MUI/BackdropComponent";

const CreateReview = (props) => {
  const offer = props.offer;
  const [informations, setInformations] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    dispatch(closeModal());
  };
  const handleApiResponseSuccess = () => {
    dispatch(fetchExchange(props.exchange._id));
  };
  const submitForm = () => {
    let communication = "yes";
    if (informations.correctCommunication === reviewEnum.NO.mainText)
      communication = "no";
    if (informations.correctCommunication === reviewEnum.NOT_BAD.mainText)
      communication = "could be better";
    let succeeded = "failed";
    if (informations.exchangeSucceed === reviewEnum.YES.mainText)
      succeeded = "succeeded";
    dispatch(
      giveReview({
        review: {
          exchange: {
            _id: props.exchange._id,
          },
          succeeded,
          communication,
          message: informations.comment,
        },
        handleApiResponseSuccess,
      })
    );
  };
  const goToNextStep = (newInformations) => {
    setInformations((prevInformations) => {
      return {
        ...prevInformations,
        ...newInformations,
      };
    });
    if (currentStep === 3) {
      closeModalHandler();
    } else {
      if (currentStep === 2) {
        submitForm();
      }
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };
  const goToPrevStep = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };
  return (
    <>
      <BackdropComponent
        isLoading
        handleClose={closeModalHandler}
        position="fixed"
      />
      <CreateReviewContainer currentStep={currentStep}>
        <CloseButton onClick={closeModalHandler}>
          <CloseIcon />
        </CloseButton>
        {currentStep === 2 && (
          <BackIcon onClick={goToPrevStep}>
            <ArrowBackIcon />
          </BackIcon>
        )}
        {currentStep === 1 && (
          <FirstStepCreateReview
            offer={offer}
            interlocutor={props.interlocutor}
            informations={informations}
            goToNextStep={goToNextStep}
          />
        )}
        {currentStep === 2 && (
          <SecondStepCreateReview
            review={informations}
            offer={offer}
            interlocutor={props.interlocutor}
            goToNextStep={goToNextStep}
          />
        )}
        {currentStep === 3 && <ThirdStepCreateReview />}
      </CreateReviewContainer>
    </>
  );
};

CreateReview.propTypes = {
  children: PropTypes.node,
  offer: PropTypes.any,
  interlocutor: PropTypes.any,
  exchange: PropTypes.any,
  handleGiveReviewSuccess: PropTypes.func,
};

export default CreateReview;

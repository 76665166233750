import { Box } from "@mui/material";
import styled from "styled-components";

export const ListContainer = styled(Box)`
  color: #fff;
  padding-left: 18px;
  & > div {
    display: list-item;
  }
`;

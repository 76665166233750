import React from "react";
import PropTypes from "prop-types";
import { ReviewContainer } from "./UserReviewsSingleCard.styled";
import ReviewerProfile from "./ReviewerProfile/ReviewerProfile";
import ReviewQuote from "./ReviewQuote/ReviewQuote";
import ReviewDetails from "./ReviewDetails/ReviewDetails";
import RemoveButton from "./RemoveButton/RemoveButton";
import ReviewOffer from "./ReviewOffer/ReviewOffer";
import GivenReviewsHeader from "./GivenReviewsHeader/GivenReviewsHeader";

const UserReviewsSingleCard = (props) => {
  const handleRemove = () => {
    props.handleRemove();
  };
  return (
    <ReviewContainer
      lastReview={props.lastReview}
      className={props.className}
      hasGivenReview={props.hasGivenReview}
    >
      {props.isAdmin && !props.rightReviews && props.hasGivenReview && (
        <GivenReviewsHeader
          hasGivenReview={props.hasGivenReview}
          userWhoReceived={props.review?.userWhoReceived}
        />
      )}
      <ReviewerProfile
        profileName={props.review?.name}
        profileImage={props.review?.image}
        userId={props.review?.userId}
      />
      <ReviewQuote
        isSuccessfulSwap={props.review?.isSuccessfulSwap}
        quote={props.review?.quote}
      />
      <ReviewDetails
        isSuccessfulSwap={props.review?.isSuccessfulSwap}
        isGoodCommunication={props.review?.isGoodCommunication}
      />
      {props.showRemoveIcon && (
        <RemoveButton
          review={props.review}
          onClick={handleRemove}
          isProfileReviews={props?.isProfileReviews}
          hasGivenReview={props.hasGivenReview}
          isRemoved={props.review?._deleted}
        />
      )}
      <ReviewOffer
        name={props.review?.offerName}
        image={props.review?.offerImage}
      />
    </ReviewContainer>
  );
};

UserReviewsSingleCard.propTypes = {
  review: PropTypes.any,
  handleRemove: PropTypes.func,
  showRemoveIcon: PropTypes.bool,
  hasGivenReview: PropTypes.bool,
  userWhoReceived: PropTypes.any,
  rightReviews: PropTypes.bool,
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
  lastReview: PropTypes.bool,
  isProfileReviews: PropTypes.any,
};
UserReviewsSingleCard.defaultProps = {
  lastReview: false,
  isAdmin: false,
  rightReviews: false,
  hasGivenReview: false,
  showRemoveIcon: false,
};

export default UserReviewsSingleCard;

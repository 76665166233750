import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoBroken } from "../../../../assets/images/svg/logo-broken.svg";
import {
  Button,
  OffersNotFoundContainer,
  OffersNotFoundLogo,
  OffersNotFoundDescription,
  OffersNotFoundHeading,
} from "./OffersNotFound.styled";
import selectedTheme from "../../../../themes";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { HOME_PAGE } from "../../../../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffers } from "../../../../store/actions/offers/offersActions";
import { selectTotalOffers } from "../../../../store/selectors/offersSelectors";

const OffersNotFound = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const totalOffers = useSelector(selectTotalOffers);
  const { t } = useTranslation();

  const showAllOffersHandler = () => {
    dispatch(fetchOffers({ queryString: "" }));
    history.replace({
      pathname: HOME_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  return (
    <>
      <OffersNotFoundContainer show={totalOffers === 0}>
        <OffersNotFoundLogo>
          <LogoBroken />
        </OffersNotFoundLogo>
        <OffersNotFoundHeading>
          {t("offersNotFound.notFound")}
        </OffersNotFoundHeading>
        <OffersNotFoundDescription>
          <Trans i18nKey="offersNotFound.errorMessage" />
        </OffersNotFoundDescription>
        <Button
          variant="contained"
          buttoncolor={selectedTheme.colors.primaryYellow}
          textcolor="black"
          onClick={showAllOffersHandler}
        >
          {t("offersNotFound.showAllOffers")}
        </Button>
      </OffersNotFoundContainer>
    </>
  );
};

OffersNotFound.propTypes = {
  skeleton: PropTypes.bool,
};
OffersNotFound.defaultProps = {
  skeleton: false,
};

export default OffersNotFound;

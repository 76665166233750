import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FieldLabel } from "../FirstPartCreateOffer.styled";
import RichTextComponent from "../../../../RichTextComponent/RichTextComponent";

const OfferDescriptionField = (props) => {
  const formik = props.formik;
  const { t } = useTranslation();
  const handleChange = (newValue) => {
    formik.setFieldValue("description", newValue);
  };
  return (
    <>
      <FieldLabel leftText={t("offer.productDescription")} />
      <RichTextComponent
        onChange={handleChange}
        value={formik.values.description}
      />
    </>
  );
};

OfferDescriptionField.propTypes = {
  formik: PropTypes.any,
};

export default OfferDescriptionField;

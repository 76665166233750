import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { ReactComponent as Logout } from "../../../../../assets/images/svg/log-out.svg";

export const LogoutButtonContainer = styled(Box)``;
export const LogoutIcon = styled(Logout)`
  width: 20px;
  height: 20px;
  & path {
    stroke: ${selectedTheme.colors.primaryYellow};
  }
`;
export const LogoutText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: ${selectedTheme.colors.primaryPurple};
  font-family: ${selectedTheme.fonts.textFont};
  position: relative;
  left: -14px;
  top: -3px;
`;

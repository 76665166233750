import React from "react";
import PropTypes from "prop-types";
import {
  OfferDescriptionContainer,
  OfferDescriptionText,
} from "./OfferDescription.styled";
import RichTextComponent from "../../../RichTextComponent/RichTextComponent";
import { isJsonString } from "../../../../util/helpers/jsonHelper";

const OfferDescription = (props) => {
  return (
    <OfferDescriptionContainer>
      <OfferDescriptionText>
        {props?.blog ? (
          <OfferDescriptionText blog={props?.blog}>
            {props?.description}
          </OfferDescriptionText>
        ) : isJsonString(props?.description) ? (
          <RichTextComponent readOnly offerCard value={props?.description} />
        ) : (
          ""
        )}
      </OfferDescriptionText>
    </OfferDescriptionContainer>
  );
};

OfferDescription.propTypes = {
  description: PropTypes.string,
  blog: PropTypes.bool,
};

export default OfferDescription;

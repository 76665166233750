import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ReactComponent as Blog } from "../../assets/images/svg/blog.svg";

export const BlogComponentContainer = styled(Box)`
  ${(props) =>
    props.sidebar
      ? `   width: 560px;
            margin-left: -160px;
            @media (max-width: 1530px) {
              margin-left: 0;
            }
            @media (min-width: 1530px) and (max-width: 1920px)  {
              width: 530px;
            }
            `
      : `   width: 60%;
            margin: 0 auto;
            `}

  @media (max-width: 900px) {
    width: 90%;
  }
`;

export const BlogComponentList = styled(Box)`
  ${(props) =>
    props.isGrid &&
    `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    div {
      margin-bottom: 0;
    }
  `};
`;

export const BlogSidebarContainer = styled(Box)`
  display: flex;
`;

export const BlogSidebarTitle = styled(Typography)`
  font-size: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.headerGray};
  strong {
    font-weight: 700;
  }
`;

export const BlogIcon = styled(Blog)`
  margin-right: 9px;
`;

import React from "react";
import PropTypes from "prop-types";
import { Content, LeftCard, AdminLayoutContainer } from "./AdminLayout.styled";
import { Grid } from "@mui/material";

const AdminLayout = (props) => {
  return (
    <AdminLayoutContainer className={props.className}>
      {props.children}
      <Grid container maxHeight="xl">
        <LeftCard item xs={0} sm={0} md={3} lg={3} xl={2.4}>
          {props.leftCard}
        </LeftCard>
        <Content item xs={12} sm={12} md={9} lg={9} xl={9.6}>
          {props.content}
        </Content>
      </Grid>
    </AdminLayoutContainer>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
  leftCard: PropTypes.node,
  content: PropTypes.node,
  rightCard: PropTypes.node,
  className: PropTypes.string,
};

export default AdminLayout;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const ColorPickerContainer = styled(Box)`
  padding: 9px 18px;
  isolation: isolate;
  width: 178px;
  height: 74px;
  background: white;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  z-index: 1000;
`;
export const ColorPickerNameContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-end;
`;
export const ColorPickerLabel = styled(Typography)`
  color: ${selectedTheme.colors.primaryTextDisabled};
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  line-height: 15.5px;
  letter-spacing: 1px;
`;
export const ColorPickerName = styled(Typography)`
  color: ${selectedTheme.colors.primaryText};
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  line-height: 18.5px;
`;
export const ColorsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding: 4px;
  align-items: flex-end;
`;

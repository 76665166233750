import { css } from "styled-components";
import { pxToRemMd } from "./helper";
import selectedTheme from "..";
import { variables } from './variables';


export const BaseStyle = css`
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-anchor: none;
    background-color: #F1F1F1;
}

* {
    box-sizing: border-box;
}
html {
    min-height: 100%;
    font-size: 16px;
}
html,
body,
#root {
    display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
blockquote,
th,
td {
  ${selectedTheme.fonts.textFont}
}

p {
  vertical-align: middle;
  display: inline-block;
  word-break: break-word;
  font-size: ${pxToRemMd(16)};
  line-height: 1.5;
}

@media only screen and (max-width: ${variables.breakpoints.bpMd}) {
    p {
        font-size: ${pxToRemMd(14)};
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  
  strong {
    font-weight: bold;
  }
  
  

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

ul {
  list-style: none;
  padding: 0;
}

@media only screen and (max-width: ${variables.breakpoints.bpXxl}) {
    html {
        font-size: 16px;
    }
}

@media only screen and (max-width: ${variables.breakpoints.bpXs})
{
    html {
        font-size: 13px;
    }
}
@media only screen and (max-width: ${variables.breakpoints.bpXxs}){
    html {
        font-size: 10.5px;
    }
}
    
`;
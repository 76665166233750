import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ArrowButton } from "../Buttons/ArrowButton/ArrowButton";

export const AboutFooterContainer = styled(Box)`
  height: 144px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 72px;

  @media (max-width: 600px) {
    height: 91px;
    padding: 0 36px;
  }
`;
export const AboutFooterText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${selectedTheme.colors.primaryGrayText};
  width: 100%;
  text-align: center;
  position: relative;
  top: 61px;

  @media (max-width: 1194px) {
    text-align: left;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    top: 30px;
  }
`;
export const ButtonContainer = styled(Link)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  text-decoration: none;
  color: ${selectedTheme.colors.primaryPurple};
  width: 100%;
  text-align: right;
  position: relative;
  top: 29px;

  @media (max-width: 600px) {
    top: -38px;
  }
`;
export const LinkText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryGrayText};
  position: relative;
  top: 8px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Arrow = styled(ArrowButton)`
  transform: rotate(-90deg);
`;

import React from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  DeleteOfferContainer,
  DeleteQuestion,
  OfferInfo,
  OfferImageContainer,
  OfferImage,
  RemoveIconContainer,
  RemoveIcon,
  PinIcon,
  UnpinIcon,
} from "./DeleteOffer.styled";
import BackdropComponent from "../../../MUI/BackdropComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffers,
  fetchOneOffer,
  fetchProfileOffers,
  pinOffer,
  removeOffer,
} from "../../../../store/actions/offers/offersActions";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import { selectQueryString } from "../../../../store/selectors/queryStringSelectors";
import CancelButton from "./CancelButton/CancelButton";
import SaveButton from "./SaveButton/SaveButton";
import { closeModal } from "../../../../store/actions/modal/modalActions";
import {
  dynamicRouteMatches,
  replaceInRoute,
  routeMatches,
} from "../../../../util/helpers/routeHelpers";
import {
  ADMIN_ITEM_DETAILS_PAGE,
  ADMIN_SINGLE_USER_PAGE,
  BASE_PAGE,
  HOME_PAGE,
  ITEM_DETAILS_PAGE,
  PROFILE_PAGE,
} from "../../../../constants/pages";
import { OfferDescriptionContainer } from "./DeleteOfferLabeledCard/DeleteOfferLabeledCard.styled";
import { fetchProfile } from "../../../../store/actions/profile/profileActions";

const DeleteOffer = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryString = useSelector(selectQueryString);
  const history = useHistory();
  const userId = props.offer.user._id;
  const { isMobile } = useIsMobile();
  const offerId = props.offer._id;
  const closeDeleteModalHandler = () => {
    dispatch(closeModal());
  };

  const handleApiResponseSuccess = () => {
    if (
      dynamicRouteMatches(PROFILE_PAGE) ||
      dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE)
    ) {
      dispatch(fetchProfileOffers({ idProfile: userId, isAdmin: true }));
      dispatch(fetchProfile(userId));
    }
    if (routeMatches(HOME_PAGE) || routeMatches(BASE_PAGE))
      dispatch(fetchOffers({ queryString }));
    if (dynamicRouteMatches(ITEM_DETAILS_PAGE)) {
      history.push(
        replaceInRoute(PROFILE_PAGE, {
          profileId: userId,
        })
      );
    }
    if (dynamicRouteMatches(ADMIN_ITEM_DETAILS_PAGE)) {
      if (props.pin) dispatch(fetchOneOffer(props.offer?._id));
      else
        history.push(
          replaceInRoute(ADMIN_SINGLE_USER_PAGE, { profileId: userId })
        );
    }
  };

  const removeOfferHandler = () => {
    if (props.pin) {
      dispatch(
        pinOffer({ offerId: props.offer?._id, handleApiResponseSuccess })
      );
    } else {
      dispatch(
        removeOffer({
          offerId,
          isAdmin: props.isAdmin,
          handleApiResponseSuccess,
        })
      );
    }
    closeDeleteModalHandler();
    if (history.location.pathname.includes("proizvodi")) {
      history.goBack();
    }
  };

  return (
    <>
      <BackdropComponent
        isLoading
        position="fixed"
        handleClose={closeDeleteModalHandler}
      />
      <DeleteOfferContainer>
        <OfferInfo>
          <OfferImageContainer>
            <OfferImage
              src={getImageUrl(
                props.offer.images[0],
                variants.deleteChat,
                isMobile
              )}
            />
          </OfferImageContainer>
          <OfferDescriptionContainer
            offerName={props.offer.name}
            categoryName={props.offer.category.name}
          />
          <RemoveIconContainer>
            {props.deleteOffer !== false ? (
              <RemoveIcon />
            ) : !props.pinnedOffer ? (
              <PinIcon />
            ) : (
              <UnpinIcon />
            )}
          </RemoveIconContainer>
        </OfferInfo>
        <DeleteQuestion>
          {props.deleteOffer !== false ? (
            <Trans i18nKey="deleteOffer.areYouSure" />
          ) : !props.pinnedOffer ? (
            t("admin.pin.reassurancePin")
          ) : (
            t("admin.unpin.reassurancePin")
          )}
        </DeleteQuestion>
        <ButtonsContainer>
          <CancelButton pin={props.pin} onClick={closeDeleteModalHandler} />
          <SaveButton
            pin={props.pin}
            pinnedOffer={props.pinnedOffer}
            deleteOffer={props.deleteOffer}
            onClick={removeOfferHandler}
          />
        </ButtonsContainer>
      </DeleteOfferContainer>
    </>
  );
};

DeleteOffer.propTypes = {
  offer: PropTypes.any,
  closeModalHandler: PropTypes.func,
  pin: PropTypes.bool,
  deleteOffer: PropTypes.bool,
  pinnedOffer: PropTypes.bool,
  isAdmin: PropTypes.bool,
};
DeleteOffer.defaultProps = {
  isAdmin: false,
};

export default DeleteOffer;

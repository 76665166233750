import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const FilterFooterContainer = styled(Box)`
  position: "sticky";
  ${(props) =>
    props.responsiveOpen &&
    `
  flex-direction: row;
  display: flex;
  justify-content: space-around;`}
  bottom: 0;
  & div button {
    height: 48px;
    padding-top: 7px;
  }
  & div button:hover {
    background-color: ${selectedTheme.colors.primaryPurple} !important;
    color: ${selectedTheme.colors.primaryBackgroundColor} !important;
  }
`;
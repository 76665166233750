import React from "react";
import PropTypes from "prop-types";
import {
  ApproveIcon,
  BlockIcon,
  ButtonContainer,
  EditIcon,
  RemoveIcon,
  UnblockIcon,
} from "./ProfileControlButton.styled";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProfileControlButton = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        props.title !== "editProfile"
          ? t(`admin.${props.title}.tooltip`)
          : t(`${props.title}.tooltip`)
      }
    >
      <ButtonContainer
        onClick={props.onClick}
        isAdmin={props.isAdmin}
        disabled={props.disabled}
      >
        {props.title === "unblockUser" ? (
          <UnblockIcon />
        ) : props.title === "blockUser" ? (
          <BlockIcon />
        ) : props.title === "deleteUser" ? (
          <RemoveIcon />
        ) : props.title === "editProfile" ? (
          <EditIcon />
        ) : props.title === "approveUser" ? (
          <ApproveIcon />
        ) : (
          <></>
        )}
      </ButtonContainer>
    </Tooltip>
  );
};

ProfileControlButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ProfileControlButton;

import React from "react";
import PropTypes from "prop-types";
import {
  OffersContainer,
  OffersScroller,
  ProfileOffersContainer,
  ProfilePaging,
  SkeletonContainer,
} from "./ProfileOffers.styled";
import { useState } from "react";
// import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OfferCard from "../../Cards/OfferCard/OfferCard";
import {
  selectProfileOffers,
  selectTotalOffers,
} from "../../../store/selectors/offersSelectors";
import { selectLatestChats } from "../../../store/selectors/chatSelectors";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import NoProfileOffers from "./NoProfileOffers.js/NoProfileOffers";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { OFFERS_PROFILE_SCOPE } from "../../../store/actions/offers/offersActionConstants";
import SkeletonOfferCard from "../../Cards/OfferCard/SkeletonOfferCard/SkeletonOfferCard";
import useIsMobile from "../../../hooks/useIsMobile";
import ProfileOffersHeaderSkeleton from "./ProfileOffersHeaderSkeleton/ProfileOffersHeaderSkeleton";
import SelectSortField from "./SelectSortField/SelectSortField";
import HeaderTitle from "./HeaderTitle/HeaderTitle";
import SearchBar from "./SearchBar/SearchBar";
import { messageUserHelper } from "../../../util/helpers/messageHelper";
import { sortEnum } from "../../../enums/sortEnum";
import usePaging from "../../../hooks/useOffers/usePaging";
import { useEffect } from "react";
import {
  fetchProfileOffers,
  setProfileOffers,
} from "../../../store/actions/offers/offersActions";
import { useRef } from "react";

const ProfileOffers = (props) => {
  const isLoadingMineOffers = useSelector(
    selectIsLoadingByActionType(OFFERS_PROFILE_SCOPE)
  );
  const chats = useSelector(selectLatestChats);
  const sortRef = useRef(null);
  const profileOffers = useSelector(selectProfileOffers);
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectUserId);
  const arrayForMapping = Array.apply(null, Array(4)).map(() => {});
  const [searchValue, setSearchValue] = useState("");
  const [sortOption, setSortOption] = useState(sortEnum.INITIAL);
  const [append, setAppend] = useState(true);
  const paging = usePaging(null, true);
  const total = useSelector(selectTotalOffers);
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(setProfileOffers([]));
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchProfileOffers({
        idProfile: props.idProfile,
        searchValue: searchValue,
        sortOption: sortOption,
        append: isMobile && append,
        page: paging.currentPage,
        isAdmin: props?.isAdmin,
      })
    );
    setAppend(true);
  }, [searchValue, sortOption, paging.currentPage]);

  useEffect(() => {
    if (isLoadingMineOffers === false) {
      if (searchRef.current && searchRef.current?.searchValue !== searchValue)
        searchRef.current.changeSearchValue(searchValue);
      if (sortRef.current && sortRef.current?.sortOption !== sortOption)
        sortRef.current.setSortOption(sortOption);
    }
  }, [isLoadingMineOffers]);

  const messageUser = (offer) => {
    messageUserHelper(chats, offer, userId);
  };

  const handleInfiniteScroll = () => {
    if (total !== profileOffers?.length) {
      paging.goToNextPage();
    }
  };

  const handleChangeSortOption = (newValue) => {
    dispatch(setProfileOffers([]));
    setAppend(true);
    paging.changePage(1);
    setSortOption(newValue);
  };

  const handleSearch = (newValue) => {
    dispatch(setProfileOffers([]));
    paging.changePage(1);
    setSearchValue(newValue);
  };

  return (
    <ProfileOffersContainer isAdmin={props.isAdmin}>
      {(isLoadingMineOffers || isLoadingMineOffers === undefined) &&
      profileOffers?.length === 0 ? (
        <>
          <ProfileOffersHeaderSkeleton />
          {isMobile ? (
            <SkeletonContainer>
              <SkeletonOfferCard vertical skeleton />
              <SkeletonOfferCard vertical skeleton />
              <SkeletonOfferCard vertical skeleton />
            </SkeletonContainer>
          ) : (
            <>
              {arrayForMapping.map((item, index) => (
                <SkeletonOfferCard key={index} skeleton />
              ))}
            </>
          )}
        </>
      ) : (
        <OffersContainer>
          <SelectSortField
            ref={sortRef}
            offersToShow={profileOffers}
            setSortOption={handleChangeSortOption}
          />
          <HeaderTitle isMyProfile={props.isMyProfile && !props.isAdmin} />
          <SearchBar
            handleSearch={handleSearch}
            value={searchValue}
            ref={searchRef}
          />
          {!isMobile &&
            (profileOffers.length !== 0 ? (
              <>
                {profileOffers.map((item) => (
                  <OfferCard
                    isAdmin={props.isAdmin}
                    isMyOffer={props.isMyProfile || props.isAdmin}
                    offer={item}
                    key={JSON.stringify(item)}
                    messageUser={messageUser}
                  />
                ))}
              </>
            ) : (
              <NoProfileOffers />
            ))}
        </OffersContainer>
      )}

      {isMobile ? (
        <OffersScroller
          hideArrows
          noOffers
          infiniteScroll={handleInfiniteScroll}
        >
          {profileOffers.length !== 0 ? (
            profileOffers.map((item) => (
              <OfferCard
                isAdmin={props.isAdmin}
                vertical
                isMyOffer={props.isMyProfile || props.isAdmin}
                offer={item}
                key={JSON.stringify(item)}
                pinned={item.pinned}
                messageUser={messageUser}
              />
            ))
          ) : (
            <NoProfileOffers />
          )}
        </OffersScroller>
      ) : (
        <ProfilePaging
          current={paging.currentPage}
          changePage={paging.changePage}
          totalElements={total}
        />
      )}
    </ProfileOffersContainer>
  );
};

ProfileOffers.propTypes = {
  children: PropTypes.node,
  isMyProfile: PropTypes.bool,
  isAdmin: PropTypes.bool,
  idProfile: PropTypes.string,
};

export default ProfileOffers;

import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { Label } from "../../../CheckBox/Label";
import Option from "../../../Select/Option/Option";

export const CreateOfferTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  width: 328px;
  height: 33px;
  text-align: center;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${selectedTheme.colors.primaryPurple};
  margin-top: 36px;
  margin-bottom: 40px;
  position: relative;
`;
export const CreateOfferDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  margin-top: 9px;
  width: 221px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${selectedTheme.colors.primaryGrayText};
  margin-bottom: 18px;
`;
export const CreateOfferFormContainer = styled(Box)`
  width: 335px;
  padding: 20px 0 35px 0;

  @media (max-width: 360px) {
    width: 100%;
  }
`;
export const FieldLabel = styled(Label)`
  position: relative;
  bottom: -14px;
  & label {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: ${selectedTheme.colors.primaryGrayText};
    cursor: auto;
    letter-spacing: 0.2px;
  }
  @media (max-width: 600px) {
    & label {
      font-size: 9px;
    }
  }
`;
export const SelectOption = styled(Option)`
  height: 40px !important;
  min-height: 40px;
  max-height: 40px;
`;

import React from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  SkeletonItemDetailsContainer,
  SkeletonItemDetailsHeader,
  SkeletonItemDetailsContent,
  SkeletonItemDetailsLineContainer,
  SkeletonItemDetailsLineSmallContainer,
  SkeletonItemDetailsLineSmallFirst,
  SkeletonItemDetailsLineSmallSecond,
  SkeletonItemDetailsLineSmallThird,
  SkeletonItemDetailsLineSmallFourth,
  SkeletonItemDetailsLineBig,
  SkeletonItemDetailsGalleryMainContainer,
  SkeletonItemDetailsGallery,
  SkeletonItemDetailsImagesContainer,
  SkeletonItemDetailsArrow,
  SkeletonItemDetailsImage,
  SkeletonItemDetailsImageHalf,
  SkeletonItemDetailsMainContainer,
  SkeletonItemDetailsMainFirstLine,
  SkeletonItemDetailsMainMarginS,
  SkeletonItemDetailsMainMarginL,
  SkeletonItemDetailsMainSecondLine,
  SkeletonItemDetailsMainThirdLine,
  SkeletonItemDetailsMainFourthLine,
  SkeletonItemDetailsMainFifthLine,
  SkeletonItemDetailsMainSixthLine,
  SkeletonItemDetailsMainButtons,
  SkeletonItemDetailsMainButtonRoundFirst,
  SkeletonItemDetailsMainButtonRoundSecond,
  SkeletonItemDetailsMainButtonContainer,
  SkeletonItemDetailsMainButton,
  SkeletonItemDetailsMainDescription,
  SkeletonItemDetailsMainSeventhLine,
  SkeletonItemDetailsMainEighthLine,
  SkeletonItemDetailsMainNinethLine,
  SkeletonItemDetailsMainTenthLine,
  SkeletonItemDetailsMainEleventhLine,
  SkeletonItemDetailsMainTwelfthLine,
  SkeletonItemDetailsMainThirteenthLine,
  SkeletonItemDetailsMainFourteenthLine,
  SkeletonItemDetailsMainMobileButtonLinesCont,
  SkeletonItemDetailsMainMobileButtonFirstLine,
  SkeletonItemDetailsMainMobileButtonSecondLine,
} from "./SkeletonItemDetails.styled";

const SkeletonItemDetails = () => {
  const { isMobile } = useIsMobile();
  return (
    <SkeletonItemDetailsContainer>
      <SkeletonItemDetailsHeader />
      <SkeletonItemDetailsContent>
        <SkeletonItemDetailsLineContainer>
          <SkeletonItemDetailsLineSmallContainer>
            <SkeletonItemDetailsLineSmallFirst />
            <SkeletonItemDetailsLineSmallSecond />
            <SkeletonItemDetailsLineSmallThird />
            <SkeletonItemDetailsLineSmallFourth />
          </SkeletonItemDetailsLineSmallContainer>
          <SkeletonItemDetailsLineBig />
        </SkeletonItemDetailsLineContainer>
        <SkeletonItemDetailsGalleryMainContainer>
          <SkeletonItemDetailsGallery>
            <SkeletonItemDetailsArrow />
            <SkeletonItemDetailsImagesContainer>
              {isMobile ? (
                <>
                  <SkeletonItemDetailsImage />
                  <SkeletonItemDetailsImageHalf />
                </>
              ) : (
                <>
                  <SkeletonItemDetailsImage />
                  <SkeletonItemDetailsImage />
                  <SkeletonItemDetailsImage />
                  <SkeletonItemDetailsImageHalf />
                </>
              )}
            </SkeletonItemDetailsImagesContainer>
            <SkeletonItemDetailsArrow />
          </SkeletonItemDetailsGallery>
          <SkeletonItemDetailsMainContainer>
            <SkeletonItemDetailsMainFirstLine />
            <SkeletonItemDetailsMainDescription />
            <SkeletonItemDetailsMainMarginS />
            <SkeletonItemDetailsMainSecondLine />
            <SkeletonItemDetailsMainThirdLine />
            <SkeletonItemDetailsMainFourthLine />
            <SkeletonItemDetailsMainMarginL />
            <SkeletonItemDetailsMainFifthLine />
            <SkeletonItemDetailsMainSixthLine />
            <SkeletonItemDetailsMainSeventhLine />
            <SkeletonItemDetailsMainEighthLine />
            <SkeletonItemDetailsMainNinethLine />
            <SkeletonItemDetailsMainTenthLine />
            <SkeletonItemDetailsMainEleventhLine />
            <SkeletonItemDetailsMainTwelfthLine />
            <SkeletonItemDetailsMainThirteenthLine />
            <SkeletonItemDetailsMainFourteenthLine />
            <SkeletonItemDetailsMainButtons>
              <SkeletonItemDetailsMainButtonRoundFirst />
              <SkeletonItemDetailsMainButtonRoundSecond />
              {isMobile && (
                <SkeletonItemDetailsMainMobileButtonLinesCont>
                  <SkeletonItemDetailsMainMobileButtonFirstLine />
                  <SkeletonItemDetailsMainMobileButtonSecondLine />
                </SkeletonItemDetailsMainMobileButtonLinesCont>
              )}
              <SkeletonItemDetailsMainButtonContainer>
                <SkeletonItemDetailsMainButton />
              </SkeletonItemDetailsMainButtonContainer>
            </SkeletonItemDetailsMainButtons>
          </SkeletonItemDetailsMainContainer>
        </SkeletonItemDetailsGalleryMainContainer>
      </SkeletonItemDetailsContent>
    </SkeletonItemDetailsContainer>
  );
};

export default SkeletonItemDetails;

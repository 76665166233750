import scrollConstants from "../../../constants/scrollConstants";
import { ABOUT_ROUTE_SELECTED } from "../../actions/app/appActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  aboutRouteSelected: scrollConstants.about.aboutPage,
};

export default createReducer(
  {
    [ABOUT_ROUTE_SELECTED]: setAboutRouteSelected,
  },
  initialState
);
function setAboutRouteSelected(state, { payload }) {
  return {
    ...state,
    aboutRouteSelected: payload,
  };
}

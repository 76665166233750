import {
  EXCHANGE_ACCEPT_FETCH,
  EXCHANGE_ACCEPT_FETCH_ERROR,
  EXCHANGE_ACCEPT_FETCH_SUCCESS,
  EXCHANGE_FETCH,
  EXCHANGE_FETCH_ERROR,
  EXCHANGE_FETCH_SUCCESS,
  EXCHANGE_SET,
  EXCHANGE_VALIDATE_FETCH,
  EXCHANGE_VALIDATE_FETCH_ERROR,
  EXCHANGE_VALIDATE_FETCH_SUCCESS,
  REQUESTER_SET,
} from "./exchangeActionConstants";
export const setExchange = (payload) => ({
  type: EXCHANGE_SET,
  payload,
});
export const setRequester = (payload) => ({
  type: REQUESTER_SET,
  payload,
});
export const fetchExchange = (payload) => ({
  type: EXCHANGE_FETCH,
  payload,
});
export const fetchExchangeSuccess = () => ({
  type: EXCHANGE_FETCH_SUCCESS,
});
export const fetchExchangeError = () => ({
  type: EXCHANGE_FETCH_ERROR,
});
export const validateExchange = (payload) => ({
  type: EXCHANGE_VALIDATE_FETCH,
  payload,
});
export const validateExchangeSuccess = () => ({
  type: EXCHANGE_VALIDATE_FETCH_SUCCESS,
});
export const validateExchangeError = () => ({
  type: EXCHANGE_VALIDATE_FETCH_ERROR,
});
export const acceptExchange = (payload) => ({
  type: EXCHANGE_ACCEPT_FETCH,
  payload,
});
export const acceptExchangeSuccess = () => ({
  type: EXCHANGE_ACCEPT_FETCH_SUCCESS,
});
export const acceptExchangeError = () => ({
  type: EXCHANGE_ACCEPT_FETCH_ERROR,
});

import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  ADD_TYPE,
  DELETE_TYPE,
  EDIT_TYPE,
} from "../../constants/adminMethodConstants";
import {
  CATEGORIES_TYPE,
  LOCATIONS_TYPE,
  SUBCATEGORIES_TYPE,
  USERS_APPROVE_TYPE,
  USERS_BLOCK_TYPE,
  USERS_DELETE_TYPE,
  USERS_UNBLOCK_TYPE,
} from "../../constants/adminTypeConstants";
import {
  attemptAddNewCategory,
  attemptAddNewSubcategory,
  attemptDeleteCategory,
  attemptDeleteSubcategory,
  attemptEditCategory,
  attemptEditSubcategory,
} from "../../request/categoriesRequest";
import {
  attemptAddNewLocation,
  attemptDeleteLocation,
  attemptEditLocation,
} from "../../request/locationsRequest";
import {
  attemptApproveProfileAsAdmin,
  attemptBlockProfileAsAdmin,
  attemptDeleteProfileAsAdmin,
  attemptUnblockProfileAsAdmin,
} from "../../request/profileRequest";
// import { attemptAddNewCategory } from "../../request/categoriesRequest";
import { ADMIN_FETCH } from "../actions/admin/adminActionConstants";
import {
  fetchAdminMethodError,
  fetchAdminMethodSuccess,
} from "../actions/admin/adminActions";
import { fetchCategories } from "../actions/categories/categoriesActions";
import { fetchLocations } from "../actions/locations/locationsActions";
import { fetchAllProfilesAsAdmin } from "../actions/profile/profileActions";

function* editCategory(payload) {
  try {
    yield call(attemptEditCategory, {
      categoryId: payload.id,
      body: { name: payload.values.title },
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* addCategory(payload) {
  try {
    let dataToSend = new FormData();
    dataToSend.append("name", payload.values.title);
    // dataToSend.append("file", payload.values.image);
    // dataToSend.append("subcategories", JSON.stringify([]));
    yield call(attemptAddNewCategory, {
      body: dataToSend,
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* deleteCategory(payload) {
  try {
    yield call(attemptDeleteCategory, {
      categoryId: payload.id,
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* editSubcategory(payload) {
  try {
    yield call(attemptEditSubcategory, {
      categoryId: payload.id,
      subcategoryName: payload.name,
      body: { name: payload.values.title },
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* addSubcategory(payload) {
  try {
    yield call(attemptAddNewSubcategory, {
      categoryId: payload.id,
      body: { name: payload.values.title },
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* deleteSubcategory(payload) {
  try {
    yield call(attemptDeleteSubcategory, {
      categoryId: payload.id,
      subcategoryName: payload.name,
    });
    yield put(fetchCategories());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* editLocation(payload) {
  try {
    yield call(attemptEditLocation, {
      locationId: payload.id,
      body: { city: payload.values.title },
    });
    yield put(fetchLocations());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* addLocation(payload) {
  try {
    yield call(attemptAddNewLocation, {
      locationId: payload.id,
      body: { city: payload.values.title },
    });
    yield put(fetchLocations());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* deleteLocation(payload) {
  try {
    yield call(attemptDeleteLocation, {
      locationId: payload.id,
    });
    yield put(fetchLocations());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* deleteUser(payload) {
  try {
    yield call(attemptDeleteProfileAsAdmin, { userId: payload.id });
    yield put(fetchAllProfilesAsAdmin());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* blockUser(payload) {
  try {
    yield call(attemptBlockProfileAsAdmin, { userId: payload.id });
    yield put(fetchAllProfilesAsAdmin());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* unblockUser(payload) {
  try {
    yield call(attemptUnblockProfileAsAdmin, { userId: payload.id });
    yield put(fetchAllProfilesAsAdmin());
  } catch (e) {
    yield call(console.log, e);
  }
}
function* approveUser(payload) {
  try {
    yield call(attemptApproveProfileAsAdmin, { userId: payload.id });
    yield put(fetchAllProfilesAsAdmin());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* fetchAdminMethod({ payload }) {
  try {
    if (payload.type === CATEGORIES_TYPE) {
      if (payload.method === ADD_TYPE)
        yield call(addCategory, { values: payload.values, id: payload.id });
      else if (payload.method === EDIT_TYPE)
        yield call(editCategory, { values: payload.values, id: payload.id });
      else if (payload.method === DELETE_TYPE)
        yield call(deleteCategory, { id: payload.id });
    } else if (payload.type === SUBCATEGORIES_TYPE) {
      if (payload.method === EDIT_TYPE)
        yield call(editSubcategory, {
          values: payload.values,
          id: payload.categoryId,
          name: payload.name,
        });
      else if (payload.method === DELETE_TYPE)
        yield call(deleteSubcategory, {
          id: payload.categoryId,
          name: payload.name,
        });
      else if (payload.method === ADD_TYPE)
        yield call(addSubcategory, {
          values: payload.values,
          id: payload.categoryId,
        });
    } else if (payload.type === LOCATIONS_TYPE) {
      if (payload.method === ADD_TYPE)
        yield call(addLocation, {
          values: payload.values,
          id: payload.id,
        });
      else if (payload.method === EDIT_TYPE)
        yield call(editLocation, {
          values: payload.values,
          id: payload.id,
        });
      else if (payload.method === DELETE_TYPE)
        yield call(deleteLocation, {
          id: payload.id,
        });
    } else if (payload.type === USERS_DELETE_TYPE) {
      yield call(deleteUser, { id: payload.id });
    } else if (payload.type === USERS_BLOCK_TYPE) {
      yield call(blockUser, { id: payload.id });
    } else if (payload.type === USERS_UNBLOCK_TYPE) {
      yield call(unblockUser, { id: payload.id });
    } else if (payload.type === USERS_APPROVE_TYPE) {
      yield call(approveUser, { id: payload.id });
    }
    if (payload.handleApiResponseSuccess)
      yield call(payload.handleApiResponseSuccess);
    yield put(fetchAdminMethodSuccess());
  } catch (e) {
    yield put(fetchAdminMethodError());
  }
}

export default function* adminSaga() {
  yield all([takeLatest(ADMIN_FETCH, fetchAdminMethod)]);
}

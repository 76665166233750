import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("login.emailFormat"))
    .required(i18n.t("login.emailRequired")),
  password: Yup.string()
    .required(i18n.t("login.passwordRequired"))
    .min(8, i18n.t("login.passwordLength")),
});

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DropdownList from "../../../../Dropdown/DropdownList/DropdownList";
import selectedTheme from "../../../../../themes";
import { ReactComponent as DropdownDown } from "../../../../../assets/images/svg/down-arrow.svg";
import { ReactComponent as DropdownUp } from "../../../../../assets/images/svg/up-arrow.svg";
import { ReactComponent as CloseBlack } from "../../../../../assets/images/svg/close-black.svg";
import { ClearText, SearchBar } from "./FilterRadioDropdown.styled";
import DropdownItem from "../../../../Dropdown/DropdownItem/DropdownItem";
import RadioButton from "../../../../Radio/Button/RadioButton";
import RadioGroup from "../../../../Radio/Group/RadioGroup";

const FilterRadioDropdown = (props) => {
  const [toSearch, setToSearch] = useState("");
  const [dataToShow, setDataToShow] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const { data } = props;
  useEffect(() => {
    setDataToShow([...data]);
  }, [data]);

  useEffect(() => {
    if (toSearch.length > 0) {
      setDataToShow(
        data.filter((item) =>
          item.name
            ? item.name.toLowerCase().includes(toSearch.toLowerCase())
            : item.toLowerCase().includes(toSearch.toLowerCase())
        )
      );
    } else {
      setDataToShow([...data]);
    }
  }, [toSearch]);

  useEffect(() => {
    if (
      props.selected?._id !== 0 &&
      props.selected !== null &&
      props.selected !== undefined &&
      Object.keys(props.selected).length !== 0
    ) {
      setIsOpened(true);
    }
  }, [props.selected]);

  const handleClear = () => {
    setToSearch("");
  };
  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
    if (props.handleOpen) props.handleOpen();
  };
  return (
    <DropdownList
      title={props.title}
      textcolor={
        !props.selected || props.selected?._id === 0 || !props.selected?._id
          ? selectedTheme.colors.primaryText
          : selectedTheme.colors.primaryPurple
      }
      dropdownIcon={props.icon}
      toggleIconClosed={<DropdownDown />}
      toggleIconOpened={<DropdownUp />}
      fullWidth
      open={props?.open !== undefined ? props.open : isOpened}
      disabled={props.disabled}
      setIsOpened={handleOpen}
      toggleIconStyles={{
        backgroundColor: (
          props.open !== undefined || props.open !== null
            ? props.open
            : isOpened
        )
          ? "white"
          : selectedTheme.colors.primaryIconBackgroundColor,
      }}
      headerOptions={
        // SearchBar
        <React.Fragment>
          <SearchBar
            placeholder={props.searchPlaceholder}
            italicPlaceholder
            value={toSearch}
            onChange={(event) => setToSearch(event.target.value)}
            textsize={"12px"}
            font={selectedTheme.fonts.textFont}
            fullWidth
            height={"40px"}
            containerStyle={{ marginTop: "6px" }}
            InputProps={{
              endAdornment:
                toSearch.length > 0 ? (
                  <ClearText onClick={handleClear}>
                    <CloseBlack />
                  </ClearText>
                ) : (
                  <React.Fragment />
                ),
            }}
          />
        </React.Fragment>
      }
    >
      {(isOpened || props?.open) && (
        <RadioGroup>
          {props.firstOption && (
            <DropdownItem>
              <RadioButton
                value={props.firstOption.value}
                label={props.firstOption.label}
                // number={item.numberOfProducts}
                fullWidth
                checked={
                  (props?.selected && !("_id" in props?.selected)) ||
                  props?.selected?._id === 0 || !props?.selected
                }
                onChange={props.setSelected}
              />
            </DropdownItem>
          )}
          {dataToShow.map((item) => {
            return (
              <DropdownItem
                key={item.name}
                onClick={() => props.setSelected(item)}
              >
                <RadioButton
                  value={item}
                  label={item?.name ? item?.name : item?.length > 0 ? item : ""}
                  number={item.offerCount}
                  fullWidth
                  checked={
                    JSON.stringify(props.selected) === JSON.stringify(item)
                  }
                  onChange={props.setSelected}
                />
              </DropdownItem>
            );
          })}
        </RadioGroup>
      )}
    </DropdownList>
  );
};

FilterRadioDropdown.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  data: PropTypes.array,
  title: PropTypes.string,
  oneValueAllowed: PropTypes.bool,
  fullWidth: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setSelected: PropTypes.func,
  selected: PropTypes.any,
  firstOption: PropTypes.any,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
};
FilterRadioDropdown.defaultProps = {
  oneValueAllowed: false,
  fullWidth: false,
};

export default FilterRadioDropdown;
